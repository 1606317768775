import { ReactNode, useMemo, useRef, useEffect, useState } from 'react'
import { Layout, Menu, Select } from 'gokwik-ui-kit'
import { useNavigate, Routes, Route, useLocation } from 'react-router-dom'
import ShopifyAppPincode from '@pages/settings/shopify-app-pincode-settings'
import ShopifyAppShipping from '@pages/settings/shopify-app-shipping-settings'
import Discount from '@pages/settings/discount-settings'
import { CreateDiscountContainer } from './discount-settings/create-discount'
import { useSelector } from 'react-redux'
import { getMerchantDetails, getUserConfig, getUserDetails } from '@store/user/selectors'
import { stepConfiguration } from '@library/utilities/constants/constants'
import CodFeatures from './cod-features'
import UiCustomisation from './shopify-app-ui-customisations'
import DiscountSuggestions from './shopify-app-ui-customisations/discount-suggestions'
import CheckoutUiCustomisation from './shopify-app-ui-customisations/gokwik-checkout-ui'
import ReviewComponent from './cod-features/helpers/ReviewComponent'
import { useAppDispatch } from '@library/utilities/hooks'
import { fetchShopifyAppMerchantConfig } from '@store/shopify-app'
import { isShopifyAppCheckoutEnabled } from '@store/shopify-app/selectors'
import PrepaidDiscounts from './shopify-app-payment-configs/prepaid-discounts'
import PaymentFeatures from './shopify-app-payment-configs'
import PpcodConfig from './shopify-app-payment-configs/ppcod'
import { FireEvents } from '@library/utilities/helpers/eventsHelper'
import { fireClickedEventOnSettingsPage } from '@library/utilities/helpers/helper'
import UpsellCheckout from './upsell/upsellCheckout'
import { titleCase } from '@gokwik/utilities'
import CodPageUiCustomisation from './cod-ui-customisations'

const { Sider } = Layout

interface MenuItem {
    key: string
    label: ReactNode
    icon?: ReactNode
    children?: MenuItem[]
    type?: 'group'
    className?: string
}

function createMenuItem(
    label: ReactNode,
    key: string,
    className?: string,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        children,
        label,
        type,
        className,
    }
}
export default function () {
    const merchant_details = useSelector(getMerchantDetails)
    const config = useSelector(getUserConfig)
    const route = useLocation()
    const navigate = useNavigate()
    const previousOpenedItems = useRef({
        openedKey: '',
        selectedKey: '',
    })
    const user_details = useSelector(getUserDetails)

    const checkoutPlatformKeys = useMemo(
        () =>
            Object.entries(config?.supported_checkout_configs || {}).reduce(
                (result: string[], item: [string, boolean]) =>
                    item[1] && item[0] !== 'custom' ? [...result, item[0]] : result,
                [],
            ),
        [config],
    )
    const [checkoutPlatform, setCheckoutPlatform] = useState('kwik_checkout')

    const eventsClass = new FireEvents(merchant_details, user_details).features
    const settingsEvents = eventsClass.settingsPageEvents

    const dispatch = useAppDispatch()
    useEffect(() => {
        if (config?.enhancer_app) {
            dispatch(fetchShopifyAppMerchantConfig())
        }
        settingsEvents.fireClickedEvent()
    }, [config?.enhancer_app])

    const shopifyAppCheckoutEnabled = useSelector(isShopifyAppCheckoutEnabled)

    const settingsList = useMemo(
        () => [
            {
                title: 'Pincode Serviceability',
                path: '/pincode',
                component: () => <ShopifyAppPincode events={eventsClass.pincodeServiceabilityEvents} reviewFlowEvents={eventsClass.reviewFlowEvents} />,
                className: 'pincode-serviceability-item',
            },
            {
                title: 'Shipping Serviceability',
                path: '/shipping',
                component: () => <ShopifyAppShipping events={eventsClass.shippingServiceabilityEvents} />,
                className: 'shipping-serviceability-item',
            },
            {
                title: 'All Discounts',
                path: '/discount',
                component: () => <Discount events={eventsClass.customDiscountsEvents} />,
                className: 'all-discounts-item',
            },
            {
                title: 'All Discounts',
                path: '/create-discount',
                component: () => <CreateDiscountContainer events={eventsClass.customDiscountsEvents} />,
                hideInMenu: true,
            },
            {
                title: 'All Discounts',
                path: '/edit-discount/:id',
                component: () => <CreateDiscountContainer events={eventsClass.customDiscountsEvents} />,
                hideInMenu: true,
            },
            {
                title: 'COD Features',
                path: '/cod-features',
                component: () => <CodFeatures events={eventsClass} />,
                className: 'cod-features-item',
            },
            ...(config?.enhancer_app && shopifyAppCheckoutEnabled
                ? [
                      {
                          title: 'Payment Features',
                          path: '/payment-features',
                          component: () => <PaymentFeatures events={eventsClass.paymentFeaturesEvents} />,
                          className: 'payment-features',
                      },
                      {
                          title: 'PPCOD',
                          path: '/payment-features/ppcod',
                          component: () => <PpcodConfig events={eventsClass.ppcodEvents} />,
                          className: 'shopify-app-pppcod',
                          hideInMenu: true,
                      },
                      {
                          title: 'Prepaid Discounts',
                          path: '/payment-features/prepaid-discounts',
                          component: () => <PrepaidDiscounts events={eventsClass.prepaidDiscountsEvents} />,
                          className: 'prepaid-discounts',
                          hideInMenu: true,
                      },
                      {
                          title: 'UI Customisation',
                          path: '/ui-customisation',
                          component: () => <UiCustomisation events={eventsClass} />,
                          className: 'ui-customisation-item',
                      },
                      {
                          title: 'Discount Suggestions',
                          path: '/ui-customisation/discount-suggestions',
                          component: () => <DiscountSuggestions events={eventsClass.discountSuggestionsEvents} />,
                          className: 'discount-suggestions-item',
                          hideInMenu: true,
                      },
                      {
                          title: 'Gokwik Checkout UI',
                          path: '/ui-customisation/checkout-ui',
                          component: () => <CheckoutUiCustomisation events={eventsClass.loginUICustomisationsEvents} paymentUICustomisationEvents={eventsClass.paymentUICustomisationsEvents} />,
                          className: 'checkout-ui-item',
                          hideInMenu: true,
                      },
                  ]
                : [
                      {
                          title: 'UI Customisation',
                          path: '/ui-customisation/cod',
                          component: () => (
                              <CodPageUiCustomisation events={eventsClass.codPageUICustomisationsEvents} />
                          ),
                          className: 'cod-ui-customisation-item',
                      },
                  ]),
        ],
        [config, shopifyAppCheckoutEnabled],
    )
    const menuItems = () => {
        const items = settingsList.reduce(
            (result, item) => [
                ...result,
                ...(item.hideInMenu
                    ? []
                    : [createMenuItem(item.title, `/shopify-app/settings${item.path}`, item.className)]),
            ],
            [],
        )
        return items
    }

    const openItems = useMemo(() => {
        const menu = menuItems()

        const openedKey = menu.find((item) => {
            if (item.key === route.pathname) {
                return true
            }
            if (item.children) {
                return item.children.some((child) => child.key === route.pathname)
            }
            return false
        })

        if (openedKey) {
            previousOpenedItems.current = {
                openedKey: openedKey.key,
                selectedKey: route.pathname,
            }
            return {
                openedKey: openedKey.key,
                selectedKey: route.pathname,
            }
        } else if (previousOpenedItems.current.openedKey) {
            return {
                ...previousOpenedItems.current,
            }
        } else {
            let similarSelectedKey: MenuItem | undefined
            const similarKey = menu.find(
                (item) =>
                    !!item.children?.find((child) => {
                        if (route.pathname.includes(child.key)) {
                            similarSelectedKey = child
                            return true
                        } else return false
                    }),
            )

            previousOpenedItems.current = {
                openedKey: similarKey?.key || '',
                selectedKey: similarSelectedKey?.key || '',
            }
            return {
                openedKey: similarKey?.key || '',
                selectedKey: similarSelectedKey?.key || '',
            }
        }
    }, [route.pathname, settingsList])

    const eventMappings: Record<string, string> = {
        '/shopify-app/settings/pincode': 'pincode',
        '/shopify-app/settings/shipping': 'shipping',
        '/shopify-app/settings/discount': 'discounts',
        '/shopify-app/settings/cod-features': 'cod_features',
        '/shopify-app/settings/payment-features': 'payment_features',
        '/shopify-app/settings/ui-customisation': 'ui_customisation',
        '/shopify-app/settings/ui-customisation/cod': 'cod_ui_customisation',
    }

    const handleMenuSelection = ({ key }: any) => {
        const eventName = eventMappings[key]

        fireClickedEventOnSettingsPage(eventName, eventsClass)
        navigate(key)
    }

    useEffect(() => {
        if (route.pathname === '/shopify-app/settings') navigate('/shopify-app/settings' + settingsList[0].path)
    }, [route.pathname])

    return (
        <div className='w-full bg-white'>
            <Layout className='h-full'>
                {(stepConfiguration[merchant_details?.onboarding_status?.kwik_checkout_app]?.id >=
                    stepConfiguration['imp_checks_passed']?.id ||
                    !merchant_details?.onboarding_status?.kwik_checkout_app) && (
                    <Sider
                        className='bg-white h-full mr-4 border-0 border-gray-100 border-r-2 fixed overflow-hidden'
                        style={{ background: 'white' }}
                    >
                        {checkoutPlatformKeys.length > 1 && (
                            <Select
                                className='w-full mb-4'
                                value={checkoutPlatform}
                                onChange={(value) => {
                                    localStorage.setItem('checkoutPlatform', value)
                                    setCheckoutPlatform(value)
                                    if (value === 'checkout_1') navigate(`/checkout/settings`)
                                }}
                                options={checkoutPlatformKeys.map((item: string) => ({
                                    label: titleCase(item.split('_').join(' ')),
                                    value: item,
                                }))}
                            />
                        )}
                        <div className='setting-menu-custom-css flex flex-col h-[95vh]'>
                            <Menu
                                onClick={handleMenuSelection}
                                defaultOpenKeys={[openItems.openedKey]}
                                defaultSelectedKeys={[openItems.selectedKey]}
                                key={`${route.pathname}-${openItems.openedKey}-${openItems.selectedKey}`}
                                items={menuItems()}
                                inlineCollapsed={true}
                                mode='inline'
                            />
                            {/*Conditions to show UpsellCheckout:  
                                (historical user AND 
                                    (is a shopify-app merchant AND
                                    merchant's onboarding step for Kwik Checkout App is not within the range of 'kyc_viewed' and 'kyc_completed' steps AND
                                    kyc details have not been submitted AND
                                    kyc has not been done manually ( merchant with 1.0 checkout and COD app))
                                )   OR
                                kwik_cod is chosen from onboarding (new user)
                            */}
                            {((!merchant_details?.gokwik_products &&
                                merchant_details?.onboarding_status?.kwik_checkout_app &&
                                (stepConfiguration[merchant_details?.onboarding_status?.kwik_checkout_app]?.id <
                                    stepConfiguration['kyc_viewed']?.id ||
                                    stepConfiguration[merchant_details?.onboarding_status?.kwik_checkout_app]?.id >
                                        stepConfiguration['kyc_completed']?.id) &&
                                !merchant_details?.easebuzz_merchant_id &&
                                !merchant_details?.kyc_completion_status) ||
                                (merchant_details?.gokwik_products?.length === 1 &&
                                    merchant_details?.gokwik_products[0] === 'kwik_cod')) && (
                                <UpsellCheckout settingsEvents={settingsEvents} />
                            )}
                        </div>
                    </Sider>
                )}
                <Layout className='overflow-auto'>
                    <ReviewComponent events={eventsClass.reviewFlowEvents} />
                    <Routes>
                        {settingsList.map((item, index) => (
                            <Route key={index} path={item.path} element={<item.component />} />
                        ))}
                    </Routes>
                </Layout>
            </Layout>
        </div>
    )
}
