import { ColumnProps, InfoCircleOutlined, Tag, Tooltip } from 'gokwik-ui-kit'
import { getTagColor, getTagTextByPercentage } from '../helpers'

export const awbIngestionColumns: ColumnProps<any>[] = [
    {
        title: 'Month',
        dataIndex: 'month',
        key: 'month',
    },
    {
        title: (
            <>
                <span className='flex items-center'>
                    AWB Fill Rate{' '}
                    <Tooltip placement='top' title={'Delivery Status Available with Gokwik/Total Orders Placed'}>
                        <InfoCircleOutlined className='ml-1' />
                    </Tooltip>
                </span>
            </>
        ),
        dataIndex: 'awbFillRate',
        ellipsis: true,
        render: (text: string) => (
            <span>
                {text}
                <Tag
                    variant={getTagColor(text)}
                    className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs ml-4`}
                >
                    {getTagTextByPercentage(text)}
                </Tag>
            </span>
        ),
    },
    {
        title: 'Ingestion Required',
        dataIndex: 'ingestionRequired',
        key: 'ingestionRequired',
    },
]
