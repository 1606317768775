import { useAppDispatch } from '@library/utilities/hooks'
import { DiyDiscountListing } from '@library/utilities/interface'
import { saveDiscount } from '@store/discounts'
import { responseMapDiscount } from '@store/discounts/api'
import {
    Alert,
    Button,
    Col,
    Divider,
    Drawer,
    Flex,
    Form,
    Input,
    InputNumber,
    PlusOutlined,
    Radio,
    Row,
    TextArea,
} from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'

const ChangeDiscountDescription = ({
    changeDescriptionDiscount,
    onClose,
}: {
    changeDescriptionDiscount: DiyDiscountListing
    onClose: (refetch?: boolean) => void
}) => {
    const [changeDescriptionForm]: [any] = Form.useForm()
    const [showAdditionalConditions, setShowAdditionalConditions] = useState(false)
    const dispatch = useAppDispatch()
    const submitForm = async () => {
        try {
            // Validate the form fields
            await changeDescriptionForm.validateFields()
            // Get the form values
            const values = changeDescriptionForm.getFieldsValue(true)
            const page = 'visibility'

            // Dispatch the saveDiscount action
            const res = await dispatch(
                saveDiscount(
                    {
                        ...values,
                        discountCodeVisibilityEnabled: true,
                        id: changeDescriptionDiscount.id,
                        discountMethod: 'manual',
                        subMethod: 'manual',
                        status: changeDescriptionDiscount.status,
                    },
                    page,
                ),
            )
            if (res.success) {
                onClose(true)
            }
        } catch (error) {
            console.error('Validation failed:', error)
        }
    }

    useEffect(() => {
        ;(async () => {
            const res = await responseMapDiscount(changeDescriptionDiscount)
            changeDescriptionForm.setFieldsValue({
                ...res,
            })
        })()
    }, [changeDescriptionDiscount])
    return (
        <Drawer
            title='Change Description'
            open={true}
            onClose={() => onClose()}
            width={600}
            footer={
                <Flex justify='end' className='gap-x-2'>
                    <Button onClick={() => onClose()}>Cancel</Button>
                    <Button variant='primary' onClick={() => submitForm()}>
                        Submit
                    </Button>
                </Flex>
            }
        >
            <Form
                className='change-description-form'
                form={changeDescriptionForm}
                layout='vertical'
                requiredMark='optional'
            >
                <Form.Item
                    label='Discount Code'
                    className='label-bold'
                    name='discountCode'
                    rules={[{ required: true, message: 'Please enter code!' }]}
                >
                    <Input disabled value={changeDescriptionDiscount.title} />
                </Form.Item>
                <Form.Item
                    label='Description'
                    className='label-bold'
                    name='discountCodeDescription'
                    rules={[{ required: true, message: 'Please enter description!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label='Additional Details' name='discountCodeDetails'>
                    <TextArea />
                </Form.Item>
                <Form.Item
                    label='Visibility'
                    name='discountCodeVisibility'
                    rules={[{ required: true, message: 'Please select visibility!' }]}
                >
                    <Radio.Group>
                        <Radio value={'ALWAYS'}>Always</Radio>
                        <Radio value={'ONLY_WHEN_ELIGIBLE'}>Only When Eligible</Radio>s
                    </Radio.Group>
                </Form.Item>
                {!showAdditionalConditions ? (
                    <Button className='pl-0 mt-2' type='link' onClick={() => setShowAdditionalConditions(true)}>
                        <PlusOutlined /> Add More Conditions
                    </Button>
                ) : (
                    <>
                        <Divider />
                        <Row gutter={[12, 12]}>
                            <Col span={6}>
                                <Form.Item
                                    label='Min Price'
                                    name='discountCodeVisibilityMinPrice'
                                    rules={[
                                        {
                                            validator: (_, value) => {
                                                if (
                                                    value &&
                                                    changeDescriptionForm.getFieldValue(
                                                        'discountCodeVisibilityMaxPrice',
                                                    ) &&
                                                    value >
                                                        changeDescriptionForm.getFieldValue(
                                                            'discountCodeVisibilityMaxPrice',
                                                        )
                                                ) {
                                                    return Promise.reject('Min price should be less than max price')
                                                }
                                                return Promise.resolve()
                                            },
                                        },
                                    ]}
                                >
                                    <InputNumber prefix='₹' className='w-full' min={0} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label='Max Price'
                                    name='discountCodeVisibilityMaxPrice'
                                    rules={[
                                        {
                                            validator: (_, value) => {
                                                if (
                                                    value &&
                                                    changeDescriptionForm.getFieldValue(
                                                        'discountCodeVisibilityMinPrice',
                                                    ) &&
                                                    value <
                                                        changeDescriptionForm.getFieldValue(
                                                            'discountCodeVisibilityMinPrice',
                                                        )
                                                ) {
                                                    return Promise.reject('Max price should be greater than min price')
                                                }
                                                return Promise.resolve()
                                            },
                                        },
                                    ]}
                                >
                                    <InputNumber prefix='₹' className='w-full' min={0} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    rules={[
                                        {
                                            validator: (_, value) => {
                                                if (
                                                    value &&
                                                    changeDescriptionForm.getFieldValue(
                                                        'discountCodeVisibilityMaxQty',
                                                    ) &&
                                                    value >
                                                        changeDescriptionForm.getFieldValue(
                                                            'discountCodeVisibilityMaxQty',
                                                        )
                                                ) {
                                                    return Promise.reject(
                                                        'Min quantity should be less than max quantity',
                                                    )
                                                }
                                                return Promise.resolve()
                                            },
                                        },
                                    ]}
                                    label='Min Quantity'
                                    name='discountCodeVisibilityMinQty'
                                >
                                    <InputNumber className='w-full' min={0} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label='Max Quantity'
                                    name='discountCodeVisibilityMaxQty'
                                    rules={[
                                        {
                                            validator: (_, value) => {
                                                if (
                                                    value &&
                                                    changeDescriptionForm.getFieldValue(
                                                        'discountCodeVisibilityMinQty',
                                                    ) &&
                                                    value <
                                                        changeDescriptionForm.getFieldValue(
                                                            'discountCodeVisibilityMinQty',
                                                        )
                                                ) {
                                                    return Promise.reject(
                                                        'Max quantity should be greater than min quantity',
                                                    )
                                                }
                                                return Promise.resolve()
                                            },
                                        },
                                    ]}
                                >
                                    <InputNumber className='w-full' min={0} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )}
            </Form>
            <Alert type='info' message='Control the user interface of coupons section on checkout.' showIcon />
        </Drawer>
    )
}

export default ChangeDiscountDescription
