import { createAsyncThunk, createSlice, Dispatch } from '@reduxjs/toolkit'
import { rtoDetailApi, fetchRtoDetailsData } from './api'

export interface RcaViewSlice {
    [key: string]: {
        [key: string]: any
        status?: 'idle' | 'loading' | 'failed'
    }
}

const assignToState = ({
    state,
    key,
    nestedKeys,
    payload,
    status,
}: {
    state: RcaViewSlice
    status: 'idle' | 'loading' | 'failed'
    key: string
    nestedKeys: string[]
    payload: any
}) => {
    if (nestedKeys && nestedKeys.length > 0) {
        nestedKeys.forEach((nestedKey) => {
            state[key][nestedKey].status = status
            if (payload && payload.data) state[key][nestedKey].data = payload.data[nestedKey]
        })
    } else {
        state[key].status = status
        state[key].data = payload.data
    }
    return state
}

const initialState: RcaViewSlice = {
    rtoInsights: {
        data: [],
        status: 'idle',
    },
    rtoInsightsPrevComparison: {
        data: [],
        status: 'idle',
    },
    decileData: {
        data: [],
        status: 'idle',
    },
    comparedDecileData: {
        data: [],
        status: 'idle',
    },
    statesData: {
        data: [],
        status: 'idle',
    },
    comparedStatesData: {
        data: [],
        status: 'idle',
    },
    citiesData: {
        data: [],
        status: 'idle',
    },
    comparedCitiesData: {
        data: [],
        status: 'idle',
    },
    pincodesData: {
        data: [],
        status: 'idle',
    },
    comparedPincodesData: {
        data: [],
        status: 'idle',
    },
    //to be added later
    // productsData: {
    //     data: [],
    //     status: 'idle',
    // },
    // comparedProductsData: {
    //     data: [],
    //     status: 'idle',
    // },
}

export const fetchRtoDetailsAsync = createAsyncThunk(
    'rca-view/tables',
    async (
        { key, params }: { key: string; nestedKey?: string; params?: { [key: string]: any } },
        { rejectWithValue },
    ) => {
        const response = await fetchRtoDetailsData({ key, params })
        if (response.success) return response
        else return rejectWithValue(response)
    },
)

export const fetchAllRtoDetailsData =
    ({ params }) =>
    async (dispatch: any) => {
        const comparisonKeys = new Set([
            'rtoInsightsPrevComparison',
            'comparedStatesData',
            'comparedCitiesData',
            'comparedPincodesData',
            'comparedDecileData',
            // 'comparedProductsData',
        ])

        const getUpdatedParams = (key: string, params: any) =>
            comparisonKeys.has(key)
                ? {
                      ...params,
                      from_date: params?.prev_comparison_from_date,
                      to_date: params?.prev_comparison_to_date,
                  }
                : params

        Object.entries(rtoDetailApi).forEach(([key, apiConfig]) => {
            const updatedParams = getUpdatedParams(key, params)
            dispatch(
                fetchRtoDetailsAsync({
                    key,
                    params: { ...updatedParams, filter_type: apiConfig?.defaultParams?.filter_type },
                }),
            )
        })
    }

export const rtoDetailsSlice = createSlice({
    name: 'rto_details',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchRtoDetailsAsync.pending, (state, action) => {
                const nestedKeys = action.meta.arg.nestedKey
                    ? [action.meta.arg.nestedKey]
                    : rtoDetailApi[action.meta.arg.key].nestedKeys || []
                assignToState({ state, key: action.meta.arg.key, nestedKeys, payload: {}, status: 'loading' })
            })
            .addCase(fetchRtoDetailsAsync.fulfilled, (state, action) => {
                const nestedKeys = action.meta.arg.nestedKey
                    ? [action.meta.arg.nestedKey]
                    : rtoDetailApi[action.meta.arg.key].nestedKeys || []
                assignToState({ state, key: action.meta.arg.key, nestedKeys, payload: action.payload, status: 'idle' })
            })
            .addCase(fetchRtoDetailsAsync.rejected, (state, action) => {
                const nestedKeys = action.meta.arg.nestedKey
                    ? [action.meta.arg.nestedKey]
                    : rtoDetailApi[action.meta.arg.key].nestedKeys || []
                assignToState({ state, key: action.meta.arg.key, nestedKeys, payload: {}, status: 'failed' })
            })
    },
})

export default rtoDetailsSlice.reducer
