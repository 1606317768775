import React, { memo, useRef } from 'react'
import { Select, Input, DatePicker, Tooltip } from 'gokwik-ui-kit'
import { SearchFilters } from '@library/utilities/interface'
import dayjs, { Dayjs } from 'dayjs'
import { phoneInputValidation } from '@gokwik/utilities'

const { Option } = Select

interface RenderFilterProps extends SearchFilters {
    removable?: boolean
    onRemove?: (key: string) => void
    value: string
    onChange: (data: { [key: string]: string }) => void
    inputKey: string
    disabled?: boolean
    disabledDate?: (current: Dayjs) => boolean; // Function to disable specific dates
}

const RenderFilter = ({
    input_details,
    value,
    label,
    removable,
    onChange,
    onRemove,
    id,
    is_dynamic,
    inputKey,
    disabled,
    disabledDate
}: RenderFilterProps) => {
    const inputRef = useRef(null)
    const handleInput = (newValue: any, key: string) => {
        if (key.toLowerCase().includes('phone')) {
            newValue = phoneInputValidation(newValue)
        }
        onChange({ [key]: newValue })
    }

    const getInputType = (label: any): any => {
        let type = 'text'
        if (label === 'Email') {
            type = 'email'
        } else {
            type = 'text'
        }
        return type
    }
    const formatString = (str) => {
        if (!str) return '';
        return str
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    };


    return (
        <>
            <Tooltip title={label}>
                {input_details?.input_type === 'date-range' ? (
                    <DatePicker.RangePicker
                        className='w-full'
                        size='middle'
                        value={[dayjs(value?.split(',')[0]), dayjs(value?.split(',')[1])]}
                        onChange={([start, end, string]: any) => {
                            const key = inputKey.split('/')
                            onChange({
                                [key[0]]: dayjs(start).format('YYYY-MM-DD'),
                                [key[1]]: dayjs(end).format('YYYY-MM-DD'),
                            })
                        }}
                        format={'MMM DD, YY'}
                        allowClear={false}
                        disabledDate={disabledDate}
                    />
                ) : input_details?.input_type === 'text' || input_details?.input_type === 'number' ? (
                    <Input
                        // ref={inputRef}
                        // onWheel={() => inputRef.current.blur()}
                        value={value}
                        type={getInputType(label)}
                        size='middle'
                        style={{ height: '32px' }}
                        className={'w-full'}
                        placeholder={label}
                        key={id}
                        maxLength={label === 'Phone' && 10}
                        allowClear
                        onChange={(e) => handleInput(e.target.value, inputKey)}
                        {...(is_dynamic && {
                            removable,
                            onRemove,
                        })}
                        disabled={disabled}
                    />
                ) : (
                    <Select
                        onSelectAll={(newValue: any) =>{
                            handleInput(newValue, inputKey)
                        }}
                        showSelectAll
                        disabled={disabled}
                        value={value || []}
                        size='middle'
                        className={'w-full'}
                        placeholder={label}
                        optionFilterProp='children'
                        maxTagCount={input_details?.mode === 'multi-select'? 1 : 2}
                        maxLength={1}
                        {...(['property_name',"modified_by"].includes(inputKey) || label === 'Merchant Name'
                            ? {}
                            : {
                                  filterOption: (input, option) =>
                                      option?.children?.toString()?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0,
                              })}
                        filterSort={(optionA, optionB) =>
                            optionA?.children
                                ?.toString()
                                ?.toLowerCase()
                                ?.localeCompare(optionB?.children?.toString()?.toLowerCase())
                        }
                        allowClear
                        key={id}
                        mode={input_details?.mode === 'multi-select' ? 'multiple' : undefined}
                        onChange={(newValue: any) => {
                            handleInput(newValue, inputKey)
                        }}
                        {...(is_dynamic && {
                            removable,
                            onRemove,
                        })}
                        showSearch
                        options={
                            input_details?.mode == 'multi-select'
                                ? input_details.options.map((itm) => ({ value: itm, label: inputKey ==="property_name" ? `${formatString(itm)}` : `${itm}` }))
                                : input_details?.options?.map((option) => ({
                                      value: (input_details?.value_key ? option[input_details.value_key] : option?.value ) || option.m_id,
                                      label: option?.text || option?.business_name || option?.short_name,
                                  }))
                        }
                    />
                        
                )}
            </Tooltip>
        </>
    )
}

export default memo(RenderFilter)
