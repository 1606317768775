import { useEffect, useState } from 'react'
import {
    Form,
    Input,
    Button,
    Select,
    Row,
    Col,
    Alert,
    Divider,
    InputNumber,
    message,
    DeleteOutlined,
} from 'gokwik-ui-kit'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import {
    PaymentRuleConditionOptions,
    PaymentRuleConditionBasedOperators,
    OperatorObjects,
    ProductConditions,
    MatchTypes,
    IndianProvinceCodes,
    PaymentConditionInputTypeAndOptions,
    CountryCodes,
    PaymentRuleConditionsForPlusOnly,
} from './ruleConstant'
import { useSelector } from 'react-redux'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { ICommonEvents } from '@library/utilities/helpers/eventsHelper'

const PaymentRuleForm = (props: {
    setShowModal
    ruleInfo
    userAction
    fetchConfig
    setIsConfigSaved
    events: ICommonEvents
    isNonPlusMerchant: boolean
}) => {
    const { setShowModal, ruleInfo, userAction, fetchConfig, setIsConfigSaved, events, isNonPlusMerchant } = props
    const { add: addConfigEvents, edit: editConfigEvents, discardSave: discardSaveConfigEvent } = events.configsUpsert
    const [form] = Form.useForm()
    const [combinator, setCombinator] = useState(null)
    const [actionField, setActionField] = useState('')
    const [addDescription, setAddDescription] = useState(ruleInfo?.description?.length > 0)

    const maxConditions = 3
    const PaymentRuleArrayInputs = [
        'ZIPCODE',
        'PRODUCT_TAG',
        'CUSTOMER_EMAIL',
        'CUSTOMER_PHONE',
        'COLLECTION_ID',
        'DISCOUNT_CODE',
    ]
    const conditionOptions = isNonPlusMerchant
        ? PaymentRuleConditionOptions.filter((option) => !PaymentRuleConditionsForPlusOnly.includes(option.value))
        : PaymentRuleConditionOptions

    const userDetails = useSelector(getUserDetails)
    const merchantDetails = useSelector(getMerchantDetails)
    const preProcessRuleInfo = (ruleInfo) => {
        const { action, conditions, ...rest } = ruleInfo
        const transformedConditions = conditions?.map((item) => {
            if (PaymentRuleArrayInputs.includes(item.field)) {
                return {
                    field: item?.field,
                    operator: item?.operator,
                    value: item?.value?.join(',')?.trim(),
                    combinator: item?.combinator,
                }
            } else return item
        })
        let transformedAction = action
        if (ruleInfo?.action?.field == 'rename') {
            transformedAction = {
                field: action.field,
                value: action.value.map((item) => {
                    const key = Object.keys(item)[0]
                    return { oldName: key, newName: item[key] }
                }),
            }
        } else if (ruleInfo?.action?.field == 'reorder') {
            transformedAction = {
                field: action.field,
                value: action.value.map((item) => {
                    const key = Object.keys(item)[0]
                    return { method: key, index: item[key] }
                }),
            }
        }
        return {
            ...rest,
            conditions: transformedConditions,
            action: transformedAction,
        }
    }

    const initialValues = () => {
        if (ruleInfo) {
            return preProcessRuleInfo(ruleInfo)
        } else {
            return {
                conditions: [
                    {
                        field: undefined,
                        operator: undefined,
                        value: undefined,
                    },
                ],
                action: {
                    value: [''],
                },
            }
        }
    }

    const addCondition = (newCombinator) => {
        if (!combinator || combinator === newCombinator) {
            setCombinator(newCombinator)
        }
    }

    useEffect(() => {
        if (ruleInfo?.combinator) setCombinator(ruleInfo?.combinator)
        if (ruleInfo?.action.field) setActionField(ruleInfo?.action.field)
    }, [])

    function fireEvent(newConfig: any[], apiStatus: string, errMessage: string) {
        if (apiStatus === 'success') {
            if (userAction?.name === 'add') {
                addConfigEvents.fireSuccessEvent({
                    previousConfig: ruleInfo,
                    currentConfig: newConfig,
                })
            } else {
                editConfigEvents.fireSuccessEvent({
                    previousConfig: ruleInfo,
                    currentConfig: newConfig,
                })
            }
        } else {
            if (userAction?.name === 'add') {
                addConfigEvents.fireFailureEvent({
                    previousConfig: ruleInfo,
                    currentConfig: newConfig,
                    errorMessage: errMessage,
                    apiStatus,
                })
            } else {
                editConfigEvents.fireFailureEvent({
                    previousConfig: ruleInfo,
                    currentConfig: newConfig,
                    errorMessage: errMessage,
                    apiStatus,
                })
            }
        }
    }

    const saveRuleChanges = async (values) => {
        const paymentCustomizationConfig = [
            {
                ...values,
                enabled: values.enabled || true,
                description: addDescription ? values.description : '',
            },
        ]
        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.shopifyShippingAndPaymentRule,
                payload: { paymentCustomizationConfig, paymentCustomizationConfigEnabled: true },
            })
            if (response.data.status_code === 200) {
                setIsConfigSaved(true)
                message.success(`payment rule ${userAction?.name === 'add' ? 'added' : 'updated'} successfully`)
                fetchConfig()
                fireEvent(paymentCustomizationConfig, 'success', '')
            } else {
                fireEvent(
                    paymentCustomizationConfig,
                    'failure',
                    'Payment Customisations API Failed with API Code ' + response.data.status_code,
                )
            }
        } catch (error) {
            fireEvent(paymentCustomizationConfig, 'failure', error?.message || 'Failed to save Payment Customisations')
            console.error('Error:', error)
            message.error(`Failed to ${userAction?.name === 'add' ? 'add' : 'update'} payment rule`)
        }
    }

    const processFormData = (formData) => {
        if (ruleInfo?.id) {
            formData.id = ruleInfo.id
        }

        formData?.conditions?.forEach((element) => {
            if (PaymentRuleArrayInputs?.includes(element?.field)) {
                element.value = element?.value
                    ?.split(',')
                    ?.map((item) => item.trim())
                    ?.filter((item) => item != '')
            }
        })
        let actionValues = formData?.action?.value
        if (formData?.action?.field == 'rename') {
            actionValues = formData?.action?.value?.map((item) => {
                return { [item.oldName]: item.newName }
            })
        } else if (formData?.action?.field == 'reorder') {
            actionValues = formData?.action?.value?.map((item) => {
                return { [item.method]: item.index }
            })
        }

        formData.action.value = actionValues
    }

    const onFinish = (values) => {
        const formData = {
            ...values,
        }
        if (combinator) formData.combinator = combinator
        processFormData(formData)

        const trimmedData = trimValues(formData)
        saveRuleChanges(trimmedData)
        form.resetFields()
        setCombinator(null)
        setShowModal(false)
    }

    const onDiscard = () => {
        form.resetFields()
        setCombinator(null)
        setShowModal(false)
        discardSaveConfigEvent()
    }

    const trimValues = (obj) => {
        if (typeof obj === 'string') {
            return obj.trim()
        } else if (Array.isArray(obj)) {
            return obj.map(trimValues)
        } else if (typeof obj === 'object' && obj !== null) {
            const trimmedObj = {}
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const trimmedKey = key.trim()
                    trimmedObj[trimmedKey] = trimValues(obj[key])
                }
            }
            return trimmedObj
        }
        return obj
    }

    const eligibleOperators = (condition) => {
        let options = []
        PaymentRuleConditionBasedOperators[condition]?.forEach((value, _) => {
            if (OperatorObjects[value]) {
                options.push(OperatorObjects[value])
            }
        })
        return options
    }

    const getInputTypeAndOptions = (condition: string) => {
        return PaymentConditionInputTypeAndOptions[condition] || { InputType: 'String', placeHolder: 'Enter value' }
    }

    return (
        <Form form={form} onFinish={onFinish} layout='vertical' initialValues={initialValues()}>
            <Divider orientation='left' />
            <Form.Item
                name='name'
                label={<span style={{ fontWeight: 'bold' }}>Payment Rule Name</span>}
                rules={[
                    {
                        required: true,
                        message: 'Please enter the payment rule name',
                    },
                ]}
            >
                <Input showCount maxLength={64} disabled={userAction?.name === 'view'} />
            </Form.Item>

            {userAction?.name !== 'view' && (
                <Button
                    type='link'
                    onClick={() => setAddDescription((prev) => !prev)}
                    className='p-0 -mt-4 items-left border-none focus:outline-none'
                >
                    <span className='hover:underline'>{addDescription ? 'Remove' : 'Add a'} description</span>
                </Button>
            )}

            {addDescription && (
                <Form.Item name='description' label={<span style={{ fontWeight: 'bold' }}>Rule Description</span>}>
                    <Input showCount maxLength={100} disabled={userAction?.name === 'view'} />
                </Form.Item>
            )}

            <Divider orientation='left' />

            <div>
                <Form.List
                    name='conditions'
                    rules={[
                        {
                            validator: async (_, condition) => {
                                if (!condition || condition.length <= 1) {
                                    setCombinator(null)
                                }
                            },
                        },
                    ]}
                >
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map(({ key, name, ...restField }, index) => (
                                <Row key={key} gutter={[8, 8]} justify='space-between'>
                                    {index == 0 && (
                                        <Col span={4}>
                                            <span style={{ fontWeight: 'bold' }}>WHEN</span>
                                        </Col>
                                    )}
                                    {index > 0 && (
                                        <Col span={4}>
                                            <span style={{ fontWeight: 'bold' }}>
                                                {combinator == 'AND' ? 'AND' : 'OR'}
                                            </span>
                                        </Col>
                                    )}
                                    <Col span={10}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'field']}
                                            rules={[{ required: true, message: 'Please select a condition' }]}
                                        >
                                            <Select
                                                placeholder='Select Condition'
                                                style={{ width: '100%' }}
                                                options={conditionOptions}
                                                onChange={() => {
                                                    form.setFieldValue(['conditions', name, 'operator'], '')
                                                    form.setFieldValue(['conditions', name, 'value'], undefined)
                                                }}
                                                disabled={userAction?.name === 'view'}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item
                                            noStyle
                                            shouldUpdate={(prevValues, currentvalues) =>
                                                prevValues['conditions'][name] !== currentvalues['conditions'][name]
                                            }
                                        >
                                            {() =>
                                                eligibleOperators(
                                                    form.getFieldValue(['conditions', name, 'field']),
                                                ) && (
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'operator']}
                                                        rules={[
                                                            { required: true, message: 'Please select an operator' },
                                                        ]}
                                                    >
                                                        <Select
                                                            placeholder='Select Operator'
                                                            style={{ width: '100%' }}
                                                            options={eligibleOperators(
                                                                form.getFieldValue(['conditions', name, 'field']),
                                                            )}
                                                            disabled={userAction?.name === 'view'}
                                                        />
                                                    </Form.Item>
                                                )
                                            }
                                        </Form.Item>
                                    </Col>
                                    <Col span={18} offset={4} style={{ marginTop: -10 }}>
                                        <Form.Item
                                            noStyle
                                            shouldUpdate={(prevValues, currentvalues) =>
                                                prevValues['conditions'][name] !== currentvalues['conditions'][name]
                                            }
                                        >
                                            {() => {
                                                const { InputType, placeHolder } = getInputTypeAndOptions(
                                                    form.getFieldValue(['conditions', name, 'field']),
                                                )
                                                return (
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'value']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter a value',
                                                            },
                                                        ]}
                                                    >
                                                        {InputType == 'String[]' && (
                                                            <Input
                                                                placeholder={placeHolder}
                                                                style={{ width: '100%' }}
                                                                disabled={userAction?.name === 'view'}
                                                            />
                                                        )}
                                                        {InputType == 'StateCode[]' && (
                                                            <Select
                                                                mode='multiple'
                                                                placeholder={placeHolder}
                                                                options={IndianProvinceCodes}
                                                                style={{ width: '100%' }}
                                                                disabled={userAction?.name === 'view'}
                                                            />
                                                        )}
                                                        {InputType == 'Country[]' && (
                                                            <Select
                                                                mode='multiple'
                                                                placeholder={placeHolder}
                                                                options={CountryCodes}
                                                                style={{ width: '100%' }}
                                                                disabled={userAction?.name === 'view'}
                                                            />
                                                        )}
                                                        {InputType == 'Number' && (
                                                            <InputNumber
                                                                placeholder={placeHolder}
                                                                style={{ width: '100%' }}
                                                                min={0}
                                                                max={Number.MAX_SAFE_INTEGER}
                                                                disabled={userAction?.name === 'view'}
                                                            />
                                                        )}
                                                        {InputType == 'Boolean' && (
                                                            <Select
                                                                placeholder={placeHolder}
                                                                style={{ width: '100%' }}
                                                                disabled={userAction?.name === 'view'}
                                                            >
                                                                <Select.Option value={true}>True</Select.Option>
                                                                <Select.Option value={false}>False</Select.Option>
                                                            </Select>
                                                        )}
                                                        {InputType == 'String' && (
                                                            <Input
                                                                placeholder={placeHolder}
                                                                style={{ width: '100%' }}
                                                                disabled={userAction?.name === 'view'}
                                                            />
                                                        )}
                                                    </Form.Item>
                                                )
                                            }}
                                        </Form.Item>
                                    </Col>
                                    <Col span={2} style={{ marginTop: -10 }}>
                                        <Form.Item>
                                            <Button
                                                onClick={() => remove(name)}
                                                danger
                                                disabled={index === 0 || userAction?.name === 'view'}
                                            >
                                                <DeleteOutlined />
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                    <Col span={18} offset={4} style={{ marginTop: -10 }}>
                                        <Form.Item
                                            noStyle
                                            shouldUpdate={(prevValues, currentvalues) =>
                                                prevValues['conditions'][name] !== currentvalues['conditions'][name]
                                            }
                                        >
                                            {() =>
                                                ProductConditions.includes(
                                                    form.getFieldValue(['conditions', name, 'field']),
                                                ) && (
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'combinator']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please select a match type',
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            placeholder='Select match type'
                                                            options={MatchTypes}
                                                            style={{ width: '100%' }}
                                                            disabled={userAction?.name === 'view'}
                                                        />
                                                    </Form.Item>
                                                )
                                            }
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ))}

                            <Row justify='end'>
                                <Col>
                                    <Form.Item>
                                        <Button
                                            onClick={() => {
                                                addCondition('AND')
                                                add()
                                            }}
                                            style={{ marginRight: '8px' }}
                                            disabled={
                                                fields?.length >= maxConditions ||
                                                (combinator && combinator !== 'AND') ||
                                                userAction?.name === 'view'
                                            }
                                        >
                                            + Add AND Condition
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                addCondition('OR')
                                                add()
                                            }}
                                            disabled={
                                                fields?.length >= maxConditions ||
                                                (combinator && combinator !== 'OR') ||
                                                userAction?.name === 'view'
                                            }
                                        >
                                            + Add OR Condition
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>

                            {userAction.name != 'view' && form.getFieldValue('conditions')?.length >= maxConditions && (
                                <Alert
                                    message="You're limited to combining a maximum of 3 conditions using 'OR' and 'AND'"
                                    type='info'
                                    showIcon
                                    style={{ textAlign: 'center' }}
                                />
                            )}
                        </>
                    )}
                </Form.List>
            </div>
            <Divider orientation='left' />
            <div>
                <Row gutter={16} justify='space-between'>
                    <Col span={4}>
                        <span style={{ fontWeight: 'bold' }}>THEN</span>
                    </Col>
                    <Col span={20}>
                        <Form.Item
                            name={['action', 'field']}
                            rules={[{ required: true, message: 'Please select an action' }]}
                        >
                            <Select
                                placeholder='Select Specific Action'
                                onChange={(value) => {
                                    setActionField(value)
                                    form.setFieldValue(['action', 'value'], [''])
                                }}
                                disabled={userAction?.name === 'view'}
                            >
                                <Select.Option value='rename'>Rename Payment Methods</Select.Option>
                                <Select.Option value='reorder'>Reorder Payment Methods</Select.Option>
                                <Select.Option value='hide'>Hide Specific Payment Methods</Select.Option>
                                <Select.Option value='hideRestMethods'>
                                    Show Only Specific Payment Methods
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {form.getFieldValue(['action', 'field']) === 'rename' && (
                    <Form.List
                        name={['action', 'value']}
                        rules={[
                            {
                                validator: async (_, condition) => {
                                    if (!condition || condition?.length < 1) {
                                        return Promise.reject(new Error('At least one payment method is required.'))
                                    }
                                },
                            },
                        ]}
                    >
                        {(fields, { add, remove }, { errors }) => (
                            <>
                                {fields.map(({ key, name, ...restField }, index) => (
                                    <Row key={key} gutter={8} justify='space-between'>
                                        <Col span={9} offset={4}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'oldName']}
                                                rules={[
                                                    { required: true, message: 'Please add a payment method' },
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            const allValues = getFieldValue(['action', 'value']) || []
                                                            const oldNames = allValues.map((item) => item.oldName)
                                                            const count = oldNames.reduce((acc, val) => {
                                                                return val === value ? acc + 1 : acc
                                                            }, 0)
                                                            if (count > 1) {
                                                                return Promise.reject(
                                                                    new Error('Payment method already added'),
                                                                )
                                                            } else return Promise.resolve()
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Input
                                                    placeholder='Enter exact payment method name'
                                                    style={{ width: '100%' }}
                                                    disabled={userAction?.name === 'view'}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={9}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'newName']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please add new name',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder='New Name' disabled={userAction?.name === 'view'} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            <Form.Item>
                                                <Button
                                                    onClick={() => remove(name)}
                                                    danger
                                                    disabled={index === 0 || userAction?.name === 'view'}
                                                >
                                                    <DeleteOutlined />
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                ))}

                                <Row justify='end'>
                                    <Col>
                                        <Form.Item>
                                            <Button
                                                onClick={() => {
                                                    add()
                                                }}
                                                disabled={userAction?.name === 'view'}
                                            >
                                                + Add Payment Method
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {form.getFieldError(['action', 'value']).map((error, index) => (
                                    <Alert
                                        key={index}
                                        message={error}
                                        type='error'
                                        showIcon
                                        style={{ textAlign: 'center' }}
                                    />
                                ))}
                            </>
                        )}
                    </Form.List>
                )}
                {form.getFieldValue(['action', 'field']) === 'reorder' && (
                    <Form.List
                        name={['action', 'value']}
                        rules={[
                            {
                                validator: async (_, condition) => {
                                    if (!condition || condition.length < 1) {
                                        return Promise.reject(new Error('At least one payment method is required.'))
                                    }
                                },
                            },
                        ]}
                    >
                        {(fields, { add, remove }, { errors }) => (
                            <>
                                {fields.map(({ key, name, ...restField }, index) => (
                                    <Row key={key} gutter={8} justify='space-between'>
                                        <Col span={9} offset={4}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'method']}
                                                rules={[
                                                    { required: true, message: 'Please add a payment method' },
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            const allValues = getFieldValue(['action', 'value']) || []
                                                            const prevMethods = allValues.map((item) => item.method)
                                                            const count = prevMethods.reduce((acc, val) => {
                                                                return val === value ? acc + 1 : acc
                                                            }, 0)
                                                            if (count > 1) {
                                                                return Promise.reject(
                                                                    new Error('Payment method already added'),
                                                                )
                                                            } else return Promise.resolve()
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Input
                                                    placeholder='Enter exact payment method name'
                                                    style={{ width: '100%' }}
                                                    disabled={userAction?.name === 'view'}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={9}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'index']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please add desired position',
                                                    },
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            const allValues = getFieldValue(['action', 'value']) || []
                                                            const prevIndex = allValues.map((item) => item.index)
                                                            const count = prevIndex.reduce((acc, val) => {
                                                                return val === value ? acc + 1 : acc
                                                            }, 0)
                                                            if (count > 1) {
                                                                return Promise.reject(
                                                                    new Error('Payment method already added'),
                                                                )
                                                            } else return Promise.resolve()
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <InputNumber
                                                    min={0}
                                                    max={10}
                                                    placeholder='Desired Position'
                                                    disabled={userAction?.name === 'view'}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            <Form.Item>
                                                <Button
                                                    onClick={() => remove(name)}
                                                    danger
                                                    disabled={index === 0 || userAction?.name === 'view'}
                                                >
                                                    <DeleteOutlined />
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                ))}

                                <Row justify='end'>
                                    <Col>
                                        <Form.Item>
                                            <Button
                                                onClick={() => {
                                                    add()
                                                }}
                                                disabled={userAction?.name === 'view'}
                                            >
                                                + Add Payment Method
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {form.getFieldError(['action', 'value']).map((error, index) => (
                                    <Alert
                                        key={index}
                                        message={error}
                                        type='info'
                                        showIcon
                                        style={{ textAlign: 'center' }}
                                    />
                                ))}
                            </>
                        )}
                    </Form.List>
                )}
                {form.getFieldValue(['action', 'field']) !== 'rename' &&
                    form.getFieldValue(['action', 'field']) !== 'reorder' && (
                        <Form.List
                            name={['action', 'value']}
                            rules={[
                                {
                                    validator: async (_, condition) => {
                                        if (!condition || condition.length < 1) {
                                            return Promise.reject(new Error('At least one payment method is required.'))
                                        }
                                    },
                                },
                            ]}
                        >
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    {fields.map(({ key, ...restField }, index) => (
                                        <Row key={key} gutter={8} justify='space-between'>
                                            <Col span={18} offset={4}>
                                                <Form.Item
                                                    {...restField}
                                                    rules={[
                                                        { required: true, message: 'Please add a payment method' },
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                const allValues =
                                                                    getFieldValue(['action', 'value']) || []
                                                                const count = allValues.reduce((acc, val) => {
                                                                    return val === value ? acc + 1 : acc
                                                                }, 0)
                                                                if (count > 1) {
                                                                    return Promise.reject(
                                                                        new Error('Payment method already added'),
                                                                    )
                                                                } else return Promise.resolve()
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <Input
                                                        placeholder='Enter exact payment method name'
                                                        style={{ width: '100%' }}
                                                        disabled={userAction?.name === 'view'}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={2}>
                                                <Form.Item>
                                                    <Button
                                                        onClick={() => remove(restField.name)}
                                                        danger
                                                        disabled={index === 0 || userAction?.name === 'view'}
                                                    >
                                                        <DeleteOutlined />
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    ))}

                                    <Row justify='end'>
                                        <Col>
                                            <Form.Item>
                                                <Button
                                                    onClick={() => {
                                                        add()
                                                    }}
                                                    disabled={userAction?.name === 'view'}
                                                >
                                                    + Add Payment Method
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    {form.getFieldError(['action', 'value']).map((error, index) => (
                                        <Alert
                                            key={index}
                                            message={error}
                                            type='info'
                                            showIcon
                                            style={{ textAlign: 'center' }}
                                        />
                                    ))}
                                </>
                            )}
                        </Form.List>
                    )}
            </div>

            <p style={{ textAlign: 'center' }}>
                *Please enter the exact payment method name as shown on the checkout (This is case sensitive)
            </p>

            <Divider orientation='left' />
            <Form.Item>
                <Row justify='end'>
                    <Col>
                        <Button
                            onClick={onDiscard}
                            style={{ marginRight: '8px' }}
                            disabled={userAction?.name === 'view'}
                        >
                            Discard
                        </Button>
                    </Col>
                    <Col>
                        <Button type='primary' htmlType='submit' disabled={userAction?.name === 'view'}>
                            Save Changes
                        </Button>
                    </Col>
                </Row>
            </Form.Item>
        </Form>
    )
}
export default PaymentRuleForm
