export const LEFT_SIDEBAR_MENU_ITEMS = {
    KEYS: {
        KWIKPASS: 'KWIKPASS',
        AUTO_TRIGGER_LOGIN: 'AUTO_TRIGGER_LOGIN',
        MARKETING_POPUP: 'MARKETING_POPUP',
    },
    LABELS: {
        KWIKPASS: 'KwikPass',
        AUTO_TRIGGER_LOGIN: 'Login Popup',
        MARKETING_POPUP: 'Marketing Popup',
    },
}

export const SUB_MENU_ITEMS = {
    KEYS: {
        LOGIN_SCREEN: 'LOGIN_SCREEN',
        OTP_SCREEN: 'OTP_SCREEN',
        COUPON_SCREEN: 'COUPON_SCREEN',
        SPIN_WHEEL: 'SPIN_WHEEL',
    },
    LABELS: {
        LOGIN_SCREEN: 'Login Screen',
        OTP_SCREEN: 'OTP Screen',
        COUPON_SCREEN: 'Coupon Screen',
        SPIN_WHEEL: 'Spin wheel',
    },
}

export const MARKETING_POPUP_TYPES = {
    KEYS: {
        AUTO_LOAD_POPUP2: 'AUTO_LOAD_POPUP2',
        AUTO_LOAD_POPUP3: 'AUTO_LOAD_POPUP3',
        AUTO_LOAD_POPUP4: 'AUTO_LOAD_POPUP4',
        AUTO_LOAD_POPUP5: 'AUTO_LOAD_POPUP5',
        AUTO_LOAD_POPUP6: 'AUTO_LOAD_POPUP6',
    },
    LABELS: {
        AUTO_LOAD_POPUP2: 'Spin the wheel',
        AUTO_LOAD_POPUP3: 'Bottom sheet',
        AUTO_LOAD_POPUP4: 'Centerload popup',
        AUTO_LOAD_POPUP5: 'Popup with Banner',
        AUTO_LOAD_POPUP6: 'Discount popup',
    },
}

export const operations = {
    CREATE: 'CREATE',
    EDIT: 'Edit',
    DELETE: 'Delete',
    DUPLICATE: 'Duplicate',
}

export const rules = {
    timeDelay: 'Time Based',
}
