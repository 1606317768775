import { filterStore } from '@gokwik/utilities'
import { AnyAction, Dispatch, createSlice } from '@reduxjs/toolkit'

type State = {
    orders: any
    abandoned_carts: any
    discount: any
    diy_sets: any
    diy_codes: any
    marketing_analytics: any
}

const initialState: State = {
    orders: {},
    abandoned_carts: {},
    discount: {},
    diy_codes: {},
    diy_sets: {},
    marketing_analytics: {},
}

const filtersSlice = createSlice({
    name: 'filtersSlice',
    initialState,
    reducers: {
        setFilters: (state, action) => ({ ...state, ...action.payload }),
    },
})

export const { setFilters } = filtersSlice.actions
export const initialiseFilters = (data) => {
    setFilters(data)
}
export const saveFiltersData =
    (page: string, data: { [key: string]: any }) => async (dispatch: Dispatch<AnyAction>) => {
        dispatch(setFilters({ [page]: data }))
        filterStore.getState().setFilters({ key: 'checkout', data: { [page]: data } })
    }

export default filtersSlice.reducer
