import { Table } from 'gokwik-ui-kit'
import { awbIngestionColumns } from './awb-ingestion-column-data'
import { AwbFillRate } from '../interface'
import { transformDataSource } from '../helpers'

function AWBIngestionTable({ awbFillRate, loading }: { awbFillRate: AwbFillRate[]; loading: boolean }) {
    return (
        <div className='mt-4'>
            <p className='text-lg font-bold text-center my-4'>AWB Sync & Ingestion Status</p>
            <Table
                columns={awbIngestionColumns}
                dataSource={transformDataSource(awbFillRate)}
                pagination={false}
                bordered={true}
                scroll={{ x: 'max-content' }}
                loading={loading}
            />
        </div>
    )
}

export default AWBIngestionTable
