import React from 'react'
import { Form, Input, Select } from 'gokwik-ui-kit'
import { Condition } from '@library/utilities/interface'

interface WorkflowFormItemProps {
    workflowData: Condition
    itm: {
        type: any
        name: string
        label?: string
    }
    index: number
    onChangeValues: (value: string | number | Date, field: string) => void
    isUtmWorkflow?: boolean
    isShippingWorkflow?: boolean
    filteredOptions?: Array<{ label: string; value: string }>
    shippingOptions?: Array<{ label: string; value: string }>
    handleSearch?: (search: string) => void
}

const WorkflowFormItem: React.FC<WorkflowFormItemProps> = ({
    workflowData,
    itm,
    index,
    onChangeValues,
    isUtmWorkflow = false,
    isShippingWorkflow = false,
    filteredOptions = [],
    shippingOptions = [],
    handleSearch,
}) => {
    const blockInvalidChar = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const invalidChars = ['e', 'E', '+', '-', '.']
        if (invalidChars.includes(e.key)) {
            e.preventDefault()
        }
    }

    const handleInputChange = (e, type: string) => {
        if (type === 'number') {
            if (!e.target.value.match(/[a-zA-Z]/g)) {
                onChangeValues(e, 'value')
            }
        } else if (type === 'date') {
            onChangeValues(new Date(e.target.value), 'value')
        } else {
            onChangeValues(e, 'value')
        }
    }

    const getInitialValue = (): string | undefined => {
        if (itm.type === 'date' && workflowData?.value) {
            return new Date(workflowData.value).toISOString().split('T')[0]
        }
        return workflowData?.value ? String(workflowData.value) : undefined
    }

    const renderUtmSelect = () => (
        <Select
            className='!max-w-[520px]'
            showSearch
            placeholder='Type here...'
            optionFilterProp='label'
            onChange={(value) => {
                onChangeValues(value, 'value')
            }}
            onSearch={handleSearch}
            options={filteredOptions}
            notFoundContent={null}
            dropdownRender={(menu) => {
                return filteredOptions.length > 0 ? menu : null
            }}
            filterSort={false as any}
        />
    )

    const renderShippingSelect = () => (
        <Select
            className='w-60 !max-w-[520px]'
            showSearch
            options={shippingOptions}
            placeholder='Type here...'
            onChange={(value) => {
                onChangeValues(value, 'value')
            }}
        />
    )

    const renderStandardInput = () => (
        <Input
            type={itm.type}
            value={getInitialValue()}
            placeholder='Type here...'
            className='w-36'
            onKeyDown={(e) => (itm.type === 'number' ? blockInvalidChar(e) : undefined)}
            onChange={(e) => handleInputChange(e, itm.type)}
        />
    )

    const renderInput = () => {
        if (isUtmWorkflow) return renderUtmSelect()
        if (isShippingWorkflow) return renderShippingSelect()
        return renderStandardInput()
    }

    return (
        <Form.Item
            className='w-36 mb-0'
            name={`input-value-${workflowData.key}-${index}`}
            rules={[
                {
                    required: true,
                    message: `Please input ${itm.label || 'a value'}`,
                },
            ]}
            initialValue={getInitialValue()}
        >
            {renderInput()}
        </Form.Item>
    )
}

export default WorkflowFormItem
