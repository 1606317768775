import { useAppDispatch } from '@library/utilities/hooks'
import { DiyDiscountListing } from '@library/utilities/interface'
import { deleteDiscount } from '@store/discounts'
import { getMerchantDetails } from '@store/user/selectors'
import { Modal } from 'gokwik-ui-kit'
import { useSelector } from 'react-redux'

const DeleteModal = ({
    show,
    handleClose,
    discount,
}: {
    show: boolean
    handleClose: (refetch?: boolean) => void
    discount: DiyDiscountListing
}) => {
    const merchantId = useSelector(getMerchantDetails)?.m_id
    const dispatch = useAppDispatch()
    if (!discount) return null
    return (
        <Modal
            open={show}
            okButtonProps={{ danger: true }}
            okText='Yes! Delete'
            onCancel={() => handleClose(false)}
            onOk={async () => {
                const res = await dispatch(deleteDiscount(discount, merchantId))
                if (res.success) {
                    handleClose(true)
                }
            }}
            title='Delete Discount Code'
        >
            <p>
                Are you sure you want to delete <span className='font-semibold'>{discount?.title}</span>?
            </p>
        </Modal>
    )
}

export default DeleteModal
