import { useEffect, useState } from 'react'

const BannerPreview = ({ config, banners }) => {
    const [currentIndex, setCurrentIndex] = useState(0)
    const slideInterval = 2000
    const totalSlides = banners?.length
    let intervalId: any

    useEffect(() => {
        if (totalSlides > 1) {
            intervalId = setInterval(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % totalSlides)
            }, slideInterval)
        } else {
            setCurrentIndex(0)
        }
        return () => clearInterval(intervalId) // Cleanup on unmount
    }, [totalSlides])

    return (
        <div className='flex flex-col'>
            <div
                className='text-center mb-2 py-2 w-full'
                style={{
                    backgroundColor: config?.colorConfig?.brandColor,
                    color: config?.colorConfig?.textColor,
                }}
            >
                {banners[currentIndex]}
            </div>
            {totalSlides > 1 && (
                <div className='flex justify-center gap-1'>
                    {banners.map((_, index) => (
                        <div
                            key={index}
                            style={{
                                backgroundColor: `${
                                    currentIndex === index ? config?.colorConfig?.brandColor : '#00000026'
                                }`,
                            }}
                            className='h-1 w-1 rounded-full transition-all duration-300'
                        />
                    ))}
                </div>
            )}
        </div>
    )
}

export default BannerPreview
