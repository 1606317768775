import { updateBreadcrumbs } from "@gokwik/utilities";
import { Form, Input, Select, Switch } from "gokwik-ui-kit";
import { useEffect } from "react";
import { CustomSwitch, SectionHeader } from "..";

const Offers = ({data, handleConfigChange}) => {
  useEffect(() => {}, []);
  const enableDiscountOptions = [
     {value:"ENABLED",label:"Enabled"},
    {value:"DISABLED",label:"Disabled"},
    {value:"READ",label:"Read Only"}
  ]
  return (
    <div className="p-6 pt-0">
      <SectionHeader
        title="Offers & Benefits"
        description="Checkout offers are powered by the merchants/platform."
      />
      <div className="w-1/2 pt-8">
        <div className="text-sm">
          <Form colon={false} layout="vertical">
             <Form.Item
              label="Enable apply discount UI"
              className="inline-block text-sm w-80"
            >
              <div className="text-gray-400 text-xs">
              Enable this if you do not want the users to apply/remove any coupons during the checkout journey
              </div>
              <Select
                options={enableDiscountOptions}
                value={data.discount_options}
                onChange={(e) =>
                  handleConfigChange("offers", "discount_options", e)
                }
                className="mt-2 w-40"
              />
            </Form.Item>
        { data.discount_options === "ENABLED" &&
            <CustomSwitch
              title="Show 1st discount upfront"
              description="To improve convenience, you can enable this to showcase the first applicable discount received in the API."
              value={data.is_one_click_apply_offer_enabled}
              switch_key={["offers","is_one_click_apply_offer_enabled"]}
              handleConfigChange={handleConfigChange}
              isDisabled={false}
            />
           }
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Offers;
