import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAwbFillRate } from '@store/awb-flows'
import { getAwbFillRate } from '@store/awb-flows/selectors'
import { checkAWBFillRateConditions } from '@library/utilities/helpers/helper'

export const useAwbFillRate = (merchantId: string) => {
    const dispatch = useDispatch()
    const { data: awbFillRate, previous_month_awb_fill_rate } = useSelector(getAwbFillRate)
    const [showBoostAWB, setShowBoostAWB] = useState(false)
    const getAwbFillRateAsync = () => {
        //@ts-ignore
        dispatch(fetchAwbFillRate({ params: { merchant_id: merchantId } }))
    }

    useEffect(() => {
        if (!awbFillRate.length) {
            getAwbFillRateAsync()
        }
    }, [])

    useEffect(() => {
        const shouldShowBoostAWB = checkAWBFillRateConditions(previous_month_awb_fill_rate)
        if (shouldShowBoostAWB) {
            setShowBoostAWB(true)
        }
    }, [previous_month_awb_fill_rate])

    return {
        awbFillRate,
        previous_month_awb_fill_rate,
        showBoostAWB
    }
}