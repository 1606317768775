import { Button, Col, ColorPicker, LoadingOutlined, message, Row, Upload, UploadOutlined } from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { deepEqualAndDiff, makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'
import { useSelector } from 'react-redux'
import { getMerchantDetails } from '@store/user/selectors'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import useShopifyMerchantConfigApi from '@library/utilities/hooks/useShopifyMerchantConfig'
import { ICodPageUiCustomisationEvents } from '@library/utilities/helpers/eventsHelper'
import SaveBar from '@library/components/save-bar'
import { logoSources } from '../shopify-app-ui-customisations/gokwik-checkout-ui/constants'
import CodUiPreview from './codUiPreview'

const defaultPaymentUiColorConfig = {
    brandColor: '#000000',
    textColor: '#FFFFFF',
}

const CodPageUiCustomisation = (props: { events: ICodPageUiCustomisationEvents }) => {
    const { logoUploadedEvents, themePrimaryColorSetEvents, themeTextColorSetEvents } = props.events
    const merchantDetails = useSelector(getMerchantDetails)
    const { config, saveConfig, refetch } = useShopifyMerchantConfigApi({
        useCache: true,
    })
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [logoUrl, setLogoUrl] = useState(null)
    const [updatedColorConfig, setUpdatedColorConfig] = useState(defaultPaymentUiColorConfig)
    const [loading, setLoading] = useState(false)
    const timestamp = Date.now()

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'settings',
                href: '/shopify-app/settings',
                text: 'Checkout Settings',
            },
            {
                key: 'ui-customisation',
                href: '/shopify-app/settings/ui-customisation/cod',
                text: 'UI Customisation',
            },
        ])
    }, [])

    useEffect(() => {
        if (config?.newPaymentsUIConfig?.colorConfig) {
            setUpdatedColorConfig(config.newPaymentsUIConfig.colorConfig)
        }
        setLogoUrl(config?.logo)
    }, [config?.logo, config?.newPaymentsUIConfig?.colorConfig])

    const getPresignedUrl = async (logo) => {
        const response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + '/api/dashboard/utility/presigned-url',
            payload: {
                path: `merchant/${merchantDetails?.m_id}/logo${timestamp}.${logo?.name.split('.').pop()}`,
                type: 'logo',
            },
        })
        if (response.success) {
            return response.data.data.url
        } else {
            console.error('getPresignedUrl failed')
        }
    }

    const updateMerchant = async () => {
        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.updateMerchant(merchantDetails?.m_id),
                payload: {
                    logo: logoUrl,
                },
            })
            if (response.success) {
                logoUploadedEvents.fireSuccessEvent({ logo: logoUrl })
                message.success('Logo uploaded successfully')
            } else {
                logoUploadedEvents.fireFailureEvent({
                    errorMessage: 'Failed to update logo in database',
                    logo: logoUrl,
                })
                message.error('Logo upload failed')
            }
        } catch (error) {
            console.error(error)
            logoUploadedEvents.fireFailureEvent({
                errorMessage: error?.message || 'Failed to update logo in database',
                logo: logoUrl,
            })
        }
    }

    const uploadLogo = async (logo) => {
        const signedURl = await getPresignedUrl(logo)
        try {
            const awsResponse = await fetch(signedURl, {
                method: 'PUT',
                body: logo,
            })

            if (awsResponse.status == 200) {
                setLogoUrl(`merchant/${merchantDetails?.m_id}/logo${timestamp}.${logo?.name.split('.').pop()}`)
                setUnsavedChanges(true)
            } else {
                message.error('Logo upload failed')
            }
        } catch (error) {
            console.error(error)
            message.error('Logo upload failed')
        }
    }

    const handleDiscard = () => {
        setLogoUrl(config?.logo)
        setUpdatedColorConfig(config?.newPaymentsUIConfig?.colorConfig ?? defaultPaymentUiColorConfig)
        setUnsavedChanges(false)
    }

    const handleSave = async () => {
        if (logoUrl !== config?.logo) {
            await updateMerchant()
        }

        if (!deepEqualAndDiff(config?.newPaymentsUIConfig?.colorConfig, updatedColorConfig)?.isEqual) {
            const brandColorUpdated =
                updatedColorConfig.brandColor !== config?.newPaymentsUIConfig?.colorConfig?.brandColor
            const textColorUpdated =
                updatedColorConfig.textColor !== config?.newPaymentsUIConfig?.colorConfig?.textColor

            await saveConfig(
                {
                    newPaymentsUIConfig: {
                        ...config?.newPaymentsUIConfig,
                        colorConfig: updatedColorConfig,
                    },
                },
                () => {
                    if (brandColorUpdated)
                        themePrimaryColorSetEvents.fireSuccessEvent({ primaryColor: updatedColorConfig.brandColor })
                    if (textColorUpdated)
                        themeTextColorSetEvents.fireSuccessEvent({ textColor: updatedColorConfig.textColor })
                },
            )
        } else {
            refetch()
        }
        setUnsavedChanges(false)
    }

    //validate logo size
    const validateLogoSize = (file) => {
        const maxSizeInBytes = 128 * 1024 // 128KB in bytes
        const isLt128KB = file.size < maxSizeInBytes
        if (!isLt128KB) {
            message.error('File must be upto 128KB!')
        }
        return isLt128KB
    }

    const handleLogoUploadChange = (info) => {
        if (info.file?.status === 'uploading') {
            setLoading(true)
            return
        }
        if (info.file?.status === 'done') {
            setLoading(false)
            uploadLogo(info.file?.originFileObj)
        }
    }

    return (
        <div className='px-2'>
            {unsavedChanges && <SaveBar handleDiscard={handleDiscard} handleSave={handleSave} />}
            <Row className='rounded-sm' gutter={16} justify={'space-between'}>
                <Col className='rounded-lg' span={16}>
                    <div className='py-2 ml-2 flex items-center justify-between'>
                        <h2 className='text-lg font-semibold'>COD Page UI</h2>
                    </div>

                    {/* Logo Upload Section */}
                    <div className='mb-6 bg-white p-3 rounded-lg shadow-md mx-auto'>
                        <label className='block text-base mb-2 font-semibold'>Brand Logo</label>
                        <p className='text-gray-500 text-xs mb-2'>
                            You can upload files up to 128KB. Consider using SVG files instead of JPG or PNG for their
                            scalability and smaller size.
                        </p>
                        <Upload
                            className='mx-50'
                            maxCount={1}
                            multiple={false}
                            accept='.jpeg,.png,.jpg,.svg'
                            showUploadList={false}
                            beforeUpload={validateLogoSize}
                            customRequest={(info) => {
                                info.onSuccess('')
                            }}
                            onChange={handleLogoUploadChange}
                        >
                            <Button disabled={loading}>
                                {loading ? <LoadingOutlined /> : <UploadOutlined />} Upload Logo
                            </Button>
                        </Upload>
                        {logoUrl && (
                            <div className='mt-2 mb-2'>
                                <img
                                    src={logoSources(logoUrl)[0]}
                                    alt='logo'
                                    height={80}
                                    width={80}
                                    onError={(e) => {
                                        if (e.target instanceof HTMLImageElement) {
                                            const img = e.target
                                            const currentSrc = img.src
                                            const currentIndex = logoSources(logoUrl).indexOf(currentSrc)
                                            if (logoUrl && currentIndex < 3) {
                                                img.src = logoSources(logoUrl)[currentIndex + 1]
                                            }
                                        }
                                    }}
                                />
                            </div>
                        )}
                    </div>

                    {/* Theme Section */}
                    <div className='mb-6 bg-white p-3 rounded-lg shadow-md  mx-auto'>
                        <p className='text-base font-semibold mb-2'>Theme</p>
                        <div className='flex flex-row space-x-4'>
                            <div className='mb-4 mr-5'>
                                <label className='block mb-1 text-sm font-medium text-gray-700'>Primary Color</label>
                                <ColorPicker
                                    value={updatedColorConfig.brandColor}
                                    onChange={(color) => {
                                        setUpdatedColorConfig((prev) => ({
                                            ...prev,
                                            brandColor: color.toHexString(),
                                        }))
                                        setUnsavedChanges(true)
                                    }}
                                    showText
                                    input
                                />
                            </div>
                            <div>
                                <label className='block mb-1 text-sm font-medium text-gray-700'>Text Color</label>
                                <ColorPicker
                                    value={updatedColorConfig.textColor}
                                    onChange={(color) => {
                                        setUpdatedColorConfig((prev) => ({
                                            ...prev,
                                            textColor: color.toHexString(),
                                        }))
                                        setUnsavedChanges(true)
                                    }}
                                    showText
                                    input
                                />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={8}>
                    <CodUiPreview newPaymentsUiColorConfig={updatedColorConfig} logo={logoUrl} />
                </Col>
            </Row>
        </div>
    )
}

export default CodPageUiCustomisation
