import {
    ColumnsType,
    DeleteOutlined,
    DownloadOutlined,
    Drawer,
    Dropdown,
    EditOutlined,
    FileOutlined,
    Form,
    InfoCircleOutlined,
    Input,
    Popover,
    Radio,
    Switch,
    Table,
    Tag,
    TextArea,
    Tooltip,
} from 'gokwik-ui-kit'
import threeDotsIcon from '@library/images/three-dots.svg'
import { useEffect, useState } from 'react'
import ChangeDiscountDescription from '@library/components/discounts/changeDescription'
import { useAppDispatch } from '@library/utilities/hooks'
import {
    fetchDiscountDataAsync,
    fetchManualDiscountsDataAsync,
    saveDiscount,
    handleDownloadBuk,
    emptyDiscountData,
} from '@store/discounts'
import { useSelector } from 'react-redux'
import { selectManualDiscounts, selectBulkDiscounts } from '@store/discounts/selectors'
import { Link } from 'react-router-dom'
import { DiyDiscountListing } from '@library/utilities/interface'
import { previousDiscountCodesFilters, previousDiscountSetsFilters } from '@store/filters/selectors'
import { convertIsoToLocaleString, updateBreadcrumbs } from '@gokwik/utilities'
import RenderSearchFilters from '@library/components/search-filter'
import { saveFiltersData } from '@store/filters'
import { getMerchantDetails } from '@store/user/selectors'
import DeleteModal from '@library/components/discounts/deleteModal'

const DiscountsComponent = ({ isBulkDiscount }) => {
    const [changeDescriptionDiscount, setChangeDescriptionDiscount] = useState<DiyDiscountListing>(null)
    const [deleteModalDiscount, setDeleteModalDiscount] = useState<DiyDiscountListing>(null)
    const dispatch = useAppDispatch()
    const merchantId = useSelector(getMerchantDetails)?.m_id
    const discounts = useSelector(isBulkDiscount ? selectBulkDiscounts : selectManualDiscounts) || {
        total: 0,
        discounts: [],
    }
    const prevFilters = useSelector(isBulkDiscount ? previousDiscountSetsFilters : previousDiscountCodesFilters)
    const [parameters, setParameters] = useState({
        page: 1,
        pageSize: 25,
        bulkDiscount: isBulkDiscount,
        ...prevFilters,
    })

    const fetchDiscounts = (params) => {
        dispatch(
            fetchManualDiscountsDataAsync({
                params: {
                    ...params,
                    searchTags: params.searchTags?.join(','),
                },
            }),
        )

        dispatch(saveFiltersData(isBulkDiscount ? 'diy_sets' : 'diy_codes', params))
    }

    const handlePaginationChange = (current: number, pageSize?: number) => {
        setParameters((prev) => ({ ...prev, page: current, pageSize }))
        fetchDiscounts({ ...parameters, page: current, pageSize })
    }

    const reset = (e) => {
        e.preventDefault()
        const newParams = {
            page: 1,
            pageSize: 25,
            bulkDiscount: isBulkDiscount,
        }
        setParameters({ ...newParams })
        fetchDiscounts(newParams)
    }

    useEffect(() => {
        fetchDiscounts({
            page: 1,
            pageSize: 25,
            bulkDiscount: isBulkDiscount,
            ...parameters,
        })
    }, [isBulkDiscount])

    const tableColumns: any = [
        {
            title: 'Code',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Tags',
            dataIndex: 'tags',
            key: 'tags',
            render: (text, record) => (
                <>
                    {record.tags?.map?.((tag) => (
                        <Tag key={tag}>{tag}</Tag>
                    ))}
                </>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (text: string, record) => {
                const isDisabled = record.discount_method === 'automatic' || ['EXPIRED', 'DRAFT'].includes(text)
                return (
                    <div className='flex justify-center items-center'>
                        <Switch
                            disabled={isDisabled}
                            onChange={async (checked) => {
                                await dispatch(
                                    saveDiscount({ ...record, status: checked ? 'ACTIVE' : 'INACTIVE' }, 'listing'),
                                )
                                fetchDiscounts({ ...parameters })
                            }}
                            checked={text === 'ACTIVE' || text === 'SCHEDULED'}
                        />

                        <span className='ml-2 text-left w-20 block capitalize'>
                            {text.toLowerCase()}

                            {isDisabled && (
                                <Tooltip
                                    title={`Status can only be changed for active/inactive ${
                                        isBulkDiscount ? 'bulk' : 'manual'
                                    }  discounts`}
                                >
                                    <InfoCircleOutlined className='text-gray-400 ml-1' />
                                </Tooltip>
                            )}
                        </span>
                    </div>
                )
            },
        },
        ...(isBulkDiscount
            ? [
                  {
                      title: 'Number of Codes',
                      dataIndex: 'number_of_codes',
                      key: 'number_of_codes',
                      align: 'right',
                      render: (text: string, record: DiyDiscountListing) => <span>{record.bulk_campaign?.count}</span>,
                  },
                  {
                      title: <span>Created At</span>,
                      dataIndex: 'created_at',
                      align: 'center',
                      render: (text: string) => <span>{text ? convertIsoToLocaleString(text) : ''}</span>,
                  },
                  {
                      title: 'End Date',
                      dataIndex: 'end_date',
                      align: 'center',
                      render: (text: string, record) => (
                          <span>
                              {record.bulk_campaign?.end_date
                                  ? convertIsoToLocaleString(record.bulk_campaign?.end_date)
                                  : ''}
                          </span>
                      ),
                  },
              ]
            : [
                  {
                      title: 'Visibility',
                      dataIndex: 'is_visible',
                      key: 'is_visible',
                      align: 'center',
                      render: (text, record: DiyDiscountListing) => {
                          const isDisabled =
                              record.discount_method === 'automatic' ||
                              ['EXPIRED', 'DRAFT'].includes(record.status) ||
                              !record.view_offer_details?.discount_detail ||
                              !record.view_offer_details?.visibility
                          return (
                              <div className='flex justify-center items-center'>
                                  <Switch
                                      disabled={isDisabled}
                                      onChange={async (checked) => {
                                          await dispatch(saveDiscount({ ...record, is_visible: checked }, 'listing'))
                                          fetchDiscounts({ ...parameters })
                                      }}
                                      checked={text}
                                  />

                                  <span className='ml-2 text-left w-24 block'>
                                      {text ? 'Active' : 'Inactive'}

                                      {isDisabled && (
                                          <Tooltip title='Visibility can only be changed for active manual discounts with visibility details'>
                                              <InfoCircleOutlined className='text-gray-400 ml-1' />
                                          </Tooltip>
                                      )}
                                  </span>
                              </div>
                          )
                      },
                  },
              ]),
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                const isAutoDiscount = !isBulkDiscount && record.discount_method === 'automatic'
                const isCompleted =
                    isBulkDiscount &&
                    record.bulk_campaign &&
                    record.bulk_campaign.job_status?.toLowerCase() === 'completed'
                return (
                    <Dropdown
                        menu={{
                            items: [
                                {
                                    key: '1',
                                    label: (
                                        <Link
                                            to={`edit/${
                                                isBulkDiscount ? 'bulk' : isAutoDiscount ? 'automatic' : 'manual'
                                            }/${record.id}`}
                                            className='flex gap-x-1 items-center hover:text-primary-500'
                                        >
                                            <EditOutlined /> Edit Rules
                                        </Link>
                                    ),
                                },
                                ...(isBulkDiscount
                                    ? [
                                          {
                                              key: '2',
                                              label: (
                                                  <Tooltip
                                                      className={`${
                                                          isCompleted ? ' cursor-pointer' : 'cursor-not-allowed'
                                                      }`}
                                                      placement='topLeft'
                                                      title={
                                                          isCompleted
                                                              ? 'Download'
                                                              : 'Download not available, record is being processed'
                                                      }
                                                  >
                                                      <span
                                                          className={`flex gap-x-1 items-center ${
                                                              isCompleted
                                                                  ? 'cursor-pointer text-primary-450'
                                                                  : 'cursor-not-allowed text-gray-400'
                                                          }`}
                                                      >
                                                          <DownloadOutlined
                                                              onClick={() =>
                                                                  isCompleted && handleDownloadBuk(record, merchantId)
                                                              }
                                                          />
                                                          Download
                                                      </span>
                                                  </Tooltip>
                                              ),
                                          },
                                      ]
                                    : !isAutoDiscount
                                    ? [
                                          {
                                              key: '2',
                                              label: (
                                                  <span
                                                      onClick={() => setChangeDescriptionDiscount(record)}
                                                      className='flex gap-x-1 items-center hover:text-primary-500'
                                                  >
                                                      <FileOutlined /> Change Description
                                                  </span>
                                              ),
                                          },
                                      ]
                                    : []),
                                {
                                    key: '3',
                                    label: (
                                        <span
                                            onClick={() => setDeleteModalDiscount(record)}
                                            className='flex gap-x-1 items-center text-error-500'
                                        >
                                            <DeleteOutlined /> Delete
                                        </span>
                                    ),
                                },
                            ],
                        }}
                    >
                        <img src={threeDotsIcon} alt='three dots' />
                    </Dropdown>
                )
            },
        },
    ]

    return (
        <div className='w-full'>
            <RenderSearchFilters
                values={parameters}
                setValues={(data, reset) => {
                    setParameters((prev) => (reset ? { ...data } : { ...prev, ...data }))
                }}
                page={isBulkDiscount ? 'diy_sets' : 'diy_codes'}
                onSearch={() => fetchDiscounts({ ...parameters, page: 1 })}
                onReset={reset}
                sixMonthsRange={true}
            />
            <Table
                columns={tableColumns as any}
                dataSource={discounts.discounts}
                pagination={{
                    current: parameters.page,
                    pageSize: parameters.pageSize,
                    total: discounts.total,
                    showSizeChanger: false,
                    onChange: handlePaginationChange,
                    showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
                }}
                rowKey='id'
                scroll={{ x: '100%' }}
            />
            {changeDescriptionDiscount && (
                <ChangeDiscountDescription
                    changeDescriptionDiscount={changeDescriptionDiscount}
                    onClose={(refetch?: boolean) => {
                        refetch && fetchDiscounts({ ...parameters })
                        setChangeDescriptionDiscount(null)
                    }}
                />
            )}
            <DeleteModal
                show={!!deleteModalDiscount}
                handleClose={(refetch: boolean) => {
                    if (refetch) fetchDiscounts({ ...parameters })
                    setDeleteModalDiscount(null)
                }}
                discount={deleteModalDiscount}
            />
        </div>
    )
}

export default DiscountsComponent
