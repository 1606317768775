import { useState, ReactNode, useMemo, useRef, useEffect } from 'react'
import { Layout, Menu, Select } from 'gokwik-ui-kit'
import { useNavigate, Routes, Route, useLocation } from 'react-router-dom'
import Pincode from '@pages/settings/pincode-settings'
import Shipping from '@pages/settings/shipping-settings'
import CheckoutConfigs from './checkout-configs'
import { useSelector } from 'react-redux'
import { getMerchantDetails, getUserConfig, getUserDetails, isMerchantSelector } from '@store/user/selectors'
import OffersDiscount from './offers-discount'
import { DiscountConfigKeys, stepConfiguration } from '@library/utilities/constants/constants'
import { titleCase } from '@gokwik/utilities'
import { logEvent } from '@library/utilities/userLogEvents/userLogEvents'
import BulkDiscounts from './bulk-discounts'
import Discounts from './discounts'
import ManualDiscounts from './discounts/discounts'
import CreateManualDiscounts from './discounts/create-discounts/manual'
import CreateBulkDiscounts from './discounts/create-discounts/bulk'
import CreateAutomaticDiscounts from './discounts/create-discounts/automatic'

const { Sider } = Layout

interface MenuItem {
    key: string
    label: ReactNode
    icon?: ReactNode
    children?: MenuItem[]
    type?: 'group'
    className?: string
}

function createMenuItem(
    label: ReactNode,
    key: string,
    className?: string,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        children,
        label,
        type,
        className,
    }
}
export default function () {
    const merchant_details = useSelector(getMerchantDetails)
    const config = useSelector(getUserConfig)
    const route = useLocation()
    const navigate = useNavigate()
    const previousOpenedItems = useRef({
        openedKey: '',
        selectedKey: '',
    })
    const isMerchantUser = useSelector(isMerchantSelector)
    const user_details = useSelector(getUserDetails)

    const [checkoutPlatform, setCheckoutPlatform] = useState('checkout_1')
    const showDiscountConfigs = useMemo(() => Object.values(DiscountConfigKeys).some((key) => config[key]), [config])
    const checkoutPlatformKeys = useMemo(
        () =>
            Object.entries(config?.supported_checkout_configs || {}).reduce(
                (result: string[], item: [string, boolean]) =>
                    item[1] && item[0] !== 'custom' ? [...result, item[0]] : result,
                [],
            ),
        [config],
    )
    const settingsList = useMemo(
        () => [
            {
                title: 'Pincode Serviceability',
                path: '/pincode',
                component: () => <Pincode checkoutPlatform={checkoutPlatform} />,
            },
            {
                title: 'Shipping Serviceability',
                path: '/shipping',
                component: Shipping,
            },
            ...(!isMerchantUser || showDiscountConfigs
                ? [
                      {
                          title: 'Discount Configs',
                          path: '/discount-configs',
                          component: CheckoutConfigs,
                      },
                  ]
                : []),
            ...(config?.diy_enabled
                ? [
                      {
                          title: 'Discounts',
                          path: '/discounts',
                          component: Discounts,
                      },
                      {
                          title: 'Manual Discounts',
                          path: '/discounts/create/manual',
                          component: CreateManualDiscounts,
                          hideInMenu: true,
                      },
                      {
                          title: 'Edit Manual Discounts',
                          path: '/discounts/edit/manual/:id',
                          component: CreateManualDiscounts,
                          hideInMenu: true,
                      },
                      {
                          title: 'Bulk Discounts',
                          path: '/discounts/create/bulk',
                          component: CreateBulkDiscounts,
                          hideInMenu: true,
                      },
                      {
                          title: 'Bulk Discounts',
                          path: '/discounts/edit/bulk/:id',
                          component: CreateBulkDiscounts,
                          hideInMenu: true,
                      },
                      {
                          title: 'Automatic Discounts',
                          path: '/discounts/create/automatic',
                          component: CreateAutomaticDiscounts,
                          hideInMenu: true,
                      },
                      {
                          title: 'Automatic Discounts',
                          path: '/discounts/edit/automatic/:id',
                          component: CreateAutomaticDiscounts,
                          hideInMenu: true,
                      },
                  ]
                : [
                      {
                          title: 'Bulk Discounts Setup',
                          path: '/bulk-discounts',
                          component: BulkDiscounts,
                      },
                  ]),
            {
                title: 'Offers Visibility Setup',
                path: '/offers-discounts',
                component: OffersDiscount,
            },
        ],
        [checkoutPlatform, showDiscountConfigs, config],
    )
    const menuItems = () => {
        const items = settingsList.reduce(
            (result, item) =>
                item.hideInMenu ? result : [...result, createMenuItem(item.title, `/checkout/settings${item.path}`)],
            [],
        )
        return items
    }

    const openItems = useMemo(() => {
        const menu = menuItems()

        const openedKey = menu.find((item) => {
            if (item.key === route.pathname) {
                return true
            }
            if (item.children) {
                return item.children.some((child) => child.key === route.pathname)
            }
            return false
        })

        if (openedKey) {
            previousOpenedItems.current = {
                openedKey: openedKey.key,
                selectedKey: route.pathname,
            }
            return {
                openedKey: openedKey.key,
                selectedKey: route.pathname,
            }
        } else if (previousOpenedItems.current.openedKey) {
            return {
                ...previousOpenedItems.current,
            }
        } else {
            let similarSelectedKey: MenuItem | undefined
            const similarKey = menu.find(
                (item) =>
                    !!item.children?.find((child) => {
                        if (route.pathname.includes(child.key)) {
                            similarSelectedKey = child
                            return true
                        } else return false
                    }),
            )

            previousOpenedItems.current = {
                openedKey: similarKey?.key || '',
                selectedKey: similarSelectedKey?.key || '',
            }
            return {
                openedKey: similarKey?.key || '',
                selectedKey: similarSelectedKey?.key || '',
            }
        }
    }, [route.pathname, settingsList, checkoutPlatform, showDiscountConfigs])

    const eventMappings: Record<string, string> = {
        '/checkout/settings/pincode': 'pincode_serviceability_clicked',
        '/checkout/settings/shipping': 'shipping_serviceability_clicked',
    }

    const handleMenuSelection = ({ key }: any) => {
        const eventName = eventMappings[key]

        if (key === '/checkout/settings/discount-configs' || key === '/checkout/settings/bulk-discounts') {
            logEvent(
                eventName,
                'click',
                'pincode service',
                user_details.email,
                merchant_details.m_id,
                merchant_details.short_name,
                user_details.name,
            )
        }
        navigate(key)
    }

    useEffect(() => {
        if (route.pathname === '/checkout/settings') navigate('/checkout/settings' + settingsList[0].path)
    }, [route.pathname])

    return (
        <div className='w-full bg-white'>
            <Layout className='h-full'>
                {(stepConfiguration[merchant_details?.onboarding_status?.kwik_checkout_app]?.id >=
                    stepConfiguration['imp_checks_passed']?.id ||
                    !merchant_details?.onboarding_status?.kwik_checkout_app) && (
                    <Sider
                        className='bg-white h-full mr-4 border-0 border-gray-100 border-r-2 fixed overflow-hidden'
                        style={{ background: 'white' }}
                    >
                        {' '}
                        {checkoutPlatformKeys.length > 1 && (
                            <Select
                                className='w-full mb-4'
                                value={checkoutPlatform}
                                onChange={(value) => {
                                    localStorage.setItem('checkoutPlatform', value)
                                    setCheckoutPlatform(value)
                                    if (value === 'kwik_checkout') navigate(`/shopify-app/settings`)
                                }}
                                options={checkoutPlatformKeys.map((item: string) => ({
                                    label: titleCase(item.split('_').join(' ')),
                                    value: item,
                                }))}
                            />
                        )}
                        <div className='setting-menu-custom-css'>
                            <Menu
                                onClick={handleMenuSelection}
                                defaultOpenKeys={[openItems.openedKey]}
                                defaultSelectedKeys={[openItems.selectedKey]}
                                key={`${route.pathname}-${openItems.openedKey}-${openItems.selectedKey}`}
                                items={menuItems()}
                                inlineCollapsed={true}
                                mode='inline'
                            />
                        </div>
                    </Sider>
                )}
                <Layout className='overflow-y-auto overflow-x-hidden'>
                    <Routes>
                        {settingsList.map((item, index) => (
                            <Route key={index} path={item.path} element={<item.component />} />
                        ))}
                    </Routes>
                </Layout>
            </Layout>
        </div>
    )
}
