import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { calculateRTOSummaryMetrics, parseMetrics } from '@library/utilities/helpers/calculate_metrics'
import { message } from 'gokwik-ui-kit'
  
export const analyticsAPIs: {
    [key: string]: {
        url: string
        errMsg: string
        nestedKeys?: string[]
        methodType?: 'get' | 'post'
        defaultParams?: { [key: string]: any }
        defaultBody?: { [key: string]: any }
        headers?: object
        formatData?: (data: any) => any
    }
} = {
    rtoInsights: {
        url: APIEndPoints.getRTOAnalyticsData,
        errMsg: 'Unable to fetch RTO Summary Data',
        defaultParams: {
            rto_segment: 'all',
            filter_type: 'risk_flag',
        },
        methodType: 'get',
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
        formatData: parseMetrics,
    },
    decileLevelMetrics: {
        url: APIEndPoints.getRTOAnalyticsData,
        errMsg: 'Unable to fetch RTO Decile Level Data',
        defaultParams: {
            rto_segment: 'all',
            filter_type: 'decile_level',
        },
        methodType: 'get',
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
        formatData: parseMetrics,
    }
}

export const fetchAnalyticsData = async ({
    key,
    params,
    payload,
    nestedKey,
}: {
    key: string
    nestedKey?: string
    params?: { [key: string]: any }
    payload?: { [key: string]: any }
    retryCount?: number
}) => {
    let { url, nestedKeys, defaultParams, methodType, defaultBody, headers, formatData } = analyticsAPIs[key]
    let responseReceived = false
    const intervalId = setInterval(() => {
        if (!responseReceived) {
            message.info({
                content: `Hang tight, we're working on it`,
                duration: 2,
            })
        }
    }, 3000)
    try {
        let response = await makeAPICall({
            method: methodType,
            url: process.env.RTO_DASHBOARD_SERVICE_URL + url,
            params: {
                ...(defaultParams || {}),
                ...(params || {}),
            },
            skipLoader: true,
            skipMode: true,
            headers: {
                ...headers,
            },
        })
        responseReceived = true
        clearInterval(intervalId)
        if (response.success) {
            if (response.data.statusCode === 500) {
                message.warning(`We're having trouble loading this right now. Please try again later.`)
                return { error: true }
            }
            let processedData = {
                fromDate: params.from_date,
                toDate: params.to_date,
                data: response.data.data,
            }

            if (formatData) {
                processedData = formatData(processedData)
            }

            return {
                success: true,
                data: processedData,
            }
        }
        return { error: true }
    } catch (error) {
        console.log('Error', error)
        responseReceived = true
        clearInterval(intervalId)
        throw error
    }
}
