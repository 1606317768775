import { useState } from 'react'
import { Modal } from 'gokwik-ui-kit'
import useShopifyMerchantConfigApi from '@library/utilities/hooks/useShopifyMerchantConfig';
import { RateComponent } from './RateComponent'
import { ReviewModal } from './ReviewModal'
export const RatingModal = ({ isModalVisible, setIsModalVisible, events }) => {
    const [rating, setRating] = useState(0)
    const [isReviewModalVisible, setIsReviewModalVisible] = useState(false)
    const [review, setReview] = useState('')
    const [error, setError] = useState('')
    const [isReviewSubmitted, setIsReviewSubmitted] = useState(false);
    const { sendRatingFeedback } = useShopifyMerchantConfigApi()
    const handleCancelClick = () => {
        setIsModalVisible(false)
        localStorage.removeItem('isFromOnboarding')
    }
    const handleRateChange = (value: number) => {
        events.fireClickedEvent({
            event: 'rating-change-clicked',
            Rating: value,
            ratingSource: 'onboarding-flow',
        })
        //hiding the rating modal before redirection or review modal.
        setIsModalVisible(false)
        localStorage.removeItem('isFromOnboarding')
        if (value <= 4) {
            setRating(value)
            setIsReviewModalVisible(true)
        } else if (value === 5) {
            setIsReviewModalVisible(false)
            window.open('https://apps.shopify.com/kwikcheckout#modal-show=WriteReviewModal', '_self')
        }
    }
    const handleReviewCancelClick = async () => {
        setIsReviewModalVisible(false)
        setRating(0)
        events.fireClickedEvent({
            event: 'rating-cancelled-clicked',
            Rating: rating,
            ratingSource: 'onboarding-flow',
        })
        await sendRatingFeedback(review,rating,false,setReview,setIsReviewSubmitted,events,"onboarding-flow")
    }
    const handleReviewSubmitClick = async () => {
        if (review?.length && review?.length < 30) {
            setError('Minimum character limit is 30.')
        } else {
            setError('')
            await sendRatingFeedback(review,rating,false,setReview,setIsReviewSubmitted,events,"onboarding-flow")
            setIsReviewModalVisible(false)
            setRating(0)
        }
    }
    return (
        <>
            <Modal centered open={isModalVisible} onCancel={handleCancelClick} closable zIndex={1002} footer={null}>
                <div className='text-center fs20' style={{ marginBottom: '10px' }}>
                    <strong>Congratulations ! COD is live on your store.</strong>
                    <p style={{ marginBottom: '10px' }}>Please take a moment to rate us 5 star on shopify.</p>
                    <RateComponent rating={rating} onRateChange={handleRateChange} />
                </div>
            </Modal>
            <ReviewModal
                visible={isReviewModalVisible}
                review={review}
                onReviewChange={(trimmedReview: string) => setReview(trimmedReview)}
                onCancel={handleReviewCancelClick}
                onSubmit={handleReviewSubmitClick}
                error={error}
                zIndex={1002}
            />
        </>
    )
}
