import { useState } from 'react'
import { DownOutlined, ArrowLeftOutlined } from 'gokwik-ui-kit'
import bag from '@library/images/bag.svg'
import stars from '@library/images/stars.svg'
import totalSavings from '@library/images/totalSavings.svg'
import flagOfIndia from '@library/images/flag-of-india.svg'
import privacy from '@library/images/privacy.svg'
import footerText from '@library/images/footer-text-gk.svg'
import gokwikLogo from '@library/images/common/logo-gokwik-business-suit.svg'
import { IShopifyMerchantConfig, IShopifyNewCheckoutUIConfig } from '@library/utilities/interface'
import { logoSources } from './constants'
import BannerPreview from './bannerSlider'

interface Props {
    newCheckoutUIConfig: IShopifyNewCheckoutUIConfig
    config: IShopifyMerchantConfig
}
const LoginPagePreview = ({ newCheckoutUIConfig, config }: Props) => {
    const banners = newCheckoutUIConfig?.textConfig?.otherSections?.brandBanner?.filter((banner) => banner.length) || []

    return (
        <div>
            <div className='text-2xl text-center font-semibold p-2'>Preview</div>
            <div className='bg-gray-200 shadow-md p-2 rounded-t-xl max-w-md w-full'>
                <div className='relative w-full flex items-center py-2'>
                    <div className='absolute left-1 top-4'>
                        <ArrowLeftOutlined />
                    </div>
                    <div className='mx-auto'>
                        <img
                            src={logoSources(config?.logo)[0]}
                            alt='logo'
                            height={40}
                            width={60}
                            onError={(e) => {
                                if (e.target instanceof HTMLImageElement) {
                                    const img = e.target
                                    const currentSrc = img.src
                                    const currentIndex = logoSources(config?.logo).indexOf(currentSrc)
                                    if (config?.logo && currentIndex < 3) {
                                        img.src = logoSources(config.logo)[currentIndex + 1]
                                    } else {
                                        img.src = gokwikLogo
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                {newCheckoutUIConfig?.uiConfig?.showBrandBanner && banners.length > 0 && (
                    <div className='-ml-2 -mr-2 mb-2'>
                        <BannerPreview config={newCheckoutUIConfig} banners={banners} />
                    </div>
                )}
                <div className='bg-white rounded-xl p-4 mb-4'>
                    <div className='flex items-center justify-between'>
                        <p className='text-normal font-semibold'>
                            <img src={bag} height={'20'} alt='bag' className='mr-2' />
                            Order Summary
                        </p>
                        <p className='text-gray-700'>
                            3 items (₹300.00) <DownOutlined />
                        </p>
                    </div>
                </div>
                <div className='bg-white rounded-xl p-4 mb-4'>
                    <div className='flex items-center justify-between'>
                        <p className='text-normal font-semibold'>
                            {' '}
                            <img src={totalSavings} height={'20'} alt='coupons' className='mr-2' />
                            Coupons/Gift Cards
                            <img src={stars} height={'20'} alt='stars' className='ml-2' />
                        </p>
                        <DownOutlined />
                    </div>
                </div>

                {/* phone number section */}
                <div className='flex flex-col justify-between h-[calc(100%-190px)] flex-grow'>
                    <div>
                        <div className='flex flex-col gap-2.5 my-4'>
                            <div className='flex justify-between text-black'>
                                <p className='font-medium text-sm'>Phone Number*</p>
                                {newCheckoutUIConfig?.uiConfig?.showGuestCheckoutOption && (
                                    <button className='bg-gray-200 text-gray-500 underline border-none font-bold text-xs focus:outline-none'>
                                        {newCheckoutUIConfig?.textConfig?.login?.guestCheckoutTitle}
                                    </button>
                                )}
                            </div>
                            <div className='bg-white border rounded-xl input-div-container'>
                                <div className='flex flex-row self-center rounded-[var(--border-regular)] border border-dark-grey'>
                                    <div className='flex flex-row items-center justify-center gap-2 p-3.5 bg-snow-white rounded-t-[var(--border-regular)] sm:rounded-l-[var(--border-regular)] sm:rounded-t-none'>
                                        <div className='relative w-7 h-7 overflow-hidden flex-shrink-0'>
                                            <img src={flagOfIndia} height={'20'} alt='country-code' />
                                        </div>
                                        <div className='relative leading-7.5 text-semi-transparent-black'>+91</div>
                                    </div>
                                    <input
                                        type='tel'
                                        inputMode='numeric'
                                        pattern={'[0-9]{10}'}
                                        maxLength={10}
                                        className='number-input border-none font-regular-regular focus:outline-none p-0'
                                        placeholder='Add Phone Number'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='flex gap-3.5 p-2.5 bg-[#fdfae3] text-dark-grey rounded-xl leading-4'>
                            <img src={privacy} height={'20'} alt='privacy' className='h-5 self-center' />
                            <div className='text-xs text-gray-500'>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: newCheckoutUIConfig?.textConfig?.login?.privacyBanner,
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='mt-5 flex flex-col items-center'>
                        <button className='flex items-center bg-gray-200 border-none mb-2 focus:outline-none'>
                            <input
                                type='checkbox'
                                className='form-checkbox'
                                style={{
                                    accentColor: newCheckoutUIConfig?.colorConfig?.brandColor,
                                }}
                            />
                            <label className='ml-2 text-xs text-gray-500'>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: newCheckoutUIConfig?.textConfig?.login?.checkboxText,
                                    }}
                                />
                            </label>
                        </button>
                        <div className='text-xs text-center text-gray-500 mt-1'>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: newCheckoutUIConfig?.textConfig?.login?.privacyText,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <footer className='p-6 bg-white rounded-b-lg'>
                <button
                    className='w-full text-gray-700 py-2 rounded-xl border-none'
                    disabled
                    style={{
                        backgroundColor: newCheckoutUIConfig?.colorConfig?.brandColor,
                        color: newCheckoutUIConfig?.colorConfig?.textColor,
                    }}
                >
                    {newCheckoutUIConfig?.textConfig?.login?.cta}
                </button>
                <div className='mt-2 text-center items-center'>
                    <img src={footerText} height={'20'} alt='gokwik' />
                </div>
            </footer>
        </div>
    )
}

export default LoginPagePreview
