import dayjs from 'dayjs';

export const formatConfigData = (data: any) => {
    let formattedData = {};
    formattedData['tagline'] = data?.data?.shopify_dashboard_config?.tagline;
    formattedData['is_enabled'] = data?.data?.shopify_dashboard_config?.is_enabled;
    return formattedData;
};

export const calculateRTOMetrics = (data: any) => {
    const fromDate = dayjs(data.fromDate);
    const toDate = dayjs(data.toDate);
    const tileMetrics = calculateTileMetrics(data.data, fromDate, toDate);
    const graphData = calculateGraphData(data.data, fromDate, toDate);
    const dateRange = { from_date: fromDate.format('YYYY-MM-DD'), to_date: toDate.format('YYYY-MM-DD') };
    const aggregatedData = { tileMetrics, graphData, dateRange };
    return aggregatedData;
};

export const formatOrderMetrics = (data: any) => {
    const fromDate = dayjs(data.fromDate);
    const toDate = dayjs(data.toDate);
    const tileMetrics = calculateOrderTileMetrics(data.data, fromDate, toDate);
    const graphData = calculateOrdersGraphData(data.data, fromDate, toDate);
    const dateRange = { from_date: fromDate.format('YYYY-MM-DD'), to_date: toDate.format('YYYY-MM-DD') };
    const aggregatedData = { tileMetrics, graphData, dateRange };
    return aggregatedData;
};

export const formatTableData = (data: any) => {
    let formattedData = [];
    data?.data?.map((item) => {
        formattedData.push({
            name: item?.billing_state || item?.billing_city || item?.billing_pincode || item?.p_name,
            total_orders: item?.total_orders,
            total_cod_orders: item?.total_cod_orders,
            total_prepaid_orders: item?.total_prepaid_orders,
            total_rto_orders: item?.total_rto_orders,
            rto_rate: (item?.avg_rto_rate * 100).toFixed(2),
        });
    });
    return formattedData;
};

export const calculateOrderMetricsDelta = (currentData: object, prevData: object) => {

    const deltaValues = {
        total_orders: 0,
        cod_orders: 0,
        prepaid_orders: 0,
    };

    deltaValues.total_orders = calculatePercentageChange(currentData?.['total_orders'], prevData?.['total_orders']);
    deltaValues.cod_orders = calculatePercentageChange(currentData?.['cod_orders'], prevData?.['cod_orders']);
    deltaValues.prepaid_orders = calculatePercentageChange(currentData?.['prepaid_orders'], prevData?.['prepaid_orders']);

    return deltaValues;
};

export const calculateDelta = (currentData: object, prevData: object) => {

    const deltaValues = {
        total_orders: 0,
        total_cod_orders: 0,
        total_prepaid_orders: 0,
        total_rto_orders: 0,
        cod_rto_orders: 0,
        prepaid_rto_orders: 0,
        cod_share: 0,
        overall_rto_rate: 0,
        cod_rto_rate: 0,
        prepaid_rto_rate: 0,
        total_rto_hits: 0,
    };

    deltaValues.total_orders = calculatePercentageChange(currentData?.['total_orders'], prevData?.['total_orders']);
    deltaValues.total_cod_orders = calculatePercentageChange(
        currentData?.['total_cod_orders'],
        prevData?.['total_cod_orders'],
    );
    deltaValues.total_rto_orders = calculatePercentageChange(
        currentData?.['total_rto_orders'],
        prevData?.['total_rto_orders'],
    );
    deltaValues.cod_rto_orders = calculatePercentageChange(currentData?.['cod_rto_orders'], prevData?.['cod_rto_orders']);
    deltaValues.prepaid_rto_orders = calculatePercentageChange(
        currentData?.['prepaid_rto_orders'],
        prevData?.['prepaid_rto_orders'],
    );
    deltaValues.overall_rto_rate = calculatePercentageChange(
        currentData?.['overall_rto_rate'],
        prevData?.['overall_rto_rate'],
    );
    deltaValues.cod_rto_rate = calculatePercentageChange(currentData?.['cod_rto_rate'], prevData?.['cod_rto_rate']);
    deltaValues.prepaid_rto_rate = calculatePercentageChange(
        currentData?.['prepaid_rto_rate'],
        prevData?.['prepaid_rto_rate'],
    );
    deltaValues.total_rto_hits = calculatePercentageChange(currentData?.['total_rto_hits'], prevData?.['total_rto_hits']);
    deltaValues.cod_share = calculatePercentageChange(currentData?.['cod_share'], prevData?.['cod_share']);
    return deltaValues;
};

const calculatePercentageChange = (val1, val2) => {
    return parseInt((((val1 - val2) / val2) * 100).toFixed(2));
};

const calculateOrderTileMetrics = (data: any[], startDate: Dayjs, endDate: Dayjs) => {
    const tileMetrics = {
        total_orders: 0,
        cod_orders: 0,
        prepaid_orders: 0,
    };

    // Filter data within the specified date range
    const filteredData = data.filter((item) => {
        const itemDate = dayjs(item.date);
        return (
            (itemDate.isSame(startDate, 'day') || itemDate.isAfter(startDate, 'day')) &&
            (itemDate.isSame(endDate, 'day') || itemDate.isBefore(endDate, 'day'))
        );
    });

    filteredData.forEach((item) => {
        const totalOrders = parseInt(item.total_orders);
        const codOrders = parseInt(item.cod_orders);
        const prepaidOrders = parseInt(item.prepaid_orders);

        tileMetrics.total_orders += totalOrders;
        tileMetrics.cod_orders += codOrders;
        tileMetrics.prepaid_orders += prepaidOrders;
    });

    return tileMetrics;
};

const calculateTileMetrics = (data: any[], startDate: Dayjs, endDate: Dayjs) => {
    const tileMetrics = {
        total_orders: 0,
        total_cod_orders: 0,
        total_prepaid_orders: 0,
        total_rto_orders: 0,
        cod_rto_orders: 0,
        prepaid_rto_orders: 0,
        high_risk_orders: 0,
        medium_risk_orders: 0,
        low_risk_orders: 0,
        cod_share: 0,
        low_risk_percentage: 0,
        high_risk_percentage: 0,
        medium_risk_percentage: 0,
        overall_rto_rate: 0,
        cod_rto_rate: 0,
        prepaid_rto_rate: 0,
        total_rto_hits: 0,
        pending_orders: 0,
        delivered_orders: 0,
        pending_cod_orders: 0,
        delivered_cod_orders: 0,
    };

    // Filter data within the specified date range
    const filteredData = data.filter((item) => {
        const itemDate = dayjs(item.date);
        return (
            (itemDate.isSame(startDate, 'day') || itemDate.isAfter(startDate, 'day')) &&
            (itemDate.isSame(endDate, 'day') || itemDate.isBefore(endDate, 'day'))
        );
    });

    filteredData.forEach((item) => {
        const totalOrders = parseInt(item.total_orders);
        const rtoOrders = parseInt(item.rto_orders);
        const deliveredOrders = parseInt(item.delivered_orders);
        const pendingOrders = parseInt(item.pending_orders);
        const codOrders = item.payment_method === 'COD' ? totalOrders : 0;
        const prepaidOrders = item.payment_method === 'PREPAID' ? totalOrders : 0;
        const highRiskOrders = item.risk_flag === 'HIGH RISK' ? totalOrders : 0;
        const mediumRiskOrders = item.risk_flag === 'MEDIUM RISK' ? totalOrders : 0;
        const lowRiskOrders = item.risk_flag === 'LOW RISK' ? totalOrders : 0;
        const totalRtoHits = parseInt(item.rid_hits);

        tileMetrics.total_orders += totalOrders;
        tileMetrics.total_rto_hits += totalRtoHits;
        tileMetrics.total_cod_orders += codOrders;
        tileMetrics.total_prepaid_orders += prepaidOrders;
        tileMetrics.total_rto_orders += rtoOrders;
        tileMetrics.cod_rto_orders += rtoOrders > 0 && codOrders > 0 ? rtoOrders : 0;
        tileMetrics.prepaid_rto_orders += rtoOrders > 0 && prepaidOrders > 0 ? rtoOrders : 0;
        tileMetrics.high_risk_orders += highRiskOrders;
        tileMetrics.medium_risk_orders += mediumRiskOrders;
        tileMetrics.low_risk_orders += lowRiskOrders;
        tileMetrics.delivered_orders += deliveredOrders;
        tileMetrics.pending_orders += pendingOrders;
        tileMetrics.pending_cod_orders += pendingOrders > 0 && codOrders > 0 ? pendingOrders : 0;
        tileMetrics.delivered_cod_orders += deliveredOrders > 0 && codOrders > 0 ? deliveredOrders : 0;
    });
    const rtoRateOrders = tileMetrics.pending_orders + tileMetrics.delivered_orders + tileMetrics.total_rto_orders;
    const codRTORateOrders = tileMetrics.pending_cod_orders + tileMetrics.delivered_cod_orders + tileMetrics.cod_rto_orders;
    tileMetrics.cod_share = calculatePercentage(tileMetrics.total_cod_orders, tileMetrics.total_orders);
    tileMetrics.overall_rto_rate = calculatePercentage(tileMetrics.total_rto_orders, rtoRateOrders);
    tileMetrics.cod_rto_rate = calculatePercentage(tileMetrics.cod_rto_orders, codRTORateOrders);
    tileMetrics.prepaid_rto_rate = calculatePercentage(tileMetrics.prepaid_rto_orders, tileMetrics.total_prepaid_orders);
    tileMetrics.high_risk_percentage = calculatePercentage(tileMetrics.high_risk_orders, tileMetrics.total_orders);
    tileMetrics.medium_risk_percentage = calculatePercentage(tileMetrics.medium_risk_orders, tileMetrics.total_orders);
    tileMetrics.low_risk_percentage = calculatePercentage(tileMetrics.low_risk_orders, tileMetrics.total_orders);

    return tileMetrics;
};

const calculateOrdersGraphData = (data: any[], startDate: Dayjs, endDate: Dayjs) => {
    const graphMetricsByDate: any = {};

    data.forEach((item) => {
        const itemDate = dayjs(item.date);
        const isAfterStartDate = itemDate.isSame(startDate, 'day') || itemDate.isAfter(startDate, 'day');
        const isBeforeEndDate = itemDate.isSame(endDate, 'day') || itemDate.isBefore(endDate, 'day');

        if (isAfterStartDate && isBeforeEndDate) {
            const date = itemDate.format('YYYY-MM-DD');
            graphMetricsByDate[date] = graphMetricsByDate[date] || {
                total_orders: 0,
                cod_orders: 0,
                prepaid_orders: 0,
            };
            const totalOrders = parseInt(item.total_orders);
            const codOrders = parseInt(item.cod_orders);
            const prepaidOrders = parseInt(item.prepaid_orders);

            graphMetricsByDate[date].total_orders += totalOrders;
            graphMetricsByDate[date].cod_orders += codOrders;
            graphMetricsByDate[date].prepaid_orders += prepaidOrders;
        }
    });

    // Transform graphMetricsByDate into an array of objects
    const graphData = Object.keys(graphMetricsByDate).map((date) => ({
        date,
        ...graphMetricsByDate[date],
    }));
    graphData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    return graphData;
};

const calculateGraphData = (data: any[], startDate: Dayjs, endDate: Dayjs) => {
    const graphMetricsByDate: any = {};

    data.forEach((item) => {
        const itemDate = dayjs(item.date);
        const isAfterStartDate = itemDate.isSame(startDate, 'day') || itemDate.isAfter(startDate, 'day');
        const isBeforeEndDate = itemDate.isSame(endDate, 'day') || itemDate.isBefore(endDate, 'day');

        if (isAfterStartDate && isBeforeEndDate) {
            const date = itemDate.format('YYYY-MM-DD');
            graphMetricsByDate[date] = graphMetricsByDate[date] || {
                total_orders: 0,
                cod_orders: 0,
                prepaid_orders: 0,
                overall_rto_orders: 0,
                cod_rto_orders: 0,
                prepaid_rto_orders: 0,
                cod_share: 0,
                rto_hits: 0,
                overall_rto_rate: 0,
                cod_rto_rate: 0,
                prepaid_rto_rate: 0,
                high_risk_orders: 0,
                high_risk_percentage: 0,
                low_risk_orders: 0,
                low_risk_percentage: 0,
                medium_risk_orders: 0,
                medium_risk_percentage: 0,
            };
            const totalOrders = parseInt(item.total_orders);
            const rtoHits = parseInt(item.rid_hits);
            const rtoOrders = parseInt(item.rto_orders);
            const codOrders = item.payment_method === 'COD' ? totalOrders : 0;
            const prepaidOrders = item.payment_method === 'PREPAID' ? totalOrders : 0;
            const highRiskOrders = item.risk_flag === 'HIGH RISK' ? totalOrders : 0;
            const mediumRiskOrders = item.risk_flag === 'MEDIUM RISK' ? totalOrders : 0;
            const lowRiskOrders = item.risk_flag === 'LOW RISK' ? totalOrders : 0;

            graphMetricsByDate[date].total_orders += totalOrders;
            graphMetricsByDate[date].cod_orders += codOrders;
            graphMetricsByDate[date].prepaid_orders += prepaidOrders;
            graphMetricsByDate[date].overall_rto_orders += rtoOrders;
            graphMetricsByDate[date].rto_hits += rtoHits;
            graphMetricsByDate[date].high_risk_orders += highRiskOrders;
            graphMetricsByDate[date].medium_risk_orders += mediumRiskOrders;
            graphMetricsByDate[date].low_risk_orders += lowRiskOrders;
            graphMetricsByDate[date].cod_rto_orders += rtoOrders > 0 && codOrders > 0 ? rtoOrders : 0;
            graphMetricsByDate[date].prepaid_rto_orders += rtoOrders > 0 && prepaidOrders > 0 ? rtoOrders : 0;
        }
    });

    Object.values(graphMetricsByDate).forEach((item: any) => {
        item.cod_share = calculatePercentage(item.cod_orders, item.total_orders);
        item.overall_rto_rate = calculatePercentage(item.overall_rto_orders, item.total_orders);
        item.cod_rto_rate = calculatePercentage(item.cod_rto_orders, item.total_cod_orders);
        item.prepaid_rto_rate = calculatePercentage(item.prepaid_rto_orders, item.total_prepaid_orders);
        item.high_risk_percentage = calculatePercentage(item.high_risk_orders, item.total_orders);
        item.medium_risk_percentage = calculatePercentage(item.medium_risk_orders, item.total_orders);
        item.low_risk_percentage = calculatePercentage(item.low_risk_orders, item.total_orders);
    });

    // Transform graphMetricsByDate into an array of objects
    const graphData = Object.keys(graphMetricsByDate).map((date) => ({
        date,
        ...graphMetricsByDate[date],
    }));
    graphData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    return graphData;
};

const calculatePercentage = (numerator: number, denominator: number) => {
    if (isNaN(numerator) || isNaN(denominator) || denominator === 0) {
        return 0;
    }
    const percentage = (numerator / denominator) * 100;
    return parseFloat(percentage.toFixed(2));
};

export const calculateRTOSummaryMetrics = (data: any) => {
    const fromDate = dayjs(data.fromDate);
    const toDate = dayjs(data.toDate);
    const metrics = calculateSummaryTableMetrics(data.data, fromDate, toDate);
    return metrics;
};

const calculateSummaryTableMetrics = (data: any[], startDate: Dayjs, endDate: Dayjs) => {
    const summaryTableMetrics = {
        total_rto_hits: 0,
        total_orders: 0,
        total_cod_orders: 0,
        cod_rto_orders: 0,
        cod_rto_rate: 0,
        total_rto_orders: 0,
        overall_rto_rate: 0,
        total_prepaid_orders: 0,
        high_risk_orders: 0,
        medium_risk_orders: 0,
        low_risk_orders: 0,
        cod_share: 0,
        prepaid_share: 0,
        total_delivered_orders: 0,
        delivered_orders_perc: 0,
        total_cancelled_orders: 0,
        cancelled_orders_perc: 0,
        total_awb_null_orders: 0,
        total_pending_orders: 0,
        awb_fill_rate_perc: 0,
    };

    const filteredData = data?.filter((item) => {
        const itemDate = dayjs(item.date);
        return (
            (itemDate.isSame(startDate, 'day') || itemDate.isAfter(startDate, 'day')) &&
            (itemDate.isSame(endDate, 'day') || itemDate.isBefore(endDate, 'day'))
        );
    })?.map(item => {
        for (let key in item) {
            if (typeof item[key] === "string" && !isNaN(Number(item[key]))) {
                item[key] = parseInt(item[key], 10);
            }
        }
        return item;
    });


    filteredData?.forEach((item) => {
        const totalOrders = parseInt(item.total_orders);
        const rtoOrders = parseInt(item.rto_orders);
        const deliveredOrders = parseInt(item.delivered_orders) || 0;
        const pendingOrders = parseInt(item.pending_orders) || 0;
        const awbNullOrders = parseInt(item.null_status_orders) || 0;
        const cancelledOrders = parseInt(item.cancelled_orders) || 0;
        const codOrders = item.payment_method === 'COD' ? totalOrders : 0;
        const prepaidOrders = item.payment_method === 'PREPAID' ? totalOrders : 0;
        const totalRtoHits = parseInt(item.rid_hits);

        summaryTableMetrics.total_orders += totalOrders;
        summaryTableMetrics.total_delivered_orders += deliveredOrders;
        summaryTableMetrics. total_pending_orders+= pendingOrders;
        summaryTableMetrics.total_cancelled_orders += cancelledOrders;
        summaryTableMetrics.total_awb_null_orders += awbNullOrders;
        summaryTableMetrics.total_rto_hits += totalRtoHits;
        summaryTableMetrics.total_cod_orders += codOrders;
        summaryTableMetrics.total_prepaid_orders += prepaidOrders;
        summaryTableMetrics.total_rto_orders += rtoOrders;
        summaryTableMetrics.cod_rto_orders += rtoOrders > 0 && codOrders > 0 ? rtoOrders : 0;
    });
    const ordersForRTORate = summaryTableMetrics.total_delivered_orders + summaryTableMetrics.total_pending_orders + summaryTableMetrics.total_rto_orders;
    summaryTableMetrics.cod_share = calculatePercentage(summaryTableMetrics.total_cod_orders, summaryTableMetrics.total_orders);
    summaryTableMetrics.prepaid_share = calculatePercentage(summaryTableMetrics.total_prepaid_orders, summaryTableMetrics.total_orders);
    summaryTableMetrics.overall_rto_rate = calculatePercentage(summaryTableMetrics.total_rto_orders, ordersForRTORate);
    summaryTableMetrics.cod_rto_rate = calculatePercentage(summaryTableMetrics.cod_rto_orders, summaryTableMetrics.total_cod_orders);
    summaryTableMetrics.delivered_orders_perc = calculatePercentage(summaryTableMetrics.total_delivered_orders, summaryTableMetrics.total_orders);
    summaryTableMetrics.cancelled_orders_perc = calculatePercentage(summaryTableMetrics.total_cancelled_orders, summaryTableMetrics.total_orders);
    summaryTableMetrics.awb_fill_rate_perc = 100-calculatePercentage(summaryTableMetrics.total_pending_orders + summaryTableMetrics.total_awb_null_orders, summaryTableMetrics.total_orders);
    return {
        originalData: filteredData,
        summaryTableMetrics
    }
};

export const parseMetrics = (data: any[]) => {
    const responseData = data['data']
    return responseData?.map((item) => {
        const parsedItem = { ...item }
        Object.keys(parsedItem)?.forEach((key) => {
            if (
                !['date', 'decile_level', 'address_length_bucket', 'quantity'].includes(key) &&
                !isNaN(parseFloat(parsedItem[key]))
            ) {
                parsedItem[key] = parseFloat(parsedItem[key])
            }
        })
        return parsedItem
    })
}
