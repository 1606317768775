import { Checkbox, Col, Form, InputNumber, Radio } from 'gokwik-ui-kit'

const SetupDiscountCartItems = ({ form }: { form: any }) => {
    const discountCartItemType = Form.useWatch('discountCartItemType', form)
    const discountCartItemsCapping = Form.useWatch('discountCartItemsCapping', form)

    return (
        <Col span={24} className='flex flex-col'>
            <p className='text-sm font-semibold w-full pb-2'>Set discount properties</p>
            <div className='flex justify-between items-end'>
                <Form.Item
                    label='Select Type'
                    name='discountCartItemType'
                    rules={[{ required: true, message: 'Please select discount value type' }]}
                >
                    <Radio.Group
                        onChange={(e) => {
                            if (e.target.value === 'fixed') {
                                form.setFieldsValue({
                                    discountCartItemsOncePerOrder: true,
                                })
                            }
                        }}
                    >
                        <Radio value='percentage'>Percentage</Radio>
                        <Radio value='fixed'>Fixed Amount</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name='discountCartItemsDiscountValue'
                    label='Discount Value'
                    rules={[{ required: true, message: 'Please input your discount value!' }]}
                >
                    <InputNumber
                        className='w-96'
                        {...(discountCartItemType === 'percentage'
                            ? {
                                  min: 0,
                                  max: 100,
                                  suffix: '%',
                              }
                            : {
                                  min: 0,
                                  prefix: '₹',
                              })}
                    />
                </Form.Item>
            </div>
            {discountCartItemType === 'percentage' && (
                <div className='flex justify-between items-end mt-2'>
                    <Form.Item name={'discountCartItemsCapping'} valuePropName='checked'>
                        <Checkbox>Set Discount Capping</Checkbox>
                    </Form.Item>
                    {discountCartItemsCapping && (
                        <Form.Item
                            label='Capping Value'
                            name='discountCartItemsCappingValue'
                            rules={[
                                { required: discountCartItemsCapping, message: 'Please input your discount value!' },
                            ]}
                        >
                            <InputNumber min={1} className='w-96' prefix={'₹'} />
                        </Form.Item>
                    )}
                </div>
            )}
            {discountCartItemType === 'fixed' && (
                <div className='flex flex-col gap-y-1 mt-2'>
                    <Form.Item name={'discountCartItemsOncePerOrder'} valuePropName='checked'>
                        <Checkbox>Only apply discount once per order</Checkbox>
                    </Form.Item>
                    <span className='text-xs text-gray-400 mt-1'>
                        If not selected, the amount will be taken off each eligible item in an order.
                    </span>
                </div>
            )}
        </Col>
    )
}

export default SetupDiscountCartItems
