import { IShopifyNewPaymentUIConfig, IShopifyPaymentUIConfig } from '@library/utilities/interface'
import rightArrowImage from '@library/images/payment-ui-customisations/arrowRight.svg'
import unionImage from '@library/images/payment-ui-customisations/Union.svg'
import phoneImage from '@library/images/payment-ui-customisations/phone.svg'
import gokwikLogo from '@library/images/common/logo-gokwik-business-suit.svg'
import { defaultShopifyNewPaymentUIConfig, defaultShopifyPaymentUIConfig, logoSources } from './constants'
import { ArrowLeftOutlined, Card } from 'gokwik-ui-kit'
import { useState, useEffect } from 'react'
import BannerPreview from './bannerSlider'

interface Props {
    paymentUIConfig: IShopifyPaymentUIConfig | IShopifyNewPaymentUIConfig
    paymentMethods: { paymentMethodId: string }[]
    newPaymentUIEnabled: boolean
    merchantLogo: string
    colorConfig: any
}
const PaymentPagePreview = ({
    merchantLogo,
    paymentUIConfig,
    paymentMethods,
    newPaymentUIEnabled,
    colorConfig,
}: Props) => {
    const banners =
        (paymentUIConfig as IShopifyNewPaymentUIConfig)?.textConfig?.bannerText?.filter(
            (banner: string) => banner?.length,
        ) || []

    let displayNames = {}
    Object.entries(
        newPaymentUIEnabled
            ? defaultShopifyNewPaymentUIConfig.methodsCustomization
            : defaultShopifyPaymentUIConfig.payment_page_text,
    ).forEach(([key, value]) => {
        displayNames[key] = newPaymentUIEnabled ? value.buttonText : value.btn_text
    })
    if (newPaymentUIEnabled) {
        displayNames['paylater'] = 'Pay via Pay Later'
    } else {
        displayNames['bnpl'] = 'Pay via BNPL'
    }

    return (
        <div>
            <div className='text-2xl text-center font-semibold p-2'>Preview</div>
            <div className='bg-gray-200 shadow-md p-2 rounded-t-xl max-w-md w-full'>
                {/* Header Section */}
                <div className='relative w-full flex items-center py-2'>
                    <div className='absolute left-1 top-4'>
                        <ArrowLeftOutlined />
                    </div>
                    <div className='mx-auto'>
                        <img
                            src={logoSources(merchantLogo)[0]}
                            alt='logo'
                            height={40}
                            width={60}
                            onError={(e) => {
                                if (e.target instanceof HTMLImageElement) {
                                    const img = e.target
                                    const currentSrc = img.src
                                    const currentIndex = logoSources(merchantLogo).indexOf(currentSrc)
                                    if (merchantLogo && currentIndex < 3) {
                                        img.src = logoSources(merchantLogo)[currentIndex + 1]
                                    } else {
                                        img.src = gokwikLogo
                                    }
                                }
                            }}
                        />
                    </div>
                </div>

                {/* Banners Section */}
                {(paymentUIConfig as IShopifyNewPaymentUIConfig)?.textConfig?.showBrandBanner && banners.length > 0 && (
                    <div className='-ml-2 -mr-2 mb-2 py-2'>
                        <BannerPreview config={paymentUIConfig} banners={banners} />
                    </div>
                )}
                {/* phone number section */}
                <div className='bg-white rounded-xl flex flex-col  justify-between h-[calc(100%-190px)] flex-grow py-2'>
                    <div>
                        <div>
                            <div className='flex flex-row justify-between text-black my-2'>
                                <div className='flex flex-row ml-2'>
                                    <img
                                        className={'mt-1'}
                                        src={unionImage}
                                        alt='avatar'
                                        width={'15px'}
                                        height={'15px'}
                                    />
                                    <p className='ml-2'>John Doe</p>
                                </div>
                                <div className='flex flex-row ml-2'>
                                    <img
                                        className={'mt-1'}
                                        src={phoneImage}
                                        alt='phone'
                                        width={'15px'}
                                        height={'15px'}
                                    />
                                    <p className='ml-2 mr-2'>9999999999</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Payment Methods Section */}
                <div className='flex flex-col items-center py-2'>
                    {paymentMethods?.length > 0 &&
                        paymentMethods.map((paymentMethod, index) => {
                            return (
                                <Card key={index} className='mb-4 w-full flex-col'>
                                    <div className='flex items-center w-full'>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                minWidth: '100%',
                                            }}
                                        >
                                            <div>
                                                {newPaymentUIEnabled ? (
                                                    <p>
                                                        {(paymentUIConfig &&
                                                            'methodsCustomization' in paymentUIConfig &&
                                                            paymentUIConfig?.methodsCustomization[
                                                                paymentMethod.paymentMethodId
                                                            ]?.buttonText) ||
                                                            ''}
                                                    </p>
                                                ) : (
                                                    <p>
                                                        {(paymentUIConfig &&
                                                            'payment_page_text' in paymentUIConfig &&
                                                            paymentUIConfig?.payment_page_text[
                                                                paymentMethod.paymentMethodId
                                                            ]?.btn_text) ||
                                                            ''}
                                                    </p>
                                                )}
                                            </div>
                                            <div
                                                style={{
                                                    justifyContent: 'space-around',
                                                }}
                                            >
                                                <span>₹100</span>
                                                <img className='ml-3' src={rightArrowImage} alt='Chevron Icon' />
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            )
                        })}
                </div>
            </div>
        </div>
    )
}

export default PaymentPagePreview
