import React, { useEffect, useRef, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Progress } from 'gokwik-ui-kit'

// Import Pages for Routing
import './main.css'
import { Provider, useDispatch, useSelector } from 'react-redux'
import store, { RootStoreType } from './store'
import { setFilters } from './store/filters'
import { dashboardStore, filterStore } from '@gokwik/utilities'
import { ToastContainer } from 'react-toastify'
import { setUserData } from '@store/user'
import AppRoutes from './routes'

const InitialiseStore = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setFilters(filterStore.getState().filters?.kwikpass || {}))
        dispatch(setUserData(dashboardStore.getState().userData))
        const unsubcribe = dashboardStore.subscribe((state) => {
            dispatch(setUserData(state.userData))
        })
        return () => unsubcribe?.()
    }, [])
    return <></>
}
const queryClient = new QueryClient()

export default function Root(props: any) {
    const appContainerRef = useRef<HTMLDivElement>()
    const [globalStoreChanged, setGlobalStoreChanged] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            appContainerRef.current.style.maxWidth = `calc(100vw - ${
                document.getElementById('side-nav').getBoundingClientRect().width
            }px)`
        }, 100)

        const unsubscribe = dashboardStore.subscribe((state) => {
            setGlobalStoreChanged((prev) => !prev)
        })
        return () => {
            unsubscribe()
        }
    }, [])
    return (
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <div
                    className='app-container max-h-screen h-screen  overflow-y-auto pt-20 px-5 w-full bg-[#003c7138]'
                    ref={appContainerRef}
                    style={{
                        maxWidth: `calc(100vw - ${document.getElementById('side-nav')?.offsetWidth}px)`,
                    }}
                >
                    <ToastContainer />
                    <InitialiseStore />
                    <BrowserRouter>
                        <React.Suspense fallback={<Progress />}>
                            <AppRoutes globalStoreChanged={globalStoreChanged} />
                        </React.Suspense>
                    </BrowserRouter>
                </div>
            </QueryClientProvider>
        </Provider>
    )
}
