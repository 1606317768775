import {
    BuyXAtYDiscount,
    BuyXAtYDiscountFlattened,
    DiscountCapping,
    DynamicDiscount,
    DynamicDiscountConditioned,
    DynamicDiscountFlattened,
    OfferAndDiscount,
    TieredDiscount,
} from '../interface'

const rangesOverlap = ([x1, x2], [y1, y2]) => x1 <= y2 && y1 <= x2

export const validateTieredDiscount = (
    discountData: Partial<TieredDiscount>,
    discountList: Partial<TieredDiscount>[],
) => {
    if (!discountData?.method_type) {
        return {
            msg: 'Please select payment method',
            status: false,
        }
    }
    const highestLowerLimitDiscount = [...discountList].sort((a, b) => +a.lower_limit - +b.lower_limit)[
        discountList.length - 1
    ]
    let ifDuplicate = false
    let isOverLapping = false
    let differentFreebieForSameRange = false;

    discountList.forEach((discount) => {
        if (
            discount.method_type !== discountData.method_type &&
            discount.discount_code === discountData.discount_code
        ) {
            ifDuplicate = false
        } else if (
            discount.method_type === discountData.method_type &&
            discount.discount_code === discountData.discount_code
        ) {
            ifDuplicate = true
        }
        let isRangesOverlap = rangesOverlap(
            [+discount.lower_limit, +discount.upper_limit || Number.MAX_SAFE_INTEGER],
            [+discountData.lower_limit, +discountData.upper_limit || Number.MAX_SAFE_INTEGER], // ensuring upper limit is considered greater
        );
        if(discountData.discount_type.toLowerCase() === 'freebie' && discount.discount_type.toLowerCase() === 'freebie' && isRangesOverlap && !(discount.discount_rule?.product[0]?.product_id == discountData.discount_rule?.product[0]?.product_id && discount.discount_rule?.product[0]?.variant_id == discountData.discount_rule?.product[0]?.variant_id)) 
            differentFreebieForSameRange = true;
        if (
            discount.method_type === discountData.method_type && isRangesOverlap
        )
            isOverLapping = true;
    })
    if (!discountData.discount_code.trim() || ifDuplicate)
        return {
            msg: 'Please enter unique code',
            status: false,
        }
    if (!discountData.lower_limit)
        return {
            msg: 'Please enter valid lower limit',
            status: false,
        }
    if (discountData.upper_limit === 0)
        return {
            msg: 'Upper limit should not be 0',
            status: false,
        }
    if (isOverLapping)
        return {
            msg: 'New limits should not overlap with existing tiers',
            status: false,
        }

    if (discountData.upper_limit && +discountData.upper_limit <= +discountData.lower_limit)
        return {
            msg: 'Upper limit should be greater than lower limit',
            status: false,
        }
    else if (highestLowerLimitDiscount) {
        if (
            discountData.method_type === highestLowerLimitDiscount.method_type &&
            !discountData.upper_limit &&
            !highestLowerLimitDiscount?.upper_limit
        )
            return {
                msg: 'Only one discount can have empty upper limit',
                status: false,
            }
        else if (
            discountData.method_type === highestLowerLimitDiscount.method_type &&
            !discountData.upper_limit &&
            discountData.lower_limit < highestLowerLimitDiscount?.lower_limit
        )
            return {
                msg: 'Only discount with highest lower limit can have empty upper limit',
                status: false,
            }
    }
    if (discountData.discount_type.toLowerCase() === 'non freebie' && !discountData.value)
        return {
            msg: 'Discount value is required',
            status: false,
        }
    if (discountData.discount_type.toLowerCase() === 'freebie' && discountData.discount_rule.product.length === 0)
        return {
            msg: 'Free Gifts are required',
            status: false,
        }
    if(differentFreebieForSameRange){
        return {
            msg: 'Cannot select different freebie products for tiers with common ranges',
            status: false,
        };
    }

    return {
        msg: 'Discount added success',
        status: true,
    }
}

export const validateDynamicDiscount = (
    discountData: DynamicDiscountFlattened,
    discountDetails: Partial<DynamicDiscountConditioned>,
) => {
    const { discount_category: existingCategory, discount_info: discountList } = discountDetails
    if (!discountData.discount_name.trim())
        return {
            msg: 'Enter a valid code',
            status: false,
        }
    if (existingCategory && discountData.discount_category !== existingCategory) {
        return {
            msg: `${discountData.discount_name} exists with discount category of ${
                existingCategory === 'value' ? 'Order Value' : 'Product Quantity'
            }`,
            status: false,
        }
    }
    if (!discountData.discount_type)
        return {
            msg: 'Select a discount type',
            status: false,
        }
    if (!discountData.discount_value)
        return {
            msg: 'Discount value should not be empty',
            status: false,
        }
    if (discountData.discount_type === 'percentage' && +discountData.discount_value > 100)
        return {
            msg: 'Discount value percentage should not be more than 100',
            status: false,
        }
    if (discountData.discount_category === 'volume') {
        if (!discountData.item_volume)
            return {
                msg: 'Item volume should not be empty',
                status: false,
            }
        const duplicateValueAndCount = (discountList || [])?.filter(
            (discount) => discountData.item_volume === discount.item_volume,
        )
        if (duplicateValueAndCount.length)
            return {
                msg: `Item volume: ${discountData.item_volume} already exists for this discount code`,
                status: false,
            }
    } else if (discountData.discount_category === 'value') {
        if (discountData.min_price === 0 || isNaN(+discountData.min_price))
            return {
                msg: 'Please enter valid min order value',
                status: false,
            }
        if (discountData.max_price === 0)
            return {
                msg: 'Max order value should not be 0',
                status: false,
            }
        if (discountData.max_price && +discountData.min_price >= +discountData.max_price)
            return {
                msg: 'Max order value should be greater than min order value',
                status: false,
            }

        if (
            discountData.min_price &&
            discountData.discount_type === 'fixed' &&
            +discountData.min_price < +discountData.discount_value
        ) {
            return {
                msg: 'Discount value should be less than order value range',
                status: false,
            }
        }
        let isOverLapping = false
        let highestLimitTier = null
        ;(discountList || []).forEach((discount) => {
            if (
                rangesOverlap(
                    [discount.min_price, discount.max_price || Number.MAX_SAFE_INTEGER],
                    [discountData.min_price, discountData.max_price || Number.MAX_SAFE_INTEGER],
                    // ensuring empty max price is considered greater
                )
            )
                isOverLapping = true
            if (discount.min_price > (highestLimitTier?.min_price || 0)) highestLimitTier = { ...discount }
        })

        if (isOverLapping)
            return {
                msg: 'New order values should not overlap with existing tiers',
                status: false,
            }
        if (highestLimitTier) {
            if (!discountData.max_price && !highestLimitTier?.max_price)
                return {
                    msg: 'Only one tier can have empty max order value for a discount code',
                    status: false,
                }
            else if (!discountData.max_price && discountData.min_price < highestLimitTier.min_price)
                return {
                    msg: 'Only tier with highest min order value can have empty max order value',
                    status: false,
                }
            else if (
                discountData.max_price &&
                !highestLimitTier?.max_price &&
                discountData.min_price > highestLimitTier.min_price
            )
                return {
                    msg: `New range overlaps with range of ${highestLimitTier.min_price} tier`,
                    status: false,
                }
        }
    }
    return {
        msg: 'Discount added success',
        status: true,
    }
}

export const validateBuyXatYDiscount = (
    discountData: BuyXAtYDiscountFlattened,
    discounts: BuyXAtYDiscountFlattened[],
) => {
    if (!discountData.discount_name.trim())
        return {
            msg: 'Enter a valid code',
            status: false,
        }
    if (!discountData.match_type)
        return {
            msg: 'Select a match type',
            status: false,
        }
    if (!discountData.product_quantity)
        return {
            msg: 'Enter a valid product quantity',
            status: false,
        }
    if (!discountData.capped_value)
        return {
            msg: 'Enter a valid capped value',
            status: false,
        }

    const similarExists = discounts.find(
        (discount) =>
            discount.discount_name === discountData.discount_name &&
            discount.product_quantity === discountData.product_quantity,
    )

    if (similarExists) {
        return {
            msg: 'Similar discount already exists',
            status: false,
        }
    }
    return {
        msg: 'Discount added success',
        status: true,
    }
}

export const validateDiscountCapping = (discountCappingObj: Partial<DiscountCapping>) => {
    if (!discountCappingObj.match_type)
        return {
            msg: 'Please select match type',
            status: false,
        }
    if (!discountCappingObj.code?.trim() && !discountCappingObj.regex?.trim()) {
        return {
            msg: 'Please enter code',
            status: false,
        }
    }
    if (!discountCappingObj.capping_limit)
        return {
            msg: 'Please enter capping limit',
            status: false,
        }
    return {
        msg: 'Discount capping added success',
        status: true,
    }
}

export const validateGWP = (discountData, discounts) => {
    if (!discountData.eligibility_rule.match_type)
        return {
            msg: 'Select a match type',
            status: false,
        }
    if (!discountData.discount_code)
        return {
            msg: 'Please enter code',
            status: false,
        }
    if (!discountData.discount_code || !discountData.eligibility_rule.match_type) {
        return {
            msg: 'Please fill all the fields',
            status: false,
        }
    }
    const similarExists = discounts.find((discount) => discount.discount_code === discountData.discount_code)
    if (similarExists) {
        return {
            msg: 'Similar discount code already exists',
            status: false,
        }
    }
    if (discountData.discount_rule.product.length === 0) {
        return {
            msg: 'Please select at least one product',
            status: false,
        }
    }
    return {
        msg: 'Discount added success',
        status: true,
    }
}

export const validateOfferDiscount = (discountData: Partial<OfferAndDiscount>) => {
    const { visibility, user_type, max_price, min_price, discount_code, discount_detail, max_quantity, min_quantity } =
        discountData
    if (!discount_code)
        return {
            msg: 'Please enter discount code',
            status: false,
        }
    if (!discount_detail)
        return {
            msg: 'Please enter discount detail',
            status: false,
        }
    if (!visibility)
        return {
            msg: 'Please select visibility',
            status: false,
        }
    if (!user_type)
        return {
            msg: 'Please select user type',
            status: false,
        }
    if ((max_price || Number.MAX_SAFE_INTEGER) <= min_price)
        return {
            msg: 'Max price should be greater than min price',
            status: false,
        }
    if ((max_quantity || Number.MAX_SAFE_INTEGER) <= min_quantity)
        return {
            msg: 'Max quantity should be greater than min quantity',
            status: false,
        }

    return {
        msg: 'Discount added success',
        status: true,
    }
}
