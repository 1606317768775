import {
    SET_INITIAL_AUTO_TRIGGER_COUPON_SCREEN_CONFIGS,
    SET_INITIAL_AUTO_TRIGGER_LOGIN_SCREEN_CONFIGS,
    SET_INITIAL_AUTO_TRIGGER_OTP_SCREEN_CONFIGS,
    SET_AUTO_TRIGGER_POPUP_COMPONENT_ID,
    SET_NEW_AUTO_TRIGGER_COUPON_SCREEN_CONFIGS,
    SET_NEW_AUTO_TRIGGER_LOGIN_SCREEN_CONFIGS,
    SET_NEW_AUTO_TRIGGER_OTP_SCREEN_CONFIGS,
} from '../types'

// Set if component configs are available
const setAutoTriggerLoginComponentId = (isAvailable) => {
    return {
        type: SET_AUTO_TRIGGER_POPUP_COMPONENT_ID,
        payload: isAvailable,
    }
}

const setInitialAutoTriggerOTPScreenConfigs = (otpScreenConfig) => {
    return {
        type: SET_INITIAL_AUTO_TRIGGER_OTP_SCREEN_CONFIGS,
        payload: otpScreenConfig,
    }
}

const setInitialAutoTriggerCouponScreenConfigs = (couponScreenConfig) => {
    return {
        type: SET_INITIAL_AUTO_TRIGGER_COUPON_SCREEN_CONFIGS,
        payload: couponScreenConfig,
    }
}

// Set New screen configs using form-values
const setNewAutoTriggerLoginScreenConfigs = (loginScreenConfig) => {
    return {
        type: 'NEW_LOGIN_POPUP_COMPONENTS',
        payload: loginScreenConfig,
    }
}

const setNewAutoTriggerOTPScreenConfigs = (otpScreenConfig) => {
    return {
        type: SET_NEW_AUTO_TRIGGER_OTP_SCREEN_CONFIGS,
        payload: otpScreenConfig,
    }
}

const setNewAutoTriggerCouponScreenConfigs = (couponScreenConfig) => {
    return {
        type: SET_NEW_AUTO_TRIGGER_COUPON_SCREEN_CONFIGS,
        payload: couponScreenConfig,
    }
}
const setInitialLoginPopupComponents = (loginPopupConfigs) => {
    return {
        type: 'INITIAL_LOGIN_POPUP_COMPONENTS',
        payload: loginPopupConfigs,
    }
}

export {
    setAutoTriggerLoginComponentId,
    setInitialLoginPopupComponents,
    setInitialAutoTriggerOTPScreenConfigs,
    setInitialAutoTriggerCouponScreenConfigs,
    setNewAutoTriggerLoginScreenConfigs,
    setNewAutoTriggerOTPScreenConfigs,
    setNewAutoTriggerCouponScreenConfigs,
}
