const APIEndPoints = {
    getFilters: '/api/dashboard/dashboard_filters',
    signin: '/api/dashboard/user/signin',
    signup: '/api/dashboard/user/signup',
    sendOTP: '/api/dashboard/user/send-otp',
    signupDetails: '/api/onboarding/signup_details',
    verifyMerchant: '/api/dashboard/merchant/verify-merchant',
    userDetails: '/api/dashboard/user/details',
    verifyOTP: '/api/dashboard/user/verify-otp',
    generateReports: '/generateReport',
    getReports: '/merchantReports',
    getMerchantList: '/api/dashboard/merchant/merchant-list',
    getAllMerchants: '/api/dashboard/merchant/all',
    getUserRoles: '/api/dashboard/roles',
    getUsers: '/api/dashboard/user/all',
    getInvitedUser: '/api/dashboard/merchant/invited-team-list',
    updateMember: '/api/dashboard/user/update-user',
    inviteMember: '/api/dashboard/user/invite-merchant-team',
    createSubscription: '/api/dashboard/onboarding/create-subscription',
    cancelPremiumKPPlan: '/api/dashboard/onboarding/cancel-subscription',
    createLead: '/api/dashboard/onboarding/generate-salesforce-lead/', //:merchant_id
    updateMerchantOnboardingStatus: '/api/dashboard/merchant/update/', //:merchant_id
    getShopifyApprovalStatusForMerchant: '/api/dashboard/onboarding/fetch-billing-subscription-status/', //:merchant_id
    getShopifyThemes: '/api/dashboard/onboarding/get-shopify-themes/', //:merchant_id
    setShopifyTheme: '/api/dashboard/onboarding/set-shopify-theme/', //:merchant_id/:theme_id
    switchMerchant: '/api/dashboard/team/switch-merchant',
    generateKYCLinks: '/api/dashboard/easebuzz/generate_vkyc_link',
    documentList: '/api/dashboard/onboarding/document-list',
    updateKycDocument: '/api/dashboard/onboarding/update-kyc-document',
    getKycDocument: '/api/dashboard/onboarding/get-kyc-document',
    deleteKycDocument: '/api/dashboard/onboarding/delete-kyc-document',
    sendKYCDocumentPostError: '/api/dashboard/easebuzz/send_kyc_docs',
    getPincodeState: '/api/dashboard/pincode/details',
    activateOrDeactivateRTO: '/api/dashboard/onboarding/rto-selfconfig',
    getPrefilledDetailsOfInvitedUser: '/api/dashboard/user/invitation',
    signInInvitedUser: '/api/dashboard/user/invitation',
    userEvent: '/api/dashboard/event',
    switchDashboard: '/api/dashboard/user/switch-dashboard',
    updatePaymentURL: '/api/dashboard/merchant/update-payment-url',
    shceduleNotificaiton: '/api/dashboard/schedule_notifications/current',
    logout: '/api/dashboard/user/logout',
    getScheduleNotifications: '/api/dashboard/schedule_notifications/all?page=1&pageSize=100',
    updateScheduleNotifications: '/api/dashboard/schedule_notifications/update',
    addScheduleNotifications: '/api/dashboard/schedule_notifications/addNotification',
    reportDownload: '/merchantReportUrl',
    merchantAppStateUpdate: '/api/dashboard/shopify/app-state',
    rateCapture: {
        agreement: '/api/dashboard/onboarding/agreement',
        pricingStrategy: '/api/onboarding/agreement/agreement_pricing_strategy',
    },
    billing: {
        pricingReport: '/api/dashboard/onboarding/agreement/download_pricing_strategies',
    },
    addPermissions: '/api/dashboard/permissions/add_permission',
    addRoles: '/api/dashboard/roles/add',
    updateRoles: '/api/dashboard/roles/update',
    admin_dashboard_stats: '/api/dashboard/dashboard-stats',
    addAccount: '/api/dashboard/merchant/ga/connect-account',
    getAccount: '/api/dashboard/merchant/ga/account-conn-status',
    fileS3UploadNotifyUrl: '/api/dashboard/pincode/upload/file',
    blockCustomerUploadNotifyUrl: '/api/dashboard/customer/block/upload/file',
    blockCustomerPresignedUrl: '/api/dashboard/customer/block/presigned-url',
    blockCustomer: '/api/dashboard/merchant/customer',
    getBlockedCustomerData: '/api/dashboard/customer/block-list/',
    unblockAll: '/api/dashboard/customer/unblock/',
    blockCustomerPartial: '/api/dashboard/customer/partial-block',
    getCustomer: '/api/dashboard/customer/all',
    experimentsEndpoint: '/api/dashboard/experiments',
    getCustomerDetails: '/api/dashboard/customer',
    terminalConfigs: {
        getTerminals: (merchantId: string) => `/v1/merchants/${merchantId}/terminals`,
        getBluePrints: (provider: string) => `/v1/terminals/blueprints/${provider}`,
        getTerminal: (id: string) => `/v1/terminals/${id}`,
        createTerminal: '/v1/terminals/global',
        updateTerminal: (id: string) => `/v1/terminals/global/${id}`,
    },
    supportedProviders: '/v1/terminals/supported',
    addUsers: '/api/dashboard/user/add-user',
    updateUsers: '/api/dashboard/user/update-user',
    createBasicPlanForCODFirst: '/api/dashboard/onboarding/create-basic-pricing', //?aap_name: ''
    getShopifyGlobalConfig: '/api/dashboard/shopify-app/global-config',
    updateShopifyGlobalConfig: '/api/dashboard/shopify-app/global-config',
    getAccoutData: `/api/dashboard/merchant/ga/account-summeries`,
    shopifyThemeExtensionURL: '/api/dashboard/onboarding/theme-extension-url',
    shopifyManualThemeCode: '/api/dashboard/onboarding/kp-generate-code',
    bulkUpload: {
        listing: '/file/list',
        // details: (id: string) => `/v1/uploads/${id}`,
        file: `/file/get-file`,
        generatePreSignedUrl: '/file/generate-pre-signed-url',
        acknowledgeUpload: '/file/upload-acknowledgment',
        publish: '/file/publish',
    },
    routing: {
        getMethods: (mId: string) => `/v1/payment/routes/${mId}/methods`,
        getRoutes: (mId: string, method: string) => `/v1/payment/routes/${mId}/methods/${method}`,
        updateRoute: (mId: string, routeId: number) => `/v1/payment/routes/${mId}/${routeId}`,
        getInstruments: (mId: string, methodName: string) =>
            `/v1/payment/routes/${mId}/methods/${methodName}/instruments`,
        createRoute: (mId: string) => `/v1/payment/routes/${mId}`,
        deactivateRoute: (mId: string, terminalId: string) => `/v1/payment/routes/${mId}/${terminalId}/deactivate`,
        supportedProviders: (mId: string, methodName: string, instrumentName: string) =>
            `/v1/payment/routes/${mId}/methods/${methodName}/instruments/${instrumentName}`,
    },
    getColumnPreferences: '/api/dashboard/column_filters',
    updateColumnPreferences: '/api/dashboard/update_column_filters',
    getTrackersData: '/api/dashboard/merchant/trackers',
    getSensetiveInfo: '/api/dashboard/customer/audit/log-sensitive-access',
}
export default APIEndPoints
