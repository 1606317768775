import { FileExcelFilled, InfoCircleFilled } from 'gokwik-ui-kit'
function UploadScreen({ fileName }: { fileName: string }) {
    return (
        <div className='flex items-center justify-center h-full flex-col gap-6 text-center'>
            <InfoCircleFilled className='text-7xl text-[#004b8d]' />
            <p className='font-semibold text-xl'>Do you wish to proceed?</p>
            <p className='text-sm font-normal text-[#00000080]'>
                Keep AWB Data in sync to access historical data and gain valuable RTO insights.
            </p>
            <p>
                When uploading the file, ensure it includes both the order number and the associated AWB status for each
                order.
            </p>
            <p className='flex items-center gap-2 text-[#004B8D] font-semibold text-base border border-solid border-[#CBD2DE] rounded-md p-2'>
                <FileExcelFilled className='text-[#14992A]' /> {fileName}
            </p>
        </div>
    )
}

export default UploadScreen
