import {
    Button,
    Card,
    CheckCircleOutlined,
    CloseCircleOutlined,
    CloudUploadOutlined,
    DownloadOutlined,
    Form,
    Select,
    SyncOutlined,
    Tag,
    Tooltip,
    Upload,
    WarningOutlined,
} from 'gokwik-ui-kit'
import { useEffect, useMemo, useState } from 'react'
import { type JobDetails, type TagProps } from '../types'
import {
    FileValidatorRule,
    getFile,
    JOB_TYPES,
    messages,
    normFile,
    publishFile,
    Statuses,
    uploadFile,
    validateFile,
} from '../bulk.service'
import { validatePermission } from '@gokwik/utilities'
import { PermissionValues } from '@library/utilities/constants/constants'
import { getCurrencySymbol } from '@library/utilities/helpers/helper'

export default function Step2({ data, onClose }: { data: JobDetails; onClose: (text: string) => void }) {
    const [status, setStatus] = useState({ text: 'Loading', icon: <SyncOutlined spin />, color: 'default' })
    const [publishPermission, setPublishPermission] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [form] = Form.useForm()

    const cannotSubmit = useMemo(() => {
        if (data?.status === Statuses.PUBLISHED) return messages.PUBLISHED
        if (data?.status === Statuses.PARTIAL_PUBLISH) return messages.PUBLISHED
        if (data?.status === Statuses.ERROR) return messages.Error
        if (data?.status === Statuses.PUBLISHING) return messages.PUBLISHING
        if (data?.validation !== Statuses.FAILED && !publishPermission) return messages.PUBLISH_PERMISSION
        return ''
    }, [data, publishPermission])

    const mapStatus = (status: string) => {
        switch (status) {
            case Statuses.SUCCESS:
                setStatus({ text: 'Validation successful', icon: <CheckCircleOutlined />, color: 'success' })
                break

            case Statuses.FAILED:
                setStatus({ text: 'Validation failed', icon: <CloseCircleOutlined />, color: 'error' })
                break

            case Statuses.ERROR:
                setStatus({ text: 'Publish failed', icon: <CloseCircleOutlined />, color: 'error' })
                break

            case Statuses.PARTIAL_PUBLISH:
                setStatus({ text: 'File Partially Published', icon: <WarningOutlined />, color: 'warning' })
                break

            case Statuses.PUBLISHED:
                setStatus({ text: 'File Published', icon: <CheckCircleOutlined />, color: 'success' })
                break
        }
    }

    // const getUploadJobDetails = async () => {
    //     await getUploadDetails(id, (data) => setData(data))
    // }

    const downloadFromURL = (path, name) => {
        try {
            const url = new URL(path)
            path = decodeURIComponent(url.pathname.slice(1))
        } finally {
            getFile(path, name)
        }
    }

    const onFinish = (values: any) => {
        if (!data) return
        setSubmitting(true)
        if (data.validation === Statuses.FAILED) {
            uploadFile({
                file: values.file[0].originFileObj,
                body: {
                    id: data.id,
                    file_name: values.file[0].originFileObj.name,
                    file_size: values.file[0].originFileObj.size,
                    file_type: 'csv',
                    action_type: data.actionType,
                    job_type: data.jobType,
                },
                callback: (status) => {
                    setSubmitting(false)
                    if (status === 'success') onClose('change')
                },
            })
        } else if (publishPermission && data.status === Statuses.DRAFT) {
            publishFile(data.id, () => {
                setSubmitting(false)
                onClose('change')
            })
        }
    }

    useEffect(() => {
        // getUploadJobDetails()
        setPublishPermission(validatePermission(PermissionValues.bulkUpload.publish))
    }, [])

    useEffect(() => {
        if (data?.status === Statuses.DRAFT) {
            mapStatus(data?.validation)
        } else if (data?.status) {
            mapStatus(data?.status)
        }
        !form.getFieldValue('uploaded_file') &&
            form.setFieldsValue({ error_file: data?.error_files?.at(0), uploaded_file: data?.uploaded_files?.at(0) })
    }, [data])

    return (
        <Form
            layout='vertical'
            colon={false}
            className='w-full [&>.ant-form-item]:mb-3'
            onFinish={onFinish}
            form={form}
            initialValues={{
                file: null,
                error_file: data?.error_files?.at(-1),
                uploaded_file: data?.uploaded_files?.at(-1),
            }}
        >
            <Form.Item>
                <p>
                    <span className='font-medium'>Job Type:</span>{' '}
                    {JOB_TYPES[`${data?.jobType}/${data?.actionType}`]?.label || ''}
                </p>
            </Form.Item>

            <Form.Item>
                <Tag variant={status.color as TagProps['variant']} icon={status.icon} className='w-full text-center'>
                    {status.text}
                </Tag>
            </Form.Item>

            {!!data && data?.validation === Statuses.SUCCESS && data?.summary && (
                <Form.Item>
                    <Card className='shadow-lg' title='Summary' loading={!data}>
                        {Object.entries(data?.summary || {}).map(([key, val]) => (
                            <p>
                                <span className='capitalize'>{key.replace(/[_-]/g, ' ')}</span>:{' '}
                                {key.includes('amount') ? getCurrencySymbol() : ''}val?.toString()
                            </p>
                        ))}
                    </Card>
                </Form.Item>
            )}

            {data?.validation === Statuses.FAILED && (
                <Form.Item
                    name='file'
                    valuePropName='fileList'
                    getValueFromEvent={normFile}
                    rules={[{ required: true, message: messages.FILE_REQUIRED }, FileValidatorRule]}
                >
                    <Upload.Dragger
                        name='files'
                        accept='.csv'
                        multiple={false}
                        maxCount={1}
                        customRequest={(info) => {
                            validateFile(info, `${data?.jobType}/${data?.actionType}`)
                        }}
                    >
                        <div className='py-8'>
                            <p className='ant-upload-drag-icon'>
                                <CloudUploadOutlined />
                            </p>
                            <p>Click or drag the revised CSV file to this area</p>
                        </div>
                    </Upload.Dragger>
                </Form.Item>
            )}

            {data && (
                <>
                    <Form.Item label={<span className='font-medium'>Error Files</span>}>
                        <div className='flex gap-x-2'>
                            <Form.Item name='error_file' className='w-1/2'>
                                <Select
                                    disabled={!data.error_files?.length}
                                    placeholder={!data.error_files?.length ? 'No files' : ''}
                                >
                                    {data.error_files?.reverse()?.map((file, i) => (
                                        <Select.Option key={file} value={file}>
                                            <Tooltip title={file.split('/').at(-1)}>
                                                <p>Error File {i + 1}</p>
                                            </Tooltip>
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Button
                                onClick={() =>
                                    downloadFromURL(
                                        form.getFieldValue('error_file'),
                                        `${data.id}_${data.error_files.indexOf(
                                            form.getFieldValue('error_file') + 1,
                                        )}.csv`,
                                    )
                                }
                                disabled={!data.error_files?.length}
                                className='w-1/2'
                            >
                                <DownloadOutlined />
                                Download
                            </Button>
                        </div>
                    </Form.Item>
                    <Form.Item label={<span className='font-medium'>Uploaded Files</span>}>
                        <div className='flex gap-x-2'>
                            <Form.Item name='uploaded_file' className='w-1/2'>
                                <Select
                                    disabled={!data.uploaded_files?.length}
                                    placeholder={!data.uploaded_files?.length ? 'No files' : ''}
                                >
                                    {data.uploaded_files?.reverse()?.map((file, i) => (
                                        <Select.Option key={file} value={file}>
                                            <Tooltip title={file.split('/').at(-1)}>
                                                <p>Uploaded File {i + 1}</p>
                                            </Tooltip>
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Button
                                onClick={() =>
                                    downloadFromURL(
                                        form.getFieldValue('uploaded_file'),
                                        `${data.id}_${data.uploaded_files.indexOf(
                                            form.getFieldValue('uploaded_file') + 1,
                                        )}.csv`,
                                    )
                                }
                                disabled={!data.uploaded_files?.length}
                                className='w-1/2'
                            >
                                <DownloadOutlined />
                                Download
                            </Button>
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <Tooltip title={cannotSubmit}>
                            <Button
                                onClick={() => form.submit()}
                                variant='primary'
                                className='w-full'
                                disabled={!!cannotSubmit}
                                loading={submitting}
                            >
                                {data?.validation === Statuses.FAILED ? 'Retry' : 'Publish'}
                            </Button>
                        </Tooltip>
                    </Form.Item>
                </>
            )}
        </Form>
    )
}
