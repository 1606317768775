import React, { useState, ReactNode, useMemo, useRef, useEffect } from "react";
import {
  Alert,
  AppstoreOutlined,
  Button,
  CarOutlined,
  ClusterOutlined,
  Divider,
  DollarOutlined,
  EditOutlined,
  EnvironmentOutlined,
  Form,
  Layout,
  LoginOutlined,
  Menu,
  message,
  PercentageOutlined,
  RadiusSettingOutlined,
  Select,
  SettingOutlined,
  StopOutlined,
  Switch,
} from "gokwik-ui-kit";
import { useNavigate, Routes, Route, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getMerchantDetails,
  getUserConfig,
  getUserDetails,
  isMerchantSelector,
} from "@store/user/selectors";
import { makeAPICall, titleCase, updateBreadcrumbs } from "@gokwik/utilities";
import Business from "./business";
import Personalization from "./personalization";
import LoginMobile from "./login-mobile";
import Address from "./address";
import Shipping from "./shipping";
import Offers from "./offers";
import ThirdParty from "./third-party";
import Workflows from "./workflows";
import Generic from "./generic";
import RtoIntervention from "./rto-intervention";
import styled from "styled-components";
const { Sider, Header } = Layout;
export const SectionHeader = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => (
  <div className="bg-white p-5 -mx-5 shadow-sm">
    <div className="font-bold mb-2 text-lg">{title}</div>
    <div className="text-gray-500">{description}</div>
  </div>
);
interface MenuItem {
  key: string;
  label: ReactNode;
  icon?: ReactNode;
  children?: MenuItem[];
  type?: "group";
  className?: string;
}

function createMenuItem(
  label: ReactNode,
  key: string,
  icon: ReactNode,
  className?: string,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    children,
    label,
    type,
    className,
    icon,
  };
}

interface CustomSwitchProps {
  title: string;
  description: string;
  value: boolean;
  switch_key: Array<string>;
  isDisabled: boolean;
  handleConfigChange: (...args: any[]) => void;
}

export const CustomSwitch: React.FC<CustomSwitchProps> = ({
  title,
  description,
  value,
  switch_key,
  isDisabled,
  handleConfigChange,
}) => {
  const [checkValue, setCheckValue] = useState(value);
  const handleChange = () => {
    setCheckValue(!checkValue);
    handleConfigChange(switch_key[0], switch_key[1], !checkValue);
  };
  return (
    <div className="flex justify-between items-start mb-5">
      <div>
        <div>{title}</div>
        <div className="text-gray-400 text-xs w-80">{description}</div>
      </div>
      <Form.Item className="inline-block flex text-sm m-2">
        <Switch
          checked={checkValue}
          onChange={handleChange}
          disabled={isDisabled}
        />
      </Form.Item>
    </div>
  );
};
export default function () {
  const merchant_details = useSelector(getMerchantDetails);
  const config = useSelector(getUserConfig);
  const route = useLocation();
  const navigate = useNavigate();
  const previousOpenedItems = useRef({
    openedKey: "",
    selectedKey: "",
  });
  const isMerchantUser = useSelector(isMerchantSelector);
  const user_details = useSelector(getUserDetails);
  const checkoutPlatformKeys = useMemo(
    () =>
      Object.entries(config?.supported_checkout_configs || {}).reduce(
        (result: string[], item: [string, boolean]) =>
          item[1] ? [...result, item[0]] : result,
        []
      ),
    [config]
  );
  const GIFT_CARD_OPTIONS = {
    custom: "CUSTOM",
    qwikcilver: "QWIKCILVER",
    disabled: "DISABLED",
  };
  const SKIP_TO_PAYMENT_MODE = {
    disabled: "DISABLED",
    one_address: "ONE_ADDRESS",
    multiple_address: "MULTIPLE_ADDRESS",
  };
  const COD_CONFIRMATION_MODE = {
    always: "ALWAYS",
    new_user: "NEW_USER",
    never: "NEVER",
    prompt: "PROMPT",
  };
  const ENABLE_DISCOUNT_MODE = {
    ENABLED: "ENABLED",
    DISABLED: "DISABLED",
    READ: "READ",
  };
  const [configApiResponse, setConfigApiResponse] = useState<any>({});
  const [checkoutPlatform, setCheckoutPlatform] = useState("");
  const initialConfigValues = {
    personalization: {
      logo: "",
      primary_bg_color: "#0565d6",
      primary_fg_color: "#ffffff",
      secondary_bg_color: "#000000",
      secondary_fg_color: "#ffffff",
      logoType: "upload",
    },
    login_mobile: {
      mobile_otp: true,
      truecaller_verification: true,
      whatsapp_otpless: false,
      enable_otp_for_gk_users: false,
      skip_otp_flow: false,
      sent_otp_on_whatsapp: true,
    },
    shipping: {
      shipping_information: [],
      show_edd: false,
      edd_message: "",
      edd_time: "",
    },
    offers: {
      is_one_click_apply_offer_enabled: false,
      discount_options: "ENABLED"
    },
    third_party: {
      enable_rewards: false,
      values: {
      twid: null,
      nector: null,
      gyftr: null,
      quickcilver: null,
      gravity: null
      }
    },
    workflows: {
      skip_to_payments: "DISABLED",
      cod_confirmation: "NEVER",
      onpl: true,
      one_click_checkout: false,
    },
    generic: {
      allow_giftcard: "DISABLED",
      exit_feedback: true,
      allow_users_gst: false,
      ms_clarity: false,
      custom_banner: false,
      custom_banner_messages: [],
      enable_ga_events: true,
      enable_fb_events: true,
    },
    rto_intervention: {
      enable_rto_api: true,
      cod_blocking: false,
      cod_captcha: false,
      captcha_title: "",
      captcha_message: "",
      cod_prompt: false,
      prompt_title: "",
      prompt_message: "",
    },
  }
  const [configs, setConfigs] = useState(initialConfigValues);
  useEffect(()=>{
     updateBreadcrumbs((prev) => [
      prev[0],
      {
        key: "checkout-platform/settings",
        text: "Checkout Platform Settings",
        href: "/checkout-platform/settings",
      }])
  },[])
  const handleConfigChange = (parent_key, child_key, value) => {
    setShowHeader(true);
    if (parent_key == "third_party") {
      setConfigs((prev_state) => {
        const resetValues = Object.keys(
          prev_state[parent_key].values || {}
        ).reduce(
          (acc, key) => ({
            ...acc,
            [key]: prev_state[parent_key].values[key] !== null ? false : null,
          }),
          {}
        );
        return {
          ...prev_state,
          [parent_key]: {
            ...prev_state[parent_key],
            values: {
              ...resetValues,
              [child_key]: value,
            },
          },
        };
      });
    } else {
      setConfigs((prev_state) => {
        return {
          ...prev_state,
          [parent_key]: {
            ...prev_state[parent_key],
            [child_key]: value,
          },
        };
      });
    }
  };
  const [showErrorPage, setShowError] = useState(false)
  const fetchConfigurationData = async () => {
    try{
    let response = await makeAPICall({
      method: "get",
      url:
        process.env.REACT_APP_BASE_URL +
        `/api/custom-merchants/${merchant_details.m_id}/config`,
    });
    if(response.status == 200)
    return response;
  else{
  setShowError(true)
  return null
  }
    }
    catch(e){
      setShowError(true)
      return null
    }
  };
  const [activeSection, setActiveSection] = useState("");
  const sectionRefs = useRef(new Map()); // To store refs for each section
  const settingsList = [
    // {
    //   title: 'Business Configurations',
    //   path: '/buiness',
    //   component: () => <Business />,
    //   icon: <AppstoreOutlined />
    // },
    {
      title: "Themes",
      path: "/personalize",
      component: () => (
        <Personalization
          merchantDetails={merchant_details}
          data={configs.personalization}
          handleConfigChange={handleConfigChange}
        />
      ),
      icon: <EditOutlined />,
    },
    {
      title: "Login/Mobile",
      path: "/log-mobile",
      component: () => (
        <LoginMobile
          data={configs.login_mobile}
          handleConfigChange={handleConfigChange}
        />
      ),
      icon: <LoginOutlined />,
    },
    // {
    //   title: 'Address',
    //   path: '/address',
    //   component: () => <Address />,
    //   icon: <EnvironmentOutlined />
    // },
    // {
    //   title: "Shipping",
    //   path: "/shipping",
    //   component: () => (
    //     <Shipping
    //       data={configs.shipping}
    //       handleConfigChange={handleConfigChange}
    //     />
    //   ),
    //   icon: <CarOutlined />,
    // },
    {
      title: "Offers & Benefits",
      path: "/offers",
      component: () => (
        <Offers data={configs.offers} handleConfigChange={handleConfigChange} />
      ),
      icon: <PercentageOutlined />,
    },
    // {
    //   title: 'Payments',
    //   path: '/payments',
    //   component: () => <Payments />,
    //   icon: <DollarOutlined />
    // },
    {
      title: "Workflows",
      path: "/workflows",
      component: () => (
        <Workflows
          data={configs.workflows}
          handleConfigChange={handleConfigChange}
        />
      ),
      icon: <ClusterOutlined />,
    },
    {
      title: "Generic",
      path: "/generic",
      component: () => (
        <Generic
          data={configs.generic}
          handleConfigChange={handleConfigChange}
          isMerchantUser={isMerchantUser}
        />
      ),
      icon: <SettingOutlined />,
    },
    // {
    //   title: "3rd Party Integrations",
    //   path: "/third-party",
    //   component: () => <ThirdParty data={configs.third_party} handleConfigChange={handleConfigChange}/>,
    //   icon: <RadiusSettingOutlined />,
    // },
    // {
    //   title: "RTO Interventions",
    //   path: "/rto",
    //   component: () => <RtoIntervention />,
    //   icon: <StopOutlined />,
    // },
  ];
  const menuItems = settingsList.map((item) =>
    createMenuItem(item.title, `/checkout/settings${item.path}`, item.icon)
  );
  useEffect(()=>{
     console.log("hello configs", configs)
  },[configs])
  const handleMenuSelection = ({ key }: any) => {
    const element = document.getElementById(key);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setActiveSection(key); // Update active section on click
  };
  const setApiValuesToForm = (configApiValues)=>{
    setConfigs((prev) => ({
      ...prev,
      personalization: {
        ...initialConfigValues.personalization,
        primary_bg_color:
        configApiValues.platform_config?.css_rules?.primary_bg_color ||
        initialConfigValues.personalization.primary_bg_color,
        primary_fg_color:
        configApiValues.platform_config?.css_rules?.primary_fg_color ||
        initialConfigValues.personalization.primary_fg_color,
        secondary_bg_color:
        configApiValues.platform_config?.css_rules?.secondary_bg_color ||
        initialConfigValues.personalization.secondary_bg_color,
        secondary_fg_color:
        configApiValues.platform_config?.css_rules?.secondary_fg_color ||
        initialConfigValues.personalization.secondary_fg_color,
        logo: configApiValues.platform_config?.logo ?? initialConfigValues.personalization.logo,
      },
      login_mobile: {
        ...initialConfigValues.login_mobile,
        truecaller_verification:
        configApiValues.platform_config?.truecaller_enabled ??
        initialConfigValues.login_mobile.truecaller_verification,
        whatsapp_otpless:
        configApiValues.auth?.is_whatsapp_login_enabled ??
        initialConfigValues.login_mobile.whatsapp_otpless,
        enable_otp_for_gk_users:
        configApiValues.auth?.otp_for_all ??
        initialConfigValues.login_mobile.enable_otp_for_gk_users,
        skip_otp_flow:
        configApiValues.auth?.skip_otp ?? initialConfigValues.login_mobile.skip_otp_flow,
        sent_otp_on_whatsapp:
        configApiValues.platform_config?.is_whatsapp_auth_otp_enabled ??
        initialConfigValues.login_mobile.sent_otp_on_whatsapp,
      },
      offers: {
        ...initialConfigValues.offers,
        is_one_click_apply_offer_enabled: configApiValues.platform_config?.is_one_click_apply_offer_enabled ??
        initialConfigValues.offers.is_one_click_apply_offer_enabled,
        discount_options:
        ENABLE_DISCOUNT_MODE[
          configApiValues.platform_config?.discount_options
        ] ?? initialConfigValues.offers.discount_options,
      },
      workflows: {
        ...initialConfigValues.workflows,
        skip_to_payments:
          SKIP_TO_PAYMENT_MODE[configApiValues.platform_config?.skip_to_payment_mode] ??
          initialConfigValues.workflows.skip_to_payments,
        cod_confirmation:
          COD_CONFIRMATION_MODE[
            configApiValues.platform_config?.cod_confirmation_mode
          ] ?? initialConfigValues.workflows.cod_confirmation,
        onpl: configApiValues.platform_config?.is_onpl_enabled ?? initialConfigValues.workflows.onpl,
        one_click_checkout:
        configApiValues.platform_config?.is_one_click_enabled ??
        initialConfigValues.workflows.one_click_checkout,

      },
      generic: {
        ...initialConfigValues.generic,
        allow_giftcard:
          GIFT_CARD_OPTIONS[configApiValues.platform_config?.gift_card_options] ??
          initialConfigValues.generic.allow_giftcard,
        exit_feedback:
        configApiValues.platform_config?.enable_exit_feedback ??
        initialConfigValues.generic.exit_feedback,
        allow_users_gst:
        configApiValues.platform_config?.is_gst_enabled ??
        initialConfigValues.generic.allow_users_gst,
        ms_clarity:
        configApiValues.platform_config?.is_msclarity_enabled ??
        initialConfigValues.generic.ms_clarity,
        custom_banner:
        configApiValues.platform_config?.is_custom_banner_enabled ??
        initialConfigValues.generic.custom_banner,
        custom_banner_messages:
        configApiValues.platform_config?.custom_banner_messages ??
        initialConfigValues.generic.custom_banner_messages,
      },
      third_party:{
        ...initialConfigValues.third_party,
        enable_rewards : configApiValues.platform_config?.rewards_config?.is_enabled ??
        initialConfigValues.third_party.enable_rewards,
        values:{
        twid :  configApiValues.platform_config?.rewards_config?.providers?.twid ??
        initialConfigValues.third_party.values.twid,
        quickcilver: configApiValues.platform_config?.rewards_config?.providers?.qwik_cilver ??
        initialConfigValues.third_party.values.quickcilver,
        nector: configApiValues.platform_config?.rewards_config?.providers?.nector ??
        initialConfigValues.third_party.values.nector,
        gravity: configApiValues.platform_config?.rewards_config?.providers?.gravity ??
        initialConfigValues.third_party.values.gravity,
        gyftr: configApiValues.platform_config?.rewards_config?.providers?.gyftr ??
        initialConfigValues.third_party.values.gyftr,
        }
      }
    }));
  }
  const cancelSaveConfigDetails = () =>{
    setApiValuesToForm(configApiResponse);
    setShowHeader(false)
  }
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id); // Set the active section based on what's in view
          }
        });
      },
      { root: null, rootMargin: "0px", threshold: 0.5 } // Adjust threshold as needed
    );

    // Observe each section
    sectionRefs.current.forEach((ref, key) => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect(); // Cleanup observer on unmount
  }, [sectionRefs]);
  const saveConfigDetails = async () => {
    let body = convertStateToApiBody();
    try {
      let response = await makeAPICall({
        method: "put",
        url:
          process.env.REACT_APP_BASE_URL +
          `/api/custom-merchants/${merchant_details.m_id}/config`,
        payload: body,
      });
      if (response.status == 200){
        message.success("Configurations saved successfully");
        setConfigApiResponse(response.data.data);
      }
      else message.error("some error occured");
    } catch (e) {
      console.log("configuration save error", e);
      message.error("some error occured");
    }
  };
  const convertStateToApiBody = () => {
    const platform_config = {
      css_rules: {
        primary_bg_color: configs.personalization.primary_bg_color,
        primary_fg_color: configs.personalization.primary_fg_color,
        secondary_bg_color: configs.personalization.secondary_bg_color,
        secondary_fg_color: configs.personalization.secondary_fg_color,
      },
      logo: configs.personalization.logo,
      truecaller_enabled: configs.login_mobile.truecaller_verification,
      is_whatsapp_auth_otp_enabled: configs.login_mobile.sent_otp_on_whatsapp,
      discount_options: configs.offers.discount_options,
      is_one_click_apply_offer_enabled: configs.offers.is_one_click_apply_offer_enabled,
      skip_to_payment_mode: configs.workflows.skip_to_payments,
      cod_confirmation_mode: configs.workflows.cod_confirmation,
      is_onpl_enabled: configs.workflows.onpl,
      is_one_click_enabled: configs.workflows.one_click_checkout,
      gift_card_options: configs.generic.allow_giftcard,
      enable_exit_feedback: configs.generic.exit_feedback,
      is_gst_enabled: configs.generic.allow_users_gst,
      is_msclarity_enabled: configs.generic.ms_clarity,
      is_custom_banner_enabled: configs.generic.custom_banner,
      custom_banner_messages: configs.generic.custom_banner_messages,
      rewards_config : {
        ...configApiResponse.platform_config?.rewards_config,
       ...(Object.values(configs.third_party.values).filter(item=>item!==null).length > 0 && {providers :{
          ...configApiResponse.platform_config?.rewards_config?.providers,
          ...( configs.third_party.values.twid !== null &&  {twid : configs.third_party.values.twid}),
          ...( configs.third_party.values.nector !== null &&  {nector: configs.third_party.values.nector}),
          ...(configs.third_party.values.quickcilver !== null &&  {qwik_cilver: configs.third_party.values.quickcilver}),
          ...(configs.third_party.values.gyftr !== null &&  {gyftr: configs.third_party.values.gyftr}),
          ...(configs.third_party.values.gravity !== null && { gravity: configs.third_party.values.gravity }),
        }
      })
    }
    };

    const auth = {
      is_whatsapp_login_enabled: configs.login_mobile.whatsapp_otpless,
      otp_for_all: configs.login_mobile.enable_otp_for_gk_users,
      skip_otp: configs.login_mobile.skip_otp_flow,
    };

    return {
      platform_config,
      auth,
    };
  };
  const [showHeader, setShowHeader] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      let response = await fetchConfigurationData();
      if(response){
      setConfigApiResponse(response.data.data);
      setApiValuesToForm(response.data.data)
   }
    };
    fetchData();
  }, []);

  return (
    <>
    { showErrorPage ?
      <Alert 
      message="Some Error Occured!"
      description="Some Error occured while fetching your configurations. Please try again after sometime or contact support."
      type="error"
      showIcon
      style={{marginLeft:"auto",marginRight:"auto",height:"110px",marginTop:"20px"}}
      /> :
    <div className="w-full bg-white" style={{borderTop:"2px solid #c7b5b55c"}}>
      <Layout className="h-full" style={{ background: "#F7FAFF" }}>
        <Sider
          width={250}
          className="bg-white h-full border-0 border-r-2 fixed overflow-hidden"
          style={{ background: "white",borderStyle:"solid", borderColor:"#c7b5b55c" }}
        >
          <div className="menu-outer">
            <Menu
              onClick={handleMenuSelection}
              items={menuItems}
              inlineCollapsed={true}
              mode="inline"
              theme="light"
              style={{ paddingLeft: "11px", borderInlineEnd: "none" }}
              selectedKeys={[activeSection]}
            />
          </div>
        </Sider>

        <Layout className="overflow-auto" style={{ background: "#F7FAFF" }}>
          { showHeader && <Header className="sticky top-0 z-1 w-full flex items-center bg-gradient-to-r from-white to-[#dcecfb] h-12 text-right justify-end">
            <Button type="default" className="m-2" onClick={cancelSaveConfigDetails}>
              Cancel{" "}
            </Button>
            <Button type="primary" className="m-2" onClick={saveConfigDetails}>
              Save Details{" "}
            </Button>
          </Header>}
          {settingsList.map((item, index) => (
            <div
              id={`/checkout/settings${item.path}`}
              key={index}
              ref={(el) =>
                sectionRefs.current.set(`/checkout/settings${item.path}`, el)
              }
            >
              <item.component />
            </div>
          ))}
        </Layout>
      </Layout>
    </div>}
    </>
  );
}
