const APIEndPoints = {
    getFilters: '/api/dashboard/dashboard_filters',
    getOverviewRTOInsightData: '/v1/shopify/rto/insights',
    getOverviewOrdersInsightData: '/v1/shopify/order/insights',
    getConfigData: '/v1/shopify/config',
    getOverviewTableData: '/v1/shopify/table',
    getTablesData: '/v1/shopify/table',
    updateShopifyAnalytics: '/v1/shopify/analytics',
    userEvent: '/v3/api/dashboard/event',
    getStateCityPincode: '/v1/shopify/state-city-pincode',
    getRTOAnalyticsData: '/v1/shopify/rto/analytics',

    //rto actions endpoints
    getWorkFlow: '/v3/api/dashboard/kwikai/get-workflows',
    createWorkflow: '/v3/api/dashboard/kwikai/create-workflow',
    updateWorkflow: '/v3/api/dashboard/kwikai/update-workflow',
    utmSuggestions: '/v1/kwikai/utm-suggestions',
    abTestMetrics: '/v1/kwikai/workflow/abTest/metrics',
    getProductsList: '/v3/api/dashboard/discount/items/search-products',
    getRiskReasons: '/v1/kwikai/risk-reasons',
    addressSuggestions: '/v1/kwikai/address-suggestions',
    workflowImpactMetrics: '/v1/kwikai/workflow/impact/metrics',

    //rto-kwikchat
    getRTOKwikChatOrders: '/v1/kwikchat/orders',
    updateKCVerificationStatus: '/api/v2/customer-verification',
    merchantConfigs: '/api/dashboard/merchant-config',
    getEidaReccomendationStatus: '/v1/kwikai/workflow/recommendations-status',
    getRecommendations: '/v1/kwikai/workflow/recommendations',

    //awb-flows
    getUploadUrl: '/upload/get_url',
    notifyUpload: '/upload/notify',
    getIngestionStatus: '/upload/status',
    getAwbFillRate: '/v1/awb/fill-rate',
}
export default APIEndPoints
