import { Alert, Checkbox, Col, Form, InputNumber } from 'gokwik-ui-kit'

const SetupShippingDiscount = (form: { form: any }) => {
    const enableShippingExclusion = Form.useWatch('enableShippingExclusion', form.form)
    return (
        <Col span={24}>
            <p className='text-sm font-semibold w-full pb-2'>Shipping rates</p>

            <Form.Item name='enableShippingExclusion' valuePropName='checked'>
                <Checkbox>Exclude shipping rates over a certain amount</Checkbox>
            </Form.Item>
            {enableShippingExclusion && (
                <Form.Item
                    label='Value'
                    name={'shippingDiscountAmount'}
                    rules={[
                        { required: true, message: 'Please input your shipping discount value!' },
                        {
                            validator: (_, value) => {
                                if (value <= 0) {
                                    return Promise.reject('Value should be greater than 0')
                                }
                                return Promise.resolve()
                            },
                        },
                    ]}
                >
                    <InputNumber prefix='₹' min={0} className='w-48' precision={2} />
                </Form.Item>
            )}
            <Alert
                message='Free shipping discounts will be applicable for all countries by default.'
                type='info'
                showIcon
                className='mt-2'
            />
        </Col>
    )
}

export default SetupShippingDiscount
