import { createSlice } from '@reduxjs/toolkit'
export interface IntegrationService extends APISchema {}
export type integrationServiceList = [IntegrationService]
interface ThirdPartyServiceProvider {
    id: string
    isEnabled: boolean
}
interface EventEngine {
    id: string
}

export interface ConfigObject extends postServiceConfigSchema {
    id: string
    isEnabled: boolean
    eventEngine: EventEngine
}

export interface postServiceConfigSchema {
    identifier: string
    events: string[]
    marketingEvents: string[]
    config: null | any
    rules?: {
        phoneFormat: string
    }
}
export const emptyServiceJSONObject: postServiceConfigSchema = {
    identifier: '',
    events: [],
    marketingEvents: [],
    config: null,
    rules: null,
}

interface APISchema {
    id: string
    name: string
    identifiers: string[]
    events: string[]
    marketingEvents: string[]
    type: string
    displayName: string
    config: null | any
    rules?: null | any // Assuming config can be null or have other types, replace 'any' as needed
    logo: string
    showOnDashboard: boolean
    thirdPartyServiceProviders: ThirdPartyServiceProvider[]
}
export interface activeConfigSchema extends ConfigObject {}

const integrationSlice = createSlice({
    name: 'Integration Services',
    initialState: {
        allAvailableService: [],
        activeServiceConfig: {} as ConfigObject,
    },
    reducers: {
        setAllAvailableService(state, action) {
            state.allAvailableService = action.payload
        },
        setActiveServiceConfig(state, action) {
            state.activeServiceConfig = action.payload
        },
    },
})

export const { setAllAvailableService, setActiveServiceConfig } = integrationSlice.actions
export default integrationSlice.reducer
