import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { getUserData, getMerchantDetails } from '@store/user/selectors'
import { Row, Col, Button, message } from 'gokwik-ui-kit'
import { useSelector } from 'react-redux'

export const Integration_1 = ({ setCurrentIntegrationScreen, navigateToStep, getCodeForManualTheme }) => {
    const userDetails = useSelector(getUserData)
    const merchant_details = useSelector(getMerchantDetails)

    return (
        <div className='mt-6 mx-12 p-4 rounded-lg bg-white'>
            <Row gutter={32}>
                <Col span={12}>
                    <div className='flex flex-col gap-6'>
                        <div>
                            <span className='text-xl font-semibold'>
                                Integrating the app: Use <span className='text-orange-400'>either</span> Theme App
                                Extension <span className='text-orange-400'>or</span> Manual Integration
                            </span>{' '}
                            <br />
                            {/* <span className='text-slate-500 text-s'>How and when to add theme app extension?</span> */}
                        </div>

                        <div className='rounded w-full text-center'>
                            <video key={1} width='100%' autoPlay controls={true} loop>
                                <source src={'https://assets.gokwik.co/videos/OnboardingVideo.mp4'} type='video/mp4' />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className='flex flex-col gap-16'>
                        <div className='flex flex-col gap-8'>
                            <div className='flex flex-col gap-4'>
                                <div className='text-xl font-semibold'>Option 3.1: Add Shopify App Extension</div>
                                <p className='text-neutral-500 font-normal'>
                                    When using <strong>theme Dawn</strong>, opt for <strong>theme app extension</strong>
                                    . This step automatically adds our code to your website, making it ready for launch.
                                </p>
                                <Button
                                    className='w-1/2'
                                    variant='primary'
                                    onClick={async () => {
                                        const response = await makeAPICall({
                                            method: 'get',
                                            url: process.env.REACT_APP_BASE_URL + APIEndPoints.shopifyThemeExtensionURL,
                                        })

                                        if (
                                            response?.data?.status_code === 200 &&
                                            response?.data?.data?.theme_extension_url
                                        ) {
                                            logEvent(
                                                'kp_integration_extension',
                                                'click',
                                                'intergration_1_screen',
                                                userDetails?.userDetails?.email,
                                                merchant_details?.m_id,
                                                merchant_details?.short_name,
                                            )
                                            window.open(response?.data?.data?.theme_extension_url)
                                        } else {
                                            message.error(
                                                'Theme extension URL could not be generated. Couldn’t redirect to shopify themes page',
                                            )
                                        }
                                        navigateToStep(null, 3)
                                        setCurrentIntegrationScreen('add_theme_automatically')
                                    }}
                                >
                                    Activate Theme App Extension
                                </Button>
                            </div>
                            <span className='text-center font-bold text-base'>OR</span>
                            <div className='flex flex-col gap-4'>
                                <div className='text-xl font-semibold'>
                                    Option 3.2: Manually Integrate our app with your Shopify Store
                                </div>
                                {/* <p className='text-neutral-500 font-normal'>
                                    Use the <strong>code snippet provided</strong> and <strong>add</strong> to your
                                    Shopify store. This is the advisable process for all <strong>custom themes</strong>.
                                </p> */}
                                <p className='text-neutral-500 font-normal'>
                                    You will be provided a piece of code and instructions to add it to your Shopify
                                    store. This is advisable for all custom themes.
                                </p>
                                <Button
                                    className='w-1/2'
                                    variant='primary'
                                    onClick={() => {
                                        getCodeForManualTheme()
                                        navigateToStep(null, 3)
                                        setCurrentIntegrationScreen('add_theme_manually')
                                    }}
                                >
                                    Manually Integrate App
                                </Button>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
