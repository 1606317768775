import { Button, InfoCircleOutlined, Tooltip } from 'gokwik-ui-kit'
import './save-bar.css'

const SaveBar = ({ handleSave, handleDiscard }) => {
    return (
        <span className='flex justify-center gap-3 saveBar'>
            <div className='flex items-center gap-1 text-white'>
                <Tooltip>
                    <InfoCircleOutlined className='ml-2 mr-2' />{' '}
                </Tooltip>
                <p className='text-white text-base font-normal'>Unsaved Changes</p>
            </div>
            <div className='flex gap-3'>
                <Button type='default' onClick={handleDiscard}>
                    Discard
                </Button>
                <Button type='primary' onClick={handleSave} className='bg-green-500 text-white'>
                    Save
                </Button>
            </div>
        </span>
    )
}

export default SaveBar
