import { useState, useEffect, useCallback, useRef } from 'react'
import APIEndPoints from '../constants/apiEndpoints'
import { makeAPICall } from '@gokwik/utilities'
import { IShopifyMerchantConfig } from '../interface'
import { message } from 'gokwik-ui-kit'
import { useSelector } from 'react-redux'
import { getMerchantDetails } from '@store/user/selectors'
import { useLocation } from 'react-router-dom';
// Simple in-memory cache
const cache: { [m_id: string]: { [url: string]: IShopifyMerchantConfig } } = {}

interface UseConfigOptions {
    configGetUrl?: string
    configSaveUrl?: string
    useCache?: boolean
    fireSuccessEvent?: Function | null;
    fireFailureEvent?: Function | null;
}

const useShopifyMerchantConfigApi = ({
    configGetUrl = APIEndPoints.shopifyMerchantConfigs,
    useCache = false,
    configSaveUrl = APIEndPoints.shopifyMerchantConfigs,
    fireSuccessEvent = null,
    fireFailureEvent = null
}: UseConfigOptions = {}) => {
    const [config, setConfig] = useState<IShopifyMerchantConfig | null>(null)
    const [loading, setLoading] = useState(false)
    const merchantDetails = useSelector(getMerchantDetails)
    const firstRender = useRef(true)
    const location = useLocation();
    const currentPagePath = location.pathname;
    const pageName = currentPagePath.split('/').pop();
    const fetchConfig = useCallback(
        async (refetch?: boolean) => {
            setLoading(true)
            try {
                if (!refetch && useCache && cache[merchantDetails.m_id]?.[configGetUrl]) {
                    setConfig(cache[merchantDetails.m_id][configGetUrl])
                } else {
                    const response = await makeAPICall({
                        method: 'get',
                        url: process.env.REACT_APP_BASE_URL + configGetUrl,
                    })
                    cache[merchantDetails.m_id] = { [configGetUrl]: JSON.parse(JSON.stringify(response.data.data)) } // Update cache
                    setConfig(response.data.data)
                }
            } catch (error) {
                console.error('Failed to fetch config:', error)
            } finally {
                setLoading(false)
            }
        },
        [configGetUrl, useCache],
    )

    const saveConfig = useCallback(
        async (newConfig: Partial<IShopifyMerchantConfig>, callback?: () => void) => {
            setLoading(true)
            try {
                const res = await makeAPICall({
                    method: 'post',
                    url: process.env.REACT_APP_BASE_URL + configSaveUrl,
                    payload: newConfig,
                })
                if (res.success) {
                    if (callback) {
                        callback()
                    }
                    if (typeof fireSuccessEvent === 'function') {
                        fireSuccessEvent({
                            newConfig: newConfig
                        });
                    }
                    refetch()
                    message.success('Settings saved successfully')
                } else {
                    if (typeof fireFailureEvent === 'function') {
                        fireFailureEvent({
                            newConfig: newConfig,
                            errorMessage: res.response?.data?.message || 'Failed to save settings'
                        });
                    }
                    message.error(res.response?.data?.message || 'Failed to save settings')
                    console.error('Failed to save config:', res.response?.data?.message)
                }
            } catch (error) {
                if (typeof fireFailureEvent === 'function') {
                    fireFailureEvent({
                        newConfig: newConfig,
                        errorMessage: error?.message
                    });
                }
                message.error('Failed to save settings')
                console.error('Failed to save config:', error)
            } finally {
                setLoading(false)
            }
        },
        [configGetUrl],
    )

    const sendRatingFeedback = async (review: string, rating: number, popupModal: boolean = false, setReview: any, setIsReviewSubmitted?: any, reviewFlowEvents = null, source?: string) => {
        let successMsg = 'Review Submitted successfully'
        let errMsg = 'Failed to submit review'
    
        const payload = {
            event_name: 'KWIK_CHECKOUT_DASHBOARD_RATING',
            feedback: review?.trim(),
            rating: rating
        }
        reviewFlowEvents.fireClickedEvent({
            Rating: rating, 
            feedback : review ? review : null, 
            ratingSource: source ? source : popupModal ? "pop-up" : "fix-modal",
        });
        
        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.shopifyReviewAndRating,
                payload,
            })
            if (response.data.status_code === 200) {
                reviewFlowEvents.fireSuccessEvent({
                    Rating: rating, 
                    feedback : review ? review : null, 
                    ratingSource: source ? source : popupModal ? "pop-up" : "fix-modal",
                })
                if (popupModal) { setIsReviewSubmitted(true) }
                setReview("");
                localStorage.setItem('isReviewSubmitted', 'true');
                message.success(successMsg)
            } else {
                reviewFlowEvents.fireFailureEvent({
                    Rating: rating, 
                    feedback : review ? review : null, 
                    ratingSource: source ? source : popupModal ? "pop-up" : "fix-modal",
                    errorMessage: "Failed to upload review",
                    apiStatus: response.data.status_code
                })
            }
        } catch (error) {
            reviewFlowEvents.fireFailureEvent({
                Rating: rating, 
                feedback : review ? review : null, 
                ratingSource: source ? source : popupModal ? "pop-up" : "fix-modal",
                errorMessage: error.message
            })
            console.error('Error:', error)
            message.error(errMsg)
        }
    }

    const refetch = useCallback(() => {
        fetchConfig(true)
    }, [fetchConfig])

    useEffect(() => {
        fetchConfig()
    }, [fetchConfig])

    useEffect(() => {
        if (!Object.keys(cache).includes(merchantDetails?.m_id) && !firstRender.current) {
            refetch()
        }
    }, [merchantDetails?.m_id])

    return { config, loading, saveConfig, refetch, sendRatingFeedback }
}

export default useShopifyMerchantConfigApi
