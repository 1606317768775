import { downloadFromURL, filterStore, makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { AnyAction, Dispatch, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { requestMapDiscount, responseMapDiscount, shopifyResponseMap } from './api'
import { message } from 'gokwik-ui-kit'
import { DiyDiscountListing, SearchedProduct } from '@library/utilities/interface'
import { RootStoreType } from '..'

type State = {
    manual_discounts: any
    discount_data: any
    shopify_discount_data: any
    bulk_discounts: any
    summary: any
}

const initialState: State = {
    manual_discounts: {
        discounts: [],
        total: 0,
    },
    summary: {},
    shopify_discount_data: {},
    discount_data: {},
    bulk_discounts: {
        discounts: [],
        total: 0,
    },
}

export const fetchManualDiscountsDataAsync = createAsyncThunk(
    'discounts/fetchManualDiscountsData',
    async ({ params = {} }: { params?: { [key: string]: any } }, { rejectWithValue, getState }) => {
        const response = await makeAPICall({
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.diyDiscounts.discounts,
            method: 'get',
            params,
        })
        if (response.success) {
            try {
                if (params.bulkDiscount) {
                    const merchantId = (getState() as RootStoreType).user.merchantDetails.m_id
                    const bulkResponse = await fetchAllBulkCampaigns({ merchantId })
                    if (bulkResponse) {
                        response.data.data.discounts = response.data.data.discounts.map(
                            (discount: DiyDiscountListing) => ({
                                ...discount,
                                bulk_campaign: bulkResponse.find(
                                    (bulk) => bulk.campaign_id === discount.bulk_campaign_id,
                                ),
                            }),
                        )
                    }
                }
            } catch (error) {
                console.warn(error)
            }
            return {
                isBulkDiscount: params.bulkDiscount,
                res: response.data.data,
            }
        } else return rejectWithValue(response)
    },
)

export const handleDownloadBuk = async (discount: DiyDiscountListing, mid: string) => {
    try {
        let preSignedURLResponse = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BULK_DISCOUNT_URL + APIEndPoints.bulkDiscounts.presignedUrl,
            payload: {
                merchant_id: mid,
                campaign_id: discount.bulk_campaign.campaign_id,
                url: discount.bulk_campaign.file_url,
                operation_type: 'FETCH',
            },
        })
        if (preSignedURLResponse?.success && preSignedURLResponse.data?.data?.presignedUrl) {
            downloadFromURL(preSignedURLResponse.data?.data?.presignedUrl, discount.bulk_campaign.code)
        }
    } catch (err) {
        console.warn(err)
    }
}

export const handleDeleteBulk = async (discount: DiyDiscountListing, mid: string) => {
    try {
        let response = await makeAPICall({
            method: 'delete',
            url: process.env.REACT_APP_BULK_DISCOUNT_URL + APIEndPoints.bulkDiscounts.createDiscount,
            params: {
                merchantId: mid,
                campaignId: discount.bulk_campaign.campaign_id,
            },
        })
        if (response?.success) {
            message.success('Discount deleted successfully')
            return response
        } else {
            message.error('Delete Error: Something went wrong')
        }
    } catch (err) {
        console.warn(err)
    }
}

export const deleteDiscount = (discount: DiyDiscountListing, mid?: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        let response = await makeAPICall({
            method: 'patch',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.diyDiscounts.discount + `/${discount.id}`,
            payload: { is_deleted: true, page_type: 'LISTING_PAGE', status: 'INACTIVE' },
        })
        if (response?.success) {
            if (discount.bulk_campaign_id && mid) {
                let bulkResponse = await handleDeleteBulk(discount, mid)
                if (bulkResponse?.success) {
                    message.success('Discount deleted successfully')
                    return response
                }
            }
            message.success('Discount deleted successfully')
            return response
        } else {
            message.error('Delete Error: Something went wrong')
        }
    } catch (err) {
        console.warn(err)
    }
}

export const fetchShopifyDiscountsDataAsync = createAsyncThunk(
    'discounts/fetchShopifyDiscountsData',
    async ({ title }: { title: string }, { rejectWithValue }) => {
        const response = await makeAPICall({
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.diyDiscounts.getShopifyCode,
            method: 'get',
            params: { title },
        })

        if (response.success) {
            const data = await shopifyResponseMap({ title, ...response.data.data.graphQLDiscountData })

            return {
                ...data,
                shopifyDiscountId: response.data.data.discountId,
                priceRuleId: response.data.data.discountId.split('DiscountCodeNode/')[1],
                discountProgress: 'import',
            }
        } else {
            const data = response.response.data.data
            if (data === 'not found') {
                return {
                    discountProgress: 'continue',
                }
            } else {
                return {
                    discountProgress: 'exists',
                }
            }
        }
    },
)

const fetchBulkCampaign = async (bulk_campaign_id: string, mid: string) => {
    const response = await makeAPICall({
        url: process.env.REACT_APP_BULK_DISCOUNT_URL + APIEndPoints.bulkDiscounts.createDiscount,
        method: 'get',
        params: { campaignId: bulk_campaign_id, merchantId: mid },
    })
    if (response.success) {
        return response
    }
}

const fetchAllBulkCampaigns = async (parameters: any) => {
    try {
        let response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BULK_DISCOUNT_URL + APIEndPoints.bulkDiscounts.getDiscountsList,
            params: parameters,
        })

        if (response?.success) {
            return response?.data?.data
        }
    } catch (err) {
        console.warn(err)
    }
}

export const fetchDiscountDataAsync = createAsyncThunk(
    'discounts/fetchDiscountData',
    async ({ id, name }: { id?: string; name?: string }, { rejectWithValue, getState }) => {
        const mid = (getState() as RootStoreType).user.merchantDetails.m_id
        const response = await makeAPICall({
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.diyDiscounts.discount,
            method: 'get',
            params: { id, name },
        })
        if (response.success) {
            const data = response.data.data

            if (data.bulk_campaign_id) {
                const bulkResponse = await fetchBulkCampaign(data.bulk_campaign_id, mid)
                if (bulkResponse?.success) {
                    response.data.data.bulk_campaign = bulkResponse.data.data
                }
            }
            if (name) {
                return { ...response.data.data, isSearch: true }
            }
            return { discountProgress: 'continue', ...(await responseMapDiscount(response.data.data)) }
        } else {
            message.error(response?.response?.data?.data || 'Failed to fetch discount')
            return rejectWithValue(response)
        }
    },
)

export const fetchShopifyCollectionsOrProducts = async ({
    searchType = 'products',
    title,
}: {
    searchType: 'products' | 'collections'
    title: string
}) => {
    const response = await makeAPICall({
        url:
            process.env.REACT_APP_BASE_URL +
            (searchType === 'products' ? APIEndPoints.productSearch : APIEndPoints.diyDiscounts.collection),
        method: 'get',
        params: searchType === 'products' ? { name: title } : { title },
        skipMode: true,
    })
    if (searchType === 'products') {
        response.data.data = response.data.data.filter((product: SearchedProduct) => product.status === 'active')
    }
    return response
}

export const fetchCustomerSegments = () => async (dispatch: Dispatch<AnyAction>) => {
    const response = await makeAPICall({
        url: process.env.REACT_APP_BASE_URL + APIEndPoints.diyDiscounts.customerSegments,
        method: 'get',
    })
    return response
}

export const saveDiscount =
    (data: any, page: string) => async (dispatch: Dispatch<AnyAction>, getState: () => RootStoreType) => {
        const discountData = getState().discounts.discount_data
        const status = page === 'listing' ? data.status : discountData.status
        let payload = await requestMapDiscount[page]({ ...data, status }, discountData)
        // Adjust the path to your state variable

        if (discountData.isImported) {
            const eligibility = await requestMapDiscount.customerEligibility(discountData)
            const conditions = requestMapDiscount.conditions(discountData)
            payload.starts_at = conditions.starts_at
            payload.customer_selection = eligibility.customer_selection
        }
        if (payload.skipApiCall) {
            dispatch(updateDiscountData({ ...data }))

            return { success: true }
        }
        const response = await makeAPICall({
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.diyDiscounts.discount + (data.id ? `/${data.id}` : ''),
            method: data.id ? 'patch' : 'post',
            payload,
        })

        if (response.success) {
            const priceRuleId = response.data?.data?.shopifyDiscountId?.split('DiscountCodeNode/')[1]
            const discountData = {
                ...data,
                status: response?.data?.data?.status || data.status || status,
            }
            if (priceRuleId) {
                discountData.priceRuleId = priceRuleId
            }
            if (!data.id) {
                discountData.id = response.data.data.id
            }
            dispatch(updateDiscountData({ ...discountData }))
            return { ...response }
        } else {
            message.error(response?.response?.data?.data || 'Failed to save discount')
        }
    }

export const fetchAutomaticDiscounts = () => async (dispatch: Dispatch<AnyAction>) => {
    const response = await makeAPICall({
        url: process.env.REACT_APP_BASE_URL + APIEndPoints.diyDiscounts.automaticDiscounts,
        method: 'get',
    })
    return response
}

const discountsSlice = createSlice({
    name: 'discountsSlice',
    initialState,
    reducers: {
        updateDiscountData: (state, action) => {
            state.discount_data = {
                ...state.discount_data,
                ...action.payload,
            }
            console.log(state.discount_data)
        },
        setDiscountData: (state, action) => {
            state.discount_data = action.payload
        },
        setSummary: (state, action) => {
            state.summary = action.payload
        },
        updateSummary: (state, action) => {
            state.summary = {
                ...state.summary,
                ...action.payload,
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchManualDiscountsDataAsync.fulfilled, (state, action) => {
                if (action.payload.isBulkDiscount) {
                    state.bulk_discounts = {
                        discounts: action.payload.res.discounts,
                        total: action.payload.res.totalCount,
                    }
                } else {
                    state.manual_discounts = {
                        discounts: action.payload.res.discounts,
                        total: action.payload.res.totalCount,
                    }
                }
            })
            .addCase(fetchShopifyDiscountsDataAsync.fulfilled, (state, action) => {
                state.discount_data = {
                    ...state.discount_data,
                    ...action.payload,
                }
            })
            .addCase(fetchDiscountDataAsync.fulfilled, (state, action) => {
                state.discount_data = {
                    ...state.discount_data,
                    ...action.payload,
                }
                state.summary = {
                    ...state.summary,
                    ...action.payload,
                }
            })
    },
})

export const { updateDiscountData, setDiscountData, updateSummary, setSummary } = discountsSlice.actions

export const emptyDiscountData = () => (dispatch: Dispatch<AnyAction>) => {
    dispatch(setDiscountData({}))
}

export default discountsSlice.reducer
