export const OperatorObjects = {
    EQ: {
        label: 'Equals',
        value: 'EQ',
    },
    NEQ: {
        label: 'Not Equals',
        value: 'NEQ',
    },
    GT: {
        label: 'Greater Than',
        value: 'GT',
    },
    GTE: {
        label: 'Greater Than Or Equals',
        value: 'GTE',
    },
    LT: {
        label: 'Less Than',
        value: 'LT',
    },
    LTE: {
        label: 'Less Than Or Equals',
        value: 'LTE',
    },
    CONTAINS: {
        label: 'Contains',
        value: 'CONTAINS',
    },
    DOES_NOT_CONTAINS: {
        label: 'Does Not Contain',
        value: 'DOES_NOT_CONTAINS',
    },
    EMPTY: {
        label: 'Is Empty',
        value: 'EMPTY',
    },
    NON_EMPTY: {
        label: 'Is Not Empty',
        value: 'NON_EMPTY',
    },
    EQUALS: {
        label: 'Equals',
        value: 'EQUALS',
    },
    NOT_EQUALS: {
        label: 'Not Equals',
        value: 'NOT_EQUALS',
    },
    DOES_NOT_CONTAIN: {
        label: 'Does Not Contain',
        value: 'DOES_NOT_CONTAIN',
    },
    IN: {
        label: 'In',
        value: 'IN',
    },
    NOT_IN: {
        label: 'Not In',
        value: 'NOT_IN',
    },
}

export const PaymentRuleConditionOptions = [
    {
        label: 'Cart Total',
        value: 'CART_TOTAL',
    },
    {
        label: 'Cart SubTotal',
        value: 'CART_SUBTOTAL',
    },
    {
        label: 'Cart Currency',
        value: 'CART_CURRENCY',
    },
    {
        label: 'Cart Weight',
        value: 'CART_WEIGHT',
    },
    {
        label: 'Cart Quantity',
        value: 'CART_QUANTITY',
    },
    {
        label: 'Cart Contains Discount',
        value: 'CART_CONTAINS_DISCOUNT',
    },
    {
        label: 'Zipcode',
        value: 'ZIPCODE',
    },
    {
        label: 'State/UT',
        value: 'PROVINCE_CODE',
    },
    {
        label: 'Destination Country',
        value: 'DESTINATION_COUNTRY',
    },
    {
        label: 'Customer Logged In',
        value: 'CUSTOMER_LOGGED_IN',
    },
    {
        label: 'Customer Order Count',
        value: 'CUSTOMER_ORDER_COUNT',
    },
    {
        label: 'Product Title',
        value: 'PRODUCT_TITLE',
    },
    {
        label: 'Product Variant Title',
        value: 'PRODUCT_VARIANT_TITLE',
    },
    {
        label: 'Product Tag',
        value: 'PRODUCT_TAG',
    },
    {
        label: 'Customer Email',
        value: 'CUSTOMER_EMAIL',
    },
    {
        label: 'Customer Phone',
        value: 'CUSTOMER_PHONE',
    },
    {
        label: 'Collection Id',
        value: 'COLLECTION_ID',
    },
    {
        label: 'Discount Percentage',
        value: 'DISCOUNT_PERCENTAGE',
    },
    {
        label: 'Discount Code',
        value: 'DISCOUNT_CODE',
    },
]

export const PaymentRuleConditionsForPlusOnly = ['DISCOUNT_CODE']

export const InputTypesBasedOnPaymentRuleConditions = {
    CART_TOTAL: 'Number',
    CART_SUBTOTAL: 'Number',
    CART_CURRENCY: 'String',
    CART_WEIGHT: 'Number',
    CART_CONTAINS_DISCOUNT: 'Boolean',
    CART_QUANTITY: 'Number',
    ZIPCODE: 'String[]',
    PROVINCE_CODE: 'StateCode[]',
    CUSTOMER_LOGGED_IN: 'Boolean',
    CUSTOMER_ORDER_COUNT: 'Number',
    PRODUCT_TAG: 'String[]',
    PRODUCT_TITLE: 'String',
    PRODUCT_VARIANT_TITLE: 'String',
    CUSTOMER_EMAIL: 'String[]',
    CUSTOMER_PHONE: 'String[]',
    COLLECTION_ID: 'String[]',
    DISCOUNT_PERCENTAGE: 'Number',
    DESTINATION_COUNTRY: 'Country[]',
    DISCOUNT_CODE: 'String[]',
}

export const PaymentRuleConditionBasedOperators = {
    CART_TOTAL: ['EQ', 'NEQ', 'GT', 'GTE', 'LT', 'LTE'],
    CART_SUBTOTAL: ['EQ', 'NEQ', 'GT', 'GTE', 'LT', 'LTE'],
    CART_CURRENCY: ['EQ', 'NEQ'],
    CART_WEIGHT: ['EQ', 'NEQ', 'GT', 'GTE', 'LT', 'LTE'],
    CART_CONTAINS_DISCOUNT: ['EQ'],
    CART_QUANTITY: ['EQ', 'NEQ', 'GT', 'GTE', 'LT', 'LTE'],
    ZIPCODE: ['EQ', 'NEQ'],
    PROVINCE_CODE: ['EQ', 'NEQ'],
    CUSTOMER_LOGGED_IN: ['EQ'],
    CUSTOMER_ORDER_COUNT: ['EQ', 'NEQ', 'GT', 'GTE', 'LT', 'LTE'],
    PRODUCT_TAG: ['EQ', 'NEQ'],
    PRODUCT_TITLE: ['EQ', 'NEQ', 'CONTAINS', 'DOES_NOT_CONTAINS'],
    PRODUCT_VARIANT_TITLE: ['EQ', 'NEQ', 'CONTAINS', 'DOES_NOT_CONTAINS'],
    CUSTOMER_EMAIL: ['EQ', 'NEQ'],
    CUSTOMER_PHONE: ['EQ', 'NEQ'],
    COLLECTION_ID: ['EQ', 'NEQ'],
    DISCOUNT_PERCENTAGE: ['EQ', 'NEQ', 'GT', 'GTE', 'LT', 'LTE'],
    DESTINATION_COUNTRY: ['EQ', 'NEQ'],
    DISCOUNT_CODE: ['EQ', 'NEQ'],
}

export const PaymentMethodOptions = [
    {
        label: 'GoKwik Payments',
        value: 'GoKwik Payments',
    },
    {
        label: 'GoKwik Cash On Delivery',
        value: 'GoKwik Cash On Delivery',
    },
]

export enum RuleTypes {
    PAYMENT = 'Payment',
    SHIPPING = 'Shipping',
}

export const MatchTypes = [
    {
        label: 'Atleast one product in the cart should meet this condition.',
        value: 'OR',
    },
    {
        label: 'All products in the cart should meet this condition.',
        value: 'AND',
    },
]

export const PaymentConditionInputTypeAndOptions = {
    CART_TOTAL: {
        InputType: 'Number',
        placeHolder: 'Enter cart total in INR',
    },
    CART_SUBTOTAL: {
        InputType: 'Number',
        placeHolder: 'Enter cart subtotal in INR',
    },
    CART_CURRENCY: {
        InputType: 'String',
        placeHolder: 'Enter three letter currency code (eg. INR)',
    },
    CART_WEIGHT: {
        InputType: 'Number',
        placeHolder: 'Enter weight in grams',
    },
    CART_CONTAINS_DISCOUNT: {
        InputType: 'Boolean',
        placeHolder: 'Select value',
    },
    CART_QUANTITY: {
        InputType: 'Number',
        placeHolder: 'Enter cart quantity',
    },
    ZIPCODE: {
        InputType: 'String[]',
        placeHolder: 'Enter the zipcodes (comma separated values)',
    },
    PROVINCE_CODE: {
        InputType: 'StateCode[]',
        placeHolder: 'Select the states/UT',
    },
    DESTINATION_COUNTRY: {
        InputType: 'Country[]',
        placeHolder: 'Select the countries',
    },
    CUSTOMER_LOGGED_IN: {
        InputType: 'Boolean',
        placeHolder: 'Select value',
    },
    CUSTOMER_ORDER_COUNT: {
        InputType: 'Number',
        placeHolder: 'Enter order count',
    },
    PRODUCT_TAG: {
        InputType: 'String[]',
        placeHolder: 'Enter exact product tags (comma separated values)',
    },
    PRODUCT_TITLE: {
        InputType: 'String',
        placeHolder: 'Enter product title',
    },
    PRODUCT_VARIANT_TITLE: {
        InputType: 'String',
        placeHolder: 'Enter product variant title',
    },
    CUSTOMER_EMAIL: {
        InputType: 'String[]',
        placeHolder: 'Enter emails (comma separated values)',
    },
    CUSTOMER_PHONE: {
        InputType: 'String[]',
        placeHolder: 'Enter phone numbers (comma separated values)',
    },
    COLLECTION_ID: {
        InputType: 'String[]',
        placeHolder: 'Enter collection ids (comma separated values)',
    },
    DISCOUNT_PERCENTAGE: {
        InputType: 'Number',
        placeHolder: 'Enter discount value(%)',
    },
    DISCOUNT_CODE: {
        InputType: 'String[]',
        placeHolder: 'Enter discount codes (comma separated values)',
    },
}

export const ProductConditions = ['PRODUCT_TAG', 'PRODUCT_TITLE', 'PRODUCT_VARIANT_TITLE']
export const TagsConditions = ['PRODUCT_TAG']

export const IndianProvinceCodes = [
    {
        label: 'Andhra Pradesh',
        value: 'AP',
    },
    {
        label: 'Arunachal Pradesh',
        value: 'AR',
    },
    {
        label: 'Assam',
        value: 'AS',
    },
    {
        label: 'Bihar',
        value: 'BR',
    },
    {
        label: 'Chhattisgarh',
        value: 'CG',
    },
    {
        label: 'Goa',
        value: 'GA',
    },
    {
        label: 'Gujarat',
        value: 'GJ',
    },
    {
        label: 'Haryana',
        value: 'HR',
    },
    {
        label: 'Himachal Pradesh',
        value: 'HP',
    },
    {
        label: 'Jharkhand',
        value: 'JH',
    },
    {
        label: 'Karnataka',
        value: 'KA',
    },
    {
        label: 'Kerala',
        value: 'KL',
    },
    {
        label: 'Madhya Pradesh',
        value: 'MP',
    },
    {
        label: 'Maharashtra',
        value: 'MH',
    },
    {
        label: 'Manipur',
        value: 'MN',
    },
    {
        label: 'Meghalaya',
        value: 'ML',
    },
    {
        label: 'Mizoram',
        value: 'MZ',
    },
    {
        label: 'Nagaland',
        value: 'NL',
    },
    {
        label: 'Odisha',
        value: 'OR',
    },
    {
        label: 'Punjab',
        value: 'PB',
    },
    {
        label: 'Rajasthan',
        value: 'RJ',
    },
    {
        label: 'Sikkim',
        value: 'SK',
    },
    {
        label: 'Tamil Nadu',
        value: 'TN',
    },
    {
        label: 'Telangana',
        value: 'TS',
    },
    {
        label: 'Tripura',
        value: 'TR',
    },
    {
        label: 'Uttar Pradesh',
        value: 'UP',
    },
    {
        label: 'Uttarakhand',
        value: 'UK',
    },
    {
        label: 'West Bengal',
        value: 'WB',
    },
    {
        label: 'Andaman and Nicobar Islands',
        value: 'AN',
    },
    {
        label: 'Chandigarh',
        value: 'CH',
    },
    {
        label: 'Dadra and Nagar Haveli',
        value: 'DN',
    },
    {
        label: 'Daman and Diu',
        value: 'DD',
    },
    {
        label: 'Delhi',
        value: 'DL',
    },
    {
        label: 'Lakshadweep',
        value: 'LD',
    },
    {
        label: 'Puducherry',
        value: 'PY',
    },
    {
        label: 'Ladakh',
        value: 'LA',
    },
    {
        label: 'Jammu and Kashmir',
        value: 'JK',
    },
]

export const UserTypes = [
    {
        label: 'New',
        value: 'new',
    },
    {
        label: 'Repeat',
        value: 'existing',
    },
]

export const ShippingRuleConditionOptions = [
    {
        label: 'Cart Weight',
        value: 'CART_TOTAL_WEIGHT',
    },
    {
        label: 'Cart Total',
        value: 'CART_TOTAL_PRICE',
    },
    {
        label: 'Cart Quantity',
        value: 'CART_ITEM_COUNT',
    },
    {
        label: 'Cart Currency',
        value: 'CART_CURRENCY',
    },
    {
        label: 'Product Title',
        value: 'PRODUCT_NAME',
    },
    {
        label: 'Collection Id',
        value: 'COLLECTION_ID',
    },
    {
        label: 'Cart Contains Discount',
        value: 'CART_CONTAINS_DISCOUNT',
    },
    {
        label: 'Zipcode',
        value: 'DESTINATION_PINCODE',
    },
    {
        label: 'Destination Country',
        value: 'DESTINATION_COUNTRY',
    },
    {
        label: 'State/UT',
        value: 'PROVINCE_CODE',
    },
    {
        label: 'Customer Type',
        value: 'CUSTOMER_TYPE',
    },
    {
        label: 'Product Variant Title',
        value: 'PRODUCT_VARIANT_TITLE',
    },
    {
        label: 'Product Tag',
        value: 'PRODUCT_TAG',
    },
    {
        label: 'Customer Email',
        value: 'CUSTOMER_EMAIL',
    },
    {
        label: 'Customer Phone',
        value: 'CUSTOMER_PHONE',
    },
    {
        label: 'Discount Percentage',
        value: 'DISCOUNT_PERCENTAGE',
    },
]

export const InputTypesBasedOnShippingRuleConditions = {
    CART_TOTAL_WEIGHT: 'Integer',
    CART_TOTAL_PRICE: 'Integer',
    CART_ITEM_COUNT: 'Integer',
    PRODUCT_NAME: 'String',
    COLLECTION_ID: 'Integer[]',
    DESTINATION_PINCODE: 'Integer[]',
    CART_CONTAINS_DISCOUNT: 'Boolean',
    PROVINCE_CODE: 'StateCode[]',
    CUSTOMER_TYPE: 'UserTypes',
    PRODUCT_TAG: 'String[]',
    PRODUCT_VARIANT_TITLE: 'String',
    CUSTOMER_EMAIL: 'String[]',
    CUSTOMER_PHONE: 'String[]',
    DISCOUNT_PERCENTAGE: 'Integer',
    DESTINATION_COUNTRY: 'Country[]',
}

export const ShippingRuleSubConditions = {
    CART_TOTAL_WEIGHT: ['GT', 'LT', 'GTE', 'LTE', 'EQUALS'],
    CART_TOTAL_PRICE: ['GT', 'LT', 'GTE', 'LTE', 'EQUALS'],
    CART_ITEM_COUNT: ['GT', 'LT', 'GTE', 'LTE', 'EQUALS'],
    PRODUCT_NAME: ['EQUALS', 'NOT_EQUALS', 'CONTAINS', 'DOES_NOT_CONTAIN'],
    COLLECTION_ID: ['IN', 'NOT_IN'],
    DESTINATION_PINCODE: ['IN', 'NOT_IN'],
    CART_CURRENCY: ['EQUALS', 'NOT_EQUALS'],
    CART_CONTAINS_DISCOUNT: ['EQUALS'],
    PROVINCE_CODE: ['EQUALS', 'NOT_EQUALS'],
    CUSTOMER_TYPE: ['EQUALS'],
    PRODUCT_TAG: ['EQUALS', 'NOT_EQUALS'],
    PRODUCT_VARIANT_TITLE: ['EQUALS', 'NOT_EQUALS', 'CONTAINS', 'DOES_NOT_CONTAIN'],
    CUSTOMER_EMAIL: ['EQUALS', 'NOT_EQUALS'],
    CUSTOMER_PHONE: ['EQUALS', 'NOT_EQUALS'],
    DISCOUNT_PERCENTAGE: ['EQUALS', 'NOT_EQUALS', 'GT', 'GTE', 'LT', 'LTE'],
    DESTINATION_COUNTRY: ['IN', 'NOT_IN'],
}

export const ShippingRuleActions = {
    SORT: ['PRICE', 'NAME'],
}

export const ShippingMethodHideOperators = ['HIDE:EQUALS', 'HIDE:NOT_EQUALS']
export const ShippingMethodSortOperators = ['SORT:PRICE', 'SORT:NAME']
export const ShippingMethodSortValues = [
    {
        label: 'Ascending Order',
        value: 'ASC',
    },
    {
        label: 'Descending Order',
        value: 'DESC',
    },
]

export const ShippingRuleActionOptions = [
    {
        label: 'Hide all paid shipping methods',
        value: 'HIDE:PAID',
    },
    {
        label: 'Hide all free shipping methods',
        value: 'HIDE:FREE',
    },
    {
        label: 'Hide specific shipping methods',
        value: 'HIDE:EQUALS',
    },
    {
        label: 'Show only specific shipping methods',
        value: 'HIDE:NOT_EQUALS',
    },
    {
        label: 'Rename Shipping Methods',
        value: 'RENAME',
    },
    {
        label: 'Re-order Shipping Methods',
        value: 'REORDER',
    },
    {
        label: 'Sort Shipping Methods Alphabetically',
        value: 'SORT:NAME',
    },
    {
        label: 'Sort Shipping Methods Based on Price',
        value: 'SORT:PRICE',
    },
]

export const ShippingConditionInputTypesAndOptions = {
    CART_TOTAL_WEIGHT: {
        InputType: 'Integer',
        placeHolder: 'Enter weight in grams',
    },
    CART_TOTAL_PRICE: {
        InputType: 'Integer',
        placeHolder: 'Enter price value in INR',
    },
    CART_ITEM_COUNT: {
        InputType: 'Integer',
        placeHolder: 'Enter items count',
    },
    CART_CURRENCY: {
        InputType: 'String',
        placeHolder: 'Enter three letter currency code (eg. INR)',
    },
    PRODUCT_NAME: {
        InputType: 'String',
        placeHolder: 'Enter product name',
    },
    COLLECTION_ID: {
        InputType: 'Integer[]',
        placeHolder: 'Enter collection ids (comma separated values)',
    },
    DESTINATION_PINCODE: {
        InputType: 'Integer[]',
        placeHolder: 'Enter the pincodes (comma separated values)',
    },
    DESTINATION_COUNTRY: {
        InputType: 'Country[]',
        placeHolder: 'Select the countries',
    },
    CART_CONTAINS_DISCOUNT: {
        InputType: 'Boolean',
        placeHolder: 'Select value',
    },
    PROVINCE_CODE: {
        InputType: 'StateCode[]',
        placeHolder: 'Select the states/UT',
    },
    CUSTOMER_TYPE: {
        InputType: 'UserTypes',
        placeHolder: 'Select User Type',
    },
    PRODUCT_TAG: {
        InputType: 'String[]',
        placeHolder: 'Enter exact product tags (comma separated values)',
    },
    PRODUCT_VARIANT_TITLE: {
        InputType: 'String',
        placeHolder: 'Enter product variant title',
    },
    CUSTOMER_EMAIL: {
        InputType: 'String[]',
        placeHolder: 'Enter emails (comma separated values)',
    },
    CUSTOMER_PHONE: {
        InputType: 'String[]',
        placeHolder: 'Enter phone numbers (comma separated values)',
    },
    DISCOUNT_PERCENTAGE: {
        InputType: 'Integer',
        placeHolder: 'Enter discount value(%)',
    },
}

export const CountryCodes = [
    { label: 'Ascension Island', value: 'AC' },
    { label: 'Afghanistan', value: 'AF' },
    { label: 'Albania', value: 'AL' },
    { label: 'Algeria', value: 'DZ' },
    { label: 'Andorra', value: 'AD' },
    { label: 'Anguilla', value: 'AI' },
    { label: 'Angola', value: 'AO' },
    { label: 'Antigua and Barbuda', value: 'AG' },
    { label: 'Argentina', value: 'AR' },
    { label: 'Armenia', value: 'AM' },
    { label: 'Aruba', value: 'AW' },
    { label: 'Australia', value: 'AU' },
    { label: 'Austria', value: 'AT' },
    { label: 'Azerbaijan', value: 'AZ' },
    { label: 'Åland Islands', value: 'AX' },
    { label: 'Bahamas', value: 'BS' },
    { label: 'Bahrain', value: 'BH' },
    { label: 'Bangladesh', value: 'BD' },
    { label: 'Barbados', value: 'BB' },
    { label: 'Bermuda', value: 'BM' },
    { label: 'Belarus', value: 'BY' },
    { label: 'Belgium', value: 'BE' },
    { label: 'Belize', value: 'BZ' },
    { label: 'Benin', value: 'BJ' },
    { label: 'Bhutan', value: 'BT' },
    { label: 'Bolivia', value: 'BO' },
    { label: 'Bosnia and Herzegovina', value: 'BA' },
    { label: 'Botswana', value: 'BW' },
    { label: 'Bouvet Island', value: 'BV' },
    { label: 'Brazil', value: 'BR' },
    { label: 'Brunei', value: 'BN' },
    { label: 'Bulgaria', value: 'BG' },
    { label: 'Burkina Faso', value: 'BF' },
    { label: 'Burundi', value: 'BI' },
    { label: 'Cape Verde', value: 'CV' },
    { label: 'Cambodia', value: 'KH' },
    { label: 'Cameroon', value: 'CM' },
    { label: 'Canada', value: 'CA' },
    { label: 'Caribbean Netherlands', value: 'BQ' },
    { label: 'Central African Republic', value: 'CF' },
    { label: 'Chad', value: 'TD' },
    { label: 'Chile', value: 'CL' },
    { label: 'China', value: 'CN' },
    { label: 'Cocos (Keeling) Islands', value: 'CC' },
    { label: 'Colombia', value: 'CO' },
    { label: 'Comoros', value: 'KM' },
    { label: 'Congo (Congo-Brazzaville)', value: 'CG' },
    { label: 'Costa Rica', value: 'CR' },
    { label: 'Côte d’Ivoire', value: 'CI' },
    { label: 'Croatia', value: 'HR' },
    { label: 'Cook Islands', value: 'CK' },
    { label: 'Cuba', value: 'CU' },
    { label: 'Curaçao', value: 'CW' },
    { label: 'Christmas Island', value: 'CX' },
    { label: 'Cyprus', value: 'CY' },
    { label: 'Czechia (Czech Republic)', value: 'CZ' },
    { label: 'Democratic Republic of the Congo', value: 'CD' },
    { label: 'Denmark', value: 'DK' },
    { label: 'Djibouti', value: 'DJ' },
    { label: 'Dominica', value: 'DM' },
    { label: 'Dominican Republic', value: 'DO' },
    { label: 'Ecuador', value: 'EC' },
    { label: 'Western Sahara', value: 'EH' },
    { label: 'Egypt', value: 'EG' },
    { label: 'El Salvador', value: 'SV' },
    { label: 'Equatorial Guinea', value: 'GQ' },
    { label: 'Eritrea', value: 'ER' },
    { label: 'Estonia', value: 'EE' },
    { label: 'Eswatini (Swaziland)', value: 'SZ' },
    { label: 'Ethiopia', value: 'ET' },
    { label: 'Fiji', value: 'FJ' },
    { label: 'Finland', value: 'FI' },
    { label: 'Falkland Islands', value: 'FK' },
    { label: 'Faroe Islands', value: 'FO' },
    { label: 'France', value: 'FR' },
    { label: 'Gabon', value: 'GA' },
    { label: 'Gambia', value: 'GM' },
    { label: 'Georgia', value: 'GE' },
    { label: 'French Guiana', value: 'GF' },
    { label: 'Germany', value: 'DE' },
    { label: 'Guernsey', value: 'GG' },
    { label: 'Ghana', value: 'GH' },
    { label: 'Gibraltar', value: 'GI' },
    { label: 'Greenland', value: 'GL' },
    { label: 'Guadeloupe', value: 'GP' },
    { label: 'Greece', value: 'GR' },
    { label: 'Grenada', value: 'GD' },
    { label: 'Guatemala', value: 'GT' },
    { label: 'Guinea', value: 'GN' },
    { label: 'South Georgia & South Sandwich Islands', value: 'GS' },
    { label: 'Guinea-Bissau', value: 'GW' },
    { label: 'Guyana', value: 'GY' },
    { label: 'Heard & McDonald Islands', value: 'HM' },
    { label: 'Hong Kong SAR', value: 'HK' },
    { label: 'Haiti', value: 'HT' },
    { label: 'Honduras', value: 'HN' },
    { label: 'Hungary', value: 'HU' },
    { label: 'Iceland', value: 'IS' },
    { label: 'India', value: 'IN' },
    { label: 'Indonesia', value: 'ID' },
    { label: 'Iran', value: 'IR' },
    { label: 'Iraq', value: 'IQ' },
    { label: 'Ireland', value: 'IE' },
    { label: 'Israel', value: 'IL' },
    { label: 'Isle of Man', value: 'IM' },
    { label: 'British Indian Ocean Territory', value: 'IO' },
    { label: 'Italy', value: 'IT' },
    { label: 'Jersey', value: 'JE' },
    { label: 'Jamaica', value: 'JM' },
    { label: 'Japan', value: 'JP' },
    { label: 'Jordan', value: 'JO' },
    { label: 'Kazakhstan', value: 'KZ' },
    { label: 'Kenya', value: 'KE' },
    { label: 'Kiribati', value: 'KI' },
    { label: 'Kuwait', value: 'KW' },
    { label: 'Kyrgyzstan', value: 'KG' },
    { label: 'Cayman Islands', value: 'KY' },
    { label: 'Laos', value: 'LA' },
    { label: 'Latvia', value: 'LV' },
    { label: 'Lebanon', value: 'LB' },
    { label: 'Lesotho', value: 'LS' },
    { label: 'Liberia', value: 'LR' },
    { label: 'Libya', value: 'LY' },
    { label: 'Liechtenstein', value: 'LI' },
    { label: 'Lithuania', value: 'LT' },
    { label: 'Luxembourg', value: 'LU' },
    { label: 'St. Martin', value: 'MF' },
    { label: 'Madagascar', value: 'MG' },
    { label: 'Malawi', value: 'MW' },
    { label: 'Malaysia', value: 'MY' },
    { label: 'Maldives', value: 'MV' },
    { label: 'Mali', value: 'ML' },
    { label: 'Malta', value: 'MT' },
    { label: 'Marshall Islands', value: 'MH' },
    { label: 'Mauritania', value: 'MR' },
    { label: 'Mauritius', value: 'MU' },
    { label: 'Mexico', value: 'MX' },
    { label: 'Micronesia', value: 'FM' },
    { label: 'Moldova', value: 'MD' },
    { label: 'Monaco', value: 'MC' },
    { label: 'Mongolia', value: 'MN' },
    { label: 'Montenegro', value: 'ME' },
    { label: 'Morocco', value: 'MA' },
    { label: 'Mozambique', value: 'MZ' },
    { label: 'Myanmar (Burma)', value: 'MM' },
    { label: 'Macao SAR', value: 'MO' },
    { label: 'Martinique', value: 'MQ' },
    { label: 'Montserrat', value: 'MS' },
    { label: 'Namibia', value: 'NA' },
    { label: 'Nauru', value: 'NR' },
    { label: 'Nepal', value: 'NP' },
    { label: 'Netherlands', value: 'NL' },
    { label: 'Netherlands Antilles', value: 'AN' },
    { label: 'Niue', value: 'NU' },
    { label: 'New Zealand', value: 'NZ' },
    { label: 'New Caledonia', value: 'NC' },
    { label: 'Nicaragua', value: 'NI' },
    { label: 'Niger', value: 'NE' },
    { label: 'Norfolk Island', value: 'NF' },
    { label: 'Nigeria', value: 'NG' },
    { label: 'North Korea', value: 'KP' },
    { label: 'North Macedonia', value: 'MK' },
    { label: 'Norway', value: 'NO' },
    { label: 'Oman', value: 'OM' },
    { label: 'French Polynesia', value: 'PF' },
    { label: 'Pakistan', value: 'PK' },
    { label: 'Palau', value: 'PW' },
    { label: 'Palestine State', value: 'PS' },
    { label: 'Panama', value: 'PA' },
    { label: 'Papua New Guinea', value: 'PG' },
    { label: 'Paraguay', value: 'PY' },
    { label: 'Peru', value: 'PE' },
    { label: 'Philippines', value: 'PH' },
    { label: 'Poland', value: 'PL' },
    { label: 'St. Pierre & Miquelon', value: 'PM' },
    { label: 'Pitcairn Islands', value: 'PN' },
    { label: 'Portugal', value: 'PT' },
    { label: 'Qatar', value: 'QA' },
    { label: 'Réunion', value: 'RE' },
    { label: 'Romania', value: 'RO' },
    { label: 'Russia', value: 'RU' },
    { label: 'Rwanda', value: 'RW' },
    { label: 'Saint Kitts and Nevis', value: 'KN' },
    { label: 'Saint Lucia', value: 'LC' },
    { label: 'Saint Vincent and the Grenadines', value: 'VC' },
    { label: 'Samoa', value: 'WS' },
    { label: 'San Marino', value: 'SM' },
    { label: 'Sao Tome and Principe', value: 'ST' },
    { label: 'Saudi Arabia', value: 'SA' },
    { label: 'Senegal', value: 'SN' },
    { label: 'Serbia', value: 'RS' },
    { label: 'Seychelles', value: 'SC' },
    { label: 'Sierra Leone', value: 'SL' },
    { label: 'Singapore', value: 'SG' },
    { label: 'St. Helena', value: 'SH' },
    { label: 'Slovakia', value: 'SK' },
    { label: 'Slovenia', value: 'SI' },
    { label: 'Svalbard & Jan Mayen', value: 'SJ' },
    { label: 'Solomon Islands', value: 'SB' },
    { label: 'Somalia', value: 'SO' },
    { label: 'Sint Maarten', value: 'SX' },
    { label: 'South Africa', value: 'ZA' },
    { label: 'South Korea', value: 'KR' },
    { label: 'South Sudan', value: 'SS' },
    { label: 'Spain', value: 'ES' },
    { label: 'Sri Lanka', value: 'LK' },
    { label: 'St. Barthélemy', value: 'BL' },
    { label: 'Sudan', value: 'SD' },
    { label: 'Suriname', value: 'SR' },
    { label: 'Sweden', value: 'SE' },
    { label: 'Switzerland', value: 'CH' },
    { label: 'Syria', value: 'SY' },
    { label: 'Tristan da Cunha', value: 'TA' },
    { label: 'Turks & Caicos Islands', value: 'TC' },
    { label: 'French Southern Territories', value: 'TF' },
    { label: 'Tajikistan', value: 'TJ' },
    { label: 'Tanzania', value: 'TZ' },
    { label: 'Thailand', value: 'TH' },
    { label: 'Timor-Leste', value: 'TL' },
    { label: 'Togo', value: 'TG' },
    { label: 'Tonga', value: 'TO' },
    { label: 'Trinidad and Tobago', value: 'TT' },
    { label: 'Tunisia', value: 'TN' },
    { label: 'Türkiye', value: 'TR' },
    { label: 'Taiwan', value: 'TW' },
    { label: 'Turkmenistan', value: 'TM' },
    { label: 'Tuvalu', value: 'TV' },
    { label: 'Uganda', value: 'UG' },
    { label: 'Ukraine', value: 'UA' },
    { label: 'U.S. Outlying Islands', value: 'UM' },
    { label: 'United Arab Emirates', value: 'AE' },
    { label: 'United Kingdom', value: 'GB' },
    { label: 'United States of America', value: 'US' },
    { label: 'Uruguay', value: 'UY' },
    { label: 'Uzbekistan', value: 'UZ' },
    { label: 'British Virgin Islands', value: 'VG' },
    { label: 'Vanuatu', value: 'VU' },
    { label: 'Vatican', value: 'VA' },
    { label: 'Venezuela', value: 'VE' },
    { label: 'Vietnam', value: 'VN' },
    { label: 'Wallis & Futuna', value: 'WF' },
    { label: 'Kosovo', value: 'XK' },
    { label: 'Yemen', value: 'YE' },
    { label: 'Mayotte', value: 'YT' },
    { label: 'Zambia', value: 'ZM' },
    { label: 'Zimbabwe', value: 'ZW' },
]
