import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ColumnProps, InfoCircleOutlined, ReloadOutlined, Select, Table, Tooltip } from 'gokwik-ui-kit'
import {
    computeRowSpans,
    exportDeepDiveDataToCSV,
    formatDateAnalytics,
    numberFormatter,
} from '@library/utilities/helpers/helper'
import { updateDataBasedOnTimeframeAndFilter } from '@library/utilities/helpers/data_filtering'
import {
    awbFillRateIconMsg,
    deepDiveViewMetrics,
    mappingsDeepDiveView,
    rtoApiHitsIconMsg,
    titlesDeepDiveView,
} from '@library/utilities/constants/constants'
import { useEffect, useMemo, useState } from 'react'

dayjs.extend(utc)

const getTitle = (filter: string): string => {
    return titlesDeepDiveView[filter] || 'Unknown Filter'
}

const createColumnsWithMetrics = (
    filter: string,
    setIsSorted: React.Dispatch<React.SetStateAction<boolean>>,
    setIsFilterApplied: React.Dispatch<React.SetStateAction<boolean>>,
    setSelectedFilters: React.Dispatch<React.SetStateAction<any[]>>,
    filterOptions: { label: string; value: any }[],
    tableData: any[],
    onSearchFilter: (searchText: string) => void,
    selectedFilters: any[]
) => {
    const monthOrder = useMemo(() => {
        const uniqueMonths = Array.from(new Set(tableData.map((row) => row.date)))
        return uniqueMonths?.reduce((order, month, index) => {
            order[month] = index + 1 // Assign priority sequentially
            return order
        }, {})
    }, [tableData])

    const baseColumns: ColumnProps<any>[] = [
        {
            title: 'DATE',
            dataIndex: 'date',
            key: 'date',
            width: 120,
            render: (val, record) => ({
                children: val,
                props: {
                    // rowSpan: record.rowSpan, //to be added later
                    style: { textAlign: 'center', fontWeight: 'bold' },
                },
            }),
        },
        {
            title: getTitle(filter),
            dataIndex: 'filter_value',
            key: 'filter_value',
            filterDropdown: () => (
                <div style={{ padding: 8 }}>
                    <Select
                        mode='multiple'
                        showSearch
                        placeholder='Search '
                        onSearch={onSearchFilter}
                        style={{ width: '200px' }}
                        options={filterOptions}
                        value={selectedFilters}
                        onChange={(selectedValues) => {
                            setSelectedFilters(selectedValues)
                            setIsFilterApplied(true)
                        }}
                    />
                </div>
            ),
            width: 180,
            render: (val, record) => ({
                children: val?.toUpperCase(),
                props: {
                    style: { textAlign: 'left', fontWeight: 'bold' },
                },
            }),
        },
    ]

    const metricColumns = Object.keys(deepDiveViewMetrics)?.map((metric_key, index, array) => {
        const metricInfo = deepDiveViewMetrics[metric_key]
        const isSortable = array.length - index <= 3
        return {
            title: (
                <>
                    {metric_key === 'hits' || metric_key === 'awb_fill_rate_perc' ? (
                        <span className='flex items-center'>
                            {metricInfo.label}{' '}
                            <Tooltip
                                placement='top'
                                title={metric_key === 'hits' ? rtoApiHitsIconMsg : awbFillRateIconMsg}
                            >
                                <InfoCircleOutlined className='ml-1' />
                            </Tooltip>
                        </span>
                    ) : (
                        metricInfo.label
                    )}
                </>
            ),
            dataIndex: metric_key,
            key: metric_key,
            render: (val, record) => {
                const exactValue = record[metric_key].exact
                const isPercentageAndNaN = metricInfo.type === 'percentage' && isNaN(exactValue)
                const formattedValue = isPercentageAndNaN ? 'Awaited' : record[metric_key].formatted
                const tooltipTitle = isPercentageAndNaN ? 'Metric awaited' : `${exactValue}`
                return (
                    <Tooltip placement='top' title={`${tooltipTitle}`}>
                        {formattedValue}
                    </Tooltip>
                )
            },
            sorter: isSortable
                ? (a, b) => {
                      setIsSorted(true)
                      const monthA = monthOrder[a.date] || 0
                      const monthB = monthOrder[b.date] || 0

                      // First sort by month
                      if (monthA !== monthB) {
                          return monthA - monthB
                      }

                      // Then sort by the metric value
                      const valueA = a[metric_key].exact ?? 0
                      const valueB = b[metric_key].exact ?? 0
                      return valueA - valueB
                  }
                : undefined,
            align: 'right' as const,
            width: 120,
        }
    })

    return [...baseColumns, ...metricColumns]
}

const mapDataToRows = (data: any[], timeframe: string) => {
    // Directly map data to rows without aggregation
    return data?.map((item: any) => {
        const formattedDate = formatDateAnalytics(item.date, timeframe) // Format date based on the timeframe
        const row: any = {
            date: formattedDate,
            filter_value: item.filter_value || 'Unclassified',
        }
        // Map each metric to the row with formatting
        Object.keys(deepDiveViewMetrics)?.forEach((metricKey) => {
            const value = item[metricKey]
            const isPercentageAndNaN = deepDiveViewMetrics[metricKey].type === 'percentage' && isNaN(value)
            row[metricKey] = {
                formatted: isPercentageAndNaN
                    ? 'Awaited'
                    : numberFormatter(parseFloat(value?.toFixed(1) || 0), deepDiveViewMetrics[metricKey].type),
                exact: value,
            }
        })
        return row
    })
}

const DeepDiveTable = ({ analyticsData, timeframe = 'monthly', filter }) => {
    const mappedFilter = mappingsDeepDiveView[filter]
    const originalData = analyticsData?.[mappedFilter]?.data
    const status = analyticsData?.[mappedFilter]?.status
    const timeframeDataFilterWise = updateDataBasedOnTimeframeAndFilter(
        originalData,
        timeframe as 'monthly' | 'weekly' | 'quarterly',
        filter,
    )
    const [isSorted, setIsSorted] = useState(false)
    const [isFilterApplied, setIsFilterApplied] = useState(false)
    const [selectedFilters, setSelectedFilters] = useState<any[]>([])
    const [searchText, setSearchText] = useState('')
    const [filteredOptions, setFilteredOptions] = useState([])

    const tableData = mapDataToRows(timeframeDataFilterWise, timeframe)

    const filterOptions = useMemo(() => {
        const allFilterValues = tableData?.map((item) => item.filter_value) || []
        const uniqueFilterValues = Array.from(new Set(allFilterValues))
        return uniqueFilterValues?.map((value) => ({
            label: typeof value === 'string' ? value.toUpperCase() : String(value),
            value,
        }))
    }, [tableData])

    const onSearchFilter = (search) => {
        setSearchText(search)
        const lowercasedSearch = search.toLowerCase()
        setFilteredOptions(filterOptions?.filter((option) => option.label.toLowerCase().includes(lowercasedSearch)))
    }

    const resetFilters = () => {
        setSelectedFilters([])
        setIsFilterApplied(false)
        setSearchText('')
        setFilteredOptions(filterOptions)
    }

    const filterTableData = (data) => {
        if (selectedFilters.length === 0) {
            return data
        }
        return data?.filter((item) => selectedFilters.includes(item.filter_value))
    }

    const filteredTableData = useMemo(() => filterTableData(tableData), [tableData, selectedFilters])

    const tableColumns = createColumnsWithMetrics(
        filter,
        setIsSorted,
        setIsFilterApplied,
        setSelectedFilters,
        filterOptions,
        tableData,
        onSearchFilter,
        selectedFilters
    )

    return (
        <>
            <div className='flex justify-end gap-2'>
                <button
                    type='button'
                    className='bg-[#004b8d] hover:bg-[#186199] text-white font-medium py-2 px-3 rounded-lg border-none mb-5'
                    onClick={() => exportDeepDiveDataToCSV(filteredTableData, deepDiveViewMetrics, getTitle(filter))}
                >
                    Export CSV
                </button>
                <Tooltip title='Reset Filters'>
                    <button
                        type='button'
                        className='bg-[#004b8d] hover:bg-[#186199] text-white font-medium py-2 px-3 rounded-lg border-none mb-5'
                        onClick={resetFilters}
                    >
                        <ReloadOutlined />
                    </button>
                </Tooltip>
            </div>
            <Table
                columns={tableColumns}
                dataSource={filteredTableData}
                pagination={false}
                bordered={true}
                scroll={{ x: 'max-content', y: 500 }}
                loading={status === 'loading'}
            />
        </>
    )
}

export default DeepDiveTable
