import { DiyDiscountVariants } from '@library/utilities/constants/constants'
import { useAppDispatch } from '@library/utilities/hooks'
import { setSummary } from '@store/discounts'
import { selectDiscountCodeData, selectSummaryData } from '@store/discounts/selectors'
import dayjs from 'dayjs'
import { Divider, Tag } from 'gokwik-ui-kit'
import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const parseSummaryData = (data: any, subMethod: string) => {
    let result = {
        title: data.discountCode,
        status: data.status?.toLowerCase() || 'draft',
        type: subMethod,
        segments: [],
    }

    if (subMethod === 'automatic') {
        if (data.enableCombineManualDiscounts === 'specific') {
            const length = data.combineManualDiscounts?.length
            if (!length) {
                result.segments.push({
                    title: 'Combinations',
                    points: [`Combination to be selected`],
                })
            } else {
                result.segments.push({
                    title: 'Combinations',
                    points: [`Combines with ${length} manual discount${length > 1 ? 's' : ''}`],
                })
            }
        } else {
            result.segments.push({
                title: 'Combinations',
                points: [`Combines with ${data.enableCombineManualDiscounts ? 'all' : 'no'} manual discounts`],
            })
        }
        if (data.enableOverwriteManualDiscounts === 'specific') {
            const length = data.overwriteManualDiscounts?.length
            if (!length) {
                result.segments.push({
                    title: 'Replaces',
                    points: [`Replacements to be selected`],
                })
            } else {
                result.segments.push({
                    title: 'Replaces',
                    points: [`Replaces with ${length} manual discount${length > 1 ? 's' : ''}`],
                })
            }
        } else {
            result.segments.push({
                title: 'Replaces',
                points: [`Replaces ${data.enableOverwriteManualDiscounts ? 'all' : 'no'} manual discounts`],
            })
        }
        return result
    }
    if (!data.discountType) {
        result.segments.push({
            title: 'Setup',
            points: ['Pending setup'],
        })
        return result
    }
    const setup = [`${DiyDiscountVariants.find((v) => v.key === data.discountType)?.value}`]
    if (data.minimumRequirements === 'noMinimum') {
        setup.push('No minimum requirements')
    } else if (data.minimumRequirements === 'minimumAmount') {
        setup.push(`Minimum amount: ${data.discountValue}`)
    } else if (data.minimumRequirements === 'minimumQuantity') {
        setup.push(`Minimum quantity: ${data.discountValue}`)
    } else {
        setup.push(`Minimum requirements to be set`)
    }
    if (data.discountApplicableOn === 'allProducts') {
        setup.push('Discount applicable on all products')
    } else if (data.discountApplicableOn === 'specificProducts') {
        const length = data.discountApplicableOnItems?.products?.data?.length
        if (!length) {
            setup.push('Discount applicable products to be selected')
        } else setup.push(`Discount applicable on ${length} product${length > 1 ? 's' : ''}`)
    } else {
        const length = data.discountApplicableOnItems?.collections?.data?.length
        if (!length) {
            setup.push('Discount applicable collections to be selected')
        } else setup.push(`Discount applicable on ${length} collection${length > 1 ? 's' : ''}`)
    }
    if (data.discountType === 'cartItemsDiscount' && data.discountCartItemsDiscountValue) {
        if (data.discountCartItemType === 'percentage') {
            setup.push(`Discount value: ${data.discountCartItemsDiscountValue}%`)
            data.discountCartItemsCapping && setup.push(`Discount capped at ₹${data.discountCartItemsCappingValue}`)
        } else {
            setup.push(`Discount value: ₹${data.discountCartItemsDiscountValue}`)
            data.discountCartItemsOncePerOrder && setup.push('Discount applied once per order')
        }
    } else if (data.discountType === 'discountedCartItems') {
        const itemsType = data.discountedCartItemsType === 'specificProducts' ? 'products' : 'collections'
        const length = data.discountedCartItemsItems?.[itemsType]?.data?.length
        if (!length) {
            setup.push('Items to be added')
        } else {
            const discountValue =
                data.discountedCartItemsDiscountType === 'percentage'
                    ? `${data.discountedCartItemsDiscountValue}%`
                    : `₹${data.discountedCartItemsDiscountValue}`
            setup.push(
                `Customer gets ${data.discountedCartItemsQuantity} from ${length} ${data.discountedCartItemsType}${
                    length > 1 ? 's' : ''
                } at a discount of ${discountValue}`,
            )
            data.discountedCartItemsUsesOrderLimit &&
                setup.push(
                    `Can be applied max ${data.discountedCartItemsUsesOrderLimit} time${
                        data.discountedCartItemsUsesOrderLimit > 1 ? 's' : ''
                    } per order`,
                )
        }
    } else if (data.discountType === 'tieredDiscount') {
        const length = data.tieredDiscountTiers?.length
        if (!length) {
            setup.push('Tiers to be added')
        } else
            setup.push(
                `${length} tier${length > 1 ? 's' : ''} applicable on ${
                    data.tieredDiscountCategory === 'volume' ? 'cart quantity' : 'order value'
                }`,
            )
    } else if (data.discountType === 'bundleDiscount') {
        const length = data.buyxatyDiscount?.length
        if (!length) {
            setup.push('Bundle items to be added')
        } else setup.push(`${length} bundle${length > 1 ? 's' : ''} added`)
    } else if (data.discountType === 'shippingDiscount') {
        data.enableShippingExclusion &&
            data.shippingDiscountAmount &&
            setup.push(`Shipping rates excluded if over ₹${data.shippingDiscountAmount}`)
    }
    if (data.topUpGifts) {
        const length = data.topUpGiftsData?.products?.data?.length
        if (length) setup.push(`${length} top-up gift${length > 1 ? 's' : ''}`)
        else setup.push('Top-up gifts to be selected')
    } else {
        setup.push('No free gifts')
    }
    result.segments.push({
        title: 'Setup',
        points: setup,
    })

    const conditions = []
    if (!data.startDate) {
        conditions.push('Conditions not set')
        result.segments.push({
            title: 'Conditions',
            points: conditions,
        })
        return result
    }
    if (data.enableLimitTotalUsage) {
        conditions.push(`Can be used ${data.limitDiscountTotalUsageValue} times`)
    }
    if (data.enableLimitOneUsagePerCustomer) {
        conditions.push(`Can be used once per customer`)
    }
    if (data.startDate && data.endDate) {
        const startDate: Dayjs = data.startDate
        const endDate: Dayjs = data.endDate
        const isSameDay = startDate.isSame(endDate, 'day')
        const isYearSame = startDate.year() === endDate.year()
        if (isSameDay) {
            conditions.push(`Valid on ${startDate.format('DD MMMM')}`)
        } else if (isYearSame) {
            conditions.push(`Valid from ${startDate.format('DD MMMM')} to ${endDate.format('DD MMMM')}`)
        } else {
            conditions.push(`Valid from ${startDate.format('DD MMMM YYYY')} to ${endDate.format('DD MMMM YYYY')}`)
        }
    } else if (data.startDate) {
        conditions.push(`Valid from ${data.startDate.format('DD MMMM YYYY')}`)
    }
    if (data.disableCod) {
        conditions.push('COD disabled for this discount')
    }
    if (data.disablePrepaidDiscounts) {
        conditions.push('Prepaid discounts disabled for this discount')
    }
    result.segments.push({
        title: 'Conditions',
        points: conditions,
    })
    const combinations = []
    if (data.enableCombineManualDiscounts) {
        const length = data.combineManualDiscounts?.length || 0
        if (length) combinations.push(`Combines with ${length} sets of manual discounts`)
        else combinations.push(`Combination to be selected`)
    } else {
        subMethod !== 'bulk' && combinations.push(`Does not combine with any manual discounts`)
    }
    if (data.enableCombineAutomaticDiscounts) {
        if (!data.combineOverwriteAutoDiscounts?.length) {
            combinations.push(`Automatic discounts to be selected`)
        } else {
            const [overWrite, combine] = data.combineOverwriteAutoDiscounts.reduce(
                (acc, val) => {
                    if (val.behavior === 'overwrite') acc[1]++
                    else acc[0]++
                    return acc
                },
                [0, 0],
            )
            if (combine) combinations.push(`Combines with ${combine} automatic discount${combine > 1 ? 's' : ''}`)

            if (overWrite) combinations.push(`Replaces ${overWrite} automatic discount${overWrite > 1 ? 's' : ''}`)
        }
    } else {
        combinations.push(`Does not combine with any automatic discounts`)
        combinations.push(`Does not replace any automatic discounts`)
    }
    result.segments.push({
        title: 'Combinations',
        points: combinations,
    })
    const customerEligibility = []
    if (data.customerEligibility === 'all') customerEligibility.push('All customers are eligible for this discount')
    if (data.customerEligibility === 'newCustomers' || data.customerEligibility === 'existingCustomers') {
        customerEligibility.push(
            `${
                data.customerEligibility === 'newCustomers' ? 'New' : 'Existing'
            } customers are eligible for this discount`,
        )
    } else if ('shopifySegments' === data.customerEligibility) {
        customerEligibility.push('Customers who match the the selected Shopify segments are eligible for this discount')
    } else if ('specificCustomers' === data.customerEligibility) {
        customerEligibility.push(
            `Specific customers are${
                data.specificCustomersType === 'exclude' ? ' not' : ''
            } eligible for this discount`,
        )
    }

    if (data.salesChannelWebsite && data.salesChannelMobileApplication) {
        customerEligibility.push('Available on both website and mobile app')
    } else if (data.salesChannelWebsite) {
        customerEligibility.push('Available on website')
    } else if (data.salesChannelMobileApplication) {
        customerEligibility.push('Available on mobile app')
    }

    if (data.enableUtmParameters) {
        customerEligibility.push('UTM parameters required')
    }
    result.segments.push({
        title: 'Customer Eligibility',
        points: customerEligibility,
    })

    if (subMethod === 'bulk') {
        const generateCodes = []
        const codeIdentifierType = data.codeIdentifier === 'Prefix' ? 'starting with' : 'ending with'
        const codeIdentifier = data.codeIdentifier === 'Prefix' ? data.prefix : data.suffix
        if (data.codeGenerationMethod === 'random') {
            generateCodes.push(`Randomly generated codes`)
        } else if (data.codeGenerationMethod) {
            generateCodes.push(`Uploaded codes`)
        } else {
            generateCodes.push('Codes to be generated/uploaded')
        }
        codeIdentifierType &&
            codeIdentifier &&
            data.codeLength &&
            generateCodes.push(`Codes ${codeIdentifierType} ${codeIdentifier} of length ${data.codeLength}`)
        data.numberOfCode && generateCodes.push(`Total codes: ${data.numberOfCodes}`)
        data.expiry && generateCodes.push(`Expires on ${data.expiry.format('DD MMMM YYYY HH:mm')}`)
        result.segments.push({
            title: 'Code Generation',
            points: generateCodes,
        })
    } else {
        const visibility = []
        if (data.discountCodeVisibilityEnabled) {
            visibility.push('Discount to be shown on the checkout')
            if (data.discountCodeVisibility === 'ALWAYS') {
                visibility.push('Discount to be shown always')
            } else if (data.discountCodeVisibility === 'ONLY_WHEN_ELIGIBLE') {
                visibility.push('Discount to be shown only to eligible customers')
            } else {
                visibility.push('Discount not to be shown on the checkout')
            }
        } else {
            visibility.push('Discount not to be shown on the checkout')
        }
        result.segments.push({
            title: 'Visibility',
            points: visibility,
        })
    }
    return result
}
const Summary = () => {
    const discountData = useSelector(selectSummaryData)
    const location = useLocation()
    const dispatch = useAppDispatch()
    const discountMethod = location.pathname.includes('manual')
        ? 'manual'
        : location.pathname.includes('bulk')
        ? 'bulk'
        : 'automatic'
    const summaryData = useMemo(() => parseSummaryData(discountData, discountMethod), [discountData])
    useEffect(() => {
        return () => {
            dispatch(setSummary({}))
        }
    }, [])

    return (
        <div className='w-full flex p-3 flex-col gap-y-3 bg-neutrals-15 rounded'>
            <span className='text-xl font-semibold'>Summary</span>

            <div className='w-full flex items-center justify-between'>
                <div className='flex flex-col gap-y-2'>
                    {summaryData?.title && <span className='text-base font-medium'>{summaryData.title}</span>}
                    <span className='text-gray-400 capitalize'>{summaryData.type || discountMethod} Discount Code</span>
                </div>
                <Tag
                    className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                    variant={
                        summaryData.status === 'active'
                            ? 'success'
                            : summaryData.status === 'inactive'
                            ? 'error'
                            : summaryData.status === 'scheduled'
                            ? 'warning'
                            : 'default'
                    }
                >
                    {summaryData.status}
                </Tag>
            </div>
            <Divider className='my-0' />

            {summaryData.segments.map((segment, index) => (
                <React.Fragment key={index}>
                    <div className='w-full flex flex-col gap-y-1'>
                        <span className='text-base font-medium'>{segment.title}</span>
                        <ul className='w-full flex flex-col gap-y-1 pl-4 m-0'>
                            {segment.points.map((point, index) => (
                                <li key={index} className='text-sm'>
                                    {point}
                                </li>
                            ))}
                        </ul>
                    </div>
                    {index !== summaryData.segments.length - 1 && <Divider className='my-0' />}
                </React.Fragment>
            ))}
        </div>
    )
}

export default Summary
