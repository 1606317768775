import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ColumnProps, InfoCircleOutlined, Table, Tooltip } from 'gokwik-ui-kit'
import {
    computeRowSpans,
    exportRiskFlagSummaryToCSV,
    formatDateTimeframe,
    numberFormatter,
} from '@library/utilities/helpers/helper'
import { aggregateSummaryMetrics } from '@library/utilities/helpers/data_filtering'
import { awbFillRateIconMsg, rtoApiHitsIconMsg, summaryViewMetrics } from '@library/utilities/constants/constants'

dayjs.extend(utc)

const createColumnsWithMetrics = () => {
    const baseColumns: ColumnProps<any>[] = [
        {
            title: 'DATE',
            dataIndex: 'date',
            key: 'date',
            width: 120,
            render: (val, record) => ({
                children: val,
                props: {
                    rowSpan: record.rowSpan,
                    style: { textAlign: 'center', fontWeight: 'bold' },
                },
            }),
        },
        {
            title: 'RISK FLAG',
            dataIndex: 'risk_flag',
            key: 'risk_flag',
            width: 150,
            render: (val, record) => ({
                children: val?.toUpperCase(),
                props: {
                    style: { fontWeight: 'bold' },
                },
            }),
        },
    ]

    const metricColumns = Object.keys(summaryViewMetrics)?.map((metric_key) => {
        const metricInfo = summaryViewMetrics[metric_key]
        return {
            title: (
                <>
                    {metric_key === 'hits' || metric_key === 'awb_fill_rate_perc' ? (
                        <span className='flex items-center'>
                            {metricInfo.label}{' '}
                            <Tooltip
                                placement='top'
                                title={metric_key === 'hits' ? rtoApiHitsIconMsg : awbFillRateIconMsg}
                            >
                                <InfoCircleOutlined className='ml-1' />
                            </Tooltip>
                        </span>
                    ) : (
                        metricInfo.label
                    )}
                </>
            ),
            dataIndex: metric_key,
            key: metric_key,
            render: (val, record) => {
                const exactValue = record[metric_key].exact
                const isPercentageAndNaN = metricInfo.type === 'percentage' && isNaN(exactValue)
                const formattedValue = isPercentageAndNaN ? 'Awaited' : record[metric_key].formatted
                const tooltipTitle = isPercentageAndNaN ? 'Metric awaited' : `${exactValue}`
                return (
                    <Tooltip placement='top' title={`${tooltipTitle}`}>
                        {formattedValue}
                    </Tooltip>
                )
            },
            align: 'right' as const,
            width: 120,
        }
    })

    return [...baseColumns, ...metricColumns]
}

const mapDataToRows = (data, timeframe) => {
    const sortedData = data?.sort((a, b) => {
        if (a.date === b.date) {
            const riskOrder = ['High Risk', 'Medium Risk', 'Low Risk']
            return riskOrder.indexOf(a.risk_flag_level) - riskOrder.indexOf(b.risk_flag_level)
        }
        return dayjs(a.date).unix() - dayjs(b.date).unix()
    })

    return sortedData?.map((item) => {
        const formattedDate = formatDateTimeframe(item.date, timeframe)

        const row = {
            date: formattedDate,
            risk_flag: item.risk_flag_level,
        }

        Object.keys(summaryViewMetrics)?.forEach((metric_key) => {
            const metric = summaryViewMetrics[metric_key]
            const value = item[metric_key]
            const isPercentageAndNaN = metric.type === 'percentage' && isNaN(value)
            row[metric_key] = {
                formatted: isPercentageAndNaN ? 'Awaited' : numberFormatter(value, metric.type),
                exact: value,
            }
        })

        return row
    })
}

const RiskFlagSummaryTable = ({ analyticsData, timeframe = 'monthly' }) => {
    const originalData = analyticsData?.rtoInsights?.data
    const status = analyticsData?.rtoInsights?.status
    const timeframeDataRiskWise = aggregateSummaryMetrics(
        originalData,
        timeframe as 'monthly' | 'weekly' | 'quarterly',
        'risk_flag',
    )

    const tableData = mapDataToRows(timeframeDataRiskWise, timeframe)

    const rowSpans = computeRowSpans(tableData)

    const tableColumns = createColumnsWithMetrics()

    const finalTableData = tableData?.map((row, index) => ({
        ...row,
        rowSpan: rowSpans[index],
    }))
    const isButtonDisabled = finalTableData.some((row) => row.awb_fill_rate_perc.exact < 80)

    return (
        <>
            <div className='flex justify-end'>
                {isButtonDisabled ? (
                    <Tooltip title='Gokwik has delivery status available for less than 80% of orders, Please reach out to your CSM to increase your AWB Fill rate and unlock this view'>
                        <div>
                            <button
                                type='button'
                                className='bg-gray-400 hover:bg-gray-400 text-white font-medium py-2 px-3 rounded-lg border-none mb-5 cursor-not-allowed'
                                onClick={() => exportRiskFlagSummaryToCSV(finalTableData, summaryViewMetrics)}
                                disabled={isButtonDisabled}
                            >
                                Export CSV
                            </button>
                        </div>
                    </Tooltip>
                ) : (
                    <button
                        type='button'
                        className='bg-[#004b8d] hover:bg-[#186199] text-white font-medium py-2 px-3 rounded-lg border-none mb-5'
                        onClick={() => exportRiskFlagSummaryToCSV(finalTableData, summaryViewMetrics)}
                        disabled={isButtonDisabled}
                    >
                        Export CSV
                    </button>
                )}
            </div>
            <Table
                columns={tableColumns}
                dataSource={finalTableData}
                pagination={false}
                bordered={true}
                scroll={{ x: 'max-content' }}
                loading={status === 'loading'}
            />
        </>
    )
}

export default RiskFlagSummaryTable
