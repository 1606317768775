import { RootStoreType } from '..'

export const previousRefundsFilters = (state: RootStoreType) => state.filters.refunds
export const previousBulkRefundsFilters = (state: RootStoreType) => state.filters.bulk_refunds
export const previousTransactionsFilters = (state: RootStoreType) => state.filters.transactions
export const previousDangledTransactionsFilters = (state: RootStoreType) => state.filters.dangled_transactions
export const previousSettlementsFilters = (state: RootStoreType) => state.filters.settlements
export const previousSettlementsFiltersNew = (state: RootStoreType) => state.filters.settlements_new
export const previousPaymentLinkFilters = (state: RootStoreType) => state.filters.payment_links
export const previousPaymentOfferFilters = (state: RootStoreType) => state.filters.payment_offers
