import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
    Button,
    InfoCircleFilled,
    InfoCircleOutlined,
    Layout,
    Modal,
    Progress,
    Segmented,
    Switch,
    Tabs,
    message,
} from 'gokwik-ui-kit'

import { getMerchantDetails } from '@store/user/selectors'
import { getWorkflowData } from '@store/actions/selectors'
import { fetchAllWorkflows, setWorkflows } from '@store/actions'
import { Workflow } from '@library/utilities/interface'
import { capitalize } from '@library/utilities/helpers/helper'
import {
    createWorkflowAsync,
    getEidaReccomendationStatus,
    getRecommendations,
    updateWorkflowAsync,
} from '@store/actions/api'

import { ruleTemplates } from '@library/utilities/constants/constants'
import { rtoActionsOptionsMenu, rtoActionsOptionsMenuWithAB } from '../all-workflow/constants'
import EmptyState from '../all-workflow/empty-state'
import BoostAWB from '@library/components/settings/awb-flows/boost-awb'
import { useAwbFillRate } from '@library/components/settings/awb-flows/useAwbFillRate'
var relativeTime = require('dayjs/plugin/relativeTime')
const { Content } = Layout
dayjs.extend(relativeTime)
dayjs.extend(utc)

interface EditWorkflowType {
    item: Workflow
    index: number
}

const ReccomendedWorkflow = ({ onClick, abControlEnable }) => {
    const merchantDetails = useSelector(getMerchantDetails)
    const workflowDetails = useSelector(getWorkflowData)
    const [draggedListItem, setDraggedListItem] = useState(null)
    const [allWorkflow, setAllWorkflow] = useState<Workflow[]>([])
    const [deleteModal, setDeleteModal] = useState<EditWorkflowType>(null)
    const [prevDraggedIndex, setPrevDraggedIndex] = useState(null)
    const [current, setCurrentFilter] = useState('Recommended(Beta)')
    const [showModal, setShowModal] = useState(false)
    const [editWorkflowData, setEditWorkflowData] = useState<EditWorkflowType>(null)
    const [isProgressModalVisible, setIsProgressModalVisible] = useState(false)
    const [progress, setProgress] = useState(0)
    const dispatch = useDispatch()
    const [editFlow, setCreateFlow] = useState(false)
    const [isEditing, setIsEditing] = useState(null)
    const [currentindex, setCurrentIndex] = useState(0)
    const [apiWorkflow, setApiWorkflow] = useState<Workflow[]>([])
    const workflows = workflowDetails?.data ?? []
    const eidaReccomendations = workflowDetails?.eida_reccomendations?.raw_data ?? []
    const [opacity, setOpacity] = useState('opacity-0')
    const isEligibe = eidaReccomendations?.length > 0 ? dayjs.utc().diff(dayjs(workflowDetails?.eida_reccomendations?.last_run), 'hour') >= 4 : true
    const [eidaStatus, setEidaStatus] = useState('pending')
    const {showBoostAWB} = useAwbFillRate(merchantDetails?.id)

    const fetchWorkflowAsync = () => {
        dispatch(
            //@ts-ignore
            fetchAllWorkflows({
                params: {
                    merchant_id: merchantDetails.id,
                },
            }),
        )
    }

    useEffect(() => {
        setApiWorkflow(workflows)
        let templates = []
        if (workflows && workflows?.length > 0) {
            templates = eidaReccomendations.map((itm) => {
                let isEqual = workflows?.filter((item: Workflow) => itm?.rule_name === item.rule_name)
                return isEqual[0] ? { ...isEqual[0] } : itm
            })
        }
        else {
            templates = eidaReccomendations
        }
        setAllWorkflow(templates)
    }, [workflows])
    const isWorkflowPresent = (workflow: Workflow) => {
        let isEqual = workflows?.filter((item: Workflow) => workflow?.rule_name === item.rule_name)
        return isEqual[0]
    }
    const createWorkflow = async (item: Workflow) => {
        let payload = {
            merchant_id: merchantDetails?.id,
            name: item.rule_name,
            ...item,
            created_at: new Date().toISOString(),
        }

        try {
            let response = await createWorkflowAsync(payload)
            if (response.success && response.data) {
                message.success('Workflow Enabled Successfully')
                fetchWorkflowAsync()
            }
        } catch (err) {
            message.error('Workflow Creation Failed')
            console.log(err)
        }
    }

    const handleClick = (e) => {
        console.log(e)
        setCurrentFilter(e)
        onClick(e)
    }

    const enableWorkflow = async (idx: number, isEnabled: boolean) => {
        let updatedWorkflows = [...allWorkflow]
        updatedWorkflows[idx] = {
            ...updatedWorkflows[idx],
            updated_at: new Date().toISOString(),
            is_enabled: !isEnabled,
        }

        const nonTemplates = updatedWorkflows?.filter((itm) => !itm?.template)
        const work = workflows.map((itm: Workflow) => {
            let isEqual = nonTemplates?.filter((workflow: Workflow) => workflow.rule_name == itm.rule_name)
            return isEqual[0] ? isEqual[0] : { ...itm, updated_at: new Date().toISOString() }
        })
        try {
            let response = await updateWorkflowAsync(work, merchantDetails?.id)
            if (response.success && response.data) {
                message.success(`Workflow ${!isEnabled ? 'Enabled' : 'Disabled'} Successfully`)
                dispatch(setWorkflows(work))
            }
        } catch (err) {
            message.error('Updation Failed')
            console.log(err)
        }
    }

    useEffect(() => {
        let intervalId
        if (isProgressModalVisible) {
            intervalId = setInterval(async () => {
                try {
                    const response = await checkProgressAPI()
                    if (response.success) {
                        if (response?.data?.data === 'completed') {
                            setProgress(100)
                            clearInterval(intervalId)
                            setEidaStatus('completed')
                            // window.location.reload()
                        } else {
                            setProgress((prev) => Math.min(prev + 10, 99))
                        }
                    }
                } catch (error) {
                    console.error('Error checking progress:', error)
                }
            }, 30000) // 30 seconds
            setProgress((prev) => Math.min(prev + 1, 99))
        }

        return () => clearInterval(intervalId)
    }, [isProgressModalVisible])

    const checkProgressAPI = async () => {
        return await getEidaReccomendationStatus(merchantDetails?.m_id)
    }

    const generateWorkflows = () => {
        setShowModal(true)
    }

    const handleGenerateOk = () => {
        setShowModal(false)
        setIsProgressModalVisible(true)
        getRecommendations(merchantDetails?.id, merchantDetails?.m_id)
    }

    return (
        <div>
            <Layout hasSider>
                <Content>
                    {showBoostAWB && (
                        <div className='p-4 bg-white'>
                            <BoostAWB
                                heading='AWB Data Not in Sync'
                                subHeading='Improve your AWB Fill Rate to uncover valuable RTO insights, optimize delivery performance, and proactively address potential issues.'
                            />
                        </div>
                    )}
                    <div className='overflow-scroll p-4 bg-white h-full'>
                        <div className='flex justify-between recommended-segmented' style={{ paddingBottom: '10px' }}>
                            <Segmented
                                options={abControlEnable ? rtoActionsOptionsMenuWithAB : rtoActionsOptionsMenu}
                                defaultValue={current}
                                onChange={onClick}
                            ></Segmented>
                            <Button onClick={generateWorkflows} disabled={!isEligibe} type='primary'>
                                Generate Workflows
                            </Button>
                        </div>
                        <div>
                            {eidaReccomendations.length === 0 ? (
                                <EmptyState
                                    title='Recommended workflows not found'
                                    icon={<InfoCircleFilled className='text-7xl text-[#004b8d]' />}
                                />
                            ) : (
                                allWorkflow?.map((item, index) => (
                                    <Fragment key={index}>
                                        <div
                                            key={index}
                                            className={`flex p-4 items-center gap-4 flex-1 rounded-lg border-solid border border-gray-300 mb-1.5 ${draggedListItem === index && opacity
                                                }`}
                                            draggable
                                        >
                                            <div className='flex py-3 pr-4 pl-0 flex-col justify-center align-start gap-3 self-stretch border-solid border-0 border-r border-r-gray-200 items-center cursor-pointer'>
                                                <p>{index + 1}</p>
                                            </div>
                                            <div className='flex flex-col items-start gap-2 flex-1'>
                                                <div className='flex items-center gap-2 self-stretch'>
                                                    <Switch
                                                        checked={item.is_enabled}
                                                        onChange={() => {
                                                            !isWorkflowPresent(item)
                                                                ? createWorkflow(item)
                                                                : enableWorkflow(index, item.is_enabled)
                                                        }}
                                                    />
                                                    <p className='flex-1 text-stone-900 text-base font-semibold leading-6'>
                                                        {item.rule_name}
                                                    </p>
                                                    {/* <DeleteFilled
                                        onClick={() => {
                                            setDeleteModal({ item, index })
                                        }}
                                        className='text-xl text-gray-500'
                                    /> */}
                                                </div>
                                                <p className='self-stretch color-[##00000073] text-xs leading-5'>
                                                    Type: {capitalize(item.type.toLowerCase())} | Last changes on:{' '}
                                                    {/* @ts-ignore */}
                                                    {dayjs(item.updated_at).fromNow()}
                                                </p>
                                                <div className='flex items-start gap-4 self-stretch justify-between'>
                                                    <div></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                ))
                            )}
                        </div>
                    </div>
                </Content>
            </Layout>
            <Modal
                open={showModal}
                closable
                title='Generate Workflows'
                okText='Generate'
                cancelText='Cancel'
                onOk={() => {
                    handleGenerateOk()
                }}
                onCancel={() => setShowModal(false)}
                centered
            >
                <p>
                    Are you sure you want to generate new workflows?
                    <br /> You will be able to generate workflows once in 4 hours.
                </p>
            </Modal>

            <Modal
                open={isProgressModalVisible}
                onCancel={() => setIsProgressModalVisible(false)}
                footer={[
                    <div style={{ textAlign: 'center' }}>
                        {eidaStatus === 'pending' ? (
                            <Button key='cancel' onClick={() => setIsProgressModalVisible(false)}>
                                Cancel
                            </Button>
                        ) : (
                            <Button
                                key='cancel'
                                onClick={() => {
                                    setIsProgressModalVisible(false)
                                    window.location.reload()
                                }}
                            >
                                Reload
                            </Button>
                        )}
                    </div>,
                ]}
                centered={true}
            >
                <div className='text-center'>
                    <Progress percent={progress} type='circle' />
                    <p style={{ fontSize: '16px', fontWeight: 'bold' }}>Recommendations</p>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#f0f0f0',
                        }}
                    >
                        <InfoCircleOutlined />
                        <p style={{ fontSize: '12px', color: '#808080', marginLeft: '5px' }}>
                            {eidaStatus === 'pending'
                                ? 'Workflow generation may take 5-10 minutes'
                                : 'Workflows generated successfully. Please refresh the page.'}
                        </p>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ReccomendedWorkflow
