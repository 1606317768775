import { downloadFromURL, downloadToCSV, makeAPICall, titleCase } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { cohortDiscountTooltips, DiscountApplicabilityKeys } from '@library/utilities/constants/constants'
import useMerchantConfigApi from '@library/utilities/hooks/useMerchantConfig'
import { DiscountApplicability } from '@library/utilities/interface'
import {
    Button,
    Col,
    ColumnsType,
    DeleteOutlined,
    Input,
    PlusOutlined,
    Row,
    Select,
    Switch,
    Table,
    message,
    Upload,
    Form,
    UploadOutlined,
    QuestionCircleOutlined,
    Tooltip,
    DownloadOutlined
} from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'

const matchTypes = {
    1: 'exact match',
    2: 'starts with',
    3: 'ends with',
}


const DiscountApplicabilityCodes = () => {
    const [fileList, setFileList] = useState([]);
    const [uploadedCsvFile, setUploadedCsvFile] = useState(null);

    const { config, loading, refetch, saveConfig } = useMerchantConfigApi({
        useCache: true,
        configSaveUrl: APIEndPoints.updateDiscountConfig,
    })
    const { config: discountCodes } = useMerchantConfigApi({
        useCache: false,
        configGetUrl: APIEndPoints.offersAndDiscounts,
    })
    const [updatedDiscountConfig, setUpdatedDiscountConfig] = useState<
        Partial<{
            user_type_discount: boolean
            discount_applicability: Partial<DiscountApplicability>[] | []
        }>
    >({})
    const [discountApplicable, setDiscountApplicable] = useState<Partial<DiscountApplicability>>({
        eligibility_rule: {
            match_type: null,
            user_type: null,
        },
        identifier: null,
        cohort_details: {
            cohort_source: null,
            included: null,
            file_name: ''
        },
        is_active: true,
        discount_code: '',
    })

    function uploadCohortCsv(file) {
        let reader = new FileReader()
        reader.readAsText(file)
        reader.onload = (e) => {
            const content = e.target.result as string
            const rows = content
                .split('\n')
                .filter((row) => row.trim() !== '')
                .map((row) => row.trim())
            if (rows.length <= 1) {
                message.error('Empty CSV file. Please upload a file with phone numbers!')
                setFileList([])
                return
            }
            if(rows[0] !== 'Phone Numbers'){
                message.error('Invalid CSV Format, please check sample CSV');
                setFileList([])
                return;
            }
            for (let i = 1; i < rows.length; i++) {
                if (!/^\d{10}$/.test(rows[i])) {
                    message.error(`Invalid phone number detected. Phone number must be of 10 digits - ${rows[i]}`);
                    setFileList([])
                    return
                }
            }
            setUploadedCsvFile(file);
        }
    }

    const [discountData, setDiscountData] = useState<{
        user_type_discount: boolean
        discount_applicability: Partial<DiscountApplicability>[] | []
    }>({
        user_type_discount: false,
        discount_applicability: [],
    })

    const discountApplicableAddition = () => {
        if (!discountApplicable.eligibility_rule.user_type) {
            message.error('Please select applicable to');
            return;
        } else if (!discountApplicable.eligibility_rule.match_type) {
            message.error('Please select match type');
            return;
        }  else if (!discountApplicable.discount_code) {
            message.error('Please enter discount code');
            return;
        } else if (discountData?.discount_applicability?.some((discount: DiscountApplicability) => discount.discount_code?.toLowerCase() === discountApplicable.discount_code?.toLowerCase())) {
            message.error('Discount is already added');
            return;
        }
        if (['new', 'repeat'].includes(discountApplicable.eligibility_rule.user_type)){
            if(!discountApplicable.identifier) {
                message.error('Please select identifier');
                return;
            }
        } else if(discountApplicable.eligibility_rule.user_type === 'cohort'){
            const isDiscountVisible = discountCodes?.merchantDiscounts?.some(discount => discount.is_cohort_based && checkMatchType(discount.discount_code.toLowerCase(), discountApplicable.discount_code.toLowerCase(), discountApplicable.eligibility_rule.match_type));
            if (!isDiscountVisible) {
                message.error('This discount code is not set up as cohort based under Offers Visibility Setup');
                return;
            }
            if(!discountApplicable.cohort_details.cohort_source){
                message.error('Please select cohort source');
                return;
            }
            if(discountApplicable.cohort_details.cohort_source === DiscountApplicabilityKeys.uploaded_CSV.value){
                if(discountApplicable.cohort_details.included === null){
                    message.error('Please specify if uploaded CSV should be included/excluded');
                    return;
                }
                if(!uploadedCsvFile){
                    message.error('Please upload a CSV file');
                    return;
                }
            }
        }
        if(discountApplicable?.cohort_details.included){
            discountApplicable.cohort_details.included = discountApplicable?.cohort_details.included === 'true';
        }
        discountApplicable.discount_code = discountApplicable.discount_code.toLowerCase();

        const temp = JSON.parse(
            JSON.stringify({
                ...discountApplicable,
                eligibility_rule: {
                    ...discountApplicable.eligibility_rule,
                    match_type: +discountApplicable.eligibility_rule.match_type,
                },
            }),
        )

        setDiscountApplicable({
            eligibility_rule: {
                match_type: null,
                user_type: null,
            },
            identifier: null,
            cohort_details: {
                cohort_source: null,
                included: null,
                file_name: ''
            },
            is_active: true,
            discount_code: '',
        });
        setFileList([])
        setUpdatedDiscountConfig((prev) => ({
            ...prev,
            discount_applicability: [...(prev?.discount_applicability || []), { ...temp }],
        }))

        setDiscountData((prev) => ({
            ...prev,
            discount_applicability: [...(prev?.discount_applicability || []), { ...temp }],
        }))
    }
    function checkMatchType (viewOfferDiscount, userTypeDiscount, matchType) {
        switch (Number(matchType)) {
        case 1: {
            if (viewOfferDiscount == userTypeDiscount)
                return true;
            break;
        }
        case 2: {
            if (viewOfferDiscount.startsWith(userTypeDiscount))
                return true;
            break;
        }
        case 3: {
            if (viewOfferDiscount.endsWith(userTypeDiscount))
                return true;
            break;
        }
        }
        return false;
    }

    const discountApplicableDelete = (item: Partial<DiscountApplicability>, idx: number) => {
        const tempUpdated = JSON.parse(JSON.stringify(updatedDiscountConfig))
        let isDeletedDiscountTemporary: boolean //flag for deleted discount if it was created in the same session without saving
        if (tempUpdated?.discount_applicability) {
            isDeletedDiscountTemporary = !!tempUpdated?.discount_applicability?.find(
                (itm: Partial<DiscountApplicability>) => itm.discount_code === item.discount_code,
            )
        }
        if (isDeletedDiscountTemporary) {
            setUpdatedDiscountConfig((prev) => ({
                ...prev,
                discount_applicability: tempUpdated?.discount_applicability?.filter(
                    (itm: Partial<DiscountApplicability>) => itm.discount_code !== item.discount_code,
                ),
            }))
        } else {
            setUpdatedDiscountConfig((prev) => ({
                ...prev,
                discount_applicability: [...(prev?.discount_applicability || []), { ...item, is_active: false }],
            }))
        }
        setDiscountData((prev) => ({
            ...prev,
            discount_applicability: prev.discount_applicability.filter((it, idex) => idex != idx),
        }))
    }

    useEffect(() => {
        if (config) {
            setDiscountData({
                user_type_discount: config.user_type_discount,
                discount_applicability: config.discount_applicability_data,
            })
        }
    }, [config])

    const Inputs = [
        {
            label: 'Applicable To*',
            placeholder: 'Select option',
            keys: ['eligibility_rule', 'user_type'],
            component: Select,
            options: ['new', 'repeat', 'cohort'].map((option) => ({
                label: titleCase(option),
                value: option,
            })),
        },
        {
            label: 'Match Type*',
            placeholder: 'Select option',
            keys: ['eligibility_rule', 'match_type'],
            component: Select,
            options: Object.entries(matchTypes).map(([value, label]) => ({
                label: titleCase(label),
                value,
            })),
        },
        {
            label: 'Discount Code*',
            placeholder: 'Enter Shopify discount code',
            keys: ['discount_code'],
            component: Input,
        },
        ...(['new', 'repeat'].includes(discountApplicable.eligibility_rule.user_type)
            ? [
                {
                    label: 'Identifier*',
                    placeholder: 'Select option',
                    keys: ['identifier'],
                    component: Select,
                    options: ['email', 'phone'].map((option) => ({
                        label: titleCase(option),
                        value: option,
                    })),
                },
              ]
            : [
                {
                    label: 'Cohort Source*',
                    placeholder: 'Select option',
                    keys: ['cohort_details', 'cohort_source'],
                    component: Select,
                    options: [
                        {
                          label: DiscountApplicabilityKeys.shopify.label,
                          value: DiscountApplicabilityKeys.shopify.value
                        },
                        {
                          label: DiscountApplicabilityKeys.uploaded_CSV.label,
                          value: DiscountApplicabilityKeys.uploaded_CSV.value
                        }
                    ],
                },
            ]),
        ...((discountApplicable.eligibility_rule.user_type === 'cohort' && discountApplicable.cohort_details.cohort_source === DiscountApplicabilityKeys.uploaded_CSV.value)
            ? [
                {
                    label: 'Include/Exclude*',
                    placeholder: 'Select option',
                    keys: ['cohort_details', 'included'],
                    component: Select,
                    options: [
                        {
                        label: DiscountApplicabilityKeys.include,
                        value: 'true'
                        },
                        {
                        label: DiscountApplicabilityKeys.exclude,
                        value: 'false'
                        }
                    ],
                },
                {
                    label: (
                        <p>
                            Upload Cohort CSV
                            <Tooltip
                                title={
                                    <>
                                        <ul style={{ padding: '0 20px', margin: '0' }}>
                                        {cohortDiscountTooltips.csvDownload.map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                        </ul>
                                        <div style={{textAlign: 'center', marginTop: '5px'}}>
                                            <a onClick={handleSampleCsvClick}>Sample CSV</a>
                                        </div>
                                    </>
                                }
                >
                                <QuestionCircleOutlined className='opacity-40' />
                            </Tooltip>
                        </p>
                    ),
                    placeholder: 'Select file',
                    component: Upload,
                    uploadProps: {
                        accept: '.csv',
                        name: 'file',
                        maxCount: 1,
                        multiple: false,
                        beforeUpload: (file) => {
                            uploadCohortCsv(file);
                            return false;
                        },
                    },
                },
            ]
            : []
        ),
    ]

    const columns: ColumnsType<any> = [
        {
            title: 'Applicable To',
            dataIndex: 'eligibility_rule',
            key: 'eligibility_rule',
            render: (text, record, index) => titleCase(record.eligibility_rule.user_type),
        },
        {
            title: 'Match Type',
            dataIndex: 'eligibility_rule',
            key: 'eligibility_rule',
            render: (text, record, index) => titleCase(matchTypes[record.eligibility_rule.match_type]),
        },
        {
            title: 'Discount Code',
            dataIndex: 'discount_code',
            key: 'discount_code',
        },
        {
            title: 'Identifier',
            dataIndex: 'identifier',
            key: 'identifier',
            render: (text, record, index) => (record?.identifier && typeof(record.identifier) === 'string') ? titleCase(record.identifier) : 'N/A',
        },
        {
            title: 'Cohort Source',
            dataIndex: 'cohort_details',
            key: 'cohort_details',
            render: (text, record, index) => 
                record?.cohort_details?.cohort_source && typeof record.cohort_details?.cohort_source === 'string'
                    ? titleCase(record.cohort_details.cohort_source === DiscountApplicabilityKeys.uploaded_CSV.value ? DiscountApplicabilityKeys.uploaded_CSV.label : record.cohort_details.cohort_source)
                    : 'N/A',        },
        {
            title: 'Include/Exclude',
            dataIndex: 'cohort_details',
            key: 'cohort_details',
            render: (text, record, index) => record?.cohort_details?.cohort_source ===DiscountApplicabilityKeys.uploaded_CSV.value ? (record?.cohort_details?.included ? DiscountApplicabilityKeys.included : DiscountApplicabilityKeys.exclude) : 'N/A',
        },
        {
            title: 'Download CSV',
            key: 'action_button',
            render: (text, record, index) => (
                record?.cohort_details?.cohort_source === DiscountApplicabilityKeys.uploaded_CSV.value 
                ?
                <DownloadOutlined
                    onClick={() => downloadFile(record, index)}
                    className='text-blue-500'
                />
                : 'N/A'
            ),
        },
        ...(discountData.user_type_discount
            ? [
                  {
                      title: '',
                      key: 'action',
                      render: (text, record, index) => (
                          <DeleteOutlined
                              className='text-error-500'
                              onClick={() => discountApplicableDelete(record, index)}
                          />
                      ),
                  },
              ]
            : []),
    ]
    function handleSampleCsvClick() {
        const data = [{phone: '1122500998'},{phone: '1122322334'},{phone: '2233233445'}];
        downloadToCSV(['phone'],['Phone Numbers'], data, 'SampleCSVFormat');
    }
    const downloadFile = async (record, index) => {
        if(!record?.cohort_details?.file_name){
            message.error('Please save the discount before downloading file');
            return;
        }
        const response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.downloadCohortCSV,
            params: {
                discount_code: record.discount_code,
                file_name: record.cohort_details.file_name
            }
        })
        if(response.success){
            const url = response.data.data.download_url;
            downloadFromURL(url, record.cohort_details.file_name)
        }
        else{
            message.error('File could not be downloaded, please try again!');
            return;
        }
    }
    const handleChange = (value: any, keys: string[], temp: any) => {
        if (keys.length === 1) {
            return { ...temp, [keys[0]]: value };
        } else {
            return { ...temp, [keys[0]]: { ...handleChange(value, keys.slice(1), temp[keys[0]]) } }
        }
    }
    const getSignedUrl = async (file_name: string, merchant_id: string) => {
        const response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.getCohortPresignedUrl,
            params: { file_name },
        })
        if (response.success) {
            return response.data.data.url
        }
    }

    const checkAndUploadPincodes = async (updatedDiscountConfig: Partial<DiscountApplicability>[]) => {
        if(!updatedDiscountConfig) return true;
        for (const discount of updatedDiscountConfig) {
            if (!discount.is_active || !(discount?.cohort_details?.cohort_source === DiscountApplicabilityKeys.uploaded_CSV.value)) continue;
            const uploadedCsvFileName = uploadedCsvFile.name || 'PhoneNumbers.csv'
            const url = await getSignedUrl(uploadedCsvFileName, discount.merchant_id);
            const file_name = await uploadCsvData(url);
            if(!file_name){
                message.error('CSV File can not be uploaded, please try again!');
                return false;
            }
            discount.cohort_details.file_name = file_name;
        }
        return true;
    }

    async function uploadCsvData(url: string) {
        try {
            const awsResponse = await fetch(url, {
                method: 'PUT',
                body: uploadedCsvFile,
            })
            if (awsResponse.status == 200) {
                const parsedUrl = new URL(url)
                const parts = parsedUrl.pathname.split('/')
                const nonEmptyParts = parts.filter((part) => part !== '')
                const filename = nonEmptyParts.pop()
                return decodeURIComponent(filename)
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    return (
        <Col className='bg-white' span={24}>
            <Row gutter={[16, 24]} align={'bottom'}>
                <Col span={24}>
                    <span className='mr-1'>
                        {!discountData.user_type_discount ? 'Enable' : 'Disable'} Specific Discounts
                    </span>
                    <Switch
                        checked={!!discountData.user_type_discount}
                        onChange={(checked) => {
                            setDiscountData((prev) => ({
                                ...prev,
                                user_type_discount: checked,
                            }));
                            setUpdatedDiscountConfig((prev) => ({
                                ...prev,
                                user_type_discount: checked,
                            }));
                        }}
                    />
                </Col>
                {Inputs.map((input, index) => (
                    <Col span={4} key={index}>
                        {input.component === Upload ? (
                            <Form.Item label={input.label} className = 'mb-0' >
                                <Upload {...input.uploadProps} fileList={fileList} onChange={(info) => {
                                setFileList(info.fileList);
                                }}>
                                    <Button icon={<UploadOutlined />} disabled= {!discountData.user_type_discount}>Click to Upload</Button>
                                </Upload>
                            </Form.Item>
                        ) : (
                            <input.component
                                disabled={!discountData.user_type_discount}
                                className='w-full'
                                placeholder={input.placeholder}
                                label={input.label}
                                value={(() => input.keys.reduce((result, curr) => result?.[curr], discountApplicable))()}
                                options={input.options}
                                onChange={(e) =>
                                    setDiscountApplicable((prev) =>
                                        typeof e === 'object'
                                            ? { ...prev, [input.keys[0]]: e.target.value }
                                            : handleChange(e, input.keys, prev),
                                    )
                                }
                            />
                        )}
                    </Col>
                ))}
                <Col span={4}>
                    <Button onClick={discountApplicableAddition} disabled={!discountData.user_type_discount}>
                        <PlusOutlined /> Add New Discount
                    </Button>
                </Col>
                <Col span={24}>
                    {discountData && (
                        <Table dataSource={discountData.discount_applicability} columns={columns} pagination={false} />
                    )}
                </Col>
                <Col>
                    <Button
                        variant='primary'
                        disabled={Object.keys(updatedDiscountConfig).length === 0}
                        onClick={async () => {
                                const response = await checkAndUploadPincodes(updatedDiscountConfig?.discount_applicability);
                                if (!response) return; 
                                saveConfig(
                                    {
                                        user_type_discount: discountData.user_type_discount,
                                        ...updatedDiscountConfig,
                                    },
                                    () => {
                                        setFileList([]);
                                        setUpdatedDiscountConfig({})
                                    },
                                )
                            }
                        }
                    >
                        Save
                    </Button>
                </Col>
            </Row>
        </Col>
    );    
}

export default DiscountApplicabilityCodes
