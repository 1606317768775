import { Tooltip, QuestionCircleOutlined, EyeOutlined } from 'gokwik-ui-kit'

export const bankDetailsFormConfig = {
    label: 'Bank Details',
    step: 'Step 3',
    status: 'Not Started',
    items: [
        {
            label: (
                <>
                    Account Holder Name
                    <Tooltip
                        title={
                            'Enter the name of the individual or entity that holds the bank account. This name must match the name on the bank account records.'
                        }
                    >
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'account_holder_name',
            type: 'input',
            placeholder: 'Account holder name',
            rules: [
                {
                    required: true,
                    message: 'Please add your account holder name',
                },
                {
                    validator: (_, value) => {
                        if (!value || value.trim() === '') {
                            return Promise.reject(new Error('Cannot be empty'))
                        }
                        const trimmedValue = value.trim()
                        if (!/^[A-Za-z0-9\s.]+$/.test(trimmedValue) || /<[^>]*>/g.test(trimmedValue)) {
                            return Promise.reject(new Error('Invalid Name'))
                        }
                        return Promise.resolve()
                    },
                },
            ],
            span: 12,
        },
        {
            label: (
                <>
                    Account Number
                    <Tooltip title={'Provide the unique account number associated with your bank account.'}>
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'account_number',
            type: 'input',
            placeholder: 'Account Number',
            rules: [
                {
                    required: true,
                    message: 'Please add your account number',
                },
                {
                    pattern: new RegExp('^(?=.*\\d)[A-Za-z0-9]{9,18}$'),
                    message: 'Account number must be 9-18 alphanumeric characters, including at least one digit',
                },
            ],
            span: 12,
        },
        {
            label: (
                <>
                    Bank Name
                    <Tooltip title={'Provide the name of the bank where the account is maintained.'}>
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'bank_name',
            type: 'input',
            placeholder: 'Bank Name',
            rules: [
                {
                    required: true,
                    message: 'Please add your bank name',
                },
                {
                    validator: (_, value) => {
                        if (!value || value.trim() === '') {
                            return Promise.reject(new Error('Cannot be empty'))
                        }
                        const trimmedValue = value.trim()
                        if (!/^[A-Za-z0-9\s.]+$/.test(trimmedValue) || /<[^>]*>/g.test(trimmedValue)) {
                            return Promise.reject(new Error('Invalid Name'))
                        }
                        return Promise.resolve()
                    },
                },
            ],
            span: 8,
        },
        {
            label: (
                <>
                    Branch Name
                    <Tooltip title={'Enter the specific branch name of the bank where your account is located.'}>
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'branch_name',
            type: 'input',
            placeholder: 'Branch Name',
            rules: [
                {
                    required: true,
                    message: 'Please add your bank branch name',
                },
                {
                    validator: (_, value) => {
                        if (!value || value.trim() === '') {
                            return Promise.reject(new Error('Cannot be empty'))
                        }
                        const trimmedValue = value.trim()
                        if (!/^[A-Za-z0-9\s.]+$/.test(trimmedValue) || /<[^>]*>/g.test(trimmedValue)) {
                            return Promise.reject(new Error('Invalid branch name'))
                        }
                        return Promise.resolve()
                    },
                },
            ],
            span: 8,
        },
        {
            label: (
                <>
                    IFSC Code
                    <Tooltip
                        title={
                            'Enter the Indian Financial System Code, which is an 11-character code assigned by the Reserve Bank of India to identify every bank branch uniquely.'
                        }
                    >
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'ifsc_code',
            type: 'input',
            placeholder: 'IFSC Code',
            class: 'uppercase',
            rules: [
                {
                    required: true,
                    message: 'Please add your bank IFSC Code',
                },
                {
                    pattern: RegExp(`^[A-Za-z]{4}0[A-Za-z0-9]{6}$`),
                    message: 'Please add your correct ifsc code',
                },
            ],
            span: 8,
        },
        {
            label: (
                <div className='flex justify-between items-center w-full'>
                    <div className='flex items-center'>
                        <span>Bank Proof (Cheque Book)</span>
                        <Tooltip
                            title={
                                'Upload a scanned copy or a clear photograph of your chequebook that shows your account details and IFSC code. Ensure that the image is legible'
                            }
                        >
                            <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                        </Tooltip>
                    </div>
                </div>
            ),
            name: 'bank_proof',
            type: 'upload',
            class: 'w-full',
            placeholder: 'Bank Proof (Cheque Book)',
            rules: [
                {
                    required: true,
                    message: 'Please add your bank proof',
                },
            ],
            span: 24,
        },
    ],
}
