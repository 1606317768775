// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@tailwind base;
@tailwind components;
@tailwind utilities;

.checkout-analytics .non-gk-abc .summary-blocks {
    flex-wrap: nowrap;
}
.checkout-analytics .non-gk-abc .summary-blocks .summary-block {
    max-width: 25%;
}

.checkout-analytics .non-gk-abc .summary-blocks .summary-block .summary-title {
    width: 100%;
    padding: 0 0.5rem;
}

.checkout-analytics .non-gk-abc .summary-blocks .summary-block .summary-title span.capitalize {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.diy-discount-button-dropdown li:hover span {
    color: var(--primary-blue-500);
}

.discount-form .ant-form-item {
    margin-bottom: 0;
}

.discount-form .ant-form-item.label-bold .ant-form-item-label label {
    font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,cAAc;AACd,oBAAoB;AACpB,mBAAmB;;AAEnB;IACI,iBAAiB;AACrB;AACA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["@tailwind base;\n@tailwind components;\n@tailwind utilities;\n\n.checkout-analytics .non-gk-abc .summary-blocks {\n    flex-wrap: nowrap;\n}\n.checkout-analytics .non-gk-abc .summary-blocks .summary-block {\n    max-width: 25%;\n}\n\n.checkout-analytics .non-gk-abc .summary-blocks .summary-block .summary-title {\n    width: 100%;\n    padding: 0 0.5rem;\n}\n\n.checkout-analytics .non-gk-abc .summary-blocks .summary-block .summary-title span.capitalize {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n\n.diy-discount-button-dropdown li:hover span {\n    color: var(--primary-blue-500);\n}\n\n.discount-form .ant-form-item {\n    margin-bottom: 0;\n}\n\n.discount-form .ant-form-item.label-bold .ant-form-item-label label {\n    font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
