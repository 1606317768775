import { makeAPICall, phoneInputValidation } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { Role } from '@library/utilities/interface'
import { getMerchantDetails } from '@store/user/selectors'
import { Button, Col, Drawer, Form, Input, Modal, Row, Select, Space, message } from 'gokwik-ui-kit'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
const { Option } = Select

interface RoleOption {
    value: string
    label: string
}

export const InviteOrEditTeamMember = ({
    openDrawer,
    setOpenDrawer,
    action,
    isMerchantUser,
    roles,
    updateUserParams,
    makeUserApIcall,
}) => {
    const [parameters, setParameters] = useState<any>({
        name: '',
        email: '',
        phone: '',
        role_id: '',
        status: '',
        email_mask: '',
    })
    const [proceed, setProceed] = useState(false)
    const [phoneErrorMsg, setPhoneErrorMsg] = useState('')

    const rolesData: RoleOption[] = useMemo(() => {
        return roles.reduce(
            (result: RoleOption[], curr: Role) => (result = [...result, { value: curr.id, label: curr.name }]),
            [],
        )
    }, [roles])
    const merchantDetails = useSelector(getMerchantDetails)

    function isFormFullyFilled(obj, action) {
        if (action === 'add_user') {
            delete obj['status']
        }

        if (action === 'update_user') {
            delete obj['email']
        }

        for (const key in obj) {
            if (!obj[key] && obj[key] !== 0) {
                return false
            }
        }
        return true
    }

    useEffect(() => {
        if (Object.keys(updateUserParams).length > 0) {
            setParameters({
                name: updateUserParams.name,
                email: updateUserParams?.email,
                phone: updateUserParams?.phone,
                role_id: updateUserParams?.role_id,
                status: updateUserParams?.status,
                email_mask: updateUserParams?.email_mask,
            })
        } else {
            setParameters({ name: '', email: '', phone: '', role_id: '', status: '' })
        }
    }, [updateUserParams])

    const inviteMember = async () => {
        if (!isMerchantUser && !parameters['email']?.includes('@gokwik.co') && merchantDetails?.m_id) {
            parameters.merchant_id = merchantDetails?.m_id
        }
        try {
            let response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.inviteMember,
                payload: { merchant_id: merchantDetails?.m_id, ...parameters },
            })

            setProceed(false)
            setOpenDrawer(false)
            setParameters({ name: '', email: '', phone: '', role_id: '', status: '' })

            if (response?.data?.status_code === 200) {
                message.success(`${response?.data?.data}`)
            } else if (response?.response?.data?.message) {
                message.error(response?.response?.data?.message)
            } else {
                message.error('Something went wrong !')
            }

            console.log(response)
        } catch (err) {
            console.warn(err)
        }
    }

    const getUpdateUserPayload = (parameters) => {
        let payload = parameters

        if (action === 'update_user') {
            delete payload['email']
            delete payload['email_mask']
        }

        payload['id'] = updateUserParams['id']
        payload['role_id'] = parameters['role_id']

        payload['merchant_id'] = merchantDetails?.m_id

        return payload
    }

    const updateMember = async () => {
        let queryString = '?user_type=merchant'

        const payload = getUpdateUserPayload(parameters)

        try {
            let response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.updateMember + queryString,
                payload: payload,
                skipMode: true,
            })

            setProceed(false)
            setOpenDrawer(false)
            setParameters({ name: '', email: '', phone: '', role_id: '', status: '' })

            if (response?.data?.status_code === 200) {
                message.success(`${response?.data?.data}`)
                makeUserApIcall()
            } else {
                message.error(response?.data?.data)
            }
        } catch (err) {
            console.warn(err)
        }
    }

    return (
        <>
            <Modal
                title={
                    <p>Are your sure, you want to {action === 'add_user' ? 'add new member' : 'edit this member'} ?</p>
                }
                open={proceed ? true : false}
                onOk={() => {
                    action === 'add_user' ? inviteMember() : updateMember()
                }}
                onCancel={() => {
                    setProceed(false)
                }}
                centered
                width={'450px'}
                footer={() => (
                    <Row className='flex justify-end gap-2'>
                        <Button onClick={() => setProceed(null)} variant='default'>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                action === 'add_user' ? inviteMember() : updateMember()
                            }}
                            variant='primary'
                        >
                            Confirm
                        </Button>
                    </Row>
                )}
            >
                <h3 className='mt-1'>
                    Name: {parameters['name']} <br />
                    Email: {action === 'update_user' ? parameters['email_mask'] : parameters['email']}
                </h3>
            </Modal>
            <Drawer
                title={action === 'update_user' ? 'Update Member' : 'Invite New Member'}
                placement='right'
                onClose={() => {
                    setPhoneErrorMsg('')
                    setOpenDrawer(false)
                    setParameters({ name: '', email: '', phone: '', role_id: '', status: '' })
                }}
                open={openDrawer}
            >
                <Space direction='vertical' className='w-full'>
                    <Row>
                        <div className='mt-0'></div>
                    </Row>
                    <Row>
                        <Col className='flex flex-col gap-y-4' sm={24}>
                            <Input
                                label={'Name'}
                                type='text'
                                value={parameters['name']}
                                placeholder='Name'
                                onChange={(e) => {
                                    setParameters((prev) => ({ ...prev, name: e.target.value }))
                                }}
                                allowClear
                            />
                            {action !== 'update_user' && (
                                <Input
                                    label={'Email'}
                                    type='text'
                                    value={parameters['email']}
                                    placeholder='Email'
                                    onChange={(e) => {
                                        setParameters((prev) => ({ ...prev, email: e.target.value }))
                                    }}
                                    allowClear
                                    disabled={action === 'update_user'}
                                />
                            )}
                            <Input
                                value={parameters['phone']}
                                label='Phone'
                                placeholder={
                                    action === 'update_user' ? 'Enter Updated Phone Number' : parameters['phone']
                                }
                                onChange={(e) => {
                                    const value = phoneInputValidation(e.target.value)
                                    if (value?.length < 10) {
                                        setPhoneErrorMsg('Min 10 digits is required!')
                                    } else {
                                        setPhoneErrorMsg('')
                                    }
                                    setParameters((prev) => ({ ...prev, phone: value }))
                                }}
                                prefix={'+91'}
                            />
                            {phoneErrorMsg && <span className=' text-red-500'>{phoneErrorMsg}</span>}
                            {/* {action === 'add_user' && !isMerchantUser && (
                                <Select
                                    value={parameters['merchant_id']}
                                    placeholder='Merchant Name'
                                    size='middle'
                                    className={'w-full'}
                                    showSearch={true}
                                    onChange={(newValue: any) => {
                                        setParameters((prev) => ({ ...prev, merchant_id: newValue }))
                                    }}
                                    label={'Merchant Name'}
                                    allowClear
                                    disabled={parameters['email']?.includes('gokwik.co')}
                                >
                                    {merchantList &&
                                        merchantList?.length > 0 &&
                                        merchantList?.map((option, index) => (
                                            <Option value={option?.m_id} key={index}>
                                                {option?.text || option?.business_name || option?.short_name}
                                            </Option>
                                        ))}
                                </Select>
                            )} */}
                            <Select
                                label={'Role'}
                                // size='middle'
                                className={'w-full'}
                                showSearch={true}
                                value={parameters['role_id']}
                                placeholder='Role'
                                onChange={(value) => {
                                    setParameters((prev) => ({ ...prev, role_id: value }))
                                }}
                                disabled={!rolesData.length}
                                options={rolesData}
                                allowClear
                            />
                            {isMerchantUser && action === 'add_user' && (
                                <Input label={'Account'} type='text' value={merchantDetails?.business_name} disabled />
                            )}
                            {action === 'update_user' && (
                                <Select
                                    label={'Status'}
                                    // size='middle'
                                    className={'w-full'}
                                    showSearch={true}
                                    value={parameters['status']}
                                    placeholder='Status'
                                    onChange={(value) => {
                                        setParameters((prev) => ({ ...prev, status: value }))
                                    }}
                                    options={[
                                        { value: 'active', label: 'Active' },
                                        { value: 'suspended', label: 'Suspended' },
                                    ]}
                                    allowClear
                                />
                            )}
                            <div className='flex gap-2 w-full'>
                                <Button
                                    onClick={() => {
                                        if (action !== 'update_user') {
                                            if (!isFormFullyFilled(parameters, action)) {
                                                message.error('Please fill all fields')
                                                return
                                            }
                                            if (parameters['phone'].length > 10 || parameters['phone'].length < 1) {
                                                message.error('Phone number not valid')
                                                return
                                            }
                                        }

                                        if (phoneErrorMsg) {
                                            message.error('Phone number not valid')
                                            return
                                        }

                                        setProceed(true)
                                    }}
                                    variant='primary'
                                    className='mt-5 w-[50%]'
                                >
                                    {action === 'update_user' ? 'Submit' : 'Send Invite'}
                                </Button>
                                <Button
                                    onClick={() => {
                                        setPhoneErrorMsg('')
                                        setOpenDrawer(false)
                                        setParameters({
                                            name: '',
                                            email: '',
                                            phone: '',
                                            role_id: '',
                                            status: '',
                                        })
                                    }}
                                    variant='default'
                                    className='mt-5 w-[50%]'
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Space>
            </Drawer>
        </>
    )
}
