import { updateBreadcrumbs } from '@gokwik/utilities'
import {
    ArrowLeftOutlined,
    Button,
    EnvironmentOutlined,
    LoginOutlined,
    Tabs,
    Tooltip,
    InfoCircleOutlined,
} from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import useShopifyMerchantConfigApi from '@library/utilities/hooks/useShopifyMerchantConfig'
import LoginPageCustomisation from './loginPage'
import AddressPageCustomisation from './addressPage'
import { ICommonEvents } from '@library/utilities/helpers/eventsHelper'
import PaymentPageCustomisation from './paymentPage'
import { defaultShopifyNewCheckoutConfig } from './constants'
import {
    IShopifyNewCheckoutUIConfig,
    IShopifyNewPaymentUIConfig,
    IShopifyPaymentUIConfig,
} from '@library/utilities/interface'

const CheckoutUiCustomisation = (props: { events: ICommonEvents; paymentUICustomisationEvents: ICommonEvents }) => {
    const navigate = useNavigate()
    const { configsUpsert } = props.events
    const { config, saveConfig } = useShopifyMerchantConfigApi({
        useCache: true,
        fireFailureEvent: configsUpsert.edit.fireFailureEvent,
        fireSuccessEvent: configsUpsert.edit.fireSuccessEvent,
    })
    const [showCopyConfirm, setShowCopyConfirm] = useState(false)
    const [activeTab, setActiveTab] = useState('login')
    const [updatedCheckoutUIConfig, setUpdatedCheckoutUIConfig] = useState<IShopifyNewCheckoutUIConfig>(
        defaultShopifyNewCheckoutConfig,
    )
    const [updatedPaymentUIConfig, setupdatedPaymentUIConfig] = useState<
        IShopifyPaymentUIConfig | IShopifyNewPaymentUIConfig
    >()

    const { config: configForPaymentPage, saveConfig: saveConfigForPaymentPage } = useShopifyMerchantConfigApi({
        useCache: true,
        fireFailureEvent: props.paymentUICustomisationEvents.configsUpsert.edit.fireFailureEvent,
        fireSuccessEvent: props.paymentUICustomisationEvents.configsUpsert.edit.fireSuccessEvent,
    })

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'checkout_settings',
                href: '/shopify-app/settings',
                text: 'Checkout Settings',
            },
            {
                key: 'ui-customisation',
                href: '/shopify-app/settings/ui-customisation',
                text: 'UI Customisation',
            },
            {
                key: 'checkout-ui',
                href: '/shopify-app/settings/ui-customisation/checkout-ui',
                text: 'Gokwik Checkout UI',
            },
        ])
    }, [])

    const handleCopyBanners = () => {
        const prevCheckoutBanners = config.newCheckoutUIConfig?.textConfig?.otherSections?.brandBanner || []
        const checkoutBanners = updatedCheckoutUIConfig?.textConfig?.otherSections?.brandBanner || []
        const existingBanners =
            ((updatedPaymentUIConfig as IShopifyNewPaymentUIConfig)?.textConfig?.bannerText?.length
                ? (updatedPaymentUIConfig as IShopifyNewPaymentUIConfig)?.textConfig?.bannerText
                : configForPaymentPage?.newPaymentsUIConfig?.textConfig?.bannerText) || []

        const newlyAddedBanners = checkoutBanners.filter((banner) => !prevCheckoutBanners.includes(banner))
        const mergedBanners = [...existingBanners]

        newlyAddedBanners?.forEach((newBanner) => {
            const emptyIndex = mergedBanners.findIndex((banner) => !banner)
            if (emptyIndex !== -1) {
                mergedBanners[emptyIndex] = newBanner
            }
        })
        const trimmedBanners = mergedBanners?.slice(0, 3)
        const trimmedConfig = {
            ...configForPaymentPage?.newPaymentsUIConfig,
            textConfig: {
                ...configForPaymentPage?.newPaymentsUIConfig?.textConfig,
                bannerText: trimmedBanners,
            },
        }

        saveConfig({
            newCheckoutUIConfig: updatedCheckoutUIConfig,
            newPaymentsUIConfig: trimmedConfig,
        })
        setupdatedPaymentUIConfig(trimmedConfig)
        setShowCopyConfirm(false)
    }

    const handleNotToCopy = () => {
        setActiveTab('payment')
        saveConfig({
            newCheckoutUIConfig: updatedCheckoutUIConfig,
        })
        setShowCopyConfirm(false)
    }

    const items = [
        {
            label: 'Login',
            key: 'login',
            icon: <LoginOutlined />,
            children: (
                <LoginPageCustomisation
                    updatedCheckoutUIConfig={updatedCheckoutUIConfig}
                    setUpdatedCheckoutUIConfig={setUpdatedCheckoutUIConfig}
                    setShowCopyConfirm={setShowCopyConfirm}
                    config={config}
                    saveConfig={saveConfig}
                    fireDiscardSaveEvent={configsUpsert.discardSave}
                />
            ),
        },
        {
            label: 'Address',
            key: 'address',
            icon: <EnvironmentOutlined />,
            children: (
                <AddressPageCustomisation
                    config={config}
                    saveConfig={saveConfig}
                    fireDiscardSaveEvent={configsUpsert.discardSave}
                />
            ),
        },
        {
            label: 'Payment',
            key: 'payment',
            icon: <EnvironmentOutlined />,
            children: (
                <PaymentPageCustomisation
                    config={configForPaymentPage}
                    saveConfig={saveConfigForPaymentPage}
                    events={props.paymentUICustomisationEvents}
                    updatedPaymentUIConfig={updatedPaymentUIConfig}
                    setupdatedPaymentUIConfig={setupdatedPaymentUIConfig}
                />
            ),
        },
    ]

    return (
        <div className='rounded-sm p-2'>
            <div className='flex items-top'>
                {showCopyConfirm && (
                    <div className='absolute z-50 globalSave p-4 rounded-lg'>
                        <div className='flex items-center text-white'>
                            <Tooltip>
                                <InfoCircleOutlined className='ml-2 mr-2' />{' '}
                            </Tooltip>
                            <p className='text-white text-base font-normal'>
                                Do you want to copy the same banner text to the payments page?
                            </p>
                        </div>
                        <div className='flex gap-3'>
                            <Button type='default' onClick={() => handleNotToCopy()}>
                                No
                            </Button>
                            <Button
                                type='primary'
                                onClick={() => handleCopyBanners()}
                                className='bg-green-500 text-white'
                            >
                                Yes
                            </Button>
                        </div>
                    </div>
                )}
                <Button className='bg-gray-200 border-none' onClick={() => navigate(-1)}>
                    <ArrowLeftOutlined />
                </Button>
                <div className='ml-2'>
                    <h2 className='text-lg font-semibold'>Gokwik Checkout UI</h2>
                    <p>
                        This configuration allows to control the banner text and colors, as well as the text on the
                        call-to-action buttons on GoKwik Checkout UI.
                    </p>
                </div>
            </div>
            <div>
                <Tabs activeKey={activeTab} onChange={(key) => setActiveTab(key)} items={items} />
            </div>
        </div>
    )
}

export default CheckoutUiCustomisation
