import { Tooltip, QuestionCircleOutlined } from 'gokwik-ui-kit'

const validateCityState = (value) => {
    if (!value || value.trim() === '') {
        return Promise.reject(new Error(''))
    }
    // Trim leading and trailing spaces and check for only alphabetic characters and spaces, also no HTML tags
    const trimmedValue = value.trim()
    if (!/^[a-zA-Z\s]+$/.test(trimmedValue) || /<[^>]*>/g.test(trimmedValue)) {
        return Promise.reject(new Error('Only alphabetic characters and spaces are allowed!'))
    }
    return Promise.resolve()
}

export const businessAddressFormConfig = {
    label: 'Address Details',
    step: 'Step 2',
    status: 'In Progress',
    items: [
        {
            label: (
                <>
                    Business Address
                    <Tooltip
                        title={
                            'Enter the official address of your business. This should match the location details provided in your business registration documents'
                        }
                    >
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'business_address',
            type: 'input',
            placeholder: 'Business Address',
            rules: [
                {
                    required: true,
                    message: 'Please add your business address',
                },
                {
                    validator: (_, value) =>
                        value?.trim() !== ''
                            ? Promise.resolve()
                            : Promise.reject(new Error('Please enter a valid address!')),
                },
            ],
            span: 24,
        },
        {
            label: (
                <>
                    Pincode
                    <Tooltip title={'Provide the postal code for your business location.'}>
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'pincode',
            type: 'inputNumber',
            placeholder: 'Pincode',
            rules: [
                {
                    required: true,
                    message: '',
                },
                {
                    validator: (_, value) =>
                        value && value.toString().length === 6
                            ? Promise.resolve()
                            : Promise.reject(new Error('Pincode must be 6 digits')),
                },
            ],

            span: 8,
        },
        {
            label: (
                <>
                    City
                    <Tooltip title={'Enter the city where your business is registered.'}>
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'city',
            type: 'input',
            placeholder: 'City',
            rules: [
                {
                    required: true,
                },
                {
                    validator: (_, value) => validateCityState(value),
                },
            ],
            span: 8,
        },
        {
            label: (
                <>
                    State
                    <Tooltip
                        title={
                            'Select the state from the dropdown where your business is located. This should be the same state as mentioned in your official business documents.'
                        }
                    >
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'state',
            type: 'input',
            placeholder: 'State',
            rules: [
                {
                    required: true,
                },
                {
                    validator: (_, value) => validateCityState(value),
                },
            ],
            span: 8,
        },
    ],
}
