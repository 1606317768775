import { IShopifyNewCheckoutUIConfig, IShopifyNewPaymentUIConfig, IShopifyPaymentUIConfig } from '@library/utilities/interface'

export const defaultShopifyNewCheckoutConfig: IShopifyNewCheckoutUIConfig = {
    textConfig: {
        discounts: {
            title: 'Coupons',
        },
        address: {
            title: 'Deliver To',
            cta: 'Proceed To Payment',
            shippingTitle: 'Delivery Options',
            addressCount: 2,
        },
        login: {
            cta: 'Proceed',
            title: 'Phone Number',
            privacyBanner:
                'We need your phone number to solely validate your account and fetch your <b>previously used addresses.</b>',
            checkboxText: 'Keep me posted about new deals',
            privacyText: `By proceeding, I accept GoKwik's <a href="https://www.gokwik.co/data-policy" target="_blank"><b>Privacy Policy</b></a> and <a href="https://www.gokwik.co/terms" target="_blank"><b>TnC</b></a>`,
            guestCheckoutTitle: 'Continue as Guest',
            otpTitle: 'Verify Mobile Number',
        },
        orderSummary: {
            title: 'Order Summary',
        },
        otherSections: {
            brandBanner: [],
        },
    },
    uiConfig: {
        showDiscounts: true,
        showOrderSummary: true,
        showBrandBanner: false,
        showGuestCheckoutOption: true,
    },
    colorConfig: {
        brandColor: '#000000',
        textColor: '#ffffff',
    },
}

export const defaultShopifyPaymentUIConfig: IShopifyPaymentUIConfig = {
    payment_page_text: {
        upi: {
            btn_text: 'Pay via UPI'
        },
        card: {
            btn_text: 'Pay via Card'
        },
        wallet: {
            btn_text: 'Pay via Wallet'
        },
        netbanking: {
            btn_text: 'Pay via Netbanking'
        },
        lazypay: {
            btn_text: 'Pay via LazyPay'
        },
        emi: {
            btn_text: 'Pay via EMI'
        },
        snapmint: {
            btn_text: 'Pay via Snapmint'
        },
        bnpl: {
            btn_text: 'Pay via BNPL'
        }
    }
}

export const defaultShopifyNewPaymentUIConfig: IShopifyNewPaymentUIConfig = {
    methodsCustomization: {
        upi: {
            buttonText: 'Pay via UPI'
        },
        card: {
            buttonText: 'Pay via Card'
        },
        wallet: {
            buttonText: 'Pay via Wallet'
        },
        netbanking: {
            buttonText: 'Pay via Netbanking'
        },
        lazypay: {
            buttonText: 'Pay via LazyPay'
        },
        emi: {
            buttonText: 'Pay via EMI'
        },
        snapmint: {
            buttonText: 'Pay via Snapmint'
        },
        paylater: {
            buttonText: 'Pay via BNPL'
        }
    },
    textConfig: {
        bannerText: [],
        showBrandBanner: false,
    }
}

export const PaymentMethodsReorderingLabelNameMapping = {
    'card': 'Cards',
    'wallet': 'Wallets',
    'upi': 'UPI',
    'netbanking': 'Internet Banking (NetBanking)',
    'emi': 'EMI',
    'snapmint': 'Snapmint',
    'lazypay': 'LazyPay',
    'bnpl': 'Buy Now Pay Later',
    'paylater': 'But Now Pay Later'
}

export const defaultPaymentMethods = ['upi', 'wallet-airtelmoney', 'wallet-phonepe', 'wallet-mobikwik', 'cc', 'dc', 'netbanking', 'snapmint'];

export const loginPageTooltips = {
    CtaButton: 'Customize the name of the call-to-action button on your checkout page',
    GuestCheckout: 'Enable or disable the option for customers to checkout as a guest',
    ConsentBox: 'Edit the text for the consent box to align with your privacy policy and terms',
}

export const addressPageTooltips = {
    CtaButton: 'Customize the name of the call-to-action button on your checkout page',
    AddressCount: 'Set the number of addresses (upto 10) to display on the checkout page',
}

export const paymentPageTooltips = {
    CtaButton: 'Customize the name of the call-to-action button on your payment page',
    Banner: 'Add custom banners to be displayed on your payment page.'
}

//sample data
export const sampleAddressList = [
    {
        fullName: 'Test Test',
        address1: 'Test address',
        city: 'Delhi',
        state: 'Delhi',
        country: 'India',
        pincode: '110016',
        phone: '9999999999',
        email: 'test@example.com',
    },
    {
        fullName: 'Manoj Kumar',
        address1: 'Test address',
        city: 'Bengaluru',
        state: 'Karnataka',
        country: 'India',
        pincode: '560010',
        phone: '9999999999',
        email: 'test@example.com',
    },
    {
        fullName: 'Arun Kumar',
        address1: 'Test address',
        city: 'Chennai',
        state: 'Tamil Nadu',
        country: 'India',
        pincode: '600028',
        phone: '9999999999',
        email: 'test@example.com',
    },
    {
        fullName: 'Neha Singh',
        address1: 'Test address',
        city: 'Bengaluru',
        state: 'Karnataka',
        country: 'India',
        pincode: '560001',
        phone: '9999999999',
        email: 'test@example.com',
    },
    {
        fullName: 'Priya Das',
        address1: 'Test address',
        city: 'Kolkata',
        state: 'West Bengal',
        country: 'India',
        pincode: '700016',
        phone: '9999999999',
        email: 'test@example.com',
    },
    {
        fullName: 'Rajesh Kumar',
        address1: 'Test address',
        city: 'Hyderabad',
        state: 'Telangana',
        country: 'India',
        pincode: '500003',
        phone: '9999999999',
        email: 'test@example.com',
    },
    {
        fullName: 'Ravi Verma',
        address1: 'Test address',
        city: 'Pune',
        state: 'Maharashtra',
        country: 'India',
        pincode: '411001',
        phone: '9999999999',
        email: 'test@example.com',
    },
    {
        fullName: 'Ankita Mehra',
        address1: 'Test address',
        city: 'Delhi',
        state: 'Delhi',
        country: 'India',
        pincode: '110075',
        phone: '9999999999',
        email: 'test@example.com',
    },
    {
        fullName: 'Vikram Singh',
        address1: 'Test address',
        city: 'Jaipur',
        state: 'Rajasthan',
        country: 'India',
        pincode: '302001',
        phone: '9999999999',
        email: 'test@example.com',
    },
    {
        fullName: 'Kavita Patel',
        address1: 'Test address',
        city: 'Ahmedabad',
        state: 'Gujarat',
        country: 'India',
        pincode: '380015',
        phone: '9999999999',
        email: 'test@example.com',
    },
]

export const dummyDeliveryOptions = [
    {
        id: '65545352fb6f',
        shipping_name: 'Standard Shipping',
        shipping_charge: 50,
        paymentOption: 'all',
    },
    {
        id: '65545352fb6g',
        shipping_name: 'Express Shipping',
        shipping_charge: 100,
        paymentOption: 'all',
    },
]

export const logoSources = (url) => {
    return [
        `https://cdn.gokwik.co/${url}`,
        // for lower environments
        `https://sandbox-store.gokwik.co/${url}`,
        `https://qa-store.gokwik.co/${url}`,
        `https://dev-store.gokwik.co/${url}`,
    ]
}
