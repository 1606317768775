import { stepConfiguration } from '@pages/onboarding/stepsConfig'
import { MerchantConfigKeys } from './constants/constants'

// Extending the Window interface to include fcWidget
declare global {
    interface Window {
        fcWidget: any
    }
}

const updateLiveChat = (merchantConfigs, merchant_details, userDetails, trackerData, userData) => {
    // if (window?.location?.pathname?.includes('/onboarding/kyc')) return
    //these are freshchat and freshworks offical chat ids, if they change their ids from their end then this code wont work anymore
    const fcFrameId = 'fc_frame'
    const freshworksContainerId = 'freshworks-container'

    function splitName(fullName) {
        const nameParts = fullName.trim().split(' ')
        const firstName = nameParts.shift() // Get the first word
        const lastName = nameParts.join(' ') // Join the remaining words

        return {
            firstName: firstName,
            lastName: lastName,
        }
    }

    function getKYCStatus(userData) {
        const current_onboarding_status = userData?.merchantDetails?.onboarding_status?.kwik_checkout_app
        if (
            stepConfiguration[current_onboarding_status]?.id >= 7 &&
            stepConfiguration[current_onboarding_status]?.id < 14
        ) {
            return 'KYC INCOMPLETE'
        } else if (current_onboarding_status === 'kyc_verification_pending') {
            return 'KYC APPROVAL PENDING'
        } else if (stepConfiguration[current_onboarding_status]?.id === 16) {
            return 'KYC APPROVED'
        } else if (current_onboarding_status === 'kyc_verification_failed') {
            return 'KYC REJECTED'
        } else {
            return ''
        }
    }

    function insertAndInitializeFCWidget() {
        const script = document.createElement('script')
        script.src = 'https://in.fw-cdn.com/31177801/539271.js'
        script.setAttribute('chat', 'true')
        script.setAttribute('widgetId', '5b912c0f-e00a-4af8-a803-1fc9060fc78d')

        // Function to initialize fcWidget with retries
        function initializeFCWidget(retries: number) {
            if (window.fcWidget) {
                // Initialize the FCWidget with the provided token and host
                window.fcWidget.init({
                    token: 'e4c0df03-ca80-4064-a70e-9be80a018abf',
                    host: 'https://wchat.freshchat.com',
                })
                // Open Freshchat on specific routes
                if (
                    (window.location.href.includes('/onboarding/kc') ||
                        window.location.href.includes('/get-started') ||
                        window.location.href.includes('/checkout/settings') ||
                        window.location.href.includes('/shopify-app/settings')) &&
                    (!localStorage.getItem('chat_opened_once') || localStorage.getItem('chat_opened_once') !== 'yes')
                ) {
                    localStorage.setItem('chat_opened_once', 'yes')
                    window?.fcWidget.open() // Automatically open the Freshchat popup on kwikcheckout routes only once per login session
                } else {
                    window?.fcWidget.close() // Automatically close it on other routes
                }
                window.fcWidget.user.setFirstName(merchant_details?.short_name || merchant_details?.business_name)
                window.fcWidget.user.setProperties({
                    email: userDetails?.email,
                    firstName: splitName(userDetails?.name)?.firstName,
                    lastName: splitName(userDetails?.name)?.lastName,
                    phone: userDetails?.phone,
                    cf_store_url: merchant_details?.website,
                    cf_merchant_id: merchant_details?.m_id,
                    cf_historical_orders: trackerData?.cf_historical_orders,
                    cf_aov: trackerData?.cf_aov,
                    cf_cod_percentage: trackerData?.cf_cod_percentage,
                    cf_total_order_value: trackerData?.cf_total_order_value,
                    cf_kyc_status: getKYCStatus(userData),
                    cf_kyc_rejection_reason:
                        userData?.merchantDetails?.onboarding_status?.kwik_checkout_app === 'kyc_verification_failed'
                            ? JSON.stringify(
                                  userData?.kycData?.kyc_failure_reasons +
                                      ' ' +
                                      userData?.kycData?.kyc_rejection_reason,
                              )
                            : '',
                })
            } else if (retries > 0) {
                // Retry after a short delay
                setTimeout(() => initializeFCWidget(retries - 1), 500)
            } else {
                console.error('FCWidget is not available on window after multiple attempts')
            }
        }

        document.body.appendChild(script)

        // Wait for the script to load before initializing
        script.onload = () => {
            initializeFCWidget(10) // Retry up to 10 times with a delay
        }
    }

    if (typeof document !== undefined) {
        const fcFrame = document.getElementById(fcFrameId)
        const freshworksContainer = document.getElementById(freshworksContainerId)
        if (merchantConfigs?.supported_checkout_configs?.[MerchantConfigKeys.kwikCheckout]) {
            if (freshworksContainer) {
                freshworksContainer.style.display = 'none'
            }
            if (fcFrame) {
                fcFrame.style.display = 'block'
            }
            insertAndInitializeFCWidget()
        } else {
            if (fcFrame) {
                fcFrame.style.display = 'none'
            }
            if (freshworksContainer) {
                freshworksContainer.style.display = 'block'
            }
            const script1 = document.createElement('script')
            script1.innerHTML = `
                window.fwSettings={'widget_id':82000005542};!function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}();
            `
            document.body.appendChild(script1)
            const script2 = document.createElement('script')
            script2.src = 'https://ind-widget.freshworks.com/widgets/82000005542.js'
            script2.async = true
            script2.defer = true
            document.body.appendChild(script2)
        }
    }
}

export { updateLiveChat }
