import { navigateToUrl } from 'single-spa'
import React, { useState, useEffect, useMemo, useCallback } from 'react'

import { Button, Col, Row, Input, message } from 'gokwik-ui-kit'

import LoginLayout from '@library/components/loginLayout'
import { useAppDispatch } from '@library/utilities/hooks'
import { sendVerifyEmailOTP, verifyOTP } from '@store/user'
import { useSelector } from 'react-redux'
import { getSignUpEmail } from '@store/user/selectors'
import { useLocation } from 'react-router'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'

const VerifyOtp = () => {
    const [formData, setFormData] = useState({
        otp: '',
    })
    const [timerOtp, setTimerOtp] = useState(60)
    const dispatch = useAppDispatch()
    const userEmail = useSelector(getSignUpEmail)
    const location = useLocation()
    const submit = useCallback(async () => {
        if (!formData.otp || formData.otp.length < 6) {
            message.error('Invalid OTP')
            return
        }

        logEvent('login_page_otp_submitted', 'click', 'login', userEmail || localStorage.getItem('email'))

        dispatch(
            verifyOTP(
                { ...formData, email: userEmail || localStorage.getItem('email') },
                location.state?.signIn ? true : false,
            ),
        )
    }, [formData])

    const callBack = (err?: any) => {
        if (!err) setTimerOtp(60)
        else {
            message.error(err.errorMessage)
        }
    }
    useEffect(() => {
        if (!timerOtp) return
        const timeout = setTimeout(() => setTimerOtp((prev) => prev - 1), 1000)
        return () => clearTimeout(timeout)
    }, [timerOtp])
    const resend = async (e) => {
        e.preventDefault()
        await dispatch(
            sendVerifyEmailOTP(
                { email: userEmail || localStorage.getItem('email') },
                callBack,
                location.state?.signIn ? true : false,
            ),
        )

        message.success('OTP re-sent successfully')
    }

    useEffect(() => {
        if (sessionStorage?.getItem('landed_otp_page') !== 'true') {
            logEvent('login_page_otp_landed', 'click', 'login', userEmail || localStorage.getItem('email'))
        }
        sessionStorage?.setItem('landed_otp_page', 'true')
    }, [])

    return (
        <LoginLayout
            titleContent={
                <>
                    <p className='text-3xl text-[#060322] font-normal leading-normal inter tracking-tight'>
                        Verify OTP
                    </p>
                    <svg xmlns='http://www.w3.org/2000/svg' width='398' height='2' viewBox='0 0 398 2' fill='none'>
                        <path d='M1 1L397 0.999965' stroke='#828282' stroke-linecap='round' />
                    </svg>
                    <p className='font-normal text-normal'>
                        {' '}
                        Enter the OTP sent to{' '}
                        <span className='font-semibold'>{userEmail || localStorage.getItem('email')}</span>
                    </p>
                </>
            }
        >
            <Row className='bg-[#ffffff] w-auto  rounded-lg  m-auto flex flex-col align-middle justify-center'>
                <div>
                    {/* <div
                        className='flex items-center cursor-pointer absolute top-[12%] text-[#002547]'
                        onClick={() => navigateToUrl('/login')}
                        style={{ marginBottom: '1.75rem' }}
                    >
                        <ArrowLeftIcon />
                        <h3 className='font-normal text-base inter ml-1'>Back</h3>
                    </div>
                    <div className='heading text-primary-750 text-left flex-start text-2xl font-semibold'>
                        OTP Verification
                    </div>
                    <div className='text-[#000000A6] subheading text-base font-normal'>
                        Enter the OTP sent to <span className='font-semibold'>{signupEmail}</span>
                    </div> */}
                    <div className='w-full flex flex-col' style={{ rowGap: '1.5rem' }}>
                        <div className='flex flex-col' style={{ rowGap: '0.75rem' }}>
                            <h4 className='body text-[#002547] text-sm !leading-[22px] font-medium'>Enter OTP</h4>
                            <Input
                                className='email-input gk-text-input'
                                value={formData.otp}
                                onChange={(e) =>
                                    setFormData((prev) => ({
                                        ...prev,
                                        otp: e.target.value.replace(/[^\d]/g, '').substring(0, 6),
                                    }))
                                }
                                placeholder='******'
                                onPressEnter={submit}
                            />
                        </div>

                        <Button
                            variant='primary'
                            className='next'
                            onClick={submit}
                            disabled={formData?.otp && formData?.otp?.length === 6 ? false : true}
                        >
                            <h3 className='body-bold'>Next</h3>
                        </Button>
                    </div>
                    <div className='text-center inter mt-2'>
                        {timerOtp === 0 ? (
                            <Button type='link' onClick={resend} className='w-full h-11' variant='default'>
                                <span className='body py-2 text-primary-700'>Resend OTP</span>
                            </Button>
                        ) : (
                            // <span className="fs14 lh-14 text-blue metro-m">
                            <p className='text-primary-800 text-center text-sm'>
                                Resend OTP in <span className='text-primary-400'>{timerOtp} Sec</span>
                            </p>
                        )}
                    </div>
                </div>
            </Row>
        </LoginLayout>
    )
}

export default VerifyOtp
