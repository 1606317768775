import { Button, CheckCircleFilled } from 'gokwik-ui-kit'

function UploadSuccess({ resetStates }) {
    return (
        <div className='flex flex-col gap-4 items-center justify-center h-full'>
            <CheckCircleFilled className='text-7xl text-[#14992A]' />
            <p className='text-lg font-semibold'>File uploaded successfully</p>
            <Button
                type='primary'
                onClick={() => {
                    resetStates()
                }}
            >
                Dismiss
            </Button>
        </div>
    )
}

export default UploadSuccess
