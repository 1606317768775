import { makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import useMerchantConfigApi from '@library/utilities/hooks/useMerchantConfig'
import { OffersDiscountKeys } from '@library/utilities/constants/constants'
import { OfferAndDiscount } from '@library/utilities/interface'
import {
    Button,
    Col,
    ColumnProps,
    ColumnsType,
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
    Popconfirm,
    Row,
    Switch,
    Table,
    Tag,
    message,
} from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import AddEditOffer from './addEditOffer'
import OneClickOffer from './oneClickOffer'
import OfferSortOrder from './offersOrderControl'
import { useSelector } from 'react-redux'
import { getUserConfig } from '@store/user/selectors'

const OffersDiscount = () => {
    const [searchedCode, setSearchedCode] = useState('')
    const userConfig = useSelector(getUserConfig)

    const [openAddDrawer, setOpenAddDrawer] = useState<boolean>(false)
    const [openRecommendedOfferDrawer, setOpenRecommendedOfferDrawer] = useState<boolean>(false)
    const [selectedDiscount, setSelectedDiscount] = useState<Partial<OfferAndDiscount>>(null)
    const [discountCodes, setDiscountCodes] = useState<Partial<OfferAndDiscount>[]>([])
    const { config, loading, refetch, saveConfig } = useMerchantConfigApi({
        useCache: true,
    })

    const [changeReordering, setChangeReordering] = useState({
        open: false,
        discounts: [],
    })

    const fetchDiscountCodes = async () => {
        const params = searchedCode
            ? { code: searchedCode }
            : {
                  page: 1,
                  pageSize: 150,
              }
        const res = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.offersAndDiscounts,
            params,
        })
        if (res.success) {
            const data = [...res.data.data.merchantDiscounts]
            if (config?.custom_config?.offers_fixed_positions) {
                setDiscountCodes(data.sort((a, b) => a?.sort_order - b?.sort_order))
            } else setDiscountCodes(data)
        }
    }

    const sortOrderColumn: ColumnProps<any> = {
        title: 'Sort Order',
        dataIndex: 'sort_order',
        key: 'sort_order',
        align: 'right',
    }

    const offersColumns: any = [
        {
            title: 'Code',
            dataIndex: 'discount_code',
            key: 'discount_code',
            fixed: 'left',
            filters: discountCodes.map((discount) => ({ text: discount.discount_code, value: discount.discount_code })),
            filterMode: 'menu',
            filterSearch: true,
            onFilter: (value: string, record: OfferAndDiscount) => record.discount_code.startsWith(value),
        },
        {
            title: 'Description',
            dataIndex: 'discount_detail',
            key: 'discount_detail',
            width: '200px',
        },
        {
            title: 'Details',
            dataIndex: 'additional_details',
            render: (text, record) => {
                let values =
                    typeof record?.additional_details?.bullet_points === 'string'
                        ? record?.additional_details?.bullet_points.split(',').map((value) => value.trim())
                        : record?.additional_details?.bullet_points
                if (!values) return null
                return (
                    <ul className='text-xs pl-3 list-disc'>
                        {values.map((value, index) => (
                            <li key={index}>{value}</li>
                        ))}
                    </ul>
                )
            },
            width: '200px',
            key: 'additional_details',
        },
        {
            title: 'Visibility',
            dataIndex: 'visibility',
            key: 'visibility',
            render: (text: string, record) => (
                <span className='capitalize'>{text.split('_').join(' ').toLowerCase()}</span>
            ),
        },
        {
            title: 'User Type',
            dataIndex: 'user_type',
            key: 'user_type',
            render: (text: string) => {
                switch (text) {
                    case OffersDiscountKeys.shopify_based_cohort.value:
                        return OffersDiscountKeys.shopify_based_cohort.label
                    case OffersDiscountKeys.csv_based_cohort.value:
                        return OffersDiscountKeys.csv_based_cohort.label
                    default:
                        return text
                }
            },
        },
        {
            title: 'Is Cohort Based',
            dataIndex: 'is_cohort_based',
            key: 'is_cohort_based',
            render: (text: boolean) => (text === undefined ? 'No' : text ? 'Yes' : 'No'),
        },
        ...(config?.custom_config?.offers_fixed_positions ? [{ ...sortOrderColumn }] : []),
        {
            title: 'Min Price',
            dataIndex: 'min_price',
            key: 'min_price',
            align: 'right',
        },
        {
            title: 'Max Price',
            dataIndex: 'max_price',
            key: 'max_price',
            align: 'right',
        },
        {
            title: 'Min Quantity',
            dataIndex: 'min_quantity',
            key: 'min_quantity',
            align: 'right',
        },
        {
            title: 'Max Quantity',
            dataIndex: 'max_quantity',
            key: 'max_quantity',
            align: 'right',
        },
        {
            title: 'Status',
            dataIndex: 'is_active',
            render(text, record) {
                return (
                    <Tag className='rounded-2xl !px-2' variant={record.is_active ? 'success' : 'warning'}>
                        {record.is_active ? 'Active' : 'Inactive'}
                    </Tag>
                )
            },
            fixed: 'right',
            align: 'center',
            key: 'is_active',
            filters: [
                {
                    text: 'Active',
                    value: true,
                },
                {
                    text: 'Inactive',
                    value: false,
                },
            ],
            filterMode: 'menu',
            filterSearch: true,
            onFilter: (value: boolean, record: OfferAndDiscount) => record.is_active === value,
        },
        ...(!userConfig.diy_enabled
            ? [
                  {
                      title: '',
                      dataIndex: 'actions',
                      fixed: 'right',
                      render: (text, record) => {
                          return (
                              <div className='flex gap-x-4'>
                                  <EditOutlined
                                      onClick={() => {
                                          setSelectedDiscount(record)
                                          setOpenAddDrawer(true)
                                      }}
                                  />
                                  <Popconfirm
                                      title={`Delete ${record.discount_code}?`}
                                      okText='Yes'
                                      okButtonProps={{ className: 'bg-error-500 hover:!bg-error-500 text-white' }}
                                      cancelText='No'
                                      onConfirm={() => updateDiscount({ id: record.id, is_deleted: true })}
                                  >
                                      <DeleteOutlined
                                          className='text-error-500'
                                          //    onClick={() => discountApplicableDelete(record, index)}
                                      />
                                  </Popconfirm>
                              </div>
                          )
                      },
                      key: 'actions',
                  },
              ]
            : []),
    ]

    const postDiscountCall = async (discount: Partial<OfferAndDiscount>) => {
        if (!discount?.sort_order && !discount.is_deleted) {
            discount['sort_order'] =
                discountCodes.sort((a, b) => a?.sort_order - b?.sort_order)[discountCodes?.length - 1]?.sort_order +
                    1 || 1
        }
        let discountId = discount.id
        delete discount.id
        delete discount.m_id
        delete discount.created_at
        delete discount.updated_at
        const response = await makeAPICall({
            method: 'post',
            url:
                process.env.REACT_APP_BASE_URL + APIEndPoints.offersAndDiscounts + (discountId ? `/${discountId}` : ''),
            payload: {
                ...discount,
                ...(!discount.is_deleted && { min_price: discount.min_price ?? 0 }),
            },
            skipMode: true,
        })
        return response
    }

    const updateAllDiscountCodes = async (discountCodesList: OfferAndDiscount[]) => {
        const response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.offersAndDiscountsAll,
            payload: {
                discount_codes: discountCodesList,
            },
            skipMode: true,
        })
        if (response.success) {
            fetchDiscountCodes()
            message.success(response.data.data || 'Discounts updated successfully')
            setChangeReordering({
                open: false,
                discounts: [],
            })
        }
        if (response.error) {
            message.error(response.response.data.data || 'Unable to update discount')
            return
        }
    }

    const updateDiscount = async (discount: Partial<OfferAndDiscount>) => {
        if (discount.additional_details)
            discount.additional_details.bullet_points =
                typeof discount?.additional_details?.bullet_points === 'string'
                    ? discount?.additional_details?.bullet_points.split(',').map((value) => value.trim())
                    : discount?.additional_details?.bullet_points
        const response = await postDiscountCall({ ...discount })
        if (response.error) {
            console.log(response.response.data.data)
            message.error(response.response.data.data || 'Unable to update discount')
            return
        } else {
            message.success(
                response.response?.data?.message || response.response?.data?.data || 'Operation Successfull',
            )
        }
        fetchDiscountCodes()

        openAddDrawer && setOpenAddDrawer(false)
        selectedDiscount && setSelectedDiscount(null)
    }

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'checkout_settings',
                href: '/checkout/settings',
                text: 'Checkout Settings',
            },
            {
                key: 'offers-discount',
                href: '/checkout/settings/offers-discounts',
                text: 'Offers Visibility Setup',
            },
        ])
    }, [])

    useEffect(() => {
        if (config) fetchDiscountCodes()
    }, [searchedCode, config])

    return (
        <div className='px-2 capitalize'>
            <Row className='bg-white rounded-sm p-3  ' gutter={[0, 24]} justify={'space-between'}>
                {changeReordering.open ? (
                    <>
                        <Col>
                            <p className='text-base font-semibold'>Updater Sorting of list</p>
                        </Col>
                        <Col>
                            <div className='flex items-center gap-x-4'>
                                <Button
                                    type='primary'
                                    onClick={() => {
                                        updateAllDiscountCodes(changeReordering.discounts)
                                    }}
                                >
                                    Save
                                </Button>
                                <Button
                                    onClick={() => {
                                        setChangeReordering({
                                            open: false,
                                            discounts: [],
                                        })
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Col>
                    </>
                ) : (
                    <>
                        <Col>
                            <p className='text-base font-semibold'>
                                {userConfig.diy_enabled ? 'Offers Visibility Setup' : 'List of Offers'}
                            </p>
                        </Col>
                        <Col>
                            <div className='flex items-center gap-x-4'>
                                {!userConfig.diy_enabled && (
                                    <>
                                        <div className='flex items-center gap-x-2'>
                                            <span>Offers Fixed Position</span>
                                            <Switch
                                                checked={config?.custom_config?.offers_fixed_positions}
                                                onChange={() => {
                                                    saveConfig({
                                                        ...config,
                                                        custom_config: {
                                                            ...config.custom_config,
                                                            offers_fixed_positions:
                                                                !config.custom_config.offers_fixed_positions,
                                                        },
                                                    })
                                                }}
                                            />
                                        </div>
                                        {config?.custom_config.offers_fixed_positions && (
                                            <Button
                                                onClick={() => {
                                                    setChangeReordering({ open: true, discounts: discountCodes })
                                                }}
                                            >
                                                Update Sorting
                                            </Button>
                                        )}
                                    </>
                                )}
                                <Button onClick={() => setOpenRecommendedOfferDrawer(true)}>Recommend Offer</Button>
                                {!userConfig.diy_enabled && (
                                    <Button type='primary' onClick={() => setOpenAddDrawer(true)}>
                                        <PlusOutlined /> Create Discount
                                    </Button>
                                )}
                            </div>
                        </Col>
                    </>
                )}
                <Col span={24}>
                    {changeReordering.open ? (
                        <OfferSortOrder
                            discounts={changeReordering.discounts}
                            setDiscountOrder={(discounts) => setChangeReordering((prev) => ({ ...prev, discounts }))}
                        />
                    ) : (
                        !userConfig.diy_enabled && (
                            <Table
                                scroll={{
                                    x: 'max-content',
                                }}
                                columns={offersColumns}
                                dataSource={discountCodes}
                                pagination={{
                                    total: discountCodes.length,
                                    pageSize: 25,
                                    showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Offers`,
                                    showSizeChanger: false,
                                    position: ['bottomLeft'],
                                }}
                            />
                        )
                    )}
                </Col>
            </Row>
            {openAddDrawer && (
                <AddEditOffer
                    discount={selectedDiscount}
                    open={openAddDrawer}
                    onSave={(discount: Partial<OfferAndDiscount>) => updateDiscount(discount)}
                    onCancel={() => {
                        setOpenAddDrawer(false)
                        selectedDiscount && setSelectedDiscount(null)
                    }}
                />
            )}
            {openRecommendedOfferDrawer && (
                <OneClickOffer
                    offer={config?.custom_config?.one_click_offer || {}}
                    open={openRecommendedOfferDrawer}
                    discounts={
                        discountCodes?.map((item: any) => ({ label: item.discount_code, value: item.discount_code })) ||
                        []
                    }
                    onCancel={() => {
                        setOpenRecommendedOfferDrawer(false)
                    }}
                    onSave={(offer) => {
                        saveConfig({
                            custom_config: {
                                ...config.custom_config,
                                one_click_offer: offer,
                            },
                        })
                    }}
                />
            )}
        </div>
    )
}

export default OffersDiscount
