import * as React from 'react'
import { Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getMerchantDetails, getMode, getUserDetails } from '@store/user/selectors'
import analytics from '@pages/analytics'
import actions from '@pages/actions'
import RTOKwikChatOrders from '@pages/post_order_verification'
import setttings from '@pages/setttings'

const AppRoutes = () => {
    const userDetails = useSelector(getUserDetails)
    const merchantDetails = useSelector(getMerchantDetails)
    const mode = useSelector(getMode)

    return (
        <Routes key={`${mode}-${JSON.stringify(merchantDetails)}`}>
            <Route path='/rto/analytics/*' Component={analytics} />
            <Route path='/rto/actions/*' Component={actions} />
            <Route path='/rto/postOrderVerification/*' Component={RTOKwikChatOrders} />
            <Route path='/rto/kwikflows/*' Component={actions} />
            <Route path='/rto/settings/*' Component={setttings} />
            <Route
                path='/rto/*'
                Component={() => (
                    <div className='m-auto'>
                        <p className='text-center '>404 Not found</p>
                    </div>
                )}
            />
        </Routes>
    )
}

export default AppRoutes
