import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { handleError } from '@library/utilities/helpers/handleError'
import { OfferListing, OfferMerchantActions, OfferStatus, type TagProp } from './constants'
import { message } from 'gokwik-ui-kit'

export function getStatusVariant(status: string): TagProp['variant'] {
    switch (status) {
        case OfferStatus.DRAFT:
            return 'warning'

        case OfferStatus.ACTIVE:
        case OfferStatus.PUBLISHED:
            return 'success'

        case OfferStatus.EXPIRED:
            return 'error'

        default:
            return 'default'
    }
}

const statusText = (status: string) => {
    switch (status) {
        case OfferStatus.DRAFT:
            return 'Offer not yet published'

        case OfferStatus.ACTIVE:
            return 'Offer published and active'

        case OfferStatus.INACTIVE:
            return 'Offer published but inactive'

        case OfferStatus.PUBLISHED:
            return 'Offer published'

        case OfferStatus.EXPIRED:
            return 'Offer expired'

        default:
            return null
    }
}

export function getStatusTooltip(status: string, adminAsMerchant: boolean): string {
    return statusText(status) + (adminAsMerchant ? ' for this merchant' : '');
}

export function getOfferUpdateDisableReason(data:
    { status: OfferStatus, isMerchant: boolean, merchant_id: string, offer: OfferListing }
) {
    if (data.status === OfferStatus.EXPIRED) {
        return 'Cannot update expired offer'
    }
    if (data.isMerchant) {
        if (data.offer?.is_global_offer) {
            return !data.offer.merchant_ids?.includes(data.merchant_id)
                ? 'Not enrolled in this Global Offer' : 'Cannot update Global Offer'
        }
        if (data.offer?.merchant_ids?.length > 1) {
            return 'Cannot update an Offer used by multiple merchants'
        }
    }
    return null
}

export const getOfferListing = async (params, callback: CallableFunction) => {
    try {
        params = Object.keys(params).reduce((prev, curr) => {
            if (![null, undefined, ''].includes(params[curr])) prev[curr] = params[curr]
            return prev
        }, {})
        let response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.offers.getAllOffers,
            params,
        })
        if (response?.data?.data?.documents?.length > 0) {
            callback(response?.data?.data?.documents ?? [], response.data.data.count ?? 0)
        } else {
            callback([], 0)
            !response?.success && handleError(response)
        }
    } catch (error) {
        callback([], 0)
        handleError(error)
    }
}

export const getOfferDetails = async (id: string, callback: CallableFunction, errorHandle?: CallableFunction) => {
    try {
        let response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.offers.getOfferDetails(id),
        })
        if (response?.success) {
            callback(response.data.data)
        } else {
            callback(null)
            errorHandle?.()
            handleError(response)
        }
    } catch (error) {
        callback(null)
        errorHandle?.()
        handleError(error)
    }
}

export const addRemoveMerchants = async (action: OfferMerchantActions, payload, errorCallback) => {
    try {
        const endpoint = APIEndPoints.offers[action]
        let response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_AUX_BASE_URL + endpoint,
            payload,
        })

        if (!response?.success) {
            errorCallback()
            handleError(response)
        } else {
            message.success('Merchant list updated successfully')
        }
    } catch (err) {
        errorCallback()
        handleError(err)
    }
}

export const updateOffer = async (options: {
    id: string,
    payload,
    success?: CallableFunction,
    errorCallback?: CallableFunction,
}) => {
    try {
        let response = await makeAPICall({
            method: 'patch',
            url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.offers.updateOffer(options.id),
            payload: options.payload,
        })

        if (response?.success) {
            options.success?.()
            message.success('Offer updated successfully')
        } else {
            options.errorCallback?.()
            handleError(response)
        }
    } catch (err) {
        options.errorCallback?.()
        handleError(err)
    }
}
