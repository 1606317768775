// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.saveBar {
    background-color: var(--primary-blue-600);
    font-weight: 400;
    align-self: flex-end !important;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: row;
    gap: 6rem;
    border-radius: 10px;
    position: fixed;
    top: 5rem;
    right: 1.75rem;
    z-index: 999;
}
`, "",{"version":3,"sources":["webpack://./src/library/components/save-bar/save-bar.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;IACzC,gBAAgB;IAChB,+BAA+B;IAC/B,oBAAoB;IACpB,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,mBAAmB;IACnB,eAAe;IACf,SAAS;IACT,cAAc;IACd,YAAY;AAChB","sourcesContent":[".saveBar {\n    background-color: var(--primary-blue-600);\n    font-weight: 400;\n    align-self: flex-end !important;\n    padding: 0.5rem 1rem;\n    display: flex;\n    flex-direction: row;\n    gap: 6rem;\n    border-radius: 10px;\n    position: fixed;\n    top: 5rem;\n    right: 1.75rem;\n    z-index: 999;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
