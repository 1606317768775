import { message } from 'gokwik-ui-kit'
import { getIngestionStatus, notifyUpload } from '@store/awb-flows/api'
import { awbFillRateSuccessfullIngestion, invalidUploadFile, requiredHeadings } from './constants'
import { AwbFillRate, FileValidationResult, PollingConfig, RawDataSource, TransformedDataSource } from './interface'
import dayjs from 'dayjs'

export enum FileType {
    CSV = 'csv',
    XLS = 'xls',
    XLSX = 'xlsx',
}

const pollIngestionStatus = async (uploadId: string, config: PollingConfig = {}): Promise<string> => {
    const { maxAttempts = 5, interval = 2000 } = config

    return new Promise((resolve, reject) => {
        let attempts = 0

        const checkStatus = async () => {
            try {
                const response = await getIngestionStatus(uploadId)
                const status = response.data.status

                const statusHandlers = {
                    INGESTING: () => {
                        message.success(awbFillRateSuccessfullIngestion)
                        resolve(status)
                    },
                    SUCCESSFUL: () => {
                        message.success(awbFillRateSuccessfullIngestion)
                        resolve(status)
                    },
                    ERROR: () => {
                        message.error(status)
                        reject(status)
                    },
                    VALIDATION_ERROR: () => {
                        message.error(status)
                        reject(status)
                    },
                    PROCESSING: () => {
                        if (attempts < maxAttempts) {
                            attempts++
                            setTimeout(checkStatus, interval)
                        } else {
                            message.success(awbFillRateSuccessfullIngestion)
                            resolve(status)
                        }
                    },
                }

                ;(statusHandlers[status] || (() => reject('Unknown status')))()
            } catch (err) {
                reject(err)
            }
        }

        checkStatus()
    })
}

const notifyAndPollUpload = async (uploadId: string, merchantId: number) => {
    try {
        const res = await notifyUpload(uploadId, merchantId)
        if (res.status === 201 && res.data) {
            const validRows = res.data.valid_rows
            const totalRows = res.data.total_rows
            message.info(`${validRows} out of ${totalRows} entries successfully processed`)
            const result = await pollIngestionStatus(uploadId)
            return result
        }
    } catch (err) {
        console.error('Error in notify and poll', err)
        throw err
    }
}

const validateFile = (
    file: File,
    maxSize: number = 20 * 1024 * 1024, // 20 MB default
): boolean => {
    if (file.size > maxSize) {
        message.error(`File size exceeds the ${maxSize / (1024 * 1024)} MB limit.`)
        return false
    }
    return true
}

export const createUploadHeaders = (contentType: string = 'text/csv'): Headers => {
    const headers = new Headers()
    headers.append('Content-Type', contentType)
    return headers
}

const getPercentageCategory = (percentValue: number) => {
    if (percentValue === 100) {
        return { color: 'success', text: 'Perfect' }
    } else if (percentValue > 95) {
        return { color: 'default', text: 'Great' }
    } else if (percentValue >= 80 && percentValue <= 95) {
        return { color: 'warning', text: 'Good' }
    } else {
        return { color: 'error', text: 'Low' }
    }
}

const getTagColor = (percentage: string) => {
    const percentValue = parseFloat(percentage)
    return getPercentageCategory(percentValue).color as 'success' | 'default' | 'warning' | 'error'
}

const getTagTextByPercentage = (percentage: string) => {
    const percentValue = parseFloat(percentage)
    return getPercentageCategory(percentValue).text
}


const getFileType = (fileName: string): FileType => {
    const fileExtension = fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2).toLowerCase() as FileType

    return Object.values(FileType).includes(fileExtension) ? fileExtension : FileType.CSV
}


const validateFileHeaders = (headers: string[], requiredHeaders: string[]): FileValidationResult => {
    const normalizedHeaders = headers.map((header) => header.toLowerCase())
    const normalizedRequiredHeaders = requiredHeaders.map((header) => header.toLowerCase())

    const headersMatch = normalizedRequiredHeaders.every((requiredHeader) => normalizedHeaders.includes(requiredHeader))

    if (!headersMatch) {
        return {
            isValid: false,
            error: invalidUploadFile,
            headers: normalizedHeaders,
        }
    }

    return {
        isValid: true,
        headers: normalizedHeaders,
    }
}


const parseFileContents = (file: File, fileType: FileType, XLSX: any): Promise<FileValidationResult> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onload = (e) => {
            try {
                const data = e.target.result
                const workbook = XLSX.read(data, { type: fileType === FileType.CSV ? 'string' : 'binary' })

                const wsname = workbook.SheetNames[0]
                const ws = workbook.Sheets[wsname]

                const parsedData = XLSX.utils.sheet_to_json(ws)

                if (parsedData.length === 0) {
                    reject(new Error('No data found in the file'))
                    return
                }

                const fileHeaders = Object.keys(parsedData[0])
                const validationResult = validateFileHeaders(fileHeaders, requiredHeadings)

                resolve(validationResult)
            } catch (error) {
                reject(error)
            }
        }

        reader.onerror = (error) => {
            reject(error)
        }

        fileType === FileType.CSV ? reader.readAsText(file) : reader.readAsBinaryString(file)
    })
}

const getPreviousMonthAwbFillRate = (data: AwbFillRate[]): number | null => {
    if (!data) return null
    const sortedData = [...data].sort((a, b) => new Date(b.month).getTime() - new Date(a.month).getTime())
    if (sortedData.length < 2) {
        return null
    }

    const previousMonthData = sortedData[1]
    if (previousMonthData.total_merchant_orders > 0) {
        const awbFillPercentage = (previousMonthData.endstate_count / previousMonthData.total_merchant_orders) * 100
        return Number(awbFillPercentage.toFixed(1))
    }
    return null
}

const transformDataSource = (data: RawDataSource[]): TransformedDataSource[] => {
    const sortedData = [...data].sort((a, b) => dayjs(a.month).diff(dayjs(b.month)))

    const currentMonth = dayjs().format('YYYY-MM-01')

    return sortedData.map((item, index) => {
        const awbFillRate = (getAwbFillRate(item)).toFixed(1) + '%'

        const ingestionRequired = determineIngestionRequired(item, currentMonth)

        const ingestionStatus = determineIngestionStatus(item, currentMonth)

        return {
            key: (index + 1).toString(),
            month: dayjs(item.month).format('MMMM YY'),
            awbFillRate,
            ingestionRequired,
            ingestionStatus,
        }
    })
}

const getAwbFillRate = (item: RawDataSource) => {
    return ((item.endstate_count / item.total_merchant_orders) * 100)
}
    
const determineIngestionRequired = (item: RawDataSource, currentMonth: string): string => {
    if (dayjs(item.month).isSame(currentMonth, 'month')) {
        return 'NA'
    }

    const fillRate = getAwbFillRate(item)

    if (fillRate < 80) {
        return 'Yes'
    }

    return 'No'
}

const determineIngestionStatus = (item: RawDataSource, currentMonth: string): string | null => {
    const fillRate = (item.endstate_count / item.total_merchant_orders) * 100

    if (fillRate >= 95) {
        return 'NA'
    }

    return 'Pending'
}


export {
    getTagColor,
    getTagTextByPercentage,
    pollIngestionStatus,
    notifyAndPollUpload,
    validateFile,
    getFileType,
    parseFileContents,
    validateFileHeaders,
    getPreviousMonthAwbFillRate,
    transformDataSource,
}
