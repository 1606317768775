import { downloadToCSV } from '@gokwik/utilities'
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    Button,
    Card,
    CloseSquareOutlined,
    EyeOutlined,
    InfoCircleFilled,
    message,
    MinusOutlined,
    PlusOutlined,
    Popover,
    Tooltip,
} from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { capitalize } from '@library/utilities/helpers/helper'
import { FilterType, rcaCardsData } from '@library/utilities/constants/constants'
import { calculatePercentage, getRangeForLabel } from '@library/utilities/helpers/rca_view_data_filtering'

interface RCACardProps {
    onEnable?: () => void
}

const RcaCards: React.FC<RCACardProps> = ({ onEnable }) => {
    const [hover, setHover] = useState(false)

    return (
        <Card hoverable onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} className='rca-card'>
            <div className='mt-0 p-0'>
                <div className='rca-card-heading flex items-center justify-between'>
                    <span className='w-full m-0 text-sm font-medium flex items-center'>
                        <Tooltip
                            title={`Identify RTO Contribution by Top ${
                                rcaCardsData['filter_type_text'] == capitalize(FilterType.CITY)
                                    ? 'Cities'
                                    : rcaCardsData['filter_type_text']
                            }`}
                        >
                            <InfoCircleFilled className='mr-1' />
                        </Tooltip>
                        Overall RTO Rate
                    </span>
                    <MinusOutlined className='self-start ml-[33px]' />
                </div>
                <div className='rca-card-insights flex !justify-end'>
                    <Tooltip
                        overlayClassName='text-center max-w-[320px] whitespace-normal break-words'
                        title={`In comparision to ${rcaCardsData['compared_date_range']}`}
                    >
                        <div
                            className={`bg-white rounded-[3px] text-[8px] px-[2px] py-[1px] gap-[4px] ${
                                rcaCardsData['1']?.rto_orders_diff > 0 ? 'text-rto-positive' : 'text-rto-negative'
                            }`}
                        >
                            {rcaCardsData['1']?.rto_orders_diff < 0 ? (
                                <span>
                                    <ArrowDownOutlined /> RTO decreased by{' '}
                                    {Math.abs(rcaCardsData['1']?.rto_orders_diff)}%
                                </span>
                            ) : (
                                <span>
                                    <ArrowUpOutlined /> RTO increased by {rcaCardsData['1']?.rto_orders_diff}%
                                </span>
                            )}
                        </div>
                    </Tooltip>
                </div>
                <span className='font-medium text-[11px] mt-[5px]'>Current RTO</span>
                <div className='pt-[4px] gap-[4px] text-[20px] font-medium'>
                    {rcaCardsData['1']?.rto_rate}% | {rcaCardsData['1']?.compared_rto_rate}%
                </div>
            </div>
            <div className='mt-[3px] flex justify-center'>
                <Button onClick={onEnable} type='default' block className='rca-card-button'>
                    <span className='m-0 p-0'>View Insights</span>
                </Button>
            </div>
        </Card>
    )
}

interface RcaChildrenCardsProps {
    data: any
    onEnable: () => void
}

const RcaChildrenCards: React.FC<RcaChildrenCardsProps> = ({ data, onEnable }) => {
    const [hover, setHover] = useState(false)

    if (isNaN(rcaCardsData[data?.label]?.rto_share)) {
        return <div className='w-[99.5px] h-[90px]' />
    }

    //to be added later
    // if (data.label == '23' && rcaCardsData['filter_type'] == FilterType.PRODUCT) {
    //     let products = sortedData.productsData
    //     products = products.map((data) => {
    //         return {
    //             ...data,
    //             rto_rate: calculatePercentage(data?.total_rto_orders, data?.total_orders, 1)
    //         }
    //     })
    //     const filterAndDownloadCSVData = () => {
    //         const finalDataColumns = ['Value', 'RTO Rate']
    //         const dataKeys = ['p_name', 'rto_rate']
    //         downloadToCSV(dataKeys, finalDataColumns, products, 'products', { addSerialNumber: false })
    //         return
    //     }

    //     const handleDownloadCSV = async () => {
    //         filterAndDownloadCSVData()
    //         message.success('CSV Downloaded!')
    //     }

    //     return (
    //         <Card
    //             hoverable
    //             onMouseEnter={() => setHover(true)}
    //             onMouseLeave={() => setHover(false)}
    //             className='rca-children-cards !bg-gradient-to-b !from-rca-light-blue !to-rca-light-blue-transition border-0 !text-rca-black-opacity'
    //         >
    //             <div className='mt-0 p-0'>
    //                 <div className='rca-children-card-subheading'>
    //                     <div className='rca-card-text w-[83px] !whitespace-break-spaces'>
    //                         For all other products details download CSV
    //                     </div>
    //                 </div>
    //                 <div className='mt-[5px] flex justify-center'>
    //                     <Button
    //                         onClick={handleDownloadCSV}
    //                         type='default'
    //                         block
    //                         className='rca-card-button !w-full !bg-none !bg-transparent !text-rca-blue !border-[0.7px] !border-rca-blue !text-[8px] !rounded'
    //                     >
    //                         <span className='m-0 p-0'>{`See Other ${rcaCardsData['filter_type_text']}`}</span>
    //                     </Button>
    //                 </div>
    //             </div>
    //         </Card>
    //     )
    // }

    const handleCardClick = () => {
        const filterType = rcaCardsData['filter_type']
        const isStateOrCity = (filterType === FilterType.STATE || filterType === FilterType.CITY) && data.label !== '8'
        const isDecile = filterType === FilterType.DECILE && data.level === '2'

        if (!data.hide_button && (isStateOrCity || isDecile)) {
            return onEnable()
        }
        return undefined
    }

    const isIconVisible = () => {
        const filterType = rcaCardsData['filter_type']
        const label = parseInt(data.label)

        const isStateOrCity =
            (filterType === FilterType.STATE || filterType === FilterType.CITY) && data.label !== '8' && label < 18
        const isDecile = filterType === FilterType.DECILE && data.level === '2' && label < 18

        return filterType !== FilterType.PINCODE && (isStateOrCity || isDecile)
    }

    const renderIcon = isIconVisible() ? (
        data.selected ? (
            <MinusOutlined className='self-start mt-[2px]' />
        ) : (
            <PlusOutlined className='self-start mt-[2px]' />
        )
    ) : null

    return (
        <Card
            hoverable
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={handleCardClick}
            className={`rca-children-cards ${
                data.selected
                    ? 'border-2 border-rca-light-blue text-white'
                    : '!bg-gradient-to-b !from-rca-light-blue !to-rca-light-blue-transition !text-rca-black-opacity'
            }`}
        >
            <div className='mt-0 p-0'>
                <div className='rca-children-card-heading'>
                    <Tooltip title={`RTO Share`}>
                        <span>{rcaCardsData[data.label]?.rto_share}%</span>
                    </Tooltip>
                    <Tooltip
                        overlayClassName='text-center max-w-[320px] whitespace-normal break-words'
                        title={`RTO Rate in comparison to ${rcaCardsData['compared_date_range']}`}
                    >
                        <div
                            className={`rto-comparison ${
                                rcaCardsData[data?.label]?.rto_rate_diff > 0
                                    ? '!text-rto-positive'
                                    : '!text-rto-negative'
                            }`}
                        >
                            {rcaCardsData[data?.label]?.rto_rate_diff <= 0 ? (
                                <>
                                    <ArrowDownOutlined /> {Math.abs(rcaCardsData[data?.label]?.rto_rate_diff)}%
                                </>
                            ) : (
                                <>
                                    <ArrowUpOutlined /> {rcaCardsData[data?.label]?.rto_rate_diff}%
                                </>
                            )}
                        </div>
                    </Tooltip>
                </div>

                <div className='rca-children-card-subheading'>
                    {(data?.label !== '8' || rcaCardsData['filter_type'] == FilterType.DECILE) && (
                        <Tooltip title={rcaCardsData[data.label]?.filter_data}>
                            <div className='rca-card-text max-w-[50px]'>{rcaCardsData[data.label]?.filter_data}</div>
                        </Tooltip>
                    )}
                    {data?.label === '8' && rcaCardsData['filter_type'] !== FilterType.DECILE && (
                        <div className={`rca-card-text${data.label === '8' ? 'max-w-[80px]' : 'max-w-[50px]'}`}>
                            {`All Other ${
                                rcaCardsData['filter_type_text'] == capitalize(FilterType.CITY)
                                    ? 'Cities'
                                    : rcaCardsData['filter_type_text']
                            }`}
                        </div>
                    )}
                    {renderIcon}
                </div>

                {data.label === '8' && rcaCardsData['filter_type'] !== FilterType.DECILE && (
                    <div className='rca-children-card-insights mt-[2px]'>
                        <div className='text-[8px] bg-rca-black-10 rounded-[2px] px-[2px] py-[1px]'>
                            20+{' '}
                            {rcaCardsData['filter_type_text'] == capitalize(FilterType.CITY)
                                ? 'Cities'
                                : rcaCardsData['filter_type_text']}{' '}
                            Available
                        </div>
                    </div>
                )}

                <div className='rca-children-card-insights'>
                    <div
                        className={`text-[8.5px] ${
                            data.selected ? 'bg-rca-white-80 text-rca-blue' : 'bg-rca-black-6'
                        } rounded-[2px] px-[2px] py-[1px]`}
                    >
                        RTO Rate
                    </div>
                    <div className={`font-semibold text-[9.5px] ${data.selected ? 'text-white' : ''}`}>
                        {rcaCardsData[data?.label]?.rto_rate}%
                    </div>
                </div>

                <div className='rca-children-card-insights min-w-[83.5px]'>
                    <div
                        className={`text-[8.5px] ${
                            data.selected ? 'bg-rca-white-80 text-rca-blue' : 'bg-rca-black-6'
                        } rounded-[2px] px-[2px] py-[0px]`}
                    >
                        COD Share
                    </div>
                    <div className={`font-semibold text-[9.5px] ${data.selected ? 'text-white' : ''}`}>
                        {rcaCardsData[data.label]?.cod_share}%
                    </div>
                </div>

                {data.label === '8' && rcaCardsData['filter_type'] !== FilterType.DECILE && !data?.hide_button && (
                    <div className='mt-[5px] flex justify-center'>
                        <Button
                            onClick={onEnable}
                            type='default'
                            block
                            className='rca-card-button !w-full !bg-none !bg-transparent !text-rca-blue !border-[0.7px] !border-rca-blue !text-[8px] !rounded'
                        >
                            <span className='m-0 p-0'>{`See Other ${
                                rcaCardsData['filter_type_text'] == capitalize(FilterType.CITY)
                                    ? 'Cities'
                                    : rcaCardsData['filter_type_text']
                            }`}</span>
                        </Button>
                    </div>
                )}
            </div>
        </Card>
    )
}

interface RcaLeafCardsProps {
    data?: any
    onEnable?: () => void
}

const RcaLeafCards: React.FC<RcaLeafCardsProps> = ({ data, onEnable }) => {
    const [hover, setHover] = useState(false)
    const [popoverVisible, setPopoverVisible] = useState(false)

    const closePopover = () => setPopoverVisible(false)

    const onClick = () => {
        setPopoverVisible(true)
        onEnable()
    }

    useEffect(() => {
        if (rcaCardsData['curr_clicked_node']?.label == data.label) setPopoverVisible(data.selected)
        else setPopoverVisible(false)
    }, [data.selected])

    const range = getRangeForLabel(data?.label)?.range

    return (
        <Card
            hoverable
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            className={`rca-children-cards ${
                data.selected
                    ? 'border-2 border-rca-light-blue text-white'
                    : '!bg-gradient-to-b !from-rca-light-blue !to-rca-light-blue-transition !text-rca-black-opacity'
            }`}
        >
            <div className='mt-0 p-0 min-w-[83.5px]'>
                <div className='rca-children-card-heading'>
                    <span className='w-full'>{rcaCardsData[data?.label]?.count}</span>
                    <div className='text-[10px] px-[2px] py-[1px] mt-[3px] mb-[3px] rounded'>Pincodes</div>
                </div>
                <div
                    className={`rca-children-card-subheading !font-light !text-[8px] gap-[6.5px] ${
                        data.selected ? 'text-white' : 'text-rca-black-50'
                    }`}
                >
                    <div className='rca-card-text'>RTO Rate between</div>
                </div>

                {rcaCardsData['filter_type'] !== FilterType.PINCODE && <div></div>}

                <div className='rca-children-card-insights gap-[0px]'>
                    <div
                        className={`text-[8px] rounded px-[1px] pt-[1px] ${
                            data.selected ? 'bg-rca-white-80 text-rca-blue' : 'bg-rca-black-6'
                        }`}
                    >
                        {range[0]}%
                    </div>

                    <div className='w-full m-0 h-[5px] flex items-center gap-0 p-0 min-w-[32px]'>
                        {data?.label !== '16' && (
                            <hr
                                className={`m-0 border-0 ${data?.label == '17' ? 'w-[20%]' : 'w-[40%]'}`}
                                style={{
                                    borderTop: data.selected ? '1px solid #EDEDED99' : '1px solid white',
                                }}
                            />
                        )}
                        <div
                            className={`h-[5px] w-[6px] rounded-full ${data.selected ? 'bg-white' : 'bg-rca-blue'}`}
                        ></div>

                        <hr
                            className={`m-0 border-0 ${data?.label == '18' ? 'w-[40%]' : 'w-[20%]'}`}
                            style={{
                                borderTop: data.selected ? '1px solid white' : '1px solid #004B8D',
                            }}
                        />

                        <div
                            className={`h-[5px] w-[6px] rounded-full ${data.selected ? 'bg-white' : 'bg-rca-blue'}`}
                        ></div>

                        {data?.label !== '18' && (
                            <hr
                                className={`m-0 border-0 ${data?.label == '17' ? 'w-[60%]' : 'w-[80%]'}`}
                                style={{
                                    borderTop: data.selected ? '1px solid #EDEDED99' : '1px solid white',
                                }}
                            />
                        )}
                    </div>

                    <div
                        className={`text-[8px] rounded px-[1px] pt-[1px] ${
                            data.selected ? 'bg-rca-white-80 text-rca-blue' : 'bg-rca-black-6'
                        }`}
                    >
                        {range[1]}%
                    </div>
                </div>
            </div>

            {
                <Popover
                    content={<RcaPopoverContent closePopover={closePopover} selectedNode={data?.label} />}
                    title={null}
                    trigger='click'
                    placement='rightBottom'
                    overlayClassName='black-popover'
                    open={popoverVisible}
                >
                    <div className='mt-1.5 flex justify-center'>
                        <Button
                            onClick={onClick}
                            type={'default'}
                            block
                            className={`rca-card-button w-full !text-[8px] rounded ${
                                data.selected
                                    ? '!bg-white border border-white'
                                    : '!bg-none !bg-transparent border border-white text-white'
                            }`}
                        >
                            <span className='m-0 p-0'>{'View Details'}</span>
                        </Button>
                    </div>
                </Popover>
            }
        </Card>
    )
}

interface RcaPopoverContentProps {
    closePopover?: () => void
    selectedNode?: string
}

const RcaPopoverContent: React.FC<RcaPopoverContentProps> = ({ closePopover, selectedNode }) => {
    const filterAndDownloadCSVData = () => {
        const finalDataColumns = ['Value', 'RTO Rate']
        const dataKeys = ['pincode', 'rto_rate']
        downloadToCSV(dataKeys, finalDataColumns, pincodes, 'pincodes', { addSerialNumber: false })
        return
    }

    const handleDownloadCSV = async () => {
        filterAndDownloadCSVData()
        message.success('CSV Downloaded!')
    }

    const range = getRangeForLabel(selectedNode)?.range

    const billingCity = rcaCardsData[rcaCardsData['selected_city']?.label]?.['city'] || ''
    const cityName = billingCity ? capitalize(billingCity) : ''

    let pincodes = getRangeForLabel(selectedNode)?.range_data
    pincodes = pincodes.map((data) => {
        return {
            ...data,
            rto_rate: calculatePercentage(data?.total_rto_orders, data?.total_orders),
        }
    })

    const renderPincodeList = () =>
        pincodes.slice(0, 5).map((pincode, idx) => (
            <div key={idx} className='flex justify-between text-gray-400 ml-2'>
                <span>{`${idx + 1}. ${pincode.pincode}`}</span>
                <span>{`${calculatePercentage(pincode?.total_rto_orders, pincode?.total_orders)}%`}</span>
            </div>
        ))

    return (
        <div className='max-w-xs w-full bg-black text-white p-2.5 rounded-lg border-none'>
            <div>
                <div className='flex justify-between items-center'>
                    <span className='m-0 text-white text-sm font-semibold'>{cityName}</span>
                    <CloseSquareOutlined onClick={closePopover} className='cursor-pointer text-white' />
                </div>
                <span className='text-gray-400'>{pincodes.length} Pincodes</span>
            </div>

            <hr style={{ borderTop: '1px solid #444', margin: '10px 0' }} />

            <div>
                <span className='block text-base font-medium mb-[0px]'>Details</span>
                <div className='flex justify-between text-gray-400'>
                    {rcaCardsData['filter_type'] !== FilterType.PINCODE && (
                        <span className='text-gray-400 mt-1.25'>City:</span>
                    )}
                    <span className='text-gray-400 mt-1.25'>{cityName}</span>
                </div>
                <div className='flex justify-between text-gray-400'>
                    <span className='text-gray-400 mr-2.5'>Date range:</span>
                    <span className='text-gray-400'>
                        {rcaCardsData['selected_date_range'] && (
                            <>
                                {rcaCardsData['selected_date_range'].split(' - ')[0]} -{' '}
                                {rcaCardsData['selected_date_range'].split(' - ')[1]}
                            </>
                        )}
                    </span>
                </div>
                <div className='flex justify-between text-gray-400 gap-2.5'>
                    <span className='text-gray-400'>RTO Rate: </span>
                    <span className='text-gray-400'>
                        {range[0]}% - {range[1]}%
                    </span>
                </div>
            </div>

            {pincodes?.length > 0 && (
                <>
                    <hr style={{ borderTop: '1px solid #444', margin: '10px 0' }} />
                    <div>
                        <span>{cityName} Top RTO Pincodes</span>
                        {renderPincodeList()}
                    </div>

                    <hr style={{ borderTop: '1px solid #444', margin: '10px 0' }} />

                    <div className='flex justify-between gap-2.5'>
                        <Button
                            onClick={handleDownloadCSV}
                            type='default'
                            icon={<EyeOutlined />}
                            className='w-full text-white border border-white bg-transparent rounded-lg'
                        >
                            View Pincodes
                        </Button>
                    </div>
                </>
            )}
        </div>
    )
}

export { RcaCards, RcaChildrenCards, RcaLeafCards }
