import { Input, Switch, Button, Tooltip, QuestionCircleOutlined, Select, DeleteOutlined } from 'gokwik-ui-kit'
import { loginPageTooltips } from './constants'
import { useState, useEffect, useMemo } from 'react'

const bannerOptions = ['banner1', 'banner2', 'banner3']

const splittedTitle = (title: string) => {
    return title?.split('|')
}

function convertToBannerFormat(inputString) {
    const match = inputString.match(/^([a-zA-Z]+)(\d+)$/)
    if (match) {
        const [_, word, number] = match
        return `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()} ${number}`
    }
    return inputString
}

const BannerFunction = ({ updatedConfig, setUpdatedConfig, setIsUpdated, setIsBannerUpdated, type }) => {
    const [bannerTitle, setBannerTitle] = useState<string>('')
    const [bannerType, setBannerType] = useState<string>('')
    const initialBanners = useMemo(() => {
        const banners =
            type === 'paymentUI'
                ? updatedConfig?.textConfig?.bannerText || []
                : updatedConfig?.textConfig?.otherSections?.brandBanner || []
        return [...banners, '', '', ''].slice(0, 3)
    }, [type, updatedConfig])

    const availableBannerOptions = bannerOptions.filter((_, index) => initialBanners[index] === '')

    const handleAddBanner = () => {
        if (bannerTitle?.trim()) {
            const newBanner = bannerTitle.trim()
            const position = initialBanners.indexOf('')
            initialBanners[position] = newBanner
            if (type === 'paymentUI') {
                setUpdatedConfig((prev) => ({
                    ...prev,
                    textConfig: {
                        ...prev?.textConfig,
                        bannerText: [...initialBanners],
                    },
                }))
            } else {
                setUpdatedConfig((prev) => ({
                    ...prev,
                    textConfig: {
                        ...prev?.textConfig,
                        otherSections: {
                            ...prev?.textConfig?.otherSections,
                            brandBanner: [...initialBanners],
                        },
                    },
                }))
                setIsBannerUpdated(true)
            }
            setBannerTitle('')
            setIsUpdated(true)
        }
    }

    function getRemainingBannerOptions(bannerOptions, availableBannerOptions) {
        return bannerOptions.filter((banner) => !availableBannerOptions.includes(banner))
    }

    const handleDeleteBanner = (index: number) => {
        initialBanners[index] = ''
        if (type === 'paymentUI') {
            setUpdatedConfig((prev) => ({
                ...prev,
                textConfig: {
                    ...prev?.textConfig,
                    bannerText: [...initialBanners],
                },
            }))
        } else {
            setUpdatedConfig((prev) => ({
                ...prev,
                textConfig: {
                    ...prev?.textConfig,
                    otherSections: {
                        ...prev.textConfig?.otherSections,
                        brandBanner: [...initialBanners],
                    },
                },
            }))
        }
        setIsUpdated(true)
    }

    useEffect(() => {
        if (availableBannerOptions?.length > 0) {
            setBannerType(availableBannerOptions[0])
        }
    }, [availableBannerOptions])
    return (
        <div className='mb-6 bg-white p-4 rounded-lg shadow-md mx-auto'>
            <div className='flex items-center justify-between mb-2'>
                <p className='mb-2 text-base font-semibold'>
                    Banner Configuration{' '}
                    <Tooltip title={<>{loginPageTooltips.CtaButton}</>}>
                        <QuestionCircleOutlined className='opacity-40' />
                    </Tooltip>
                </p>

                <div className='flex gap-2'>
                    <Switch
                        checked={
                            type === 'paymentUI'
                                ? updatedConfig?.textConfig?.showBrandBanner
                                : updatedConfig?.uiConfig?.showBrandBanner
                        }
                        onChange={(checked) => {
                            setUpdatedConfig((prev) => {
                                const keyToUpdate = type === 'paymentUI' ? 'textConfig' : 'uiConfig'
                                return {
                                    ...prev,
                                    [keyToUpdate]: {
                                        ...prev[keyToUpdate],
                                        showBrandBanner: checked,
                                    },
                                }
                            })
                            setIsUpdated(true)
                        }}
                    />
                </div>
            </div>
            <div className='flex gap-4'>
                <div className='flex flex-col'>
                    <div>
                        <label className='block mb-2 text-sm font-medium text-gray-700'>Banner Type</label>
                        <Select
                            placeholder='Select type'
                            className='w-full mb-2'
                            value={convertToBannerFormat(bannerType)}
                            options={availableBannerOptions.map((option) => ({ label: option, value: option }))}
                            optionRender={(option) => convertToBannerFormat(option.label)}
                            onChange={(value) => setBannerType(value)}
                            disabled={availableBannerOptions?.length === 0}
                        />
                    </div>
                    {getRemainingBannerOptions(bannerOptions, availableBannerOptions)?.map((matchedBanner, index) => (
                        <div className='font-normal px-2' key={index}>
                            {matchedBanner.length && convertToBannerFormat(matchedBanner)}
                        </div>
                    ))}
                </div>

                <div className='w-full'>
                    <div className='flex flex-col'>
                        <div>
                            <label className='block mb-2 text-sm font-medium text-gray-700'>Banner Title</label>
                            <div className='w-full flex justify-between'>
                                <div className='w-full mr-2'>
                                    <Input
                                        maxLength={40}
                                        showCount
                                        type='text'
                                        value={bannerTitle}
                                        onChange={(e) => setBannerTitle(e.target.value)}
                                        placeholder='Enter Banner Title'
                                        className='border mb-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400'
                                    />
                                </div>
                                <Button
                                    onClick={handleAddBanner}
                                    size='small'
                                    variant='primary'
                                    className='text-white rounded-md'
                                    disabled={!availableBannerOptions?.length}
                                >
                                    Add
                                </Button>
                            </div>
                        </div>
                        {initialBanners?.map((matchedBanner, index) => (
                            <div className='flex justify-between w-full pr-12' key={index}>
                                {matchedBanner?.length > 0 && (
                                    <>
                                        <div className='font-normal pl-2'>
                                            {matchedBanner.includes('|')
                                                ? splittedTitle(matchedBanner)[1]
                                                : matchedBanner}
                                        </div>
                                        <DeleteOutlined
                                            className='text-error-500 cursor-pointer'
                                            onClick={() => handleDeleteBanner(initialBanners?.indexOf(matchedBanner))}
                                        />
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BannerFunction
