import { filterDateFormatter, makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { Button, Col, Row, Table, Tag, Modal, message, Tabs, Tooltip } from 'gokwik-ui-kit'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
    SolutionOutlined,
    UserOutlined,
    EnvironmentFilled,
    GlobalOutlined,
    PhoneOutlined,
    MailOutlined,
    StopOutlined,
    ExclamationCircleOutlined,
    EyeOutlined,
} from '@ant-design/icons'
import { isMerchantSelector } from '@store/user/selectors'
import { customerBlockingDetailsColumns, customerOrdersDetailsColumns } from './data'
import dayjs from 'dayjs'

const today = dayjs()
const formattedDate = filterDateFormatter(today)

const CustomerDetails = () => {
    const { id } = useParams()
    const [customerDetailsData, setCustomerDetailsData] = useState(null)
    const [ordersData, setOrdersData] = useState([])
    const [total, setTotal] = useState(0)
    const [parameters, setParameters] = useState<any>({
        page: 1,
        pageSize: 25,
        sortKey: 'created_at',
        sortOrder: -1,
        start_dt: formattedDate,
        end_dt: formattedDate,
    })
    const [modalOpen, setModalOpen] = useState<any>(false)
    const [customerPhoneNumber, setCustomerPhoneNumber] = useState(null)
    const [customerEmail, setCustomerEmail] = useState(null)
    const [showViewPhoneBtn, setShowViewPhoneBtn] = useState(true)
    const [showViewEmailBtn, setShowViewEmailBtn] = useState(true)

    const fetchCustomerDetails = useCallback(
        async (parameters) => {
            const response = await makeAPICall({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.getCustomerDetails}/${id}`,
                params: {
                    ...parameters,
                    start_dt: parameters?.start_dt,
                    end_dt: parameters?.end_dt,
                },
            })

            if (
                response?.data?.status_code === 200 &&
                response?.data?.data?.customer_details &&
                response?.data?.data?.orders_details
            ) {
                setCustomerDetailsData(response?.data?.data?.customer_details)
                setCustomerPhoneNumber(response?.data?.data?.customer_details?.phone_mask)
                setCustomerEmail(response?.data?.data?.customer_details?.email_mask)
                setOrdersData(response?.data?.data?.orders_details?.orders)
                setTotal(response?.data?.data?.orders_details?.orders_count || 0)
            } else {
                console.warn('Invalid Response Data:', response?.data)
                setCustomerDetailsData(null)
                setTotal(0)
            }
        },
        [id],
    )

    useEffect(() => {
        fetchCustomerDetails(parameters)
    }, [id, parameters, fetchCustomerDetails])

    const customerInformation = useMemo(
        () => [
            { icon: SolutionOutlined, value: customerDetailsData?.id, label: 'ID' },
            {
                icon: UserOutlined,
                value: customerDetailsData?.firstname + ' ' + customerDetailsData?.lastname,
                label: 'Name',
            },
            {
                icon: PhoneOutlined,
                value: customerPhoneNumber,
                label: 'Phone',
            },
            { icon: MailOutlined, value: customerEmail, label: 'Email Address' },
            { icon: EnvironmentFilled, value: customerDetailsData?.address1, label: 'Address 1' },
            { icon: EnvironmentFilled, value: customerDetailsData?.address2, label: 'Address 2' },
            { icon: EnvironmentFilled, value: customerDetailsData?.city, label: 'City' },
            { icon: GlobalOutlined, value: customerDetailsData?.country, label: 'Country' },
            {
                icon: StopOutlined,
                value: customerDetailsData?.is_customer_blocked ? 'Yes' : 'No',
                label: 'Customer Blocked',
            },
        ],
        [customerDetailsData, customerPhoneNumber, customerEmail],
    )

    const isMerchantUser = useSelector(isMerchantSelector)

    useEffect(() => {
        updateBreadcrumbs((prev) =>
            prev[prev?.length - 1]?.text?.includes(String(id))
                ? prev
                : [
                      prev[0],

                      {
                          key: 'customers',
                          href: `/${isMerchantUser ? 'admin' : 'access-control'}/customers`,
                          text: 'Customers',
                      },
                      {
                          key: 'customer-details',
                          href: `/${isMerchantUser ? 'admin' : 'access-control'}/customers/${id}`,
                          text: String(id),
                      },
                  ],
        )
    }, [])

    const handlePaginationChange: any = (current: number, pageSize?: number) => {
        setParameters((prev) => ({ ...prev, page: current, pageSize }))
        fetchCustomerDetails({ ...parameters, page: current, pageSize })
    }

    async function customerBlock(customerCurrentStatus, customerId) {
        if (!customerId) return

        const params = { customerId: customerId, is_blocked: !customerCurrentStatus }

        try {
            // let response = dummyOrders?.data;
            let response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + '/api/customer/block',
                params,
            })

            message.success(response?.response?.data?.message)

            console.log(response, 'test')
        } catch (err) {
            console.warn(err)
        }
    }

    const handleShowInfo = async (type) => {
        const response = await makeAPICall({
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.getSensetiveInfo}`,
            params: {
                id: customerDetailsData?.id,
                type,
            },
        })

        if (response?.data?.data?.phone?.length > 0) {
            setCustomerPhoneNumber(response?.data?.data?.phone)
            setShowViewPhoneBtn(false)
        }

        if (response?.data?.data?.email?.length > 0) {
            setCustomerEmail(response?.data?.data?.email)
            setShowViewEmailBtn(false)
        }
    }

    return (
        <>
            <Modal
                title={
                    <p>
                        Are your sure, you want to{' '}
                        {customerDetailsData?.is_customer_blocked ? (
                            <span className='text-green-500'>unblock</span>
                        ) : (
                            <span className='text-red-500'>block</span>
                        )}{' '}
                        this customer ?
                    </p>
                }
                open={modalOpen ? true : false}
                onOk={() => {
                    customerBlock(customerDetailsData?.is_customer_blocked, customerDetailsData?.id)
                    setModalOpen(false)
                }}
                onCancel={() => {
                    setModalOpen(false)
                }}
                centered
                width={'450px'}
                footer={() => (
                    <Row className='flex justify-end gap-2'>
                        <Button onClick={() => setModalOpen(null)} variant='default'>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                customerBlock(customerDetailsData?.is_customer_blocked, customerDetailsData?.id)
                                setModalOpen(null)
                            }}
                            variant='primary'
                        >
                            Confirm
                        </Button>
                    </Row>
                )}
            >
                <h3 className='mt-1'>Customer #{customerDetailsData?.id}</h3>
            </Modal>
            <div className='w-full inter'>
                <Row className='p-2 rounded bg-neutrals-15' align={'middle'} justify={'space-between'}>
                    <Col sm={12} className='flex items-center gap-x-2'>
                        <span className='text-black text-base inter font-semibold m-0 tracking-wide capitalize'>
                            {customerDetailsData?.firstname + ' ' + customerDetailsData?.lastname}
                        </span>
                        <Tag
                            variant={customerDetailsData?.is_customer_blocked ? 'error' : 'success'}
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        >
                            {customerDetailsData?.is_customer_blocked ? 'Blocked' : 'Active'}
                        </Tag>
                    </Col>
                </Row>

                <Row className='mt-5 w-full bg-neutrals-15' justify={'space-between'}>
                    <Col className='bg-neutrals-15 p-2' sm={10}>
                        <Row>
                            <span className='text-black text-base inter font-semibold m-0'>Details</span>
                        </Row>

                        <Row className='flex flex-col gap-3 justify-start align-top mt-5 max-h-[100px] w-[85%]'>
                            {customerInformation.map((itm) => (
                                <div className='flex items-center w-full text-sm inter font-normal' key={itm.label}>
                                    <div className='flex items-center w-full '>
                                        <div className='text-gray-600'>
                                            <itm.icon className='mr-2' width={20} height={20} />
                                            <span className='text-gray-600 font-medium mr-2 capitalize'>
                                                {itm.label}:
                                            </span>
                                        </div>
                                        <Tooltip placement='topLeft' title={itm.value ? itm.value : ''}>
                                            <span className='text-black font-medium max-w-[50%] truncate'>
                                                {itm.value ? itm.value : ''}
                                            </span>
                                        </Tooltip>
                                        {itm?.label?.toLowerCase() === 'phone' && showViewPhoneBtn && (
                                            <Tooltip title={'View Complete Phone Number'}>
                                                <a
                                                    className='cursor-pointer text-xs ml-2 font-semibold'
                                                    onClick={async () => await handleShowInfo('phone')}
                                                >
                                                    View
                                                </a>
                                            </Tooltip>
                                        )}
                                        {itm?.label?.toLowerCase() === 'email address' && showViewEmailBtn && (
                                            <Tooltip title={'View Complete Email Address'}>
                                                <a
                                                    className='cursor-pointer text-xs ml-2 font-semibold'
                                                    onClick={async () => await handleShowInfo('email')}
                                                >
                                                    View
                                                </a>
                                            </Tooltip>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </Row>
                    </Col>
                </Row>

                {isMerchantUser && customerDetailsData?.is_customer_blocked && (
                    <Row className='p-2 rounded bg-neutrals-15 mt-4' align={'middle'} justify={'space-between'}>
                        <Col sm={12} className='flex items-center gap-x-2'>
                            <span className='text-black text-base inter font-semibold m-0 tracking-wide'>
                                Blocked Payment Method:{' '}
                            </span>
                            <Tag>
                                {customerDetailsData?.merchant_blocked?.[0]?.type?.length > 0
                                    ? customerDetailsData?.merchant_blocked?.[0]?.type?.toUpperCase()
                                    : 'ALL'}
                            </Tag>
                            {}
                        </Col>
                    </Row>
                )}

                {!isMerchantUser && customerDetailsData?.is_customer_blocked && (
                    <Row className='mt-5 w-full bg-neutrals-15 p-2' justify={'space-between'}>
                        <Row>
                            <span className='text-black text-base inter font-semibold m-0'>Blocking details</span>
                        </Row>

                        <Row className='flex flex-col gap-3 justify-start align-top mt-1 w-full'>
                            <div className=' bg-white rounded overflow-clip mt-5'>
                                <Table
                                    columns={customerBlockingDetailsColumns}
                                    dataSource={
                                        customerDetailsData?.merchant_blocked || customerDetailsData?.platform_blocked
                                            ? [
                                                  ...customerDetailsData?.merchant_blocked,
                                                  ...customerDetailsData?.platform_blocked,
                                              ]
                                            : []
                                    }
                                />
                            </div>
                        </Row>
                    </Row>
                )}

                <div className=' bg-white rounded overflow-clip mt-5'>
                    {total > 0 ? (
                        <Table
                            columns={customerOrdersDetailsColumns}
                            scroll={{ x: 800, y: 300 }}
                            pagination={{
                                current: parameters.page,
                                pageSize: parameters.pageSize,
                                total: total,
                                showSizeChanger: false,
                                onChange: handlePaginationChange,
                                position: ['topLeft', 'bottomLeft'],
                                showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
                            }}
                            dataSource={ordersData || []}
                        />
                    ) : (
                        <Row className='bg-white rounded mt-5'>
                            <Col sm={24}>
                                <div className='flex flex-col items-center justify-center min-h-[300px]'>
                                    <ExclamationCircleOutlined className='text-gray-500 text-2xl' />
                                    <span className='text-gray-500 text-base inter font-semibold mt-1'>
                                        No Orders Found
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    )}
                </div>
            </div>
        </>
    )
}

export default CustomerDetails
