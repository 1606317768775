import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { useAppDispatch } from '@library/utilities/hooks'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { fetchUserDetails } from '@store/user'
import { getMerchantDetails, getUserData } from '@store/user/selectors'
import { Row, Col, Button, message } from 'gokwik-ui-kit'
import { useSelector } from 'react-redux'
import { navigateToUrl } from 'single-spa'

export const ConfigurePublish = () => {
    const merchant_details = useSelector(getMerchantDetails)
    const userDetails = useSelector(getUserData)

    const dispatch = useAppDispatch()

    const completeOnboarding = async () => {
        const response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.updateMerchantOnboardingStatus + merchant_details.m_id,
            payload: {
                kp_onboarding_status: 'setup_completed',
            },
        })

        if (response?.data?.status_code === 200) {
            dispatch(fetchUserDetails())
            navigateToUrl('/kwikpass/configuration')
            // navigateToUrl('/kwikpass/analytics')
            logEvent(
                'kp_live',
                'click',
                'final_screen',
                userDetails?.userDetails?.email,
                merchant_details?.m_id,
                merchant_details?.short_name,
            )
        } else {
            message.error('Something went wrong. Please retry ! ')
        }
    }
    return (
        <div className='mt-6 mx-12 bg-white p-4 rounded'>
            <Row gutter={32}>
                <Col span={12}>
                    <div className='flex flex-col gap-6'>
                        <div className=' rounded w-full '>
                            <video key={1} width='100%' autoPlay controls={true} loop>
                                <source src={'https://assets.gokwik.co/videos/NV_KP2.mp4'} type='video/mp4' />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </Col>
                <Col span={12}>
                    {' '}
                    <div className='bg-white p-4 rounded-sm flex flex-col gap-12'>
                        <div className='flex flex-col gap-4'>
                            <div className='text-xl font-semibold'>Congratulations!</div>
                            <p>
                                Now that the changes have been published, let’s check that they are visible on the live
                                theme and functional
                            </p>
                            <ul className='list-none p-0'>
                                <li className='p-2'>
                                    <span className='font-semibold'>Step 1:</span> Go to the theme where you added the
                                    code
                                </li>
                                <li className='p-2'>
                                    <span className='font-semibold'>Step 2:</span> Publish this theme to make it live,
                                    if not already live
                                </li>
                                <li className='p-2'>
                                    <span className='font-semibold'>Step 3:</span> Check your website to ensure the
                                    flows are working fine - refer to the adjoining video on how to test
                                </li>
                            </ul>
                        </div>

                        <div className='flex gap-2 justify-start'>
                            <Button variant={'default'} onClick={completeOnboarding}>
                                Go back to configuration
                            </Button>

                            {/* <Button className='w-1/3' variant='primary' onClick={completeOnboarding}>
                                Track Analytics
                            </Button> */}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
