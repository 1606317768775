import {
    Button,
    ColumnProps,
    DeleteOutlined,
    Drawer,
    Form,
    PlusCircleOutlined,
    Select,
    Space,
    Table,
    Tag,
    Tooltip,
} from 'gokwik-ui-kit'
import { useSelector } from 'react-redux'
import { getMerchantDetails, isMerchantSelector } from '@store/user/selectors'

type PlatformBlockedItem = {
    platform: string
    type?: string
}

type MerchantBlockedItem = {
    mid: string
    type?: string
}

export default function BlockCustomerDrawer({
    modalOpen,
    setModalOpen,
    blockData,
    setBlockData,
    merchantList,
    customer,
    handleSubmit,
    handleUnblockAll,
    blockDataInfo,
    setBlockDataInfo,
    blockedPayment,
    setBlockedPayment,
}) {
    const resetFormData = () => {
        setBlockData({ on: '', name: '', type: '', m_id: '' })
        setBlockedPayment('')
        form.resetFields()
    }

    const isMerchantUser = useSelector(isMerchantSelector)
    const merchantDetails = useSelector(getMerchantDetails)

    function getPayload(input, blockedPaymentMode) {
        if (isMerchantUser) {
            const merchantBlocked: MerchantBlockedItem[] = []
            const item: MerchantBlockedItem = { mid: merchantDetails?.m_id }
            if (blockedPaymentMode?.toLowerCase() !== 'all') {
                item.type = blockedPaymentMode
            }

            merchantBlocked.push(item)

            return {
                platform_blocked: [],
                merchant_blocked: merchantBlocked,
            }
        } else {
            const platformBlocked: PlatformBlockedItem[] = []
            const merchantBlocked: MerchantBlockedItem[] = []

            input.forEach((item) => {
                if (item.on === 'platform') {
                    const platformItem: PlatformBlockedItem = { platform: item.name }
                    if (item.type && item.type !== 'all') {
                        platformItem.type = item.type
                    }
                    platformBlocked.push(platformItem)
                } else if (item.on === 'merchant') {
                    const merchantItem: MerchantBlockedItem = { mid: item.m_id! }
                    if (item.type && item.type !== 'all') {
                        merchantItem.type = item.type
                    }
                    merchantBlocked.push(merchantItem)
                }
            })

            return {
                platform_blocked: platformBlocked,
                merchant_blocked: merchantBlocked,
            }
        }
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo)
    }

    const removeEntry = (index) => {
        setBlockDataInfo((prevList) => {
            let tempList = [...prevList]

            if (tempList[index]) {
                tempList.splice(index, 1)
            }

            return tempList
        })
    }

    const addEntry = () => {
        const { on, name, type, m_id } = blockData

        if (name?.length < 1 || on?.length < 1 || type?.length < 1) {
            return
        }

        setBlockDataInfo((prev) => {
            const updated = [...prev]
            const index = updated?.findIndex(
                (entry) => (m_id && entry?.m_id === m_id) || (!m_id && entry?.name === name),
            )

            if (index !== -1) {
                updated[index] = { ...updated[index], type }
            } else {
                updated.push({ ...blockData })
            }

            return updated
        })

        form.resetFields()
        resetFormData()
    }

    const platformList = [
        { value: 'shopify', label: 'Shopify' },
        { value: 'magento', label: 'Magento' },
        { value: 'wooCommerce', label: 'WooCommerce' },
        { value: 'custom', label: 'Custom' },
    ]
    const [form] = Form.useForm()
    const columns: ColumnProps<any>[] = [
        {
            title: 'Merchant/ Platform',
            dataIndex: 'name',
            render: (text) => (
                <Tooltip title={text} color='#fff' overlayInnerStyle={{ color: '#000' }}>
                    <span className='truncate itm.name-ellipsis max-w-28 overflow-hidden whitespace-nowrap inline-block'>
                        {text}
                    </span>
                </Tooltip>
            ),
        },
        {
            title: 'Method',
            dataIndex: 'type',
            render: (text) => <span>{text?.toUpperCase()}</span>,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (_, record, index) => (
                <Tooltip title={'Delete Variant'} color='#fff' overlayInnerStyle={{ color: '#000' }}>
                    <DeleteOutlined className='cursor-pointer' onClick={() => removeEntry(index)} />
                </Tooltip>
            ),
        },
    ]

    if (blockedPayment && isMerchantUser) {
        form.setFieldValue('merchant_payment_method', blockedPayment)
    }

    return (
        <Drawer
            title={'Block/ Unblock Customer'}
            placement='right'
            onClose={() => {
                setModalOpen(false)
                resetFormData()
                setBlockDataInfo([])
            }}
            open={modalOpen}
            width={500}
        >
            <Space direction='vertical' className='w-full'>
                <div className='flex flex-col bg-slate-100 p-3 rounded-lg'>
                    <div className='grid grid-cols-2 gap-4 '>
                        <div className='flex items-center'>
                            <span className='text-gray-600 font-medium mr-2'>Name:</span>
                            <Tooltip placement='topLeft' title={customer?.firstname + ' ' + customer?.lastname}>
                                <span className='text-black font-medium max-w-[50%] truncate'>
                                    {customer?.firstname + ' ' + customer?.lastname}
                                </span>
                            </Tooltip>
                        </div>

                        <div className='flex items-center'>
                            <span className='text-gray-600 font-medium mr-2'>Email:</span>
                            <Tooltip placement='topLeft' title={customer?.email}>
                                <span className='text-black font-medium max-w-[90%] truncate'>{customer?.email}</span>
                            </Tooltip>
                        </div>

                        <div className='flex items-center w-full'>
                            <span className='text-gray-600 font-medium mr-2'>Phone:</span>
                            <span className='text-black font-medium max-w-[50%] truncate'>{customer?.phone}</span>
                        </div>

                        <div className='flex items-center'>
                            <span className='text-gray-600 font-medium mr-2'>Status:</span>
                            <span className='text-black font-medium max-w-[50%] truncate'>
                                <Tag
                                    variant={customer?.is_customer_blocked ? 'error' : 'success'}
                                    className='!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs'
                                >
                                    {customer?.is_customer_blocked ? 'Blocked' : 'Active'}
                                </Tag>
                            </span>
                        </div>
                    </div>
                    {isMerchantUser && customer?.is_customer_blocked && (
                        <div className='flex items-center mt-4'>
                            <span className='text-gray-600 font-medium mr-2'>Blocked Payment Method:</span>
                            <span className='text-black font-medium max-w-[50%] truncate'>
                                {blockedPayment?.toUpperCase()}
                            </span>
                        </div>
                    )}
                </div>

                {isMerchantUser ? (
                    <Form layout='vertical' className='h-full w-full' onFinishFailed={onFinishFailed} form={form}>
                        <Form.Item
                            label={'Payment Method:'}
                            name={'merchant_payment_method'}
                            className='mb-2 mt-5'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select payment method',
                                },
                            ]}
                        >
                            <Select
                                className='w-full text-left'
                                placeholder='Select an option'
                                value={blockedPayment}
                                options={[
                                    { value: 'cod', label: 'COD' },
                                    { value: 'upi', label: 'UPI' },
                                    { value: 'all', label: 'All' },
                                ]}
                                onChange={(value) => {
                                    setBlockedPayment(value)
                                }}
                            />
                        </Form.Item>
                    </Form>
                ) : (
                    <Form layout='vertical' className='h-full w-full' onFinishFailed={onFinishFailed} form={form}>
                        <Form.Item
                            label={'Block Type:'}
                            name={'block_type'}
                            className='mb-2 mt-5'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select block type',
                                },
                            ]}
                        >
                            <Select
                                className='w-full text-left'
                                placeholder='Select an option'
                                value={blockData?.on?.length > 0 ? blockData.on : 'merchant'}
                                options={[
                                    { value: 'merchant', label: 'Merchant' },
                                    { value: 'platform', label: 'Platform' },
                                ]}
                                onChange={(value) => {
                                    setBlockData((prev) => ({
                                        ...prev,
                                        on: value,
                                    }))
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            label={'Merchants'}
                            name={'merchants'}
                            className='mb-2'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select a merchant',
                                },
                            ]}
                        >
                            <Select
                                className={'w-full'}
                                showSearch={true}
                                value={blockData?.name}
                                placeholder='Select a merchant'
                                onChange={(value, option) => {
                                    const selectedOption = option as any
                                    setBlockData((prev) => ({
                                        ...prev,
                                        name: selectedOption?.name,
                                        m_id: value,
                                    }))
                                }}
                                filterOption={(input, option) => {
                                    return (option?.name as string)?.toLowerCase().includes(input.toLowerCase())
                                }}
                                allowClear
                            >
                                {(form.getFieldValue('block_type') === 'merchant' ? merchantList : platformList)?.map(
                                    (item) => (
                                        <Select.Option
                                            className={'flex gap-x-2 items-center'}
                                            key={item.id || item.value}
                                            value={item.m_id || item.value}
                                            name={item.short_name || item.label}
                                        >
                                            <div className='flex items-center'>
                                                <span className='text-gray-900 inter font-medium capitalize truncate'>
                                                    {item.short_name || item.label}
                                                </span>
                                            </div>
                                        </Select.Option>
                                    ),
                                )}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={'Payment Method:'}
                            name={'payment_method'}
                            className='mb-2 mt-5'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select payment method',
                                },
                            ]}
                        >
                            <Select
                                className='w-full text-left'
                                placeholder='Select an option'
                                value={blockData?.type}
                                options={[
                                    { value: 'cod', label: 'COD' },
                                    { value: 'upi', label: 'UPI' },
                                    { value: 'all', label: 'All' },
                                ]}
                                onChange={(value) => {
                                    setBlockData((prev) => ({
                                        ...prev,
                                        type: value,
                                    }))
                                }}
                            />
                        </Form.Item>
                        <div className='flex justify-end'>
                            <Button
                                onClick={async () => {
                                    try {
                                        await form.validateFields()
                                        addEntry()
                                    } catch (error) {
                                        console.log('Validation failed:', error)
                                    }
                                }}
                                variant='primary'
                            >
                                <PlusCircleOutlined />
                                Add
                            </Button>
                        </div>
                    </Form>
                )}

                {blockDataInfo.length > 0 && (
                    <>
                        <Table
                            columns={columns}
                            dataSource={blockDataInfo || []}
                            pagination={false}
                            bordered
                            className='mt-4'
                        />
                    </>
                )}
                <div className='flex gap-2 w-full justify-start'>
                    {((!isMerchantUser && blockDataInfo?.length > 0) || (isMerchantUser && blockedPayment)) && (
                        <Button
                            onClick={async () => {
                                await handleSubmit(customer?.id, getPayload(blockDataInfo, blockedPayment))
                                resetFormData()
                            }}
                            variant='primary'
                            className='mt-5 w-full'
                        >
                            {isMerchantUser && !customer?.is_customer_blocked ? 'Block' : 'Submit'}
                        </Button>
                    )}
                    {customer?.is_customer_blocked && (
                        <Button
                            onClick={async () => {
                                await handleUnblockAll(customer?.id)
                                setModalOpen(false)
                                resetFormData()
                            }}
                            variant='primary'
                            className='mt-5 w-full'
                        >
                            Unblock All
                        </Button>
                    )}
                </div>
            </Space>
        </Drawer>
    )
}
