import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { convertIsoToLocaleString, filterDateFormatter, makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'
import {
    Table,
    Row,
    Col,
    Button,
    Tag,
    Modal,
    Tooltip,
    ColumnProps,
    message,
    Upload,
    InboxOutlined,
    DownloadOutlined,
    InfoCircleOutlined,
    PlusCircleOutlined,
    Drawer,
    Space,
    EditOutlined,
    CheckCircleFilled,
    CloseCircleOutlined,
    Spin,
} from 'gokwik-ui-kit'
import { previousCustomerFilters } from '@store/filters/selectors'
import { saveFiltersData } from '@store/filters'
import RenderSearchFilters from '@library/components/search-filter'
import { getMerchantDetails, isMerchantSelector } from '@store/user/selectors'
const { Dragger } = Upload
const today = dayjs()
const formattedDate = filterDateFormatter(today)
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { LABELS, ICONS } from './constants'
import { Link } from 'react-router-dom'
import BlockCustomerDrawer from './blockCustomerDrawer'

export default function () {
    const [total, setTotal] = useState(0)
    const [customerData, setCustomerData] = useState([])
    const prevFilters = useSelector(previousCustomerFilters)
    const [openDrawer, setOpenDrawer] = useState(false)
    const isMerchantUser = useSelector(isMerchantSelector)
    const [merchantList, setMerchantList] = useState([])
    const [parameters, setParameters] = useState<any>({
        page: 1,
        sortKey: 'created_at',
        sortOrder: -1,
        pageSize: 25,
        ...prevFilters,
    })
    const [modalOpen, setModalOpen] = useState(false)
    const [isDrawerOpen, setDrawerOpen] = useState(false)
    const [uploadedCsv, setUploadedCsv] = useState([])
    const [uploadedFile, setUploadedFile] = useState()
    const [CustomerDetails, setCustomerDetails] = useState([])
    const [blockDataInfo, setBlockDataInfo] = useState([])
    const [blockedPayment, setBlockedPayment] = useState('')
    const [blockData, setBlockData] = useState({ on: '', name: '', type: '', m_id: '' })
    const [selectedCustomer, setSelectedCustomer] = useState(null)
    const [uploadFailed, setUploadFailed] = useState(true)
    const [uploadSuccess, setUploadSuccess] = useState(false)
    const [previewCsv, setPreviewCsv] = useState(false)
    const [parcedCsv, setParcedCsv] = useState([])
    const [failedEntries, setFailedEntries] = useState(0)
    const [entries, setEntries] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const disptach = useDispatch()

    function titleCase(str) {
        var splitStr = str.toLowerCase().split(' ')
        for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
        }
        // Directly return the joined string
        return splitStr.join(' ')
    }

    const columns: ColumnProps<any>[] = [
        {
            title: <span>Customer Id</span>,
            dataIndex: 'id',
            width: 20,
            render: (text: string, record: any) => {
                return (
                    <div className='flex gap-3 items-baseline text-base'>
                        <Tooltip title={text}>
                            <Link to={`/${isMerchantUser ? 'admin' : 'access-control'}/customers/${record?.id}`}>
                                {text}
                            </Link>
                        </Tooltip>
                    </div>
                )
            },
        },
        {
            title: <span>Full Name</span>,
            dataIndex: 'firstnameAndLastname',
            width: 30,
            ellipsis: true,
            render: (text, record) => <span>{`${titleCase(record.firstname)} ${titleCase(record.lastname)}`}</span>,
        },
        {
            title: <span>Phone</span>,
            dataIndex: 'masked_phone',
            width: 20,
            render: (text: string, record) => <span>{record?.masked_phone || record?.phone_mask}</span>,
        },
        {
            title: <span>Customer Status</span>,
            dataIndex: 'is_customer_blocked',
            width: 30,
            render: (status: string) => (
                <span>
                    <Tag
                        variant={status ? 'error' : 'success'}
                        className='!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs'
                    >
                        {status ? 'Blocked' : 'Active'}
                    </Tag>
                </span>
            ),
        },
        {
            title: <span>Orders count</span>,
            dataIndex: 'orders_count',
            width: 20,
            render: (text: string, record) => <span>{record?.orders_count === 0 ? 'NA' : record?.orders_count}</span>,
        },
        {
            title: <span>Updated At</span>,
            dataIndex: 'updated_at',
            width: 25,
            ellipsis: true,
            render: (text: string) => (
                <Tooltip title={convertIsoToLocaleString(text)}>{convertIsoToLocaleString(text)}</Tooltip>
            ),
            sorter: (a, b) => (dayjs(a.updated_at).isBefore(dayjs(b.updated_at)) ? -1 : 1),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: <span>Created At</span>,
            dataIndex: 'created_at',
            width: 25,
            ellipsis: true,
            render: (text: string) => (
                <Tooltip title={convertIsoToLocaleString(text)}>{convertIsoToLocaleString(text)}</Tooltip>
            ),
            sorter: (a, b) => (dayjs(a.updated_at).isBefore(dayjs(b.updated_at)) ? -1 : 1),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: 20,
            fixed: 'right',
            render: (_, record) => (
                <span className='text-lg'>
                    <Tooltip title={'Edit Customer'}>
                        <EditOutlined
                            className='px-2 text-blue-500 cursor-pointer'
                            onClick={async () => {
                                await handleActionClick(record)
                            }}
                        />
                    </Tooltip>
                </span>
            ),
        },
    ]

    const csvPreviewColumns: ColumnProps<any>[] = [
        {
            title: <span>Phone Number</span>,
            dataIndex: 'phone',
            width: 30,
            render: (phone: string) => <span>{phone}</span>,
        },
        {
            title: <span>Block Type</span>,
            dataIndex: 'type',
            width: 30,
            ellipsis: true,
            render: (type: string) => <span>{toCapitalCase(type)}</span>,
        },
        {
            title: <span>Entity</span>,
            dataIndex: 'entity',
            width: 30,
            ellipsis: true,
            render: (entity: string) => (
                <span>
                    {' '}
                    <Tooltip title={entity}>{entity}</Tooltip>
                </span>
            ),
        },
        {
            title: <span>Method</span>,
            dataIndex: 'method',
            width: 30,
            ellipsis: true,
            render: (method: string) => (
                <span>
                    <Tag className='text-xs px-2'> {toCapitalCase(method)}</Tag>
                </span>
            ),
        },
        {
            title: <span>Action</span>,
            dataIndex: 'action',
            width: 30,
            ellipsis: true,
            render: (action: string) => <span>{toCapitalCase(action)}</span>,
        },
        {
            title: <span>Status</span>,
            dataIndex: 'status',
            width: 30,
            ellipsis: true,
            render: (status: string) => (
                <Tag
                    variant={status !== 'success' ? 'error' : 'success'}
                    className='!px-3 !py-1 rounded-md inter font-medium inline-block capitalize text-xs'
                >
                    {toCapitalCase(status)}
                </Tag>
            ),
        },
        {
            title: <span>Remark</span>,
            dataIndex: 'remarks',
            width: 50,
            ellipsis: true,
            render: (remark: string) => (
                <span>
                    <Tooltip title={remark}>{remark}</Tooltip>
                </span>
            ),
        },
    ]

    //function which return block type in case of merchants
    const getBlockTypeFromObj = (blockObject) => {
        return blockObject[0]?.type
    }

    function transformInput(input) {
        const result = []

        input?.merchant_blocked?.forEach((merchant) => {
            result.push({
                on: 'merchant',
                name: merchant.merchant_name || '',
                type: merchant.type || 'All',
                m_id: merchant.mid,
            })
        })

        input?.platform_blocked?.forEach((platform) => {
            result.push({
                on: 'platform',
                name: platform.platform,
                type: platform.type || 'All',
                m_id: '',
            })
        })

        return result
    }

    const setBlockingTableData = (data) => {
        const blocksArray = transformInput(data)

        if (isMerchantUser) {
            setBlockedPayment(getBlockTypeFromObj(blocksArray))
        } else {
            setBlockDataInfo(blocksArray)
        }
    }

    async function handleActionClick(record) {
        let response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.getBlockedCustomerData + `${record?.id}`,
        })
        setSelectedCustomer(record)

        setBlockingTableData(response?.data?.data?.customer)
        setModalOpen(true)
    }

    const handleSubmit = async (customer_id, payload) => {
        try {
            let response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.blockCustomerPartial,
                payload: { customer_id, ...payload },
            })
            if (response.success) {
                message.success('Success')
                setModalOpen(false)
                makeCustomersApiCall(parameters)
            } else {
                message.warning(
                    response?.data?.message ||
                        response?.data?.data?.message ||
                        response?.response?.data?.message ||
                        response?.response?.data?.data?.message ||
                        'Failed',
                )
            }
        } catch (error) {
            console.log('Error while blocking the customer', error)
        }
    }

    const handleUnblockAll = async (customer_id) => {
        try {
            let response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.unblockAll + `${customer_id}`,
            })
            if (response.success) {
                message.success('Successfully Updated')
                makeCustomersApiCall(parameters)
            } else {
                message.warning('Failed!')
            }
        } catch (err) {
            console.warn(err)
        }
    }

    async function customerBlock(customerCurrentStatus, customerId, type) {
        if (!customerId) return

        const params = { customer_id: customerId, is_customer_blocked: !customerCurrentStatus, type: type }
        try {
            let response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.blockCustomer,
                payload: params,
            })
            if (response.success) {
                message.success('Successfully Updated')
                makeCustomersApiCall(parameters)
            } else {
                message.warning(
                    response?.data?.message ||
                        response?.data?.data?.message ||
                        response?.response?.data?.message ||
                        response?.response?.data?.data?.message,
                )
            }
        } catch (err) {
            console.warn(err)
        }
    }

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'customers',
                href: `/${isMerchantUser ? 'admin' : 'access-control'}/customers`,
                text: 'Customers',
            },
        ])
        makeCustomersApiCall(parameters)
        fetchMerchantList()
    }, [])

    async function makeCustomersApiCall(parameters) {
        if (!parameters.page) return
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.getCustomer,
                params: {
                    ...parameters,
                },
            })

            if (response.data?.data?.customers?.length > 0) {
                setCustomerData(response.data?.data?.customers)
                setTotal(response.data?.data?.totalCount)
            } else {
                setCustomerData([])
                setTotal(0)
            }

            disptach<any>(saveFiltersData('customers', { ...parameters }))
        } catch (err) {
            console.warn(err)
        }
    }

    const reset = (e) => {
        e.preventDefault()
        const newParameters = {
            page: 1,
            sortKey: 'created_at',
            sortOrder: -1,
            pageSize: 25,
            start_dt: formattedDate,
            end_dt: formattedDate,
        }
        setParameters(newParameters)
        setTotal(0)
        setCustomerData([])
        disptach<any>(saveFiltersData('customers', {}))
    }
    const handleSearchClick = (e) => {
        e?.preventDefault()

        if (parameters?.['searchEmail']) {
            const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

            if (!parameters?.['searchEmail'].match(emailPattern)) {
                message.error('Invalid email format.')

                return
            }
        }

        if (parameters?.['searchPhone'] && parameters?.['searchPhone']?.toString()?.length < 10) {
            message.error('Phone number should be 10-digits.')
            return
        }

        makeCustomersApiCall({ ...parameters, page: 1 })
    }
    const handlePaginationChange: any = (current: number, pageSize?: number) => {
        setParameters((prev) => ({ ...prev, page: current, pageSize }))
        makeCustomersApiCall({ ...parameters, page: current, pageSize })
    }
    useEffect(() => {
        if (Object.keys(prevFilters).length && JSON.stringify(prevFilters) !== JSON.stringify(parameters))
            setParameters({ ...prevFilters })
    }, [prevFilters])

    const donwloadSample = () => {
        let csvContent = isMerchantUser ? 'Phone,Method,Action\r\n' : 'Phone,Type,Entity,Method,Action\r\n'
        csvContent += isMerchantUser
            ? ['9999999999,cod,block', '9999998888,upi,unblock', '9999998887,all,block'].join('\r\n')
            : [
                  '9999999999,merchant,192fmqudabyl9fu34,upi,block',
                  '9999998888,merchant,19g6ilkatpl78,all,unblock',
                  '9999998887,platform,WooCommerce,cod,unblock',
                  '9999998886,platform,Custom,all,block',
              ].join('\r\n')

        try {
            var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
            var link = document.createElement('a')

            var url = URL.createObjectURL(blob)
            link.href = url

            link.setAttribute('download', 'block_sample.csv')

            document.body.appendChild(link)

            link.click()
            document.body.removeChild(link)

            URL.revokeObjectURL(url)

            message.success('Sample downloaded successfully!')
        } catch (error) {
            console.error('Error during file download:', error)
            message.error('Failed to download the sample.')
        }
    }

    function uploadCsv(file) {
        let reader = new FileReader()
        reader.readAsText(file)
        reader.onload = (e) => {
            const content = e.target.result as string
            const rows = content
                .replace(/\r\n/g, '\n')
                .replace(/\r/g, '\n')
                .split('\n')
                .filter((row) => row.trim() !== '')
                .map((row) => row.split(','))

            if (isMerchantUser) {
                if (
                    rows[0].length !== 3 ||
                    rows[0][0].toLowerCase() !== 'phone' ||
                    rows[0][1].toLowerCase() !== 'method' ||
                    rows[0][2].toLowerCase() !== 'action'
                ) {
                    setUploadFailed(true)
                    message.error('Wrong CSV file structure. Please look at the sample to know the right structure!')
                    return
                }
            } else {
                if (
                    rows[0].length !== 5 ||
                    rows[0][0].toLowerCase() !== 'phone' ||
                    rows[0][1].toLowerCase() !== 'type' ||
                    rows[0][2].toLowerCase() !== 'entity' ||
                    rows[0][3].toLowerCase() !== 'method' ||
                    rows[0][4].toLowerCase() !== 'action'
                ) {
                    setUploadFailed(true)
                    message.error('Wrong CSV file structure. Please look at the sample to know the right structure!')
                    return
                }
            }

            // Remove header row
            rows.shift()

            // Validate and process each row
            if (isMerchantUser) {
                for (let i = 0; i < rows.length; i++) {
                    const [phoneNumber, paymentMethod, action] = rows[i].map((value) => value.trim())
                    if (
                        !/^\d{10}$/.test(phoneNumber) ||
                        !paymentMethod ||
                        (paymentMethod?.toLowerCase() !== 'cod' &&
                            paymentMethod?.toLowerCase() !== 'upi' &&
                            paymentMethod?.toLowerCase() !== 'all') ||
                        !action ||
                        (action?.toLowerCase() !== 'block' && action?.toLowerCase() !== 'unblock')
                    ) {
                        message.error('Invalid value in CSV detected. Please check and upload.')
                        setUploadFailed(true)
                        return
                    }
                }
            } else {
                for (let i = 0; i < rows.length; i++) {
                    const [phoneNumber, type, entity, paymentMethod, action] = rows[i].map((value) => value.trim())
                    if (
                        !/^\d{10}$/.test(phoneNumber) ||
                        !paymentMethod ||
                        (paymentMethod?.toLowerCase() !== 'cod' &&
                            paymentMethod?.toLowerCase() !== 'upi' &&
                            paymentMethod?.toLowerCase() !== 'all') ||
                        !action ||
                        (action?.toLowerCase() !== 'block' && action?.toLowerCase() !== 'unblock') ||
                        !type ||
                        !entity
                    ) {
                        message.error('Invalid value in CSV detected. Please check and upload.')
                        setUploadFailed(true)
                        return
                    }
                }
            }

            // Convert rows to an array of objects
            let parsedData = []
            if (isMerchantUser) {
                parsedData = rows.map((row) => ({
                    phone: row[0],
                    method: row[1],
                    action: row[2],
                }))
            } else {
                parsedData = rows.map((row) => ({
                    phone: row[0],
                    type: row[1],
                    entity: row[2],
                    method: row[3],
                    action: row[4],
                }))
            }

            // Handle the parsed data as needed
            setUploadedCsv([...parsedData])
            setUploadedFile(file)
            setUploadFailed(false)
        }
    }

    const showPreview = () => {
        setPreviewCsv(true)
    }

    const getSignedUrl = async () => {
        const response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.blockCustomerPresignedUrl,
            params: { file_name: 'customer_block.csv' },
        })

        if (response.success) {
            return response.data.data.url
        }
    }

    const setStats = (csvData) => {
        let failures = 0
        csvData?.forEach((entry) => {
            if (entry?.status?.toLowerCase() === 'failure') {
                failures++
            }
        })

        setFailedEntries(failures)
        setEntries(csvData.length)
    }

    async function uploadCsvData() {
        if (!uploadedFile) {
            return
        }
        setIsLoading(true)
        setDrawerOpen(false)
        try {
            const url = await getSignedUrl()
            const awsResponse = await fetch(url, {
                method: 'PUT',
                body: uploadedFile,
            })
            if (awsResponse.status == 200) {
                const parsedUrl = new URL(url)
                const parts = parsedUrl.pathname.split('/')
                const nonEmptyParts = parts.filter((part) => part !== '')
                const filename = nonEmptyParts.pop()

                const postData = {
                    file_name: filename,
                }

                const response = await makeAPICall({
                    method: 'post',
                    url: process.env.REACT_APP_BASE_URL + APIEndPoints.blockCustomerUploadNotifyUrl,
                    payload: {
                        ...postData,
                    },
                }).then((res) => {
                    message.success('Uploaded successfully!')
                    if (res?.data) {
                        const parcedData = parseCSVData(res?.data)
                        setParcedCsv(parcedData)
                        setStats(parcedData)
                    }
                    setIsLoading(false)
                    makeCustomersApiCall(parameters)
                    setUploadSuccess(true)
                })
                if (response.status != 200)
                    console.error('Failed to make POST request:', response.status, response.data, response.statusText)
            } else {
                console.error('Failed to make POST request:', awsResponse.status, awsResponse.statusText)
            }
        } catch {
            // toast.error('Upload Failed!', {
            //     position: 'top-center',
            //     autoClose: 1500,
            // })
        }
    }

    function parseCSVData(csvData) {
        const lines = csvData.split('\n')
        const headers = lines[0].split(',')

        const result = []

        for (let i = 1; i < lines.length - 1; i++) {
            const values = lines[i].split(',')
            const rowObject = {}

            headers.forEach((header, index) => {
                rowObject[header] = values[index] || ''
            })

            result.push(rowObject)
        }

        return result
    }

    const convertToCSV = (data) => {
        if (!data.length) return ''

        const headers = Object.keys(data[0])
        const rows = data.map((row) => headers.map((header) => `"${row[header] || ''}"`).join(','))

        return [headers.join(','), ...rows].join('\n')
    }

    const downloadCSV = () => {
        if (parcedCsv?.length > 0) {
            const csvContent = convertToCSV(parcedCsv)
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
            const url = URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.download = (uploadedFile as any)?.name ?? 'data.csv'
            link.click()
            URL.revokeObjectURL(url)
        } else {
            message.error('Something went wrong!')
        }
    }

    const getValue = (value) => (typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value)
    const getLabel = (key) => LABELS[key] || key.charAt(0).toUpperCase() + key.slice(1).replace('_', ' ')

    const fetchMerchantList = async () => {
        const respnse = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.getMerchantList,
        })
        setMerchantList(respnse?.data?.data)
    }

    const toCapitalCase = (text) => {
        if (!text) {
            return
        }

        return text.charAt(0).toUpperCase() + text.slice(1)
    }

    return (
        <>
            <Drawer
                title={'Customer Info'}
                placement='right'
                onClose={() => {
                    setOpenDrawer(false)
                    setCustomerDetails([])
                }}
                open={openDrawer}
            >
                <Space direction='vertical' className='w-full'>
                    <Row>
                        <Col className='flex flex-col gap-y-4' sm={24}>
                            {Object.entries(CustomerDetails).map(([key, value]) => {
                                const Icon = ICONS[key]
                                if (Icon) {
                                    return (
                                        <div
                                            key={key}
                                            className='flex items-center justify-between w-full text-sm inter font-normal'
                                        >
                                            <div className='text-gray-600'>
                                                <Icon className='mr-2' width={20} height={20} />
                                                <span>{getLabel(key)}</span>
                                            </div>
                                            <Tooltip placement='topLeft' title={getValue(value)}>
                                                <span className='text-black font-medium max-w-[50%] truncate'>
                                                    {getValue(value)}
                                                </span>
                                            </Tooltip>
                                        </div>
                                    )
                                }
                            })}
                        </Col>
                    </Row>
                </Space>
            </Drawer>
            <div className='w-full h-full'>
                <Row className='p-2 rounded bg-neutrals-15 mb-2' align={'middle'} justify={'space-between'}>
                    <Col sm={12} className='flex items-center'>
                        <div className='text-black inter m-0 tracking-wide items-center flex gap-2'>
                            <Tooltip
                                title={'Upload csv file in appropriate format to block/unblock the customers in bulk'}
                            >
                                <InfoCircleOutlined className='text-blue fs20' />
                            </Tooltip>

                            <span>Add files here to bulk block the customers</span>
                        </div>
                    </Col>
                    <Col className='flex justify-end' sm={2}>
                        <Button onClick={() => setDrawerOpen(true)} variant='primary'>
                            <PlusCircleOutlined />
                            Upload File
                        </Button>
                    </Col>
                </Row>
                {(uploadSuccess || isLoading) && (
                    <Tag className='bg-white p-2 rounded-md relative mt-4 mb-4 flex justify-between'>
                        {isLoading ? (
                            <>
                                <div className='absolute top-[-8px] left-[-5px]'>
                                    <CloseCircleOutlined
                                        style={{ fontSize: '20px', color: '#8c8c8c', cursor: 'pointer' }}
                                        onClick={() => setUploadSuccess(false)}
                                    />
                                </div>

                                <div className='gap-2 rounded-md flex items-center justify-between ml-6'>
                                    <Spin /> <span className='text-sm'>Uploading...</span>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className='absolute top-[-8px] left-[-5px]'>
                                    <CloseCircleOutlined
                                        style={{ fontSize: '20px', color: '#8c8c8c', cursor: 'pointer' }}
                                        onClick={() => setUploadSuccess(false)}
                                    />
                                </div>

                                <div className='gap-2 rounded-md flex items-center justify-between ml-6'>
                                    <span className='text-sm'>
                                        File uploaded successfully{' '}
                                        <CheckCircleFilled style={{ fontSize: '16px', color: '#52c41a' }} />
                                    </span>
                                </div>
                                <div className='gap-2 rounded-md flex items-center justify-between'>
                                    <Tooltip title={'Donwload CSV'}>
                                        <DownloadOutlined
                                            style={{ fontSize: '20px' }}
                                            onClick={downloadCSV}
                                            className='text-blue-500'
                                        />
                                    </Tooltip>
                                    <p className='flex items-center'>
                                        <span className='mr-1'>{entries} Entries </span>
                                        <span className='opacity-40 flex items-center'>
                                            • {failedEntries}/{entries} Failed{' '}
                                        </span>
                                    </p>
                                    <Button variant='primary' onClick={() => showPreview()}>
                                        preview
                                    </Button>
                                </div>
                            </>
                        )}
                    </Tag>
                )}

                <div className='mt-2'>
                    <Row className='bg-white rounded'>
                        <RenderSearchFilters
                            values={parameters}
                            setValues={(data, reset) =>
                                setParameters((prev: any) => (reset ? { ...data } : { ...prev, ...data }))
                            }
                            page='customers'
                            onSearch={handleSearchClick}
                            onReset={reset}
                        />
                    </Row>
                </div>

                <div className=' bg-white rounded overflow-clip mt-5'>
                    <Table
                        columns={customerData?.length > 0 ? columns : []}
                        dataSource={customerData || []}
                        pagination={{
                            current: parameters.page,
                            pageSize: parameters.pageSize,
                            total: total,
                            showSizeChanger: false,
                            onChange: handlePaginationChange,
                            position: ['topLeft', 'bottomLeft'],
                            showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
                        }}
                    />
                </div>
            </div>

            <BlockCustomerDrawer
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                blockData={blockData}
                setBlockData={setBlockData}
                merchantList={merchantList}
                customer={selectedCustomer}
                blockDataInfo={blockDataInfo}
                setBlockDataInfo={setBlockDataInfo}
                handleSubmit={handleSubmit}
                handleUnblockAll={handleUnblockAll}
                blockedPayment={blockedPayment}
                setBlockedPayment={setBlockedPayment}
            />
            <Modal
                okText={null}
                title={'Block/Unblock Bulk Customers'}
                className='w-[80vw] p-0'
                onCancel={() => {
                    setDrawerOpen(false)
                }}
                open={isDrawerOpen}
                footer={null}
                styles={{
                    content: {
                        padding: '0px',
                        width: '40vw',
                        paddingTop: '16px',
                        background: '#F3F3F3',
                        borderRadius: '8px',
                    },
                    body: {
                        padding: '0px',
                        background: '#FFFFFF',
                        borderRadius: '8px',
                    },
                    header: {
                        background: '#F3F3F3',
                        padding: '2px',
                        paddingLeft: '15px',
                    },
                }}
            >
                <div className='p-3'>
                    <Dragger
                        accept='.csv'
                        name='file'
                        maxCount={1}
                        multiple={true}
                        itemRender={(originNode, file) => {
                            return (
                                <div>
                                    <span>{file.name}</span>
                                </div>
                            )
                        }}
                        onChange={(info) => {
                            const { status, originFileObj } = info.file
                            if (status?.toLowerCase() !== 'uploading') {
                                uploadCsv(originFileObj)
                            }
                        }}
                    >
                        <p className='ant-upload-drag-icon'>
                            <InboxOutlined />
                        </p>
                        <p className='ant-upload-text'>Click or drag file to this area to upload</p>
                    </Dragger>
                    <div className='flex items-center justify-between border-solid rounded-md border-[#1677FF8C] p-2 bg-[#1677FF0D] my-2'>
                        <p className='text-xs text-gray-600'>
                            Please ensure that the uploaded file is in the suggested format as shown in the sample file
                            on the right
                        </p>
                        <Button onClick={donwloadSample} type='link' variant='default' className='ml-3 pr-0'>
                            <DownloadOutlined />
                            Download Sample
                        </Button>
                    </div>

                    <Row justify='space-between' className='my-2 mt-3'>
                        <p className='text-sm'>Supported Formats: CSV</p>
                        <p className='text-sm text-gray-500'>Maximum file size: 200 MB</p>
                    </Row>
                </div>
                <Row className='flex align-middle justify-center mt-2'>
                    <Button
                        onClick={async () => {
                            try {
                                await uploadCsvData()
                            } catch (error) {
                                console.log('Validation failed:', error)
                            }
                        }}
                        variant='primary'
                        className='w-[50%] mb-6'
                        disabled={uploadFailed}
                    >
                        Proceed
                    </Button>
                </Row>
            </Modal>
            <Modal
                title='Uploaded CSV Preview'
                style={{ top: 20, left: 100 }}
                open={previewCsv}
                footer={null}
                width={1200}
                onCancel={() => setPreviewCsv(false)}
                styles={{
                    body: {
                        marginTop: '50px',
                    },
                }}
            >
                <Table
                    columns={
                        isMerchantUser
                            ? csvPreviewColumns?.filter(
                                  (entry) => entry?.dataIndex !== 'entity' && entry?.dataIndex !== 'type',
                              )
                            : csvPreviewColumns
                    }
                    dataSource={parcedCsv || []}
                />
            </Modal>
        </>
    )
}
