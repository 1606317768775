import React, { useState } from 'react'
import { Col, Row, Form, InputNumber, Button, Table, DeleteOutlined, message } from 'gokwik-ui-kit'

const SetupBundleDiscounts = ({ form }: { form: any }) => {
    const [bundleInputs, setBundleInputs] = useState({
        product_quantity: 0,
        capped_value: 0,
    })
    const buyxatyDiscount = Form.useWatch('buyxatyDiscount', form)
    const handleAddTier = () => {
        if (bundleInputs.product_quantity === 0 || bundleInputs.capped_value === 0) {
            return message.error('Please fill all the fields')
        }
        const minimumRequirementsValue = form.getFieldValue('discountValue')
        const minimumRequirements = form.getFieldValue('minimumRequirements')

        if (minimumRequirements === 'minimumQuantity' && minimumRequirementsValue > bundleInputs.product_quantity) {
            message.error('Minimum quantity should be less than or equal to the quantity of the bundle')
            return
        }

        if (buyxatyDiscount?.some((item) => item.product_quantity === bundleInputs.product_quantity)) {
            return message.error('Tier with same quantity already exists')
        }
        const newValues = [...(buyxatyDiscount || []), { ...bundleInputs, match_type: 1 }]
        form.setFieldsValue({
            buyxatyDiscount: newValues,
        })
        setBundleInputs({ product_quantity: 0, capped_value: 0 })
    }

    const columns: any = [
        {
            title: 'Quantity',
            dataIndex: 'product_quantity',
            key: 'product_quantity',
            align: 'right',
        },
        {
            title: 'Capped Value',
            dataIndex: 'capped_value',
            key: 'capped_value',
            align: 'right',
        },
        {
            title: '',
            dataIndex: 'action',
            align: 'center',

            render: (text, record) => (
                <DeleteOutlined
                    className='cursor-pointer text-error-500'
                    onClick={() => {
                        const newValues = buyxatyDiscount.filter(
                            (item) => JSON.stringify(item) !== JSON.stringify(record),
                        )
                        form.setFieldsValue({
                            buyxatyDiscount: newValues,
                        })
                    }}
                />
            ),
        },
    ]
    return (
        <Col span={24}>
            <p className='text-sm font-semibold w-full pb-2'>Set discount properties</p>
            <Row gutter={[16, 16]} align={'bottom'}>
                <Col span={6}>
                    <InputNumber
                        className='w-full'
                        label={'Size of the bundle (Quantity)'}
                        value={bundleInputs.product_quantity}
                        min={1}
                        onChange={(value) => setBundleInputs({ ...bundleInputs, product_quantity: +value })}
                    />
                </Col>
                <Col span={6}>
                    <InputNumber
                        className='w-full'
                        label={'Capped Value'}
                        value={bundleInputs.capped_value}
                        onChange={(value) => setBundleInputs({ ...bundleInputs, capped_value: +value })}
                        min={1}
                    />
                </Col>
                <Col>
                    <Button onClick={handleAddTier}>Add more tiers</Button>
                </Col>
                <Col span={24}>
                    <Table
                        columns={columns}
                        dataSource={buyxatyDiscount}
                        pagination={{
                            pageSize: 10,
                            total: buyxatyDiscount?.length,
                            showSizeChanger: false,
                            position: ['none', 'bottomRight'],
                            showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
                        }}
                    />
                </Col>
                <Form.Item
                    name='buyxatyDiscount'
                    className='pl-4 hide-control-input'
                    rules={[{ required: true, message: 'Please add tiers' }]}
                >
                    <> </>
                </Form.Item>
            </Row>
        </Col>
    )
}

export default SetupBundleDiscounts
