import { updateBreadcrumbs } from '@gokwik/utilities'
import DiscountCombinations from '@library/components/discounts/combination'
import DiscountConditions from '@library/components/discounts/conditions'
import DiscountCustomerEligibility from '@library/components/discounts/customerEligibility'
import DiscountGenerateCodes from '@library/components/discounts/generateCodes'
import SetupDiscount from '@library/components/discounts/setup'
import Summary from '@library/components/discounts/summary'
import { bulkDiscountInitialValues } from '@library/utilities/constants/constants'
import { useAppDispatch } from '@library/utilities/hooks'
import { emptyDiscountData, fetchDiscountDataAsync, saveDiscount, setSummary } from '@store/discounts'
import { selectDiscountCodeData } from '@store/discounts/selectors'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { Button, Col, Form, Row, Steps } from 'gokwik-ui-kit'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

const CreateBulkDiscounts = () => {
    const [form]: [any] = Form.useForm()
    const [currentStep, setCurrentStep] = useState(0)
    const discountCode = Form.useWatch('discountCode', form)
    const dispatch = useAppDispatch()
    const discountData = useSelector(selectDiscountCodeData)
    const routeParams = useParams<{ id: string }>()
    const navigate = useNavigate()
    const merchant_details = useSelector(getMerchantDetails)
    const user_details = useSelector(getUserDetails)
    const previousStep = useRef(0)
    const stepsDisabledAfter = routeParams.id ? 4 : previousStep.current

    const submitForm = async () => {
        try {
            // Validate the form fields
            await form.validateFields()
            // Get the form values
            const currentValues = form.getFieldsValue()
            const values = form.getFieldsValue(true)
            const isNotChanged =
                currentStep === 3 && discountData.status === 'DRAFT'
                    ? false
                    : !!routeParams.id &&
                      Object.keys(currentValues).every((key) => {
                          if (typeof currentValues[key] === 'object' && currentValues[key] !== null) {
                              return JSON.stringify(currentValues[key]) === JSON.stringify(discountData[key])
                          } else return currentValues[key] === discountData[key]
                      })
            if (isNotChanged || (currentStep === 4 && discountData.bulkDisabled)) {
                if (currentStep === 4) {
                    navigate('/checkout/settings/discounts', {
                        state: {
                            activeTab: 'bulk',
                        },
                    })
                } else {
                    setCurrentStep((prev) => prev + 1)
                }
                return
            }

            const page =
                currentStep === 0
                    ? 'setup'
                    : currentStep === 1
                    ? 'conditions'
                    : currentStep === 2
                    ? 'combinations'
                    : currentStep === 3
                    ? 'customerEligibility'
                    : 'generateCodes'

            const res = await dispatch(
                saveDiscount(
                    {
                        ...values,
                        id: routeParams.id || discountData.id,
                        discountMethod: 'manual',
                        shopifyDiscountId: discountData.shopifyDiscountId,
                        merchantId: merchant_details.m_id,
                        email: user_details.email,
                        priceRuleId: discountData.priceRuleId,
                        title: values.discountCode,
                        isBulkDiscount: true,
                        subMethod: 'bulk',
                        status: discountData.shopifyDiscountId ? 'ACTIVE' : '',
                    },
                    page,
                ),
            )
            if (res.success) {
                if (currentStep === 4) {
                    navigate('/checkout/settings/discounts', {
                        state: {
                            activeTab: 'bulk',
                        },
                    })
                } else {
                    setCurrentStep((prev) => prev + 1)
                    if (previousStep.current < currentStep + 1) {
                        previousStep.current = currentStep + 1
                    }
                }
            }
        } catch (error) {
            console.error('Validation failed:', error)
        }
    }

    useEffect(() => {
        if (discountData.discountProgress === 'continue' && !discountData.noImport) {
            const updatedData = {
                ...discountData,
                discountCode: form.getFieldValue('discountCode') || discountData.discountCode,
            }
            form.setFieldsValue(updatedData)
            dispatch(setSummary({ ...updatedData }))
        } else if (!discountCode) {
            form.setFieldsValue({
                ...bulkDiscountInitialValues,
            })
        }
    }, [discountData])
    useEffect(() => {
        return () => {
            dispatch(emptyDiscountData())
        }
    }, [])
    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'checkout-settings',
                href: '/checkout/settings',
                text: 'Checkout Settings',
            },
            {
                key: 'discounts',
                href: '/checkout/settings/discounts',
                text: 'Discounts',
            },
            {
                key: 'bulk',
                href: routeParams.id
                    ? '/checkout/settings/discounts/edit/buk/' + routeParams.id
                    : '/checkout/settings/discounts/create/buk',
                text: (routeParams.id ? 'Edit' : 'Create') + ' Bulk Discount',
            },
        ])
        routeParams.id &&
            dispatch(
                fetchDiscountDataAsync({
                    id: routeParams.id,
                }),
            )
    }, [routeParams.id])
    return (
        <Row gutter={[16, 16]}>
            <Col lg={24} xl={18}>
                <div className='w-full flex items-center justify-between mb-3'>
                    <div>
                        <span className='text-xl font-semibold'>
                            {routeParams.id ? 'Edit' : 'Create'} Bulk Discount
                        </span>
                    </div>
                    <Button
                        onClick={() => {
                            submitForm()
                        }}
                        type='primary'
                        className='ml-4'
                    >
                        Save {currentStep !== 4 ? 'and Proceed' : ''}
                    </Button>
                </div>
                <div className='w-full bg-neutrals-15 p-3 pt-6 discount-form'>
                    <Steps
                        progressDot
                        onChange={(current) => setCurrentStep(current)}
                        items={[
                            {
                                title: 'Step 1',
                                description: 'Setup',
                            },
                            {
                                title: 'Step 2',
                                description: 'Conditions',
                                disabled: stepsDisabledAfter < 1,
                            },
                            {
                                title: 'Step 3',
                                description: 'Combination',
                                disabled: stepsDisabledAfter < 2,
                            },
                            {
                                title: 'Step 4',
                                description: 'Customer Eligibility',
                                disabled: stepsDisabledAfter < 3,
                            },
                            {
                                title: 'Step 5',
                                description: 'Generate Codes',
                                disabled: stepsDisabledAfter < 4,
                            },
                        ]}
                        current={currentStep}
                        className='mb-4'
                        size='small'
                    />
                    {currentStep === 0 && <SetupDiscount form={form} />}
                    {currentStep === 1 && <DiscountConditions form={form} />}
                    {currentStep === 2 && <DiscountCombinations form={form} />}
                    {currentStep === 3 && <DiscountCustomerEligibility form={form} />}
                    {currentStep === 4 && <DiscountGenerateCodes form={form} />}
                </div>
            </Col>
            <Col lg={12} xl={6}>
                <Summary />
            </Col>
        </Row>
    )
}

export default CreateBulkDiscounts
