import { Alert, AlertOutlined, DownOutlined } from 'gokwik-ui-kit'
import MainIcon from '@library/images/common/kyc/icons/website-readiness.svg'
import { useState } from 'react'

const WebsiteReadiness = ({ userData }) => {
    const [isExpanded, setIsExpanded] = useState(true)

    const toggleCollapse = () => {
        setIsExpanded(!isExpanded)
    }

    const tableData = [
        { key: 'About us', hasError: false },
        { key: 'Terms of services', hasError: false },
        { key: 'Contact us', hasError: false },
        { key: 'Privacy policy', hasError: false },
        {
            key: (
                <div className='flex flex-col'>
                    <p>Return policy</p>
                    <ul className='list-none'>
                        <li>Refund process</li>
                        <li>Cancellation process</li>
                        <li>Refund and return</li>
                    </ul>
                </div>
            ),
            hasError: false,
        },
        {
            key: (
                <div className='flex flex-col'>
                    <p>Shipping policy</p>
                    <ul className='list-none'>
                        <li>A packaging details</li>
                        <li>Delivery timelines</li>
                        <li>Tracking details</li>
                    </ul>
                </div>
            ),
            hasError: false,
        },
    ]

    const items = [
        {
            key: '1',
            label: (
                <div className='flex gap-2'>
                    <div className='flex'>
                        <img src={MainIcon} width={24} />
                    </div>
                    <div className='flex flex-col gap-1'>
                        <div className='flex'>
                            <div className='text-base font-semibold mr-2'>Website Readiness</div>

                            <div className='flex gap-2'>
                                <div
                                    style={{
                                        background:
                                            'linear-gradient(289.8deg, rgba(145, 202, 255, 0.6) 0%, rgba(230, 244, 255, 0) 100%)',
                                        color: '#006ED2',
                                    }}
                                    className='text-xs font-bold flex align-middle items-center rounded-md p-0 pr-2'
                                >
                                    Important
                                </div>

                                {userData?.merchantDetails?.onboarding_status?.kwik_checkout_app ===
                                    'kyc_verification_failed' &&
                                    Object.keys(userData?.kycData?.kyc_failure_reasons)?.includes('website') && (
                                        <Alert
                                            message={userData?.kycData?.kyc_failure_reasons['website']}
                                            icon={<AlertOutlined />}
                                            type='error'
                                            showIcon
                                            className='w-full text-sm text-red-700 p-0.5'
                                        />
                                    )}
                            </div>
                        </div>

                        <div className='items-center text-sm inter font-normal'>
                            As per RBI regulations, you’re required to have the following details on your website.
                        </div>
                    </div>
                </div>
            ),
            children: (
                <div>
                    <div className='text-base font-semibold inter mb-3'>Required Details/Pages for KYC Compliance</div>
                    <div className='overflow-x-auto mb-3'>
                        <table className='table-auto border border-collapse border-solid border-gray-300 w-full rounded-lg text-sm font-normal inter'>
                            <tbody>
                                {/* Render each key in a new row, 2 keys per row */}
                                {tableData.map(
                                    (item, index) =>
                                        index % 2 === 0 && (
                                            <tr key={index} className='border border-solid border-gray-300'>
                                                <td className='px-4 py-2 border border-solid border-gray-300 text-gray-700'>
                                                    <div className='flex justify-start gap-2 items-center'>
                                                        <div
                                                            className='flex'
                                                            style={
                                                                tableData[index]?.hasError ? { color: '#b91c1c' } : {}
                                                            }
                                                        >
                                                            {tableData[index]?.key || ''}
                                                        </div>
                                                        {tableData[index]?.hasError && (
                                                            <div className='flex h-max'>
                                                                <span
                                                                    className={`px-2 rounded-sm text-xs text-center bg-red-100 text-red-700 font-medium`}
                                                                >
                                                                    Missing
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </td>
                                                <td className='px-4 py-2 border border-solid border-gray-300 text-gray-700'>
                                                    <div className='flex justify-start gap-2 items-center'>
                                                        <div
                                                            className='flex'
                                                            style={
                                                                tableData[index + 1]?.hasError
                                                                    ? { color: '#b91c1c' }
                                                                    : {}
                                                            }
                                                        >
                                                            {tableData[index + 1]?.key || ''}
                                                        </div>
                                                        {tableData[index + 1]?.hasError && (
                                                            <div className='flex h-max'>
                                                                <span
                                                                    className={`px-2 rounded-sm text-xs text-center bg-red-100 text-red-700 font-medium`}
                                                                >
                                                                    Missing
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        ),
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            ),
        },
    ]

    return (
        <div className='bg-white rounded-md border border-solid border-[#d9d9d9]'>
            <div onClick={toggleCollapse} className='cursor-pointer flex justify-between items-center p-4'>
                {items[0].label}
                <DownOutlined rotate={isExpanded ? 180 : 0} />
            </div>
            {isExpanded && <div className='p-4'>{items[0].children}</div>}
        </div>
    )
}

export default WebsiteReadiness
