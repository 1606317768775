import { convertIsoToLocaleString } from '@gokwik/utilities'
import { type ColumnProps, EyeOutlined, Tag, Tooltip } from 'gokwik-ui-kit'
import { type TagProps } from './types'
import { JOB_TYPES, Statuses } from './bulk.service'

function statusMapping(status: string): { text: string; variant: TagProps['variant'] } | null {
    const text = status.replace(/_/g, ' ')
    switch (status) {
        case Statuses.SUCCESS:
            return { text, variant: 'success' }

        case Statuses.FAILED:
            return { text, variant: 'error' }

        case Statuses.IN_PROGRESS:
            return { text, variant: 'default' }

        case Statuses.DRAFT:
            return { text, variant: 'warning' }

        case Statuses.PUBLISHING:
            return { text: 'In Progress', variant: 'default' }

        case Statuses.PARTIAL_PUBLISH:
            return { text: 'Partial Success', variant: 'default' }

        case Statuses.PUBLISHED:
            return { text: 'Success', variant: 'success' }

        case Statuses.ERROR:
            return { text: 'Failed', variant: 'error' }

        default:
            return null
    }
}

function Status(v: string) {
    const mapping = statusMapping(v)
    return (
        !!mapping && (
            <Tag
                variant={mapping.variant}
                className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
            >
                {mapping.text}
            </Tag>
        )
    )
}

export const bulkJobsColumnsInternal: (viewCallback: CallableFunction) => ColumnProps<any>[] = (vc) => [
    {
        title: <span>Job #</span>,
        dataIndex: 'id',
        width: 100,
        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                {text.toUpperCase()}
            </Tooltip>
        ),
        fixed: 'left',
    },
    {
        title: <span>File Name</span>,
        dataIndex: 'fileName',
        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                {text}
            </Tooltip>
        ),
        width: 200,
        fixed: 'left',
    },
    {
        title: <span>Validation Status</span>,
        width: 120,
        dataIndex: 'validation',
        render: Status,
    },
    {
        title: <span>Publish Status</span>,
        width: 150,
        dataIndex: 'status',
        render: Status,
    },
    // {
    //     title: <span>Row Count</span>,
    //     width: 100,
    //     dataIndex: 'count',
    // },
    {
        title: <span>Job Type</span>,
        width: 100,
        render: (val) => (
            <span className='capitalize'>{JOB_TYPES[`${val.jobType}/${val.actionType}`]?.label || ''}</span>
        ),
    },
    {
        title: <span>Created At</span>,
        dataIndex: 'createdAt',
        width: 100,
        render: (val) => convertIsoToLocaleString(val),
    },
    {
        title: <span>Created By</span>,
        width: 120,
        dataIndex: 'createdBy',
    },
    {
        title: <span>View</span>,
        width: 80,
        align: 'center',
        render: (val) => {
            let disable = ''
            if (!val.validation || Statuses.IN_PROGRESS === val.validation) {
                disable = 'Validation in progress'
            }
            if (val.status === Statuses.PUBLISHING) {
                disable = 'File is being published'
            }
            return (
                <Tooltip title={disable}>
                    <EyeOutlined
                        className={!!disable ? 'text-gray-300' : ''}
                        onClick={!disable ? () => vc(val) : null}
                    />
                </Tooltip>
            )
        },
        fixed: 'right',
    },
]
