import { downloadFromURL, downloadToCSV, makeAPICall, titleCase } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { useAppDispatch } from '@library/utilities/hooks'
import { fetchCustomerSegments, updateSummary } from '@store/discounts'
import { getMerchantDetails } from '@store/user/selectors'
import {
    Alert,
    Button,
    Checkbox,
    CloseOutlined,
    Col,
    DeleteOutlined,
    DownloadOutlined,
    FileAddFilled,
    FileAddOutlined,
    FileOutlined,
    Form,
    Input,
    message,
    Radio,
    Row,
    Select,
    Space,
    Switch,
    Table,
    Upload,
    UploadOutlined,
} from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const infoTexts = {
    shopifySegments: 'Segments created under customers sections on Shopify are automatically fetched.',
    specificCustomers: 'Upload a CSV file containing Phone Numbers eligible for this discount.',
    newCustomers: 'Identification is done based on your Shopify store data.',
    existingCustomers: 'Identification is done based on your Shopify store data.',
}

const DiscountCustomerEligibility = ({ form }: { form: any }) => {
    const location = useLocation()
    const [customerSegments, setCustomerSegments] = useState([])
    const dispatch = useAppDispatch()
    const isBulkDiscount = location.pathname.includes('bulk')
    const [utmParamInputs, setUtmParamInputs] = useState({
        utm_source: '',
        utm_medium: '',
        utm_campaign: '',
        utm_term: '',
        utm_content: '',
    })
    const discountCode = Form.useWatch('discountCode', form)
    const customerEligibility = Form.useWatch('customerEligibility', form)
    const customerSegmentSource = Form.useWatch('customerSegmentSource', form)
    const selectedSegments = Form.useWatch('selectedSegments', form)
    const enableUtmParameters = Form.useWatch('enableUtmParameters', form)
    const uploadedCsvFile = Form.useWatch('customerSegmentCSV', form)
    const utmParameters = Form.useWatch('utmParameters', form)

    const columns = [
        {
            title: 'UTM Source',
            dataIndex: 'utm_source',
            key: 'utm_source',
            render: (value) => titleCase(value),
        },
        {
            title: 'UTM Medium',
            dataIndex: 'utm_medium',
            key: 'utm_medium',
            render: (value) => (value ? titleCase(value) : '-'),
        },
        {
            title: 'UTM Campaign',
            dataIndex: 'utm_campaign',
            key: 'utm_campaign',
            render: (value) => (value ? titleCase(value) : '-'),
        },
        {
            title: 'UTM Term',
            dataIndex: 'utm_term',
            key: 'utm_term',
            render: (value) => (value ? titleCase(value) : '-'),
        },
        {
            title: 'UTM Content',
            dataIndex: 'utm_content',
            key: 'utm_content',
            render: (value) => (value ? titleCase(value) : '-'),
        },
        {
            title: 'Action',
            key: 'action',
            render: (record) => (
                <Space size='middle'>
                    <DeleteOutlined
                        className='text-error-500 cursor-pointer '
                        onClick={() =>
                            form.setFieldsValue({
                                utmParameters: utmParameters.filter(
                                    (u) => JSON.stringify(record) !== JSON.stringify(u),
                                ),
                            })
                        }
                    />
                </Space>
            ),
        },
    ]
    const downloadFile = async (record) => {
        const response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.downloadCohortCSV,
            params: {
                discount_code: discountCode,
                file_name: uploadedCsvFile[0].name,
            },
        })
        if (response.success) {
            const url = response.data.data.download_url
            downloadFromURL(url, uploadedCsvFile[0].name)
        } else {
            message.error('File could not be downloaded, please try again!')
            return
        }
    }

    function handleSampleCsvClick() {
        const data = [{ phone: '1122500998' }, { phone: '1122322334' }, { phone: '2233233445' }]
        downloadToCSV(['phone'], ['Phone Numbers'], data, 'SampleCSVFormat')
    }
    function uploadCohortCsv(file) {
        let reader = new FileReader()
        reader.readAsText(file)
        reader.onload = (e) => {
            const content = e.target.result as string
            const rows = content
                .split('\n')
                .filter((row) => row.trim() !== '')
                .map((row) => row.trim())
            if (rows.length <= 1) {
                message.error('Empty CSV file. Please upload a file with phone numbers!')
                form.setFieldsValue({ customerSegmentCSV: null })
                return
            }
            if (rows[0] !== 'Phone Numbers') {
                message.error('Invalid CSV Format, please check sample CSV')
                form.setFieldsValue({ customerSegmentCSV: null })

                return
            }
            for (let i = 1; i < rows.length; i++) {
                if (!/^\d{10}$/.test(rows[i])) {
                    message.error(`Invalid phone number detected. Phone number must be of 10 digits - ${rows[i]}`)
                    form.setFieldsValue({ customerSegmentCSV: null })

                    return
                }
            }
            form.setFieldsValue({ customerSegmentCSV: [file] })
        }
    }
    useEffect(() => {
        ;(async () => {
            const segments = await dispatch(fetchCustomerSegments())
            if (segments.success && segments.data.data) {
                setCustomerSegments(segments.data.data.map((segment: any) => segment.node))
            }
        })()
    }, [])

    return (
        <Form
            form={form}
            layout='vertical'
            requiredMark='optional'
            onValuesChange={(changedValues, allValues) => {
                dispatch(updateSummary(allValues))
            }}
        >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Form.Item
                        required
                        rules={[{ required: true, message: 'Please select customer eligibility!' }]}
                        label='Customer eligibility'
                        name='customerEligibility'
                        className='label-bold'
                    >
                        <Radio.Group>
                            <Space direction='vertical'>
                                <Radio value={'all'}>All Customers</Radio>
                                <Radio value={'newCustomers'}>New Customers Only</Radio>
                                <Radio value={'existingCustomers'}>Existing Customers Only</Radio>
                                <Radio value={'shopifySegments'}>Shopify Customer Segments</Radio>
                                <Radio value={'specificCustomers'}>Specific Customers</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                    {customerEligibility === 'shopifySegments' || customerEligibility === 'specificCustomers' ? (
                        <Row gutter={[12, 12]} className='my-3'>
                            {customerEligibility === 'specificCustomers' ? (
                                <>
                                    <Col span={12}>
                                        <Form.Item
                                            label='How do you want to identify users in the file?'
                                            name='specificCustomersType'
                                            className='label-bold'
                                            rules={[{ required: true, message: 'Please select customer identity!' }]}
                                        >
                                            <Radio.Group>
                                                <Radio value={'include'}>Include Customers</Radio>
                                                <Radio value={'exclude'}>Exclude Customers</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} className='flex items-end flex-wrap gap-1 justify-center'>
                                        <Form.Item
                                            name='customerSegmentCSV'
                                            rules={[{ required: true, message: 'Please upload a CSV!' }]}
                                            valuePropName='list'
                                            getValueFromEvent={(e) => {
                                                if (Array.isArray(e)) {
                                                    return e
                                                }
                                                return e && e.fileList
                                            }}
                                        >
                                            <Upload
                                                accept='.csv'
                                                maxCount={1}
                                                fileList={uploadedCsvFile}
                                                beforeUpload={async (file) => {
                                                    uploadCohortCsv(file)
                                                    return false
                                                }}
                                                showUploadList={{
                                                    showPreviewIcon: uploadedCsvFile?.[0]?.alreadyUploaded,
                                                }}
                                                onPreview={(file: any) => {
                                                    if (file.alreadyUploaded) {
                                                        downloadFile(uploadedCsvFile)
                                                    }
                                                }}
                                            >
                                                {!uploadedCsvFile?.length && (
                                                    <Button className='text-primary'>
                                                        <UploadOutlined /> Upload a CSV
                                                    </Button>
                                                )}
                                            </Upload>
                                        </Form.Item>
                                        {!uploadedCsvFile?.length && (
                                            <Button onClick={handleSampleCsvClick} type='link' className='text-primary'>
                                                <FileAddOutlined /> Download Sample CSV
                                            </Button>
                                        )}
                                    </Col>
                                </>
                            ) : (
                                customerEligibility === 'shopifySegments' && (
                                    <>
                                        <Col span={24} className='flex flex-col items-stretch justify-end'>
                                            <p className='text-sm font-semibold w-full pb-2'>Shopify Segments</p>
                                            <Select
                                                mode='multiple'
                                                className='w-full'
                                                placeholder='Select Segments'
                                                value={
                                                    selectedSegments?.segmentData?.map?.(
                                                        (segment: any) => segment.id,
                                                    ) || []
                                                }
                                                onChange={(value) => {
                                                    const segmentIds =
                                                        selectedSegments?.segmentData?.map(
                                                            (segment: any) => segment.id,
                                                        ) || []
                                                    const segmentIdsToAdd = value.filter(
                                                        (v: any) => !segmentIds.includes(v),
                                                    )
                                                    const segmentToAdd = customerSegments
                                                        .filter((segment: any) => segmentIdsToAdd.includes(segment.id))
                                                        .map((segment: any) => segment.id)
                                                    const segmentIdsToRemove = segmentIds.filter(
                                                        (s: any) => !value.includes(s),
                                                    )
                                                    const segmentToRemove = selectedSegments?.segmentData
                                                        ?.filter((segment: any) =>
                                                            segmentIdsToRemove.includes(segment.id),
                                                        )
                                                        .map((segment: any) => segment.id)
                                                    const segments =
                                                        customerSegments.filter((segment: any) =>
                                                            value.includes(segment.id),
                                                        ) || []
                                                    form.setFieldsValue({
                                                        selectedSegments: {
                                                            add: segmentToAdd,
                                                            remove: segmentToRemove,
                                                            segmentData: segments,
                                                        },
                                                    })
                                                }}
                                                options={customerSegments.map((segment: any) => ({
                                                    label: segment.name,
                                                    value: segment.id,
                                                }))}
                                            />
                                            <Form.Item
                                                name='selectedSegments'
                                                className='pl-4 hide-control-input'
                                                rules={[
                                                    { required: true, message: 'Please select segments!' },
                                                    {
                                                        validator: (rule, value) => {
                                                            if (!value?.segmentData?.length) {
                                                                return Promise.reject('Please select segments!')
                                                            }
                                                            return Promise.resolve()
                                                        },
                                                    },
                                                ]}
                                            >
                                                <></>
                                            </Form.Item>
                                        </Col>
                                    </>
                                )
                            )}
                        </Row>
                    ) : customerEligibility === 'newCustomers' || customerEligibility === 'existingCustomers' ? (
                        <Form.Item
                            rootClassName='my-3'
                            label='How do you want to identify these users?'
                            name='customerSegmentSource'
                            className='label-bold'
                            rules={[{ required: true, message: 'Please select customer segment source!' }]}
                        >
                            <Radio.Group>
                                <Radio value={'phone'}>Phone</Radio>
                                <Radio value={'email'}>Email</Radio>
                            </Radio.Group>
                        </Form.Item>
                    ) : null}
                    {customerEligibility !== 'all' && (
                        <Alert className='my-3' message={infoTexts[customerEligibility]} type='info' showIcon />
                    )}
                </Col>
                <Col span={24} className='flex flex-col'>
                    <p className='text-sm font-semibold w-full pb-2 '>Sales channel control</p>
                    <Form.Item name={'salesChannelWebsite'} valuePropName='checked'>
                        <Checkbox>Website</Checkbox>
                    </Form.Item>
                    <Form.Item name={'salesChannelMobileApplication'} valuePropName='checked'>
                        <Checkbox>Mobile Application</Checkbox>
                    </Form.Item>
                    <Alert
                        className='my-3'
                        message={
                            <p>
                                GoKwik checkout needs to be active on your mobile app to control discounts on mobile app
                                specifically. <a href='#'>Learn more</a>.
                            </p>
                        }
                        type='info'
                        showIcon
                    />
                </Col>
                {!isBulkDiscount && (
                    <Col span={24}>
                        <div className='flex w-full items-center gap-x-2 pb-2'>
                            <p className='text-sm font-semibold'>
                                Do you want this to work on specific UTM parameters?
                            </p>
                            <Form.Item name={'enableUtmParameters'} valuePropName='checked'>
                                <Switch />
                            </Form.Item>
                        </div>
                        {enableUtmParameters && (
                            <>
                                <Row gutter={[12, 12]} align={'bottom'}>
                                    <Col span={8}>
                                        <Input
                                            label='UTM Source'
                                            value={utmParamInputs.utm_source}
                                            onChange={(e) =>
                                                setUtmParamInputs({ ...utmParamInputs, utm_source: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <Input
                                            label='UTM Medium'
                                            value={utmParamInputs.utm_medium}
                                            onChange={(e) =>
                                                setUtmParamInputs({ ...utmParamInputs, utm_medium: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <Input
                                            label='UTM Campaign'
                                            value={utmParamInputs.utm_campaign}
                                            onChange={(e) =>
                                                setUtmParamInputs({ ...utmParamInputs, utm_campaign: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <Input
                                            label='UTM Term'
                                            value={utmParamInputs.utm_term}
                                            onChange={(e) =>
                                                setUtmParamInputs({ ...utmParamInputs, utm_term: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <Input
                                            label='UTM Content'
                                            value={utmParamInputs.utm_content}
                                            onChange={(e) =>
                                                setUtmParamInputs({ ...utmParamInputs, utm_content: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col span={6}>
                                        <Button
                                            onClick={() => {
                                                const noValue = !utmParamInputs.utm_source.trim()
                                                if (noValue) {
                                                    message.error('UTM Source is required')
                                                    return
                                                }
                                                const findDuplicate = utmParameters?.some(
                                                    (param) =>
                                                        param.utm_source?.toLowerCase?.().trim() ===
                                                            utmParamInputs.utm_source.toLowerCase().trim() &&
                                                        param.utm_medium?.toLowerCase?.().trim() ===
                                                            utmParamInputs.utm_medium.toLowerCase().trim() &&
                                                        param.utm_campaign?.toLowerCase?.().trim() ===
                                                            utmParamInputs.utm_campaign.toLowerCase().trim() &&
                                                        param.utm_term?.toLowerCase?.().trim() ===
                                                            utmParamInputs.utm_term.toLowerCase().trim() &&
                                                        param.utm_content?.toLowerCase?.().trim() ===
                                                            utmParamInputs.utm_content.toLowerCase().trim(),
                                                )
                                                if (findDuplicate) {
                                                    message.error(
                                                        'UTM parameters cannot be the same as an existing entr',
                                                    )
                                                    return
                                                }
                                                setUtmParamInputs({
                                                    utm_source: '',
                                                    utm_medium: '',
                                                    utm_campaign: '',
                                                    utm_term: '',
                                                    utm_content: '',
                                                })
                                                form.setFieldsValue({
                                                    utmParameters: [
                                                        ...(utmParameters || []),
                                                        {
                                                            utm_source: utmParamInputs.utm_source.trim(),
                                                            utm_medium: utmParamInputs.utm_medium.trim(),
                                                            utm_campaign: utmParamInputs.utm_campaign.trim(),
                                                            utm_term: utmParamInputs.utm_term.trim(),
                                                            utm_content: utmParamInputs.utm_content.trim(),
                                                        },
                                                    ],
                                                })
                                            }}
                                        >
                                            Add UTM
                                        </Button>
                                    </Col>
                                    <Col span={24}>
                                        <Table
                                            dataSource={utmParameters}
                                            columns={columns}
                                            pagination={{
                                                pageSize: 10,
                                                total: utmParameters?.length,
                                                showSizeChanger: false,
                                                position: ['none', 'bottomRight'],
                                                showTotal: (total, range) =>
                                                    `Showing ${range[0]}-${range[1]} of ${total} Records`,
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Form.Item
                                    className='pl-4 hide-control-input'
                                    name={'utmParameters'}
                                    rules={[{ required: true, message: 'Please add UTM parameters' }]}
                                />
                            </>
                        )}
                        <Alert
                            className='my-3'
                            message='Enabling this will ensure that this discount will only applicable on specific UTMs.'
                            type='info'
                            showIcon
                        />
                    </Col>
                )}
            </Row>
        </Form>
    )
}

export default DiscountCustomerEligibility
