import { Col, Divider, Form, Input, InputNumber, Modal, Radio, Row, Space } from 'gokwik-ui-kit'
import ProductSearch from '../productSearch'
import SetupDiscountedCartItems from './discountedCartItems'
import SetupBundleDiscounts from './bundleDiscount'
import SetupShippingDiscount from './shippingDiscount'
import SetupDiscountCartItems from './discountCartItems'
import SetupTieredDiscounts from './tieredDiscount'
import { useAppDispatch } from '@library/utilities/hooks'
import {
    fetchShopifyDiscountsDataAsync,
    setDiscountData,
    setSummary,
    updateDiscountData,
    updateSummary,
} from '@store/discounts'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectDiscountCodeData } from '@store/discounts/selectors'
import { useLocation, useParams } from 'react-router-dom'
import {
    bulkDiscountInitialValues,
    DiyDiscountVariants,
    manualDiscountInitialValues,
} from '@library/utilities/constants/constants'

const SetupDiscount = ({ form }: { form: any }) => {
    const discountType = Form.useWatch('discountType', form)
    const minimumRequirements = Form.useWatch('minimumRequirements', form)
    const discountApplicableOn = Form.useWatch('discountApplicableOn', form)
    const discountApplicableOnItems = Form.useWatch('discountApplicableOnItems', form)
    const topUpGifts = Form.useWatch('topUpGifts', form)
    const discountCode = Form.useWatch('discountCode', form)
    const topUpGiftsData = Form.useWatch('topUpGiftsData', form)
    const dispatch = useAppDispatch()
    const discountData = useSelector(selectDiscountCodeData)
    const [discountVerified, setDiscountVerified] = useState(false)
    const routeParams = useParams<{ id: string }>()
    const isBulk = useLocation().pathname.includes('bulk')

    useEffect(() => {
        let timer
        if (discountCode?.trim() && discountCode.trim()?.toLowerCase() !== discountData?.title) {
            timer = setTimeout(async () => {
                const res = await dispatch(fetchShopifyDiscountsDataAsync({ title: discountCode })).unwrap()
                if (res?.discountProgress === 'continue' && !discountVerified) {
                    dispatch(
                        updateSummary({
                            discountCode,
                            ...(isBulk ? bulkDiscountInitialValues : manualDiscountInitialValues),
                        }),
                    )
                    setDiscountVerified(true)
                } else if (res.discountProgress === 'continue') {
                    dispatch(
                        updateSummary({
                            discountCode,
                            ...(form.getFieldsValue() || {}),
                        }),
                    )
                }

                if (!res) {
                    return Promise.reject('Discount set does not exist')
                } else {
                    return Promise.resolve()
                }
            }, 800)
        }
        return () => clearTimeout(timer)
    }, [discountCode])

    useEffect(() => {
        if (discountType === 'discountedCartItems') {
            form.setFieldsValue({
                ...(minimumRequirements === 'noMinimum' && { minimumRequirements: '' }),
                ...(discountApplicableOn === 'allProducts' && { discountApplicableOn: '' }),
            })
        } else if (['bundleDiscount', 'tieredDiscount'].includes(discountType)) {
            form.setFieldsValue({
                ...(discountApplicableOn === 'allProducts' && { discountApplicableOn: '' }),
                ...(discountType === 'tieredDiscount' &&
                    !discountData.tieredDiscountCategory && { tieredDiscountCategory: 'volume' }),
            })
        }
    }, [discountType])
    useEffect(() => {
        if (discountData?.discountProgress === 'exists') {
            form.setFields([
                {
                    name: 'discountCode',
                    errors: ['Discount code already exists'],
                },
            ])
        } else if (discountData?.discountProgress === 'continue' && discountData.discountCode) {
            setDiscountVerified(true)
            form.setFields([
                {
                    name: 'discountCode',
                    errors: [],
                },
            ])
        }
    }, [discountData, form])

    return (
        <Form
            onValuesChange={(changedValues, allValues) => {
                !changedValues.discountCode && dispatch(updateSummary(allValues))
            }}
            form={form}
            layout='vertical'
            requiredMark='optional'
        >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Form.Item
                        label={isBulk ? 'Discount Set Title' : 'Discount Code'}
                        name='discountCode'
                        className='label-bold'
                        validateTrigger='onChange'
                        validateDebounce={800}
                        rules={[
                            { required: true, message: 'Please input your discount code!' },
                            {
                                validator: (_, value) => {
                                    if (value && value.trim() !== value) {
                                        return Promise.reject(
                                            'Discount code should not have leading or trailing spaces',
                                        )
                                    }
                                    if (discountData?.discountProgress === 'exists') {
                                        return Promise.reject('Discount code already exists')
                                    }
                                    return Promise.resolve()
                                },
                            },
                        ]}
                    >
                        <Input disabled={isBulk && discountData?.status?.toLowerCase() === 'active'} />
                    </Form.Item>
                    <span className='text-black/40'>
                        {isBulk
                            ? 'This discount set will define the behaviour of all code generated against this set.'
                            : 'Customer must enter this code at checkout'}
                    </span>
                </Col>
                {discountVerified && (
                    <>
                        <Col span={24}>
                            <Form.Item
                                className='label-bold'
                                label='What should this discount do?'
                                name='discountType'
                                rules={[{ required: true, message: 'Please select discount type!' }]}
                            >
                                <Radio.Group
                                    disabled={
                                        (routeParams.id && discountData?.status?.toLowerCase() !== 'draft') ||
                                        (!discountData.noImport && discountData.shopifyDiscountId)
                                    }
                                >
                                    <Space direction='vertical'>
                                        {(isBulk
                                            ? DiyDiscountVariants.filter((variant) => variant.key !== 'tieredDiscount')
                                            : DiyDiscountVariants
                                        ).map((variant) => (
                                            <Radio key={variant.key} value={variant.key}>
                                                {variant.value}
                                            </Radio>
                                        ))}
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                            <Divider className='my-3' />
                            <Form.Item
                                className='label-bold'
                                name={'minimumRequirements'}
                                label='What are the minimum purchase requirements?'
                                rules={[{ required: true, message: 'Please select minimum requirements' }]}
                            >
                                <Radio.Group>
                                    <Radio value='noMinimum' disabled={discountType === 'discountedCartItems'}>
                                        No Minimum Requirements
                                    </Radio>
                                    <Radio value='minimumAmount'>Minimum Purchase Amount</Radio>
                                    <Radio value='minimumQuantity'>Minimum Purchase Quantity</Radio>
                                </Radio.Group>
                            </Form.Item>
                            {minimumRequirements && minimumRequirements !== 'noMinimum' && (
                                <Form.Item
                                    label='Value'
                                    name='discountValue'
                                    className='mt-2'
                                    rules={[
                                        {
                                            required: minimumRequirements !== 'noMinimum',
                                            message: 'Please input your minimum requirement  value!',
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        prefix={minimumRequirements === 'minimumAmount' && '₹'}
                                        className='w-48'
                                        min={1}
                                    />
                                </Form.Item>
                            )}

                            <Divider className={discountType === 'shippingDiscount' ? 'mt-3 mb-0' : 'my-3'} />
                            {discountType !== 'shippingDiscount' && (
                                <>
                                    <ProductSearch
                                        searchType={
                                            discountApplicableOn === 'specificCollections' ? 'collections' : 'products'
                                        }
                                        items={discountApplicableOnItems}
                                        collateProducts
                                        hideSearch={discountApplicableOn === 'allProducts' || !discountApplicableOn}
                                        onAdd={(items) => {
                                            const itemType =
                                                discountApplicableOn === 'specificCollections'
                                                    ? 'collections'
                                                    : 'products'
                                            const itemsRecentlyRemoved = items.filter((item) =>
                                                discountApplicableOnItems?.[itemType]?.remove?.includes(item.id),
                                            )
                                            form.setFieldsValue({
                                                discountApplicableOnItems: {
                                                    ...(discountApplicableOnItems || {}),
                                                    [itemType]: {
                                                        ...(discountApplicableOnItems?.[itemType] || {}),
                                                        remove: discountApplicableOnItems?.[itemType]?.remove?.filter(
                                                            (id) =>
                                                                !itemsRecentlyRemoved.find((item) => item.id === id),
                                                        ),
                                                        add: [
                                                            ...(discountApplicableOnItems?.[itemType]?.add || []),
                                                            ...items
                                                                .filter(
                                                                    (addedItem) =>
                                                                        !itemsRecentlyRemoved.find(
                                                                            (item) => item.id === addedItem.id,
                                                                        ),
                                                                )
                                                                .map((item) => item.id),
                                                        ],
                                                        data: [
                                                            ...(
                                                                discountApplicableOnItems?.[itemType]?.data || []
                                                            ).filter((item) =>
                                                                items.every((addedItem) => addedItem.id !== item.id),
                                                            ),
                                                            ...items.filter((item) =>
                                                                item.variants ? !!item.variants.length : true,
                                                            ),
                                                        ],
                                                    },
                                                },
                                            })
                                        }}
                                        onRemove={(removedItem) => {
                                            const itemType =
                                                discountApplicableOn === 'specificCollections'
                                                    ? 'collections'
                                                    : 'products'
                                            const isItemRecentlyAdded = discountApplicableOnItems[
                                                itemType
                                            ].add?.includes(removedItem.id)

                                            form.setFieldsValue({
                                                discountApplicableOnItems: {
                                                    ...(discountApplicableOnItems || {}),

                                                    [itemType]: {
                                                        ...discountApplicableOnItems[itemType],
                                                        ...(isItemRecentlyAdded
                                                            ? {
                                                                  add: discountApplicableOnItems[itemType].add.filter(
                                                                      (id) => id !== removedItem.id,
                                                                  ),
                                                              }
                                                            : {
                                                                  remove: [
                                                                      ...(discountApplicableOnItems[itemType].remove ||
                                                                          []),
                                                                      removedItem.id,
                                                                  ],
                                                              }),
                                                        data: discountApplicableOnItems[itemType].data.filter(
                                                            (item) => item.id !== removedItem.id,
                                                        ),
                                                    },
                                                },
                                            })
                                        }}
                                    >
                                        <div className='flex flex-col'>
                                            <p className='text-sm font-semibold w-full pb-2'>
                                                What should this discount be applicable on?
                                            </p>
                                            <Form.Item name={'discountApplicableOn'}>
                                                <Radio.Group>
                                                    <Radio
                                                        value='allProducts'
                                                        disabled={[
                                                            'discountedCartItems',
                                                            'bundleDiscount',
                                                            'tieredDiscount',
                                                        ].includes(discountType)}
                                                    >
                                                        All Products
                                                    </Radio>
                                                    <Radio value='specificCollections'>Specific Collections</Radio>
                                                    <Radio value='specificProducts'>Specific Products</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </div>
                                    </ProductSearch>
                                    {discountApplicableOn !== 'allProducts' && (
                                        <Form.Item
                                            name='discountApplicableOnItems'
                                            className='pl-4 hide-control-input'
                                            rules={[
                                                {
                                                    required: discountApplicableOn !== 'allProducts',
                                                    message:
                                                        'Please add ' +
                                                        (discountApplicableOn === 'specificCollections'
                                                            ? 'collections'
                                                            : 'products'),
                                                },
                                                {
                                                    validator: (_, value) => {
                                                        if (
                                                            !value?.products?.data?.length &&
                                                            !value?.collections?.data?.length
                                                        ) {
                                                            return Promise.reject(
                                                                'Please add ' +
                                                                    (discountApplicableOn === 'specificCollections'
                                                                        ? 'collections'
                                                                        : 'products'),
                                                            )
                                                        }
                                                        return Promise.resolve()
                                                    },
                                                },
                                            ]}
                                        >
                                            <> </>
                                        </Form.Item>
                                    )}
                                </>
                            )}
                        </Col>
                        {discountType === 'cartItemsDiscount' && <SetupDiscountCartItems form={form} />}
                        {discountType === 'discountedCartItems' && <SetupDiscountedCartItems form={form} />}
                        {discountType === 'bundleDiscount' && <SetupBundleDiscounts form={form} />}
                        {discountType === 'tieredDiscount' && <SetupTieredDiscounts form={form} />}
                        {discountType === 'shippingDiscount' && <SetupShippingDiscount form={form} />}
                        <Col span={24}>
                            <ProductSearch
                                hideSearch={!topUpGifts}
                                searchType='products'
                                items={topUpGiftsData}
                                showQuantity
                                onAdd={(items) => {
                                    form.setFieldsValue({
                                        topUpGiftsData: {
                                            products: { data: [...(topUpGiftsData?.products?.data || []), ...items] },
                                        },
                                    })
                                }}
                                onRemove={(removedItem) => {
                                    form.setFieldsValue({
                                        topUpGiftsData: {
                                            products: {
                                                data: topUpGiftsData.products.data.filter(
                                                    (item) => item.variant_id !== removedItem.variant_id,
                                                ),
                                            },
                                        },
                                    })
                                }}
                            >
                                <div className='flex flex-col'>
                                    <p className='text-sm font-semibold w-full pb-2'>Top-up with free gift?</p>
                                    <Form.Item
                                        name='topUpGifts'
                                        rules={[{ required: true, message: 'Please select free gift' }]}
                                    >
                                        <Radio.Group>
                                            <Radio value={false}>No</Radio>
                                            <Radio value={true}>Yes</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                            </ProductSearch>
                            {topUpGifts && (
                                <Form.Item
                                    name='topUpGiftsData'
                                    className='pl-4 hide-control-input'
                                    rules={[
                                        { required: topUpGifts, message: 'Please select free gift' },
                                        {
                                            validator: (_, value) => {
                                                if (value?.products?.data?.length === 0) {
                                                    return Promise.reject('Please select free gift')
                                                }
                                                return Promise.resolve()
                                            },
                                        },
                                    ]}
                                >
                                    <> </>
                                </Form.Item>
                            )}
                        </Col>
                    </>
                )}
            </Row>
            <Modal
                title='Duplicate Discount Code'
                open={discountData?.discountProgress === 'import'}
                okText='Yes! Import'
                cancelText={discountVerified ? `No! I'll continue` : `No! I'll start fresh`}
                onOk={() => {
                    dispatch(
                        updateDiscountData({
                            isImported: true,
                            discountProgress: 'continue',
                        }),
                    )
                }}
                onCancel={() => {
                    if (discountVerified) {
                        dispatch(updateDiscountData({ discountProgress: 'continue', discountCode: '', noImport: true }))
                    } else {
                        dispatch(updateDiscountData({ discountProgress: '', noImport: true }))
                        setDiscountVerified(false)
                    }
                    form.setFieldsValue({ discountCode: '' })
                }}
            >
                <p>
                    Looks like <span className='font-bold'>'{discountCode}'</span> already exist on Shopify, do you wish
                    to import the discounts settings?
                </p>
            </Modal>
        </Form>
    )
}

export default SetupDiscount
