// Import Dependencies
import { navigateToUrl } from 'single-spa'
import React, { useState, useEffect } from 'react'

// Import Images
import logoGokwik from '@library/images/common/logo-gokwik-business-suit.svg'

// Import Styles
import './login.css'

// Import Utilities
import useAESEncryption from '@library/utilities/authorization/encryption'
import { makeAPICall } from '@gokwik/utilities'
import { Button, Col, Row, Input, message } from 'gokwik-ui-kit'
import EmailIcon from '@library/images/icons/emailIcon'
import PasswordIcon from '@library/images/icons/passwordIcon'
import { redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { sendVerifyEmailOTP, setSignUpEmail, signin } from '@store/user'
import LoginLayout from '@library/components/loginLayout'
import { getSignUpEmail } from '@store/user/selectors'
import { useAppDispatch } from '@library/utilities/hooks'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { useNavigate } from 'react-router-dom'

export default function () {
    const [step, setStep] = useState(0)
    const [isEmailValid, setIsEmailValid] = useState(true)
    const [credentialsData, setCredentialsData] = useState({ email: '', password: '' })
    const [current, setCurrent] = useState('emailCheck')
    // const [error, setError] = useErrorContext();
    // const router = useRoute();
    const signupEmail = useSelector(getSignUpEmail)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        let page = localStorage.getItem('currentPage')
        if (page == 'password') {
            localStorage.setItem('currentPage', 'emailCheck')
        } else {
            localStorage.setItem('currentPage', 'emailCheck')
            setCurrent('emailCheck')
        }
        window.parent.postMessage({ data: { page: 'login_page' } }, '*')
    }, [current])

    // useEffect(() => {
    //     if (signupEmail) setFormData({ email: signupEmail, password: '' })
    // }, [signupEmail])

    const handleSignin = async (e) => {
        e.preventDefault()
        logEvent('login_page_password_entered', 'click', 'login', credentialsData.email)
        if (credentialsData.password && credentialsData.password.length < 6) {
            message.error('Invalid password')
            return
        } else if (!credentialsData.password) {
            message.error('Please enter password')
            return
        }
        localStorage.setItem('email', credentialsData.email)
        dispatch(signin(credentialsData, navigate))
    }

    useEffect(() => {
        const customEvent = new CustomEvent('loader', {
            detail: {
                data: { reset: true },
            },
        })
        window.dispatchEvent(customEvent)
    }, [])

    useEffect(() => {
        logEvent('login_page_landed', 'pageLoad', 'login', credentialsData.email)
        sessionStorage?.setItem('landed_otp_page', 'false')
    }, [])

    const forgotPassword = () => {
        logEvent('login_page_password_reset_clicked', 'click', 'login', credentialsData?.email)

        navigateToUrl('/forgot-password')
        // localStorage.setItem("currentPage", "forgot-password");
        // setCurrent("forgot-password");
    }

    function handleEmailApiResponse(resMessage, status_code) {
        // console.log(message, status_code)

        if (status_code === 200 && resMessage === 'success') {
            logEvent('login_page_email_submitted', 'click', 'login', credentialsData?.email)
            logEvent('login_page_merchant_exists', 'click', 'login', credentialsData?.email)
            logEvent('login_page_password_landed', 'click', 'login', credentialsData?.email)
            setStep((prev) => prev + 1)
        } else if (status_code === 200 && resMessage === 'Otp Sent Successfully') {
            console.log('Success:', resMessage)
        } else if (status_code === 400 && resMessage === 'Your Account Is Blocked OR Suspended') {
            message.error(resMessage)
        } else if (status_code === 401 && resMessage === 'Invalid Credentials') {
            localStorage.setItem('email', credentialsData.email)
            navigateToUrl('/signup')
        } else {
            message.warning('Unable to proceed with your request. Try later!')
        }
    }

    const validateUserEmail = async () => {
        if (!isEmailValid) {
            message.error('Enter valid email address')
            return
        }

        logEvent('login_page_email_entered', 'click', 'login', credentialsData.email)
        const { email } = credentialsData

        if (email) {
            try {
                let response = await makeAPICall({
                    method: 'post',
                    url: process.env.REACT_APP_BASE_URL + '/api/dashboard/user/check-user',
                    payload: { email: email },
                })

                let message, status_code

                if (response?.response?.data?.message && response?.response?.data?.status_code) {
                    message = response?.response?.data?.message
                    status_code = response?.response?.data?.status_code
                } else if (response?.data?.message && response?.data?.status_code) {
                    message = response?.data?.message
                    status_code = response?.data?.status_code
                }

                handleEmailApiResponse(message, status_code)
            } catch (error) {
                message.warning('Something went wrong ! Try Later.')
                console.log(error)
            }
        } else {
            message.error('Please enter email')
        }
    }

    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        return emailRegex.test(email)
    }

    const handleEmailChange = (e) => {
        const email = e.target.value
        setCredentialsData((prev) => ({ ...prev, email }))
        setIsEmailValid(validateEmail(email))
    }

    return (
        <LoginLayout
            titleContent={
                <>
                    {' '}
                    <p className='text-3xl text-[#060322] font-normal leading-normal inter tracking-tight'>
                        Welcome Back
                    </p>
                    <svg xmlns='http://www.w3.org/2000/svg' width='398' height='2' viewBox='0 0 398 2' fill='none'>
                        <path d='M1 1L397 0.999965' stroke='#828282' stroke-linecap='round' />
                    </svg>
                    <p className='font-normal text-normal'>For secure access, please enter your details below.</p>
                </>
            }
        >
            <div className='   flex flex-col '>
                <form
                    className='w-full flex flex-col gap-y-6'
                    onSubmit={(e) => {
                        e?.preventDefault()
                    }}
                >
                    <div className='flex flex-col gap-y-3'>
                        <h4 className='body text-[#002547] text-sm !leading-[22px] font-medium'>Email</h4>
                        <Input
                            prefix={<EmailIcon />}
                            value={credentialsData?.email}
                            onChange={handleEmailChange}
                            placeholder='example@email.com'
                            type='email'
                            name='email'
                            autoComplete='email'
                            disabled={step === 1}
                        />
                    </div>

                    {step === 1 && (
                        <div className='flex flex-col gap-y-3'>
                            <h4 className='body text-[#002547] text-sm !leading-[22px] font-medium'>Password</h4>
                            <Input.Password
                                prefix={<PasswordIcon />}
                                value={credentialsData.password}
                                placeholder=''
                                onChange={(e) => setCredentialsData((prev) => ({ ...prev, password: e.target.value }))}
                            />
                            <span
                                className='text-right w-max inter cursor-pointer self-end text-[#004B8D] text-sm'
                                onClick={forgotPassword}
                            >
                                Forgot Password?
                            </span>
                        </div>
                    )}
                    <Button
                        variant='primary'
                        htmlType='submit'
                        disabled={!credentialsData.email && !credentialsData.password}
                        className='next'
                        onClick={(e) => {
                            if (!step) {
                                validateUserEmail()
                            } else if (step === 1) handleSignin(e)
                        }}
                    >
                        <h3 className='body-bold'>Next</h3>
                    </Button>
                </form>
            </div>
        </LoginLayout>
    )
}
