import { updateBreadcrumbs } from '@gokwik/utilities'
import DiscountCombinations from '@library/components/discounts/combination'
import Summary from '@library/components/discounts/summary'
import { automaticDiscountInitialValues } from '@library/utilities/constants/constants'
import { useAppDispatch } from '@library/utilities/hooks'
import {
    emptyDiscountData,
    fetchDiscountDataAsync,
    fetchShopifyDiscountsDataAsync,
    saveDiscount,
    updateSummary,
} from '@store/discounts'
import { selectDiscountCodeData } from '@store/discounts/selectors'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import {
    Alert,
    Button,
    Col,
    DeleteOutlined,
    Form,
    Input,
    message,
    Radio,
    Row,
    Steps,
    Switch,
    Table,
} from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
const matchTypesValues = {
    'exact match': 1,
    'starts with': 2,
    'ends with': 3,
    contains: 4,
}
const CreateAutomaticDiscounts = () => {
    const [form]: [any] = Form.useForm()
    const discountCode = Form.useWatch('discountCode', form)
    const dispatch = useAppDispatch()
    const discountData = useSelector(selectDiscountCodeData)
    const routeParams = useParams<{ id: string }>()
    const navigate = useNavigate()
    const enableCombineManualDiscounts = Form.useWatch('enableCombineManualDiscounts', form)
    const enableOverwriteManualDiscounts = Form.useWatch('enableOverwriteManualDiscounts', form)
    const combineManualDiscounts = Form.useWatch('combineManualDiscounts', form)
    const overwriteManualDiscounts = Form.useWatch('overwriteManualDiscounts', form)

    const [combineDiscount, setCombineDiscount] = useState({
        discount_code: '',
        club_with_loyalty_points: true,
    })
    const [overWriteDiscount, setOverWriteDiscount] = useState({
        discount_code: '',
        club_with_loyalty_points: false,
    })

    const compareIfCodesOverlap = (combineManualDiscounts, overwriteManualDiscounts) => {
        const combineCodes = combineManualDiscounts?.map((item: any) => item.discount_code) || []
        const overwriteCodes = overwriteManualDiscounts?.map((item: any) => item.discount_code) || []
        const overlap =
            combineCodes.filter((code: any) => overwriteCodes.includes(code)) ||
            overwriteCodes.filter((code: any) => combineCodes.includes(code))
        return overlap.length > 0
    }

    const submitForm = async () => {
        try {
            // Validate the form fields
            await form.validateFields()
            // Get the form values
            const currentValues = form.getFieldsValue()
            const values = form.getFieldsValue(true)
            const isNotChanged =
                !!routeParams.id &&
                Object.keys(currentValues).every((key) => {
                    if (typeof currentValues[key] === 'object' && currentValues[key] !== null) {
                        return JSON.stringify(currentValues[key]) === JSON.stringify(discountData[key])
                    } else return currentValues[key] === discountData[key]
                })
            if (isNotChanged) {
                return
            }

            const res = await dispatch(
                saveDiscount(
                    {
                        ...values,
                        id: routeParams.id || discountData.id,
                        discountMethod: 'automatic',
                        subMethod: 'automatic',
                    },
                    'automatic',
                ),
            )
            if (res.success) {
                message.success('Discount saved successfully')
                navigate('/checkout/settings/discounts')
            }
        } catch (error) {
            message.error('Failed to save discount')
            console.error('Validation failed:', error)
        }
    }

    useEffect(() => {
        if (routeParams.id || discountData.isImported) {
            form.setFieldsValue(discountData)
        } else if (!discountCode)
            form.setFieldsValue({
                ...automaticDiscountInitialValues,
            })
    }, [discountData])

    useEffect(() => {
        return () => {
            dispatch(emptyDiscountData())
        }
    }, [])

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'checkout-settings',
                href: '/checkout/settings',
                text: 'Checkout Settings',
            },
            {
                key: 'discounts',
                href: '/checkout/settings/discounts',
                text: 'Discounts',
            },
            {
                key: 'automatic',
                href: routeParams.id
                    ? '/checkout/settings/discounts/edit/automatic/' + routeParams.id
                    : '/checkout/settings/discounts/create/automatic',
                text: 'Configure Automatic Discount',
            },
        ])
        routeParams.id &&
            dispatch(
                fetchDiscountDataAsync({
                    id: routeParams.id,
                }),
            )
    }, [routeParams.id])

    return (
        <Row gutter={[16, 16]}>
            <Col lg={24} xl={18}>
                <div className='w-full flex items-center justify-between mb-3'>
                    <div>
                        <span className='text-xl font-semibold'>Configure Automatic Discount</span>
                    </div>
                    <Button type='primary' onClick={() => form.submit()} className='ml-4'>
                        Save
                    </Button>
                </div>
                <div className='w-full rounded bg-neutrals-15 p-3 pt-6 discount-form'>
                    <Form
                        form={form}
                        onFinish={submitForm}
                        layout='vertical'
                        requiredMark='optional'
                        onValuesChange={(changedValues, allValues) => {
                            dispatch(updateSummary(allValues))
                        }}
                    >
                        <Row gutter={[16, 16]} className='mb-4'>
                            <Col span={24}>
                                <Form.Item
                                    label='Automatic Discount Code'
                                    name='discountCode'
                                    className='label-bold'
                                    rules={[{ required: true, message: 'Please input your discount code!' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <span className='text-black/40 mb-4'>
                                    Enter the automatic discount you want to configure
                                </span>
                                <Alert
                                    className='mt-2'
                                    message='Please make sure the discount is pre-created and tested.'
                                    type='info'
                                    showIcon
                                />
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <p className='text-sm font-semibold w-full '>Select a Match Type</p>
                                <span className='text-black/40 pb-2'>
                                    This defines the matching criteria for the above discount code
                                </span>
                                <Form.Item
                                    name='matchType'
                                    rules={[{ required: true, message: 'Please select a match type!' }]}
                                >
                                    <Radio.Group>
                                        {Object.entries(matchTypesValues).map((variant) => (
                                            <Radio rootClassName='capitalize' key={variant[1]} value={variant[1]}>
                                                {variant[0]}
                                            </Radio>
                                        ))}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label='Do you want this to be clubbed with manual discount codes?'
                                    name='enableCombineManualDiscounts'
                                    className='label-bold'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select automatic discounts combination!',
                                        },
                                        {
                                            validator: (_, value) => {
                                                if (
                                                    value === true &&
                                                    form.getFieldValue('enableOverwriteManualDiscounts') === true
                                                ) {
                                                    return Promise.reject(
                                                        'Cannot be clubbed and replace at the same time',
                                                    )
                                                }
                                                return Promise.resolve()
                                            },
                                        },
                                    ]}
                                >
                                    <Radio.Group>
                                        <Radio value={false}>No</Radio>
                                        <Radio value={true}>Yes</Radio>
                                        <Radio value={'specific'}>Only Specific Discount Codes</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {enableCombineManualDiscounts === 'specific' && (
                                    <>
                                        <Row gutter={[12, 12]} align={'bottom'}>
                                            <Col span={8}>
                                                <Input
                                                    placeholder='Enter code'
                                                    label='Enter Discount Code to Club'
                                                    value={combineDiscount.discount_code}
                                                    onChange={(e) =>
                                                        setCombineDiscount((prev) => ({
                                                            ...prev,
                                                            discount_code: e.target.value,
                                                        }))
                                                    }
                                                />
                                            </Col>

                                            <Col className='pb-2' span={6}>
                                                <label className='gk-label w-full'>Club with Loyalty points</label>
                                                <Switch
                                                    checked={combineDiscount.club_with_loyalty_points}
                                                    onChange={(value) =>
                                                        setCombineDiscount((prev) => ({
                                                            ...prev,
                                                            club_with_loyalty_points: value,
                                                        }))
                                                    }
                                                />
                                            </Col>

                                            <Col>
                                                <Button
                                                    onClick={() => {
                                                        if (!combineDiscount.discount_code) return
                                                        if (
                                                            combineManualDiscounts?.some(
                                                                (item: any) =>
                                                                    item.discount_code ===
                                                                    combineDiscount.discount_code,
                                                            )
                                                        ) {
                                                            message.error('Discount code already added')
                                                            return
                                                        }
                                                        if (
                                                            compareIfCodesOverlap(
                                                                [combineDiscount],
                                                                overwriteManualDiscounts,
                                                            )
                                                        ) {
                                                            message.error(
                                                                'Discount code already added in replacing codes',
                                                            )
                                                            return
                                                        }

                                                        form.setFieldsValue({
                                                            combineManualDiscounts: [
                                                                ...(combineManualDiscounts || []),
                                                                { ...combineDiscount },
                                                            ],
                                                        })
                                                        setCombineDiscount({
                                                            discount_code: '',
                                                            club_with_loyalty_points: true,
                                                        })
                                                    }}
                                                >
                                                    Add more
                                                </Button>
                                            </Col>
                                            <Col span={24}>
                                                <Table
                                                    columns={[
                                                        {
                                                            title: 'Allowed Codes',
                                                            dataIndex: 'discount_code',
                                                        },
                                                        {
                                                            title: 'Club with Loyalty points',
                                                            dataIndex: 'club_with_loyalty_points',
                                                            key: 'club_with_loyalty_points',
                                                            render: (text: any) => (text ? 'Yes' : 'No'),
                                                        },
                                                        {
                                                            title: '',
                                                            dataIndex: 'action',
                                                            render: (text: any, record: any) => (
                                                                <DeleteOutlined
                                                                    className='cursor-pointer text-error-500'
                                                                    onClick={() => {
                                                                        form.setFieldsValue({
                                                                            combineManualDiscounts:
                                                                                combineManualDiscounts.filter(
                                                                                    (item: any) =>
                                                                                        JSON.stringify(item) !==
                                                                                        JSON.stringify(record),
                                                                                ),
                                                                        })
                                                                    }}
                                                                />
                                                            ),
                                                        },
                                                    ]}
                                                    dataSource={combineManualDiscounts}
                                                    pagination={{
                                                        pageSize: 10,
                                                        total: combineManualDiscounts?.length,
                                                        showSizeChanger: false,
                                                        position: ['none', 'bottomRight'],
                                                        showTotal: (total, range) =>
                                                            `Showing ${range[0]}-${range[1]} of ${total} Records`,
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Form.Item
                                            className='w-full'
                                            name={'combineManualDiscounts'}
                                            rules={[
                                                {
                                                    required: enableCombineManualDiscounts,
                                                    message: 'Please add discounts to combine!',
                                                },
                                            ]}
                                        ></Form.Item>
                                    </>
                                )}
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label='Do you want this to get replaced by manual discount codes?'
                                    name='enableOverwriteManualDiscounts'
                                    className='label-bold'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select automatic discounts combination!',
                                        },
                                        {
                                            validator: (_, value) => {
                                                if (
                                                    value === true &&
                                                    form.getFieldValue('enableCombineManualDiscounts') === true
                                                ) {
                                                    return Promise.reject(
                                                        'Cannot be clubbed and replace at the same time',
                                                    )
                                                }
                                                return Promise.resolve()
                                            },
                                        },
                                    ]}
                                >
                                    <Radio.Group>
                                        <Radio value={false}>No</Radio>
                                        <Radio value={true}>Yes</Radio>
                                        <Radio value={'specific'}>Only Specific Discount Codes</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {enableOverwriteManualDiscounts === 'specific' && (
                                    <>
                                        <Row gutter={[12, 12]} align={'bottom'}>
                                            <Col span={8}>
                                                <Input
                                                    placeholder='Enter code'
                                                    label='Enter Discount Code to Replace'
                                                    value={overWriteDiscount.discount_code}
                                                    onChange={(e) =>
                                                        setOverWriteDiscount((prev) => ({
                                                            ...prev,
                                                            discount_code: e.target.value,
                                                        }))
                                                    }
                                                />
                                            </Col>

                                            <Col className='pb-2' span={6}>
                                                <label className='gk-label w-full'>Replace with Loyalty points</label>
                                                <Switch
                                                    checked={overWriteDiscount.club_with_loyalty_points}
                                                    onChange={(value) =>
                                                        setOverWriteDiscount((prev) => ({
                                                            ...prev,
                                                            club_with_loyalty_points: value,
                                                        }))
                                                    }
                                                />
                                            </Col>

                                            <Col>
                                                <Button
                                                    onClick={() => {
                                                        if (!overWriteDiscount.discount_code) return
                                                        if (
                                                            overwriteManualDiscounts?.some(
                                                                (item: any) =>
                                                                    item.discount_code ===
                                                                    overWriteDiscount.discount_code,
                                                            )
                                                        ) {
                                                            message.error('Discount code already added')
                                                            return
                                                        }
                                                        if (
                                                            compareIfCodesOverlap(combineManualDiscounts, [
                                                                overWriteDiscount,
                                                            ])
                                                        ) {
                                                            message.error(
                                                                'Discount code already added in clubbed codes',
                                                            )
                                                            return
                                                        }

                                                        form.setFieldsValue({
                                                            overwriteManualDiscounts: [
                                                                ...(overwriteManualDiscounts || []),
                                                                { ...overWriteDiscount },
                                                            ],
                                                        })

                                                        setOverWriteDiscount({
                                                            discount_code: '',
                                                            club_with_loyalty_points: false,
                                                        })
                                                    }}
                                                >
                                                    Add more
                                                </Button>
                                            </Col>
                                            <Col span={24}>
                                                <Table
                                                    columns={[
                                                        {
                                                            title: 'Allowed Code',
                                                            dataIndex: 'discount_code',
                                                            key: 'name',
                                                        },
                                                        {
                                                            title: 'Replace with Loyalty points',
                                                            dataIndex: 'club_with_loyalty_points',
                                                            key: 'club_with_loyalty_points',
                                                            render: (text: any) => (text ? 'Yes' : 'No'),
                                                        },
                                                        {
                                                            title: '',
                                                            dataIndex: 'action',
                                                            render: (text: any, record: any) => (
                                                                <DeleteOutlined
                                                                    className='cursor-pointer text-error-500'
                                                                    onClick={() => {
                                                                        form.setFieldsValue({
                                                                            overwriteManualDiscounts:
                                                                                overwriteManualDiscounts.filter(
                                                                                    (item: any) =>
                                                                                        JSON.stringify(item) !==
                                                                                        JSON.stringify(record),
                                                                                ),
                                                                        })
                                                                    }}
                                                                />
                                                            ),
                                                        },
                                                    ]}
                                                    dataSource={overwriteManualDiscounts}
                                                    pagination={{
                                                        pageSize: 10,
                                                        total: overwriteManualDiscounts?.length,
                                                        showSizeChanger: false,
                                                        position: ['none', 'bottomRight'],
                                                        showTotal: (total, range) =>
                                                            `Showing ${range[0]}-${range[1]} of ${total} Records`,
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Form.Item
                                            className='pl-4 hide-control-input'
                                            name={'overwriteManualDiscounts'}
                                            rules={[
                                                {
                                                    required: enableOverwriteManualDiscounts,
                                                    message: 'Please add discounts to replace!',
                                                },
                                            ]}
                                        ></Form.Item>
                                    </>
                                )}
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Col>
            <Col lg={12} xl={6}>
                <Summary />
            </Col>
        </Row>
    )
}

export default CreateAutomaticDiscounts
