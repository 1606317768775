import { DownOutlined, ArrowLeftOutlined, CloseOutlined, RightOutlined, PlusSquareFilled } from 'gokwik-ui-kit'
import { useState } from 'react'
import bag from '@library/images/bag.svg'
import stars from '@library/images/stars.svg'
import totalSavings from '@library/images/totalSavings.svg'
import truck from '@library/images/truck.svg'
import radioChecked from '@library/images/radio-checked.svg'
import radioUnchecked from '@library/images/radio-unchecked.svg'
import gokwikLogo from '@library/images/common/logo-gokwik-business-suit.svg'
import logoutButton from '@library/images/logout-button.svg'
import footerText from '@library/images/footer-text-gk.svg'
import { IShopifyMerchantConfig, IShopifyNewCheckoutUIConfig } from '@library/utilities/interface'
import { dummyDeliveryOptions, logoSources, sampleAddressList } from './constants'
import BannerPreview from './bannerSlider'

interface Props {
    newCheckoutUIConfig: IShopifyNewCheckoutUIConfig
    config: IShopifyMerchantConfig
}
const AddressPagePreview = ({ newCheckoutUIConfig, config }: Props) => {
    const [selectedDeliveryOption, setSelectedDeliveryOption] = useState(0)
    const [isSelectedAddress, setIsSelectedAddress] = useState(0)
    const [selectedAddress, setSelectedAddress] = useState(sampleAddressList[0])
    const [showPopup, setShowPopup] = useState(false)
    const [showCloseButton, setShowCloseButton] = useState(false)
    const updatedAddressList = sampleAddressList.slice(0, newCheckoutUIConfig?.textConfig?.address?.addressCount ?? 2)
    const deliveryOptions = config?.shippingConfig ?? dummyDeliveryOptions
    const banners = newCheckoutUIConfig?.textConfig?.otherSections?.brandBanner?.filter((banner) => banner.length)

    const onChangeAddress = () => {
        setShowPopup(true)
        setShowCloseButton(true)
    }
    return (
        <div className='relative'>
            <div className='text-2xl text-center font-semibold p-2'>Preview</div>
            <div className='bg-gray-200 shadow-md p-2 rounded-t-xl max-w-md w-full'>
                <div className='relative w-full flex items-center py-2'>
                    <div className='absolute left-1 top-4'>
                        <ArrowLeftOutlined />
                    </div>
                    <div className='mx-auto'>
                        <img
                            src={logoSources(config?.logo)[0]}
                            alt='logo'
                            height={40}
                            width={60}
                            onError={(e) => {
                                if (e.target instanceof HTMLImageElement) {
                                    const img = e.target
                                    const currentSrc = img.src
                                    const currentIndex = logoSources(config?.logo).indexOf(currentSrc)
                                    if (config?.logo && currentIndex < 3) {
                                        img.src = logoSources(config.logo)[currentIndex + 1]
                                    } else {
                                        img.src = gokwikLogo
                                    }
                                }
                            }}
                        />
                    </div>
                </div>

                {newCheckoutUIConfig?.uiConfig?.showBrandBanner && banners.length > 0 && (
                    <div className='-ml-2 -mr-2'>
                        <BannerPreview config={newCheckoutUIConfig} banners={banners} />
                    </div>
                )}

                <div className='bg-white rounded-xl p-4 mb-4'>
                    <div className='flex items-center justify-between'>
                        <p className='text-normal font-semibold'>
                            <img src={bag} height={'20'} alt='bag' className='mr-2' />
                            Order Summary
                        </p>
                        <p className='text-gray-700'>
                            3 items (₹300.00) <DownOutlined />
                        </p>
                    </div>
                </div>
                <div className='bg-white rounded-xl p-4 mb-4'>
                    <div className='flex items-center justify-between'>
                        <p className='text-normal font-semibold'>
                            <img src={totalSavings} height={'20'} alt='coupons' className='mr-2' />
                            Coupons/Gift Cards
                            <img src={stars} height={'20'} alt='stars' className='ml-2' />
                        </p>
                        <DownOutlined />
                    </div>
                </div>

                {/* delivery section */}
                <div className='my-4 rounded-xl bg-[#e6edf4]'>
                    <div className='auto p-4 rounded-xl border-gray-300 bg-white'>
                        <div className='flex flex-col gap-4'>
                            <div className='flex items-center justify-between'>
                                <div className='flex items-center gap-2 font-medium'>
                                    <img alt='Delivery truck icon' src={truck} height={'20'} />
                                    <div className='text-black'>Deliver To</div>
                                </div>
                                {updatedAddressList.length > 0 && (
                                    <button
                                        className='bg-white border-none focus:outline-none'
                                        onClick={onChangeAddress}
                                    >
                                        <div className='text-blue-500 text-sm font-semibold'>Change Address</div>
                                    </button>
                                )}
                            </div>
                            {updatedAddressList.length > 0 && (
                                <div style={{ border: '1px solid black' }} className='p-2 rounded-xl'>
                                    <div className='flex flex-col gap-2'>
                                        <div className='text-black break-words text-sm'>
                                            <span className='font-bold'>{selectedAddress.fullName} : </span>
                                            {selectedAddress?.address1},
                                            {selectedAddress?.city && `${selectedAddress.city}, `}
                                            {selectedAddress?.state && `${selectedAddress.state}, `}
                                            {selectedAddress?.country && `${selectedAddress.country}, `}
                                            {selectedAddress?.pincode}
                                        </div>
                                        <div className='text-gray-700 text-sm'>
                                            {selectedAddress?.phone && <span>(+91) {selectedAddress.phone}, </span>}
                                            {selectedAddress?.email}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='p-3 flex flex-col rounded-b-xl items-start justify-center gap-4 bg-[#eff5ff]'>
                        <div className='font-bold text-sm leading-4'>Delivery Options</div>
                        {deliveryOptions?.length > 0 && (
                            <div className='m-0 w-full max-h-[16dvh] overflow-y-auto flex flex-col gap-4 pr-1'>
                                {deliveryOptions.map((deliveryDetails, index) => (
                                    <button
                                        key={index}
                                        className='rounded-xl bg-white'
                                        onClick={() => setSelectedDeliveryOption(index)}
                                    >
                                        <div
                                            className={`m-0 flex flex-col items-start justify-center p-4 border text-black`}
                                        >
                                            <div className='w-full flex justify-between items-center'>
                                                <div
                                                    className={
                                                        selectedDeliveryOption === index ? 'font-bold' : 'text-sm'
                                                    }
                                                >
                                                    {deliveryDetails?.shipping_name}
                                                </div>
                                                {deliveryDetails?.shipping_name && (
                                                    <img
                                                        className='w-4'
                                                        alt='checkbox icon'
                                                        src={
                                                            selectedDeliveryOption === index
                                                                ? radioChecked
                                                                : radioUnchecked
                                                        }
                                                        height={'20'}
                                                    />
                                                )}
                                            </div>
                                            {deliveryDetails?.shipping_name && (
                                                <div className='w-full flex justify-between items-center min-h-[18px]'>
                                                    <div className='text-xs text-gray-600'>
                                                        {deliveryDetails?.shipping_charge === 0
                                                            ? 'Free shipping available'
                                                            : 'You will be charged'}
                                                    </div>
                                                    <div className='text-sm font-bold'>
                                                        {deliveryDetails?.shipping_charge === 0
                                                            ? 'Free'
                                                            : `₹${deliveryDetails?.shipping_charge}`}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <div className='flex items-center justify-between mx-auto relative'>
                    <span className='text-xs text-grey-500'>
                        Logged in with <b>{` (+91) 9999999999`}</b>
                    </span>
                    <button className='bg-white border-none focus:outline-none'>
                        <img src={logoutButton} alt='logout' height={'20'} />
                    </button>
                </div>
            </div>
            <footer className='p-6 bg-white rounded-b-lg'>
                <button
                    className='w-full bg-gray-300 text-gray-700 py-2 rounded-xl border-none'
                    disabled
                    style={{
                        backgroundColor: newCheckoutUIConfig?.colorConfig?.brandColor,
                        color: newCheckoutUIConfig?.colorConfig?.textColor,
                    }}
                >
                    {newCheckoutUIConfig?.textConfig?.address?.cta}
                </button>
                <div className='mt-2 text-center items-center'>
                    <img src={footerText} height={'20'} alt='stars' />
                </div>
            </footer>
            <BottomSheet
                showPopup={showPopup}
                setShowPopup={setShowPopup}
                showCloseButton={showCloseButton}
                setShowCloseButton={setShowCloseButton}
                isSelectedAddress={isSelectedAddress}
                setIsSelectedAddress={setIsSelectedAddress}
                setSelectedAddress={setSelectedAddress}
                updatedAddressList={updatedAddressList}
                newCheckoutUIConfig={newCheckoutUIConfig}
            />
        </div>
    )
}

const AddressListComponent = ({
    isSelectedAddress,
    setIsSelectedAddress,
    setSelectedAddress,
    updatedAddressList,
    setShowPopup,
    newCheckoutUIConfig,
}) => {
    return (
        <div>
            <div className='bg-white rounded-xl p-4' style={{ border: '1px solid gray' }}>
                <div className='flex items-center justify-between'>
                    <p className='text-normal'>
                        <PlusSquareFilled className='mr-2 text-blue-500' />
                        Add New Address
                    </p>
                    <RightOutlined />
                </div>
            </div>

            {updatedAddressList.map((addressDetails, index) => (
                <button
                    key={index}
                    onClick={() => {
                        setIsSelectedAddress(index)
                    }}
                    className='visually-hidden w-full mt-4 p-2 rounded-xl border-black bg-white'
                >
                    <div className={`border ${isSelectedAddress === index ? 'border-black' : 'border-gray-100'}`}>
                        <div className='flex justify-between items-center w-full'>
                            <div className='font-semibold'>{addressDetails.fullName}</div>
                        </div>
                        <div className='text-sm font-sm text-black text-left'>
                            {addressDetails?.address1},{addressDetails?.city && `${addressDetails?.city}, `}
                            {addressDetails?.state && `${addressDetails?.state}, `}
                            {addressDetails?.country && `${addressDetails?.country}, `}
                            {addressDetails?.pincode}
                        </div>
                        <div className='main-contact text-dark-grey text-left font-sm text-xs'>
                            {addressDetails?.phone && <span>(+91) {addressDetails?.phone}, </span>}
                            {addressDetails?.email && addressDetails?.email}
                        </div>
                    </div>
                </button>
            ))}
            <div>
                <button
                    onClick={() => {
                        setSelectedAddress(updatedAddressList[isSelectedAddress ?? 0])
                        setShowPopup(false)
                    }}
                    className='visually-hidden w-full mt-6 p-2 rounded-xl border-none bg-black text-white font-semibold'
                    style={{
                        backgroundColor: newCheckoutUIConfig?.colorConfig?.brandColor,
                        color: newCheckoutUIConfig?.colorConfig?.textColor,
                    }}
                >
                    Done
                </button>
            </div>
        </div>
    )
}

const BottomSheet = ({
    showPopup,
    setShowPopup,
    showCloseButton,
    setShowCloseButton,
    isSelectedAddress,
    setIsSelectedAddress,
    setSelectedAddress,
    updatedAddressList,
    newCheckoutUIConfig,
}) => {
    return (
        <div>
            {showPopup && (
                <div className='absolute inset-0 bg-[#00000038] rounded-xl bg-opacity-50 flex justify-center items-end'>
                    <div className='bg-white w-full rounded-lg p-4 max-h-[85%] overflow-y-auto z-10001'>
                        <div className='flex justify-between items-center bg-white mb-2'>
                            <div className='flex justify-center items-center gap-2'>
                                <div className='text-lg'>All Addresses</div>
                            </div>
                            {showCloseButton && (
                                <button
                                    className='bg-white border-none focus:outline-none'
                                    onClick={() => {
                                        setShowPopup(false)
                                        setShowCloseButton(false)
                                    }}
                                >
                                    <CloseOutlined />
                                </button>
                            )}
                        </div>
                        <div className='flex'>
                            <AddressListComponent
                                isSelectedAddress={isSelectedAddress}
                                setIsSelectedAddress={setIsSelectedAddress}
                                setSelectedAddress={setSelectedAddress}
                                updatedAddressList={updatedAddressList}
                                setShowPopup={setShowPopup}
                                newCheckoutUIConfig={newCheckoutUIConfig}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AddressPagePreview
