import { CloudDownloadOutlined, DownloadOutlined } from 'gokwik-ui-kit'
import { Upload, message } from 'gokwik-ui-kit'
import { downloadToCSV } from '@gokwik/utilities'
import { cellKeys, cellHeadings, sampleAWBData } from '../constants'

interface UploadDraggerProps {
    handleFileChange: (file: File) => Promise<boolean>
}

function UploadDragger({ handleFileChange }: UploadDraggerProps) {
    const { Dragger } = Upload

    const handleDownloadSampleFile = () => {
        downloadToCSV(cellKeys, cellHeadings, sampleAWBData, 'sample_awb_data', { addSerialNumber: false })
    }

    return (
        <>
            <Dragger
                name='file'
                multiple={false}
                beforeUpload={handleFileChange}
                onChange={(info) => {
                    const { status } = info.file
                    if (status !== 'uploading') {
                        console.log(info.file, info.fileList)
                    }
                    if (status === 'done') {
                        message.success(`${info.file.name} file uploaded successfully.`)
                    } else if (status === 'error') {
                        message.error(`${info.file.name} file upload failed.`)
                    }
                }}
                onDrop={(e) => {
                    console.log('Dropped files', e.dataTransfer.files)
                }}
                accept='.xlsx,.xlsm,.xltm,.csv'
                showUploadList={false}
            >
                <div className='p-12 mt-4'>
                    <p className='ant-upload-drag-icon'>
                        <CloudDownloadOutlined
                            style={{
                                color: '#00000026',
                            }}
                        />
                    </p>
                    <p className='ant-upload-text'>Drag and drop your file here</p>
                    <p className='ant-upload-hint'>Choose Files</p>
                </div>
            </Dragger>

            <div className='flex items-center justify-between p-2 mt-4 !bg-[#1677FF0D] border border-solid !border-[#1677FF8C] rounded-md'>
                <p className='text-[#000000E0] flex-[0.85] text-left'>
                    To help us process your AWB Data correctly, please upload your file in the format outlined here.
                </p>
                <p
                    className='flex items-center gap-2 text-[#1677FF] cursor-pointer'
                    onClick={handleDownloadSampleFile}
                >
                    <DownloadOutlined /> Download Sample File
                </p>
            </div>
        </>
    )
}

export default UploadDragger