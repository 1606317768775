import { ANALYTICS_DATA_SOURCE, AnalyticsCharts } from '@library/utilities/constants/constants'
import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'

const chartConfig = {
    [AnalyticsCharts.keyMetrics]: uuidv4(),
    [AnalyticsCharts.userSegmentation]: uuidv4(),
    [AnalyticsCharts.trends]: uuidv4(),
}
const analytics_store_slice = createSlice({
    name: 'analytics_store_slice',
    initialState: {
        source: ANALYTICS_DATA_SOURCE.SNOWPLOW,
        activeChartId: chartConfig[AnalyticsCharts.keyMetrics], //since keyMetrics is the default chart that opens
        chartID: { ...chartConfig },
    },
    reducers: {
        setDataSource: (state, action) => {
            state.source = action.payload.source
        },
        setChartActionId: (state, action) => {
            const chartType = action.payload?.chartType
            state.activeChartId = chartConfig[chartType]
        },
    },
})
export const { setDataSource, setChartActionId } = analytics_store_slice.actions
export default analytics_store_slice.reducer
