import { formatAmount } from '@library/utilities/helpers/helper'
import { validateDynamicDiscount } from '@library/utilities/helpers/validations'
import { Button, Col, DeleteOutlined, Form, InputNumber, message, Radio, Row, Select, Table } from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
const renderAmount = (val) => <span>{formatAmount(+(val || 0), 2)}</span>

const SetupTieredDiscounts = ({ form }: { form: any }) => {
    const [tierInputs, setTierInputs] = useState({
        min_price: 0,
        max_price: null,
        item_volume: 0,
        discount_type: 'percentage',
        discount_value: 0,
    })
    const tieredDiscountTiers = Form.useWatch('tieredDiscountTiers', form)
    const tieredDiscountCategory = Form.useWatch('tieredDiscountCategory', form)

    const columns: any = [
        {
            title: 'Discount Type',
            dataIndex: 'discount_type',
            key: 'discount_type',
            render: (text) => (text === 'percentage' ? 'Percentage' : 'Fixed Amount'),
        },
        {
            title: 'Discount Value',
            dataIndex: 'discount_value',
            key: 'discount_value',
            align: 'right',
            render: (text, record) =>
                record.discount_type === 'percentage' ? <span>{text}%</span> : <span>{renderAmount(text)}</span>,
        },
        ...(tieredDiscountCategory === 'value'
            ? [
                  {
                      title: 'Min Value',
                      dataIndex: 'min_price',
                      key: 'min_price',
                      align: 'right',
                      render: (text) => renderAmount(text),
                  },
                  {
                      title: 'Max Value',
                      dataIndex: 'max_price',
                      key: 'max_price',
                      align: 'right',
                      render: (text) => (text ? renderAmount(text) : 'No Limit'),
                  },
              ]
            : [
                  {
                      title: 'Item Volume',
                      dataIndex: 'item_volume',
                      key: 'item_volume',
                      align: 'right',
                  },
              ]),
        {
            title: '',
            dataIndex: 'action',
            render: (text, record) => (
                <DeleteOutlined
                    className='cursor-pointer text-error-500'
                    onClick={() => {
                        const newValues = tieredDiscountTiers.filter(
                            (item) => JSON.stringify(item) !== JSON.stringify(record),
                        )
                        form.setFieldsValue({
                            tieredDiscountTiers: newValues,
                        })
                    }}
                />
            ),
        },
    ]

    return (
        <Col span={24}>
            <Row gutter={[16, 16]} align={'bottom'}>
                <Col span={24}>
                    <Form.Item
                        label='How should the tiers define?'
                        name='tieredDiscountCategory'
                        rules={[{ required: true, message: 'Please select discount value type' }]}
                        className='label-bold'
                    >
                        <Radio.Group disabled={tieredDiscountTiers?.length > 0}>
                            <Radio value='volume'>Based on Cart Quantity</Radio>
                            <Radio value='value'>Base on Order Value</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {tieredDiscountTiers?.length > 0 && (
                        <p className='text-black/40'>Tiers category can not be changed if tiers are defined.</p>
                    )}
                </Col>
                {tieredDiscountCategory === 'volume' ? (
                    <Col span={5}>
                        <InputNumber
                            className='w-full'
                            label={'Item Volume'}
                            min={1}
                            value={tierInputs.item_volume}
                            onChange={(value) => setTierInputs({ ...tierInputs, item_volume: +value })}
                        />
                    </Col>
                ) : (
                    <>
                        <Col span={5}>
                            <InputNumber
                                className='w-full'
                                label={'Minimum Order Value'}
                                min={0}
                                value={tierInputs.min_price}
                                onChange={(value) => setTierInputs({ ...tierInputs, min_price: +value })}
                            />
                        </Col>
                        <Col span={5}>
                            <InputNumber
                                className='w-full'
                                label={'Maximum Order Value'}
                                value={tierInputs.max_price}
                                min={0}
                                onChange={(value) => setTierInputs({ ...tierInputs, max_price: value ? +value : null })}
                            />
                        </Col>
                    </>
                )}
                <Col span={5}>
                    <Select
                        className='w-full'
                        label={'Discount Type'}
                        value={tierInputs.discount_type}
                        onChange={(value) => setTierInputs({ ...tierInputs, discount_type: value })}
                        options={[
                            { label: 'Percentage', value: 'percentage' },
                            { label: 'Fixed Amount', value: 'fixed' },
                        ]}
                    />
                </Col>
                <Col span={5}>
                    <InputNumber
                        className='w-full'
                        label={'Discount Value'}
                        min={0}
                        value={tierInputs.discount_value}
                        onChange={(value) => setTierInputs({ ...tierInputs, discount_value: +value })}
                        {...(tierInputs.discount_type === 'percentage' ? { max: 100, suffix: '%' } : { prefix: '₹' })}
                    />
                </Col>
                <Col span={4}>
                    <Button
                        onClick={() => {
                            const minimumRequirements = form.getFieldValue('minimumRequirements')
                            const minimumOrderValue = form.getFieldValue('discountValue')
                            if (
                                minimumRequirements === 'minimumQuantity' &&
                                tieredDiscountCategory === 'volume' &&
                                tierInputs.item_volume < minimumOrderValue
                            ) {
                                message.error('Item volume should not be less than minimum quantity')
                                return
                            } else if (minimumRequirements === 'minimumAmount' && tieredDiscountCategory === 'value') {
                                let messageText = ''
                                if (tierInputs.min_price < minimumOrderValue) {
                                    messageText = 'Minimum'
                                }
                                if (tierInputs.max_price < minimumOrderValue) {
                                    messageText = messageText ? `${messageText} and Maximum` : 'Maximum'
                                }
                                if (messageText) {
                                    message.error(
                                        `${messageText} order value should not be less than minimum order value`,
                                    )
                                    return
                                }
                            }
                            const verifyDiscount = validateDynamicDiscount(
                                {
                                    ...tierInputs,
                                    discount_name: 'tieredDiscountTiers',
                                    discount_category: tieredDiscountCategory,
                                },
                                {
                                    discount_category: tieredDiscountCategory,
                                    discount_info: tieredDiscountTiers,
                                },
                            )
                            if (!verifyDiscount.status) {
                                message.error(verifyDiscount.msg)
                                return
                            }
                            const newValues = [
                                ...(tieredDiscountTiers || []),
                                {
                                    discount_type: tierInputs.discount_type,
                                    discount_value: tierInputs.discount_value,
                                    ...(tieredDiscountCategory === 'volume'
                                        ? { item_volume: tierInputs.item_volume }
                                        : { min_price: tierInputs.min_price, max_price: tierInputs.max_price }),
                                },
                            ]
                            form.setFieldsValue({
                                tieredDiscountTiers: newValues,
                            })
                            setTierInputs({
                                min_price: 0,
                                max_price: null,
                                discount_type: 'percentage',
                                discount_value: 0,
                                item_volume: 0,
                            })
                        }}
                    >
                        Add more tiers
                    </Button>
                </Col>
                <Col span={24}>
                    <Table
                        dataSource={tieredDiscountTiers}
                        columns={columns}
                        pagination={{
                            pageSize: 10,
                            total: tieredDiscountTiers?.length,
                            showSizeChanger: false,
                            position: ['none', 'bottomRight'],
                            showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
                        }}
                    />
                </Col>
                <Form.Item
                    className='pl-4 hide-control-input'
                    name={'tieredDiscountTiers'}
                    rules={[{ required: true, message: 'Please add tiers' }]}
                ></Form.Item>
            </Row>
        </Col>
    )
}

export default SetupTieredDiscounts
