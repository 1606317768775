import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { convertUtcToIst } from './helper';

dayjs.extend(utc);
dayjs.extend(timezone);
interface OriginalDataItem {
    date: string;
    hour: string;
    risk_flag: string;
    payment_method: 'COD' | 'PREPAID';
    rto_orders: number;
    delivered_orders: number;
    cancelled_orders: number;
    pending_orders: number;
    null_status_orders: number;
    rid_hits: number;
    total_orders: number;
}

interface OriginalDataItem {
    date: string
    decile_level: string
    total_hits: number
    total_orders: number
    cod_orders: number
    total_rto_orders: number
    total_delivered_orders: number
    total_pending_orders: number
    total_null_status_orders: number
    total_cancelled_orders: number
    total_cod_rto_orders: number
    total_cod_pending_orders: number
    total_cod_delivered_orders: number
}

interface AggregatedMetrics {
    date: string
    decile_level: string
    hits: number
    total_orders: number
    cod_orders: number
    total_rto_orders: number
    total_delivered_orders: number
    total_pending_orders: number
    total_null_status_orders: number
    total_cancelled_orders: number
    total_cod_rto_orders: number
    total_cod_pending_orders: number
    total_cod_delivered_orders: number
    prepaid_share: number
    cod_rto_perc: number
    rto_overall_perc: number
    awb_fill_rate_perc: number
    delivered_orders_perc: number
    cancelled_orders_perc: number
}

interface DeepDiveAggregatedMetrics {
    date: string
    hits: number
    total_orders: number
    cod_orders: number
    total_rto_orders: number
    total_delivered_orders: number
    total_pending_orders: number
    total_null_status_orders: number
    total_cancelled_orders: number
    total_cod_rto_orders: number
    total_cod_pending_orders: number
    total_cod_delivered_orders: number
    prepaid_share: number
    cod_rto_perc: number
    rto_overall_perc: number
    order_share: number
    rto_share_overall: number
    delivered_share: number
    filter_value: string
}

interface SummaryAggregatedMetrics {
    date: string
    hits: number
    total_orders: number
    cod_orders: number
    total_rto_orders: number
    total_delivered_orders: number
    total_pending_orders: number
    total_null_status_orders: number
    total_cancelled_orders: number
    total_cod_rto_orders: number
    total_cod_pending_orders: number
    total_cod_delivered_orders: number
    prepaid_share: number
    cod_rto_perc: number
    rto_overall_perc: number
    delivered_orders_perc: number
    cancelled_orders_perc: number
    awb_fill_rate_perc: number
    risk_flag_level?: string
}

export const updateDataBasedOnFilter = (originalData, filterValue) => {
    if (filterValue === 'daily') {
        return originalData
    } else if (filterValue === 'weekly') {
        // If weekly filter is selected, combine data for every 7 days
        const updatedData = []
        for (let i = 0; i < originalData?.length; i += 7) {
            const chunk = originalData.slice(i, i + 7)
            const startDate = chunk[0].date
            const endDate = chunk[chunk.length - 1].date
            const combinedData = {
                date: startDate,
                // Aggregate values for the week
                total_orders: chunk.reduce((total, item) => total + item.total_orders, 0),
                cod_orders: chunk.reduce((total, item) => total + item.cod_orders, 0),
                prepaid_orders: chunk.reduce((total, item) => total + item.prepaid_orders, 0),
                overall_rto_orders: chunk.reduce((total, item) => total + item.overall_rto_orders, 0),
                cod_rto_orders: chunk.reduce((total, item) => total + item.cod_rto_orders, 0),
                prepaid_rto_orders: chunk.reduce((total, item) => total + item.prepaid_rto_orders, 0),
                overall_rto_rate: (
                    (chunk.reduce((total, item) => total + item.overall_rto_orders, 0) /
                        chunk.reduce((total, item) => total + item.total_orders, 0)) *
                    100
                ).toFixed(2),
                cod_rto_rate: (
                    (chunk.reduce((total, item) => total + item.cod_rto_orders, 0) /
                        chunk.reduce((total, item) => total + item.total_orders, 0)) *
                    100
                ).toFixed(2),
                prepaid_rto_rate: (
                    (chunk.reduce((total, item) => total + item.prepaid_rto_orders, 0) /
                        chunk.reduce((total, item) => total + item.total_orders, 0)) *
                    100
                ).toFixed(2),
            };
            updatedData.push(combinedData);
        }
        return updatedData
    } else if (filterValue === 'monthly') {
        // If monthly filter is selected, separate out data for specific months and combine it
        const updatedData = {}
        originalData?.forEach((item) => {
            const monthYear = item.date.substring(0, 7) // Extracting month and year
            if (!updatedData[monthYear]) {
                updatedData[monthYear] = { ...item } // Initialize with the first occurrence of the month
            } else {
                // Combine data for the same month
                updatedData[monthYear].total_orders += item.total_orders
                updatedData[monthYear].cod_orders += item.cod_orders
                updatedData[monthYear].prepaid_orders += item.prepaid_orders
                updatedData[monthYear].overall_rto_orders += item.overall_rto_orders
                updatedData[monthYear].cod_rto_orders += item.cod_rto_orders
                updatedData[monthYear].prepaid_rto_orders += item.prepaid_rto_orders
                // Calculate overall_rto_rate, cod_rto_rate, and prepaid_rto_rate
                updatedData[monthYear].overall_rto_rate = (
                    (updatedData[monthYear].overall_rto_orders / updatedData[monthYear].total_orders) *
                    100
                ).toFixed(2);
                updatedData[monthYear].cod_rto_rate = (
                    (updatedData[monthYear].cod_rto_orders / updatedData[monthYear].total_orders) *
                    100
                ).toFixed(2);
                updatedData[monthYear].prepaid_rto_rate = (
                    (updatedData[monthYear].prepaid_rto_orders / updatedData[monthYear].total_orders) *
                    100
                ).toFixed(2);
            }
        })
        return Object.values(updatedData)
    } else {
        return originalData // Handle other filter options as needed
    }
}

const calculateMetrics = (aggregated: SummaryAggregatedMetrics): SummaryAggregatedMetrics => {
    const totalOrders = aggregated.total_orders
    const codRtoRateOrders =
        aggregated.total_cod_rto_orders + aggregated.total_cod_pending_orders + aggregated.total_cod_delivered_orders
    const rtoRateOrders =
        aggregated.total_rto_orders + aggregated.total_pending_orders + aggregated.total_delivered_orders

    // Calculate percentages and shares
    aggregated.prepaid_share =
        totalOrders > 0 ? parseFloat((((totalOrders - aggregated.cod_orders) / totalOrders) * 100).toFixed(1)) : 0
    aggregated.cod_rto_perc =
        codRtoRateOrders > 0 ? parseFloat(((aggregated.total_cod_rto_orders / codRtoRateOrders) * 100).toFixed(1)) : 0
    aggregated.rto_overall_perc =
        rtoRateOrders > 0 ? parseFloat(((aggregated.total_rto_orders / rtoRateOrders) * 100).toFixed(1)) : 0
    aggregated.delivered_orders_perc =
        totalOrders > 0 ? parseFloat(((aggregated.total_delivered_orders / totalOrders) * 100).toFixed(1)) : 0
    aggregated.cancelled_orders_perc =
        totalOrders > 0 ? parseFloat(((aggregated.total_cancelled_orders / totalOrders) * 100).toFixed(1)) : 0
    aggregated.awb_fill_rate_perc =
        totalOrders > 0
            ? parseFloat(
                  (
                      100 -
                      ((aggregated.total_null_status_orders + aggregated.total_pending_orders) / totalOrders) * 100
                  ).toFixed(1),
              )
            : 0

    return aggregated
}

export const aggregateSummaryMetrics = (
    originalData: OriginalDataItem[] | null | undefined,
    timeframe: string,
    filter?: keyof OriginalDataItem,
): SummaryAggregatedMetrics[] => {
    if (!originalData) return []

    const aggregateData = (data: OriginalDataItem[]): SummaryAggregatedMetrics[] => {
        const aggregated: { [key: string]: SummaryAggregatedMetrics } = {}

        data.forEach((item) => {
            if (!item) return

            const filterValue = filter ? item[filter] : 'all'
            const key = `${getKeyByTimeframe(item.date, timeframe)}-${filterValue}`

            if (!aggregated[key]) {
                aggregated[key] = {
                    date: getKeyByTimeframe(item.date, timeframe),
                    hits: 0,
                    total_orders: 0,
                    cod_orders: 0,
                    total_rto_orders: 0,
                    total_delivered_orders: 0,
                    total_pending_orders: 0,
                    total_null_status_orders: 0,
                    total_cancelled_orders: 0,
                    total_cod_rto_orders: 0,
                    total_cod_pending_orders: 0,
                    total_cod_delivered_orders: 0,
                    prepaid_share: 0,
                    cod_rto_perc: 0,
                    rto_overall_perc: 0,
                    delivered_orders_perc: 0,
                    cancelled_orders_perc: 0,
                    awb_fill_rate_perc: 0,
                    ...(filter && { risk_flag_level: String(filterValue) }),
                }
            }

            aggregated[key].hits += item.total_hits ?? 0
            aggregated[key].total_orders += item.total_orders ?? 0
            aggregated[key].cod_orders += item.cod_orders ?? 0
            aggregated[key].total_rto_orders += item.total_rto_orders ?? 0
            aggregated[key].total_delivered_orders += item.total_delivered_orders ?? 0
            aggregated[key].total_pending_orders += item.total_pending_orders ?? 0
            aggregated[key].total_null_status_orders += item.total_null_status_orders ?? 0
            aggregated[key].total_cancelled_orders += item.total_cancelled_orders ?? 0
            aggregated[key].total_cod_rto_orders += item.total_cod_rto_orders ?? 0
            aggregated[key].total_cod_pending_orders += item.total_cod_pending_orders ?? 0
            aggregated[key].total_cod_delivered_orders += item.total_cod_delivered_orders ?? 0
        })

        return Object.values(aggregated)?.map(calculateMetrics)
    }

    // Segregate data by filter if provided, otherwise aggregate all data
    const segregatedDataByFilter = originalData?.reduce(
        (acc: { [key: string]: OriginalDataItem[] }, curr: OriginalDataItem) => {
            if (!curr) return acc

            const filterKey = filter && curr[filter] ? curr[filter] : 'all'
            if (!acc[filterKey]) acc[filterKey] = []
            acc[filterKey].push(curr)
            return acc
        },
        {},
    )

    let aggregatedMetrics: SummaryAggregatedMetrics[] = []
    Object.keys(segregatedDataByFilter)?.forEach((key) => {
        const metrics = aggregateData(segregatedDataByFilter[key])
        aggregatedMetrics = [...aggregatedMetrics, ...metrics]
    })

    return aggregatedMetrics.sort((a, b) => a.date.localeCompare(b.date))
}

const getKeyByTimeframe = (date: string, timeframe: string): string => {
    if (!date) return ''
    switch (timeframe) {
        case 'weekly':
            return dayjs(date).startOf('week').format('YYYY-MM-DD')
        case 'monthly':
            return dayjs(date).format('YYYY-MM')
        case 'quarterly':
            if (date.includes('Q')) {
                return date
            }
            const month = dayjs(date).month()
            let quarterStartMonth
            if (month >= 0 && month <= 2) {
                quarterStartMonth = 0 // January
            } else if (month >= 3 && month <= 5) {
                quarterStartMonth = 3 // April
            } else if (month >= 6 && month <= 8) {
                quarterStartMonth = 6 // July
            } else {
                quarterStartMonth = 9 // October
            }
            const quarterYear = dayjs(date).year()
            return `Q${Math.floor(month / 3) + 1} ${quarterYear}`
        default:
            return ''
    }
}

export const updateDataBasedOnTimeframeRiskProbability = (
    originalData: OriginalDataItem[] | null | undefined,
    timeframe: 'weekly' | 'monthly' | 'quarterly',
): AggregatedMetrics[] => {
    if (!originalData) return [] // Guard clause for null or undefined originalData

    const adjustDecileLevel = (decile: string): string => {
        const decileNumber = parseFloat(decile)
        // Determine the lower bound of the decile range
        const lowerBound = Math.floor(decileNumber * 10) / 10
        // Ensure grouping into 10 distinct ranges: 0-0.1, 0.1-0.2, ..., 0.9-1.0
        const rangeStart = lowerBound.toFixed(1)
        const rangeEnd = (lowerBound + 0.1).toFixed(1)
        return `${rangeStart}-${rangeEnd}`
    }

    const aggregateData = (data: OriginalDataItem[]): AggregatedMetrics[] => {
        const aggregated: { [key: string]: AggregatedMetrics } = {}

        data?.forEach((item) => {
            if (!item) return // Guard clause for null items

            const adjustedDecileLevel = adjustDecileLevel(item.decile_level)
            const key = `${getKeyByTimeframe(item.date, timeframe)}-${adjustedDecileLevel}`
            if (!aggregated[key]) {
                aggregated[key] = {
                    date: getKeyByTimeframe(item.date, timeframe),
                    decile_level: adjustedDecileLevel,
                    hits: item.total_hits, // Initialize with the first item's total_hits
                    total_orders: item.total_orders,
                    cod_orders: item.cod_orders,
                    total_rto_orders: item.total_rto_orders,
                    total_delivered_orders: item.total_delivered_orders,
                    total_pending_orders: item.total_pending_orders,
                    total_null_status_orders: item.total_null_status_orders,
                    total_cancelled_orders: item.total_cancelled_orders,
                    total_cod_rto_orders: item.total_cod_rto_orders,
                    total_cod_pending_orders: item.total_cod_pending_orders,
                    total_cod_delivered_orders: item.total_cod_delivered_orders,
                    prepaid_share: 0,
                    cod_rto_perc: 0,
                    rto_overall_perc: 0,
                    awb_fill_rate_perc: 0,
                    delivered_orders_perc: 0,
                    cancelled_orders_perc: 0,
                }
            } else {
                // Safely increment aggregated metrics
                aggregated[key].hits += item.total_hits ?? 0
                aggregated[key].total_orders += item.total_orders ?? 0
                aggregated[key].cod_orders += item.cod_orders ?? 0
                aggregated[key].total_rto_orders += item.total_rto_orders ?? 0
                aggregated[key].total_delivered_orders += item.total_delivered_orders ?? 0
                aggregated[key].total_pending_orders += item.total_pending_orders ?? 0
                aggregated[key].total_null_status_orders += item.total_null_status_orders ?? 0
                aggregated[key].total_cancelled_orders += item.total_cancelled_orders ?? 0
                aggregated[key].total_cod_rto_orders += item.total_cod_rto_orders ?? 0
                aggregated[key].total_cod_pending_orders += item.total_cod_pending_orders ?? 0
                aggregated[key].total_cod_delivered_orders += item.total_cod_delivered_orders ?? 0
            }
        })

        return Object?.values(aggregated)?.map((aggregatedItem) => {
            // Perform calculations with null checks and safe defaults
            const totalOrders = aggregatedItem.total_orders ?? 0
            const codRtoRateOrders =
                (aggregatedItem.total_cod_rto_orders ?? 0) +
                (aggregatedItem.total_cod_pending_orders ?? 0) +
                (aggregatedItem.total_cod_delivered_orders ?? 0)
            const rtoRateOrders =
                (aggregatedItem.total_rto_orders ?? 0) +
                (aggregatedItem.total_pending_orders ?? 0) +
                (aggregatedItem.total_delivered_orders ?? 0)
            aggregatedItem.prepaid_share =
                totalOrders > 0
                    ? parseFloat((((totalOrders - (aggregatedItem.cod_orders ?? 0)) / totalOrders) * 100).toFixed(1))
                    : 0
            aggregatedItem.cod_rto_perc =
                codRtoRateOrders > 0
                    ? parseFloat((((aggregatedItem.total_cod_rto_orders ?? 0) / codRtoRateOrders) * 100).toFixed(1))
                    : 0
            aggregatedItem.rto_overall_perc =
                rtoRateOrders > 0
                    ? parseFloat((((aggregatedItem.total_rto_orders ?? 0) / rtoRateOrders) * 100).toFixed(1))
                    : 0
            aggregatedItem.awb_fill_rate_perc =
                totalOrders > 0
                    ? 100 -
                      parseFloat(
                          (
                              (((aggregatedItem.total_pending_orders ?? 0) +
                                  (aggregatedItem.total_null_status_orders ?? 0)) /
                                  totalOrders) *
                              100
                          ).toFixed(2),
                      )
                    : 0
            aggregatedItem.delivered_orders_perc =
                totalOrders > 0
                    ? parseFloat((((aggregatedItem.total_delivered_orders ?? 0) / totalOrders) * 100).toFixed(1))
                    : 0
            aggregatedItem.cancelled_orders_perc =
                totalOrders > 0
                    ? parseFloat((((aggregatedItem.total_cancelled_orders ?? 0) / totalOrders) * 100).toFixed(1))
                    : 0

            return aggregatedItem
        })
    }

    const segregatedDataByDecile = originalData?.reduce((acc: { [decile: string]: OriginalDataItem[] }, curr) => {
        if (!curr || !curr.decile_level) return acc // Guard clause for null or undefined decile_level

        if (!acc[curr.decile_level]) acc[curr.decile_level] = []
        acc[curr.decile_level].push(curr)
        return acc
    }, {})

    let aggregatedMetrics: AggregatedMetrics[] = []
    Object?.keys(segregatedDataByDecile)?.forEach((decile) => {
        const metrics = aggregateData(segregatedDataByDecile[decile])
        aggregatedMetrics = [...aggregatedMetrics, ...metrics]
    })

    return aggregatedMetrics.sort(
        (a, b) => a.date.localeCompare(b.date) || a.decile_level.localeCompare(b.decile_level),
    )
}

const calculateTotalsByTimeframe = (
    originalData: OriginalDataItem[],
    timeframe: 'weekly' | 'monthly' | 'quarterly',
    metric: keyof OriginalDataItem,
): { [key: string]: number } => {
    const totalsByTimeframe: { [key: string]: number } = {}

    originalData?.forEach((item) => {
        const key = getKeyByTimeframe(item.date, timeframe)
        if (!totalsByTimeframe[key]) {
            totalsByTimeframe[key] = 0
        }
        totalsByTimeframe[key] += +item[metric]
    })

    return totalsByTimeframe
}

export const updateDataBasedOnTimeframeAndFilter = (
    originalData: OriginalDataItem[] | null | undefined,
    timeframe: 'weekly' | 'monthly' | 'quarterly',
    filter: 'state' | 'tier' | 'address_length_bucket' | 'browser' | 'os' | 'quantity',
): DeepDiveAggregatedMetrics[] => {
    if (!originalData) return [] // Guard clause for null or undefined originalData
    const totalOrdersByTimeframe = calculateTotalsByTimeframe(originalData, timeframe, 'total_orders')
    const totalRtoOrdersByTimeframe = calculateTotalsByTimeframe(originalData, timeframe, 'total_rto_orders')
    const totalDeliveredOrdersByTimeframe = calculateTotalsByTimeframe(
        originalData,
        timeframe,
        'total_delivered_orders',
    )

    const aggregateData = (
        data: OriginalDataItem[],
        totalOrdersByTimeframe: { [key: string]: number },
        totalRtoOrdersByTimeframe: { [key: string]: number },
        totalDeliveredOrdersByTimeframe: { [key: string]: number },
    ): DeepDiveAggregatedMetrics[] => {
        const aggregated: { [key: string]: DeepDiveAggregatedMetrics } = {}

        data?.forEach((item) => {
            if (!item) return // Guard clause for null items

            // Dynamically use the filter value to get the corresponding item property
            const filterValue = item[filter] // Assuming all filters directly map to item properties
            const key = `${getKeyByTimeframe(item.date, timeframe)}-${filterValue}`
            if (!aggregated[key]) {
                aggregated[key] = {
                    date: getKeyByTimeframe(item.date, timeframe),
                    filter_value: filterValue, // This will hold the value of the filter (e.g., state name, tier, etc.)
                    hits: item.total_hits, // Initialize with the first item's total_hits
                    total_orders: item.total_orders,
                    cod_orders: item.cod_orders,
                    total_rto_orders: item.total_rto_orders,
                    total_delivered_orders: item.total_delivered_orders,
                    total_pending_orders: item.total_pending_orders,
                    total_null_status_orders: item.total_null_status_orders,
                    total_cancelled_orders: item.total_cancelled_orders,
                    total_cod_rto_orders: item.total_cod_rto_orders,
                    total_cod_pending_orders: item.total_cod_pending_orders,
                    total_cod_delivered_orders: item.total_cod_delivered_orders,
                    prepaid_share: 0,
                    cod_rto_perc: 0,
                    rto_overall_perc: 0,
                    order_share: 0,
                    rto_share_overall: 0,
                    delivered_share: 0,
                }
            } else {
                aggregated[key].hits += item.total_hits ?? 0
                aggregated[key].total_orders += item.total_orders ?? 0
                aggregated[key].cod_orders += item.cod_orders ?? 0
                aggregated[key].total_rto_orders += item.total_rto_orders ?? 0
                aggregated[key].total_delivered_orders += item.total_delivered_orders ?? 0
                aggregated[key].total_pending_orders += item.total_pending_orders ?? 0
                aggregated[key].total_null_status_orders += item.total_null_status_orders ?? 0
                aggregated[key].total_cancelled_orders += item.total_cancelled_orders ?? 0
                aggregated[key].total_cod_rto_orders += item.total_cod_rto_orders ?? 0
                aggregated[key].total_cod_pending_orders += item.total_cod_pending_orders ?? 0
                aggregated[key].total_cod_delivered_orders += item.total_cod_delivered_orders ?? 0
            }
        })

        return Object?.values(aggregated)?.map((aggregatedItem) => {
            const timeframeKey = getKeyByTimeframe(aggregatedItem.date, timeframe).toString()
            const timeframeTotalOrders = totalOrdersByTimeframe[timeframeKey] || 0
            const timeframeTotalRtoOrders = totalRtoOrdersByTimeframe[timeframeKey] || 0
            const timeframeTotalDeliveredOrders = totalDeliveredOrdersByTimeframe[timeframeKey] || 0
            const totalOrders = aggregatedItem.total_orders ?? 0
            const codRtoRateOrders =
                (aggregatedItem.total_cod_rto_orders ?? 0) +
                (aggregatedItem.total_cod_pending_orders ?? 0) +
                (aggregatedItem.total_cod_delivered_orders ?? 0)
            const rtoRateOrders =
                (aggregatedItem.total_rto_orders ?? 0) +
                (aggregatedItem.total_pending_orders ?? 0) +
                (aggregatedItem.total_delivered_orders ?? 0)
            aggregatedItem.prepaid_share =
                totalOrders > 0
                    ? parseFloat((((totalOrders - (aggregatedItem.cod_orders ?? 0)) / totalOrders) * 100).toFixed(1))
                    : 0
            aggregatedItem.cod_rto_perc =
                codRtoRateOrders > 0
                    ? parseFloat((((aggregatedItem.total_cod_rto_orders ?? 0) / codRtoRateOrders) * 100).toFixed(1))
                    : 0
            aggregatedItem.rto_overall_perc =
                rtoRateOrders > 0
                    ? parseFloat((((aggregatedItem.total_rto_orders ?? 0) / rtoRateOrders) * 100).toFixed(1))
                    : 0
            aggregatedItem.order_share =
                timeframeTotalOrders > 0
                    ? parseFloat(((aggregatedItem.total_orders / timeframeTotalOrders) * 100).toFixed(1))
                    : 0
            aggregatedItem.rto_share_overall =
                timeframeTotalRtoOrders > 0
                    ? parseFloat(((aggregatedItem.total_rto_orders / timeframeTotalRtoOrders) * 100).toFixed(1))
                    : 0
            aggregatedItem.delivered_share =
                timeframeTotalDeliveredOrders > 0
                    ? parseFloat(
                          ((aggregatedItem.total_delivered_orders / timeframeTotalDeliveredOrders) * 100).toFixed(1),
                      )
                    : 0
            return aggregatedItem
        })
    }

    const segregatedDataByFilter = originalData?.reduce((acc: { [key: string]: OriginalDataItem[] }, curr) => {
        if (!curr || curr[filter] === undefined) return acc // Adjusted to check for the filter property

        const filterKey = curr[filter]
        if (!acc[filterKey]) acc[filterKey] = []
        acc[filterKey].push(curr)
        return acc
    }, {})

    let aggregatedMetrics: DeepDiveAggregatedMetrics[] = []
    Object?.keys(segregatedDataByFilter)?.forEach((key) => {
        const metrics = aggregateData(
            segregatedDataByFilter[key],
            totalOrdersByTimeframe,
            totalRtoOrdersByTimeframe,
            totalDeliveredOrdersByTimeframe,
        )
        aggregatedMetrics = [...aggregatedMetrics, ...metrics]
    })

    aggregatedMetrics?.sort(
        (a, b) => a.date.localeCompare(b.date), // Adjusted to sort by filter_value
    )
    return filter === 'quantity' ? combineMetricsForQtyOrdered(aggregatedMetrics) : aggregatedMetrics
}

const combineMetricsForQtyOrdered = (data: any[]) => {
    const combinedData: { [key: string]: any } = {} // Use an object to store aggregated data by date

    data?.forEach((item) => {
        const filterValue = parseInt(item.filter_value) >= 4 ? '4+' : item.filter_value
        const key = `${item.date}-${filterValue}` // Combine date and filter_value to create a unique key

        if (!combinedData[key]) {
            combinedData[key] = { ...item, filter_value: filterValue, totalOrders: item.total_orders } // Initialize if not exist
        } else {
            // Aggregate metrics for existing entries
            const existingItem = combinedData[key]
            existingItem.hits += item.hits
            existingItem.total_orders += item.total_orders
            existingItem.cod_orders += item.cod_orders
            existingItem.total_rto_orders += item.total_rto_orders
            existingItem.total_delivered_orders += item.total_delivered_orders
            existingItem.total_pending_orders += item.total_pending_orders
            existingItem.total_null_status_orders += item.total_null_status_orders
            existingItem.total_cancelled_orders += item.total_cancelled_orders
            existingItem.total_cod_rto_orders += item.total_cod_rto_orders
            existingItem.total_cod_pending_orders += item.total_cod_pending_orders
            existingItem.total_cod_delivered_orders += item.total_cod_delivered_orders
            // For weighted averages
            existingItem.prepaid_share += item.prepaid_share * item.total_orders
            existingItem.cod_rto_perc += item.cod_rto_perc * item.total_orders
            existingItem.rto_overall_perc += item.rto_overall_perc * item.total_orders
            existingItem.order_share += item.order_share * item.total_orders
            existingItem.rto_share_overall += item.rto_share_overall * item.total_orders
            existingItem.delivered_share += item.delivered_share * item.total_orders
            existingItem.totalOrders += item.total_orders // Sum total orders for weighted average calculation later
        }
    })

    // Convert the aggregated object back to an array and calculate weighted averages
    return Object.values(combinedData).map((item) => {
        if (item.filter_value === '4+' && item.totalOrders > 0) {
            // Calculate weighted averages for '4+' entries
            item.prepaid_share /= item.totalOrders
            item.cod_rto_perc /= item.totalOrders
            item.rto_overall_perc /= item.totalOrders
            item.order_share /= item.totalOrders
            item.rto_share_overall /= item.totalOrders
            item.delivered_share /= item.totalOrders
        }
        // Remove the totalOrders field as it's no longer needed
        delete item.totalOrders
        return item
    })
}