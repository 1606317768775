import { Button, Col, DownOutlined, Dropdown, IconProvider, PlusOutlined, Row, Tabs, Tooltip } from 'gokwik-ui-kit'
import BookDownload from '@library/images/book-download.svg'
import { AutomaticDiscountIcon, BulkDiscountSetIcon, ManualDiscountIcon } from '@library/images/discountIcons'
import DiscountCodes from './discounts'
import { Link, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { updateBreadcrumbs } from '@gokwik/utilities'

const Discounts = () => {
    const location = useLocation()
    const menuItems = [
        {
            key: '1',
            label: (
                <Link to={'create/manual'} className='flex gap-x-1 items-center'>
                    <ManualDiscountIcon /> Create Manual Discount
                </Link>
            ),
        },
        {
            key: '2',
            label: (
                <Link to={'create/automatic'} className='flex gap-x-1 items-center'>
                    <AutomaticDiscountIcon /> Configure Automatic Discount
                </Link>
            ),
        },
        {
            key: '3',
            label: (
                <Link to={'create/bulk'} className='flex gap-x-1 items-center'>
                    <BulkDiscountSetIcon /> Create Bulk Discount Set
                </Link>
            ),
        },
    ]

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'checkout-settings',
                href: '/checkout/settings',
                text: 'Checkout Settings',
            },
            {
                key: 'discounts',
                href: '/checkout/settings/discounts',
                text: 'Discounts',
            },
        ])
    }, [])

    return (
        <div className='w-full flex flex-col gap-4 '>
            <Row className='p-2 rounded bg-neutrals-15' align={'middle'} justify={'space-between'}>
                <Col span={18}>
                    <div className='text-lg leading-7'>
                        <span className='font-semibold'>All Discounts</span>
                        {/* <a
                            className='ml-2 mb-1 text-sm font-normal'
                            href='https://scribehow.com/page/Guide_to_Set_Up_Shipping_Serviceability_on_GoKwik_Dashboard__cxszaSrOQ6GjY8KvNdtWOg'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <img className='mr-1 mb-1' src={BookDownload} height={'18'} width={'18'} />
                            Open Guide
                        </a> */}
                    </div>

                    <p className='text-black/40'>
                        This section allows you to create a new manual discount code or configure a pre-created
                        automatic/script based discount on Shopify and apply custom use cases and control levers powered
                        by GoKwik.
                    </p>
                </Col>
                <Col>
                    <Dropdown
                        trigger={['click', 'hover']}
                        overlayClassName='diy-discount-button-dropdown'
                        menu={{
                            items: menuItems,
                        }}
                    >
                        <Button onClick={() => {}} variant='primary'>
                            <PlusOutlined />
                            Create Discount
                            <DownOutlined />
                        </Button>
                    </Dropdown>
                </Col>
            </Row>
            <div className='p-2 rounded bg-neutrals-15'>
                <Tabs
                    defaultActiveKey={location.state?.activeTab || 'codes'}
                    items={[
                        {
                            key: 'codes',
                            label: (
                                <span className='flex items-center gap-x-1.5'>
                                    <ManualDiscountIcon className='text-2xl' /> Codes
                                </span>
                            ),
                            children: <DiscountCodes isBulkDiscount={false} />,
                        },
                        {
                            key: 'bulk',
                            label: (
                                <span className='flex items-center gap-x-1.5'>
                                    <BulkDiscountSetIcon className='text-2xl' /> Sets
                                </span>
                            ),
                            children: <DiscountCodes isBulkDiscount={true} />,
                        },
                    ]}
                />
            </div>
        </div>
    )
}

export default Discounts
