import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { FilterType, sortedData } from '@library/utilities/constants/constants'
import {
    calculateRTOMetrics,
    updateCitiesRcaCardsData,
    updatePincodesRcaCardsData,
} from '@library/utilities/helpers/rca_view_data_filtering'
import { FetchParams } from '@library/utilities/interface'
import { message } from 'gokwik-ui-kit'

export const rtoDetailApi: {
    [key: string]: {
        url: string
        errMsg: string
        nestedKeys?: string[]
        methodType?: 'get' | 'post'
        defaultParams?: { [key: string]: any }
        headers?: object
        formatData?: (data: any) => any
    }
} = {
    rtoInsights: {
        url: APIEndPoints.getRTOAnalyticsData,
        errMsg: 'Unable to fetch RTO Insight Data',
        defaultParams: {
            filter_type: 'risk_flag',
            rto_segment: 'all',
            group_by_date: false,
        },
        methodType: 'get',
        formatData: calculateRTOMetrics,
    },
    rtoInsightsPrevComparison: {
        url: APIEndPoints.getRTOAnalyticsData,
        errMsg: 'Unable to fetch RTO Insight Data',
        defaultParams: {
            filter_type: 'risk_flag',
            rto_segment: 'all',
            group_by_date: false,
        },
        methodType: 'get',
        formatData: calculateRTOMetrics,
    },
    decileData: {
        url: APIEndPoints.getRTOAnalyticsData,
        errMsg: 'Unable to Fetch RTO Risk Score Data',
        defaultParams: {
            filter_type: 'decile_level',
            rto_segment: 'all',
            group_by_date: false,
        },
        methodType: 'get',
    },
    comparedDecileData: {
        url: APIEndPoints.getRTOAnalyticsData,
        errMsg: 'Unable to Fetch States Data',
        defaultParams: {
            filter_type: 'decile_level',
            rto_segment: 'all',
            group_by_date: false,
        },
        methodType: 'get',
    },
    statesData: {
        url: APIEndPoints.getRTOAnalyticsData,
        errMsg: 'Unable to Fetch States Data',
        defaultParams: {
            filter_type: 'state_level',
            rto_segment: 'all',
            group_by_date: false,
        },
        methodType: 'get',
    },
    comparedStatesData: {
        url: APIEndPoints.getRTOAnalyticsData,
        errMsg: 'Unable to Fetch States Data',
        defaultParams: {
            filter_type: 'state_level',
            rto_segment: 'all',
            group_by_date: false,
        },
        methodType: 'get',
    },
    citiesData: {
        url: APIEndPoints.getRTOAnalyticsData,
        errMsg: 'Unable to Fetch Cities Data',
        defaultParams: {
            filter_type: 'city_level',
            rto_segment: 'all',
            group_by_date: false,
        },
        methodType: 'get',
    },
    comparedCitiesData: {
        url: APIEndPoints.getRTOAnalyticsData,
        errMsg: 'Unable to Fetch Cities Data',
        defaultParams: {
            filter_type: 'city_level',
            rto_segment: 'all',
            group_by_date: false,
        },
        methodType: 'get',
    },
    pincodesData: {
        url: APIEndPoints.getRTOAnalyticsData,
        errMsg: 'Unable to Fetch Pincodes Data',
        defaultParams: {
            filter_type: 'pincode_level',
            rto_segment: 'all',
            group_by_date: false,
        },
        methodType: 'get',
    },
    comparedPincodesData: {
        url: APIEndPoints.getRTOAnalyticsData,
        errMsg: 'Unable to Fetch Pincodes Data',
        defaultParams: {
            filter_type: 'pincode_level',
            rto_segment: 'all',
            group_by_date: false,
        },
        methodType: 'get',
    },
    //to be added later

    // productsData: {
    //     url: APIEndPoints.getTablesData,
    //     errMsg: 'Unable to Fetch Products Data',
    //     defaultParams: {
    //         merchant_id: 8,
    //         filter_type: 'product',
    //         from_date: '2024-10-01',
    //         to_date: '2024-10-31',
    //         page_size: 10,
    //         page: 1,
    //     },
    //     methodType: 'post',
    // },
    // comparedProductsData: {
    //     url: APIEndPoints.getTablesData,
    //     errMsg: 'Unable to Fetch Products Data',
    //     defaultParams: {
    //         merchant_id: 8,
    //         filter_type: 'product',
    //         from_date: '2024-10-01',
    //         to_date: '2024-10-31',
    //         page_size: 10,
    //         page: 1,
    //     },
    //     methodType: 'post',
    // },
}

export const fetchRtoDetailsData = async ({
    key,
    params,
}: {
    key: string
    nestedKey?: string
    params?: { [key: string]: any }
}) => {
    const { url, defaultParams, methodType, formatData } = rtoDetailApi[key]
    const response = await makeAPICall({
        method: methodType,
        url: process.env.RTO_DASHBOARD_SERVICE_URL + url,
        params: {
            ...(defaultParams || {}),
            ...(params || {}),
        },
        skipLoader: true,
        skipMode: true,
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
    })
    if (response.success) {
        if (response.data.statusCode === 500 && key == 'rtoInsights') {
            message.warning(`We're having trouble loading this right now. Please try again later.`)
        }
        let processedData = {
            fromDate: params.from_date,
            toDate: params.to_date,
            data: response.data.data,
        }

        if (formatData) {
            processedData = formatData(processedData)
        }

        return {
            success: true,
            data: processedData,
        }
    }
    return { error: true }
}

export const getStateCityPincode = async ({
    merchantId,
    fromDate,
    toDate,
    filterType,
    filterValue,
    clickedNode,
}: FetchParams): Promise<void> => {
    let responseReceived = false
    const intervalId = setInterval(() => {
        if (!responseReceived) {
            message.info({
                content: `Hang tight, we're working on it`,
                duration: 2,
            })
        }
    }, 3000)
    try {
        const response = await makeAPICall({
            method: 'get',
            url: process.env.RTO_DASHBOARD_SERVICE_URL + APIEndPoints.getStateCityPincode,
            params: {
                merchant_id: merchantId,
                from_date: fromDate,
                to_date: toDate,
                filter_type: filterType,
                filter_value: filterValue,
            },
            headers: {
                Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN ?? '',
            },
            skipLoader: true,
        })

        responseReceived = true
        clearInterval(intervalId)

        if (response.success) {
            if (response.data.status_code !== 200) {
                message.warning(`We're having trouble loading this right now. Please try again later.`)
            }

            const responseData = response?.data?.data

            if (response.data.status_code == 200 && (!responseData || responseData?.length == 0)) {
                message.warning(`No data available for the selected filters. Please adjust your search and try again.`)
            }

            if (filterType === FilterType.STATE) {
                updateCitiesRcaCardsData(
                    filterType,
                    sortedData.citiesData,
                    sortedData.comparedCitiesData,
                    responseData,
                    clickedNode,
                )
            } else {
                updatePincodesRcaCardsData(
                    filterType,
                    sortedData.pincodesData,
                    sortedData.comparedPincodesData,
                    responseData,
                    clickedNode,
                )
            }
        }
    } catch (error) {
        console.log('Error', error)
        responseReceived = true
        clearInterval(intervalId)
        throw error
    }
}
