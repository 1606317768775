import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { useAppDispatch } from '@library/utilities/hooks'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { fetchUserDetails } from '@store/user'
import { getMerchantDetails, getUserData } from '@store/user/selectors'
import { Button, CheckCircleOutlined, Col, CopyOutlined, message, Radio, Row, Tooltip } from 'gokwik-ui-kit'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { navigateToUrl } from 'single-spa'

export const Integration_2 = ({
    currentIntegrationScreen,
    setCurrentIntegrationScreen,
    manualThemeScript,
    manualThemeId,
}) => {
    const [autoValue, setAutoValue] = useState('')
    const [manualValue, setManualValue] = useState('')
    const merchant_details = useSelector(getMerchantDetails)
    const userDetails = useSelector(getUserData)
    const [manualCodeAdded, setManualCodeAdded] = useState(false)
    const [manualCodeAPIFail, setManualCodeAPIFail] = useState(false)
    // const [manualThemeId, setManualThemeId] = useState(null)
    const dispatch = useAppDispatch()
    const getStoreName = () => {
        const url = merchant_details?.website
        const storeName = url ? url?.split('.')[0] : url
        return storeName
    }
    const storeName = getStoreName()

    const onAutoChange = (e) => {
        setAutoValue(e.target.value)
    }
    const onManualChange = (e) => {
        setManualValue(e.target.value)
    }

    const handleAutoThemePageSelection = () => {
        if (autoValue && autoValue === 'connect_with_auto_support') {
            setCurrentIntegrationScreen('support_for_auto_theme')
            getSupport('theme_app_extension')
        } else {
            //configure pub
            logEvent(
                'kp_integration_extension_configure',
                'click',
                'intergration_2_screen',
                userDetails?.userDetails?.email,
                merchant_details?.m_id,
                merchant_details?.short_name,
            )
            dispatch(fetchUserDetails())
            navigateToUrl('/kwikpass/configuration')
        }
    }

    const handleManualThemePageSelection = () => {
        if (manualValue && manualValue === 'connect_with_manual_support') {
            setCurrentIntegrationScreen('support_for_manual_theme')
            getSupport('manual')
        } else {
            //configure pub
            logEvent(
                'kp_integration_manual_configure',
                'click',
                'intergration_2_screen',
                userDetails?.userDetails?.email,
                merchant_details?.m_id,
                merchant_details?.short_name,
            )
            dispatch(fetchUserDetails())
            navigateToUrl('/kwikpass/configuration')
        }
    }

    const getSupport = async (themeImplementationType) => {
        let url
        if (process.env.NODE_ENV === 'development') {
            url = 'https://api-gw-v4.dev.gokwik.in/dev/kp/api/v1/shopify-app/contact-support'
        } else if (window.location.href?.includes('qa-')) {
            url = 'https://api-gw-v4.dev.gokwik.in/qa/kp/api/v1/shopify-app/contact-support'
        } else {
            url = 'https://gkx.gokwik.co/kp/api/v1/shopify-app/contact-support'
        }
        const response = await makeAPICall({
            method: 'get',
            url: url,
            params: {
                shop: merchant_details?.website,
                shopifyPlan: 'Premium', //this value will come from API
                shopifyImplementation: themeImplementationType,
            },
            headers: { 'kp-merchant-id': merchant_details?.m_id },
        })

        if (response?.data?.status_code === 200) {
            logEvent(
                themeImplementationType === 'manual' ? 'kp_integration_manual_help' : 'kp_integration_extension_help',
                'click',
                'intergration_2_screen',
                userDetails?.userDetails?.email,
                merchant_details?.m_id,
                merchant_details?.short_name,
            )
            message.success('Thanks for contacting. We will get back to you shortly !')
        } else {
            message.error('Could not connect support. Try again later !')
        }
    }

    const handleCopy = (textToCopy) => {
        try {
            logEvent(
                'kp_integration_manual_copy_code',
                'click',
                'intergration_2_screen',
                userDetails?.userDetails?.email,
                merchant_details?.m_id,
                merchant_details?.short_name,
            )
            navigator.clipboard.writeText(textToCopy)
            message.success('Code Copied')
        } catch (err) {
            console.error('Unable to copy to clipboard', err)
            message.error('Failed to copy to clipboard')
        }
    }

    const activateAutoTheme = async () => {
        const response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.shopifyThemeExtensionURL,
        })

        if (response?.data?.status_code === 200 && response?.data?.data?.theme_extension_url) {
            window.open(response?.data?.data?.theme_extension_url)
        } else {
            message.error('Theme extension URL could not be generated. Couldn’t redirect to shopify themes page')
        }
    }

    const addCodeToStore = async () => {
        const response = await makeAPICall({
            method: 'get',
            url: 'https://api-gw-v4.dev.gokwik.in/dev/kp/api/v1/shopify-app/add-snippet',
            headers: { 'kp-merchant-id': merchant_details?.m_id },
        })

        if (response?.data?.status_code === 200 && response?.data?.data?.asset?.theme_id) {
            // setManualThemeId(response?.data?.data?.asset?.theme_id)
            setManualCodeAdded(true)
        } else {
            setManualCodeAPIFail(true)
        }
    }

    const IntegrationsMap = {
        add_theme_automatically: (
            <>
                <div className='flex flex-col gap-5'>
                    <div className='flex flex-col gap-4'>
                        <div className='text-xl font-semibold'>Step 4.1: Add Shopify App Extension</div>
                        <p className='text-neutral-500 font-normal'>
                            Use this when you’re using Dawn theme. Ensure to <strong>save</strong> the changes after the
                            KwikPass power button becomes visible on your theme.
                        </p>
                        <Button className='w-1/2' variant='primary' onClick={activateAutoTheme}>
                            Activate Theme App Extension
                        </Button>
                    </div>

                    <div className=''>
                        <Radio.Group className='flex flex-col gap-6' onChange={onAutoChange} value={autoValue}>
                            <Radio value={'auto_theme_extension_selected'}>
                                I have checked the placement of the button on my website{' '}
                            </Radio>
                            <Radio value={'connect_with_auto_support'}>I have trouble with the placement/preview</Radio>
                        </Radio.Group>
                    </div>

                    <div className={!autoValue && 'hidden'}>
                        <Button variant='primary' onClick={handleAutoThemePageSelection}>
                            {autoValue === 'connect_with_auto_support'
                                ? 'Connect with support'
                                : 'Configure and finish'}
                        </Button>
                    </div>
                </div>
            </>
        ),

        support_for_auto_theme: (
            <>
                <div className='flex flex-col gap-4'>
                    <div className='text-xl font-semibold'>Step 4.2: Support for Adding Shopify App Extension</div>
                    <p className='text-neutral-500 font-normal italic'>
                        Our Customer Support team will reach out to you within 48 business hours. Keep an eye out for
                        our email or phone call!
                    </p>
                    <p>Once your issue is resolved, and you have tested the flow, let’s configure the pop!</p>

                    <Button
                        className='w-1/2'
                        variant='primary'
                        onClick={() => {
                            dispatch(fetchUserDetails())
                            navigateToUrl('/kwikpass/configuration')
                        }}
                    >
                        Configure and finish
                    </Button>
                </div>
            </>
        ),
        auto_theme_app_guide: {
            header: 'Integrating by Theme App Extension',
            sub_header: 'How to add theme app extension?',
            video_link: 'https://assets.gokwik.co/videos/Copy%20of%20Theme%20App%20Extension.mp4',
        },
        add_theme_manually: (
            <>
                <div className='flex flex-col gap-5'>
                    <div className='flex flex-col gap-4'>
                        <div className='text-xl font-semibold'>
                            Step 4.1: Manually Integrate our KwikPass app with your Shopify Store
                        </div>

                        {/* <div className='flex flex-col'>
                            <Tooltip
                                title='Click to copy script'
                                className='flex items-end relative left-[92%] top-10 text-white !z-50'
                            >
                                <CopyOutlined
                                    onClick={() => handleCopy(`${manualThemeScript}`)}
                                    className='cursor-pointer text-2xl'
                                />
                            </Tooltip>
                            <div>
                                {manualThemeScript ? (
                                    <div className='bg-gray-900 text-white p-2 rounded-lg max-h-32 overflow-scroll relative'>
                                        <pre className='whitespace-pre-wrap'>{manualThemeScript}</pre>
                                        <div className='absolute bottom-0 left-0 h-6 bg-gradient-to-t from-gray-900 to-transparent'></div>
                                    </div>
                                ) : (
                                    <span className='text-red-400 font-semibold'>
                                        Script for manual theme could not be generated. Something went wrong !
                                    </span>
                                )}
                            </div>
                        </div> */}

                        {/* <div>
                            You can choose to test the code on a test theme, OR you can choose to directly configure it
                            and launch.
                        </div> */}

                        <ol type='a' className='pl-0'>
                            <li className='mb-8'>
                                {/* <div className='flex flex-col gap-2'>
                                    <p className='font-normal'>
                                        Click on the button below and we will automatically add the KwikPass code to
                                        your store.
                                    </p>
                                    <div className='flex gap-2 items-center'>
                                        <Button variant='primary' disabled={manualCodeAdded} onClick={addCodeToStore}>
                                            Add code to your Shopify store
                                        </Button>

                                        {manualCodeAdded && (
                                            <span>
                                                Code added&nbsp;
                                                <CheckCircleOutlined className='text-green-400' />
                                            </span>
                                        )}
                                    </div>
                                </div> */}

                                <div className='flex flex-col'>
                                    <div className='font-normal'>
                                        Copy the below code. Go to Shopify via below button, make a <b>new file</b>{' '}
                                        within <b>snippets</b> called&nbsp;
                                        <b>kwikpass</b>.liquid, paste the code
                                    </div>

                                    <CopyOutlined
                                        onClick={() => handleCopy(`${manualThemeScript}`)}
                                        className='flex relative left-[62%] top-10 text-white !z-50 cursor-pointer text-2xl w-[5%]'
                                    />

                                    <div>
                                        {manualThemeScript ? (
                                            <div className='flex items-center gap-1'>
                                                <div className='bg-gray-900 text-white p-2 rounded-lg max-h-14 overflow-scroll relative'>
                                                    <pre className='whitespace-pre-wrap'>{manualThemeScript}</pre>
                                                    <div className='absolute bottom-0 left-0 h-2 bg-gradient-to-t from-gray-900 to-transparent'></div>
                                                </div>
                                                <Button
                                                    className='w-1/2'
                                                    variant='primary'
                                                    onClick={() => {
                                                        setManualCodeAdded(true)
                                                        window.open(
                                                            `https://admin.shopify.com/store/${storeName}/themes/${manualThemeId}?key=layout%2Ftheme.liquid`,
                                                            '_blank',
                                                            'noopener,noreferrer',
                                                        )
                                                    }}
                                                >
                                                    Go to Shopify
                                                </Button>
                                            </div>
                                        ) : (
                                            <span className='text-red-400 font-semibold'>
                                                Script for manual theme could not be generated. Something went wrong !
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </li>

                            {manualCodeAPIFail && (
                                <>
                                    <div className='flex flex-col gap-2'>
                                        <p className='text-red-400'>
                                            Oh no! Looks like the above step failed. Please click on the below{' '}
                                            <b>Connect with Support</b> button and, we'd be in touch.
                                        </p>
                                        <Button
                                            className='w-2/5'
                                            variant='primary'
                                            onClick={() => {
                                                setManualValue('connect_with_manual_support')
                                                getSupport('manual')
                                            }}
                                        >
                                            Connect with support
                                        </Button>
                                    </div>

                                    <br />

                                    {manualValue === 'connect_with_manual_support' && (
                                        <div className='text-sm italic'>
                                            Our Customer Support team will reach out to you within 48 business hours.
                                            Keep an eye out for our email or phone call!
                                        </div>
                                    )}
                                </>
                            )}

                            {manualCodeAdded && (
                                <li className='mb-8'>
                                    <div className='flex flex-col gap-2'>
                                        {/* <div>
                                            Now add the below code to theme.liquid (Just refer to the video adjoining.
                                            Trust us, we've kept it simple)
                                        </div> */}

                                        <div>
                                            Once the above is done, copy the below code, and add it to the theme.liquid
                                            file. How? Search for /body within <b>theme.liquid</b> and right before{' '}
                                            <b>/body</b>, add the code. Then, press enter. Refer to the video to cross
                                            verify that your code is added well. This code makes our popup live on your
                                            website - replacing your current account page action!
                                        </div>

                                        <div className='flex gap-1'>
                                            <div className='bg-gray-300 text-white rounded-lg w-1/2 flex items-center'>
                                                <span className='p-1 text-black'>{"{% render 'kwikpass' %}"}</span>
                                            </div>
                                            <Button
                                                variant='primary'
                                                onClick={() => handleCopy(`{% render 'kwikpass' %}`)}
                                            >
                                                Copy Code
                                            </Button>
                                        </div>

                                        <Button
                                            className='w-1/2'
                                            variant='primary'
                                            onClick={() =>
                                                window.open(
                                                    `https://admin.shopify.com/store/${storeName}/themes/${manualThemeId}?key=layout%2Ftheme.liquid`,
                                                    '_blank',
                                                    'noopener,noreferrer',
                                                )
                                            }
                                        >
                                            Go to Shopify to add code
                                        </Button>
                                    </div>
                                </li>
                            )}

                            {manualCodeAdded && (
                                <li>
                                    <div className='flex flex-col gap-2'>
                                        {/* <p>
                                            Is the above code added? <strong>If yes</strong>, then let's move to test
                                            KwikPass! You're{' '}
                                            <strong>
                                                90% <span className='text-green-400'>done</span>
                                            </strong>
                                        </p> */}
                                        <div>
                                            Were you able to seamlessly add the above codes? Go to your live theme, and
                                            you'd see our popup is working as expected.
                                        </div>
                                        <div className=''>
                                            <Radio.Group
                                                className='flex flex-col gap-2'
                                                onChange={onManualChange}
                                                value={manualValue}
                                            >
                                                <Radio value={'manual_theme_extension_selected'}>
                                                    Added the code to the store and tested
                                                </Radio>
                                                <Radio value={'connect_with_manual_support'}>
                                                    Added the code to the store but need help
                                                </Radio>
                                            </Radio.Group>
                                        </div>

                                        <div className={!manualValue && 'hidden'}>
                                            <Button variant='primary' onClick={handleManualThemePageSelection}>
                                                {manualValue === 'connect_with_manual_support'
                                                    ? 'Connect with support'
                                                    : 'Configure and finish'}
                                            </Button>
                                        </div>
                                    </div>
                                </li>
                            )}
                        </ol>
                    </div>
                </div>
            </>
        ),
        support_for_manual_theme: (
            <>
                <div className='flex flex-col gap-14'>
                    <div className='flex flex-col gap-4'>
                        <div className='text-xl font-semibold'>
                            Step 4.2: Support for Manual Integration with Shopify App
                        </div>
                        {/* <p className='text-neutral-500 font-normal'>
                            Click on the button below and we will automatically add the KwikPass code to your store.
                        </p> */}
                        {/* <div className='flex flex-col'>
                            <Tooltip
                                title='Click to copy script'
                                className='flex items-end relative left-[92%] top-10 text-white !z-50'
                            >
                                <CopyOutlined
                                    onClick={() => handleCopy(`${manualThemeScript}`)}
                                    className='cursor-pointer text-2xl'
                                />
                            </Tooltip>
                            <div>
                                {manualThemeScript ? (
                                    <div className='bg-gray-900 text-white p-2 rounded-lg max-h-32 overflow-scroll relative'>
                                        <pre className='whitespace-pre-wrap'>{manualThemeScript}</pre>
                                        <div className='absolute bottom-0 left-0 h-6 bg-gradient-to-t from-gray-900 to-transparent'></div>
                                    </div>
                                ) : (
                                    <span className='text-red-400 font-semibold'>
                                        Script for manual theme could not be generated. Something went wrong !
                                    </span>
                                )}
                            </div>
                        </div> */}
                    </div>
                    <div className='flex flex-col gap-4'>
                        <p className='text-neutral-500 font-normal italic'>
                            <span>
                                Our Customer Support team will reach out to you within 48 business hours. Keep an eye
                                out for our email or phone call!
                            </span>
                        </p>
                        <p>Once your issue is resolved, and you have tested the flow, let’s configure the pop!</p>
                        <Button
                            className='w-1/2'
                            variant='primary'
                            onClick={() => {
                                dispatch(fetchUserDetails())
                                navigateToUrl('/kwikpass/configuration')
                            }}
                        >
                            Configure and finish
                        </Button>
                    </div>
                </div>
            </>
        ),
        manual_theme_app_guide: {
            header: 'Manual Integration',
            sub_header: 'How to add the code to your shopify store?',
            video_link: 'https://assets.gokwik.co/videos/NV_KP.mp4',
        },
    }

    const getGuide = useMemo(() => {
        if (currentIntegrationScreen?.includes('auto')) {
            return IntegrationsMap['auto_theme_app_guide']
        } else {
            return IntegrationsMap['manual_theme_app_guide']
        }
    }, [currentIntegrationScreen])

    return (
        <div className='mt-6 mx-12 p-4 rounded-lg bg-white'>
            <Row gutter={32}>
                <Col span={12}>
                    <div className='flex flex-col gap-6 h-full'>
                        <div>
                            <span className='text-xl font-semibold'>{getGuide?.header}</span> <br />
                            <span className='text-slate-500 text-s'>{getGuide?.sub_header}</span>
                        </div>

                        <div className='rounded w-full text-center'>
                            <video key={1} width='100%' autoPlay controls={true} loop>
                                <source src={getGuide?.video_link} type='video/mp4' />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className='h-full'>{IntegrationsMap[currentIntegrationScreen]}</div>
                </Col>
            </Row>
        </div>
    )
}
