import {
    actionInputOptions,
    actionInputOptionsWithFreebies,
    actionTypes,
    bulkUploadCheck,
    fullUppercaseWords,
    ppcodActionType,
    tieringFreeConditions,
} from '../constants/constants'
import {
    SearchFilters,
    FilterCreatorModel,
    SubCategory,
    ActionType,
    Workflow,
    WorkflowAction,
    Condition,
    RuleRawData,
} from '../interface'
import AllowCod from '@library/images/allowCod.svg'
import CodBlocking from '@library/images/blockCod.svg'
import CodCaptcha from '@library/images/codCaptcha.svg'
import CodFees from '@library/images/codFees.svg'
import CodPrompt from '@library/images/codPrompt.svg'
import PpcodUpi from '@library/images/partialCod.svg'
import UpiDiscount from '@library/images/upiDiscount.svg'
import dayjs from 'dayjs'
import ApplyFreebie from '@library/images/applyFreebie.svg'
import { downloadToCSV } from '@gokwik/utilities'

const getDynamicSearchFilters = (searchFilters: SearchFilters[]): FilterCreatorModel => {
    const filterCreatorState: FilterCreatorModel = {
        title: 'Add More Filters +',
        options: searchFilters
            ?.filter((item) => item?.is_dynamic)
            ?.map((filter) => {
                return {
                    id: filter?.id,
                    text: filter?.label,
                }
            }),
    }
    return filterCreatorState
}

const capitalize = (str: string) => {
    return str
        .split(' ')
        .map((word) => {
            if (fullUppercaseWords.includes(word.toLowerCase())) {
                return word.toUpperCase()
            } else {
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            }
        })
        .join(' ')
}

const getSelectValue = (value: string | number): string | undefined => {
    return typeof value === 'string' && value.length > 0 ? value : undefined
}

const isEqualString = (val1: string, val2: string): boolean => {
    return val1 && val2 ? val1.toLowerCase() === val2.toLowerCase() : false
}

const isValidNumber = (value: number) => {
    return typeof value === 'number' && !isNaN(value)
}

const inputNumberFormatter = (value: string) => {
    return value ? (Math.round(Number(value) * 100) / 100).toString() : ''
}

const inputNumberParser = (value: string) => {
    return value && Math.round(Number(value) * 100) / 100
}

const getSubCategory = (workflow: string) => {
    let subCategory: SubCategory[] | undefined
    switch (workflow) {
        case 'CUSTOMER':
            subCategory = [
                { name: 'Phone no.', value: 'phone_number' },
                { name: 'Name', value: 'billing_name' },
                { name: 'Email', value: 'billing_email' },
                // { name: 'Past delivery', value: 'past_delivery' },
                // { name: 'Past Shipped', value: 'past_shipped' },
                { name: 'COD Order Rate Limiter', value: 'cod_rate' },
                { name: 'Duplicate Order', value: 'duplicate_order' },
                { name: 'Customer Type', value: 'customer_type' },
                { name: 'Past RTO', value: 'customer_past_rto' },
                { name: 'Customer Since', value: 'customer_since_dt' },
            ]
            break
        case 'ADDRESS':
            subCategory = [
                { name: 'Shipping State', value: 'shipping_state' },
                { name: 'Shipping City', value: 'shipping_city' },
                { name: 'Pincode', value: 'pincode' },
                { name: 'Address Length', value: 'address_length' },
            ]
            break
        case 'CART':
            subCategory = [
                { name: 'Cart Value', value: 'cart_value' },
                { name: 'Total Line Items', value: 'total_line_items' },
                { name: 'GST Tag', value: 'is_gst_cart' },
            ]
            break
        case 'UTM':
            subCategory = [
                { name: 'Source', value: 'utm_source' },
                { name: 'Medium', value: 'utm_medium' },
                { name: 'Campaign', value: 'utm_campaign' },
            ]
            break
        case 'SKU':
            subCategory = [
                { name: 'SKU ID', value: 'sku_ids' },
                { name: 'Product ID', value: 'product_id' },
                { name: 'Product', value: 'product_title' },
                { name: 'Product Variant', value: 'variant_ids' },
            ]
            break
        case 'RTO_RISK':
            subCategory = [
                { name: 'Risk Flag', value: 'risk_flag' },
                { name: 'Risk Probability', value: 'rto_score' },
                { name: 'Risk Reason', value: 'risk_reason' },
            ]
        default:
            break
    }

    return subCategory
}

const getSecondaryActionInputs = (primaryAction: string) => {
    switch (primaryAction) {
        case 'cod_fees':
            return [
                { value: 'cod_prompt', label: 'COD Prompt' },
                { value: 'cod_captcha', label: 'COD Captcha' },
                { value: 'upi_discount', label: 'UPI Discount' },
            ]
        case 'cod_blocking':
            return [{ value: 'upi_discount', label: 'UPI Discount' }]
        case 'cod_prompt':
            return [
                { value: 'cod_fees', label: 'COD Fees' },
                { value: 'upi_discount', label: 'UPI Discount' },
            ]
        case 'cod_captcha':
            return [
                { value: 'cod_fees', label: 'COD Fees' },
                { value: 'upi_discount', label: 'UPI Discount' },
            ]
        case 'allow_cod':
            return []
        case 'ppcod_upi':
            return [{ value: 'upi_discount', label: 'UPI Discount' }]
        case 'upi_discount':
            return [
                { value: 'cod_blocking', label: 'COD Blocking' },
                { value: 'cod_fees', label: 'COD Fees' },
                { value: 'cod_prompt', label: 'COD Prompt' },
                { value: 'cod_captcha', label: 'COD Captcha' },
                { value: 'ppcod_upi', label: 'Partial COD' },
            ]
        case 'apply_freebie':
            return [
                { value: 'cod_blocking', label: 'COD Blocking' },
                { value: 'cod_prompt', label: 'COD Prompt' },
                { value: 'cod_captcha', label: 'COD Captcha' },
                { value: 'ppcod_upi', label: 'Partial COD' },
            ]
    }
}

const getTertiaryActionInputs = (primaryAction: string, secondaryAction: string) => {
    const actionMap: { [key: string]: { [key: string]: { value: string; label: string }[] } } = {
        cod_captcha: {
            cod_fees: [{ value: 'upi_discount', label: 'UPI Discount' }],
            upi_discount: [{ value: 'cod_fees', label: 'COD Fees' }],
        },
        cod_prompt: {
            cod_fees: [{ value: 'upi_discount', label: 'UPI Discount' }],
            upi_discount: [{ value: 'cod_fees', label: 'COD Fees' }],
        },
        cod_fees: {
            cod_prompt: [{ value: 'upi_discount', label: 'UPI Discount' }],
            cod_captcha: [{ value: 'upi_discount', label: 'UPI Discount' }],
        },
        upi_discount: {
            cod_captcha: [{ value: 'cod_fees', label: 'COD Fees' }],
            cod_prompt: [{ value: 'cod_fees', label: 'COD Fees' }],
        },
    }

    // Return the corresponding action inputs or an empty array if not found
    return actionMap[primaryAction]?.[secondaryAction] || []
}

const shouldShowTertiaryOption = (actionType: ActionType, secondaryType: ActionType): boolean => {
    if (!actionType.action) return true
    const primaryAction = actionType.action
    const secondaryAction = secondaryType?.action

    if (['allow_cod', 'apply_freebie', 'cod_blocking', 'ppcod_upi'].includes(primaryAction)) {
        return false
    }

    if (primaryAction === 'cod_fees' && secondaryAction === 'upi_discount') {
        return false
    }

    if (primaryAction === 'upi_discount' && ['cod_blocking', 'cod_fees', 'ppcod_upi'].includes(secondaryAction)) {
        return false
    }

    return true
}

const convertIfNumber = (str: string): string | number => {
    if (/\d/.test(str)) {
        if (/^\d+(\.\d+)?$/.test(str)) {
            return parseFloat(str)
        }
        return str
    }
    return str
}

const getPreviewModalTitle = (action: string) => {
    switch (action) {
        case 'allow_cod':
            return 'Allow COD Preview'
        case 'cod_blocking':
            return 'COD Blocking Preview'
        case 'cod_captcha':
            return 'COD Captcha Preview'
        case 'cod_fees':
            return 'COD Fees Preview'
        case 'cod_prompt':
            return 'COD Prompt Preview'
        case 'ppcod_upi':
            return 'Partial COD Preview'
        case 'upi_discount':
            return 'UPI Discount Preview'
        case 'apply_freebie':
            return 'Freebie Preview'
        default:
            return 'RTO Actions Preview'
    }
}

const getPreviewModalAlert = (action: string) => {
    switch (action) {
        case 'allow_cod':
            return 'In this case, COD will be available without any additional charge or friction.'
        case 'cod_blocking':
            return 'In this case, COD will be disable for the user.'
        case 'cod_captcha':
            return 'In this case, Customer has to enter a 4 digit number before COD order is placed. This helps in reducing RTO by adding friction for the user for completing payment.'
        case 'cod_fees':
            return 'In this case, The amount you decide below will be added to the order value and will be collected at the time of delivery. This helps in shifting user intent towards prepaid method.'
        case 'cod_prompt':
            return 'In this case, The user is asked to confirm the order. This helps in reducing COD orders placed by mistake which reduces cancellations and RTOs.'
        case 'ppcod_upi':
            return ' In this case, The amount you decide below is collected upfront in form of UPI payment and non refundable for user. This helps in reducing RTO and also helps you get nominal amount back in case a customer does RTO.'
        case 'upi_discount':
            return 'In this case, The discount % you decide will be reflected in the UPI payment mode. This helps in shifting user intent towards prepaid method.'
        case 'apply_freebie':
            return "Once a discount code is created in the backend and linked to RTO Actions, it will appear here along with the payment method it's configured for. Simply select the relevant discount codes. Once enabled, the freebie will be applied to the selected user segment for the specified payment methods associated with the discount codes entered."
        default:
            return 'Please select an option for preview.'
    }
}

const imageMapping: { [key: string]: string } = {
    allow_cod: AllowCod,
    cod_blocking: CodBlocking,
    cod_captcha: CodCaptcha,
    cod_fees: CodFees,
    cod_prompt: CodPrompt,
    ppcod_upi: PpcodUpi,
    upi_discount: UpiDiscount,
    apply_freebie: ApplyFreebie,
}

const getMultiSelectOptions = (options: string[]) => {
    return options.map((option) => {
        return {
            value: option,
            label: option,
        }
    })
}
const shouldHideCreateButton = (
    storeActionType: ActionType[],
    abTestEnabledOn,
    actionTypeB: ActionType,
    abTestToggle: boolean,
) => {
    const hasStoreActionType = storeActionType && storeActionType.length > 0
    const isAllowCodActionPresent =
        hasStoreActionType && storeActionType.some((actionType) => actionType.action === actionTypes.allowCod)
    return (
        !hasStoreActionType || (isAllowCodActionPresent && !((actionTypeB.action && abTestEnabledOn) || abTestToggle))
    )
}

const isValidPPCODConfig = (actionType: ActionType) => {
    if (actionType.action === actionTypes.ppcodUpi) {
        const ppcodConfig = actionType.ppcod_config?.[0]
        return ppcodConfig?.deductionType === ppcodActionType.fixed
            ? ppcodConfig.fixedDeduction
                ? true
                : false
            : ppcodConfig?.minimumDeduction && ppcodConfig?.maximumDeduction
            ? true
            : false
    }
    return false
}

const isValidCODFeeConfig = (actionType: ActionType) => {
    return actionType.action === actionTypes.codFee && actionType.cod_fees !== null && actionType.cod_fees !== undefined
}

const isValidUPIDiscountConfig = (actionType: ActionType) => {
    return actionType.action === actionTypes.upiDiscount && actionType.upi_discount
}

const isValidApplyFreebieConfig = (actionType: ActionType) => {
    return (
        actionType.action === actionTypes.applyFreebie &&
        actionType.apply_freebie_config &&
        actionType.apply_freebie_config.length > 0
    )
}

const getDefaultAbShopifyFlag = () => {
    return `Gokwik_ABTest${Math.floor(Math.random() * 999) + 1}`
}

const getActionNames = (actions: ActionType[]) => {
    const actionNames = actions.map((actionItem: ActionType) => {
        const actionMatched = actionInputOptionsWithFreebies.find((item) => item.value === actionItem.action)
        if (actionMatched) {
            return actionMatched?.label
        }
    })
    return actionNames.join(' and ')
}
const getActionNameByWorkflowResponse = (workflow: Workflow) => {
    const rules = workflow?.rules?.[0] ?? {}
    //@ts-ignore
    const actions = rules.raw_data?.actions ?? []

    return getActionNames(actions)
}

const getAbTestControlActionName = (workflow: Workflow) => {
    const abTestControlAction = workflow?.ab_control_actions ?? []
    return getActionNames(abTestControlAction)
}

const getAbControlActions = (
    actionType: ActionType,
    secondaryActionType: ActionType,
    tertiaryActionType: ActionType,
) => {
    return [
        ...(actionType.action
            ? [
                  {
                      action: actionType.action ?? actionTypes.allowCod,
                      actionConfigs: {
                          ...((({ action, ...rest }) => rest)(actionType) ?? null), // Exclude action key
                      },
                  },
              ]
            : []),
        ...(secondaryActionType?.action
            ? [
                  {
                      action: secondaryActionType.action,
                      actionConfigs: {
                          ...(({ action, ...rest }) => rest)(secondaryActionType), // Exclude action key
                      },
                  },
              ]
            : []),
        ...(tertiaryActionType?.action
            ? [
                  {
                      action: tertiaryActionType.action,
                      actionConfigs: {
                          ...(({ action, ...rest }) => rest)(tertiaryActionType), // Exclude action key
                      },
                  },
              ]
            : []),
    ]
}

const getAbSingleInterventionPrimaryActionInputs = (
    typeA: boolean,
    workflowAction: WorkflowAction,
    workflowActionB: WorkflowAction,
    actionType: ActionType,
) => {
    const currentAction = typeA ? workflowActionB : workflowAction
    const availableActions = actionInputOptions.filter(
        (action) =>
            action.isNumeric ||
            action.value !== currentAction.primary.action ||
            currentAction.primary.action === actionType.action,
    )
    return availableActions
}

const getAbPrimaryActionInputs = (
    typeA: boolean,
    workflowAction: WorkflowAction,
    workflowActionB: WorkflowAction,
    actionType: ActionType,
) => {
    // If single intervention on both sides
    const isSingleIntervention =
        (!workflowAction?.secondary?.action && !workflowActionB?.secondary?.action) ||
        (typeA ? !workflowActionB.secondary?.action : !workflowAction.secondary?.action)
    if (isSingleIntervention) {
        return getAbSingleInterventionPrimaryActionInputs(typeA, workflowAction, workflowActionB, actionType)
    }

    // If double intervention on one/both side
    return actionInputOptions
}

function deepEqual(obj1, obj2, visited = new WeakSet()) {
    if (obj1 === obj2) return true
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) return false
    if (visited.has(obj1) || visited.has(obj2)) return false

    visited.add(obj1)
    visited.add(obj2)

    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)
    if (keys1.length !== keys2.length) return false

    for (let key of keys1) {
        if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key], visited)) {
            return false
        }
    }

    return true
}

const validateActionType = (actionType: ActionType) => {
    const actionValidationMap = {
        [actionTypes.codFee]: isValidCODFeeConfig,
        [actionTypes.ppcodUpi]: isValidPPCODConfig,
        [actionTypes.upiDiscount]: isValidUPIDiscountConfig,
        [actionTypes.applyFreebie]: isValidApplyFreebieConfig,
    }
    const validateAction = actionValidationMap[actionType.action]
    return validateAction ? !validateAction(actionType) : false
}

const shouldHideWorkflowForDuplicateOrders = (workflow: Workflow) => {
    return workflow?.rules?.some((rule) =>
        rule?.raw_data?.conditions?.some((condition: Condition) => condition?.rto_rule_type === 'DuplicateOrder'),
    )
        ? true
        : false
}

const numberFormatter = (value: number, type: 'number' | 'percentage'): string => {
    if (type === 'percentage') {
        return `${value}%`
    } else {
        // Assuming type is 'number'
        if (value >= 1000000000) {
            return `${(value / 1000000000).toFixed(1).replace(/\.0$/, '')}B`
        }
        if (value >= 1000000) {
            return `${(value / 1000000).toFixed(1).replace(/\.0$/, '')}M`
        }
        if (value >= 1000) {
            return `${(value / 1000).toFixed(1).replace(/\.0$/, '')}K`
        }
        return value.toString()
    }
}

const convertUtcToIst = (utcDate) => {
    const date = new Date(utcDate)
    const istDate = new Date(date.getTime() + 19800000)
    const formattedDate = istDate.toISOString()
    return formattedDate.replace('Z', '+05:30')
}

const formatDateTimeframe = (date, timeframe) => {
    if (timeframe === 'weekly') {
        return dayjs(date).format('YYYY-MM-DD')
    } else if (timeframe === 'monthly') {
        return dayjs(date).format('MMMM YYYY')
    } else if (timeframe === 'quarterly') {
        if (!/^Q\d \d{4}$/.test(date)) {
            const quarter = Math.floor((dayjs(date).month() + 3) / 3)
            return `Q${quarter} ${dayjs(date).year()}`
        }
        return date;
    }
}

const getDifference = (oldValue: Workflow | undefined, newValue: Workflow | undefined) => {
    const differences: { field: string; old_value: any; new_value: any }[] = []

    const formatArrayOfObjects = (array) => {
        if (Array.isArray(array) && array.length > 0) {
            return array.map((item) => JSON.stringify(item, null, 2)).join(', ')
        }
        return 'No value'
    }

    const compareObjects = (oldObj: Workflow | undefined, newObj: Workflow | undefined, path: string) => {
        const allKeys = new Set<string>([...Object.keys(oldObj || {}), ...Object.keys(newObj || {})])

        allKeys.forEach((key) => {
            const newPath = path ? `${path}.${key}` : key
            const oldVal = oldObj ? oldObj[key as keyof Workflow] : undefined
            const newVal = newObj ? newObj[key as keyof Workflow] : undefined

            if (key === 'values' && Array.isArray(oldVal) && Array.isArray(newVal)) {
                if (oldVal.length !== newVal.length) {
                    differences.push({
                        field: formatFieldName(newPath),
                        old_value: Array.isArray(oldVal)
                            ? formatArrayOfObjects(oldVal)
                            : oldVal !== null && oldVal !== undefined
                            ? oldVal
                            : 'No value',
                        new_value: Array.isArray(newVal)
                            ? formatArrayOfObjects(newVal)
                            : newVal !== null && newVal !== undefined
                            ? newVal
                            : 'No value',
                    })
                } else {
                    compareArrays(oldVal, newVal, newPath)
                }
            } else if (Array.isArray(newVal)) {
                compareArrays(oldVal as any[], newVal, newPath)
            } else if (typeof newVal === 'object' && newVal !== null) {
                compareObjects(oldVal as Workflow, newVal as Workflow, newPath)
            } else if (oldVal !== newVal) {
                differences.push({
                    field: formatFieldName(newPath),
                    old_value: Array.isArray(oldVal)
                        ? formatArrayOfObjects(oldVal)
                        : oldVal !== null && oldVal !== undefined
                        ? oldVal
                        : 'No value',
                    new_value: Array.isArray(newVal)
                        ? formatArrayOfObjects(newVal)
                        : newVal !== null && newVal !== undefined
                        ? newVal
                        : 'No value',
                })
            }
        })
    }

    const compareArrays = (oldArr: any[], newArr: any[], path: string) => {
        for (let i = 0; i < Math.max(oldArr?.length || 0, newArr?.length || 0); i++) {
            const newPath = `${path}[${i}]`
            const oldVal = oldArr ? oldArr[i] : undefined
            const newVal = newArr ? newArr[i] : undefined

            if (Array.isArray(newVal)) {
                compareArrays(oldVal as any[], newVal, newPath)
            } else if (typeof newVal === 'object' && newVal !== null) {
                compareObjects(oldVal as Workflow, newVal as Workflow, newPath)
            } else if (oldVal !== newVal) {
                differences.push({
                    field: formatFieldName(newPath),
                    old_value: Array.isArray(oldVal)
                        ? formatArrayOfObjects(oldVal)
                        : oldVal !== null && oldVal !== undefined
                        ? oldVal
                        : 'No value',
                    new_value: Array.isArray(newVal)
                        ? formatArrayOfObjects(newVal)
                        : newVal !== null && newVal !== undefined
                        ? newVal
                        : 'No value',
                })
            }
        }
    }

    const formatFieldName = (path: string): string => {
        const ruleMatch = path.match(/rules\[(\d+)\]/)
        if (ruleMatch) {
            const ruleIndex = parseInt(ruleMatch[1], 10) + 1

            const conditionMatch = path.match(/conditions\[(\d+)\]/)
            let conditionPart = ''
            if (conditionMatch) {
                const conditionIndex = parseInt(conditionMatch[1], 10) + 1
                conditionPart = `Sub-condition ${conditionIndex}`
            }

            const actionsIndexMatch = path.match(/actions\[(\d+)\]/)
            let actionType = ''
            if (actionsIndexMatch) {
                const actionIndex = parseInt(actionsIndexMatch[1], 10)
                actionType = actionIndex === 0 ? 'Primary action' : 'Secondary action'
            }

            const cleanPath = path.replace(/\[\d+\]/g, '')
            const pathParts = cleanPath.split('.')
            const lastPathPart = pathParts.pop()

            let formattedName = `Condition ${ruleIndex}`
            if (conditionPart) {
                formattedName += ` - ${conditionPart}`
            }
            if (actionType) {
                formattedName += ` - ${actionType}`
            }
            formattedName += ` - ${lastPathPart}`

            return formattedName
        }
        return path
    }

    compareObjects(oldValue, newValue, '')
    return differences
}

const getAbTestImpactInfo = (createdAt: string, abTestEnabledOn: string) => {
    const ninetyDaysAgo = new Date()
    ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90)

    const abTestDate = new Date(abTestEnabledOn)
    const date = new Date(createdAt)
    const now = new Date()

    const formattedTime = date.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    })

    const timeDiff = now.getTime() - date.getTime()
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24))

    let message = ''

    if (daysDiff === 0 && now > date) {
        message = `Last updated at ${formattedTime}. Data refreshes daily before 6 PM.`
    } else if (daysDiff === 1) {
        message = `Last updated yesterday at ${formattedTime}. Data refreshes daily before 6 PM.`
    } else {
        const dayWord = daysDiff === 1 ? 'day' : 'days'
        message = `Last updated ${daysDiff} ${dayWord} ago at ${formattedTime}. Data refreshes daily before 6 PM.`
    }

    if (abTestDate < ninetyDaysAgo) {
        message += ' Impact Metrics available only for last 90 days.'
    }

    return message
}

const getAwaitedText = (abTestEnabledOn: string, showAwbMetricsAfterDays: number) => {
    const createdAtDate = new Date(abTestEnabledOn)
    const resultDate = new Date(createdAtDate)
    resultDate.setDate(resultDate.getDate() + showAwbMetricsAfterDays)

    const currentDate = new Date()
    const differenceInDays = Math.ceil((resultDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24))

    if (differenceInDays > 0) {
        return `Metrics will start appearing within ${differenceInDays} days.`
    } else {
        return `Metrics should now be visible.`
    }
}

const handleDownloadOverallSummaryCSV = (tableColumns, tableData, metrics) => {
    const cellKeys = tableColumns.map((column) => column.dataIndex).filter((key) => typeof key === 'string')
    let cellHeadings = tableColumns
        .map((column) => column.title)
        .filter((title) => typeof title === 'string' || typeof title === 'number')
        .map((title) => title.toString())
    cellHeadings[0] = 'Metric'
    const modifiedTableData = tableData.map((row) => {
        const metricLabel = metrics[row.metric_name]?.label || row.metric_name
        return { ...row, metric_name: metricLabel }
    })
    downloadToCSV(cellKeys, cellHeadings, modifiedTableData, 'rto_analytics_overall_summary')
}

const exportRiskFlagSummaryToCSV = (finalTableData, metrics) => {
    const cellKeys = ['date', 'risk_flag', ...Object.keys(metrics)]
    const cellHeadings = ['DATE', 'RISK FLAG', ...Object.keys(metrics).map((key) => metrics[key].label)]
    const data = finalTableData.map((row) => ({
        ...row,
        ...Object.keys(metrics).reduce((acc, key) => {
            acc[key] = row[key]?.exact || ''
            return acc
        }, {}),
    }))
    downloadToCSV(cellKeys, cellHeadings, data, 'risk_flag_summary')
}

function checkWorkflowLimits(
    workflows: Workflow[],
    maxAbWorkflows: number,
    maxActiveWorkflows: number,
    falseCondition: boolean = false,
    currentIndex: number | null = null,
    abTestToggle: boolean = false,
): boolean {
    if (falseCondition) return false

    let enabledCount = 0
    let abEnabledCount = 0
    const workflowDataClone: Workflow[] = JSON.parse(JSON.stringify(workflows))

    // Function to handle error messages and determine if limits are exceeded
    const handleLimitExceeded = (count: number, maxLimit: number) => {
        if (count > maxLimit) {
            return true
        }
        return false
    }

    // Check each workflow for enabled status and A/B test status
    for (const workflow of workflowDataClone) {
        if (workflow.is_enabled) {
            enabledCount++
            if (handleLimitExceeded(enabledCount, maxActiveWorkflows)) {
                return true
            }

            if (workflow.ab_test_enabled_on && workflow.is_ab_test_enabled) {
                abEnabledCount++
                if (handleLimitExceeded(abEnabledCount, maxAbWorkflows)) {
                    return true
                }
            }
        }
    }

    // Additional check if a specific index is provided
    if (currentIndex !== null && currentIndex >= 0 && currentIndex <= workflowDataClone.length) {
        const workflow = workflowDataClone[currentIndex]
        if (!workflow?.is_enabled) {
            enabledCount++
            if (handleLimitExceeded(enabledCount, maxActiveWorkflows)) {
                return true
            }
        }
        if ((workflow?.ab_test_enabled_on && workflow?.is_ab_test_enabled) || abTestToggle) {
            abEnabledCount++
            if (
                handleLimitExceeded(abEnabledCount, maxAbWorkflows) ||
                checkABTypeBAction(workflow?.ab_control_actions)
            ) {
                return true
            }
        }
    }

    return false
}

const getTieringStatus = (
    enableTiering: boolean,
    startTierFlow: boolean,
    isFreelookupRequested: string | null,
    freelookupPeriod: number,
) => {
    if (!startTierFlow) return false
    if (!isFreelookupRequested) return enableTiering

    const lookupDate = new Date(isFreelookupRequested)
    lookupDate.setDate(lookupDate.getDate() + freelookupPeriod)
    return lookupDate > new Date() ? false : enableTiering
}

const checkItemsForTiering = (items) => {
    return items.some((item) =>
        item.conditions.some((condition: Condition) => !tieringFreeConditions.includes(condition.key)),
    )
}

const checkABTypeBAction = (abControlActions: ActionType[] = []) => {
    return abControlActions.some((action) => action.action !== actionTypes.allowCod)
}

const calculateValues = (allWorkflows: Workflow[], currentIndex: number, currentConditions: Condition[]): number => {
    let valuesLength = 0

    allWorkflows?.forEach((workflow, index) => {
        if (index !== currentIndex && workflow.rules) {
            workflow?.rules?.forEach(rule => {
                    rule?.raw_data?.conditions?.forEach(condition => {
                        if (bulkUploadCheck.includes(condition.key) && (condition.rto_rule_type === 'ContainsRule' || condition.rto_rule_type === 'ContainsRuleExtra')) {
                        if (condition.values) {
                            valuesLength += condition.values.length
                        }
                    }
                })
                
            })
        }
    });

    currentConditions?.forEach(condition => {
        if (
            bulkUploadCheck?.includes(condition.key)
        ) {
            if (condition.values) {
                valuesLength += condition.values.length;
            }
        }
    })

    return valuesLength
}

const checkAWBFillRateConditions = (previousMonthAWBFillRate: string): boolean => {
    const currentDate = dayjs()

    if (previousMonthAWBFillRate) {
        const hasPreviousMonthData = previousMonthAWBFillRate !== undefined || previousMonthAWBFillRate !== null
        const isPreviousAWBFillRateMonthLessThan80 = hasPreviousMonthData && parseFloat(previousMonthAWBFillRate) < 80

        const isAfter15th = currentDate.date() > 15

        return hasPreviousMonthData && isPreviousAWBFillRateMonthLessThan80 && isAfter15th
    }

    return false
}

const exportRiskProbabilityToCSV = (finalTableData, metrics) => {
    const cellKeys = ['date', 'decile_level', ...Object.keys(metrics)]
    const cellHeadings = ['DATE', 'DECILE LEVEL', ...Object.keys(metrics).map((key) => metrics[key].label)]
    const data = finalTableData.map((row) => ({
        ...row,
        ...Object.keys(metrics).reduce((acc, key) => {
            acc[key] = row[key]?.exact || ''
            return acc
        }, {}),
    }))
    downloadToCSV(cellKeys, cellHeadings, data, 'risk_probablility')
}

const exportDeepDiveDataToCSV = (finalTableData, metrics, filter) => {
    const cellKeys = ['date', 'filter_value', ...Object.keys(metrics)]
    const cellHeadings = ['DATE', filter.toUpperCase(), ...Object.keys(metrics).map((key) => metrics[key].label)]
    const data = finalTableData.map((row) => ({
        ...row,
        ...Object.keys(metrics).reduce((acc, key) => {
            acc[key] = row[key]?.exact || ''
            return acc
        }, {}),
    }))
    downloadToCSV(cellKeys, cellHeadings, data, filter+'_level_metrics')
}

const computeRowSpans = (data) => {
    const spans = Array(data.length).fill(0);
    let lastDate = null;
    let spanIndex = -1;

    data.forEach((item, index) => {
        if (item.date !== lastDate) {
            lastDate = item.date;
            spanIndex = index;
            spans[spanIndex] = 1;
        } else {
            spans[spanIndex]++;
            spans[index] = 0;
        }
    });

    return spans;
}

const formatDateAnalytics = (date, timeframe) => {
    if (timeframe === 'weekly') {
        return dayjs(date).format('YYYY-MM-DD')
    } else if (timeframe === 'monthly') {
        return dayjs(date).format('MMMM YYYY')
    } else if (timeframe === 'quarterly') {
        return date
    }
}

const sanitizeString = (str: string | null | undefined): string | null => {
    if (typeof str !== 'string') return str

    return str
        .replace(/['`""]/g, '') 
        .replace(/[()[\]{}]/g, '') 
        .replace(/[\\\/]/g, '') 
        .replace(/[&]/g, 'and') 
        .replace(/[®™©]/g, '') 
        .replace(/[<>]/g, '') 
        .replace(/\s+/g, ' ') 
        .trim() 
}

const sanitizeJson = (selectedProducts: any[] | null | undefined) => {
    if (!Array.isArray(selectedProducts) || selectedProducts.length === 0) return selectedProducts

    const sanitiseProducts = selectedProducts?.map((product) => ({
        ...product,
        product_name: sanitizeString(product?.product_name),
        variant_ids: Array.isArray(product?.variant_ids)
            ? product.variant_ids?.map((variant) => ({
                  ...variant,
                  variant_name: sanitizeString(variant?.variant_name),
              }))
            : product?.variant_ids,
    }))
    return sanitiseProducts
}

const sanitizeCondition = (condition: Condition) => {
    if (condition?.key === 'product_id' || condition?.key === 'variant_ids') {
        const sanitisedSelectedProducts = sanitizeJson(condition?.selected_products)
        return {
            ...condition,
            selected_products: sanitisedSelectedProducts,
        }
    }
    return condition
}

const updateRules = (rules) => {
    return rules.map((rule: RuleRawData) => ({
        ...rule,
        raw_data: {
            ...rule.raw_data,
            conditions: rule?.raw_data?.conditions?.map(sanitizeCondition),
        },
    }))
}

export {
    getDynamicSearchFilters,
    capitalize,
    getSelectValue,
    isEqualString,
    isValidNumber,
    inputNumberFormatter,
    inputNumberParser,
    getSubCategory,
    getSecondaryActionInputs,
    getTertiaryActionInputs,
    shouldShowTertiaryOption,
    convertIfNumber,
    getPreviewModalTitle,
    getPreviewModalAlert,
    imageMapping,
    getMultiSelectOptions,
    shouldHideCreateButton,
    isValidPPCODConfig,
    isValidCODFeeConfig,
    isValidUPIDiscountConfig,
    getDefaultAbShopifyFlag,
    getActionNameByWorkflowResponse,
    getAbTestControlActionName,
    numberFormatter,
    convertUtcToIst,
    formatDateTimeframe,
    getAbControlActions,
    getAbPrimaryActionInputs,
    deepEqual,
    validateActionType,
    shouldHideWorkflowForDuplicateOrders,
    getDifference,
    getAbTestImpactInfo,
    getAwaitedText,
    handleDownloadOverallSummaryCSV,
    exportRiskFlagSummaryToCSV,
    checkWorkflowLimits,
    getTieringStatus,
    checkItemsForTiering,
    checkABTypeBAction,
    calculateValues,
    checkAWBFillRateConditions,
    exportRiskProbabilityToCSV,
    exportDeepDiveDataToCSV,
    computeRowSpans,
    formatDateAnalytics,
    sanitizeJson,
    updateRules,
}
