import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ColumnProps, InfoCircleOutlined, ReloadOutlined, Select, Table, Tooltip } from 'gokwik-ui-kit'
import {
    computeRowSpans,
    exportRiskProbabilityToCSV,
    formatDateAnalytics,
    numberFormatter,
} from '@library/utilities/helpers/helper'
import { updateDataBasedOnTimeframeRiskProbability } from '@library/utilities/helpers/data_filtering'
import { awbFillRateIconMsg, rtoApiHitsIconMsg, summaryViewMetrics } from '@library/utilities/constants/constants'
import { useMemo, useState } from 'react'

dayjs.extend(utc)

const createColumnsWithMetrics = (
    setIsFilterApplied: React.Dispatch<React.SetStateAction<boolean>>,
    setSelectedFilters: React.Dispatch<React.SetStateAction<any[]>>,
    filterOptions: { label: string; value: any }[],
    onSearchFilter: (searchText: string) => void,
    selectedFilters: any[],
) => {
    const baseColumns: ColumnProps<any>[] = [
        {
            title: 'DATE',
            dataIndex: 'date',
            key: 'date',
            width: 120,
            render: (val, record) => ({
                children: val,
                props: {
                    rowSpan: record.rowSpan,
                    style: { textAlign: 'center', fontWeight: 'bold' },
                },
            }),
        },
        {
            title: 'DECILE LEVEL',
            dataIndex: 'decile_level',
            key: 'decile_level',
            filterDropdown: () => (
                <div style={{ padding: 8 }}>
                    <Select
                        mode='multiple'
                        showSearch
                        placeholder='Search '
                        onSearch={onSearchFilter}
                        style={{ width: '200px' }}
                        options={filterOptions}
                        value={selectedFilters}
                        onChange={(selectedValues) => {
                            setSelectedFilters(selectedValues)
                            setIsFilterApplied(true)
                        }}
                    />
                </div>
            ),
            width: 150,
            render: (val, record) => ({
                children: val,
                props: {
                    style: { textAlign: 'right', fontWeight: 'bold' },
                },
            }),
        },
    ]

    const metricColumns = Object.keys(summaryViewMetrics)?.map((metric_key) => {
        const metricInfo = summaryViewMetrics[metric_key]
        return {
            title: (
                <>
                    {metric_key === 'hits' || metric_key === 'awb_fill_rate_perc' ? (
                        <span className='flex items-center'>
                            {metricInfo.label}{' '}
                            <Tooltip
                                placement='top'
                                title={metric_key === 'hits' ? rtoApiHitsIconMsg : awbFillRateIconMsg}
                            >
                                <InfoCircleOutlined className='ml-1' />
                            </Tooltip>
                        </span>
                    ) : (
                        metricInfo.label
                    )}
                </>
            ),
            dataIndex: metric_key,
            key: metric_key,
            render: (val, record) => {
                const exactValue = record[metric_key].exact
                const isPercentageAndNaN = metricInfo.type === 'percentage' && isNaN(exactValue)
                const formattedValue = isPercentageAndNaN ? 'Awaited' : record[metric_key].formatted
                const tooltipTitle = isPercentageAndNaN ? 'Metric awaited' : `${exactValue}`
                return (
                    <Tooltip placement='top' title={`${tooltipTitle}`}>
                        {formattedValue}
                    </Tooltip>
                )
            },
            align: 'right' as const,
            width: 120,
        }
    })

    return [...baseColumns, ...metricColumns]
}

const mapDataToRows = (data: any[], timeframe: string) => {
    // Aggregate data by decile_level
    const aggregatedData = data.reduce((acc: Record<string, any>, item: any) => {
        const decile = item.decile_level
        const formattedDate = formatDateAnalytics(item.date, timeframe) // Use the formatted date for aggregation
        const uniqueKey = `${decile}-${formattedDate}` // Combine decile and formatted date for a unique key

        if (!acc[uniqueKey]) {
            acc[uniqueKey] = { ...item, date: formattedDate, count: 1 } // Initialize with the first item of this unique key
        } else {
            // Aggregate data for the same decile level and timeframe
            acc[uniqueKey].hits += item.hits
            acc[uniqueKey].total_orders += item.total_orders
            acc[uniqueKey].cod_orders += item.cod_orders
            acc[uniqueKey].total_rto_orders += item.total_rto_orders
            acc[uniqueKey].total_delivered_orders += item.total_delivered_orders
            acc[uniqueKey].total_pending_orders += item.total_pending_orders
            acc[uniqueKey].total_null_status_orders += item.total_null_status_orders
            acc[uniqueKey].total_cancelled_orders += item.total_cancelled_orders
            acc[uniqueKey].total_cod_rto_orders += item.total_cod_rto_orders
            acc[uniqueKey].total_cod_pending_orders += item.total_cod_pending_orders
            acc[uniqueKey].total_cod_delivered_orders += item.total_cod_delivered_orders
            acc[uniqueKey].prepaid_share = parseFloat(
                ((acc[uniqueKey].prepaid_share + item.prepaid_share) / 2).toFixed(1),
            )
            acc[uniqueKey].cod_rto_perc = parseFloat(((acc[uniqueKey].cod_rto_perc + item.cod_rto_perc) / 2).toFixed(1))
            acc[uniqueKey].rto_overall_perc = parseFloat(
                ((acc[uniqueKey].rto_overall_perc + item.rto_overall_perc) / 2).toFixed(1),
            )
            acc[uniqueKey].awb_fill_rate_perc = parseFloat(
                ((acc[uniqueKey].awb_fill_rate_perc + item.awb_fill_rate_perc) / 2).toFixed(1),
            )
            acc[uniqueKey].delivered_orders_perc = parseFloat(
                ((acc[uniqueKey].delivered_orders_perc + item.delivered_orders_perc) / 2).toFixed(1),
            )
            acc[uniqueKey].cancelled_orders_perc = parseFloat(
                ((acc[uniqueKey].cancelled_orders_perc + item.cancelled_orders_perc) / 2).toFixed(1),
            )
            acc[uniqueKey].count += 1
        }
        return acc
    }, {})

    // Convert aggregated data back to array format and sort by date
    const sortedAggregatedData = Object.values(aggregatedData).sort(
        (a: any, b: any) => dayjs(a.date).unix() - dayjs(b.date).unix(),
    )

    // Map over sortedAggregatedData to format for table rows
    return sortedAggregatedData.map((item: any) => {
        // The row object is already prepared with aggregated and formatted data
        const row: any = {
            date: item.date,
            decile_level: item.decile_level,
        }
        Object.keys(summaryViewMetrics).forEach((metricKey) => {
            const value = item[metricKey]
            const isPercentageAndNaN = summaryViewMetrics[metricKey].type === 'percentage' && isNaN(value)
            row[metricKey] = {
                formatted: isPercentageAndNaN
                    ? 'Awaited'
                    : numberFormatter(parseFloat(value?.toFixed(1) || 0), summaryViewMetrics[metricKey].type),
                exact: value,
            }
        })
        return row
    })
}

const RiskProbabilityTable = ({ analyticsData, timeframe = 'monthly' }) => {
    const originalData = analyticsData?.decileLevelMetrics?.data
    const status = analyticsData?.decileLevelMetrics?.status
    const timeframeDataRiskProbablilityWise = updateDataBasedOnTimeframeRiskProbability(
        originalData,
        timeframe as 'monthly' | 'weekly' | 'quarterly',
    )
    const tableData = mapDataToRows(timeframeDataRiskProbablilityWise, timeframe)
    const [isFilterApplied, setIsFilterApplied] = useState(false)
    const [selectedFilters, setSelectedFilters] = useState<any[]>([])
    const [searchText, setSearchText] = useState('')
    const [filteredOptions, setFilteredOptions] = useState([])
    const filterOptions = useMemo(() => {
        const allFilterValues = tableData?.map((item) => item.decile_level) || []
        const uniqueFilterValues = Array.from(new Set(allFilterValues))
        return uniqueFilterValues?.map((value) => ({
            label: typeof value === 'string' ? value : String(value),
            value,
        }))
    }, [tableData])

    const onSearchFilter = (search) => {
        setSearchText(search)
        const lowercasedSearch = search.toLowerCase()
        setFilteredOptions(filterOptions?.filter((option) => option.label.toLowerCase().includes(lowercasedSearch)))
    }

    const resetFilters = () => {
        setSelectedFilters([])
        setIsFilterApplied(false)
        setSearchText('')
        setFilteredOptions(filterOptions)
    }

    const filterTableData = (data) => {
        if (selectedFilters.length === 0) {
            return data
        }
        return data?.filter((item) => selectedFilters.includes(item.decile_level))
    }

    const filteredTableData = useMemo(() => filterTableData(tableData), [tableData, selectedFilters])

    // const rowSpans = computeRowSpans(tableData) // to be added later

    const tableColumns = createColumnsWithMetrics(
        setIsFilterApplied,
        setSelectedFilters,
        filterOptions,
        onSearchFilter,
        selectedFilters,
    )

    const finalTableData = filteredTableData?.map((row, index) => ({
        ...row,
        // rowSpan: rowSpans[index], //to be added later
    }))

    const isButtonDisabled = finalTableData.some((row) => row.awb_fill_rate_perc.exact > 80) //to confirm from Pratham

    return (
        <>
            <div className='flex justify-end gap-2'>
                {/* {isButtonDisabled ? ( // will be added later based dynamic awb fill rate for last month only
                    <Tooltip title='Gokwik has delivery status available for less than 80% of orders, Please reach out to your CSM to increase your AWB Fill rate and unlock this view'>
                        <div>
                            <button
                                type='button'
                                className='bg-gray-400 hover:bg-gray-400 text-white font-medium py-2 px-3 rounded-lg border-none mb-5 cursor-not-allowed'
                                onClick={() => exportRiskProbabilityToCSV(finalTableData, summaryViewMetrics)}
                                disabled={isButtonDisabled}
                            >
                                Export CSV
                            </button>
                        </div>
                    </Tooltip>
                ) : ( */}
                <button
                    type='button'
                    className='bg-[#004b8d] hover:bg-[#186199] text-white font-medium py-2 px-3 rounded-lg border-none mb-5'
                    onClick={() => exportRiskProbabilityToCSV(finalTableData, summaryViewMetrics)}
                    disabled={isButtonDisabled}
                >
                    Export CSV
                </button>
                <Tooltip title='Reset Filters'>
                    <button
                        type='button'
                        className='bg-[#004b8d] hover:bg-[#186199] text-white font-medium py-2 px-3 rounded-lg border-none mb-5'
                        onClick={resetFilters}
                    >
                        <ReloadOutlined />
                    </button>
                </Tooltip>
                {/* )} */}
            </div>
            <Table
                columns={tableColumns}
                dataSource={finalTableData}
                pagination={false}
                bordered={true}
                scroll={{ x: 'max-content' }}
                loading={status === 'loading'}
            />
        </>
    )
}

export default RiskProbabilityTable
