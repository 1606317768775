import PaymentOffers from '@pages/settings/offers'
import store from '@store/index'
import { Provider } from 'react-redux'
import { InitialiseStore } from '../../../root.component'


export default function PaymentOffersExport(props) {
    return (
        <Provider store={store}>
            <InitialiseStore />
            <PaymentOffers {...props} />
        </Provider>
    )
}
