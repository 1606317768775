import React, { useState } from 'react';
import { Button, Modal, Row, TextArea } from 'gokwik-ui-kit';

export const ReviewModal = ({ visible, review, onReviewChange, onCancel, onSubmit, error,zIndex=1000 }) => {

    return (
        <Modal
            centered
            open={visible}
            onOk={onCancel}
            onCancel={onCancel}
            className='w-full'
            zIndex={zIndex}
            footer={() => (
                <Row className='flex justify-end gap-2'>
                    <Button onClick={onCancel} variant='default'>
                        Discard
                    </Button>
                    <Button onClick={onSubmit} variant='primary'>
                        Report an Issue
                    </Button>
                </Row>
            )}
        >
            <div style={{ height: '550px' }}>
                <div className='mb-5' >
                    <p className='font-semibold text-[20px] text-[rgba(0,0,0,0.88)]' >Why is That?</p>
                    <p className='text-[14px] text-[rgba(0,0,0,0.88)]' >Please describe the problem you are having while using the app</p>
                </div>
                <TextArea
                    rows={8}
                    className='w-full'
                    value={review}
                    onChange={(e) => {
                        const reviewValue = e.target.value;
                        const trimmedValue = reviewValue.replace(/^\s+/, '');
                        onReviewChange(trimmedValue);
                    }}
                    placeholder='Add your review here.'
                    maxLength={180}
                    minLength={30}
                />
                {error && <div style={{ color: 'red' }}>{error}</div>}
                <div className='text-[14px] text-[rgba(0,0,0,0.88)]' >
                    {review.length}/1800 characters
                </div>
            </div>
        </Modal>
    );
};