import { Table, type ColumnProps, Col, Tag, Row, Tooltip, Input } from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { addBreadcrumb, convertIsoToLocaleDateString, updateBreadcrumbs } from '@gokwik/utilities'
import { Link, useParams } from 'react-router-dom'
import { getSettlementDetails, getTagVariant } from '../settlements.service'
import {
    RefundDetailsResponse,
    SettlementDetailsResponse,
    TransactionDetailsResponse,
} from '../types'
import { formatAmount } from '@library/utilities/helpers/helper'

const enum CollectionSection {
    settlement = 'settlement',
    transactions = 'transactions',
    refunds = 'refunds',
    collectionDetails = 'collection_details',
}

type SplitCollection = {
    label: string
    amount: number
}

export default function () {
    const renderSplit = (split_collections: SplitCollection[]) => {
        if (split_collections) {
            let totalAmount = Object.values(split_collections).reduce((sum: number, collection: SplitCollection) => {
                return sum + (collection?.amount || 0);
            }, 0);
            return (<p>{totalAmount}</p>);
        }
    }
    const summaryColumns: ColumnProps<any>[] = [
        {
            title: <span>Transactions</span>,
            dataIndex: 'transactions_count',
            fixed: 'left',
        },
        {
            title: <span>Gross Amount</span>,
            dataIndex: 'gross_amount',
            render: (val) => <p>{formatAmount(val)}</p>,
        },
        {
            title: <span>Refunded Amount</span>,
            dataIndex: 'refunded_amount',
            render: (val) => <p>{formatAmount(val)}</p>,
        },
        {
            title: <span>Split Collections</span>,
            dataIndex: 'split_collections',
            render: renderSplit,
        },
        {
            title: <span>Tax</span>,
            dataIndex: 'tax',
            render: (val) => <p>{formatAmount(val)}</p>,
        },
        {
            title: <span>PG Fee</span>,
            dataIndex: 'fee',
            render: (val) => <p>{formatAmount(val)}</p>,
        },
        {
            title: <span>Other Fee</span>,
            dataIndex: 'other_fee',
            render: (val) => <p>{formatAmount(val)}</p>,
        },
        {
            title: <span>Net Amount</span>,
            dataIndex: 'net_settled_amount',
            render: (val) => <p>{formatAmount(val)}</p>,
        },
    ]
    const transactionDetailsColumns: ColumnProps<any>[] = [
        {
            title: <span>MOID</span>,
            dataIndex: 'moid',
            width: 180,
            ellipsis: true,
            render: (text: string) => (
                <Link
                    to={`/checkout/orders/${text}`}
                    onClick={() =>
                        addBreadcrumb({
                            key: 'order-details',
                            href: `/checkout/orders/${text}`,
                            text,
                        })
                    }
                >
                    <a style={{ width: 'min-content' }}>
                        <Tooltip placement='topLeft' title={text}>
                            {text.toUpperCase()}
                        </Tooltip>
                    </a>
                </Link>
            ),
            fixed: 'left',
        },
        {
            title: <span>Payment ID</span>,
            width: 180,
            ellipsis: true,
            dataIndex: 'payment_id',
        },
        {
            title: <span>Payment Method</span>,
            dataIndex: 'payment_method',
        },
        {
            title: <span>Amount</span>,
            dataIndex: 'amount',
            render: (val) => <p>{formatAmount(val)}</p>,
        },
        {
            title: <span>Split Collections</span>,
            dataIndex: 'split_collections',
            render: renderSplit,
        },
        {
            title: <span>PG Fee</span>,
            dataIndex: 'fee',
            render: (val) => <p>{formatAmount(val)}</p>,
        },
        {
            title: <span>Tax</span>,
            dataIndex: 'tax',
            render: (val) => <p>{formatAmount(val)}</p>,
        },
        {
            title: <span>Other Fee</span>,
            dataIndex: 'other_fee',
            render: (val) => <p>{formatAmount(val)}</p>,
        },
        {
            title: <span>Net Amount</span>,
            dataIndex: 'net_amount',
            render: (val) => <p>{formatAmount(val)}</p>,
        },
        {
            title: <span>Date</span>,
            dataIndex: 'transaction_date',
            render: (value) => convertIsoToLocaleDateString(value),
        },
    ]
    const refundDetailsColumns = transactionDetailsColumns.filter((col) => col.dataIndex !== 'split_collections')

    const collectionDetailsColumns: ColumnProps<any>[] = [
        {
            title: <span>Collection ID</span>,
            dataIndex: 'invoice_id',
            fixed: 'left',
        },
        {
            title: <span>Reference ID</span>,
            dataIndex: 'reference_id',
        },
        {
            title: <span>Total Transactions</span>,
            dataIndex: 'total_transactions',
        },
        {
            title: <span>Gross Amount</span>,
            dataIndex: 'gross_amount',
            render: (val) => <p>{formatAmount(val)}</p>,
        },
        {
            title: <span>Split Amount</span>,
            dataIndex: 'split_amount',
            render: (val) => <p>{formatAmount(val)}</p>,
        },
    ]

    const [settlementDetails, setSettlementDetails] = useState<SettlementDetailsResponse>(null)
    const [transactionDetails, setTransactionDetails] = useState<{ data: TransactionDetailsResponse[]; page: number }>(
        null,
    )
    const [refundDetails, setRefundDetails] = useState<{ data: RefundDetailsResponse[]; page: number }>(null)
    const [collectionDetails, setCollectionDetails] = useState<RefundDetailsResponse[]>([])
    const [filteredTransactions, setFilteredTransactions] = useState<{
        data: TransactionDetailsResponse[]
        page: number
    }>(null)
    const [filteredRefunds, setFilteredRefunds] = useState<{ data: RefundDetailsResponse[]; page: number }>(null)
    const { id } = useParams()

    useEffect(() => {
        getSettlementSummaryDetails(id)
        getSettlementTransactionDetails(id)
        getSettlementRefundDetails(id)
        getSettlementCollectionDetails(id)
    }, [id])

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'settlement-details',
                href: `/payment/settlements`,
                text: 'Settlements',
            },
            {
                key: 'settlement-details',
                href: `/payment/settlements/${id}`,
                text: id,
            },
        ])
    }, [])

    async function getSettlementSummaryDetails(masterSettlementId: string) {
        getSettlementDetails(
            { master_settlement_id: masterSettlementId, section: CollectionSection.settlement },
            (response) => {
                setSettlementDetails(response?.[0] ?? null)
            },
        )
    }

    async function getSettlementTransactionDetails(masterSettlementId: string, page = 1) {
        getSettlementDetails(
            { master_settlement_id: masterSettlementId, section: CollectionSection.transactions, page },
            (response) => {
                setTransactionDetails({ data: response ?? [], page })
            },
        )
    }

    async function getSettlementRefundDetails(masterSettlementId: string, page = 1) {
        getSettlementDetails(
            { master_settlement_id: masterSettlementId, section: CollectionSection.refunds, page },
            (response) => {
                setRefundDetails({ data: response ?? [], page })
            },
        )
    }

    async function getSettlementCollectionDetails(masterSettlementId: string) {
        getSettlementDetails(
            { master_settlement_id: masterSettlementId, section: CollectionSection.collectionDetails },
            (response) => {
                setCollectionDetails(response ?? [])
            },
        )
    }

    function handlePaginationChange(page: number, pageSize: number, section: CollectionSection) {
        if (section === CollectionSection.transactions) {
            getSettlementTransactionDetails(id, page)
        } else if (section === CollectionSection.refunds) {
            getSettlementRefundDetails(id, page)
        }
    }

    function handleFilterChange(value: string, filter: string, section: CollectionSection) {
        if (section === CollectionSection.transactions) {
            const transactions = transactionDetails?.data?.filter((transaction) => transaction[filter]?.includes(value))
            setFilteredTransactions({ data: transactions, page: 1 })
        } else if (section === CollectionSection.refunds) {
            const refunds = refundDetails?.data?.filter((refund) => refund[filter]?.includes(value))
            setFilteredRefunds({ data: refunds, page: 1 })
        }
    }

    return (
        <Row className='w-full h-full'>
            <div className='flex items-center gap-x-3'>
                <Tag variant={getTagVariant(settlementDetails?.recon_percentage)}>
                    {settlementDetails?.recon_percentage ?? 0}% Recon
                </Tag>
                <span>Settled on: {convertIsoToLocaleDateString(settlementDetails?.settled_on)}</span>
            </div>
            <Col span={24} className='mt-8'>
                <p className='font-semibold pl-2'>Summary</p>
                <div className='mt-3 bg-white rounded overflow-clip'>
                    <Table
                        columns={summaryColumns}
                        dataSource={settlementDetails ? [settlementDetails] : []}
                        style={{
                            width: '100vw',
                        }}
                        pagination={false}
                        scroll={{ x: 'max-content' }}
                    />
                </div>
            </Col>
            <Col span={24} className='mt-12'>
                <div className='flex items-center gap-x-3'>
                    <p className='font-semibold pl-2'>Transactions</p>
                    <Input
                        size='middle'
                        placeholder='Payment ID'
                        allowClear
                        onChange={(e) =>
                            handleFilterChange(e.target.value, 'payment_id', CollectionSection.transactions)
                        }
                    />
                    <Input
                        size='middle'
                        placeholder='Order #'
                        allowClear
                        onChange={(e) => handleFilterChange(e.target.value, 'moid', CollectionSection.transactions)}
                    />
                </div>
                <div className='mt-3 bg-white rounded overflow-clip'>
                    <Table
                        columns={transactionDetailsColumns}
                        dataSource={
                            filteredTransactions ? filteredTransactions?.data ?? [] : transactionDetails?.data ?? []
                        }
                        style={{
                            width: '100vw',
                        }}
                        scroll={{ x: 'max-content' }}
                        pagination={{
                            position: ['none', 'bottomRight'],
                            current: filteredTransactions
                                ? filteredTransactions?.page ?? 1
                                : transactionDetails?.page ?? 1,
                            pageSize: filteredTransactions
                                ? filteredTransactions?.data?.length ?? 10
                                : transactionDetails?.data?.length ?? 10,
                            total: settlementDetails?.transactions_count ?? transactionDetails?.data?.length,
                            showSizeChanger: false,
                            onChange: (page, pageSize) =>
                                handlePaginationChange(page, pageSize, CollectionSection.transactions),
                            showTotal: (total, range) => (
                                <p className='ml-4'>{`Showing ${range[0]}-${range[1]} of ${total} Records`}</p>
                            ),
                        }}
                    />
                </div>
            </Col>
            <Col span={24} className='mt-12'>
                <div className='flex items-center gap-x-3'>
                    <p className='font-semibold pl-2'>Refunds</p>
                    <Input
                        size='middle'
                        placeholder='Payment ID'
                        allowClear
                        onChange={(e) => handleFilterChange(e.target.value, 'payment_id', CollectionSection.refunds)}
                    />
                    <Input
                        size='middle'
                        placeholder='Order #'
                        allowClear
                        onChange={(e) => handleFilterChange(e.target.value, 'moid', CollectionSection.refunds)}
                    />
                </div>
                <div className='mt-3 bg-white rounded overflow-clip'>
                    <Table
                        columns={refundDetailsColumns}
                        dataSource={filteredRefunds ? filteredRefunds?.data ?? [] : refundDetails?.data ?? []}
                        style={{
                            width: '100vw',
                        }}
                        pagination={{
                            current: filteredRefunds ? filteredRefunds?.page ?? 1 : refundDetails?.page ?? 1,
                            pageSize: filteredRefunds
                                ? filteredRefunds?.data?.length ?? 10
                                : refundDetails?.data?.length ?? 10,
                            total: settlementDetails?.refunds_count ?? refundDetails?.data?.length,
                            showSizeChanger: false,
                            onChange: (page, pageSize) =>
                                handlePaginationChange(page, pageSize, CollectionSection.refunds),
                            showTotal: (total, range) => (
                                <p className='ml-4'>{`Showing ${range[0]}-${range[1]} of ${total} Records`}</p>
                            ),
                        }}
                        scroll={{ x: 'max-content' }}
                    />
                </div>
            </Col>
            <Col span={24} className='mt-12'>
                <p className='font-medium pl-2'>Collections</p>
                <div className='mt-3 mb-12 bg-white rounded overflow-clip'>
                    <Table
                        columns={collectionDetailsColumns}
                        dataSource={collectionDetails ?? []}
                        style={{
                            width: '100vw',
                        }}
                        pagination={false}
                        scroll={{ x: 'max-content' }}
                    />
                </div>
            </Col>
        </Row>
    )
}
