import React, { useCallback, useEffect, useState } from 'react'
import ReactFlow, {
    addEdge,
    useNodesState,
    useEdgesState,
    ConnectionLineType,
    Handle,
    Position,
    FitViewOptions,
} from 'reactflow'
import { RcaChildrenCards } from '.'
import { ArrowLeftOutlined, Button } from 'gokwik-ui-kit'

const CustomCardNode = ({ data }) => {
    const getHandle = (type, position, background) => (
        <Handle
            type={type}
            position={position}
            className={`z-[1] !border-none !w-[1px] !h-[1px] ${
                background === 'rca-blue' ? '!bg-rca-blue' : '!bg-rca-gray'
            }`}
        />
    )

    const renderButtonOrChildCards = () => {
        if (data.label === '1') {
            return (
                <Button
                    type='default'
                    onClick={data?.onChange}
                    block
                    className='w-[99.5px] !bg-transparent border border-rca-blue text-xs z-20'
                >
                    <ArrowLeftOutlined /> Go Back
                </Button>
            )
        }
        return <RcaChildrenCards data={{ ...data, hide_button: true }} onEnable={() => data?.onChange(data)} />
    }

    return (
        <div className='card-block'>
            {data.label === '8' && getHandle('target', Position.Top, 'rca-blue')}

            {data.label === '1' && getHandle('source', Position.Bottom, 'rca-blue')}

            {data.label !== '1' && data.label !== '8' && getHandle('target', Position.Left, 'bg-rca-gray')}

            {renderButtonOrChildCards()}

            {data.label === '8' && getHandle('source', Position.Right, 'bg-rca-gray')}
        </div>
    )
}

const defaultNodeProperties = {
    type: 'customCard',
    position: { x: 0, y: 0 },
    data: {
        defaultFunction: () => {},
        selected: false,
    },
}

const createNode = (id, label, level) => ({
    id,
    ...defaultNodeProperties,
    data: { ...defaultNodeProperties.data, label, level },
})

const initialNodes = [
    createNode('1', '1', '1'),
    createNode('8', '8', '1'),
    createNode('19', '19', '2'),
    createNode('20', '20', '2'),
    createNode('21', '21', '2'),
    createNode('22', '22', '2'),
    createNode('23', '23', '2'),
]

const defaultEdgeProperties = {
    type: 'smoothstep',
    pathOptions: { borderRadius: 50 },
    animated: false,
    style: { stroke: 'rgb(var(--rca-gray))', strokeWidth: 1 },
    zIndex: 20,
}

const createEdge = (id, source, target, level) => ({
    id,
    source,
    target,
    data: { level },
    ...defaultEdgeProperties,
})

const initialEdges = [
    {
        id: '1',
        source: '1',
        target: '8',
        type: 'straight',
        data: { level: '1' },
        pathOptions: { borderRadius: 50 },
        animated: false,
        style: { stroke: 'rgb(var(--rca-blue))', strokeWidth: 1 },
        zIndex: 21,
    },
    createEdge('2', '8', '19', '1'),
    createEdge('3', '8', '20', '2'),
    createEdge('4', '8', '21', '2'),
    createEdge('5', '8', '22', '2'),
    createEdge('6', '8', '23', '2'),
]

const calculateNodePositions = (nodes, switchToMoreView) => {
    const viewportWidth = window.innerWidth

    const verticalpos = {
        '1': 100,
        '8': 190,
        '19': 0,
        '20': 100,
        '21': 200,
        '22': 300,
        '23': 400,
    }

    const horizontalPos = {
        '1': 0,
        '8': 0,
        '19': 300,
        '20': 300,
        '21': 300,
        '22': 300,
        '23': 300,
    }

    return nodes.map((node) => ({
        ...node,
        data: { ...node.data, onChange: switchToMoreView },
        position: {
            x: viewportWidth / 2 + horizontalPos[node.id],
            y: verticalpos[node.id],
        },
    }))
}

const MoreView: React.FC<{ switchToMoreView: () => void; filterType: string; selectedItem: string }> = ({
    switchToMoreView,
    filterType,
    selectedItem,
}) => {
    const [nodes, setNodes] = useNodesState(calculateNodePositions(initialNodes, switchToMoreView))
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges)
    const [filtertype, setFiltertype] = useState(filterType)
    const [selectedItems, setSelectedItem] = useState(selectedItem)

    const fitViewOptions: FitViewOptions = {
        padding: 0,
    }

    useEffect(() => {
        setFiltertype(filterType)
    }, [filterType])

    useEffect(() => {
        setSelectedItem(selectedItem)
    }, [selectedItem])

    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges])

    return (
        <div className='reactflow-wrapper'>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                fitView={true}
                fitViewOptions={fitViewOptions}
                nodeTypes={{ customCard: CustomCardNode }}
                connectionLineType={ConnectionLineType.Straight}
                zoomOnScroll={false}
                panOnScroll={false}
                zoomOnDoubleClick={false}
                panOnDrag={false}
                preventScrolling={false}
                zoomOnPinch={false}
            ></ReactFlow>
        </div>
    )
}

export default MoreView
