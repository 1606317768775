import React, { useEffect, useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootStoreType } from './store'
import { KPMerchantPlatform, KPPlatform } from '@library/utilities/constants/constants'

const Dashboard = React.lazy(() => import('@pages/kwikpass-dashboard'))
const Faq = React.lazy(() => import('@pages/kwikpass-faq'))
const AnalyticsPage = React.lazy(() => import('@pages/kwikpass-analytics'))
const Integration = React.lazy(() => import('@pages/kwikpass-integration'))
const NotFound = React.lazy(() => import('@pages/not-found'))
const Plan = React.lazy(() => import('@pages/kwikpass-plan'))

export default function AppRoutes({ globalStoreChanged }: { globalStoreChanged: boolean }) {
    const userDetails = useSelector((state: RootStoreType) => state?.user?.userData?.user_details)
    const userConfig = useSelector((state: RootStoreType) => state?.user?.userData?.config)
    const mode = useSelector((state: RootStoreType) => state?.user?.userData?.mode)
    const isInternalUser = useSelector((state: RootStoreType) => state?.user?.userData?.user_details.is_internal_user)

    const [configChanged, setConfigChanged] = useState(false)

    // Watch for changes in userConfig or globalStoreChanged
    useEffect(() => {
        setConfigChanged((prev) => prev !== (userConfig?.kp_merchant_platform || userConfig?.kp_platform))
    }, [userConfig, globalStoreChanged])

    return (
        <>
            <Routes
                key={configChanged ? `${mode}-${globalStoreChanged}-updated` : `${mode}-${globalStoreChanged}-default`}
            >
                {Object.keys(userDetails)?.length > 0 && isInternalUser ? (
                    <>
                        <Route path='/kwikpass/configuration' Component={Dashboard} />
                        <Route path='/kwikpass/analytics' Component={AnalyticsPage} />
                        <Route path='/kwikpass/integration' Component={Integration} />
                        <Route path='/kwikpass/faq' Component={Faq} />
                        <Route path='kwikpass/yourplan' Component={Plan} />
                    </>
                ) : (
                    Object.keys(userConfig)?.length > 0 && (
                        <>
                            <Route
                                path='/kwikpass/configuration'
                                element={
                                    userConfig?.kp_merchant_platform?.toLowerCase() !==
                                        KPMerchantPlatform.custom_headless &&
                                    userConfig?.kp_platform?.includes(KPPlatform.web) ? (
                                        <Dashboard />
                                    ) : (
                                        <NotFound />
                                    )
                                }
                            />
                            <Route path='/kwikpass/analytics' Component={AnalyticsPage} />
                            <Route
                                path='/kwikpass/integration'
                                element={
                                    userConfig?.kp_merchant_platform?.toLowerCase() ===
                                        KPMerchantPlatform.custom_shopify ||
                                    (userConfig?.kp_merchant_platform?.toLowerCase() == KPMerchantPlatform.shopify &&
                                        userConfig?.kp_platform?.includes(KPPlatform.web)) ? (
                                        <Integration />
                                    ) : (
                                        <NotFound />
                                    )
                                }
                            />
                            <Route path='/kwikpass/faq' Component={Faq} />
                            <Route path='/kwikpass/yourplan' Component={Plan} />
                            <Route path='/*' Component={NotFound} />
                        </>
                    )
                )}
            </Routes>
        </>
    )
}
