import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'

export const awbFlowsApi: {
    [key: string]: {
        url: string
        errMsg: string
        nestedKeys?: string[]
        defaultParams?: { [key: string]: any }
        formatData?: (data: any) => any
    }
} = {
    awbFillRate: {
        url: APIEndPoints.getAwbFillRate,
        errMsg: 'Error fetching awb fill rate',
        defaultParams: {
            merchant_id: 8,
            mode: 'live',
        },
    },
}

export const getUploadUrl = async (uploadType: string, uploadName: string, merchantId: string) => {
    const response = await makeAPICall({
        method: 'get',
        url: process.env.RTO_DASHBOARD_SERVICE_URL + APIEndPoints.getUploadUrl,
        params: {
            upload_type: uploadType,
            upload_name: uploadName,
            merchant_id: merchantId,
        },
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
        skipLoader: true,
    })
    return response
}

export const uploadCsvToS3 = async (url: string, requestOptions: any) => {
    const response = await fetch(url, requestOptions)

    return response
}

export const notifyUpload = async (uploadId: string, merchantId: number) => {
    const response = await makeAPICall({
        method: 'post',
        url: process.env.RTO_DASHBOARD_SERVICE_URL + APIEndPoints.notifyUpload,
        payload: {
            upload_id: uploadId,
            merchant_int_id: merchantId,
        },
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
        skipLoader: true,
    })
    return response
}

export const getIngestionStatus = async (uploadId: string) => {
    const response = await makeAPICall({
        method: 'get',
        url: process.env.RTO_DASHBOARD_SERVICE_URL + APIEndPoints.getIngestionStatus,
        params: {
            upload_id: uploadId,
        },
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
        skipLoader: true,
    })
    return response
}

export const fetchAwbFillRateData = async ({
    key,
    params,
    nestedKey,
}: {
    key: string
    nestedKey?: string
    params?: { [key: string]: any }
}) => {
    const { url, defaultParams } = awbFlowsApi[key]
    const response = await makeAPICall({
        method: 'get',
        url: process.env.RTO_DASHBOARD_SERVICE_URL + url,
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
        params: {
            merchant_id: params?.merchant_id,
        },
        skipLoader: true,
    })
    if (response.success) {
        return {
            success: true,
            data: response.data?.data,
        }
    }
    return { error: true }
}
