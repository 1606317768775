import { downloadFromURL, makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { debounce, disabledBeforeDate } from '@library/utilities/helpers/helper'
import { useAppDispatch } from '@library/utilities/hooks'
import { updateSummary } from '@store/discounts'
import { selectDiscountCodeData } from '@store/discounts/selectors'
import { getMerchantDetails } from '@store/user/selectors'
import dayjs from 'dayjs'
import {
    Button,
    Col,
    DatePicker,
    Divider,
    FileAddOutlined,
    Form,
    Input,
    InputNumber,
    message,
    Radio,
    Row,
    Segmented,
    Upload,
    UploadOutlined,
} from 'gokwik-ui-kit'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import DiscountsSample from '@public/assets/gokwik_sample_bulk_discount_codes.zip'

const DiscountGenerateCodes = ({ form }: { form: any }) => {
    const generateDiscountCodeMethod = Form.useWatch('generateDiscountCodeMethod', form)
    const codeLength = Form.useWatch('codeLength', form)
    const codeIdentifier = Form.useWatch('codeIdentifier', form)
    const bulkDiscountCSV = Form.useWatch('bulkDiscountCSV', form)
    const merchant_details = useSelector(getMerchantDetails)
    const discountData = useSelector(selectDiscountCodeData)
    const dispatch = useAppDispatch()
    const codeGenerationFormula = () => {
        const NUMBERS_CHARSET = 10
        const ALPHABET_CHARSET = 26
        const COMBINE_CHARSET = 36
        const numberOfCodes = form.getFieldValue('numberOfCodes')
        const identifier = form.getFieldValue(codeIdentifier === 'Prefix' ? 'prefix' : 'suffix')

        let a = COMBINE_CHARSET
        const minimumLength = Math.floor(Math.ceil(Math.log(numberOfCodes) / Math.log(a)) + 3) + identifier?.length

        if (codeLength < minimumLength) {
            return `The combination you are trying is not feasible. Please try minimum length of ${minimumLength}`
        }
        return true
    }
    const downloadSample = () => {
        downloadFromURL(DiscountsSample, 'sample_discount.zip')
        message.success('Sample downloaded successfully!')
    }

    const handleDownload = async (discount) => {
        try {
            let preSignedURLResponse = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BULK_DISCOUNT_URL + APIEndPoints.bulkDiscounts.presignedUrl,
                payload: {
                    merchant_id: merchant_details?.m_id,
                    campaign_id: discount.campaign_id,
                    url: discount.url,
                    operation_type: 'FETCH',
                },
            })
            if (preSignedURLResponse?.success && preSignedURLResponse.data?.data?.presignedUrl) {
                downloadFromURL(preSignedURLResponse.data?.data?.presignedUrl, discount.code)
            }
        } catch (err) {
            console.warn(err)
        }
    }
    return (
        <Form
            form={form}
            disabled={!!discountData?.bulkDisabled}
            onValuesChange={(changedValues, allValues) => {
                dispatch(updateSummary(allValues))
            }}
            layout='vertical'
            requiredMark='optional'
        >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Form.Item
                        label='How do you want to create the discount codes for this set?'
                        name='generateDiscountCodeMethod'
                        className='label-bold'
                        rules={[{ required: true, message: 'Please select a method!' }]}
                    >
                        <Radio.Group>
                            <Radio value='random'>Generate Random Code</Radio>
                            <Radio value='upload'>Upload a ZIP of CSV file(s)</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {generateDiscountCodeMethod === 'upload' && (
                        <>
                            <Row gutter={[12, 12]} className='my-2'>
                                <Col span={8} className='flex items-end'>
                                    <Form.Item
                                        name='bulkDiscountCSV'
                                        rules={[{ required: true, message: 'Please upload a zip!' }]}
                                        valuePropName='list'
                                        getValueFromEvent={(e) => {
                                            if (Array.isArray(e)) {
                                                return e
                                            }
                                            return e && e.fileList
                                        }}
                                    >
                                        <Upload
                                            accept='.zip'
                                            maxCount={1}
                                            fileList={bulkDiscountCSV}
                                            showUploadList={{
                                                showPreviewIcon: bulkDiscountCSV?.[0]?.alreadyUploaded,
                                            }}
                                            onPreview={(file: any) => {
                                                if (file.alreadyUploaded) {
                                                    handleDownload(bulkDiscountCSV[0])
                                                }
                                            }}
                                            beforeUpload={(file: any) => {
                                                const isZip = file.type === 'application/zip'
                                                if (!isZip) {
                                                    message.error('You can only upload a zip file!')
                                                    return false
                                                }
                                                form.setFieldsValue({ bulkDiscountCSV: [file] })
                                                return false
                                            }}
                                            capture={false}
                                            hasControlInside={false}
                                        >
                                            {!bulkDiscountCSV?.length && (
                                                <Button className='text-primary'>
                                                    <UploadOutlined /> Click to upload (Max Size: 200MB)
                                                </Button>
                                            )}
                                        </Upload>
                                    </Form.Item>
                                    <Button onClick={() => downloadSample()} type='link' className='text-primary'>
                                        <FileAddOutlined /> Sample CSV
                                    </Button>
                                </Col>
                                <Col span={24}>
                                    <div className='bg-primary-25 rounded p-2'>
                                        <ul className='text-black/80 pl-4 m-0 font-normal'>
                                            <li>Upload a Zip of CSV(s) with a single column of discount codes</li>
                                            <li>
                                                Each code must be between 3 and 20 characters, using only letters and
                                                numbers—no special characters
                                            </li>
                                            <li>Ensure no duplicate codes are present in the file</li>
                                            <li>All codes should be uppercase (ALL CAPS)</li>
                                            <li>Please ensure that the file contains correct prefix/suffix</li>
                                            <li>Max file size 200MB</li>
                                            <li>Supported format: .zip</li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                            <Divider className='mt-3' />
                        </>
                    )}
                    <Row gutter={[12, 12]} className='mt-3'>
                        <Col span={8}>
                            <Form.Item
                                rules={[
                                    { required: true, message: 'Please enter a number!' },
                                    {
                                        type: 'number',
                                        max: 20000000,
                                        min: 1,
                                        message: 'Number of codes should not exceed 2,00,00,000',
                                    },
                                ]}
                                label={
                                    generateDiscountCodeMethod === 'upload'
                                        ? 'Number of codes in Zip'
                                        : 'Number of codes to generate'
                                }
                                name='numberOfCodes'
                            >
                                <InputNumber className='w-full' max={20_000_000} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                rules={[
                                    { required: true, message: 'Please enter a number!' },
                                    {
                                        type: 'number',
                                        min: 3,
                                        max: 20,
                                        message: 'Code length should be between 3 and 20',
                                    },
                                    {
                                        validator: (_, value) => {
                                            const result = codeGenerationFormula()
                                            if (result !== true) {
                                                return Promise.reject(result)
                                            } else {
                                                return Promise.resolve()
                                            }
                                        },
                                    },
                                ]}
                                label='Code Length'
                                name='codeLength'
                            >
                                <InputNumber min={3} max={20} className='w-full' />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label='Expiry Date'
                                rules={[{ required: true, message: 'Please select expiry!' }]}
                                name='expiry'
                            >
                                <DatePicker
                                    className='w-full'
                                    format='DD/MM/YYYY HH:mm'
                                    showTime
                                    disabledDate={disabledBeforeDate}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider className='my-3' />
                    <Row gutter={[12, 12]}>
                        <Col span={4}>
                            <Form.Item
                                rules={[{ required: true, message: 'Please select an identifier!' }]}
                                name='codeIdentifier'
                            >
                                <Segmented disabled={!!discountData?.bulkDisabled} options={['Prefix', 'Suffix']} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: `Please enter ${
                                            codeIdentifier ? codeIdentifier?.toLowerCase() : 'prefix/suffix'
                                        }`,
                                    },
                                    {
                                        pattern: /^[A-Z0-9]+$/,
                                        message: 'Only capital letters (A-Z) and numbers (0-9).',
                                    },
                                    {
                                        max: codeLength - 1,
                                        message: `Length of ${codeIdentifier} must be less than "Code Length"`,
                                    },
                                ]}
                                name={codeIdentifier === 'Prefix' ? 'prefix' : 'suffix'}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    )
}

export default DiscountGenerateCodes
