import {
    Table,
    message,
    ColumnProps,
    Row,
    Button,
    Col,
    ReloadOutlined,
    TagTwoTone,
    Tag,
    InfoCircleOutlined,
    Tooltip,
    InfoCircleTwoTone,
} from 'gokwik-ui-kit'
import { useEffect, useRef, useState } from 'react'
import { makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'
import { useSelector } from 'react-redux'
import { getMerchantDetails } from '@store/user/selectors'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { handleError } from '@library/utilities/helpers/handleError'
import { useParams } from 'react-router-dom'
import AddEditCollection from '../addEditCollection'
import CollectionLogs from './collectionLogs'
import { SuccessStatusCodes } from '@library/utilities/constants/constants'

interface CollectionDetails {
    account: string
    amount: number
    balance: number
    collected: number
    collection: number
    end_date: null | string
    start_date: string
    invoice_id: string
    max_deduction_per_day: number
    status: string
    reference_id: string
    description: string
    active: boolean
    scheduled_changes: {
        collection: number
        max_deduction_per_day: number
    }
    scheduled_time: string
}

interface CollectionSummary {
    date: string
    gokwik_split_amount: number
    merchant_split_amount: number
    total_amount: number
    transactions: number
}

export default function () {
    const columns: ColumnProps<any>[] = [
        {
            title: <span>Date</span>,
            dataIndex: 'date',
            width: 200,
        },
        {
            title: <span>Total Transactions</span>,
            dataIndex: 'transactions',
        },
        {
            title: <span>Total Transaction Amount</span>,
            dataIndex: 'total_amount',
        },
        {
            title: <span>Gokwik Split Amount</span>,
            dataIndex: 'gokwik_split_amount',
        },
    ]
    const [collections, setCollections] = useState<CollectionSummary[]>([])
    const [openAddDrawer, setOpenAddDrawer] = useState<boolean>(false)
    const [openLogsDrawer, setOpenLogsDrawer] = useState<boolean>(false)
    const [collectionDetails, setCollectionDetails] = useState<CollectionDetails | null>(null)
    const [lastDate, setLastDate] = useState<string>('')
    const merchantData = useSelector(getMerchantDetails)
    const isFetchedAll = useRef(null)
    const { id } = useParams()

    useEffect(() => {
        getCollectionSummary()
        getCollections()
    }, [])

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'settings',
                href: '/payment/settings',
                text: 'Payment Settings',
            },
            {
                key: 'collections',
                href: '/payment/settings/collections',
                text: 'Collections',
            },
            {
                key: 'collection-details',
                href: `/payment/settings/collections/${id}`,
                text: id,
            },
        ])
    }, [])


    function handleEdit() {
        setOpenAddDrawer(true)
    }

    async function getCollectionSummary() {
        if (!merchantData?.m_id) {
            message.error('Merchant is required')
            return
        }
        try {
            let response = await makeAPICall({
                method: 'get',
                url:
                    process.env.REACT_APP_AUX_BASE_URL +
                    APIEndPoints.splitSettlements.getCollectionSummary(merchantData.m_id, id),
            })
            if (response.data?.data) {
                setCollectionDetails(response.data?.data)
            } else {
                setCollectionDetails(null)
            }
        } catch (error) {
            handleError(error)
        }
    }

    async function getCollections() {
        if (!merchantData?.m_id) {
            message.error('Merchant is required')
            return
        }
        if (isFetchedAll.current) {
            message.info(`Fetched all collections`)
            return
        }
        try {
            let response = await makeAPICall({
                method: 'get',
                url:
                    process.env.REACT_APP_AUX_BASE_URL +
                    APIEndPoints.splitSettlements.getCollections(merchantData.m_id, id, 10, lastDate),
            })
            if (response.data?.data) {
                const items = response.data?.data?.items ?? []
                const lastDate = response.data?.data?.last_date ?? ''
                isFetchedAll.current = false
                if (!lastDate && collections.length > 0) {
                    message.info(`Fetched all collections`)
                    isFetchedAll.current = true
                }
                setCollections((collections) => [...collections, ...items])
                setLastDate(lastDate)
            } else {
                setCollections([])
                setLastDate('')
            }
        } catch (error) {
            handleError(error)
        }
    }

    async function handleUpdate() {
        if (!merchantData?.m_id) {
            message.error('Merchant is required')
            return
        }
        const invoicePayload = {
            active: !collectionDetails.active,
        }

        try {
            const url =
                process.env.REACT_APP_AUX_BASE_URL +
                APIEndPoints.splitSettlements.updateInvoice(merchantData?.m_id, collectionDetails?.invoice_id)
            let response = await makeAPICall({
                method: 'patch',
                url,
                payload: invoicePayload,
            })
            if (SuccessStatusCodes.includes(response.data?.status_code)) {
                message.success('Status updated successfully')
                setTimeout(() => {
                    getCollectionSummary()
                    getCollections()
                }, 150)
            } else {
                const errMessage = response?.response?.data?.error?.message ?? `Failed to update`
                message.error(errMessage)
            }
        } catch (error) {
            handleError(error)
        }
    }

    const handleClose = (fetchDetails = false) => {
        if (fetchDetails) getCollectionSummary()
        setOpenAddDrawer(false)
    }

    const toggleShowLogs = (shouldOpen: boolean) => {
        setOpenLogsDrawer(shouldOpen)
    }

    const getStatusText = (status: string) => {
        switch (status) {
            case 'in_progress':
                return 'In Progress'
            case 'queued':
                return 'Queued'
            default:
                return 'Completed'
        }
    }

    const getStatusVariant = (status: string) => {
        switch (status) {
            case 'in_progress':
                return 'default'
            case 'queued':
                return 'warning'
            default:
                return 'success'
        }
    }

    return (
        <div className='w-full h-full'>
            <div className='flex justify-between items-center'>
                <div>
                    <div className='flex items-baseline mb-1'>
                        <span className='pr-2'>
                            <TagTwoTone size={12} className='border-2 rounded-full w-6 p-1 bg-blue-500 bg-opacity-10' />
                        </span>
                        <p className='text-base font-medium mb-1'>Split Collection Details</p>
                    </div>
                    <div className='mt-4'>
                        <p className='text-[14px] font-normal mb-1 text-gray-500 pl-2'>
                            Reference ID:
                            <span className='text-black font-medium pl-2'>{collectionDetails?.reference_id}</span>
                        </p>
                    </div>
                </div>
                <div className='mt-4 flex gap-2'>
                    <Button
                        onClick={handleUpdate}
                        variant='primary'
                        disabled={collectionDetails?.status === 'completed'}
                    >
                        {collectionDetails?.active ? 'Pause' : 'Start'}
                    </Button>
                    <Button onClick={handleEdit} variant='primary' disabled={collectionDetails?.status === 'completed'}>
                        Edit
                    </Button>
                    <Button onClick={() => toggleShowLogs(true)} variant='primary' type='default'>
                        Change logs
                    </Button>
                </div>
            </div>
            <div className='mt-3 bg-white rounded overflow-clip p-6'>
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <p className='text-normal font-normal mb-1 text-gray-500'>
                            Status:
                            <span className='text-sm ml-2'>
                                <Tag
                                    className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                                    variant={getStatusVariant(collectionDetails?.status)}
                                >
                                    {getStatusText(collectionDetails?.status)}
                                </Tag>
                            </span>
                        </p>
                    </Col>
                    <Col span={8}>
                        <p className='text-normal font-normal mb-1 text-gray-500'>
                            Collection Account:<span className='pl-2 text-black'>{collectionDetails?.account}</span>
                        </p>
                    </Col>
                    <Col span={8}>
                        <p className='text-normal font-normal mb-1 text-gray-500'>
                            Collection Amount:<span className='pl-2 text-black'>{collectionDetails?.amount}</span>
                        </p>
                    </Col>

                    <Col span={8}>
                        <p className='text-normal font-normal mb-1 text-gray-500'>
                            Collected:<span className='pl-2 text-black'>{collectionDetails?.collected}</span>
                        </p>
                    </Col>

                    <Col span={8}>
                        <p className='text-normal font-normal mb-1 text-gray-500'>
                            Description:<span className='pl-2 text-black'>{collectionDetails?.description}</span>
                        </p>
                    </Col>

                    <Col span={8}>
                        <p className='text-normal font-normal mb-1 text-gray-500'>
                            Collection Split
                            {collectionDetails?.scheduled_changes?.collection && (
                                <Tooltip
                                    title={`Collection Split will be updated to ${collectionDetails?.scheduled_changes?.collection}% by ${collectionDetails?.scheduled_time}`}
                                >
                                    <InfoCircleTwoTone className='ml-1 mr-1 blue-500' height={14} width={14} />
                                </Tooltip>
                            )}
                            :<span className='pl-2 text-black whitespace-nowrap'>{collectionDetails?.collection}%</span>
                        </p>
                    </Col>

                    <Col span={8}>
                        <p className='text-normal font-normal mb-1 text-gray-500'>
                            Remaining:<span className='pl-2 text-black'>{collectionDetails?.balance}</span>
                        </p>
                    </Col>

                    <Col span={8}>
                        <p className='text-normal font-normal mb-1 text-gray-500'>
                            Max Collection Per Day
                            {collectionDetails?.scheduled_changes?.max_deduction_per_day && (
                                <Tooltip
                                    title={`Max Collections Per Day will be updated to ${collectionDetails?.scheduled_changes?.max_deduction_per_day} by ${collectionDetails?.scheduled_time}`}
                                >
                                    <InfoCircleTwoTone className='ml-1 mr-1 blue-500' height={14} width={14}/>
                                </Tooltip>
                            )}
                            :
                            <span className='pl-2 text-black whitespace-nowrap'>
                                {collectionDetails?.max_deduction_per_day ?? 'NA'}
                            </span>
                        </p>
                    </Col>

                    <Col span={8}>
                        <p className='text-normal font-normal mb-1 text-gray-500'>
                            Collection Start Date:
                            <span className='pl-2 text-black'>
                                {collectionDetails?.start_date ? collectionDetails?.start_date : 'NA'}
                            </span>
                        </p>
                    </Col>
                    <Col span={8}>
                        <p className='text-normal font-normal mb-1 text-gray-500'>
                            Collection End Date:
                            <span className='pl-2 text-black'>
                                {collectionDetails?.end_date ? collectionDetails?.end_date : 'NA'}
                            </span>
                        </p>
                    </Col>
                    <Col span={8}>
                        <p className='text-normal font-normal mb-1 text-gray-500'>
                            Active:
                            <span
                                className={`pl-2 text-sm ${
                                    collectionDetails?.active ? 'text-green-500' : 'text-red-500'
                                }`}
                            >
                                {collectionDetails?.active ? 'True' : 'False'}
                            </span>
                        </p>
                    </Col>
                </Row>
            </div>
            <Col span={24} className='mt-6'>
                <p className='font-medium pl-2'>Daily Collection Details</p>
                <div className='mt-3 bg-white rounded overflow-clip'>
                    <Table
                        columns={columns}
                        dataSource={collections}
                        style={{
                            width: '100vw',
                        }}
                        pagination={false}
                        scroll={{ x: 'max-content' }}
                    />
                </div>
            </Col>
            {collections.length > 0 && (
                <Col span={24} className='p-2 bg-white flex items-center justify-center'>
                    <Button onClick={getCollections} type='text' className='flex items-center text-[#004B8D]'>
                        Show more <ReloadOutlined />
                    </Button>
                </Col>
            )}

            {openAddDrawer && (
                <AddEditCollection
                    mId={merchantData?.m_id}
                    merchantName={merchantData?.business_name}
                    isEdit={true}
                    selectedRecord={collectionDetails}
                    handleClose={handleClose}
                />
            )}

            {openLogsDrawer && (
                <CollectionLogs
                    invoiceId={collectionDetails?.invoice_id}
                    mId={merchantData?.m_id}
                    handleClose={toggleShowLogs}
                />
            )}
        </div>
    )
}
