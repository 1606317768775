import { Col, Collapse, InfoCircleOutlined, Row, Tooltip } from 'gokwik-ui-kit'
import CodLimits from './codLimits'
import BlockDiscountCodes from './blockDiscountCodes'
import DiscountCodes from './discountCodes'
import TieredDiscount from './tieredDiscounts'
import SalesChannelSpecificDiscount from './salesChannelDiscounts'
import DynamicDiscounts from './dynamicDiscounts'
import BuyXAtYDiscounts from './buyXatY'
import DiscountApplicability from './discountApplicability'
import DiscountCapping from './discountCapping'
import GwpWithAnyDiscount from './gwpAnyDiscounts'
import { useEffect } from 'react'
import { updateBreadcrumbs } from '@gokwik/utilities'
import { getUserConfig, isMerchantSelector } from '@store/user/selectors'
import { useSelector } from 'react-redux'
import { DiscountConfigKeys } from '@library/utilities/constants/constants'
import UTMDiscounting from './utmDiscount'

const CheckoutConfigs = () => {
    const config = useSelector(getUserConfig)
    const isMerchantUser = useSelector(isMerchantSelector)
    const collapseItems = [
        {
            key: 'block-discount-codes',
            label: 'COD Blocked Discount Codes',
            children: <BlockDiscountCodes />,
            configKey: 'discountCode',
        },
        {
            key: 'cod-limits',
            label: 'COD Limits',
            children: <CodLimits />,
            configKey: 'codChargeConfig',
        },
        {
            key: 'discount-codes',
            label: (
                <p>
                    GWP (Gift with Purchase) Discount Codes
                    <Tooltip
                        title={
                            <>
                                GWP (Gift with Purchase) configuration allows to whitelist any manual BXGY coupon code
                                created on Shopify following which if the coupon is applied on the GoKwik checkout then
                                the free gift (as defined in BXGY discount on Shopify) gets dynamically added
                            </>
                        }
                    >
                        <InfoCircleOutlined className='ml-2' />{' '}
                    </Tooltip>
                </p>
            ),
            children: <DiscountCodes />,
            configKey: 'gwpAndExtraDiscount',
        },
        {
            key: 'tiered-discounts',
            label: (
                <p>
                    Tiered Prepaid Discounts{' '}
                    <Tooltip
                        title={
                            <>
                                Configuration to set tiered discount on all prepaid/specific payment methods. The
                                discount can be configured for different order value range and can also be capped.
                                <br />
                                Few examples are: Flat 5% off on all prepaid orders or 5% off on UPI for orders above
                                899 or Flat 50 Rs off on all prepaid orders and so on..
                                <br />
                            </>
                        }
                    >
                        <InfoCircleOutlined className='ml-2' />{' '}
                    </Tooltip>
                </p>
            ),
            children: <TieredDiscount />,
            configKey: 'tieredPrepaidDiscount',
        },
        {
            key: 'sales-channel-specific-discount',
            label: (
                <p>
                    Sales Channel Specific Discount{' '}
                    <Tooltip
                        title={
                            <>
                                Configuration to set any discount code (must be create on Shopify) to be applicable and
                                visible on a particular sales channel only (mobile app or website). Any code configured
                                particularly for a specific sales channel will be applicable and visible to the users
                                (under view offers section) only if they are using GoKwik checkout on that particular
                                channel.
                                <br />
                                Note: Support for bulk discounts also available (use match type)
                                <br />
                            </>
                        }
                    >
                        <InfoCircleOutlined className='ml-2' />{' '}
                    </Tooltip>
                </p>
            ),
            children: <SalesChannelSpecificDiscount />,
        },
        {
            key: 'utm-discounting',
            label: (
                <p>
                    UTM Based Discounting
                    <Tooltip
                        title={
                            <>
                                UTM based discounts configuration allows you to control the application of any manual
                                coupon code created on Shopify to a particular source, medium or campaign of user
                                acquisition.
                                <br />
                                You can create any code with predefined conditions on Shopify and even use any of
                                GoKwik’s custom discount use cases (eg: GWP, Buy X at Y, etc) on top of that.
                                <br />
                                Such coupons will be auto-applied on Checkout for users coming from the defined UTMs and
                                other manual discounts cannot be stacked on top of this (except Payment offers and
                                Abandoned cart discounts).
                                <br />
                                Note: UTM is based on last click attribution model and it should be correctly passed to
                                shopify cookies(_shopify_sa_p) in order to ensure proper functioning.
                            </>
                        }
                    >
                        <InfoCircleOutlined className='ml-2' />
                    </Tooltip>
                </p>
            ),
            children: <UTMDiscounting />,
        },
        {
            key: 'dynamic-discounts',
            label: (
                <p>
                    Dynamic Discounts{' '}
                    <Tooltip
                        title={
                            <>
                                Configuration to run tiered discounts (both flat or percentage) based upon product
                                quantity or order value on a 'single' coupon code
                                <br />
                                Eg: Buy 1 and get 10 % off, Buy 2 and get 20% off and so on
                                <br />
                                OR
                                <br /> Buy 1 and get 100 Rs off, Buy 2 and get 200 Rs off and so on ORBuy for 399 and
                                get 10% off, Buy for 699 and get 20% off and so on ORBuy for 399 and get Rs 100 off, Buy
                                for 699 and get Rs 200 off and so on
                                <br />
                                <br />
                                How to set it up: Create a '0' value 'amount off products' discount on Shopify with
                                whatever conditions requiredNow simply use the same code to configure the conditions
                                below as required
                                <br />
                            </>
                        }
                    >
                        <InfoCircleOutlined className='ml-2' />{' '}
                    </Tooltip>
                </p>
            ),
            children: <DynamicDiscounts />,
            configKey: 'dynamicDiscount',
        },
        {
            key: 'buy-x-at-y',
            label: (
                <p>
                    Buy X at Y
                    <Tooltip
                        title={
                            <>
                                Configuration to run bundle discounts (cap the value of cart) based upon the eligible
                                product quantity in the cart.
                                <br />
                                Eg: Buy 3 for 899 OR Buy 2 for 699/3 for 899/4 for 1299 (single coupon code) ORBuy any
                                at 399
                                <br />
                                <br />
                                How to set it up: Create a '0' value 'amount off products' discount on Shopify with
                                whatever conditions requiredNow simply use the same code to configure the conditions
                                below as required
                                <br />
                                <br />
                                Note: Support for bulk discounts also available (use match types)
                                <br />
                            </>
                        }
                    >
                        <InfoCircleOutlined className='ml-2' />
                    </Tooltip>
                </p>
            ),
            children: <BuyXAtYDiscounts />,
            configKey: 'buyXAtYDiscounts',
        },
        {
            key: 'discount-applicability',
            label: (
                <p>
                    User Type/Cohort Based Discounts
                    <Tooltip
                        title={
                            <>
                                Configuration to set any discount code (must be created on Shopify) to be applicable for
                                a particular user segment only. Segments are: New, Repeat and Cohort based.
                                <br />
                                In new/repeat segment we allow check based on either email or phone.
                                <br />
                                Cohorts can either be created on Shopify or can be uploaded via a csv (with
                                exclude/include conditions).
                                <br />
                                All such discounts if configured under offers visibility will be shown to eligible users
                                with a 'Specially for You' tag on Checkout.
                            </>
                        }
                    >
                        <InfoCircleOutlined className='ml-2' />{' '}
                    </Tooltip>
                </p>
            ),

            children: <DiscountApplicability />,
            configKey: 'discountApplicability',
        },
        {
            key: 'discount-capping',
            label: (
                <p>
                    Discount Capping{' '}
                    <Tooltip
                        title={
                            'Configuration to cap the maximum discount value of any discount code (must be created on Shopify) and run offers like "20% off upto 100". Support for bulk discounts also available (use match types)'
                        }
                    >
                        <InfoCircleOutlined className='ml-2' />{' '}
                    </Tooltip>{' '}
                </p>
            ),
            children: <DiscountCapping />,
            configKey: 'discountCapping',
        },
        {
            key: 'gwp-with-any-discount',
            label: (
                <p>
                    GWP With Any Discounts{' '}
                    <Tooltip
                        title={
                            <>
                                Configuration which allows you to add any free gift(s) on top of any discount code (must
                                be created on Shopify)This is compatible with all kind of discounts on Shopify and
                                custom GoKwik discounts
                                <br />
                                Eg: Buy 3 for 899 + get a free gift, 10% off + a free gift , Multi GWP (offer more than
                                one freebies), etc
                                <br />
                                <br />
                                Note: Support for bulk discounts also available (use match types)
                                <br />
                            </>
                        }
                    >
                        <InfoCircleOutlined className='ml-2' />{' '}
                    </Tooltip>
                </p>
            ),
            children: <GwpWithAnyDiscount />,
            configKey: 'gwpWithAnyDiscount',
        },
    ].filter((itm) => {
        if (config?.diy_enabled) {
            return itm.key === 'cod-limits' || itm.key === 'tiered-discounts'
        }
        return isMerchantUser && itm.configKey ? config[DiscountConfigKeys[itm.configKey]] : true
    })

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                text: 'Checkout Settings',
                key: 'settings',
                href: '/checkout/settings',
            },
            {
                key: 'checkout-configs',
                text: 'Discount Configs',
                href: '/checkout/settings/discount-configs',
            },
        ])
    }, [])

    return (
        <div className='bg-white p-3 overflow-y-auto rounded'>
            <Collapse className='rounded-lg' defaultActiveKey={[collapseItems[0].key]} items={collapseItems} />
        </div>
    )
}

export default CheckoutConfigs
