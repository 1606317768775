import { RootStoreType } from '..'

export const previousReports = (state: RootStoreType) => state.filters.reports
export const previousBulkUploadFilters = (state: RootStoreType) => state.filters.bulk_upload
export const previousCustomerFilters = (state: RootStoreType) => state.filters.customers
export const previousUsersFilters = (state: RootStoreType) => state.filters.users
export const previousInvitedUsersFilters = (state: RootStoreType) => state.filters.invited_users
export const previousTimelineFilters = (state: RootStoreType) => state.filters.timelineFilters
export const previousMerchantFilters = (state:RootStoreType) => state.filters.merchants

