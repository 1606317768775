import { Collapse, Badge, Popover, message, Tooltip } from 'gokwik-ui-kit'
import { InfoCircleOutlined, LoadingOutlined, PlayCircleOutlined, ReloadOutlined, StopFilled } from '@ant-design/icons'
import { useEffect, useRef, useState } from 'react'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { handleError } from '@library/utilities/helpers/handleError'
import { useSelector } from 'react-redux'
import { getMerchantDetails } from '@store/user/selectors'
import { SuccessStatusCodes } from '@library/utilities/constants/constants'
import { WALLETS } from './payment-testing.const'
import { IPaymentInstrumentData, IRunningTests, ITestInstrumentUpdate, TestResults } from './payment-testing.interface'
import { Centrifuge } from 'centrifuge'
import { PaymentInstrumentStatusEnum, PaymentMethodEnumV2, PaymentProviderEnum } from './payment-testing.enum'
import TestDrawer from './TestDrawer'
import dayjs from 'dayjs'
import { abortTest } from './payment-testing.service'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'

export default function PaymentTesting() {
    const [netbankingData, setNetbankingData] = useState<IPaymentInstrumentData[]>([])
    const [walletData, setWalletData] = useState<IPaymentInstrumentData[]>([])
    const testResults = useRef<TestResults | {}>({});
    const merchantData = useSelector(getMerchantDetails)
    const [showTestDrawer, setShowTestDrawer] = useState<boolean>(false)
    const [selectedProvider, setSelectedProvider] = useState<PaymentProviderEnum | null>(null)
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethodEnumV2 | null>(null)
    const [paymentInstruments, setPaymentInstruments] = useState<IPaymentInstrumentData[]>([])
    const [runningTests, setRunningTests] = useState<IRunningTests>({})
    const netbankingGridApiRef = useRef(null)
    const walletGridApiRef = useRef(null)

    useEffect(() => {
        const setupCentrifuge = async () => {
            try {
                const tokenResponse = await makeAPICall({
                    url:
                        process.env.REACT_APP_AUX_BASE_URL +
                        APIEndPoints.paymentTesting.getToken +
                        `/${merchantData?.m_id}`,
                    method: 'get',
                })
                // we need a token to subscribe to the channel
                const token = tokenResponse?.data?.data

                if (token) {
                    const websocketUrl = process.env.REACT_APP_CENTRIFUGO_BASE_URL + '/connection/websocket'
                    const centrifuge = new Centrifuge(websocketUrl)
                    centrifuge.setToken(token)

                    const subscription = centrifuge.newSubscription(`instrument-test-results-${merchantData.m_id}`)

                    subscription.on('publication', (ctx) => {
                        const data = ctx?.data
                        if (data) updateTestResults(data)
                    })

                    await subscription.subscribe()
                    await centrifuge.connect()
                }
            } catch (error) {
                console.error('Error setting up Centrifuge:', error)
            }
        }

        getLastTestResults()
        setupCentrifuge()
    }, [merchantData.m_id])

    const getLastTestResults = async () => {
        // get the last runs for all the instruments
        const response = await makeAPICall({
            url:
                process.env.REACT_APP_AUX_BASE_URL +
                APIEndPoints.paymentTesting.getLastTestResults +
                `/${merchantData.m_id}`,
            method: 'get',
        })
        testResults.current = response.data.data
        setPaymentData(response.data.data)
    }

    const setCurrentRunningTestToIdle = (provider: PaymentProviderEnum, method: PaymentMethodEnumV2) => {
        setRunningTests((prev) => {
            const updatedTests = { ...prev }
            if (updatedTests[provider]) {
                updatedTests[provider] = {
                    ...updatedTests[provider],
                    [method]: {
                        ...updatedTests[provider][method],
                        isRunning: false,
                    },
                }
            }
            return updatedTests
        })
    }

    const updateTestResults = (data: ITestInstrumentUpdate) => {
        const testResultsValue = testResults.current
        const { test, instrument, completed } = data
        const { paymentProvider, paymentMethod } = test
        const { paymentInstrument, status, in_progress, result, createdAt } = instrument

        if (completed) {
            // allow user to run tests again
            setCurrentRunningTestToIdle(paymentProvider, paymentMethod)
        }

        if (testResultsValue[paymentProvider] && testResultsValue[paymentProvider][paymentMethod]) {
            const existingInstrumentIndex = testResultsValue[paymentProvider][paymentMethod]?.instruments?.findIndex(
                (inst) => inst.paymentInstrument === paymentInstrument,
            )
            if (existingInstrumentIndex !== -1) {
                testResultsValue[paymentProvider][paymentMethod][existingInstrumentIndex] = instrument
            } else {
                testResultsValue[paymentProvider][paymentMethod].push(instrument)
            }
            testResults.current = testResultsValue

            if (paymentMethod === PaymentMethodEnumV2.NETBANKING) {
                // get the specific row that needs to be updated and update only that row, instead of rerendering the whole table
                const rowNode = netbankingGridApiRef.current?.api?.getRowNode(paymentInstrument)
                rowNode.setDataValue(paymentProvider.toLowerCase(), { status, in_progress, result, createdAt })
            } else if (paymentMethod === PaymentMethodEnumV2.WALLET) {
                const rowNode = walletGridApiRef.current?.api?.getRowNode(paymentInstrument)
                rowNode.setDataValue(paymentProvider.toLowerCase(), { status, in_progress, result, createdAt })
            }
        }
    }

    const getStatusBadge = ({ status, in_progress, result, createdAt }) => {
        if (in_progress) {
            return (
                <span className='flex items-center justify-start pt-3'>
                    <LoadingOutlined spin style={{ color: 'green ' }} />
                </span>
            )
        }
        let badge
        switch (status) {
            case PaymentInstrumentStatusEnum.SUCCESS:
                badge = <Badge color='green' text='Success' />
                break
            case PaymentInstrumentStatusEnum.PARTIAL_SUCCESS:
                badge = <Badge color='yellow' text='Partial Success' />
                break
            case PaymentInstrumentStatusEnum.FAILURE:
                badge = <Badge color='orange' text='Failure' />
                break
            case PaymentInstrumentStatusEnum.NA:
                return (
                    <span className='flex items-center'>
                        <Badge color='orange' text='NA' />
                    </span>
                )
            default:
                return (
                    <span className='flex items-center'>
                        <Badge color='gray' text='Unassessed' />
                    </span>
                )
        }

        const popoverContent = (
            <div className='bg-white p-1'>
                {createdAt && (
                    <p className='text-xs mb-1'>
                        <strong>Last tested:</strong>{' '}
                        <span className='text-gray-600'>{dayjs(createdAt).format('MMMM D YYYY, h:mm:ss A')}</span>
                    </p>
                )}
                {result?.contentMatch !== undefined && (
                    <p className='text-xs mb-1'>
                        <strong>Content Match:</strong>{' '}
                        <span className='text-gray-600'>{result.contentMatch ? 'True' : 'False'}</span>
                    </p>
                )}
                {result?.urlMatch !== undefined && (
                    <p className='text-xs'>
                        <strong>URL Match:</strong>{' '}
                        <span className='text-gray-600'>{result.urlMatch ? 'True' : 'False'}</span>
                    </p>
                )}
            </div>
        )

        return (
            <Popover content={popoverContent} placement='right'>
                <span className='flex items-center'>
                    {badge}
                    <InfoCircleOutlined className='ml-1 text-gray-400 text-xs' />
                </span>
            </Popover>
        )
    }

    const handlePlay = async (provider: PaymentProviderEnum, paymentMethod: PaymentMethodEnumV2) => {
        setSelectedProvider(provider)
        setSelectedPaymentMethod(paymentMethod)
        if (paymentMethod === PaymentMethodEnumV2.NETBANKING) {
            setPaymentInstruments(netbankingData)
        } else if (paymentMethod === PaymentMethodEnumV2.WALLET) {
            setPaymentInstruments(walletData)
        }
        setShowTestDrawer(true)
    }

    const handleAbort = async (provider: PaymentProviderEnum, method: PaymentMethodEnumV2) => {
        const testId = runningTests[provider]?.[method]?.testId
        if (testId) {
            const response = await abortTest(testId)
            if (response?.data) {
                setCurrentRunningTestToIdle(provider, method)
                message.info('Test was aborted successfully')
            }
        }
    }

    const getColumns = (paymentMethod: PaymentMethodEnumV2) => [
        {
            headerName: 'Name',
            field: 'name',
            sortable: true,
            filter: true,
            flex: 1,
        },
        {
            headerName: 'Easebuzz',
            field: PaymentProviderEnum.EASEBUZZ as string,
            valueGetter: (params) => params.data?.easebuzz?.status,
            headerComponent: (params) => (
                <div className='flex items-center justify-between'>
                    <span className='cursor-pointer' onClick={() => {
                        const currentState = params.api.getColumnState().find(col => col.colId === PaymentProviderEnum.EASEBUZZ);
                        const newSort = currentState?.sort === 'asc' ? 'desc' : 'asc';
                        params.api?.applyColumnState({
                            state: [{ colId: PaymentProviderEnum.EASEBUZZ, sort: newSort }],
                            defaultState: { sort: null }
                        });
                    }}>
                        Easebuzz
                    </span>
                    {runningTests?.[PaymentProviderEnum.EASEBUZZ]?.[paymentMethod]?.isRunning ? (
                        <StopFilled
                            onClick={() =>
                                handleAbort(PaymentProviderEnum.EASEBUZZ, paymentMethod as PaymentMethodEnumV2)
                            }
                            className='ml-2 text-red-500 cursor-pointer hover:text-red-600 active:text-red-700 transition-colors duration-150'
                            style={{ fontSize: '16px' }}
                        />
                    ) : (
                        <Tooltip title='Run test' placement='top'>
                            <div>
                                <PlayCircleOutlined
                                    onClick={() => handlePlay(PaymentProviderEnum.EASEBUZZ, paymentMethod)}
                                    className='ml-2 text-green-500 cursor-pointer hover:text-green-600 active:text-green-700 transition-colors duration-150'
                                    style={{ fontSize: '16px' }}
                                />
                            </div>
                        </Tooltip>
                    )}
                </div>
            ),
            cellRenderer: (params) => getStatusBadge(params.data.easebuzz),
            cellStyle: { textAlign: 'center' },
            sortable: true,
            filter: true,
        },
        {
            headerName: 'PayU',
            field: PaymentProviderEnum.PAYU as string,
            headerComponent: (params) => (
                <div className='flex items-center justify-between'>
                    <span className='cursor-pointer' onClick={() => {
                        const currentState = params.api.getColumnState().find(col => col.colId === PaymentProviderEnum.PAYU);
                        const newSort = currentState?.sort === 'asc' ? 'desc' : 'asc';
                        params.api?.applyColumnState({
                            state: [{ colId: PaymentProviderEnum.PAYU, sort: newSort }],
                            defaultState: { sort: null }
                        });
                    }}>
                        PayU
                    </span>
                    {runningTests?.[PaymentProviderEnum.PAYU]?.[paymentMethod]?.isRunning ? (
                        <StopFilled
                            onClick={() => handleAbort(PaymentProviderEnum.PAYU, paymentMethod as PaymentMethodEnumV2)}
                            className='ml-2 text-red-500 cursor-pointer hover:text-red-600 active:text-red-700 transition-colors duration-150'
                            style={{ fontSize: '16px' }}
                        />
                    ) : (
                        <Tooltip title='Run test' placement='top'>
                            <div>
                                <PlayCircleOutlined
                                    onClick={() => handlePlay(PaymentProviderEnum.PAYU, paymentMethod)}
                                    className='ml-2 text-green-500 cursor-pointer hover:text-green-600 active:text-green-700 transition-colors duration-150'
                                    style={{ fontSize: '16px' }}
                                />
                            </div>
                        </Tooltip>
                    )}
                </div>
            ),
            cellRenderer: (params) => getStatusBadge(params.data.payu),
            cellStyle: { textAlign: 'center' },
            sortable: true,
            valueGetter: (params) => params.data?.payu?.status,
            filter: true,
        },
        {
            headerName: 'Billdesk',
            field: PaymentProviderEnum.BILLDESK as string,
            headerComponent: (params) => (
                <div className='flex items-center justify-between'>
                    <span className='cursor-pointer' onClick={() => {
                        const currentState = params.api.getColumnState().find(col => col.colId === PaymentProviderEnum.BILLDESK);
                        const newSort = currentState?.sort === 'asc' ? 'desc' : 'asc';
                        params.api?.applyColumnState({
                            state: [{ colId: PaymentProviderEnum.BILLDESK, sort: newSort }],
                            defaultState: { sort: null }
                        });
                    }}>
                        Billdesk
                    </span>
                    {runningTests?.[PaymentProviderEnum.BILLDESK]?.[paymentMethod]?.isRunning ? (
                        <StopFilled
                            onClick={() =>
                                handleAbort(PaymentProviderEnum.BILLDESK, paymentMethod as PaymentMethodEnumV2)
                            }
                            className='ml-2 text-red-500 cursor-pointer hover:text-red-600 active:text-red-700 transition-colors duration-150'
                            style={{ fontSize: '16px' }}
                        />
                    ) : (
                        <Tooltip title='Run test' placement='top'>
                            <div>
                                <PlayCircleOutlined
                                    onClick={() => handlePlay(PaymentProviderEnum.BILLDESK, paymentMethod)}
                                    className='ml-2 text-green-500 cursor-pointer hover:text-green-600 active:text-green-700 transition-colors duration-150'
                                    style={{ fontSize: '16px' }}
                                />
                            </div>
                        </Tooltip>
                    )}
                </div>
            ),
            cellRenderer: (params) => getStatusBadge(params.data.billdesk),
            cellStyle: { textAlign: 'center' },
            sortable: true,
            valueGetter: (params) => params.data?.billdesk?.status,
            filter: true,
        },
    ]

    async function setPaymentData(testResults: TestResults) {
        if (!merchantData?.m_id) {
            return
        }
        try {
            const response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.getNetbankingMappings(merchantData.m_id),
            })
            // turn the objects into map for faster access time
            Object.entries(testResults)?.forEach(([provider, providerMethods]) => {
                Object.entries(providerMethods)?.forEach(([method, instruments]) => {
                    const methodInstrumentMapping = {}
                    instruments?.forEach((instrument) => {
                        methodInstrumentMapping[instrument.paymentInstrument] = instrument
                    })
                    providerMethods[method] = methodInstrumentMapping
                })
                testResults[provider] = providerMethods
            })

            if (SuccessStatusCodes.includes(response?.data?.status_code) && response.data?.data?.length > 0) {
                const formattedNetbankingData = response.data.data.map((bank) => {
                    const easebuzzInstrument = testResults?.easebuzz?.netbanking?.[bank.code]
                    const payuInstrument = testResults?.payu?.netbanking?.[bank.code]
                    const billdeskInstrument = testResults?.billdesk?.netbanking?.[bank.code]
                    return {
                        id: bank.code,
                        key: bank.code,
                        name: bank.name,
                        api: netbankingGridApiRef.current,
                        easebuzz: {
                            status: easebuzzInstrument?.status || 'Unassessed',
                            in_progress: easebuzzInstrument?.in_progress || false,
                            result: easebuzzInstrument?.result,
                            createdAt: easebuzzInstrument?.createdAt,
                        },
                        payu: {
                            status: payuInstrument?.status || 'Unassessed',
                            in_progress: payuInstrument?.in_progress || false,
                            result: payuInstrument?.result,
                            createdAt: payuInstrument?.createdAt,
                        },
                        billdesk: {
                            status: billdeskInstrument?.status || 'Unassessed',
                            in_progress: billdeskInstrument?.in_progress || false,
                            result: billdeskInstrument?.result,
                            createdAt: billdeskInstrument?.createdAt,
                        },
                    }
                })
                setNetbankingData(formattedNetbankingData)

                const formattedWalletData = WALLETS.map((wallet) => {
                    const easebuzzInstrument = testResults?.easebuzz?.wallet?.[wallet.key]
                    const payuInstrument = testResults?.payu?.wallet?.[wallet.key]
                    const billdeskInstrument = testResults?.billdesk?.wallet?.[wallet.key]
                    return {
                        id: wallet.key,
                        key: wallet.key,
                        name: wallet.name,
                        api: walletGridApiRef.current,
                        easebuzz: {
                            status: easebuzzInstrument?.status || 'Unassessed',
                            in_progress: easebuzzInstrument?.in_progress || false,
                            result: easebuzzInstrument?.result,
                            createdAt: easebuzzInstrument?.createdAt,
                        },
                        payu: {
                            status: payuInstrument?.status || 'Unassessed',
                            in_progress: payuInstrument?.in_progress || false,
                            result: payuInstrument?.result,
                            createdAt: payuInstrument?.createdAt,
                        },
                        billdesk: {
                            status: billdeskInstrument?.status || 'Unassessed',
                            in_progress: billdeskInstrument?.in_progress || false,
                            result: billdeskInstrument?.result,
                            createdAt: billdeskInstrument?.createdAt,
                        },
                    }
                })
                setWalletData(formattedWalletData)
            } else {
                setNetbankingData([])
                setWalletData([])
            }
        } catch (error) {
            handleError(error)
        }
    }

    const PaymentTable = ({ data, paymentMethod, gridApiRef }) => (
        <div className='ag-theme-alpine' style={{ height: '100%', width: '100%' }}>
            <AgGridReact
                rowData={data}
                columnDefs={getColumns(paymentMethod)}
                domLayout='autoHeight'
                animateRows={true}
                containerStyle={{ height: '100%' }}
                getRowId={(params: any) => {
                    return params.data.id
                }}
                ref={gridApiRef}
            />
        </div>
    )

    const CollapseItem = ({ label, paymentMethod, data, gridApiRef }) => ({
        key: label,
        label: label,
        children: <PaymentTable data={data} paymentMethod={paymentMethod} gridApiRef={gridApiRef} />,
    })

    return (
        <div className='h-full w-full'>
            <TestDrawer
                open={showTestDrawer}
                onClose={() => setShowTestDrawer(false)}
                selectedProvider={selectedProvider}
                selectedPaymentMethod={selectedPaymentMethod}
                paymentInstruments={paymentInstruments}
                setRunningTests={setRunningTests}
            />
            <div className='w-full h-full'>
                <div className='flex justify-between items-center mb-4'>
                    <div>
                        <p className='text-base font-medium mb-1'>
                            Payment Testing
                            <Tooltip title='Refresh' placement='top'>
                                <ReloadOutlined
                                    onClick={() => getLastTestResults()}
                                    className='ml-2 text-green-500 cursor-pointer hover:text-green-600 active:text-green-700 transition-colors duration-150'
                                    style={{ fontSize: '16px' }}
                                />
                            </Tooltip>
                        </p>
                        <p className='text-xs text-gray-400 font-normal'>
                            View the status of payment tests for different providers.
                        </p>
                    </div>
                </div>
                <div className='bg-white p-4 rounded-lg shadow'>
                    <Collapse
                        className='mb-4'
                        items={[
                            CollapseItem({
                                label: 'Netbanking',
                                paymentMethod: PaymentMethodEnumV2.NETBANKING,
                                data: netbankingData,
                                gridApiRef: netbankingGridApiRef,
                            }),
                        ]}
                        defaultActiveKey={['Netbanking']}
                    />
                    <Collapse
                        items={[
                            CollapseItem({
                                label: 'Wallets',
                                paymentMethod: PaymentMethodEnumV2.WALLET,
                                data: walletData,
                                gridApiRef: walletGridApiRef,
                            }),
                        ]}
                        defaultActiveKey={['Wallets']}
                    />
                </div>
            </div>
        </div>
    )
}
