export const toastMessages = {
    DOWNLOAD_INITIATED: 'Download Initiated',
    DOWNLOAD_FAILED: 'Failed to initiate download',
    FILE_UPLOADED: 'File Uploaded',
    INVALID_FORM: 'Invalid inputs. Please verify inputs before publishing !',
    MANDATORY_FIELDS: 'Please fill all required fields!',
    SUCCESSFULLY_PUBLISHED: 'Changes published  successfully!',
    SUCCESSFULLY_SAVED: 'Changes saved successfully!',
    SOMETHING_WENT_WRONG: 'Something went wrong',

    SUCCESSFULLY_UPDATED: 'Changes updated successfully!',
    PUBLISH_FAILED: 'Unable to complete request',
    POPUP_DELETED: 'Popup deleted successfully',
    MAKE_CHANGE_IN_FIELDS: 'Same configurations already exist. Kindly edit the configuration to create a new popup.',
    FETCHING_ERROR: 'There is some error fetching default Assets',
    SPIN_THE_WHEEL_ERROR: 'The configurations are under development. Please get in touch with your CSM to enable.',
    DUPLICATE_URL: 'This url already exists',
    CHECK_PERCENTAGE: 'Doesn’t seem like you have the enough data to show.',
    INCOMPLETE_CONFIGS: 'Incomplete configuration',
    NO_IDENTIFIER_CONIFS: 'Kindly select a identifier to enable the configuration',
    NO_EVENTS_ENTERED: 'Kindly select at least one event to enable the configuration',
    Max_Data_capture_limit_reached: "You've reached the max selections!",
    // plans
    SUCCESS_BASIC_PLAN_SELECTED: 'Basic plan selected successfully',
}

export const toastMessageId = {
    successfullyPublished: 'successfullyPublished',
}
