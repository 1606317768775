import {
    Alert,
    Button,
    CheckCircleFilled,
    Collapse,
    CopyOutlined,
    DownOutlined,
    Form,
    InfoCircleFilled,
    Row,
    Spin,
    Tooltip,
} from 'gokwik-ui-kit'
import { useEffect, useRef, useState } from 'react'
import { CollapsePanelStatusChips } from '@pages/onboarding/kyc/utils'
import { getUserData } from '@store/user/selectors'
import { useSelector } from 'react-redux'
import { stepConfiguration } from '@pages/onboarding/stepsConfig'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'

const VBC = ({ currentStep, setCurrentStep, VBCLink, onFinish, isPanelClickable, undeterminedKycErrorState }) => {
    const userData = useSelector(getUserData)
    const [isCopied, setIsCopied] = useState(false)
    const [form] = Form.useForm()
    const copyTimerRef = useRef(null)

    const onCopy = () => {
        setIsCopied(true)
        logEvent(
            'background_verification_copy_link',
            'click',
            'kyc',
            userData?.userDetails?.email,
            userData?.merchantDetails?.m_id,
        )
        navigator.clipboard.writeText(form.getFieldValue('vbc_link')).then(
            () => {
                console.log('Link copied to clipboard!')
            },
            (err) => {
                console.error('Could not copy text: ', err)
            },
        )

        copyTimerRef.current = setTimeout(() => {
            setIsCopied(false)
        }, 4000)
    }

    useEffect(() => {
        return () => {
            if (copyTimerRef.current) {
                clearTimeout(copyTimerRef.current)
            }
        }
    }, [])

    const openInNewTab = () => {
        logEvent(
            'background_verification_new_tab_clicked',
            'click',
            'kyc',
            userData?.userDetails?.email,
            userData?.merchantDetails?.m_id,
        )
        const url = form.getFieldValue('vbc_link')
        if (url) window.open(url, '_blank').focus()
    }

    const items = [
        {
            key: '1',
            label: (
                <>
                    <div className='flex flex-col gap-1'>
                        <div className='text-[#004B8D] text-xs font-semibold'>Step 6</div>

                        <div className='flex gap-4 items-center'>
                            <div
                                className={`text-xl font-semibold ${
                                    userData?.merchantDetails?.onboarding_status?.kwik_checkout_app ===
                                        'kyc_verification_failed' &&
                                    userData?.kycData?.is_cpv_mandatory &&
                                    'text-red-700'
                                }`}
                            >
                                Virtual Background Check
                            </div>{' '}
                            <CollapsePanelStatusChips
                                errors={
                                    userData?.merchantDetails?.onboarding_status?.kwik_checkout_app ===
                                        'kyc_verification_failed' && userData?.kycData?.is_cpv_mandatory
                                        ? ['Virtual KYC Required']
                                        : []
                                }
                                stepConfiguration={stepConfiguration}
                                current_onboarding_status={
                                    userData?.merchantDetails?.onboarding_status?.kwik_checkout_app
                                }
                                sectionName={'vbc'}
                            />
                        </div>
                    </div>
                </>
            ),
            children: (
                <>
                    {VBCLink ? (
                        <>
                            <div className='flex justify-between mt-2 w-full'>
                                <Alert
                                    type='info'
                                    showIcon
                                    icon={<InfoCircleFilled />}
                                    className='w-full py-1'
                                    message={
                                        <p className='text-sm font-normal leading-6 text-[#002C8C]'>
                                            We recommend finishing this on mobile, please read guide for instructions.
                                        </p>
                                    }
                                />
                            </div>

                            <p className='text-sm font-normal leading-custom-22 text-gray-600 mt-6'>
                                Please ensure the below mentioned pre-requisite to start the process :
                            </p>

                            <ol>
                                <li className='text-sm font-normal leading-custom-22 text-gray-600'>
                                    Please ensure you are doing CPV from your{' '}
                                    <span className='text-black font-semibold'>
                                        business location (registered office address)
                                    </span>
                                </li>
                                <li className='text-sm font-normal leading-custom-22 text-gray-600'>
                                    Provide{' '}
                                    <span className='text-black font-semibold'>
                                        Camera, location & microphone access{' '}
                                    </span>
                                    whenever prompted after starting the Virtual background check.
                                </li>
                                <li className='text-sm font-normal leading-custom-22 text-gray-600'>
                                    If you need to send the background check link to other device , you can copy the
                                    link using{' '}
                                    <span className='text-[#004B8D] cursor-pointer' onClick={onCopy}>
                                        {isCopied ? (
                                            <>Copied!</>
                                        ) : (
                                            <>
                                                <CopyOutlined /> Copy link
                                            </>
                                        )}
                                    </span>
                                </li>
                            </ol>

                            {VBCLink && (
                                <Form
                                    layout='vertical'
                                    form={form}
                                    onFinish={(v) => onFinish(v, 5)}
                                    className='mt-6'
                                    initialValues={{ vbc_link: VBCLink }}
                                >
                                    <Row>
                                        <Row className='ml-2 items-center'>
                                            <Tooltip title='Open In New Tab'>
                                                <Button
                                                    // type='link'
                                                    target='_blank'
                                                    className='ml-2'
                                                    variant='primary'
                                                    onClick={openInNewTab}
                                                >
                                                    Start Virtual Background Check
                                                </Button>
                                            </Tooltip>
                                        </Row>
                                    </Row>
                                </Form>
                            )}
                        </>
                    ) : (
                        <>
                            {stepConfiguration[userData?.merchantDetails?.onboarding_status?.kwik_checkout_app]?.id >=
                            12 ? (
                                <>
                                    Virtual Background Check Completed <CheckCircleFilled className='text-green-500' />
                                </>
                            ) : (
                                <div className='flex justify-center items-center'>
                                    <div className='flex flex-col justify-center items-center'>
                                        <Spin />
                                        <p>Fetching Video KYC Link</p>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </>
            ),
        },
    ]

    const onChange = (e) => {
        setCurrentStep(5)
    }

    return (
        <div className=''>
            <Collapse
                collapsible={isPanelClickable(5)}
                className='bg-white'
                activeKey={
                    (currentStep === 5 ||
                        (userData?.merchantDetails?.onboarding_status?.kwik_checkout_app ===
                            'kyc_verification_failed' &&
                            userData?.kycData?.is_cpv_mandatory)) && ['1']
                }
                onChange={onChange}
                items={items}
                accordion
                expandIconPosition='right'
                expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
            />
        </div>
    )
}

export default VBC
