import { filterStore } from '@gokwik/utilities'
import { AnyAction, Dispatch, createSlice } from '@reduxjs/toolkit'

type State = {
    reports: any
    bulk_upload: any
    customers: any
    users: any
    invited_users: any
    timelineFilters:any
    merchants:any
}

const initialState: State = {
    reports: {},
    bulk_upload: {},
    customers: {},
    users: {},
    invited_users: {},
    timelineFilters:{},
    merchants:{}
}
const filtersSlice = createSlice({
    name: 'filtersSlice',
    initialState,
    reducers: {
        setFilters: (state, action) => ({ ...state, ...action.payload }),
    },
})

export const { setFilters } = filtersSlice.actions

export const initialiseFilters = (data) => {
    setFilters(data)
}

// export const saveFiltersData = (page: string, data: { [key: string]: any }) => {
//     setFilters({ [page]: data })
//     filterStore.getState().setFilters({ key: 'dashboard', data: { [page]: data } })
// }

export const saveFiltersData =
    (page: string, data: { [key: string]: any }) => async (dispatch: Dispatch<AnyAction>) => {
        dispatch(setFilters({ [page]: data }))
        filterStore.getState().setFilters({ key: 'dashboard', data: { [page]: data } })
    }

export default filtersSlice.reducer
