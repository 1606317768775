import { disabledBeforeDate, disabledBeforeTime } from '@library/utilities/helpers/helper'
import { useAppDispatch } from '@library/utilities/hooks'
import { updateSummary } from '@store/discounts'
import dayjs, { Dayjs } from 'dayjs'
import { Alert, Checkbox, Col, DatePicker, Divider, Form, InputNumber, Row } from 'gokwik-ui-kit'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const DiscountConditions = ({ form }: { form: any }) => {
    const location = useLocation()
    const isBulkDiscount = location.pathname.includes('bulk')
    const endDateRequired = Form.useWatch('endDateRequired', form)
    const enableLimitTotalUsage = Form.useWatch('enableLimitTotalUsage', form)
    const dispatch = useAppDispatch()
    useEffect(() => {
        const startDate = form.getFieldValue('startDate')
        if (!startDate) {
            const startDate = dayjs()
            form.setFieldsValue({
                startDate,
            })
            dispatch(updateSummary({ startDate }))
        }
    }, [])
    return (
        <Form
            onValuesChange={(changedValues, allValues) => {
                dispatch(updateSummary(allValues))
            }}
            form={form}
            layout='vertical'
            requiredMark='optional'
        >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <p className='text-sm font-medium w-full pb-2'>Maximum discount usage</p>
                    {isBulkDiscount ? (
                        <Alert
                            message='Each code is limited to one user per customer when created as part of a set'
                            type='info'
                            showIcon
                            className='mb-3'
                        />
                    ) : (
                        <>
                            <div className='flex gap-x-4'>
                                <Form.Item name={'enableLimitTotalUsage'} valuePropName='checked'>
                                    <Checkbox>Limit Total Usage</Checkbox>
                                </Form.Item>
                                {enableLimitTotalUsage && (
                                    <Form.Item
                                        name='limitDiscountTotalUsageValue'
                                        rules={[
                                            {
                                                required: enableLimitTotalUsage,
                                                message: 'Please enter maximum discount usage',
                                            },
                                        ]}
                                    >
                                        <InputNumber className='w-60' min={0} />
                                    </Form.Item>
                                )}
                            </div>
                            <Form.Item name={'enableLimitOneUsagePerCustomer'} valuePropName='checked'>
                                <Checkbox>Limit One Usage Per Customer</Checkbox>
                            </Form.Item>
                        </>
                    )}
                    <Divider className='my-3' />
                    <p className='text-sm font-semibold w-full pb-2'>Active period</p>
                    <Form.Item name={'endDateRequired'} valuePropName='checked'>
                        <Checkbox>Set End Date</Checkbox>
                    </Form.Item>
                    <div className='flex gap-x-4 mt-3'>
                        <Form.Item
                            label='Start Date and Time'
                            name='startDate'
                            required
                            rules={[{ required: true, message: 'Please select start date and time' }]}
                        >
                            <DatePicker
                                disabledDate={disabledBeforeDate}
                                format='DD/MM/YYYY HH:mm'
                                // disabledTime={() => disabledBeforeTime()}
                                showTime={{
                                    showHour: true,
                                    showMinute: true,
                                }}
                            />
                        </Form.Item>
                        {endDateRequired && (
                            <Form.Item
                                label='End Date and Time'
                                name='endDate'
                                rules={[
                                    { required: endDateRequired, message: 'Please select end date and time' },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('startDate') < value) {
                                                return Promise.resolve()
                                            }
                                            return Promise.reject(
                                                new Error('End date should be greater than start date'),
                                            )
                                        },
                                    }),
                                ]}
                            >
                                <DatePicker
                                    disabledDate={disabledBeforeDate}
                                    format='DD/MM/YYYY HH:mm'
                                    showTime={{
                                        showHour: true,
                                        showMinute: true,
                                    }}
                                    // disabledTime={() => disabledBeforeTime()}
                                />
                            </Form.Item>
                        )}
                    </div>
                    {!isBulkDiscount && (
                        <>
                            <Divider className='my-3' />
                            <p className='text-sm font-semibold w-full pb-2'>Payment specific controls</p>
                            <Form.Item name={'disableCod'} valuePropName='checked'>
                                <Checkbox>Disable Cash on Delivery on Application</Checkbox>
                            </Form.Item>
                            <Form.Item name={'disablePrepaidDiscounts'} valuePropName='checked'>
                                <Checkbox>Disable Prepaid Discounts on Application</Checkbox>
                            </Form.Item>
                        </>
                    )}
                </Col>
            </Row>
        </Form>
    )
}

export default DiscountConditions
