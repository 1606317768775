import { FieldTimeOutlined } from '@ant-design/icons'
import { addBreadcrumb, convertIsoToLocaleString } from '@gokwik/utilities'
import { capitalizeFirstLetter, formatAmount } from '@library/utilities/helpers/helper'
import { ColumnProps, Tag, Tooltip } from 'gokwik-ui-kit'
import { Link } from 'react-router-dom'
import TwidEarnIcon from '@library/images/common/twid-earn.svg'
import TwidBurnIcon from '@library/images/common/twid-burn.svg'
import { PaymentProviders, RewardRedemptions } from '@library/utilities/constants/constants'

const renderPaymentStatus = (text: string) => {
    const statusMap = {
        payment_successful: { variant: 'success', label: 'Completed' },
        payment_failed: { variant: 'error', label: 'Failed' },
        pending: { variant: 'warning', label: 'Pending' },
        dropped: { variant: 'warning', label: 'User Dropped' }
    };

    const { variant, label } = statusMap[text] || statusMap.dropped;

    return (
        <div>
            <Tag
                className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                variant={variant}
            >
                {label}
            </Tag>
        </div>
    );
};

const isRewardTransaction = (payment_provider: string) => {
    const specificProviders = [PaymentProviders.TWID, PaymentProviders.QWIKCILVER, PaymentProviders.GYFTR, PaymentProviders.NECTOR];
    return specificProviders.includes(payment_provider);
};

const renderAmount = (amount: number | null | undefined, record: any) => {
    if (record.redemption_type === RewardRedemptions.EARN && record.payment_provider === PaymentProviders.TWID) {
        return <p>NA</p>;
    }
    return <p>{formatAmount(amount, 2)}</p>;
};
const renderPaymentProvider = (payment_provider: string | null | undefined, redemption_type: string | null | undefined) => {
    if (payment_provider && isRewardTransaction(payment_provider)) {
        return (
            <div className='flex items-center gap-x-2'>
                <p>{payment_provider}</p>
                {redemption_type === RewardRedemptions.EARN && (
                    <Tooltip title='Rewards Earned' placement='top'>
                        <img src={TwidEarnIcon} alt="Redemption Type"  />
                    </Tooltip>
                )}
                {redemption_type === RewardRedemptions.BURN && (
                    <Tooltip title='Rewards Redeemed' placement='top'>
                        <img src={TwidBurnIcon} alt="Redemption Type"/>
                    </Tooltip>
                )}
            </div>
        );
    }
    return <p>{payment_provider}</p>;
};
function getRedirectionLink(text, source) {
    switch (source) {
        case 'payment_link':
            return `/payment/payment-links/${text}`

        default:
            return `/checkout/orders/${text}`
    }
}
export const transactionColumnsInternal: ColumnProps<any>[] = [
    {
        title: <span>Order #</span>,
        dataIndex: 'order_number',
        width: 180,
        ellipsis: true,
        render: (text: string, record: any) => (
            <Link
                to={getRedirectionLink(text, record.source)}
                onClick={() =>
                    addBreadcrumb({
                        key: 'order-details',
                        href: getRedirectionLink(text, record.source),
                        text,
                    })
                }
            >
                <a style={{ width: 'min-content' }}>
                    <Tooltip placement='topLeft' title={text}>
                        {text.toUpperCase()}
                    </Tooltip>
                </a>
            </Link>
        ),
        fixed: 'left',
    },
    {
        title: <span>Payment ID</span>,
        dataIndex: 'payment_id',
        render: (text: string) => {
            return <>{text.toUpperCase()}</>
        },
        width: 200,
        fixed: 'left',
    },
    {
        title: <span>Phone</span>,
        dataIndex: 'phone',
    },
    {
        title: <span>Payment Mode</span>,
        dataIndex: 'payment_method',
    },
    {
        title: <span>Payment Provider</span>,
        dataIndex: 'payment_provider',
        render: (payment_provider, record) => renderPaymentProvider(payment_provider, record.redemption_type),
    },
    {
        title: <span>Amount</span>,
        dataIndex: 'amount',
        render: (amount, record) => renderAmount(amount, record),
    },
    {
        title: <span>Payment Status</span>,
        dataIndex: 'bank_status',
        render: renderPaymentStatus,
        align: 'center',
    },
    {
        title: <span>Failure Bucket 1</span>,
        dataIndex: 'bucket1',
        render: (t) => <p>{capitalizeFirstLetter(t)}</p>
    },
    {
        title: <span>Failure Bucket 2</span>,
        dataIndex: 'bucket2',
        render: (t) => <p>{capitalizeFirstLetter(t)}</p>
    },
    {
        title: <span>Status Description</span>,
        dataIndex: 'status_description',
        render: (text) => (
            <Tooltip title={text}>
                <p>{text?.slice(0, 20)}</p>
            </Tooltip>
        ),
    },
    {
        title: <span>Auto Refund</span>,
        dataIndex: 'is_auto_refund',
        render: (e) => <p>{e.toString()}</p>,
    },
    {
        title: <span>Is Deleted</span>,
        dataIndex: 'is_deleted',
    },
    {
        title: <span>Created At</span>,
        dataIndex: 'created_at',
        render: (e) => convertIsoToLocaleString(e),
    },
    {
        title: <span>Updated At</span>,
        dataIndex: 'updated_at',
        render: (e) => convertIsoToLocaleString(e),
    },
    {
        title: <span>Settlement At</span>,
        dataIndex: 'settlement.bank_timestamp',
        render: (e) => convertIsoToLocaleString(e),
    },
    {
        title: <span>Merchant ID</span>,
        dataIndex: 'merchant_id',
    },
    {
        title: <span>Customer ID</span>,
        dataIndex: 'customer_id',
    },
    {
        title: <span>Cust Ref ID</span>,
        dataIndex: 'cust_ref_id',
    },
    {
        title: <span>Is Blocked</span>,
        dataIndex: 'is_blocked',
    },
    {
        title: <span>Short Link</span>,
        dataIndex: 'short_link',
    },
    {
        title: <span>UPI Link</span>,
        dataIndex: 'upi_link',
        render: (text) => (
            <Tooltip title={text}>
                <a className='link'>{text?.slice(0, 10)}</a>
            </Tooltip>
        ),
    },
    {
        title: <span>NPC ID</span>,
        dataIndex: 'npc_id',
    },
    {
        title: <span>Settlement id</span>,
        dataIndex: 'settlement_id',
    },
    {
        title: <span>Card Network</span>,
        dataIndex: 'cardNetwork',
    },
    {
        title: <span>Card Issuer</span>,
        dataIndex: 'cardIssuer',
    },
    {
        title: <span>Bank Name</span>,
        dataIndex: 'bank_name',
    },
    {
        title: <span>Source</span>,
        dataIndex: 'source',
    },
]

export const transactionColumnsMerchant: ColumnProps<any>[] = [
    {
        title: <span>Order #</span>,
        dataIndex: 'order_number',
        width: 180,
        ellipsis: true,
        render: (text: string, record: any) => (
            <Link
                to={getRedirectionLink(text, record.source)}
                onClick={() =>
                    addBreadcrumb({
                        key: 'order-details',
                        href: getRedirectionLink(text, record.source),
                        text,
                    })
                }
            >
                <a style={{ width: 'min-content' }}>
                    <Tooltip placement='topLeft' title={text}>
                        {text.toUpperCase()}
                    </Tooltip>
                </a>
            </Link>
        ),
        fixed: 'left',
    },
    {
        title: <span>Payment ID</span>,
        dataIndex: 'payment_id',
        render: (text: string) => {
            return <>{text.toUpperCase()}</>
        },
        width: 200,
        fixed: 'left',
    },
    {
        title: <span>Phone</span>,
        dataIndex: 'phone',
    },
    {
        title: <span>Payment Mode</span>,
        dataIndex: 'payment_method',
    },
    {
        title: <span>Payment Provider</span>,
        dataIndex: 'payment_provider',
    },
    {
        title: <span>Amount</span>,
        dataIndex: 'amount',
        render: (amount, record) => renderAmount(amount, record),
    },
    {
        title: <span>Bank Status</span>,
        dataIndex: 'bank_status',
        render: renderPaymentStatus,
        align: 'center',
    },
    {
        title: <span>Failure Bucket 1</span>,
        dataIndex: 'bucket1',
        render: (t) => <p>{capitalizeFirstLetter(t)}</p>
    },
    {
        title: <span>Failure Bucket 2</span>,
        dataIndex: 'bucket2',
        render: (t) => <p>{capitalizeFirstLetter(t)}</p>
    },
    {
        title: <span>Status Description</span>,
        dataIndex: 'status_description',
    },
    {
        title: <span>Auto Refund</span>,
        dataIndex: 'is_auto_refund',
        render: (e) => <p>{e.toString()}</p>,
    },
    {
        title: <span>Created At</span>,
        dataIndex: 'created_at',
        render: (e) => convertIsoToLocaleString(e),
    },
    {
        title: <span>Card Network</span>,
        dataIndex: 'cardNetwork',
    },
    {
        title: <span>Card Issuer</span>,
        dataIndex: 'cardIssuer',
    },
    {
        title: <span>Bank Name</span>,
        dataIndex: 'bank_name',
    },
    {
        title: <span>Source</span>,
        dataIndex: 'source',
    },
]
