const sampleAWBData = [
    {
        'Merchant OrderID': '#WL375778',
        'AWB Number (Optional)': '',
        'Shipping Provider (Optional)': 'nimbus',
        'AWB Status': 'rto',
    },
    {
        'Merchant OrderID': 'FC102079833',
        'AWB Number (Optional)': '742073750573423',
        'Shipping Provider (Optional)': '',
        'AWB Status': 'shipped',
    },
    {
        'Merchant OrderID': '#11510790',
        'AWB Number (Optional)': '742073750573425',
        'Shipping Provider (Optional)': 'shipway',
        'AWB Status': 'order placed',
    },
]

const cellKeys = ['Merchant OrderID', 'AWB Number (Optional)', 'Shipping Provider (Optional)', 'AWB Status']

const cellHeadings = ['Merchant OrderID', 'AWB Number (Optional)', 'Shipping Provider (Optional)', 'AWB Status']

const requiredHeadings = ['Merchant OrderID', 'AWB Status']

const uploadType = 'AWB_INGESTION'

const awbFillRateSuccessfullIngestion =
    'Great news! Your AWB Ingestion is currently underway. This process can take up to 12-18 hours to complete. Please check back here tomorrow after 1 PM for the latest status update.'

const invalidUploadFile =
    "Please ensure your file's column names precisely match those in the provided sample file for successful data uploads."

export {
    sampleAWBData,
    cellKeys,
    cellHeadings,
    requiredHeadings,
    uploadType,
    awbFillRateSuccessfullIngestion,
    invalidUploadFile,
}
