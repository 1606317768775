import { loaderEvent, makeAPICall } from '@gokwik/utilities'
import { API_ENDPOINTS } from './endpoints'
import { getCall, postCall } from './service'

export const getDashboardTableData = async (params): Promise<any> => {
  const response = await getCall(API_ENDPOINTS.DASHBOARD_TABLE, params)
  return response.data
}

// function for shipment details
export const getShipmentDetails = async (orderId: string) => {
  // Remove any '#' from the orderId
  const sanitizedOrderId = encodeURIComponent(orderId)

  // Make the API call with the sanitized ID
  const response = await getCall(API_ENDPOINTS.SHIPMENT_DETAILS + '/' + sanitizedOrderId, {})
  return response.data
}

export const getShipmentAnalytics = async (params): Promise<any> => {
  const response = await getCall(API_ENDPOINTS.SHIPMENT_ANALYTICS, params)
  return response.data
}

export const getOrdersTableData = async (params): Promise<any> => {
  const response = await getCall(API_ENDPOINTS.ORDERS_TABLE, params)
  return response.data
}

export const bulkShipOrders = async (headers, body): Promise<any> => {
  const response = await postCall(API_ENDPOINTS.BULK_SHIP, body, headers)
  return response.data
}

export const editOrder = async (body): Promise<any> => {
  const response = await postCall(API_ENDPOINTS.ORDERS_UPDATE, body)
  return response.data
}


export const bulkOrderUpdate = async (headers, body): Promise<any> => {
  loaderEvent({ count: 1 })
  const response = await fetch(process.env.BASE_URL + API_ENDPOINTS.BULK_UPDATE, {
    body,
    method: 'post',
    headers: { ...headers, token: process.env.KWIKSHIP_TOKEN },
  })
  loaderEvent({ count: -1 })
  return response
}

export const fetchLocations = async (merchant_id): Promise<any> => {
  const response = await getCall(`${API_ENDPOINTS.LOCATIONS}`, { merchant_id })
  return response.data
}

export const downloadShipmentLabel = async (body) => {
  const response = await postCall(API_ENDPOINTS.DOWNLOAD_LABEL, body)
  return response.data
}
