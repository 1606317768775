import {
    Table,
    message,
    ColumnProps,
    Button,
    TagTwoTone,
    Tag,
    Tooltip,
    Input,
    SearchOutlined,
    Col,
    PlusCircleOutlined,
} from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { addBreadcrumb, makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'
import { useSelector } from 'react-redux'
import { getMerchantDetails } from '@store/user/selectors'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { handleError } from '@library/utilities/helpers/handleError'
import { Link } from 'react-router-dom'
import AddEditCollection from './addEditCollection'

interface Invoice {
    id: string
    amount: number
    balance: number
    collection: number
    max_deduction_per_day: number
    recovery_account: string
    active: boolean
    status: string
    reference_id: string
    description: string
    created_at: number
    updated_at: number
}

export default function () {
    // columns mapping
    const columns: ColumnProps<any>[] = [
        {
            title: <span>Collection Id</span>,
            dataIndex: 'id',
            width: 100,
            ellipsis: true,
            render: (text: string) => (
                <Link
                    to={`/payment/settings/collections/${text}`}
                    onClick={() =>
                        addBreadcrumb({
                            key: 'collection-details',
                            href: `/payment/settings/collections/${text}`,
                            text,
                        })
                    }
                >
                    <a style={{ width: 'min-content' }}>
                        <Tooltip placement='topLeft' title={text}>
                            {text.toUpperCase()}
                        </Tooltip>
                    </a>
                </Link>
            ),
            fixed: 'left',
        },
        {
            title: <span>Reference Id</span>,
            dataIndex: 'reference_id',
            fixed: 'left',
        },
        {
            title: <span>Description</span>,
            dataIndex: 'description',
        },
        {
            title: <span>Account</span>,
            dataIndex: 'recovery_account',
        },
        {
            title: <span>Split Deduction %</span>,
            dataIndex: 'collection',
        },
        {
            title: <span>Amount</span>,
            dataIndex: 'amount',
        },
        {
            title: <span>Balance</span>,
            dataIndex: 'balance',
        },
        {
            title: <span>Created At</span>,
            dataIndex: 'created_at',
        },
        {
            title: <span>Active</span>,
            dataIndex: 'active',
            render: (e) => {
                return (
                    <span className='text-sm'>
                        <Tag
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                            variant={e ? 'success' : 'error'}
                        >
                            {e ? 'True' : 'False'}
                        </Tag>
                    </span>
                )
            },
        },
        {
            title: <span>Status</span>,
            dataIndex: 'status',
            render: (e) => {
                return (
                    <span className='text-sm'>
                        <Tag
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                            variant={getStatusVariant(e)}
                        >
                            {getStatusText(e)}
                        </Tag>
                    </span>
                )
            },
        },
    ]
    const [invoices, setInvoices] = useState<Invoice[]>([])
    const [searchedInvoices, setSearchedInvoices] = useState<Invoice[]>([])
    const [openAddDrawer, setOpenAddDrawer] = useState<boolean>(false)
    const merchantData = useSelector(getMerchantDetails)

    useEffect(() => {
        getMerchantInvoices()
    }, [])

    updateBreadcrumbs((prev) => [
        prev[0],
        {
            key: 'settings',
            href: '/payment/settings',
            text: 'Payment Settings',
        },
        {
            key: 'collections',
            href: '/payment/settings/collections',
            text: 'Collections',
        },
    ])

    const getStatusText = (status:string) => {
        switch(status){
            case 'in_progress':
                return 'In Progress'
            case 'queued':
                return 'Queued'
            default:
                return 'Completed'        
        }
    }

    const getStatusVariant = (status:string) => {
        switch(status){
            case 'in_progress':
                return 'default'
            case 'queued':
                return 'warning'
            default:
                return 'success'   
        }
    }

    async function getMerchantInvoices() {
        if (!merchantData?.m_id) {
            message.error('Merchant is required')
            return
        }
        try {
            let response = await makeAPICall({
                method: 'get',
                url:
                    process.env.REACT_APP_AUX_BASE_URL +
                    APIEndPoints.splitSettlements.getMerchantInvoices(merchantData.m_id),
            })
            if (response.data?.data?.length > 0) {
                const invoices = response.data.data
                setInvoices(invoices)
                setSearchedInvoices(invoices)
            } else {
                setInvoices([])
            }
        } catch (error) {
            handleError(error)
        }
    }

    const searchInReferences = (id: string) => {
        if (!id) {
            setSearchedInvoices(invoices)
            return
        }
        const filteredInvoices = invoices.filter((invoice) => invoice?.reference_id?.toLowerCase()?.includes(id?.toLowerCase()))
        setSearchedInvoices(filteredInvoices)
    }

    const handleClose = (fetchDetails = false) => {
        if (fetchDetails) getMerchantInvoices()
        setOpenAddDrawer(false)
    }

    return (
        <div className='w-full h-full'>
            <div className='flex justify-between items-center'>
                <div>
                    <div className='flex items-baseline mb-1'>
                        <span className='pr-2'>
                            <TagTwoTone size={12} className='border-2 rounded-full w-6 p-1 bg-blue-500 bg-opacity-10' />
                        </span>
                        <p className='text-base font-medium mb-1'>Split Collections</p>
                    </div>
                    <div className='mt-4'>
                        <Input
                            placeholder='Reference Id'
                            onChange={(e) => searchInReferences(e.target.value)}
                            suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
                        />
                    </div>
                </div>
                <Button onClick={() => setOpenAddDrawer(true)} variant='primary'>
                    Add New Collection
                </Button>
            </div>
            <Col span={24}>
                <div className='mt-4 bg-white rounded overflow-clip'>
                    <Table
                        rowKey='invoice_id'
                        columns={columns}
                        dataSource={searchedInvoices}
                        style={{
                            width: '100vw',
                        }}
                        pagination={false}
                        scroll={{ x: 'max-content' }}
                    />
                </div>
            </Col>
            {openAddDrawer && (
                <AddEditCollection
                    mId={merchantData?.m_id}
                    merchantName={merchantData?.business_name}
                    isEdit={false}
                    selectedRecord={null}
                    handleClose={handleClose}
                />
            )}
        </div>
    )
}
