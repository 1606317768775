import {
    Button,
    ColumnProps,
    CopyOutlined,
    Dropdown,
    EditOutlined,
    EyeOutlined,
    MenuProps,
    MoreOutlined,
    Popconfirm,
    Popover,
    ShopOutlined,
    Switch,
    Tag,
    Tooltip,
} from 'gokwik-ui-kit'
import { useContext, useMemo, useState } from 'react'
import { formatPaymentMethod, OfferContext, OfferListing, OfferPageParent, OfferStatus } from './constants'
import { convertIsoToLocaleDateString } from '@gokwik/utilities'
import logo from '@library/images/common/gk-gray.svg'
import { getOfferUpdateDisableReason, getStatusTooltip, getStatusVariant } from './offer.service'

function getMenuItems(row: OfferListing, isMerchantUser: boolean, openAddRemoveMerchant): MenuProps['items'] {
    return [
        {
            key: '1',
            label: 'Edit',
            icon: <EditOutlined />,
            disabled: true,
        },
        {
            key: '2',
            label: 'Duplicate',
            icon: <CopyOutlined />,
            disabled: true,
        },
        {
            key: '3',
            label: 'View Offer Construct',
            icon: <EyeOutlined />,
            disabled: true,
        },
        isMerchantUser
            ? undefined
            : {
                  key: '4',
                  label: (
                      <Tooltip title={!row.is_global_offer ? 'Cannot update merchant level offers' : ''}>
                          Update Merchants
                      </Tooltip>
                  ),
                  icon: <ShopOutlined />,
                  onClick: () => openAddRemoveMerchant(),
                  disabled: !row.is_global_offer,
              },
    ]
}

function RenderStatus({ status, rec, isMerchant }) {
    const offerContext = useContext(OfferContext)
    const cannotUpdateStatus = useMemo(
        () => getOfferUpdateDisableReason({ status, isMerchant, merchant_id: offerContext.merchant_id, offer: rec }),
        [status, isMerchant, rec, offerContext],
    )
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const switchChecked = useMemo(() => {
        if (rec?.status === OfferStatus.PUBLISHED && rec?.is_global_offer) {
            if ((isMerchant || offerContext.parent === OfferPageParent.PAYMENT) && offerContext.merchant_id) {
                return rec?.merchant_ids?.includes(offerContext.merchant_id)
            } else if (offerContext.merchant_id_searched) {
                return rec?.merchant_ids?.includes(offerContext.merchant_id_searched)
            }
        }
        return rec?.status === OfferStatus.PUBLISHED
    }, [isMerchant, rec, offerContext])

    const confirm = () => {
        setOpen(false)
        setLoading(true)
        offerContext.updateStatus(
            rec.status !== OfferStatus.PUBLISHED,
            rec?.id,
            () => setLoading(false),
            () => setLoading(false),
        )
    }

    const cancel = () => {
        setOpen(false)
    }

    const handleOpenChange = (openState) => {
        if (!openState) {
            setOpen(openState)
            return
        }

        if ((rec?.merchant_ids?.length ?? 0) < 2) {
            confirm()
        } else {
            setOpen(openState)
        }
    }

    return (
        <div className='flex items-center gap-x-3'>
            <Popconfirm
                title='Update Status'
                description={
                    <>
                        <p>Are you sure to update the status?</p>
                        <p>There are {rec?.merchant_ids?.length ?? 0} active merchants using this offer.</p>
                    </>
                }
                open={open}
                onOpenChange={handleOpenChange}
                onConfirm={confirm}
                onCancel={cancel}
                okText='Yes'
                cancelText='No'
            >
                <Tooltip title={cannotUpdateStatus}>
                    <Switch size='small' checked={switchChecked} loading={loading} disabled={!!cannotUpdateStatus} />
                </Tooltip>
            </Popconfirm>
            {status?.length && (
                <Tooltip
                    title={getStatusTooltip(
                        status,
                        !isMerchant && rec?.is_global_offer && !!offerContext.merchant_id_searched,
                    )}
                >
                    <Tag
                        variant={getStatusVariant(status)}
                        className='!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs'
                    >
                        {status}
                    </Tag>
                </Tooltip>
            )}
        </div>
    )
}

export function getColumns(isMerchant): ColumnProps<any>[] {
    return [
        {
            title: <span>Offer Code</span>,
            width: 150,
            fixed: 'left',
            dataIndex: 'coupon_code',
            ellipsis: true,
            render: (coupon_code, rec, i) => {
                const offerContext = useContext(OfferContext)
                return (
                    <div className='flex items-center gap-x-2'>
                        <Tooltip title={coupon_code}>
                            <Button
                                className='px-0 !outline-0 truncate'
                                block
                                type='link'
                                onClick={() => offerContext.viewOffer(i)}
                            >
                                <span className='text-left truncate w-full'>{coupon_code}</span>
                            </Button>
                        </Tooltip>
                        {rec.is_global_offer && (
                            <Tooltip title="This offer is made by GoKwik. You're allowed to duplicate and change active status.">
                                <div className='ms-auto flex items-center justify-center h-6 w-8 rounded-full bg-[#F0F0F0]'>
                                    <img className='h-4' src={logo} />
                                </div>
                            </Tooltip>
                        )}
                    </div>
                )
            },
        },
        {
            title: <span>Offer Name</span>,
            width: 150,
            dataIndex: 'title',
            ellipsis: true,
            render: (title) => <Tooltip title={title} children={<p className='truncate w-full'>{title}</p>} />,
        },
        {
            title: <span>Status</span>,
            dataIndex: 'show_status',
            width: 150,
            render: (status, rec) => <RenderStatus status={status} rec={rec} isMerchant={isMerchant} />,
        },
        {
            title: <span>Creation date</span>,
            width: 120,
            dataIndex: 'created_at',
            render: (created_at) => convertIsoToLocaleDateString(created_at),
        },
        {
            title: <span>Offer Type</span>,
            width: 150,
            dataIndex: 'offer_category',
            render: (offer_category) => <p className='capitalize'>{offer_category?.replace(/_/g, ' ')}</p>,
        },
        {
            title: <span>Payment Method</span>,
            width: 180,
            dataIndex: 'valid_payment_methods',
            ellipsis: true,
            render: (valid_payment_methods) => {
                const methods = (valid_payment_methods?.map((method) => formatPaymentMethod(method)) as string[]) ?? []
                const content = methods.at(1) && (
                    <ul className='p-0 pe-2 m-0 list-none overflow-scroll max-h-32'>
                        {methods.slice(1).map((m, i) => (
                            <li key={i}>{m}</li>
                        ))}
                    </ul>
                )
                return (
                    <>
                        {methods.at(0) && <Tag>{methods.at(0)}</Tag>}
                        {content && (
                            <Popover content={content}>
                                <Tag>+{methods.length - 1}</Tag>
                            </Popover>
                        )}
                    </>
                )
            },
        },
        {
            title: <span>Start date</span>,
            width: 120,
            dataIndex: 'start_date',
            render: (start_date) => convertIsoToLocaleDateString(start_date),
        },
        {
            title: '',
            fixed: 'right',
            width: 50,
            render: (offerData, _, i) => {
                const offerContext = useContext(OfferContext)
                const items = getMenuItems(offerData, isMerchant, () => offerContext.addRemoveMerchant(i))
                return (
                    <Dropdown menu={{ items }} trigger={['click']}>
                        <MoreOutlined className='p-2 rounded-full hover:bg-gray-300' />
                    </Dropdown>
                )
            },
        },
    ]
}
