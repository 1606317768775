import { ArrowLeftOutlined, Skeleton } from 'gokwik-ui-kit'
import footerText from '@library/images/footer-text-gk.svg'
import gokwikLogo from '@library/images/common/logo-gokwik-business-suit.svg'
import codBanner from '@library/images/codBanner.svg'
import unionImage from '@library/images/union.svg'
import phoneImage from '@library/images/phone.svg'
import { logoSources } from '../shopify-app-ui-customisations/gokwik-checkout-ui/constants'

interface Props {
    newPaymentsUiColorConfig: { brandColor: string; textColor: string }
    logo: string
}

const CodUiPreview = ({ newPaymentsUiColorConfig, logo }: Props) => {
    return (
        <div>
            <div className='text-center text-xl font-semibold py-2'>Preview</div>
            <div className='flex flex-col relative mx-auto max-h-[100dvh] shadow-md'>
                <div className='bg-white px-3 py-3 max-w-md w-full overflow-auto'>
                    <div className='relative w-full flex items-center py-2 justify-between'>
                        <div className='flex left-1 top-4 py-1'>
                            <ArrowLeftOutlined />
                            <div className='mx-auto ml-2'>
                                <img
                                    src={logoSources(logo)[0]}
                                    alt='logo'
                                    height={40}
                                    width={60}
                                    onError={(e) => {
                                        if (e.target instanceof HTMLImageElement) {
                                            const img = e.target
                                            const currentSrc = img.src
                                            const currentIndex = logoSources(logo).indexOf(currentSrc)
                                            if (logo && currentIndex < 3) {
                                                img.src = logoSources(logo)[currentIndex + 1]
                                            } else {
                                                img.src = gokwikLogo
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className='font-medium'>₹3000</div>
                    </div>

                    {/* phone number section */}
                    <div className='flex justify-between rounded-lg py-2 text-sm text-[#929ca8]'>
                        <div className='name-info flex items-center gap-2.5'>
                            <img src={unionImage} alt='avatar' className='w-[15px] h-auto' />
                            <p className='m-0 p-0'>John Doe</p>
                        </div>
                        <div className='phone flex items-center gap-2.5'>
                            <img src={phoneImage} alt='phone' className='w-[15px] h-auto' />
                            <p className='m-0 p-0'>9999999999</p>
                        </div>
                    </div>
                </div>
                <div className='p-3 flex flex-col flex-grow overflow-auto bg-light-grey max-h-screen mt-3'>
                    <div className='flex justify-center items-center flex-grow w-full bg-light-grey h-[200px]'>
                        <img className='object-contain max-h-[85%] max-w-full' src={codBanner} alt='cod-banner' />
                    </div>
                    <div className='bg-white p-3 space-y-3 flex flex-col justify-between mx-auto mb-5 min-h-fit rounded-md'>
                        <div className='space-y-2 py-3'>
                            <Skeleton.Input active={false} block />
                            <Skeleton.Input active={false} block />
                        </div>
                        <div className='space-y-2'>
                            <button
                                className='w-full bg-black text-white font-semibold p-4 rounded-md border-none'
                                disabled
                                style={{
                                    backgroundColor: newPaymentsUiColorConfig?.brandColor,
                                    color: newPaymentsUiColorConfig?.textColor,
                                }}
                            >
                                Proceed
                            </button>
                            <button
                                className='w-full bg-white font-semibold border-2 border-solid text-black p-4 rounded-md'
                                disabled
                                style={{
                                    borderColor: newPaymentsUiColorConfig?.brandColor,
                                }}
                            >
                                Try Other Payments
                            </button>
                        </div>

                        <div className='mt-2 text-center items-center'>
                            <img src={footerText} height={'20'} alt='gokwik' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CodUiPreview
