import { makeAPICall } from "@gokwik/utilities"
import APIEndPoints from "@library/utilities/constants/apiEndpoints"
import { handleError } from "@library/utilities/helpers/handleError"
import { Tag } from "gokwik-ui-kit"
import { ComponentProps } from "react"

export function getProviderName(provider: string) {
    switch (provider) {
        case 'payu':
            return 'PayU'
        case 'billdesk':
            return 'BillDesk'
        default:
            return provider
    }
}

export function getTagVariant(percent: number): ComponentProps<typeof Tag>['variant'] {
    if (percent > 80) return 'success'
    else if (percent > 40) return 'default'
    return 'error'
}

export const getSettlementSummary = async (params, callback: CallableFunction) => {
    try {
        let response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.settlements.summary,
            params
        })
        if (response?.success) {
            callback(response?.data?.data)
        } else {
            callback(null)
            handleError(response)
        }
    } catch (err) {
        callback(null)
        handleError(err)
    }
}

export const getSettlementListing = async (params, callback: CallableFunction) => {
    try {
        let response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.settlements.listing,
            params
        })
        if (response?.success) {
            callback(response?.data?.data)
        } else {
            callback([], 0)
            handleError(response)
        }
    } catch (err) {
        callback([], 0)
        handleError(err)
    }
}

export const getSettlementSearchListing = async (params, callback: CallableFunction) => {
    try {
        let response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.settlements.search,
            params
        })
        if (response?.success) {
            callback(response?.data?.data)
        } else {
            callback([], 0)
            handleError(response)
        }
    } catch (err) {
        callback([], 0)
        handleError(err)
    }
}

export const getSettlementDetails = async (params, callback: CallableFunction) => {
    try {
        let response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.settlements.details,
            params
        })
        if (response?.success) {
            callback(response?.data?.data)
        } else {
            callback(null)
            handleError(response)
        }
    } catch (err) {
        callback(null)
        handleError(err)
    }
}