import { Button, Checkbox, CloudUploadOutlined, DownloadOutlined, Form, Select, Upload } from 'gokwik-ui-kit'
import { JOB_TYPES, FileValidatorRule, messages, normFile, uploadFile, validateFile, getFile } from '../bulk.service'
import { validatePermission } from '@gokwik/utilities'
import { useEffect } from 'react'
import { useState } from 'react'
import { PermissionValues } from '@library/utilities/constants/constants'

export default function Step1({ onClose }) {
    const [form] = Form.useForm()
    const [publishPermission, setPublishPermission] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [submittable, setSubmittable] = useState<boolean>(false)

    const downloadSample = () => {
        const job = JOB_TYPES[form.getFieldValue('jobType')]
        getFile(job.sample_key, `${job.label}.csv`)
    }

    const onFinish = (values: any) => {
        setSubmitting(true)
        uploadFile({
            file: values.file[0].originFileObj,
            body: {
                file_name: values.file[0].originFileObj.name,
                file_size: values.file[0].originFileObj.size,
                file_type: 'csv',
                auto_publish: values.publish,
                ...JOB_TYPES[values.jobType].body,
            },
            callback: (status) => {
                setSubmitting(false)
                if (status === 'success') onClose('change')
            },
        })
    }

    useEffect(() => {
        setPublishPermission(validatePermission(PermissionValues.bulkUpload.publish))
    }, [])

    const values = Form.useWatch([], form)

    useEffect(() => {
        form.validateFields({ validateOnly: true })
            .then(() => setSubmittable(true))
            .catch(() => setSubmittable(false))
    }, [form, values])

    return (
        <Form
            layout='horizontal'
            colon={false}
            className='w-full [&>.ant-form-item]:mb-3'
            onFinish={onFinish}
            form={form}
            initialValues={{ jobType: Object.keys(JOB_TYPES)[0], publish: false }}
        >
            <Form.Item label='Job Type'>
                <div className='flex gap-x-2'>
                    <Form.Item
                        name='jobType'
                        rules={[{ required: true, message: messages.JOB_TYPE_REQUIRED }]}
                        className='w-full'
                    >
                        <Select className='capitalize' onChange={() => form.resetFields(['file'])}>
                            {Object.entries(JOB_TYPES).map(([key, type]) => (
                                <Select.Option className='capitalize' key={key} value={key}>
                                    {type.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Button onClick={downloadSample}>
                        <DownloadOutlined /> Sample
                    </Button>
                </div>
            </Form.Item>
            <Form.Item
                name='file'
                valuePropName='fileList'
                rules={[{ required: true, message: messages.FILE_REQUIRED }, FileValidatorRule]}
                getValueFromEvent={normFile}
            >
                <Upload.Dragger
                    name='files'
                    accept='.csv'
                    multiple={false}
                    maxCount={1}
                    customRequest={(info) => {
                        validateFile(info, form.getFieldValue('jobType'))
                    }}
                >
                    <div className='py-8'>
                        <p className='ant-upload-drag-icon'>
                            <CloudUploadOutlined />
                        </p>
                        <p>Click or drag a CSV file to this area</p>
                    </div>
                </Upload.Dragger>
            </Form.Item>

            <Form.Item
                name='publish'
                valuePropName='checked'
                tooltip={!publishPermission ? messages.PUBLISH_PERMISSION : ''}
            >
                <Checkbox disabled={!publishPermission}>Publish on successful validation</Checkbox>
            </Form.Item>

            <Form.Item>
                <Button
                    onClick={() => form.submit()}
                    disabled={!submittable}
                    loading={submitting}
                    variant='primary'
                    className='w-full'
                >
                    Upload & Save as Draft
                </Button>
            </Form.Item>
        </Form>
    )
}
