import {
    Input,
    FolderFilled,
    Row,
    Col,
    Button,
    Form,
    Radio,
    message,
    CloseOutlined,
    Select,
    Divider,
    FileImageOutlined,
} from 'gokwik-ui-kit'
import { CardLayout } from './cardLayout'
import React, { useState, useEffect, FC } from 'react'
import { DiscountFormState } from '../helpers/useCreateDiscount'
import { ProductsOrCollectionsSearchModal } from './products&CollectionsSearchModal'

interface ProductSelectionAndCollectionInterface {
    formState?: DiscountFormState
    setFormState?: any
    discountAction: any
}

const Card: FC<ProductSelectionAndCollectionInterface> = (props) => {
    const { formState, discountAction, setFormState } = props

    const condition = formState?.rules?.[0]?.conditions?.[0] || {}

    const [category, setCategory] = useState(() =>
        condition.collection_name_mapping?.length > 0 ? 'specific_collections' : 'specific_products',
    )
    const [searchValue, setSearchValue] = useState('')
    const [selectedProducts, setSelectedProducts] = useState(condition.selected_products || [])
    const [selectedProductVariants, setSelectedProductVariants] = useState(condition.selected_product_variants || [])
    const [renderProductMapping, setRenderProductMapping] = useState(condition.product_name_mapping || [])
    const [selectedCollections, setSelectedCollections] = useState(condition.collection_name_mapping || [])
    const [showCheckedListTags, setShowCheckedListTags] = useState(false)

    const [modalProps, setModalProps] = useState({ title: '', searchPlaceholder: 'Search products' })
    const [showProductSelectionModal, setShowProductSelectionModal] = useState(false)

    const handleInputBrowseInputChange = (e) => {
        if (!category && e.target.value) {
            message.error('Please select a category of items from which to browse')
            return
        }
        if (category && e.target.value) {
            setSearchValue(e.target.value)
            setShowProductSelectionModal(true)
        }
    }

    const handleCategoryChange = (value) => {
        setCategory(value)
    }

    const handleBrowseProduct = () => {
        if (!category) return message.error('Please select a category of items from which to browse')
        setShowProductSelectionModal(true)
    }

    const manageOptionSelection = (e) => {
        setFormState((prevState) => {
            const updatedRules = [...prevState.rules]
            updatedRules[0] = {
                ...updatedRules[0],
                conditions: [
                    {
                        ...updatedRules[0].conditions[0],
                        applies_to_all_products: e.target.value,
                    },
                ],
            }
            return { ...prevState, rules: updatedRules }
        })
    }

    const removeProductFromList = (product_id) => {
        setSelectedProducts((prevProducts) => prevProducts?.filter((product) => product?.product_id !== product_id))
        setSelectedProductVariants((prevVariants) =>
            prevVariants?.filter((variant) => variant?.product_id !== product_id),
        )
        setRenderProductMapping((prevProducts) => prevProducts?.filter((product) => product?.product_id !== product_id))
        setFormState((prev) => {
            const temp = JSON.parse(JSON.stringify(prev))
            const condition = temp.rules[0].conditions[0]

            condition.product_name_mapping = condition.product_name_mapping?.filter(
                (item) => item?.product_id !== product_id,
            )
            condition.applicable_list = condition.applicable_list?.filter((item) => item !== product_id)
            condition.product_variant_applicable_list = condition.product_variant_applicable_list?.filter((variant) => {
                return condition.product_name_mapping?.some((product) =>
                    product?.variant_ids?.some((selectedVariant) => selectedVariant?.variant_id === variant),
                )
            })
            condition.selected_products = condition.selected_products?.filter((item) => item?.product_id !== product_id)
            condition.selected_product_variants = condition.selected_product_variants?.filter(
                (item) => item?.product_id !== product_id,
            )
            return temp
        })
    }

    const removeCollectionFromList = (id) => {
        setSelectedCollections((prevCollections) =>
            prevCollections.filter((collection) => collection.collection_id !== id),
        )
        setFormState((prev) => {
            const temp = JSON.parse(JSON.stringify(prev))
            const condition = temp.rules[0].conditions[0]

            condition.collection_name_mapping = condition.collection_name_mapping?.filter(
                (item) => item?.collection_id !== id,
            )
            condition.applicable_list = condition.applicable_list?.filter((item) => item !== id)
            return temp
        })
    }

    useEffect(() => {
        if (category === 'specific_products') {
            setFormState((prev) => {
                const temp = JSON.parse(JSON.stringify(prev))
                const condition = temp.rules[0].conditions[0]

                condition.product_name_mapping = renderProductMapping?.map((item) => ({
                    product_name: item?.product_name,
                    product_id: item?.product_id,
                    variant_ids:
                        item?.variant_ids?.length > 0
                            ? item?.variant_ids.map((variant) => ({
                                  variant_id: variant?.variant_id,
                                  variant_name: variant?.variant_name,
                              }))
                            : [],
                }))
                condition.product_attribute = 'ProductId'
                temp.rules[0].actions[0].product_attribute = 'ProductId'

                if ('collection_name_mapping' in condition) {
                    delete condition.collection_name_mapping
                    delete condition.applicable_list
                }
                return temp
            })
        } else if (category === 'specific_collections') {
            setFormState((prev) => {
                const temp = JSON.parse(JSON.stringify(prev))
                const condition = temp.rules[0].conditions[0]

                condition.collection_name_mapping = selectedCollections?.map((item) => ({
                    collection_id: item?.collection_id,
                    collection_name: item?.collection_name,
                }))
                condition.applicable_list = selectedCollections?.map((item) => item.collection_id)
                condition.product_attribute = 'CollectionId'
                temp.rules[0].actions[0].product_attribute = 'CollectionId'

                if ('product_name_mapping' in condition) {
                    delete condition.product_name_mapping
                }
                return temp
            })
        }
    }, [category])

    useEffect(() => {
        if (category) {
            setModalProps({
                title: category === 'specific_products' ? 'Choose from products' : 'Choose from collections',
                searchPlaceholder: category === 'specific_products' ? 'Search products' : 'Search collections',
            })
        }
    }, [category])

    useEffect(() => {
        const products = condition.product_name_mapping || []
        if (products.length > 0) {
            setRenderProductMapping(products)
            setCategory('specific_products')
        }
    }, [discountAction, condition.product_name_mapping?.length])

    useEffect(() => {
        const collections = condition.collection_name_mapping || []
        if (collections.length > 0) {
            setSelectedCollections(collections)
            setCategory('specific_collections')
        }
    }, [discountAction, condition.collection_name_mapping?.length])

    useEffect(() => {
        if (condition.selected_products?.length) {
            setSelectedProducts(condition.selected_products)
            setSelectedProductVariants(condition.selected_product_variants)
            setRenderProductMapping(condition.product_name_mapping)
        }
    }, [condition.selected_product_variants])

    return (
        <CardLayout title='Applies to' subtitle='Specify the criteria for discount applicability'>
            {showProductSelectionModal && (
                <ProductsOrCollectionsSearchModal
                    showProductSelectionModal={showProductSelectionModal}
                    setShowProductSelectionModal={setShowProductSelectionModal}
                    modalProps={modalProps}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    category={category}
                    selectedProducts={selectedProducts}
                    setSelectedProducts={setSelectedProducts}
                    setRenderProductMapping={setRenderProductMapping}
                    selectedProductVariants={selectedProductVariants}
                    setSelectedProductVariants={setSelectedProductVariants}
                    selectedCollections={selectedCollections}
                    setSelectedCollections={setSelectedCollections}
                    setShowCheckedListTags={setShowCheckedListTags}
                    setFormState={setFormState}
                    formState={formState}
                />
            )}

            <Form.List name={['rules']} initialValue={formState?.rules}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.length > 0 && (
                            <div>
                                <Radio.Group
                                    onChange={manageOptionSelection}
                                    value={formState?.rules[0]?.conditions[0]?.applies_to_all_products}
                                    className='flex flex-col'
                                >
                                    <Radio value={true}>All Products</Radio>
                                    <Radio value={false}>Specific Collection/Product</Radio>
                                </Radio.Group>

                                {!formState?.rules[0]?.conditions[0]?.applies_to_all_products && (
                                    <>
                                        <div className='mb-4'>
                                            <Select
                                                label='Any items from'
                                                placeholder=''
                                                onChange={handleCategoryChange}
                                                defaultValue={category}
                                                value={category}
                                            >
                                                <Select.Option value='specific_products'>
                                                    Specific Products
                                                </Select.Option>
                                                <Select.Option value='specific_collections'>
                                                    Specific Collections
                                                </Select.Option>
                                            </Select>
                                        </div>

                                        <Row gutter={[12, 0]}>
                                            <Col span={18}>
                                                <Form.Item>
                                                    <Input
                                                        value={searchValue}
                                                        allowClear
                                                        placeholder={modalProps?.searchPlaceholder}
                                                        onChange={handleInputBrowseInputChange}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item>
                                                    <Button className='w-full' onClick={handleBrowseProduct}>
                                                        <FolderFilled /> Browse
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </>
                                )}

                                {!formState?.rules[0]?.conditions[0]?.applies_to_all_products &&
                                category === 'specific_products'
                                    ? renderProductMapping &&
                                      renderProductMapping?.length > 0 && (
                                          <div className='flex gap-2 flex-wrap'>
                                              {renderProductMapping?.map((item, index) => {
                                                  return (
                                                      <div
                                                          className='border border-solid border-[#00000026] rounded-lg'
                                                          key={index}
                                                      >
                                                          <div className='flex justify-between gap-2 p-1 min-w-max items-center'>
                                                              <div className='flex gap-1 items-center truncate'>
                                                                  <span className='bg-[#627F99] rounded-md'>
                                                                      <FileImageOutlined className='text-sm text-white p-[6px]' />
                                                                  </span>
                                                                  <span className='text-sm font-semibold truncate'>
                                                                      {item?.product_name}
                                                                  </span>
                                                              </div>

                                                              <div
                                                                  className={
                                                                      discountAction === 'view'
                                                                          ? 'pointer-events-none'
                                                                          : 'cursor-pointer'
                                                                  }
                                                                  onClick={() =>
                                                                      removeProductFromList(item?.product_id)
                                                                  }
                                                              >
                                                                  <CloseOutlined className='text-[#00000073]' />
                                                              </div>
                                                          </div>
                                                          <Divider className='my-1' />
                                                          <div>
                                                              {item?.variant_ids?.length > 0 ? (
                                                                  <ul className='list-none m-0 p-0'>
                                                                      {item.variant_ids.map((variant, vIndex) => (
                                                                          <li
                                                                              key={vIndex}
                                                                              className='py-1 text-sm text-gray-500 flex items-center ml-1'
                                                                          >
                                                                              <span className='bg-gray-200 rounded-full px-2 py-1 text-xs mr-1'>
                                                                                  {vIndex + 1}
                                                                              </span>
                                                                              {variant?.variant_name}
                                                                          </li>
                                                                      ))}
                                                                  </ul>
                                                              ) : (
                                                                  <p className='text-sm text-gray-500 mr-1 ml-1'>
                                                                      All variants selected
                                                                  </p>
                                                              )}
                                                          </div>
                                                      </div>
                                                  )
                                              })}
                                          </div>
                                      )
                                    : !formState?.rules[0]?.conditions[0]?.applies_to_all_products &&
                                      selectedCollections &&
                                      selectedCollections?.length > 0 && (
                                          <div className='flex gap-2 flex-wrap'>
                                              {selectedCollections?.map((item, index) => {
                                                  return (
                                                      <div key={index}>
                                                          <div className='border border-solid border-[#00000026] rounded-lg flex justify-between gap-2 p-1 min-w-max items-center'>
                                                              <div className='flex gap-1 items-center truncate'>
                                                                  <span className='bg-[#627F99] rounded-md'>
                                                                      <FileImageOutlined className='text-sm text-white p-[6px]' />
                                                                  </span>
                                                                  <span className='text-sm font-semibold truncate'>
                                                                      {item?.collection_name}
                                                                  </span>
                                                              </div>

                                                              <div
                                                                  className={
                                                                      discountAction === 'view'
                                                                          ? 'pointer-events-none'
                                                                          : 'cursor-pointer'
                                                                  }
                                                                  onClick={() =>
                                                                      removeCollectionFromList(item?.collection_id)
                                                                  }
                                                              >
                                                                  <CloseOutlined className='text-[#00000073]' />
                                                              </div>
                                                          </div>
                                                      </div>
                                                  )
                                              })}
                                          </div>
                                      )}
                            </div>
                        )}
                    </>
                )}
            </Form.List>
        </CardLayout>
    )
}

export const ProductSelectionAndCollectionCard = React.memo(Card)
