import { deepEqualAndDiff, titleCase } from '@gokwik/utilities'
import useShopifyMerchantConfigApi from '@library/utilities/hooks/useShopifyMerchantConfig'
import { InfoCircleOutlined, Select, Switch, Tooltip } from 'gokwik-ui-kit'
import { useEffect, useState, useRef } from 'react'
import { CloseReviewModal } from '../helpers/CloseReviewModal'
import { ICommonEvents } from '@library/utilities/helpers/eventsHelper'

const CodVerification = (props: {
    setOnSave
    setOnDiscard
    handleUnsavedChanges
    codConfigEvents: ICommonEvents
    reviewFlowEvents: any
}) => {
    const { setOnSave, setOnDiscard, handleUnsavedChanges, codConfigEvents, reviewFlowEvents } = props
    const { config, saveConfig } = useShopifyMerchantConfigApi({
        useCache: true,
        fireSuccessEvent: codConfigEvents.configsUpsert.edit.fireSuccessEvent,
        fireFailureEvent: codConfigEvents.configsUpsert.edit.fireFailureEvent,
    })

    const availableRiskFlags = ['High Risk', 'Medium Risk', 'Low Risk']
    const [selectedRiskFlags, setSelectedRiskFlags] = useState(availableRiskFlags)

    const [localConfig, setLocalConfig] = useState({
        mandatoryOtpForCod: false,
        mandatoryOtpForNewUser: false,
        cod_prompt: [],
        recaptcha: [],
        digits: [],
        cod_blocking: [],
        mandatory_otp: [],
    })
    const [isConfigSaved, setIsConfigSaved] = useState(false)
    const [handleSaveCalled, setHandleSaveCalled] = useState(false)
    const localConfigRef = useRef(localConfig)
    const configInterventionsRef = useRef(config)
    const previousConfigRef = useRef(null)
    const codInterventionKeys = ['cod_prompt', 'recaptcha', 'digits', 'cod_blocking', 'mandatory_otp']
    const mandatoryOtpKeys = ['mandatoryOtpForCod', 'mandatoryOtpForNewUser']
    const isLogoUploaded = !!config?.logo

    useEffect(() => {
        codConfigEvents.fireClickedEvent()
    }, [])

    useEffect(() => {
        setOnSave(handleSave)
        setOnDiscard(handleDiscard)
    }, [setOnSave, setOnDiscard])

    useEffect(() => {
        if (config) {
            const currentConfig = {
                cod_prompt: config?.codInterventionConfig?.cod_prompt || [],
                recaptcha: config?.codInterventionConfig?.recaptcha || [],
                digits: config?.codInterventionConfig?.digits || [],
                cod_blocking: config?.codInterventionConfig?.cod_blocking || [],
                mandatory_otp: config?.codInterventionConfig?.mandatory_otp || [],
                mandatoryOtpForCod: config?.mandatoryOtpForCod,
                mandatoryOtpForNewUser: config?.mandatoryOtpForNewUser,
            }
            checkIfCodInterventionsChanged(currentConfig)
            const newConfig = { ...currentConfig }
            if (newConfig.mandatoryOtpForCod || newConfig.mandatoryOtpForNewUser) {
                newConfig.cod_prompt = []
                newConfig.recaptcha = []
                newConfig.digits = []
                newConfig.cod_blocking = []
                newConfig.mandatory_otp = []
            }
            setLocalConfig(newConfig)
            localConfigRef.current = newConfig
            configInterventionsRef.current = config
            resetSelectedRiskFlags()
        }
        setHandleSaveCalled(false)
    }, [config])

    function checkIfCodInterventionsChanged(currentConfig) {
        const areArraysEqual = (arr1, arr2) => {
            if (arr1.length !== arr2.length) return false
            return arr1.every((value, index) => value === arr2[index])
        }
        const hasConfigChanged = () => {
            const prevConfig = previousConfigRef.current
            if (!prevConfig) return true
            return (
                !areArraysEqual(prevConfig.cod_prompt, currentConfig.cod_prompt) ||
                !areArraysEqual(prevConfig.recaptcha, currentConfig.recaptcha) ||
                !areArraysEqual(prevConfig.digits, currentConfig.digits) ||
                !areArraysEqual(prevConfig.cod_blocking, currentConfig.cod_blocking) ||
                !areArraysEqual(prevConfig.mandatory_otp, currentConfig.mandatory_otp) ||
                prevConfig.mandatoryOtpForCod !== currentConfig.mandatoryOtpForCod ||
                prevConfig.mandatoryOtpForNewUser !== currentConfig.mandatoryOtpForNewUser
            )
        }
        if (handleSaveCalled && hasConfigChanged()) {
            setIsConfigSaved(true)
        }
        previousConfigRef.current = currentConfig
    }

    function resetSelectedRiskFlags() {
        setSelectedRiskFlags(
            availableRiskFlags.filter((riskFlag) => {
                return (
                    !localConfig?.cod_prompt?.includes(riskFlag) &&
                    !localConfig?.recaptcha?.includes(riskFlag) &&
                    !localConfig?.digits?.includes(riskFlag) &&
                    !localConfig?.cod_blocking?.includes(riskFlag) &&
                    !localConfig?.mandatory_otp?.includes(riskFlag)
                )
            }),
        )
    }

    useEffect(() => {
        localConfigRef.current = localConfig
        resetSelectedRiskFlags()
    }, [localConfig])

    function isConfigDisabled(key: string) {
        // Config should be disabled, if rto config is disabled or if the config has no risk flags and there are no more risk flags available to be set.
        return (
            !config?.rtoEnabled ||
            !(
                localConfig?.[key]?.length > 0 ||
                (selectedRiskFlags.length > 0 && selectedRiskFlags.length <= availableRiskFlags.length)
            )
        )
    }
    function mapRiskFlagsToOptions() {
        return selectedRiskFlags.map((option) => ({
            label: titleCase(option),
            value: option,
        }))
    }

    function handleChange(key, value) {
        props.codConfigEvents.configsUpsert.edit.fireClickedEvent({
            configClicked: key,
            configValue: value,
        })
        if (key === 'mandatoryOtpForCod' || key === 'mandatoryOtpForNewUser') {
            const resetOtherOtpKey = key === 'mandatoryOtpForCod' ? 'mandatoryOtpForNewUser' : 'mandatoryOtpForCod'

            setLocalConfig((prevConfig) => ({
                ...prevConfig,
                [key]: value,
                cod_prompt: [],
                recaptcha: [],
                digits: [],
                cod_blocking: [],
                mandatory_otp: [],
                [resetOtherOtpKey]: false,
            }))
        } else if (codInterventionKeys.includes(key)) {
            const updatedConfig = {
                ...localConfig,
                [key]: value,
                mandatoryOtpForCod: false,
                mandatoryOtpForNewUser: false,
            }
            setLocalConfig(updatedConfig)

            setSelectedRiskFlags(
                availableRiskFlags.filter((riskFlag) => {
                    return !value.includes(riskFlag)
                }),
            )
        }
        localConfigRef.current = localConfig
        handleUnsavedChanges(true)
    }

    function handleSave() {
        const currentConfig = localConfigRef.current
        const previousConfig = {
            mandatoryOtpForCod: configInterventionsRef.current.mandatoryOtpForCod,
            mandatoryOtpForNewUser: configInterventionsRef.current.mandatoryOtpForNewUser,
            ...configInterventionsRef.current.codInterventionConfig,
        }
        if (!deepEqualAndDiff(previousConfig, currentConfig).isEqual) {
            saveConfig({
                codInterventionConfig: {
                    cod_prompt: currentConfig?.cod_prompt,
                    recaptcha: currentConfig?.recaptcha,
                    digits: currentConfig?.digits,
                    cod_blocking: currentConfig?.cod_blocking,
                    mandatory_otp: currentConfig?.mandatory_otp,
                },
                mandatoryOtpForCod: currentConfig?.mandatoryOtpForCod,
                mandatoryOtpForNewUser: currentConfig?.mandatoryOtpForNewUser,
            })
        }
        setHandleSaveCalled(true)
        handleUnsavedChanges(false)
    }

    function handleDiscard() {
        const currentConfigInterventions = configInterventionsRef.current
        setLocalConfig({
            cod_prompt: currentConfigInterventions?.codInterventionConfig?.cod_prompt || [],
            recaptcha: currentConfigInterventions?.codInterventionConfig?.recaptcha || [],
            digits: currentConfigInterventions?.codInterventionConfig?.digits || [],
            cod_blocking: currentConfigInterventions?.codInterventionConfig?.cod_blocking || [],
            mandatory_otp: currentConfigInterventions?.codInterventionConfig?.mandatory_otp || [],
            mandatoryOtpForCod: currentConfigInterventions?.mandatoryOtpForCod,
            mandatoryOtpForNewUser: currentConfigInterventions?.mandatoryOtpForNewUser,
        })
        localConfigRef.current = localConfig
        resetSelectedRiskFlags()
        handleUnsavedChanges(false)
        props.codConfigEvents.configsUpsert.discardSave()
    }

    const Inputs = [
        {
            key: 'cod_prompt',
            text: 'COD Prompt (Risk based)',
            tooltipText:
                'This can be enabled for users basis their risk category, select multiple risk categories from the dropdown on the right to enable this.',
            component: Select,
            options: mapRiskFlagsToOptions(),
            width: 100,
            placeholder: 'Risk flags',
            disabled: isConfigDisabled('cod_prompt'),
        },
        {
            key: 'recaptcha',
            text: 'COD ReCaptcha (Risk based)',
            tooltipText:
                'This can be enabled for users basis their risk category, select multiple risk categories from the dropdown on the right to enable this.',
            component: Select,
            options: mapRiskFlagsToOptions(),
            width: 100,
            placeholder: 'Risk flags',
            disabled: isConfigDisabled('recaptcha'),
        },
        {
            key: 'digits',
            text: 'COD Digits Captcha (Risk based)',
            tooltipText:
                'This can be enabled for users basis their risk category, select multiple risk categories from the dropdown on the right to enable this.',
            component: Select,
            options: mapRiskFlagsToOptions(),
            width: 100,
            placeholder: 'Risk flags',
            disabled: isConfigDisabled('digits'),
        },
        {
            key: 'cod_blocking',
            text: 'Disable COD (Risk based)',
            tooltipText:
                'This can be enabled for users basis their risk category, select multiple risk categories from the dropdown on the right to enable this.',
            component: Select,
            options: mapRiskFlagsToOptions(),
            width: 100,
            placeholder: 'Risk flags',
            disabled: isConfigDisabled('cod_blocking'),
        },
        {
            key: 'mandatory_otp',
            text: 'COD OTP (Risk based)',
            tooltipText:
                'Enable COD OTP for High, Medium, or Low risk orders to add an extra layer of verification and reduce fraud. Works with other COD security settings.',
            component: Select,
            options: mapRiskFlagsToOptions(),
            width: 100,
            placeholder: 'Risk flags',
            disabled: isConfigDisabled('mandatory_otp'),
        },
        {
            key: 'mandatoryOtpForCod',
            text: 'COD OTP For All',
            tooltipText: 'Enabling the feature will allow users to place CoD orders only after OTP verification',
            component: Switch,
            disabled: !isLogoUploaded,
        },
        {
            key: 'mandatoryOtpForNewUser',
            text: 'COD OTP For New User',
            tooltipText: 'Enabling the feature will allow new users to place CoD orders only after OTP verification',
            component: Switch,
            disabled: !isLogoUploaded,
        },
    ]

    return (
        <div className='w-full flex flex-col gap-2 inter p-2 rounded-md bg-[#f2f4f675] cod-verification'>
            {Inputs.map((input, index) => (
                <div className='flex justify-between items-center p-3 gap-1 rounded-md hover:bg-gray-100' key={index}>
                    <div className='w-2/3'>
                        <div className='flex gap-2 items-center'>
                            <p className='text-sm font-semibold mb-2'>
                                {input.text}
                                <Tooltip title={<>{input.tooltipText}</>}>
                                    <InfoCircleOutlined className='ml-2 mr-2' />{' '}
                                </Tooltip>
                                <div>
                                    {codInterventionKeys.includes(input.key) && !config?.rtoEnabled ? (
                                        <span className='text-sm text-gray-400 font-normal'>
                                            This feature only works with RTO. To enable RTO, complete KYC or contact the
                                            GoKwik team
                                        </span>
                                    ) : mandatoryOtpKeys.includes(input.key) && !isLogoUploaded ? (
                                        <span className='text-sm text-gray-400 font-normal'>
                                            Please upload brand logo in UI Customisation section then try activating
                                            this feature
                                        </span>
                                    ) : null}
                                </div>
                            </p>
                        </div>
                    </div>
                    <div className='flex gap-1 items-center'>
                        <input.component
                            className='mx-5 text-[#142a81fa]'
                            style={{ minWidth: input.width, maxWidth: '100%' }}
                            options={input.options}
                            mode='multiple'
                            placeholder={input.placeholder}
                            value={localConfig[input.key]}
                            disabled={input.disabled}
                            onChange={(value) => handleChange(input.key, value)}
                        />
                    </div>
                </div>
            ))}
            {isConfigSaved ? <CloseReviewModal setIsConfigSaved={setIsConfigSaved} events={reviewFlowEvents} /> : ''}
        </div>
    )
}

export default CodVerification
