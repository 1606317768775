import { downloadFromURL, makeAPICall } from '@gokwik/utilities'
import { handleError } from '@library/utilities/helpers/handleError'
import { message, type UploadProps } from 'gokwik-ui-kit'
import { type UploadEvent, type ValidatorRule } from './types'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'

export enum Statuses {
    SUCCESS = 'success',
    FAILED = 'failed',
    IN_PROGRESS = 'in_progress',
    DRAFT = 'draft',
    PUBLISHING = 'publishing',
    PARTIAL_PUBLISH = 'partial_publish',
    PUBLISHED = 'published',
    ERROR = 'error',
}

export const JOB_TYPES: Record<
    string,
    { headers: string[]; sample_key: string; body: { job_type: string; action_type: string }; label: string }
> = {
    'terminal-configuration/configure-split-terminal': {
        headers: ['Merchant ID', 'Split Collection', 'Account Type', 'Account Label'],
        sample_key: '/sample-files/collection_account_setup.csv',
        body: {
            action_type: 'configure-split-terminal',
            job_type: 'terminal-configuration',
        },
        label: 'Collection Account Setup',
    },
    'split-collections/create-collection': {
        headers: [
            'Merchant ID',
            'Reference ID',
            'Description',
            'Amount',
            'Collection Account',
            'Split Percentage',
            'Max Collection Per Day',
        ],
        sample_key: '/sample-files/create_collection.csv',
        body: {
            action_type: 'create-collection',
            job_type: 'split-collections',
        },
        label: 'Create Collection',
    },
    'split-collections/edit-collection': {
        headers: ['Merchant ID', 'Invoice ID', 'Active Status', 'Split Percentage', 'Max Collection Per Day'],
        sample_key: '/sample-files/update_collection.csv',
        body: {
            action_type: 'edit-collection',
            job_type: 'split-collections',
        },
        label: 'Update Collection',
    },
}

export const messages = {
    FILE_REQUIRED: 'Please select a file!',
    PUBLISH_PERMISSION: 'You do not have the necessary permission to publish',
    PUBLISHED: 'File already published',
    Error: 'Technical error while publishing',
    PARTIAL_PUBLISH: 'File partially published',
    PUBLISHING: 'File is being published',
    JOB_TYPE_REQUIRED: 'Please select a type!',
}

export const normFile = (e: unknown) => {
    if (Array.isArray(e)) {
        return e
    }
    return (e as UploadEvent)?.fileList
}

export const FileValidatorRule: ValidatorRule = {
    validator: (_, val) => {
        const file = val?.at(0)
        if (!file) return Promise.reject()
        if (!file.percent || file.percent !== 100 || file.response?.name !== 'success') {
            return Promise.reject('Invalid file. Please check the error and try again.')
        }
        return Promise.resolve()
    },
}

export function validateFile(info: Parameters<Required<UploadProps>['customRequest']>[0], type: string) {
    if (!(info.file instanceof File) || info.file.type !== 'text/csv') {
        info.onError({ name: 'error', message: 'Invalid File Type' })
        return
    }

    if (!JOB_TYPES[type]) {
        info.onError({ name: 'error', message: 'Invalid Job Type' })
        return
    }

    const headers = JOB_TYPES[type].headers
    const reader = new FileReader()
    reader.onload = function (e) {
        const text = e.target.result as string
        const lines = text.split(/\r*\n/)
        const firstLine = lines[0]?.split(',') ?? []

        const headersMissing = headers.filter((header) => !firstLine.includes(header))

        if (!text || !lines[0]?.length) {
            info.onError({ name: 'error', message: 'Missing headers on line 1' })
        } else if (headersMissing.length) {
            info.onError({ name: 'error', message: 'Following headers are missing: ' + headersMissing.join(', ') })
        } else if (headers.join() !== lines[0].trim()) {
            info.onError({
                name: 'error',
                message: 'Headers are not in the required sequence. Please refer the sample file.',
            })
        } else if (!lines[1]?.length) {
            info.onError({ name: 'error', message: 'Missing Data!' })
        } else {
            info.onSuccess({ name: 'success' })
        }
    }

    const headersSize = new Blob([headers.join()]).size
    //slice end based on byte size of headers with extra padding (3x size will leave enough room)
    reader.readAsText(info.file.slice(0, headersSize * 3) as Blob)
}

export const getUploadListing = async (params, callback) => {
    try {
        const response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BULK_SERVICE_BASE_URL + APIEndPoints.bulkUpload.listing,
            params,
        })
        if (response?.success) {
            callback(response.data?.files, response.data?.pagination?.totalRecords)
        } else {
            message.error('Failed to fetch bulk upload listing')
        }
    } catch (error) {
        handleError(error)
    }
}

// export const getUploadDetails = async (id, callback) => {
//     try {
//         const response = await makeAPICall({
//             method: 'get',
//             url: process.env.REACT_APP_BULK_SERVICE_BASE_URL + APIEndPoints.bulkUpload.details(id),
//         })
//         if (response?.success) {
//             callback(response.data.data)
//         } else {
//             message.error('Failed to fetch upload data')
//         }
//     } catch (error) {
//         handleError(error)
//     }
// }

export const getFile = async (key, name) => {
    try {
        const response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BULK_SERVICE_BASE_URL + APIEndPoints.bulkUpload.file,
            params: {
                key,
            },
        })
        if (response?.success) {
            downloadFromURL(response.data.url, name)
        } else {
            message.error('Failed to fetch download URL')
        }
    } catch (error) {
        handleError(error)
    }
}

export const uploadFile = async (data: {
    file: File
    body: {
        file_name: string
        file_type: string
        file_size: number
        job_type: string
        action_type: string
        auto_publish?: boolean
        id?: string
    }
    callback
}) => {
    try {
        const response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BULK_SERVICE_BASE_URL + APIEndPoints.bulkUpload.generatePreSignedUrl,
            payload: {
                contentType: data.file.type,
                ...data.body,
            },
        })
        if (response?.success) {
            message.info('Uploading the file')
            fetch(response.data.url, { method: 'put', body: data.file })
                .then(() => {
                    makeAPICall({
                        method: 'post',
                        url: process.env.REACT_APP_BULK_SERVICE_BASE_URL + APIEndPoints.bulkUpload.acknowledgeUpload,
                        payload: {
                            file_key: response.data.fileKey,
                            ...data.body,
                        },
                    })
                        .then((response) => {
                            if (response?.success) {
                                message.success('File uploaded successfully')
                                data.callback('success')
                            } else {
                                message.error('Error while file acknowledgement')
                                data.callback('fail')
                            }
                        })
                        .catch(() => {
                            message.error('Error while file acknowledgement')
                            data.callback('fail')
                        })
                })
                .catch(() => {
                    message.error('Error while uploading the file')
                    data.callback('fail')
                })
        } else {
            message.error('Failed to fetch upload URL')
            data.callback('fail')
        }
    } catch (error) {
        handleError(error)
        data.callback('fail')
    }
}

export const publishFile = async (fileKey, callback) => {
    try {
        const response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BULK_SERVICE_BASE_URL + APIEndPoints.bulkUpload.publish,
            payload: { fileKey },
        })
        if (response?.success) {
            message.success('File published successfully')
            callback()
        } else {
            message.error('Failed to publish file')
            callback()
        }
    } catch (error) {
        handleError(error)
    }
}
