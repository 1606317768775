import { Tooltip, QuestionCircleOutlined } from 'gokwik-ui-kit'

export const basicDetailsFormConfig = {
    label: 'Basic Details',
    step: 'Step 1',
    status: 'Completed',
    items: [
        {
            label: (
                <>
                    Website/Store URL
                    <Tooltip
                        title={
                            'Enter the full web address of your online store or website where customers make purchases'
                        }
                    >
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'website',
            type: 'input',
            // disabled: true,
            placeholder: 'Enter Website URL',
            rules: [
                {
                    required: true,
                    message: 'Please input your website URL!',
                },
            ],
        },
        {
            label: (
                <>
                    Brand Name
                    <Tooltip title={'Provide the name of your brand as it is recognized by customers'}>
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'business_name',
            type: 'input',
            placeholder: 'Enter Brand Name',
            rules: [
                {
                    required: true,
                    message: 'Please input your brand name!',
                },
                {
                    pattern: /^[a-zA-Z0-9 .&-]*$/,
                    message: 'Please enter a valid Brand Name!',
                },
            ],
            disabled: true,
        },
        {
            label: (
                <>
                    Business Category
                    <Tooltip
                        title={
                            'Select the primary category that best describes the nature of your business, such as apparel, electronics, etc.'
                        }
                    >
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'business_category',
            type: 'select',
            options: ['Category 1', 'Category 2', 'Category 3'],
            placeholder: 'Enter Category',
            rules: [
                {
                    required: true,
                    message: 'Please select your business category!',
                },
            ],
        },
        {
            label: (
                <>
                    Average Order Value
                    <Tooltip
                        title={
                            'Enter the typical amount that customers spend on a single order in your store, in Indian Rupees. Estimate the average if exact numbers are not available.'
                        }
                    >
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'average_order_value',
            type: 'inputNumber',
            placeholder: 'Enter value',
            rules: [
                {
                    required: true,
                    message: 'Please input the Average Order Value!',
                },
                {
                    min: 0,
                    validator: (_, value) => (value <= 0 ? Promise.reject(new Error('')) : Promise.resolve()),
                },
            ],
        },
        {
            label: (
                <>
                    Registration type
                    <Tooltip
                        title={
                            'Choose the legal structure of your business entity, such as sole proprietorship, partnership, private limited company, etc'
                        }
                    >
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'entity_type',
            type: 'select',
            options: [
                { 'Private Ltd/Public Ltd/One Person Company': 11 },
                { 'Partnership Firm': 9 },
                { 'Sole Proprietor': 10 },
                { 'Limited liability partnership (LLP)': 7 },
                { 'Hindu Undivided Family (HUF)': 5 },
            ],
            placeholder: 'Enter type of the Registration type',
            rules: [
                {
                    required: true,
                    message: 'Please select the Registration type!',
                },
            ],
            warning: 'If you change or update this field, new documents must be resubmitted.',
        },
    ],
}
