import { Alert, Button, Checkbox, Modal } from 'gokwik-ui-kit'
import ComplienceBanner from '@library/images/common/kyc/complience-banner.svg'

const ComplienceModal = ({ isOpen, setIsOpen, setComplienceAgreed, complienceAgreed, onKycSubmit }) => {
    return (
        <Modal
            title='Before you submit please ensure that following Details/Pages for KYC Compliance'
            open={isOpen}
            footer={null}
            width={650}
            onCancel={() => setIsOpen(false)}
        >
            <Alert
                className='flex align-top items-start'
                message={
                    <p className='inter text-sm font-normal mt-[-2px]'>
                        To complete the KYC process, ensure these essential pages are live, accessible, and up-to-date
                        on your website. They provide critical information, enhance trust, and comply with regulatory
                        requirements. <br />
                        Please update the following information within the next 3 days to prevent any potential KYC
                        rejection. No action is required if everything is already in place.
                    </p>
                }
                banner
            />
            <div className='mt-5'>
                <img src={ComplienceBanner} width={600} />
            </div>
            <div className='flex justify-between align-middle items-center mt-5'>
                <div className='flex'>
                    <Checkbox
                        onChange={() => {
                            setComplienceAgreed((prev) => !prev)
                        }}
                    >
                        All of the above pages/information are available on my website
                    </Checkbox>
                </div>
                <div className='flex'>
                    <Button
                        htmlType='submit'
                        variant='primary'
                        disabled={!complienceAgreed}
                        onClick={() => onKycSubmit()}
                    >
                        Submit KYC
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default ComplienceModal
