import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "./root.component";
import PaymentOffersExport from "@library/components/exports/PaymentOffersExport";

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

export const offersParcelConfig = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: PaymentOffersExport,
  errorBoundary: (err) => {
    return <div>Error: {err.message}</div>;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
