import './reports.css'
import { Table, Button, Col, ColumnProps, Tag, message } from 'gokwik-ui-kit'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DownloadOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { PermissionValues } from '@library/utilities/constants/constants'
import {
    convertIsoToLocaleDateString,
    convertIsoToLocaleString,
    filterDateFormatter,
    makeAPICall,
    updateBreadcrumbs,
    validatePermission,
    downloadFromURL,
} from '@gokwik/utilities'
import ReportsIcon from '@library/images/icons/reportsIcon'
import RenderSearchFilters from '@library/components/search-filter'
import { useDispatch, useSelector } from 'react-redux'
import { previousReports } from '@store/filters/selectors'
import { saveFiltersData } from '@store/filters'
import { getMerchantDetails, getUserDetails, isMerchantSelector,getSignUpEmail } from '@store/user/selectors'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { useAppDispatch, useInterval } from '@library/utilities/hooks'
import { handleError } from '@library/utilities/helpers/handleError'
import { useNavigate } from 'react-router-dom'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'

const today = dayjs()
const formattedDate = filterDateFormatter(today)

export default function () {
    const navigate = useNavigate()
    const [total, setTotal] = useState(0)
    const [reports, setReports] = useState([])
    const prevFilters = useSelector(previousReports)
    const isMerchantUser = useSelector(isMerchantSelector)
    const merchantDetails = useSelector(getMerchantDetails)
    const userDetails = useSelector(getUserDetails)
    const params = new URLSearchParams(location.search)
    const queryParams = {
        from: params.get('from'),
        start: params.get('start'),
        end: params.get('end'),
    }
    const [parameters, setParameters] = useState({
        page: 1,
        sortKey: 'created_at',
        sortOrder: -1,
        pageSize: 25,
        st_date: formattedDate,
        end_date: formattedDate,
        reportType: 'order',
        filterField: 'createdOn',
        generatedOn: {
            from: new Date().getTime() - 2 * 24 * 60 * 60 * 1000,
            to: new Date().getTime(),
        },
        ...prevFilters,
    })
    console.log(dayjs(new Date().getTime() - 2 * 24 * 60 * 60 * 1000).format('DD,MMM,YY'))
    const dispatch = useAppDispatch()
    const [interval, initiateInterval, removeInterval] = useInterval(
        () => getReports(true),
        process.env.REACT_APP_PUBLIC_REPORT_FETCH_INTERVAL,
    )

    const intervalHandling = useCallback(
        (reports) => {
            if ((reports || []).find((report) => ['pending', 'processing'].includes(report.status?.toLowerCase()))) {
                initiateInterval()
            } else if (interval) removeInterval()
        },
        [parameters],
    )

    const columns: ColumnProps<any>[] = useMemo(
        () => [
            {
                title: <span> Name</span>,
                dataIndex: 'reportName',
                fixed: 'left',
                width: 200,
                render: (t) => <p className='truncate w-48'>{t}</p>,
            },
            {
                title: <span>Generated On</span>,
                dataIndex: 'generatedOn',
                render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,

                sorter: (a, b) => (dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? -1 : 1),
                sortDirections: ['ascend', 'descend'],
            },
            {
                title: <span>Generated by</span>,
                dataIndex: 'generatedBy',
            },
            ...(!isMerchantUser
                ? [
                    {
                        title: <span>Merchant</span>,
                        dataIndex: 'merchantName',
                    },
                ]
                : []),
            {
                title: <span>Type</span>,
                dataIndex: 'reportPrettyName',
            },
            {
                title: <span>Date Range</span>,
                dataIndex: 'dateRange',
                render: (e) => (
                    <div>
                        <p>
                            {convertIsoToLocaleDateString(e.from)} - {convertIsoToLocaleDateString(e.to)}
                        </p>
                    </div>
                ),
            },

            {
                title: <span>Status</span>,
                dataIndex: 'status',
                render: (text: string) =>
                    text.toLowerCase() === 'complete' ? (
                        <Tag variant='success' className='rounded-2xl !px-2 !py-1'>
                            Success
                        </Tag>
                    ) : text.toLowerCase() === 'failed' ? (
                        <Tag variant='error' className='rounded-2xl !px-2 !py-1'>
                            Error
                        </Tag>
                    ) : (
                        <Tag variant='warning' className='rounded-2xl !px-2 !py-1'>
                            Pending
                        </Tag>
                    ),

                align: 'center',
            },
            {
                title: <span> Action</span>,
                align: 'center',
                dataIndex: 'id',
                render: (e, itm) => (
                    <div className='flex justify-center'>
                        {itm.status.toLowerCase() === 'complete' && (
                            <a onClick={() => downloadReport(e)} className='mx-auto'>
                                <DownloadOutlined className='text-primary-500' />
                            </a>
                        )}
                    </div>
                ),
            },
        ],
        [isMerchantUser],
    )
    const downloadReport = async (id) => {
        const res = await makeAPICall({
            url: process.env.REACT_APP_BASE_URL_REPORTS + APIEndPoints.reportDownload,
            method: 'get',
            params: {
                id,
            },
        })
        if (res.success) {
            downloadFromURL(res.data.data?.s3url, id)
        } else message.error('Unable to download')
    }

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'reports',
                href: '/reports',
                text: 'Reports',
            },
        ])
    }, [])
    async function getReports(skipLoader?: boolean) {
        try {
            let response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL_REPORTS + APIEndPoints.getReports,
                payload: {
                    generatedOn: {
                        from: new Date(parameters.generatedOn.from).setHours(0, 0, 0, 0),
                        to: new Date(parameters.generatedOn.to).setHours(23, 59, 59, 999),
                    },
                    ...(!isMerchantUser && { merchantId: [] }),
                    pageNo: parameters.page,
                    perPage: 25,
                },
                skipLoader,
            })
            if (response.success) {
                setReports(response.data?.reports)
                intervalHandling(response.data?.reports)
                setTotal(response.data?.totalDocsCount)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const reset = (e) => {
        e.preventDefault()
        setParameters({
            page: 1,
            sortKey: 'created_at',
            sortOrder: -1,
            pageSize: 25,
            st_date: formattedDate,
            end_date: formattedDate,
            filterField: '',
            // merchantId: [],
            reportType: '',
        })
        dispatch(saveFiltersData('reports', {}))
    }

    async function makeReportsCall() {
        try {
            let response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL_REPORTS + APIEndPoints.generateReports,
                payload: {
                    reportName: parameters.reportName,
                    reportType: parameters.reportType,
                    filters: {
                        createdOn: {
                            from: new Date(parameters.st_date).setHours(0, 0, 0, 0),
                            to: new Date(parameters.end_date).setHours(23, 59, 59, 999),
                        },
                    },
                    ...(!isMerchantUser && { merchantId: [`${merchantDetails.id}`] }),
                    generatedOn: {
                        from: new Date(parameters.generatedOn.from).setHours(0, 0, 0, 0),
                        to: new Date(parameters.generatedOn.to).setHours(23, 59, 59, 999),
                    },
                    // recipients: [],
                    viewUnmaskedReport: validatePermission(PermissionValues.reports.viewUnmaskedReport),
                    generatedBy: userDetails?.name,
                    mailTo:[userDetails.email]
                },
            })

            if (response.data?.data?.reports?.length > 0) {
                setReports(response.data?.data?.reports)
                setTotal(response.data?.data?.totalDocsCount)
                intervalHandling(response.data?.data?.reports)
            }
            
            if (response.data?.data?.err) {
                message.error(response.data?.data?.err);
            }
            else if (response.data?.message) {
                message.success(response.data?.message);
            }
        } catch (error) {
            handleError(error)
        }
    }

    useEffect(() => {
        logEvent('admin_reports_clicked', 'click', 'Admin Reports', userDetails?.email, merchantDetails?.m_id, merchantDetails?.short_name, userDetails?.name, parameters);
    }, [])

    useEffect(() => {
        getReports()
    }, [parameters.page, queryParams.from, queryParams.start, queryParams.end])

    useEffect(() => {
        if (queryParams.from && queryParams.start && queryParams.end) {
            setParameters((prev) => ({
                ...prev,
                st_date: filterDateFormatter(new Date(Number(queryParams.start))),
                end_date: filterDateFormatter(new Date(Number(queryParams.end))),
                generatedOn: {
                    from: Number(queryParams.start),
                    to: Number(queryParams.end),
                },
                reportType: queryParams.from,
            }))
        }
    }, [])

    const handleSearchClick = (e) => {
        e?.preventDefault()
        makeReportsCall()
    }

    const submit = async () => {
        makeReportsCall()
    }
    const handlePaginationChange: any = (current: number, pageSize?: number) => {
        setParameters((prev) => ({ ...prev, page: current, pageSize }))
    }

    
    const getYearMonth = (date: Dayjs) => date.year() * 12 + date.month();

    const disabled366DaysDate: any = (current, { from, type }) => {
        if (from) {
          const minDate = from.add(-365, 'days');
          const maxDate = from.add(365, 'days').isAfter(today) ? today : from.add(365, 'days');
      
          switch (type) {
            case 'year':
              return current.year() < minDate.year() || current.year() > maxDate.year();
      
            case 'month':
              return (
                getYearMonth(current) < getYearMonth(minDate) ||
                getYearMonth(current) > getYearMonth(maxDate)
              );
      
            default:
              return Math.abs(current.diff(from, 'days')) >= 366;
          }
        }
      
        return false;
    };


    return (
        <div className='overflow-auto w-full dashboard-reports'>
            <div className='bg-white'>
                <RenderSearchFilters
                    values={parameters}
                    setValues={(data, reset) =>
                        setParameters((prev: any) => (reset ? { ...data } : { ...prev, ...data }))
                    }
                    page='reports'
                    onSearch={handleSearchClick}
                    onReset={reset}
                    disabledDate={disabled366DaysDate}
                >
                    <>
                        <Col className='flex flex-grow justify-end gap-x-3'>
                            <Button onClick={submit} variant='primary' className='flex align-center items-center'>
                                <ReportsIcon width={16} color='white' />
                                <span className='ml-2 text-white'>Generate Report</span>
                            </Button>
                        </Col>
                    </>
                </RenderSearchFilters>
            </div>

            <div className='mt-5 bg-white rounded overflow-clip'>
                <p className='p-4 pl-2 text-sm'> Reports generated in last 7 days </p>
                <Table
                    className={'ordersTable'}
                    columns={columns}
                    dataSource={reports || []}
                    style={{
                        width: '100vw',
                    }}
                    pagination={{
                        current: parameters.page, // Current page number
                        pageSize: parameters.pageSize, // Number of items to display per page
                        total: total, // Total number of items in the data array
                        showSizeChanger: false,
                        onChange: handlePaginationChange,
                        position: ['topRight', 'bottomRight'],
                        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
                    }}
                    rowClassName={(record, index) =>
                        (new Date().getTime() - new Date(record.generatedOn).getTime()) / 1000 < 30 &&
                        `animate-bg-${record.status.toLowerCase()}`
                    }
                    scroll={{ x: 'max-content' }}
                />
            </div>
        </div>
    )
}
