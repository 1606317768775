import { Button, Modal, Segmented } from 'gokwik-ui-kit'
import { useState } from 'react'
import { getSampleDocument, isIdCard } from '../../utils'

const DocumentPreview = ({ isOpen, setIsOpen, previewDocs, setPreviewDocs }) => {
    const [activeTab, setActiveTab] = useState(previewDocs[0]?.value)

    const handleTabChange = (value) => {
        setActiveTab(value)
    }

    const onClose = () => {
        setPreviewDocs([])
        setIsOpen(false)
    }

    return (
        <Modal open={isOpen} footer={null} width={700} onCancel={onClose} title={'Sample Document'}>
            <Segmented
                options={previewDocs.map((doc) => ({
                    label: doc.label,
                    value: doc.value,
                }))}
                block
                onChange={handleTabChange}
                value={activeTab}
            />
            <div
                style={{
                    marginTop: '20px',
                    textAlign: 'center',
                    maxHeight: '300px',
                    overflowY: 'auto',
                }}
            >
                {activeTab ? (
                    <img
                        src={getSampleDocument(activeTab)}
                        alt={activeTab}
                        style={{
                            width: '100%',
                            height: isIdCard(activeTab) ? '300px' : 'auto',
                            display: 'block',
                        }}
                    />
                ) : (
                    <p>No document selected</p>
                )}
            </div>
            <div className='flex justify-end align-middle' style={{ marginTop: '20px' }}>
                <Button type='primary' onClick={onClose}>
                    Understood
                </Button>
            </div>
        </Modal>
    )
}

export default DocumentPreview
