import { filterDateFormatter, updateBreadcrumbs } from '@gokwik/utilities'
import RenderSearchFilters from '@library/components/search-filter'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { getUserDetails } from '@store/user/selectors'
import { Button, Col, InfoCircleOutlined, Row, Table, Tooltip, UploadOutlined } from 'gokwik-ui-kit'
import { getMerchantDetails } from '@store/user/selectors'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { previousBulkUploadFilters } from '@store/filters/selectors'
import dayjs from 'dayjs'
import { saveFiltersData } from '@store/filters'
import { useAppDispatch } from '@library/utilities/hooks'
import { bulkJobsColumnsInternal } from './columnsData'
import BulkUploadDrawer from './bulkDrawer'
import { type JobDetails } from './types'
import { getUploadListing, JOB_TYPES } from './bulk.service'

const today = dayjs()
const last7Days = today.subtract(6, 'day')
const formattedTodayDate = filterDateFormatter(today)
const formattedLast7DaysDate = filterDateFormatter(last7Days)
const defaultParams = {
    page: 1,
    sortKey: 'created_at',
    sortOrder: -1,
    limit: 25,
    startDate: formattedLast7DaysDate,
    endDate: formattedTodayDate,
    fileName: null,
    status: null,
    validation: null,
    jobType: null,
}

export default function () {
    const merchantDetails = useSelector(getMerchantDetails)
    const userDetails = useSelector(getUserDetails)
    const prevFilters = useSelector(previousBulkUploadFilters)
    const [uploadOpen, setUploadOpen] = useState<boolean>(false)
    const [renderDrawer, setRenderDrawer] = useState<boolean>(false)
    const [viewJobDetails, setViewJobDetails] = useState<JobDetails>(null)
    const [total, setTotal] = useState(0)
    const [records, setRecords] = useState([])
    const [parameters, setParameters] = useState({
        ...defaultParams,
        ...prevFilters,
    })
    const dispatch = useAppDispatch()

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'bulk-upload',
                href: '/general/bulk-upload',
                text: 'Uploads',
            },
        ])
        logEvent(
            'bulk_upload_clicked',
            'click',
            'Admin Uploads',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
        )
        getListing(parameters)
    }, [])

    useEffect(() => {
        if (Object.keys(prevFilters).length && JSON.stringify(prevFilters) !== JSON.stringify(parameters)) {
            setParameters({ ...prevFilters })
        }
    }, [prevFilters])

    useEffect(() => {
        if (!uploadOpen)
            setTimeout(() => {
                setRenderDrawer(false)
            }, 150)
        else setRenderDrawer(true)
    }, [uploadOpen])

    const columns = bulkJobsColumnsInternal((details: JobDetails) => (setViewJobDetails(details), setUploadOpen(true)))

    const handlePaginationChange = (current: number, pageSize?: number) => {
        const params = { ...parameters, page: current, pageSize }
        setParameters(params)
        getListing(params)
    }

    const handleSearchClick = (e: React.MouseEvent<HTMLElement>) => {
        e?.preventDefault()
        const params = { ...parameters, page: 1 }
        setParameters(params)
        getListing(params)
    }

    const reset = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        setParameters(defaultParams)
        getListing(defaultParams)
    }

    const getListing = async (params) => {
        const jobData = !!params.jobType ? JOB_TYPES[params.jobType].body : {}
        await getUploadListing(
            { ...parameters, ...params, ...jobData },
            (data, total) => (setRecords(data), setTotal(total)),
        )
        dispatch(saveFiltersData('bulk_upload', { ...parameters, ...params }))
    }

    const onClose = (action: string) => {
        if (action === 'change') {
            setTimeout(() => {
                getListing({ ...parameters })
            }, 200)
        }
        setUploadOpen(false)
        setViewJobDetails(null)
    }

    return (
        <>
            <div className='overflow-auto w-full dashboard-reports'>
                <div className='bg-white rounded'>
                    <Row className='p-3'>
                        <Col sm={12} className='flex items-center gap-x-2 '>
                            <div className='text-black inter m-0 tracking-wide items-center flex gap-2'>
                                <Tooltip title={'View and manage bulk upload actions efficiently here.'}>
                                    <InfoCircleOutlined className='text-blue fs20' />
                                </Tooltip>

                                <span>Perform bulk upload actions</span>
                            </div>
                        </Col>
                        <Col className='flex flex-grow justify-end gap-x-2'>
                            <Button
                                onClick={() => {
                                    setViewJobDetails(null)
                                    setUploadOpen(true)
                                }}
                                variant='primary'
                                className='flex align-center items-center'
                            >
                                <UploadOutlined width={16} color='white' />
                                <span className='ml-2 text-white'>Upload</span>
                            </Button>
                        </Col>
                    </Row>
                </div>
                <div className='mt-5 bg-white rounded-t'>
                    <RenderSearchFilters
                        values={parameters}
                        setValues={(data: typeof parameters, reset) =>
                            setParameters((prev) => (reset ? { ...data } : { ...prev, ...data }))
                        }
                        page='bulk_upload'
                        onSearch={handleSearchClick}
                        onReset={reset}
                    />
                </div>

                <div className='mb-16 bg-white rounded-b overflow-clip'>
                    <Table
                        columns={columns}
                        dataSource={records ?? []}
                        style={{
                            width: '100vw',
                        }}
                        pagination={{
                            current: parameters.page,
                            pageSize: parameters.limit,
                            total,
                            showSizeChanger: false,
                            onChange: handlePaginationChange,
                            showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
                        }}
                        scroll={{ x: 900 }}
                    />
                </div>
            </div>
            {renderDrawer && <BulkUploadDrawer open={!!uploadOpen} onClose={onClose} recordData={viewJobDetails} />}
        </>
    )
}
