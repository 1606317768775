import { Button, CloudUploadOutlined, Col, Drawer, InfoCircleOutlined, Input, message, Row, Select, Tooltip } from 'gokwik-ui-kit'
import { SelectDropdown } from '@library/utilities/interface'
import { useEffect, useState } from 'react'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { makeAPICall } from '@gokwik/utilities'
import { handleError } from '@library/utilities/helpers/handleError'
import { SuccessStatusCodes } from '@library/utilities/constants/constants'

interface Props {
    mId: string
    merchantName: string
    isEdit: boolean
    selectedRecord: any
    handleClose: (fetchNewRecords: boolean) => void
}

interface Account {
    id: string
    account: string
    name: string
    created_at: number
    updated_at: number
}

interface MerchantSplitData {
    available_merchant_split: number
    accounts: Account[]
}

interface FormData {
    referenceId: string
    description: string
    amount: string
    collectionAccount: string | null
    splitPercentage: string
    maxCollectionPerDay: string
}

interface CreateInvoicePayload {
    amount: number
    balance: number
    recovery_account: string
    active: boolean
    max_deduction_per_day: number
    reference_id: string
    description: string
    collection: number
}

const defaultMerchantSplitData = { available_merchant_split: 0, accounts: [] }

export default function ({ mId, merchantName, isEdit = false, selectedRecord, handleClose }: Props) {
    const [merchantSplitData, setMerchantSplitData] = useState<MerchantSplitData>(defaultMerchantSplitData)
    const [collectionAccounts, setCollectionAccounts] = useState<SelectDropdown[]>([])
    const [availableSplit, setAvailableSplit] = useState<number>()
    const [formData, setFormData] = useState<FormData>({
        referenceId: '',
        description: '',
        amount: '',
        collectionAccount: null,
        splitPercentage: '',
        maxCollectionPerDay: '',
    })

    useEffect(() => {
        getAvailableAccounts()
    }, [])

    useEffect(() => {
        if (isEdit && selectedRecord?.invoice_id) {
            setFormData({
                referenceId: selectedRecord?.reference_id || '',
                description: selectedRecord?.description || '',
                amount: selectedRecord?.amount?.toString() || '',
                collectionAccount: selectedRecord?.account || '',
                splitPercentage: selectedRecord?.collection?.toString() || '',
                maxCollectionPerDay: selectedRecord?.max_deduction_per_day?.toString() || '',
            })
        }
    }, [isEdit])

    const validateIntegerInput = (field: string, value: any) => {
        if (isNaN(value) || Number(value) < 0) {
            const fieldNames = {
                amount: 'Amount',
                splitPercentage: 'Split percentage',
                maxCollectionPerDay: 'Max Collection Per Day',
            };
            if (field in fieldNames) {
                message.error(`${fieldNames[field]} must be a number`);
                return false;
            }
        }
        return true;
    };

    const handleInputChange = (field: string, value: any) => {
        if (!validateIntegerInput(field, value)) return;
        if (field === 'splitPercentage') {
            const split = Number(merchantSplitData.available_merchant_split) - Number(value)
            if (split < 0) {
                message.error(`Split percentage cannot be more than ${merchantSplitData.available_merchant_split}`)
                return
            }
            setAvailableSplit(split)
        }
        setFormData((prevState) => ({
            ...prevState,
            [field]: value,
        }))
    }
    
    const checkRequiredField = (fieldValue: string, fieldName: string) => {
        if (!fieldValue) {
            message.error(`${fieldName} is required`)
            return false
        }
        return true
    }

    async function handleInvoice(action: 'create' | 'edit') {
        if (!mId) {
            message.error('Merchant is required')
            return
        }

        const { description, referenceId, collectionAccount, amount, maxCollectionPerDay, splitPercentage } = formData

        if (!checkRequiredField(referenceId, 'Reference ID')) return
        if (!checkRequiredField(description, 'Description')) return
        if (!checkRequiredField(collectionAccount, 'Collection Account')) return
        if (!checkRequiredField(amount, 'Amount')) return
        if (!checkRequiredField(maxCollectionPerDay, 'Max Collection Per Day')) return

        const createInvoicePayload: CreateInvoicePayload = {
            reference_id: referenceId,
            description,
            amount: Number(amount),
            max_deduction_per_day: Number(maxCollectionPerDay),
            recovery_account: collectionAccount,
            active: true,
            balance: action === 'create' ? Number(amount) : selectedRecord?.balance,
            collection: Number(splitPercentage),
        }

        try {
            const endpoint =
                action === 'create'
                    ? APIEndPoints.splitSettlements.createInvoice(mId)
                    : APIEndPoints.splitSettlements.updateInvoice(mId, selectedRecord?.invoice_id)
            const method = action === 'create' ? 'post' : 'patch'
            const url = `${process.env.REACT_APP_AUX_BASE_URL}${endpoint}`

            let response = await makeAPICall({
                method,
                url,
                payload: createInvoicePayload,
            })
            if (SuccessStatusCodes.includes(response.data?.status_code)) {
                message.success(`Collection ${action === 'create' ? 'created' : 'updated'} successfully`)
                handleClose(true)
            } else {
                const errMessage = response?.response?.data?.error?.message ?? `Failed to ${action === 'create' ? 'create' : 'update'} invoice`
                message.error(errMessage)
            }
        } catch (error) {
            handleError(error)
        }
    }

    async function getAvailableAccounts() {
        if (!mId) {
            message.error('Merchant is required')
            return
        }
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.splitSettlements.getAvailableAccounts(mId),
            })
            if (response.data?.data) {
                const receivedData = response.data.data
                const accounts = receivedData?.accounts?.map((acc) => ({ label: acc.name, value: acc.account })) ?? []
                setMerchantSplitData(receivedData)
                setCollectionAccounts(accounts)
            } else {
                setMerchantSplitData(defaultMerchantSplitData)
            }
        } catch (error) {
            handleError(error)
        }
    }

    return (
        <>
            <Drawer title={`${isEdit ? 'Edit' : 'Configure new'} collection split`} open={true} onClose={() => handleClose(false)} width={400}>
                <p className='text-normal font-normal mb-1'>
                    Merchant Name: <span className='text-green-600 text-base font-medium mb-1'>{merchantName}</span>
                </p>
                <Row gutter={[4, 4]} className='mt-6'>
                    <Col span={24}>
                        <Input
                            label='Reference Id*'
                            placeholder='Enter Reference Id'
                            value={formData.referenceId}
                            onChange={(e) => handleInputChange('referenceId', e.target.value)}
                            disabled={isEdit}
                            maxLength={30}
                        />
                    </Col>
                    <Col span={24}>
                        <Input
                            label='Description*'
                            placeholder='Enter Description'
                            value={formData.description}
                            onChange={(e) => handleInputChange('description', e.target.value)}
                            disabled={isEdit}
                        />
                    </Col>
                    <Col span={24}>
                        <Input
                            label='Amount*'
                            placeholder='Enter Amount'
                            value={formData.amount}
                            onChange={(e) => handleInputChange('amount', e.target.value)}
                            disabled={isEdit}
                        />
                    </Col>
                    <Col span={24}>
                        <Select
                            label='Collection Account*'
                            className='w-full'
                            placeholder='Select Collection Account'
                            onChange={(value) => handleInputChange('collectionAccount', value)}
                            options={collectionAccounts}
                            value={formData.collectionAccount}
                            disabled={isEdit}
                        />
                    </Col>
                </Row>
                <Row gutter={[4, 4]} className='mt-12'>
                    <Col span={24}>
                        <Input
                            label='Setup Split Percentage(%)*'
                            placeholder='Enter split percentage'
                            value={formData.splitPercentage}
                            onChange={(e) => handleInputChange('splitPercentage', e.target.value)}
                        />
                    </Col>
                    <Col span={24}>
                        <Input
                            label='Max Collection Per Day*'
                            placeholder='Enter collection'
                            value={formData.maxCollectionPerDay}
                            onChange={(e) => handleInputChange('maxCollectionPerDay', e.target.value)}
                        />
                        {availableSplit >= 0 && (
                            <p className='mt-1.5 ml-1 text-xs font-normal text-gray-500'>
                                Merchant Split Available: {availableSplit?.toFixed(2)}%
                            </p>
                        )}
                    </Col>
                    <Col span={24}>
                        <Button
                            variant='primary'
                            className='flex items-center justify-center w-full mt-6'
                            onClick={() => handleInvoice(isEdit ? 'edit' : 'create')}
                        >
                            <CloudUploadOutlined style={{ fontSize: 16 }} />
                            {isEdit ? 'Update Details' : 'Save Details'}
                        </Button>
                    </Col>
                    <Col className='mt-1' span={24}>
                        <p className='text-red-500 text-xs'>*Your changes will take affect from mid night</p>
                    </Col>
                </Row>
            </Drawer>
        </>
    )
}
