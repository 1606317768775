import {
    Tooltip,
    QuestionCircleOutlined,
    TagOutlined,
    Dropdown,
    Button,
    Segmented,
    DownOutlined,
    SwapOutlined,
    DatePicker,
    Spin,
    WarningFilled,
} from 'gokwik-ui-kit'
import React, { useEffect, useRef, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import RcaViewLayout, {
    initialEdges,
    initialNodes,
} from '../../../library/components/analytics/rca-cards/rca-view-layout'
import RcaMoreView from '../../../library/components/analytics/rca-cards/rca-more-view'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchAllRtoDetailsData } from '@store/rca-view'
import { getRcaViewData } from '@store/rca-view/selector'
import { getMerchantDetails } from '@store/user/selectors'
import {
    FilterType,
    rcaCardsData,
    rcaViewDropdownItems,
    resetRcaCardsData,
} from '@library/utilities/constants/constants'
import { updateRcaCardsData } from '@library/utilities/helpers/rca_view_data_filtering'
import EmptyPageData from '@library/components/empty-page-data'
import { updateBreadcrumbs } from '@gokwik/utilities'
import { saveFiltersData } from '@store/filters'

type Align = 'RTO Risk Score' | 'States' | 'City' | 'Pincodes'

export const keyMap = {
    [FilterType.DECILE]: { keyName: 'decileData', comparedKeyName: 'comparedDecileData' },
    [FilterType.STATE]: { keyName: 'statesData', comparedKeyName: 'comparedStatesData' },
    [FilterType.CITY]: { keyName: 'citiesData', comparedKeyName: 'comparedCitiesData' },
    [FilterType.PINCODE]: { keyName: 'pincodesData', comparedKeyName: 'comparedPincodesData' },
}

const RCAView = () => {
    const merchantDetails = useSelector(getMerchantDetails)
    const allowedMerchantIds = process.env.RCA_VIEW_MERCHANTS || []
    const currentMerchantId = merchantDetails.id
    const isRCAViewAllowed = allowedMerchantIds.includes(currentMerchantId)

    if (!isRCAViewAllowed) {
        return (
            <EmptyPageData
                title="You currently don't have access to this page!"
                icon={<WarningFilled className='text-[#ffc107] text-7xl' />}
            />
        )
    }

    const dispatch = useDispatch()
    const [alignValue, setAlignValue] = useState<Align>('RTO Risk Score')
    const [options, setOptions] = useState([
        { label: 'RTO Risk Score', value: 'RTO Risk Score', disabled: true },
        'States',
        'City',
        { label: 'Pincodes', value: 'Pincodes', disabled: false },
        // 'Products',
    ])
    const [view, setView] = useState<'RcaViewLayout' | 'RcaMoreView'>('RcaViewLayout')
    const [selectedRange, setSelectedRange] = useState<[Dayjs, Dayjs] | null>([
        dayjs().subtract(1, 'month').startOf('month'),
        dayjs().subtract(1, 'month').endOf('month'),
    ])
    const [comparedRange, setComparedRange] = useState<[Dayjs, Dayjs] | null>([
        dayjs().subtract(2, 'month').startOf('month'),
        dayjs().subtract(2, 'month').endOf('month'),
    ])
    const { filter_type = FilterType.DECILE } = useParams()
    const [filterType, setFilterType] = useState(filter_type)
    const [selectedItem, setSelectedItem] = useState('RTO Share')
    const [loading, setLoading] = useState(true)

    const rcaViewLayoutStateRef = useRef({
        nodes: initialNodes,
        edges: initialEdges,
        clickedNodeData: null,
        selectedNodeIds: new Set<any>(),
    })

    let rtoDetailsData = useSelector(getRcaViewData)

    const disabledDate = (current: Dayjs) => {
        const today = dayjs().endOf('day')
        const ninetyDaysAgo = dayjs().subtract(90, 'day').startOf('day')
        return current && (current > today || current < ninetyDaysAgo)
    }

    const checkApiStatus = () => {
        const { keyName, comparedKeyName } = keyMap[filterType] || {}
        return (
            rtoDetailsData?.rtoInsights?.status === 'loading' ||
            rtoDetailsData?.rtoInsightsPrevComparison?.status === 'loading' ||
            rtoDetailsData?.[keyName]?.status === 'loading' ||
            rtoDetailsData?.[comparedKeyName]?.status === 'loading'
        )
    }

    const formatDateRange = (dates: [Dayjs, Dayjs] | null) =>
        dates ? dates.map((date) => date.format('DD MMM YYYY')).join(' - ') : ''

    const handleFilterChange = (key: Align) => {
        setAlignValue(key)
        let newFilterType
        switch (key) {
            case 'RTO Risk Score':
                newFilterType = FilterType.DECILE
                break
            case 'States':
                newFilterType = FilterType.STATE
                break
            case 'City':
                newFilterType = FilterType.CITY
                break
            case 'Pincodes':
                newFilterType = FilterType.PINCODE
                break
            // case 'Products':    //to be added later
            //     newFilterType = FilterType.PRODUCT
            //     break
        }
        setFilterType(newFilterType)

        rcaCardsData['filter_type'] = filterType
        rcaCardsData['filter_type_text'] = alignValue
    }

    const handleViewChange = (newView: 'RcaViewLayout' | 'RcaMoreView') => {
        setView(newView)
        let opt = options

        if (newView == 'RcaMoreView')
            opt = [
                { label: 'RTO Risk Score', value: 'RTO Risk Score', disabled: true },
                'States',
                'City',
                { label: 'Pincodes', value: 'Pincodes', disabled: true },
                // 'Products',
            ]
        else
            opt = [
                { label: 'RTO Risk Score', value: 'RTO Risk Score', disabled: false },
                'States',
                'City',
                { label: 'Pincodes', value: 'Pincodes', disabled: false },
                // 'Products',
            ]

        setOptions(opt)
    }

    const handleRangeChange = (dates: [Dayjs, Dayjs] | null) => {
        if (dates) {
            const [x, y] = dates
            const duration = y.diff(x, 'day')
            const z = x.subtract(duration + 1, 'day')
            const comparedEnd = x.subtract(1, 'day')

            const formattedRange = formatDateRange(selectedRange)
            const formattedComparedRange = formatDateRange(comparedRange)

            setSelectedRange(dates)
            setComparedRange([z, comparedEnd])

            rcaCardsData['selected_date_range'] = formattedRange
            rcaCardsData['compared_date_range'] = formattedComparedRange
        } else {
            setSelectedRange(selectedRange)
            setComparedRange(comparedRange)
        }
    }

    const handleMenuClick = ({ key }: { key: string }) => {
        const selected = rcaViewDropdownItems.find((item) => item.key === key)
        if (selected) setSelectedItem(selected.label)
    }

    const getFetch = async () => {
        dispatch(
            //@ts-ignore
            fetchAllRtoDetailsData({
                params: {
                    from_date: selectedRange?.[0]?.format('YYYY-MM-DD'),
                    to_date: selectedRange?.[1]?.format('YYYY-MM-DD'),
                    prev_comparison_from_date: comparedRange?.[0]?.format('YYYY-MM-DD'),
                    prev_comparison_to_date: comparedRange?.[1]?.format('YYYY-MM-DD'),
                    filter_type: filterType,
                    merchant_id: merchantDetails.id,
                },
            }),
        )
        dispatch<any>(
            saveFiltersData('rcaView', {
                from_date: selectedRange?.[0]?.format('YYYY-MM-DD'),
                to_date: selectedRange?.[1]?.format('YYYY-MM-DD'),
                prev_comparison_from_date: comparedRange?.[0]?.format('YYYY-MM-DD'),
                prev_comparison_to_date: comparedRange?.[1]?.format('YYYY-MM-DD'),
                filter_type: filterType,
                merchant_id: merchantDetails.id,
            }),
        )
    }

    useEffect(() => {
        let breadcrumbs = [
            {
                key: 'rto-analytics',
                href: '/rto/analytics/rca-view',
                text: 'RTO Analytics RCA View',
            },
        ]
        updateBreadcrumbs((prev) => [prev[0], ...breadcrumbs])
    }, [])

    useEffect(() => {
        getFetch()
    }, [selectedRange, merchantDetails.id])

    useEffect(() => {
        rcaViewLayoutStateRef.current = {
            nodes: initialNodes,
            edges: initialEdges,
            clickedNodeData: null,
            selectedNodeIds: new Set(),
        }
    }, [filterType, selectedRange, selectedItem])

    useEffect(() => {
        resetRcaCardsData()
        const formattedRange = formatDateRange(selectedRange)
        const formattedComparedRange = formatDateRange(comparedRange)
        rcaCardsData['selected_date_range'] = formattedRange
        rcaCardsData['compared_date_range'] = formattedComparedRange
        rcaCardsData['filter_type'] = filterType
        rcaCardsData['filter_type_text'] = alignValue
        updateRcaCardsData(rtoDetailsData, filterType, selectedItem)
    }, [checkApiStatus(), filterType, selectedItem, selectedRange, rtoDetailsData])

    useEffect(() => {
        setLoading(checkApiStatus())
    }, [rtoDetailsData, checkApiStatus()])

    useEffect(() => {
        handleViewChange(view)
    }, [view])

    const menuProps = {
        items: rcaViewDropdownItems,
        onClick: handleMenuClick,
    }

    return (
        <div className='bg-white h-[100%]'>
            <div className='h-max bg-white flex w-full flex-col mb-[1%]'>
                <div className='flex'>
                    <div className='pl-1 w-full'>
                        <div className='mt-5 mb-2.5'>
                            <p className='text-rca-black-opacity text-[18px] font-semibold leading-[16px]'>
                                {React.createElement(TagOutlined)} RCA View (Beta){' '}
                                {
                                    <Tooltip
                                        title={`Uncover the reasons behind RTOs and identify areas to improve your business.`}
                                    >
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                }
                            </p>
                        </div>
                        <div className='text-rca-black-45'>Optimize Performance with Data-Driven RTO Insights</div>
                    </div>
                    <div className='mt-[1%] self-flex-end p-[10px]'>
                        <div className='flex items-center gap-[10px] whitespace-nowrap'>
                            <span className='text-rca-black-50'>View By</span>
                            <Segmented
                                defaultValue='RTO Risk Score'
                                className='mb-[8px]'
                                onChange={(value) => handleFilterChange(value as Align)}
                                options={options}
                            />
                        </div>
                    </div>
                </div>
                <div className='flex pt-0 pb-0 px-[10px] justify-end items-center self-stretch mt-[1%]'>
                    <div className='flex items-center gap-[20px] whitespace-nowrap text-rca-black-50'>
                        {filterType != FilterType.DECILE && (
                            <div>
                                <span className='pr-[9px]'>Sort By</span>
                                <Dropdown menu={menuProps} trigger={['click']}>
                                    <Button variant='default' className='border-rca-black-15'>
                                        <SwapOutlined className='text-rca-black-45' /> {selectedItem}{' '}
                                        <DownOutlined className='text-rca-black-45' />
                                    </Button>
                                </Dropdown>
                            </div>
                        )}

                        <div>
                            <span className='pr-[9px]'>Apply date range</span>
                            <DatePicker.RangePicker
                                onChange={handleRangeChange}
                                disabledDate={disabledDate}
                                value={selectedRange}
                                format='DD MMM YYYY'
                                presets={[
                                    {
                                        label: 'Last 7 Days',
                                        value: [dayjs().subtract(7, 'days'), dayjs()],
                                    },
                                    {
                                        label: 'Last 30 Days',
                                        value: [dayjs().subtract(30, 'days'), dayjs()],
                                    },
                                    {
                                        label: 'Last 90 Days',
                                        value: [dayjs().subtract(90, 'days'), dayjs()],
                                    },
                                ]}
                            />
                        </div>

                        <div>
                            <span className='pr-[9px]'>Compare with</span>
                            <DatePicker.RangePicker value={comparedRange} format='DD MMM YYYY' disabled />
                        </div>
                    </div>
                </div>
            </div>
            <div className='content-wrapper'>
                {loading ? (
                    <div className='flex justify-center items-center h-full'>
                        <Spin />
                    </div>
                ) : view === 'RcaViewLayout' ? (
                    <RcaViewLayout
                        switchToMoreView={() => handleViewChange('RcaMoreView')}
                        filterType={filterType}
                        selectedItem={selectedItem}
                        merchantId={merchantDetails.id}
                        fromDate={new Date(selectedRange?.[0]?.format('YYYY-MM-DD'))}
                        toDate={new Date(selectedRange?.[1]?.format('YYYY-MM-DD'))}
                        savedStateRef={rcaViewLayoutStateRef}
                    />
                ) : (
                    <RcaMoreView
                        switchToMoreView={() => handleViewChange('RcaViewLayout')}
                        filterType={filterType}
                        selectedItem={selectedItem}
                    />
                )}
            </div>
        </div>
    )
}

export default RCAView
