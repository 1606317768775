import { Drawer, Steps } from 'gokwik-ui-kit'
import { type UploadDrawerProps } from './types'
import { useEffect, useState } from 'react'
import Step1 from './steps/step1'
import Step2 from './steps/step2'

const BulkUploadDrawer = ({ open, onClose, recordData }: UploadDrawerProps) => {
    const [current, setCurrent] = useState(0)

    const renderStep = () => {
        switch (current) {
            case 0:
                return <Step1 onClose={onClose} />

            case 1:
                return <Step2 data={recordData} onClose={onClose} />
        }
    }

    useEffect(() => {
        if (recordData) {
            setCurrent(1)
        }
    }, [])

    return (
        <>
            <Drawer
                title='Bulk Upload'
                className='pt-0'
                placement='right'
                onClose={() => onClose('cancelled')}
                open={open}
                width={450}
            >
                <Steps
                    current={current}
                    className='mb-10'
                    items={[
                        {
                            title: <span className='text-sm'>Upload</span>,
                        },
                        {
                            title: <span className='text-sm'>Review</span>,
                        },
                    ]}
                />
                {renderStep()}
            </Drawer>
        </>
    )
}

export default BulkUploadDrawer
