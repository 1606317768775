// Import Dependencies
import { navigateToUrl } from 'single-spa'
import React, { useState, useEffect, useMemo } from 'react'

import backArrow from '@library/images/icons/backArrow.svg'

// Import Images
import logoGokwik from '@library/images/common/logo-gokwik-business-suit.svg'
import shieldGreen from '@library/images/icons/shield.svg'
import shieldRed from '@library/images/icons/shield-cross.svg'

// Import Styles
// import './signup.css';

// Import Utilities
import useAESEncryption from '@library/utilities/authorization/encryption'
import { makeAPICall } from '@gokwik/utilities'
import { Button, Col, Row, Input, message } from 'gokwik-ui-kit'
import PasswordIcon from '@library/images/icons/passwordIcon'
import { redirect } from 'react-router-dom'
import CompanyIcon from '@library/images/icons/companyIcon'
import UserIcon from '@library/images/icons/userIcon'
import { useSelector } from 'react-redux'
import { getSignUpEmail } from '@store/user/selectors'
import LoginLayout from '@library/components/loginLayout'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'

const passwordValidationTexts = [
    {
        text: 'Minimum 8 characters',
        key: 'length',
    },
    {
        text: '1 lowercase and 1 uppercase letter',
        key: 'casing',
    },
    {
        text: '1 special character',
        key: 'specialChar',
    },
]

export default function () {
    const [formData, setFormData] = useState({ password: '', confirmPassword: '' })
    const signupEmail = useSelector(getSignUpEmail)

    const passwordValidations = useMemo(
        () => ({
            length: formData.password.length > 7,
            casing: /^(?=.*[a-z])(?=.*[A-Z]).+$/.test(formData.password),
            specialChar: /[!@#$%^&*()_+{}\[\]:;<>,.?~\\\-=/|]+/.test(formData.password),
        }),
        [formData.password],
    )
    useEffect(() => {
        if (!signupEmail) navigateToUrl('/login')
    }, [])

    const forgotPassword = async () => {
        const allValiationsPassed = Object.values(passwordValidations).reduce((result, curr) => curr && result, true)
        if (!allValiationsPassed) {
            message.error('Password validations not matched')
            return
        } else if (formData.password !== formData.confirmPassword) {
            message.error('Passwords do not match')
            return
        }
        const encryptedPass = await useAESEncryption(formData.password, process.env.REACT_APP_PUBLIC_CRYPTO_KEY)
        await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + '/api/dashboard/user/change-password',
            headers: {
                token: localStorage.getItem('temptoken'),
            },
            payload: {
                password: encryptedPass,
                confirm: encryptedPass,
                email: signupEmail,
            },
        }).then((response) => {
            if (response.success) {
                localStorage.removeItem('temptoken')
                localStorage.setItem('currentPage', 'change-password')
                logEvent('login_page_password_change_success', 'click', 'login', localStorage.getItem('email'))
                navigateToUrl('/login')
            } else if (response.error) {
                logEvent('login_page_password_change_failed', 'click', 'login', localStorage.getItem('email'))
                message.error(response.response?.data.message || 'Something went wrong')
            }
        })
    }

    return (
        <LoginLayout
            titleContent={
                <>
                    <p className='text-3xl text-[#060322] font-normal leading-normal inter tracking-tight'>
                        Change Password
                    </p>
                    <svg xmlns='http://www.w3.org/2000/svg' width='398' height='2' viewBox='0 0 398 2' fill='none'>
                        <path d='M1 1L397 0.999965' stroke='#828282' stroke-linecap='round' />
                    </svg>
                    <p className='font-normal text-normal'>
                        {' '}
                        We'll ask for this password whenever you sign in. {signupEmail}
                    </p>
                </>
            }
        >
            <Row className='w-full flex flex-col flex-grow'>
                <div className='w-full flex flex-col'>
                    <div className='flex flex-col mb-4' style={{ rowGap: '0.5rem' }}>
                        <div className='flex flex-col' style={{ rowGap: '0.75rem' }}>
                            <h4 className='body text-primary-750'>Enter a strong password</h4>
                            <Input.Password
                                prefix={<PasswordIcon />}
                                className='email-input gk-text-input'
                                placeholder=''
                                {...(!Object.values(passwordValidations).reduce(
                                    (result, curr) => result && curr,
                                    true,
                                ) && {
                                    status: 'error',
                                })}
                                value={formData.password}
                                onChange={(e) => setFormData((prev) => ({ ...prev, password: e.target.value }))}
                            />
                        </div>
                        {passwordValidationTexts.map((validation) => (
                            <h4
                                key={validation.key}
                                style={{ columnGap: '0.5rem', fontWeight: 400 }}
                                className={`flex items-center body ${
                                    passwordValidations[validation.key] ? 'text-success-600' : 'text-error-700'
                                } `}
                            >
                                <img src={passwordValidations[validation.key] ? shieldGreen : shieldRed} />
                                {validation.text}
                            </h4>
                        ))}
                        <div className='flex flex-col mt-2' style={{ rowGap: '0.75rem' }}>
                            <h4 className='body text-primary-750'>Confirm password</h4>
                            <Input.Password
                                prefix={<PasswordIcon />}
                                className='email-input gk-text-input'
                                placeholder=''
                                value={formData.confirmPassword}
                                onChange={(e) => setFormData((prev) => ({ ...prev, confirmPassword: e.target.value }))}
                            />
                        </div>
                    </div>

                    <Button
                        variant='primary'
                        className='next'
                        onClick={(e) => {
                            forgotPassword()
                        }}
                    >
                        <h3 className='body-bold'>Change password</h3>
                    </Button>
                </div>
            </Row>
        </LoginLayout>
    )
}
