import { Col, Radio, Form, Divider, InputNumber, Select, Checkbox } from 'gokwik-ui-kit'
import ProductSearch from '../productSearch'

const SetupDiscountedCartItems = ({ form }) => {
    const discountedCartItemsDiscountType = Form.useWatch('discountedCartItemsDiscountType', form)
    const cartItemsType = Form.useWatch('discountedCartItemsType', form)
    const discountedCartItemsType =
        cartItemsType === 'specificCollections'
            ? 'collections'
            : cartItemsType === 'specificProducts'
            ? 'products'
            : cartItemsType
    const discountedCartDiscountItems = Form.useWatch('discountedCartDiscountItems', form)
    const discountedCartItemsEnableUsesOrderLimit = Form.useWatch('discountedCartItemsEnableUsesOrderLimit', form)
    return (
        <Col span={24}>
            <ProductSearch
                items={discountedCartDiscountItems}
                searchType={discountedCartItemsType}
                collateProducts
                hideSearch={!cartItemsType}
                onAdd={(items) => {
                    const itemsInRemove = items.filter((item) =>
                        discountedCartDiscountItems?.[discountedCartItemsType]?.remove?.includes?.(item.id),
                    )

                    form.setFieldsValue({
                        discountedCartDiscountItems: {
                            ...(discountedCartDiscountItems || {}),
                            [discountedCartItemsType]: {
                                ...(discountedCartDiscountItems?.[discountedCartItemsType] || {}),
                                remove: discountedCartDiscountItems?.[discountedCartItemsType]?.remove?.filter?.((id) =>
                                    itemsInRemove.find((item) => item.id !== id),
                                ),
                                add: [
                                    ...(discountedCartDiscountItems?.[discountedCartItemsType]?.add || []),
                                    ...items
                                        .filter((addedItem) => !itemsInRemove.find((item) => item.id === addedItem.id))
                                        .map((item) => item.id),
                                ],
                                data: [
                                    ...(discountedCartDiscountItems?.[discountedCartItemsType]?.data || []),
                                    ...items,
                                ],
                            },
                        },
                    })
                }}
                onRemove={(removedItem) => {
                    const isItemInAdd = discountedCartDiscountItems[discountedCartItemsType].add?.includes(
                        removedItem.id,
                    )
                    form.setFieldsValue({
                        discountedCartDiscountItems: {
                            ...(discountedCartDiscountItems || {}),

                            [discountedCartItemsType]: {
                                ...discountedCartDiscountItems[discountedCartItemsType],
                                ...(isItemInAdd
                                    ? {
                                          add: discountedCartDiscountItems[discountedCartItemsType].add.filter(
                                              (id) => id !== removedItem.id,
                                          ),
                                      }
                                    : {
                                          remove: [
                                              ...(discountedCartDiscountItems[discountedCartItemsType].remove || []),
                                              removedItem.id,
                                          ],
                                      }),
                                data: discountedCartDiscountItems[discountedCartItemsType].data.filter(
                                    (item) => item.id !== removedItem.id,
                                ),
                            },
                        },
                    })
                }}
            >
                <div className='flex flex-col'>
                    <p className='text-sm font-semibold w-full pb-2'>Customer gets?</p>
                    <div className='flex gap-x-4'>
                        <Form.Item
                            label='Quantity'
                            name='discountedCartItemsQuantity'
                            rules={[{ required: true, message: 'Please enter quantity' }]}
                        >
                            <InputNumber className='w-40' min={1} />
                        </Form.Item>
                        <Form.Item
                            label='Any Item From'
                            name='discountedCartItemsType'
                            rules={[{ required: true, message: 'Please select item type' }]}
                            className='w-40'
                        >
                            <Select
                                options={[
                                    { label: 'Specific Collection', value: 'specificCollections' },
                                    { label: 'Specific Products', value: 'specificProducts' },
                                ]}
                            />
                        </Form.Item>
                    </div>
                </div>
            </ProductSearch>
            <Form.Item
                name='discountedCartDiscountItems'
                className='pl-4 hide-control-input'
                rules={[
                    { required: true, message: 'Please add ' + (discountedCartItemsType || 'items') },
                    {
                        validator: (_, value) => {
                            if (!value?.products?.data?.length && !value?.collections?.data?.length) {
                                return Promise.reject('Please add ' + discountedCartItemsType)
                            }
                            return Promise.resolve()
                        },
                    },
                ]}
            >
                <> </>
            </Form.Item>

            <Divider className='my-3' />
            <div className='flex justify-between items-end'>
                <Form.Item
                    rules={[{ required: true, message: 'Please select discount value type' }]}
                    className='label-bold'
                    label='At a discounted value'
                    name='discountedCartItemsDiscountType'
                >
                    <Radio.Group>
                        <Radio value='percentage'>Percentage</Radio>
                        <Radio value='fixed'>Fixed Amount</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label='Discount Value'
                    name='discountedCartItemsDiscountValue'
                    rules={[{ required: true, message: 'Please input your discount value!' }]}
                >
                    <InputNumber
                        className='w-96'
                        {...(discountedCartItemsDiscountType === 'percentage'
                            ? {
                                  min: 1,
                                  max: 100,
                                  suffix: '%',
                              }
                            : {
                                  min: 1,
                                  prefix: '₹',
                              })}
                    />
                </Form.Item>
            </div>
            <div className='flex justify-between items-end mt-2'>
                <Form.Item name='discountedCartItemsEnableUsesOrderLimit' valuePropName='checked'>
                    <Checkbox>Set a maximum number of uses per order</Checkbox>
                </Form.Item>
                {discountedCartItemsEnableUsesOrderLimit && (
                    <Form.Item
                        label='Usage Limit Per Order'
                        name='discountedCartItemsUsesOrderLimit'
                        rules={[
                            {
                                required: discountedCartItemsEnableUsesOrderLimit,
                                message: 'Please input your order limit!',
                            },
                        ]}
                    >
                        <InputNumber min={1} className='w-96' />
                    </Form.Item>
                )}
            </div>
        </Col>
    )
}

export default SetupDiscountedCartItems
